import { I18nService } from '../../services/I18nService';
import { EventType } from '../models/calendar';

export const translateEventType = (type: EventType) => {
  const names = {
    [EventType.Event]: I18nService.getString('Event'),
    [EventType.Absence]: I18nService.getString('Absence'),
    [EventType.Availability]: I18nService.getString('Availability'),
    [EventType.Collection]: I18nService.getString('Collection'),
    [EventType.External]: I18nService.getString('Event'),
    [EventType.Feed]: I18nService.getString('Feed'),
  } satisfies Record<EventType, string>;

  return names[type];
};
