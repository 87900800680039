import { Button } from 'antd';
import React from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { StatementType } from './ConsentCreateStatement';

import { I18nService } from '@/react/services/I18nService';

const ConsentCreateStatementButton = () => {
  const showConsentCreateStatementModal = (statementType: StatementType) => {
    NiceModal.show('ConsentCreateStatement', { statementType });
  };

  return (
    <Button
      onClick={() => showConsentCreateStatementModal(StatementType.Create)}
      type="primary"
    >
      {' '}
      {I18nService.getString('Create statement')}
    </Button>
  );
};

export default ConsentCreateStatementButton;
