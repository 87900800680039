/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { GetMessagesIdResponseBody } from '../models/GetMessagesIdResponseBody';
import type { GetMessagesResponseBody } from '../models/GetMessagesResponseBody';
import type { PostMessagesRequestBody } from '../models/PostMessagesRequestBody';
import type { PostMessagesResponseBody2 } from '../models/PostMessagesResponseBody2';
import type { PutMessagesCommentsIdRequestBody } from '../models/PutMessagesCommentsIdRequestBody';
import type { PutMessagesIdRequestBody } from '../models/PutMessagesIdRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MessagesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetMessages
     * Index
     * @returns GetMessagesResponseBody
     * @throws ApiError
     */
    public getMessages(): CancelablePromise<GetMessagesResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/messages',
        });
    }
    /**
     * Create a new group message.
     * User needs to be member of the group and be part of the org to post to the group.
     * @returns PostMessagesResponseBody2
     * @throws ApiError
     */
    public postMessages({
        groupId,
        requestBody,
    }: {
        groupId?: number,
        requestBody?: PostMessagesRequestBody,
    }): CancelablePromise<PostMessagesResponseBody2> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/messages',
            query: {
                'groupId': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeleteMessagesCommentsId
     * Reply Delete
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteMessagesComments(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/messages/comments/{id}',
        });
    }
    /**
     * PutMessagesCommentsId
     * Reply Update
     * @returns EmptyObject
     * @throws ApiError
     */
    public putMessagesComments({
        requestBody,
    }: {
        requestBody?: PutMessagesCommentsIdRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/messages/comments/{id}',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeleteMessagesId
     * Delete
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteMessages(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/messages/{id}',
        });
    }
    /**
     * GetMessagesId
     * Retrieve
     * @returns GetMessagesIdResponseBody
     * @throws ApiError
     */
    public getMessages1({
        id,
    }: {
        /**
         * The message id.
         */
        id: any,
    }): CancelablePromise<GetMessagesIdResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/messages/{id}',
            query: {
                'id': id,
            },
        });
    }
    /**
     * PutMessagesId
     * Update
     * @returns EmptyObject
     * @throws ApiError
     */
    public putMessages({
        requestBody,
    }: {
        requestBody?: PutMessagesIdRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/messages/{id}',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * PostMessagesIdComments
     * Reply Create
     * @returns EmptyObject
     * @throws ApiError
     */
    public postMessagesComments({
        requestBody,
    }: {
        requestBody?: PutMessagesIdRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/messages/{id}/comments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
