/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { ListBlogViewsDictionariesResponseBody } from '../models/ListBlogViewsDictionariesResponseBody';
import type { ListCalendarViewsDictionariesResponseBody } from '../models/ListCalendarViewsDictionariesResponseBody';
import type { ListSiteDictionariesResponseBody } from '../models/ListSiteDictionariesResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DictionaryService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * ListSiteDictionaries
     * Index dictionaries
     * @returns ListSiteDictionariesResponseBody
     * @throws ApiError
     */
    public getDictionaries(): CancelablePromise<ListSiteDictionariesResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/dictionaries',
        });
    }
    /**
     * ListBlogViewsDictionaries
     * Blog view dictionary
     * @returns ListBlogViewsDictionariesResponseBody
     * @throws ApiError
     */
    public getDictionariesBlogViewsData({
        organizationIds,
    }: {
        /**
         * Array of the organization ids to fetch data for.
         */
        organizationIds: any,
    }): CancelablePromise<ListBlogViewsDictionariesResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/dictionaries/blog-views-data',
            query: {
                'organizationIds': organizationIds,
            },
        });
    }
    /**
     * GetDictionariesByCountry
     * @returns EmptyObject
     * @throws ApiError
     */
    public getDictionariesByCountry(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/dictionaries/by-country',
        });
    }
    /**
     * ListCalendarViewsDictionaries
     * Calendar view dictionary
     * @returns ListCalendarViewsDictionariesResponseBody
     * @throws ApiError
     */
    public getDictionariesCalendarViewsData({
        organizationIds,
    }: {
        /**
         * Array of the organization ids to fetch data for.
         */
        organizationIds: any,
    }): CancelablePromise<ListCalendarViewsDictionariesResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/dictionaries/calendar-views-data',
            query: {
                'organizationIds': organizationIds,
            },
        });
    }
}
