import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCalendarSearchContext } from '@/react/calendar/components/sidebar-search/CalendarSearchContext';
import { FilterType, SelectedCalendar } from '@/react/calendar/store/filters';
import {
  getSelectedCalendarsRich,
  getUnselectedCalendars,
} from '@/react/calendar/store/filters/Selectors';
import { toggleUsersAndRoomsCalenders } from '@/react/calendar/store/filters/Actions';
import { AppState } from '@/react/redux';

export type RenderCalendar = {
  calendar: SelectedCalendar;
  isInNormalList: boolean;
  isSelected: boolean;
  id: number;
};

export const useSelectableCalendars = () => {
  const [calendarIdsInOrder, setCalendarIdsInOrder] = useState<number[] | null>(
    null
  );
  const { isDuringSearch, lowerCaseSearchName } = useCalendarSearchContext();
  const selectedCalendars: SelectedCalendar[] = useSelector(
    getSelectedCalendarsRich,
    _.isEqual
  );
  const disorderedAndUnselectedCalendars: SelectedCalendar[] = useSelector(
    getUnselectedCalendars,
    _.isEqual
  );
  const selectedUsers = useSelector(
    (state: AppState) => state.calendar.filters.selectedUsers
  );
  const selectedResources = useSelector(
    (state: AppState) => state.calendar.filters.selectedResources
  );

  const selectedData = useMemo(
    () =>
      selectedCalendars.map((calendar) => ({
        calendar,
        isInNormalList: true,
        id: calendar.id,
        isSelected:
          calendar.filterType === FilterType.users
            ? selectedUsers[calendar.id]
            : selectedResources[calendar.id],
      })),
    [selectedCalendars, selectedResources, selectedUsers]
  );

  const unselectedData = useMemo(
    () =>
      disorderedAndUnselectedCalendars.map((calendar) => ({
        calendar,
        isInNormalList: false,
        id: calendar.id,
        isSelected:
          calendar.filterType === FilterType.users
            ? selectedUsers[calendar.id]
            : selectedResources[calendar.id],
      })),
    [disorderedAndUnselectedCalendars, selectedResources, selectedUsers]
  );

  useEffect(() => {
    if (isDuringSearch) {
      setCalendarIdsInOrder([
        ...selectedCalendars.map((calendar) => calendar.id),
        ...disorderedAndUnselectedCalendars.map((calendar) => calendar.id),
      ]);
    } else {
      setCalendarIdsInOrder(null);
    }
    // we explicitly want to run this effect only when isDuringSearch changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDuringSearch]);

  const calendarsToRender: RenderCalendar[] = useMemo(() => {
    if (calendarIdsInOrder) {
      return calendarIdsInOrder.map((id) => {
        const selectedCalendar = selectedData.find(
          (calendarData) => calendarData.calendar.id === id
        );
        const unselectedCalendar = unselectedData.find(
          (calendarData) => calendarData.calendar.id === id
        );
        return selectedCalendar || unselectedCalendar;
      });
    } else {
      return selectedData;
    }
  }, [calendarIdsInOrder, selectedData, unselectedData]);

  const visibleCalendars = useMemo(
    () =>
      calendarsToRender.filter((c) =>
        c.calendar.name?.toLowerCase().includes(lowerCaseSearchName)
      ),
    [calendarsToRender, lowerCaseSearchName]
  );

  const areAllSelected = useMemo(
    () =>
      visibleCalendars.length > 0 &&
      !visibleCalendars.some((c) => !c.isSelected)
        ? true
        : visibleCalendars.some((c) => c.isSelected)
          ? null
          : false,
    [visibleCalendars]
  );

  const dispatch = useDispatch();
  const toggleSelectAll = useCallback(() => {
    if (!isDuringSearch) {
      dispatch(toggleUsersAndRoomsCalenders({ shouldSelect: !areAllSelected }));
    } else {
      dispatch(
        toggleUsersAndRoomsCalenders({
          calendars: visibleCalendars.map((c) => c.calendar),
          shouldSelect: !areAllSelected,
        })
      );
    }
  }, [areAllSelected, visibleCalendars, dispatch, isDuringSearch]);

  return {
    calendarsToRender,
    areAllSelected,
    isDuringSearch,
    toggleSelectAll,
  };
};
