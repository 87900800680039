import { ConfigProvider } from 'antd';
import React from 'react';
import { ThemeProvider } from 'styled-components';

const colors = {
  lightBlue: '#008db6',
  lightGray: '#d4d4d5',
  lightGreen: '#27ae60',
  mediumGray: '#868686',
  darkGray: '#303030',
  highlight: '#fcf8e3',
  white: '#FFFFFF',
};

const borders = {
  boxShadow:
    'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
};

const theme = {
  colors,
  borders,
};

export default function CdrThemeProvider({ children }) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#008DB6',
          colorInfo: '#008DB6',
          borderRadius: 4,
          colorLink: '#008DB6',
          colorSuccess: '#7ab800',
          colorWarning: '#faad14',
          colorError: '#f5222d',
          fontSize: 14,
          fontSizeHeading1: Math.ceil(14 * 1.71),
          fontSizeHeading2: Math.ceil(14 * 1.42),
          fontSizeHeading3: Math.ceil(14 * 1.14),
          fontSizeHeading4: Math.ceil(14 * 1),
          // '@page-header-heading-title': '@heading-2-size',
          // '@btn-text-hover-bg': 'rgba(0, 0, 0, 0.12)', // Overwrites hover and focus to improve accessibility
          // '@progress-default-color': '#008DB6',
        },
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ConfigProvider>
  );
}
