import { Form } from 'antd';
import React from 'react';

import { I18nService } from '@/react/services/I18nService';
import cdApp from '@/react/config';
import { phoneNumber, required } from '@/react/shared/antd/validations/common';
import { CdPhoneInput } from '@/react/shared/components/CdPhoneInput';

export const PhoneInput = ({ name, label }) => {
  const country = cdApp.organization.countryIso2;

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        required(I18nService.getString('Phone number is required')),
        phoneNumber,
      ]}
      initialValue={{
        short: country,
      }}
      style={{ marginBottom: '10px' }}
    >
      <CdPhoneInput inline />
    </Form.Item>
  );
};
