/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CalendarPosterService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * CalendarPosterCreateFile
     * @returns EmptyObject
     * @throws ApiError
     */
    public postCalendarPosterFiles({
        calendarId,
        posterId,
        type,
        requestBody,
    }: {
        calendarId: number,
        posterId: number,
        type?: string,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/calendar/{calendarId}/poster/{posterId}/files',
            path: {
                'calendarId': calendarId,
                'posterId': posterId,
            },
            query: {
                'type': type,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
