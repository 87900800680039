import { Button, Card, Col, Row, Typography } from 'antd';
import React from 'react';

import { openConnectToAccountManagerModal } from './ConnectToAccountManagerModal';

import { I18nService } from '@/react/services/I18nService';
import { CdExtraWebsite } from '@/react/shared/components/Icons';

export const WebsiteCardBuyExtra = () => (
  <Card
    hoverable
    style={{ width: 270 }}
    bodyStyle={{ padding: '0px' }}
    cover={
      <div
        onClick={() => openConnectToAccountManagerModal()}
        style={{
          cursor: 'pointer',
          width: 270,
          height: 216,
          background: '#279c59',
          boxShadow:
            'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Row>
          <Col
            span={24}
            style={{
              textAlign: 'center',
            }}
          >
            <CdExtraWebsite
              size="3x"
              style={{ color: 'white', height: '80px' }}
            />
          </Col>
          <Col span={24}>
            <Row align="middle" justify="center">
              <Col span={24} style={{ textAlign: 'center' }}>
                <Typography.Title
                  level={5}
                  style={{ marginTop: '0px', color: 'white' }}
                >
                  {I18nService.getString('Get an extra website')}
                </Typography.Title>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    }
    actions={[
      <Button
        key="buy"
        type="text"
        onClick={() => openConnectToAccountManagerModal()}
      >
        {I18nService.getString('Buy an extra website')}
      </Button>,
    ]}
  />
);
