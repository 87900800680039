import React, { Suspense } from 'react';
import styled from 'styled-components';
import { Button, Space, Form, Input, Radio } from 'antd';

import { I18nService } from '@/react/services/I18nService';
import {
  CdSave,
  CdEyeIcon,
  CdPrivacyPolicy,
} from '@/react/shared/components/Icons';
import Redactor from '@/react/people/message-editor/shared/containers/Redactor';
import usePrivacyPolicy from '@/react/settings/hooks/usePrivacyPolicy';
import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';

enum ContentType {
  BodyContent = 'bodyContent',
  LinkContent = 'linkContent',
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 24px 16px 16px;
  background-color: #fff;
`;
export const ContentTypeSubtitle = styled.p`
  color: gray;
  text-align: justify;
  margin-top: -16px;
`;
export const Header = styled.p`
  font-size: 14px;
`;
const Shadowbox = styled(Space)`
  &&&& {
    max-width: 800px;
    background-color: #dcf0fa;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 8px 16px;
    color: #3481a8;
    margin-bottom: 16px;
  }
`;
const PrivacyPolicy = () => {
  const { form, initialValues, canViewPolicy, viewCurrentPolicy, onSubmit } =
    usePrivacyPolicy();

  const getPageHeaderExtra = () => (
    <Space>
      <Button
        icon={<CdEyeIcon />}
        size="large"
        disabled={!canViewPolicy}
        onClick={viewCurrentPolicy}
      >
        {I18nService.getString('View')}
      </Button>
      <Button type="primary" size="large" icon={<CdSave />} onClick={onSubmit}>
        {I18nService.getString('Save')}
      </Button>
    </Space>
  );

  return (
    <Container>
      <CdPage
        pageHeaderProps={{
          title: I18nService.getString('Privacy policy'),
          extra: getPageHeaderExtra(),
        }}
      >
        <Content>
          <Shadowbox>
            <CdPrivacyPolicy size="2x" />
            <div>
              {I18nService.getString(
                'According to GDPR, you are obliged to have a Privacy Policy available on your website and other relevant places. It should explain to readers how you store and process data along with your legal justification for collecting and storing data.'
              )}
            </div>
          </Shadowbox>
          <Form
            form={form}
            initialValues={initialValues}
            layout="vertical"
            style={{
              maxWidth: '800px',
              width: '100%',
            }}
          >
            <Form.Item
              name="contentType"
              label={I18nService.getString(
                'How will you create this privacy policy?'
              )}
            >
              <Radio.Group>
                <Radio value={ContentType.BodyContent}>
                  <Header>
                    {I18nService.getString(
                      `Provide privacy policy statement as text`
                    )}
                  </Header>
                  <ContentTypeSubtitle>
                    {I18nService.getString(
                      `You can provide the full statement here or use our template`
                    )}
                  </ContentTypeSubtitle>
                </Radio>
                <Radio value="linkContent">
                  <Header>
                    {I18nService.getString('Provide consent statement as link')}
                  </Header>
                  <ContentTypeSubtitle>
                    {I18nService.getString(
                      '..or you can provide a link to your current privacy policy statement'
                    )}
                  </ContentTypeSubtitle>
                </Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) =>
                getFieldValue('contentType') === ContentType.BodyContent ? (
                  <Form.Item
                    name="privacyPolicyText"
                    label={I18nService.getString(
                      'Full privacy policy statement'
                    )}
                    style={{
                      width: '100%',
                    }}
                    rules={[
                      {
                        required: true,
                        validator(_, value: any) {
                          const textWithoutHtml = (value || '')
                            .replace(/(<([^>]+)>)/gi, '')
                            .replace(/\n/g, '')
                            .trim();
                          if (textWithoutHtml.length < 100) {
                            return Promise.reject(
                              new Error(
                                I18nService.getString(
                                  'Please provide a privacy policy statement with at least 100 characters'
                                )
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Redactor
                      imagePluginEnabled={false}
                      messagePlaceHolderEnabled={false}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    style={{ width: '100%' }}
                    name="privacyPolicy"
                    label={I18nService.getString(
                      'Alternatively you can provide link to your privacy policy statement page'
                    )}
                    rules={[
                      {
                        required: true,
                        message: I18nService.getString(
                          'Please provide a link to your privacy policy statement'
                        ),
                      },
                      {
                        type: 'url',
                        message: I18nService.getString(
                          'Please provide a valid url'
                        ),
                      },
                    ]}
                  >
                    <Input placeholder="https://...." />
                  </Form.Item>
                )
              }
            </Form.Item>
          </Form>
        </Content>
      </CdPage>
    </Container>
  );
};

export default function PrivacyPolicyHOC() {
  return (
    <Suspense fallback={<CdrPageLoader />}>
      <PrivacyPolicy />
    </Suspense>
  );
}
