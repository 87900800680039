import { InputNumber, Row, Space } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { FormDuration } from '../types/availability-taxonomy.types';
import { I18nService } from '../../../services/I18nService';

const StypedInputNumber = styled(InputNumber)`
    width: 100px;
`;

type DurationPickerProps = {
  showDays?: boolean;
  value?: FormDuration;
  onChange?: (value: FormDuration) => void;
};

export const DurationPicker = (props: DurationPickerProps) => (
  <Row>
    <Space>
      {props.showDays && (
        <StypedInputNumber
          addonAfter={I18nService.getString(
            'days',
            null,
            'Short Name of unit (Days). Shown at the end of an input field'
          )}
          precision={0}
          min={0}
          max={366}
          value={props.value?.days}
          onChange={(days: number) =>
            props.onChange({ ...props.value, days: days || 0 })
          }
        />
      )}
      <StypedInputNumber
        addonAfter={I18nService.getString(
          'h',
          null,
          'Short Name of unit (Hours). Shown at the end of an input field'
        )}
        precision={0}
        min={0}
        max={144}
        value={props.value?.hours}
        onChange={(hours: number) =>
          props.onChange({ ...props.value, hours: hours || 0 })
        }
      />
      <StypedInputNumber
        addonAfter={I18nService.getString(
          'min',
          null,
          'Short Name of unit (Minutes). Shown at the end of an input field'
        )}
        precision={0}
        step={5}
        min={0}
        max={59}
        width={100}
        value={props.value?.minutes}
        onChange={(minutes: number) =>
          props.onChange({ ...props.value, minutes: minutes || 0 })
        }
      />
    </Space>
  </Row>
);
