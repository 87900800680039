import { Dropdown, Button, Space } from 'antd';
import React from 'react';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { DropdownProps } from 'antd/lib';

import { CdAngleDown } from '../Icons';

export interface CdDropdownProps extends Pick<DropdownProps, 'placement'> {
  items: ItemType[];
  size?: SizeType;
  children?: React.ReactNode;
}

export const CdDropdown = ({
  items,
  size,
  children,
  ...props
}: CdDropdownProps) => (
  <Dropdown menu={{ items }} {...props}>
    <Button size={size}>
      <Space>
        {children}
        <CdAngleDown />
      </Space>
    </Button>
  </Dropdown>
);
