import React from 'react';
import styled from 'styled-components';
import { Col, Form, Row, Select, Typography } from 'antd';

import { I18nService } from '../../../../services/I18nService';

import { TIME_OPTIONS } from './PrepAndCleanUpTimeOptions';

const PrepAndCleanUpWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PreparationAndCleanUpTime = (props: { disabled?: boolean }) => (
  <PrepAndCleanUpWrapper>
    <Row gutter={4} style={{ marginTop: '8px' }}>
      <Col span={12}>
        <Form.Item name={['resourcesLocation', 'prepTimeMinutes']} noStyle>
          <Select
            data-testid="prep-time"
            placeholder={I18nService.getString('Set preparation time...')}
            allowClear
            options={TIME_OPTIONS()}
            style={{ marginRight: '8px', width: '100%' }}
            disabled={props.disabled}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name={['resourcesLocation', 'cleanupTimeMinutes']} noStyle>
          <Select
            data-testid="cleanup-time"
            placeholder={I18nService.getString('Set clean up time...')}
            allowClear
            options={TIME_OPTIONS()}
            disabled={props.disabled}
          />
        </Form.Item>
      </Col>
    </Row>
    <Typography.Text type="secondary">
      {I18nService.getString(
        'Prevent others from booking the resources before and after the event.'
      )}
    </Typography.Text>
  </PrepAndCleanUpWrapper>
);

export default PreparationAndCleanUpTime;
