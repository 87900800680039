import { Button, Divider, Select, Space } from 'antd';
import React, { useMemo, useState } from 'react';

import { I18nService } from '@/react/services/I18nService';
import {
  getPlaceHolderText,
  getTagItemText,
  renderColor,
} from '@/react/calendar/reports/common/Helper';
import { CdUserAvatar } from '@/react/user/components/cd-user-avatar/CdUserAvatar';

export const ReportsFilterSelect = (props: {
  value?: any;
  onChange?: (value: any) => void;
  options?: {
    label: string;
    value: string;
    color?: number;
    picture?: string;
  }[];
  type?: string;
  placeHolderText?: string;
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [allSelected, setAllSelected] = useState<boolean>(
    props?.value.length === props.options.length
  );

  const filteredOptions = useMemo(() => {
    const searchedArray = !searchValue
      ? props.options
      : props.options?.filter((option) =>
          option.label.toLowerCase().includes(searchValue.toLowerCase())
        );
    return searchedArray;
  }, [searchValue, props.options]);

  const onSelectHandler = (value: any) => {
    setSearchValue(undefined);
    props.onChange(value);
  };

  const selectAllHandled = () => {
    setAllSelected(!allSelected);
    const allValues = filteredOptions.map((option) => option.value);
    if (allSelected) {
      props.onChange(allValues);
    } else {
      props.onChange([]);
    }
  };

  const onSearchHandler = (value: string) => {
    setSearchValue(value);
  };

  return (
    <Select
      mode="multiple"
      style={{ width: '100%' }}
      placeholder={getPlaceHolderText(props.type)}
      dropdownRender={(menu) => (
        <>
          <Button
            type="link"
            style={{ width: '100%', display: 'flex', justifyContent: 'start' }}
            onClick={selectAllHandled}
          >
            {allSelected
              ? I18nService.getString('Select all')
              : I18nService.getString('Reset')}
          </Button>
          <Divider style={{ margin: '8px 0' }} />
          {menu}
        </>
      )}
      filterOption={false}
      popupMatchSelectWidth={false}
      dropdownStyle={{ maxWidth: 350, overflow: 'auto' }}
      value={props?.value}
      allowClear
      onSearch={onSearchHandler}
      onChange={onSelectHandler}
      maxTagCount={0}
      maxTagPlaceholder={(omittedValues) => {
        const itemCount = omittedValues.length;
        return getTagItemText({ type: props.type, count: itemCount });
      }}
      optionFilterProp="children"
    >
      {filteredOptions &&
        filteredOptions.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            <Space direction="horizontal">
              {(props.type === 'user' || props.type === 'group') && (
                <CdUserAvatar picture={option.picture} size={30} />
              )}
              {(props.type === 'event' || props.type === 'absence') &&
                renderColor({ color: option.color })}
              {option.label}
            </Space>
          </Select.Option>
        ))}
    </Select>
  );
};
