import { react2angular } from 'react18-react2angular';
import angular from 'angular';
import createSharedContext from 'react2angular-shared-context';

import { ReactModalBridge } from '@/react/angular/ReactModalBridge';
import { RootProvider } from '@/react/shared/components/RootProvider';

// Create shared context instance

const sharedContext = createSharedContext(RootProvider);

angular
  .module('cdApp.shared')
  .component('sharedContextReact', react2angular(sharedContext.component));

angular
  .module('cdApp.shared')
  .component(
    'cdrReactModalBridge',
    react2angular(sharedContext.use(ReactModalBridge), [], [])
  );

export default sharedContext;
