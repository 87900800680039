import { orderBy, toLower } from 'lodash';

import { mainApi } from '@/react/api';
import cdApp from '@/react/config';

export const orderUsers = <T extends { name: string; email: string }>(
  users: T[]
): T[] => orderBy(users, (user) => toLower(user.name || user.email));

export const getStripeAccountSession = async () => {
  const response = await mainApi.get<string>(
    `/p/organizations/${cdApp.organization.id}/stripe/session`
  );
  if (response.ok) {
    return response.data;
  }
  throw response.data;
};
