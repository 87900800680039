import styled from 'styled-components';
import { Space, Switch, Typography } from 'antd';
import React from 'react';

import { NotSaved } from '../NotSaved';

import { I18nService } from '@/react/services/I18nService';

const StyledSpace = styled(Space)`
  &&&& .ant-space-item:nth-child(2) {
    margin-left: 8px;
  }
`;
export const SuggestedWidgetSwitch = ({
  settings,
  value,
  onChange,
}: {
  settings: any;
  value?: boolean;
  onChange?: (showSuggestedEventsOrBlogs: boolean) => void;
}) => (
  <StyledSpace
    direction="horizontal"
    style={{ display: 'flex', alignItems: 'flex-start' }}
  >
    <Switch checked={value} onClick={(v) => onChange(v)} size="small" />

    <Typography.Text strong>
      {I18nService.getString('Show suggested content on event and blog pages')}
    </Typography.Text>
    <NotSaved name="showSuggestedEventsOrBlogs" initialValues={settings} />
  </StyledSpace>
);
