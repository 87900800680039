import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import { EditBasicInformationButton } from '@/react/people/components/people-contacts-profile-modals/EditBasicInformationButton';

angular
  .module('cdApp.people')
  .component(
    'cdrEditBasicInformationButton',
    react2angular(
      sharedContext.use(EditBasicInformationButton),
      ['person', 'createMode', 'allowCreate', 'executeFromPicture'],
      []
    )
  );
