/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostUserRequestBody = {
    email: string;
    firstName?: string;
    lastName?: string;
    status?: PostUserRequestBody.status;
    roles?: Array<number>;
    groupIds?: Array<number>;
    timeRegistrationEnabled?: boolean;
    initialTimeBalance?: number;
    safeguarding?: {
        type: PostUserRequestBody.type;
        workAndResponsibilities: string;
        parishIds?: Array<number>;
        safeguardRequirement: PostUserRequestBody.safeguardRequirement;
    };
    address?: {
        street?: string | null;
        postCode?: string | null;
        city?: string | null;
        countryIso2?: string | null;
    };
};
export namespace PostUserRequestBody {
    export enum status {
        ACTIVE = 'active',
        BLOCKED = 'blocked',
        CREATED_WITHOUT_LOGIN = 'createdWithoutLogin',
    }
    export enum type {
        EMPLOYEE = 'employee',
        VOLUNTEER = 'volunteer',
    }
    export enum safeguardRequirement {
        NOT_REQUIRED = 'not required',
        VERIFIED_BY_THIRD_PARTY = 'verified by third party',
        MINIMUM = 'minimum',
        BASIC = 'basic',
        BASIC_PLUS = 'basic plus',
        EXTENDED = 'extended',
        EXTENDED_PLUS = 'extended plus',
    }
}

