import React, { useMemo, useEffect, useState } from 'react';
import { debounce, find, join } from 'lodash';
import { Space, Avatar, Select, Spin } from 'antd';
import { useRecoilValue } from 'recoil';

import { UserChurchesWithPermissionQuery } from '../../../../../user/store/permissions';
import {
  CdFilterIcon,
  CdNewsletterListIcon,
  CdParticipantList,
  CdUserAvatar,
} from '../../../../../shared/components/Icons';

import { I18nService } from '@/react/services/I18nService';
import useRecipient from '@/react/people/hooks/useRecipient';

interface RecipientSelectorProps {
  onChange?: any;
  value?: string[];
  disabled: boolean;
}
const RecipientSelector = ({
  onChange,
  value,
  disabled,
}: RecipientSelectorProps) => {
  const churches = useRecoilValue(
    UserChurchesWithPermissionQuery({
      permissionContext: 'people',
      permissionType: 'communicate',
    })
  );
  const { messageRecipients, messageRecipientsQuery } = useRecipient(value);
  const [loading, setLoading] = useState<boolean>(false);
  const filters = messageRecipients?.segments?.filter(
    (item) => item.type === 'filter'
  );
  const newsletters = messageRecipients?.segments?.filter(
    (item) => item.type === 'newsletter'
  );
  const participants = messageRecipients?.segments?.filter(
    (item) => item.type === 'participant'
  );
  const options = messageRecipients && [
    {
      key: 'participants',
      label: I18nService.getString('Participant Lists').toLocaleUpperCase(),
      options:
        participants?.map((item) => ({
          label: (
            <Space>
              <CdParticipantList />
              {item.name}
            </Space>
          ),
          value: `segment-${item.id}`,
        })) || [],
    },
    {
      key: 'newsletterlists',
      label: I18nService.getString('Newsletter Lists').toLocaleUpperCase(),
      options:
        newsletters?.map((item) => ({
          label: (
            <Space>
              <CdNewsletterListIcon />
              {item.name}
            </Space>
          ),
          value: `segment-${item.id}`,
        })) || [],
    },
    {
      key: 'lists',
      label: I18nService.getString('Filters').toLocaleUpperCase(),
      options:
        filters?.map((item) => ({
          label: (
            <Space>
              <CdFilterIcon />
              {item.name}
            </Space>
          ),
          value: `segment-${item.id}`,
        })) || [],
    },
    {
      key: 'contacts',
      label: I18nService.getString('Contacts').toLocaleUpperCase(),
      options:
        messageRecipients?.people?.map((recipient) => {
          const firstName = find(recipient.fields, {
            property: 'firstName',
          }).value;
          const lastName = find(recipient.fields, {
            property: 'lastName',
          }).value;
          const email = find(recipient.fields, { property: 'email' }).value;
          const name = join([firstName, lastName], ' ');

          return {
            label: (
              <Space>
                <Avatar
                  src={recipient.picture}
                  icon={<CdUserAvatar />}
                  size={16}
                />
                {name.length > 1 ? name : email}
              </Space>
            ),
            value: `person-${recipient.id}`,
          };
        }) || [],
    },
  ];

  const debouncedSearch = useMemo(
    () =>
      debounce(
        (search: string) =>
          messageRecipientsQuery({
            churchIds: churches.map((item) => item.id),
            search,
            toValue: value,
          }),
        500
      ),
    [churches, messageRecipientsQuery, value]
  );

  useEffect(() => {
    async function firstFetch() {
      setLoading(true);
      await messageRecipientsQuery({
        churchIds: churches.map((item) => item.id),
        toValue: value,
      });
      setLoading(false);
    }
    firstFetch();
    return () => {
      debouncedSearch.cancel();
    };
  }, [churches, debouncedSearch, messageRecipientsQuery, value]);

  const onValueSelected = (value, option) => {
    messageRecipientsQuery({
      churchIds: churches.map((item) => item.id),
      toValue: value,
    });
    onChange(value, option);
  };

  if (!onChange && !value) {
    return null;
  }

  return (
    <Spin spinning={loading}>
      <Select
        mode="multiple"
        allowClear
        showSearch
        placeholder={I18nService.getString(
          'Search for lists or recipients in People'
        )}
        onSearch={debouncedSearch}
        options={options}
        optionLabelProp="label"
        filterOption={false}
        onChange={onValueSelected}
        value={value}
        notFoundContent={
          !messageRecipients ? (
            <Spin size="small" />
          ) : (
            I18nService.getString(
              'No contacts or lists found. Try changing your search.'
            )
          )
        }
        disabled={disabled}
      />
    </Spin>
  );
};

export default RecipientSelector;
