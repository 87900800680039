import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { I18nService } from '../../services/I18nService';
import {
  AbsenceSelectors,
  AbsencesActions,
} from '../store/absences/absencesSlice';

import { SearchableFilterItem } from '@/react/calendar/components/SearchableFilterItem';
import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';

interface DisplayFilterItem {
  name: string;
  groupId: number;
  isDisabled: boolean;
}

export const getClassName = (isSelected: boolean, color: number): string =>
  `bullet color-${color || 0} ${isSelected ? 'bullet-full' : 'bullet-empty'}`;

const AbsenceItem = (props: DisplayFilterItem) => {
  const { name, groupId, isDisabled } = props;
  const dispatch = useDispatch();
  const isSelected = useSelector(
    AbsenceSelectors.isGroupAbsenceActive(groupId)
  );
  const onAbsenceClick = useCallback(
    () => !isDisabled && dispatch(AbsencesActions.toggleGroupAbsence(groupId)),
    [isDisabled, groupId, dispatch]
  );
  const content = (
    <SearchableFilterItem
      isDisabled={isDisabled}
      isSelected={isSelected}
      onLabelClick={onAbsenceClick}
      name={name}
      color="absences"
    />
  );
  if (isDisabled) {
    return (
      <CdTooltip
        title={I18nService.getString(
          'This calendar cannot be enabled for this view'
        )}
      >
        {content}
      </CdTooltip>
    );
  }
  return content;
};

export default AbsenceItem;
