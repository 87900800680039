import moment from 'moment';

export const transformAPIAvailabilityToFormData = (apiData: any) =>
  apiData
    ? {
        date: {
          startDate: moment(apiData.startDate),
          endDate: moment(apiData.endDate),
          allDay: apiData.allDay,
          rrule: apiData.rrule || null,
        },
        mainCategory: apiData.mainCategory,
        user: apiData.users ? Object.keys(apiData.users).map(Number)[0] : null,
        bookingOptionIds: apiData.bookingOptions?.map((option) => option.id),
      }
    : {};
