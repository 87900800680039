import { useCallback } from 'react';
import { Form } from 'antd';

import { TagInterface } from '@/react/people/types/tag.type';
import { useCreateOrEditTags } from '@/react/shared/hooks/useTags';

export const useCreateTag = ({
  data,
  isEdit,
}: { data?: TagInterface; isEdit?: boolean }) => {
  const [createTagForm] = Form.useForm();

  const { createOrEditTag } = useCreateOrEditTags();

  const onCreateOrEditTag = useCallback(async () => {
    await createTagForm.validateFields().then(async (value) => {
      const names = value.names.split(',');
      names.map((name: string) => {
        createOrEditTag({ name, data, isEdit });
      });
    });
  }, [createTagForm, createOrEditTag, data, isEdit]);

  return {
    createTagForm,
    onCreateOrEditTag,
  };
};
