import React, { FunctionComponent } from 'react';
import { sagaModal } from 'redux-saga-modal';
import { Modal } from 'antd';

import { EventType, FEvent } from '../models/calendar';
import MODAL_TYPES from '../sagas/ModalTypes';
import { I18nService } from '../../services/I18nService';
import { translateEventType } from '../helpers/event.helpers';

interface UpdateEventModalProps {
  isOpen: boolean;
  hide: () => void;
  submit: () => void;
  oldEvent: FEvent;
  newEvent: FEvent;
}

const UpdateEventConfirmation: FunctionComponent<UpdateEventModalProps> = ({
  isOpen,
  submit,
  hide,
  oldEvent,
}) =>
  oldEvent ? (
    <Modal
      title={
        oldEvent.type === 'event'
          ? I18nService.getString('Update event')
          : oldEvent.type === 'absence'
            ? I18nService.getString('Update absence')
            : I18nService.getString('Update availability')
      }
      open={isOpen}
      okText={I18nService.getString('Update')}
      onOk={submit}
      onCancel={hide}
    >
      {I18nService.getString('Are you sure you want to update {{ title }}?', {
        title:
          oldEvent.type === EventType.Availability
            ? translateEventType(EventType.Availability).toLowerCase()
            : oldEvent.title,
      })}
    </Modal>
  ) : null;

export default sagaModal({
  // A unique name for the modal
  name: MODAL_TYPES.EVENT_UPDATE_CONFIRM,
})(UpdateEventConfirmation);
