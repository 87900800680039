/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChurchscreenGalleryRetrieveResponseBody } from '../models/ChurchscreenGalleryRetrieveResponseBody';
import type { EmptyObject } from '../models/EmptyObject';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class GalleriesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetAllGalleries
     * INDEX
     * @returns EmptyObject
     * @throws ApiError
     */
    public getGalleries(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/galleries',
        });
    }
    /**
     * CreateGallery
     * CREATE
     * @returns EmptyObject
     * @throws ApiError
     */
    public postGalleries({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/galleries',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetGallery
     * RETRIEVE
     * @returns EmptyObject
     * @throws ApiError
     */
    public getGalleries1({
        limit,
    }: {
        /**
         * Number of images to select per gallery
         */
        limit: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/galleries/{galleryId}',
            query: {
                'limit': limit,
            },
        });
    }
    /**
     * UpdateGallery
     * UPDATE
     * @returns EmptyObject
     * @throws ApiError
     */
    public putGalleries({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/galleries/{galleryId}',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * UpdateGallery
     * DELETE
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteGalleries(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/galleries/{galleryId}',
        });
    }
    /**
     * RemoveImageFromGallery
     * Remove Image
     * @returns EmptyObject
     * @throws ApiError
     */
    public postGalleriesRemoveImage({
        fileId,
        requestBody,
    }: {
        /**
         * The image id to remove
         */
        fileId: any,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/galleries/{galleryId}/remove/image',
            query: {
                'fileId': fileId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * ChurchscreenGalleryRetrieve
     * Retrieve
     * @returns ChurchscreenGalleryRetrieveResponseBody Success-Response: Success
     * @throws ApiError
     */
    public getGalleries2({
        partnerToken,
        imageFormat,
    }: {
        /**
         * Partner API identifier.
         */
        partnerToken: any,
        /**
         * The format of the images needs to be fetched (comma separated).
         */
        imageFormat: any,
    }): CancelablePromise<ChurchscreenGalleryRetrieveResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/galleries/{id}',
            query: {
                'partnerToken': partnerToken,
                'imageFormat': imageFormat,
            },
        });
    }
}
