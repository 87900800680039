import { Col, Skeleton } from 'antd';
import React, { useMemo } from 'react';

import { I18nService } from '../../../services/I18nService';
import { CdTooltip } from '../../../shared/components/cd-tooltip/CdTooltip';
import { CdCalendarFilterCollapse } from '../../containers/calendar-filter/CdCalendarFilterCollapse';
import FilterList from '../FilterList';
import { FilterSectionHeader } from '../FilterSectionHeader';

import { SearchableFilterItem } from '@/react/calendar/components/SearchableFilterItem';
import { useCalendarSearchContext } from '@/react/calendar/components/sidebar-search/CalendarSearchContext';

export interface FilterSectionProps<
  T extends { id: number; name: string } = any,
> {
  title: string;
  isLoading: boolean;
  isDisabled: boolean;
  options?: T[];
  value?: Record<number, boolean>;
  onChange: (values: Record<number, boolean>) => void;
}

export const FilterSection = (props: FilterSectionProps) => {
  const { isDuringSearch, lowerCaseSearchName } = useCalendarSearchContext();

  const visibleOptions: { id: number; name: string }[] = useMemo(() => {
    if (!isDuringSearch) {
      return props.options ?? [];
    } else {
      return (
        props.options?.filter((option) =>
          option.name.toLowerCase().includes(lowerCaseSearchName)
        ) ?? []
      );
    }
  }, [isDuringSearch, lowerCaseSearchName, props.options]);

  const visibleSelectedIds = useMemo(
    () =>
      new Set(
        visibleOptions.filter((op) => props.value[op.id]).map((o) => o.id)
      ),
    [props.value, visibleOptions]
  );

  const isAllSelected =
    visibleOptions.length > 0 &&
    visibleOptions.length === visibleSelectedIds.size;
  const isSomeSelected = visibleSelectedIds.size > 0;

  const toggleOne = (id: number, check: boolean) =>
    props.onChange({
      ...props.value,
      [id]: check,
    });

  const onSelectAll = () =>
    props.onChange(
      isAllSelected
        ? Object.fromEntries(
            Object.entries(props.value).filter(
              ([id]) => !visibleSelectedIds.has(Number(id))
            )
          )
        : visibleOptions
            .map((option) => option.id)
            .reduce((acc, id) => ({ ...acc, [id]: true }), {})
    );

  return (
    <CdCalendarFilterCollapse
      $transparentBackground
      items={[
        {
          key: 'selectAll',
          label: !props.isLoading ? (
            <FilterSectionHeader
              name={props.title}
              isAllSelected={
                isSomeSelected && !isAllSelected ? null : isAllSelected
              }
              onClick={onSelectAll}
              isDisabled={props.isDisabled}
              type="availability"
            />
          ) : (
            <Skeleton
              title={false}
              paragraph={{ rows: 1, width: '100%' }}
              loading
              active
              style={{ padding: '0 16px' }}
            />
          ),
          children: !props.isLoading ? (
            <Col>
              {props.options?.length > 0 && (
                <Col>
                  <FilterList>
                    {props.options.map((option) => (
                      <CdTooltip
                        key={option.id}
                        title={
                          props.isDisabled &&
                          I18nService.getString(
                            'This calendar cannot be enabled for this view'
                          )
                        }
                      >
                        <SearchableFilterItem
                          isDisabled={props.isDisabled}
                          onLabelClick={() =>
                            toggleOne(option.id, !props.value[option.id])
                          }
                          color="availabilites"
                          isSelected={props.value[option.id]}
                          name={option.name}
                        />
                      </CdTooltip>
                    ))}
                  </FilterList>
                </Col>
              )}
            </Col>
          ) : (
            <Skeleton
              title={false}
              paragraph={{ rows: 4, width: '100%' }}
              loading
              active
              style={{ padding: '8px 16px' }}
            />
          ),
        },
      ]}
    />
  );
};
