import { capitalize } from 'lodash';

import { mainApi } from '../../../api';

import { I18nService } from '@/react/services/I18nService';
import {
  FormBookingHours,
  ScheduleItem,
} from '@/react/settings/booking-pages/types/availability-taxonomy.types';
import { validationRule } from '@/react/shared/antd/validations/validation-rule';

export const checkForDuplicateTaxonomyAlias = async (alias: string) => {
  const response = await mainApi.post<{ isAvailable: boolean }>(
    '/taxonomies/alias/check',
    { alias }
  );

  if (response.ok && !response.data.isAvailable) {
    throw new Error(
      I18nService.getString(
        'This alias is already in use on another booking page.'
      )
    );
  }

  if (!response.ok) {
    throw new Error(
      I18nService.getString('An error occurred while validating the alias.')
    );
  }
};

export const hasOverlappingTime = validationRule<FormBookingHours>((value) => {
  const validationErrors = Object.entries(value)
    .map(([k, v]) => {
      if (v.enabled) {
        return doesIntervalsOverlap(v.data, k);
      }
    })
    .filter(Boolean);
  const weekdaysWithOverlappingTime = validationErrors.filter(
    (e) => e.type === 'overlap'
  );
  const weekdaysWithSameTime = validationErrors.filter(
    (e) => e.type === 'same'
  );
  const weekdaysWithStartHigher = validationErrors.filter(
    (e) => e.type === 'start-higher'
  );

  const getWeekdays = (weekdays: { weekday: string }[]) =>
    weekdays
      // it's ok to have a variable inside gettextCatalog here
      // because we are sure that weekdays are translated
      .map((weekdayError) => I18nService.getString(weekdayError.weekday))
      .join(', ');

  if (weekdaysWithOverlappingTime.length > 0) {
    throw new Error(
      I18nService.getString(
        '{{ weekdays }} share overlapping time',
        { weekdays: capitalize(getWeekdays(weekdaysWithOverlappingTime)) },
        'Validation error indicating that specific weekday(s) have overlapping day e.g: Monday, Tuesday has overlapping time'
      )
    );
  }
  if (weekdaysWithSameTime.length > 0) {
    throw new Error(
      I18nService.getString(
        '{{ weekdays }} have the same start and end time',
        { weekdays: capitalize(getWeekdays(weekdaysWithSameTime)) },
        'Validation error indicating that specific weekday(s) have the same start and end time e.g: Monday, Tuesday have the same start and end time'
      )
    );
  }
  if (weekdaysWithStartHigher.length > 0) {
    throw new Error(
      I18nService.getString(
        '{{ weekdays }} have a start time that is later than the end time',
        { weekdays: capitalize(getWeekdays(weekdaysWithStartHigher)) },
        'Validation error indicating that specific weekday(s) have a start time that is later than the end time e.g: Monday, Tuesday have a start time that is later than the end time'
      )
    );
  }
});

export const hasAtLeastOneTimeInterval = validationRule<FormBookingHours>(
  (value) => {
    const values = Object.values(value);

    if (
      values.every((v) => !v.enabled) ||
      values.every((v) => !v.data.length)
    ) {
      throw new Error(
        I18nService.getString('At least one week day must have a time interval')
      );
    }
  }
);

export const doesIntervalsOverlap = (
  intervals: ScheduleItem[],
  weekday: string
) => {
  for (let i = 0; i < intervals.length; i++) {
    for (let j = i + 1; j < intervals.length; j++) {
      if (
        intervals[i].start < intervals[j].end &&
        intervals[i].end > intervals[j].start
      ) {
        return {
          weekday,
          type: 'overlap',
          intervals: [intervals[i], intervals[j]],
        };
      }
    }
    if (intervals[i].start === intervals[i].end) {
      return { weekday, type: 'same', intervals: [intervals[i]] };
    }
    if (intervals[i].start > intervals[i].end) {
      return { weekday, type: 'start-higher', intervals: [intervals[i]] };
    }
  }
};
