/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostCalendarPermissionsRequestBody = {
    id?: number;
    visibility?: PostCalendarPermissionsRequestBody.visibility;
    churches?: Array<{
        id?: number;
    }>;
    type?: PostCalendarPermissionsRequestBody.type;
    groups?: Array<{
        id?: number;
    }>;
};
export namespace PostCalendarPermissionsRequestBody {
    export enum visibility {
        PRIVATE = 'private',
        INTERNAL = 'internal',
        PUBLIC = 'public',
    }
    export enum type {
        ABSENCE = 'absence',
        EVENT = 'event',
    }
}

