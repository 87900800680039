import { useState, useMemo } from 'react';
import { isEqual, orderBy } from 'lodash';

import { defaultOrderFunctions } from '../cd-table/useCdTable';

import { CdClientSideQuery, CdClientSideTableApiSearchParam } from './types';

import { OrderType, TableOrdering } from '@/react/shared/models/table';

export interface useCdClientSideTableProps<T> {
  defaultPageSize?: number;
  filters?: ((item: T, index: number) => boolean)[];
  orderFunctions?: OrderFunctions<T>;
  extraData?: CdClientSideTableApiSearchParam;
  querySource: CdClientSideQuery<T>;
}

export interface OrderFunctions<T> {
  [orderType: string]: (order: TableOrdering, data: T[]) => T[];
}

const orderFunc = <T>(
  order: TableOrdering,
  orderFunctions: OrderFunctions<T>,
  data: T[]
): T[] => {
  if (!order) {
    return data;
  }

  const combinedOrderFunctions: OrderFunctions<T> = {
    ...defaultOrderFunctions,
    ...orderFunctions,
  };
  if (combinedOrderFunctions[order.orderType]) {
    return combinedOrderFunctions[order.orderType](order, data);
  } else {
    const orderByField = order?.orderBy || 'name';
    if (
      data &&
      data[0] &&
      (typeof data[0][orderByField] === 'string' ||
        data[0][orderByField] instanceof String)
    ) {
      return combinedOrderFunctions[OrderType.STRING](order, data);
    } else {
      return orderBy(data, orderByField, [
        (order?.orderDirection?.toLowerCase() as 'asc' | 'desc') || 'asc',
      ]);
    }
  }
};

export function useCdClientSideTable<T>({
  querySource,
  defaultPageSize = 30,
  filters,
  orderFunctions,
  extraData,
}: useCdClientSideTableProps<T>) {
  const [order, setOrder] = useState<TableOrdering>(null);
  const [pageInfo, setPageInfo] = useState<{ pageSize; pageNumber }>({
    pageSize: defaultPageSize,
    pageNumber: 1,
  });
  const queryResult = querySource({
    extraData,
  });
  const data = useMemo(() => {
    let filteredData = queryResult?.data?.items?.filter((item, idx) =>
      filters ? filters.some((filter) => filter(item, idx)) : true
    );
    if (order) {
      filteredData = orderFunc<T>(order, orderFunctions, filteredData);
    }
    const startPosition = (pageInfo.pageNumber - 1) * defaultPageSize;
    // in case that the last page is empty, we need to go back one page
    if (
      startPosition >= filteredData?.length &&
      startPosition >= defaultPageSize // don't go back if we are on the first page
    ) {
      setPageInfo({
        pageNumber: pageInfo.pageNumber - 1,
        pageSize: defaultPageSize,
      });
    }
    return {
      items: filteredData?.slice(
        startPosition,
        startPosition + defaultPageSize
      ),
      total: filteredData?.length,
      access: queryResult?.access,
    };
  }, [
    queryResult.data?.items,
    queryResult.access,
    order,
    pageInfo.pageNumber,
    defaultPageSize,
    filters,
    orderFunctions,
  ]);

  const handleTableChange = (
    pageNumber: number,
    pageSize: number = defaultPageSize,
    ordering?: TableOrdering
  ) => {
    if (
      pageNumber > 0 &&
      (pageSize !== pageInfo.pageSize || pageNumber !== pageInfo.pageNumber)
    ) {
      setPageInfo({ pageSize, pageNumber });
    }
    if (!isEqual(order, ordering)) {
      if (ordering) {
        setOrder(ordering);
      } else {
        setOrder(null);
      }
    }
  };

  return {
    data: data,
    handleTableChange,
    isLoading: queryResult.isLoading,
    order,
  };
}
