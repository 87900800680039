import { Button } from 'antd';
import React from 'react';

import { I18nService } from '../../services/I18nService';
import { OrderOfServiceSearchParams } from '../types/orderOfServiceTypes';

import { CdOrderOfService } from '@/react/shared/components/Icons';
import { useTemplates } from '@/react/calendar/hooks/order-of-service/useTemplates';

export default function OrderOfServiceButton(
  orderOfServiceSearchParams: OrderOfServiceSearchParams
) {
  const { openTemplateSelector } = useTemplates();
  return (
    <Button
      icon={<CdOrderOfService />}
      onClick={() => openTemplateSelector(orderOfServiceSearchParams)}
      disabled={orderOfServiceSearchParams.disabled}
    >
      {I18nService.getString('Activity list')}
    </Button>
  );
}
