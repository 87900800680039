import {
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';

import { AppState } from '../../redux';
import { Taxonomy } from '../../shared/models/taxonomy';

const taxonomiesAdapter = createEntityAdapter({
  selectId: (model: Taxonomy) => model.id,
});
export type TaxonomiesState = EntityState<Taxonomy>;
const initialState: TaxonomiesState = taxonomiesAdapter.getInitialState();

const taxonomiesSlice = createSlice({
  name: 'taxonomies',
  initialState: initialState,
  reducers: {
    fetchTaxonomiesSuccess: taxonomiesAdapter.setAll,
  },
});

export const { fetchTaxonomiesSuccess } = taxonomiesSlice.actions;
const selectors = taxonomiesAdapter.getSelectors(
  (state: AppState) => state.taxonomies
);

export const { selectAll } = selectors;

export const selectTaxonomyById = (id: number) => (state: AppState) =>
  selectors.selectById(state, id);

export const taxonomiesReducers = taxonomiesSlice.reducer;
