import { Form } from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { isEqual, isNil, omit, omitBy } from 'lodash';

import { SetDrawerPropsType } from '../../../shared/components/cd-drawer/CdDrawer';
import { useTaxonomies } from '../../hooks/use-taxonomies';
import { AddEvent } from '../../store/main-view/Actions';
import { CategoryType } from '../../../shared/models/category';
import { useGroups } from '../../../organization/store/organization';
import { DetailedEvent, EventType } from '../../models/calendar';
import NotificationService from '../../../services/NotificationService';
import { getUsersInAvailabilityGroup } from '../utils/availability.helpers';
import { AvailabilityDrawerProps } from '../components/AvailabilityDrawer';
import { useCalendarEntry } from '../../components/pop-overs/use-calendar-entry';
import { I18nService } from '../../../services/I18nService';
import { calendarEntryQueries } from '../../../shared/queries/calendar/entry';
import { transformAPIAvailabilityToFormData } from '../utils/transform';
import { showConfirmModal } from '../../../shared/components/cd-confirm-modal/CdConfirmModal';
import { mainApi } from '../../../api';
import { handleError } from '../../../services/ErrorHandlingService';
import { fullCalendarActions } from '../../store/events/fullCalendarEventsSlice';
import { postEventAPI, putEventAPI } from '../../services/event.service';

export const useAvailabilityDrawer = (
  setDrawerProps: SetDrawerPropsType,
  closeDrawer: () => void,
  props?: AvailabilityDrawerProps
) => {
  // ID stored separate from Form state, because calendarEntryQueries.getById needs it immediately
  // and you can't set initialValues on form create, but has to wait till after it has been passed to <Form>
  const [id, setId] = useState<number>(props?.id);
  const isEditMode = Boolean(id);

  const [form] = Form.useForm();
  const currentValues = Form.useWatch([], form);
  const mainCategoryId = Form.useWatch('mainCategory', form);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { groups } = useGroups();
  const { taxonomies } = useTaxonomies(CategoryType.availability);
  const availabilityQuery = useSuspenseQuery(calendarEntryQueries.getById(id));
  useCalendarEntry(form, currentValues, isEditMode);

  const availabilityFromAPI = availabilityQuery.data;
  const selectedTaxonomy = taxonomies?.find(
    (taxonomy) => taxonomy.id === mainCategoryId
  );
  const userIdsInGroups = getUsersInAvailabilityGroup(selectedTaxonomy, groups);

  const createInitialValues = (apiData: DetailedEvent) => ({
    type: EventType.Availability,
    date: {
      startDate: moment().startOf('hour').add(1, 'hour'),
      endDate: moment().startOf('hour').add(2, 'hour'),
      allDay: false,
    },
    bookingOptionIds: [],
    ...props,
    ...transformAPIAvailabilityToFormData(apiData),
  });

  const hasChanges = useCallback(() => {
    const values = omitBy(form.getFieldsValue(), isNil);
    const initial = omitBy(initialValues, isNil);
    return !isEqual(values, initial);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const onSave = useCallback(async () => {
    // Fake validateFields because the first one will always return outOfDate on validation, but the second is fine
    try {
      await form.validateFields();
    } catch (_) {}

    const values = await form.validateFields();

    try {
      const payload = {
        startDate: moment(values.date.startDate).toISOString(),
        endDate: moment(values.date.endDate).toISOString(),
        allDay: values.date.allDay,
        mainCategory: values.mainCategory,
        rrule: values.date.rrule,
        users: [values.user],
        bookingOptionIds: values.bookingOptionIds,
        type: EventType.Availability,
      };

      if (!isEditMode) {
        const response = await postEventAPI(payload);
        queryClient.invalidateQueries(calendarEntryQueries.getById(id));
        dispatch(AddEvent(response.data));
        return response;
      } else {
        const response = await putEventAPI(payload, id);
        queryClient.invalidateQueries(calendarEntryQueries.getById(id));
        dispatch(fullCalendarActions.reloadCurrentView());
        return response;
      }
    } catch (error) {
      NotificationService.notifyError('Failed to save availability');
    }
  }, [dispatch, form, isEditMode, id, queryClient]);

  const saveAndCopy = useCallback(async () => {
    const formHasChanges = hasChanges();

    if (formHasChanges) {
      const result = await showConfirmModal({
        title: I18nService.getString('Unsaved changes'),
        message: I18nService.getString(
          'To create a copy of this event please save it first.'
        ),
        okText: I18nService.getString('Save & make a copy'),
      });
      if (!result.resolved) {
        return;
      }
    }

    const savedEvent = await onSave();

    form.setFieldsValue(createInitialValues(omit(savedEvent.data, ['rrule'])));
    setId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChanges, onSave]);

  const onDelete = useCallback(
    () =>
      showConfirmModal({
        title: I18nService.getString('Delete availability'),
        message: I18nService.getString(
          'Are you sure you want to delete this availability?'
        ),
        okButtonProps: { danger: true },
        okText: I18nService.getString('Delete'),
        onOk: async () => {
          const response = await mainApi.delete(`/calendar/${id}`);
          if (response.ok) {
            NotificationService.notifySuccess(
              I18nService.getString('The availability has been deleted')
            );
            closeDrawer();
          } else {
            handleError(response);
          }
        },
      }),
    // It cannot depend on closeDrawer, because onDelete is used in modalProps, which causes it to recreate closeDrawer in useCdModal
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  const onCancel = useCallback(async () => {
    if (hasChanges()) {
      const result = await showConfirmModal({
        title: I18nService.getString('Close without saving?'),
        message: I18nService.getString(
          'Your changes will not be saved. Close anyway?'
        ),
        okText: I18nService.getString('Leave without saving'),
        cancelText: I18nService.getString('Continue editing'),
      });
      if (!result.resolved) {
        return { preventClose: true };
      }
    }
  }, [hasChanges]);

  useEffect(() => {
    setDrawerProps({
      title: isEditMode
        ? I18nService.getString('Edit availability')
        : I18nService.getString('Create availability'),
      onOk: onSave,
      okText: I18nService.getString('Save'),
      onCancel: onCancel,
      dropdownOptions: [
        {
          key: 'delete',
          label: I18nService.getString('Delete'),
          onClick: onDelete,
          disabled: !isEditMode,
        },
        {
          type: 'divider',
        },
        {
          key: 'saveAndCopy',
          label: isEditMode
            ? I18nService.getString('Make a copy')
            : I18nService.getString('Save & Copy'),
          onClick: saveAndCopy,
        },
      ],
    });
  }, [isEditMode, onCancel, onDelete, onSave, saveAndCopy, setDrawerProps]);

  const initialValues = createInitialValues(availabilityFromAPI);

  return {
    form,
    initialValues,
    userIdsInGroups,
    selectedTaxonomy,
    availabilityFromAPI,
  };
};
