/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GetCalendarBookingBookingpageResponseBody = {
    id: number;
    type: GetCalendarBookingBookingpageResponseBody.type;
    publicName?: string;
    description?: string | null;
    form?: {
        id: string;
        url: string;
    };
    followUpForm?: {
        id: string;
        url: string;
    };
    emailTemplates?: {
        confirmation?: string;
        rejection?: string;
    };
    organization: {
        publicId: string;
        name: string;
        timezone: string;
        language: string;
        email?: string;
        logo?: {
            url: string;
            height?: number | null;
            width?: number | null;
        };
        privacyPolicyLink?: string;
        imprintLink?: string;
    };
    configuration?: {
        primaryColor: string;
        cardColor: string;
        domain?: string;
        font?: string;
    };
    bookingOptions?: Array<{
        id: string;
        name: string;
        description: string;
        showUsers?: boolean;
        users?: Array<{
            id: number;
            contact?: {
                firstName: string;
                lastName: string;
                fullName: string;
            };
        }>;
        location?: {
            title: string;
            address?: {
                street?: string;
                city?: string;
                country?: string;
                zipcode?: string;
            };
        };
    }>;
};
export namespace GetCalendarBookingBookingpageResponseBody {
    export enum type {
        SPECIFIC_AVAILABILITY = 'specific-availability',
        GENERAL_AVAILABILITY = 'general-availability',
    }
}

