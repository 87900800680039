import { EventApi } from '@fullcalendar/core';
import _ from 'lodash';
import React from 'react';

import { I18nService } from '@/react/services/I18nService';
import { CdIntentionsIcon } from '@/react/shared/components/Icons';

type IntentionsInfoProps = {
  event: EventApi;
};

export const IntentionsInfo = ({ event }: IntentionsInfoProps) => {
  const noOfIntentions = _.get(event, 'extendedProps.noOfIntentions');
  const noOfStoles = _.get(event, 'extendedProps.noOfStoles');

  return (
    <div className="fc-extra-content__intentions">
      {noOfIntentions > 0 ? (
        <div className="fc-extra-content__intentions__info">
          <CdIntentionsIcon size="xs" />
          {noOfIntentions}
        </div>
      ) : null}
      {noOfStoles > 0 ? (
        <div className="fc-extra-content__stoles__info">
          {I18nService.getPlural(noOfStoles, '1 Stole', '{{ count }} Stoles', {
            count: noOfStoles,
          })}
        </div>
      ) : null}
    </div>
  );
};
