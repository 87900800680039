import { Modal, Spin, Button, Typography } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import styled from 'styled-components';
import CopyToClipboard from 'react-copy-to-clipboard';

import { I18nService } from '../../services/I18nService';
import PortalService from '../../portal/services/portal.service';
import ErrorHandlingService from '../../services/ErrorHandlingService';
import { CdCopyIcon } from '../../shared/components/Icons';

const { Paragraph } = Typography;

const Container = styled.div`
  margin: 8px 32px 0 48px;
`;
export const GetCodeSignUpWidgetModal = NiceModal.create(
  ({ organizationId }: { organizationId?: number }) => (
    <GetCodeSignUpWidget organizationId={organizationId} />
  )
);

interface GetCodeSignUpWidgetProps {
  organizationId: number;
}

export const GetCodeSignUpWidget: FunctionComponent<GetCodeSignUpWidgetProps> =
  ({ organizationId }) => {
    const [isLoading, setLoading] = useState<boolean>(true);
    const [codeValue, setCodeValue] = useState<string>('');
    const modal = useModal('GetCodeSignUpWidget');
    const [buttonText, setButtonText] = useState(
      I18nService.getString('Copy code')
    );

    const close = () => {
      modal.remove();
    };

    const getSignUpWidgetCode = () => {
      PortalService.getSignUpWidgetCode(organizationId)
        .then((data) => {
          // Remove first and last line ending.
          setCodeValue(data.replace(/^\s+|\s+$/g, ''));
        })
        .catch(ErrorHandlingService.handleError)
        .finally(() => setLoading(false));
    };
    // eslint-disable-next-line
    useEffect(getSignUpWidgetCode, []);
    return (
      <Modal
        {...{
          ...antdModalV5(modal),
          width: 800,
          title: I18nService.getString(`Copy code`),
          bodyStyle: { padding: 0 },
          onCancel: close,
          cancelText: I18nService.getString('Close'),
          okButtonProps: { style: { display: 'none' } },
        }}
      >
        <Container>
          <Paragraph>
            {I18nService.getString(
              'Insert the widget on your website by copying this code and pasting it into the HTML of your site.'
            )}
          </Paragraph>
          <Spin spinning={isLoading}>
            <Paragraph code style={{ whiteSpace: 'pre' }}>
              {codeValue}
            </Paragraph>
          </Spin>
          <CopyToClipboard
            text={codeValue}
            onCopy={() => {
              setButtonText(I18nService.getString('Copied'));
            }}
          >
            <Button style={{ margin: '0px 0px 10px 2px' }}>
              <CdCopyIcon />
              {buttonText}
            </Button>
          </CopyToClipboard>
        </Container>
      </Modal>
    );
  };
