import { Button, Space } from 'antd';
import React from 'react';

import { RotaSchemeTable } from '@/react/calendar/rota-scheme/RotaSchemeTable';
import { I18nService } from '@/react/services/I18nService';
import { getStateUrl } from '@/react/services/StateServiceFactory';
import { CdPage } from '@/react/shared/components/cd-page/CdPage';

export const RotaSchemePage = () => (
  <CdPage
    pageHeaderProps={{
      title: I18nService.getString('Rota schemes'),
      subTitle: I18nService.getString('Manage and share your rota schemes'),
      extra: (
        <Space>
          <Button
            type="primary"
            href={getStateUrl('app.private.calendar.rotas.new')}
          >
            {I18nService.getString('New rota scheme')}
          </Button>
          <Button href={getStateUrl('app.private.intranet.groups')}>
            {I18nService.getString('View rota groups')}
          </Button>
        </Space>
      ),
    }}
  >
    <RotaSchemeTable />
  </CdPage>
);
