/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { GetCalendarFormsSearchResponseBody } from '../models/GetCalendarFormsSearchResponseBody';
import type { GetShiftsResponseBody } from '../models/GetShiftsResponseBody';
import type { PostShiftsRotasLinkRequestBody } from '../models/PostShiftsRotasLinkRequestBody';
import type { PostShiftsSelfunassignfromrotasRequestBody } from '../models/PostShiftsSelfunassignfromrotasRequestBody';
import type { RetrieveResourceResponseBody } from '../models/RetrieveResourceResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class RotasService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetShifts
     * List Shifts
     * @returns GetShiftsResponseBody Success-Response
     * @throws ApiError
     */
    public getShifts({
        accessToken,
        type = 'user',
        period,
        search,
        orderBy = 'startDate',
        desc = true,
        limit = 25,
        offset,
        includeAllUserOrganizations = false,
        shiftId,
    }: {
        accessToken?: string,
        type?: 'user' | 'open' | null,
        period?: 'upcoming' | 'past' | null,
        search?: string | null,
        orderBy?: 'startDate' | 'eventTitle' | 'taskTitle' | null,
        desc?: boolean | null,
        limit?: number | null,
        offset?: number | null,
        includeAllUserOrganizations?: boolean,
        shiftId?: number,
    }): CancelablePromise<GetShiftsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/shifts',
            path: {
                'shiftId': shiftId,
            },
            query: {
                'access_token': accessToken,
                'type': type,
                'period': period,
                'search': search,
                'orderBy': orderBy,
                'desc': desc,
                'limit': limit,
                'offset': offset,
                'includeAllUserOrganizations': includeAllUserOrganizations,
            },
        });
    }
    /**
     * GetShiftsRotas
     * Retrieve Rotas
     * @returns GetCalendarFormsSearchResponseBody
     * @throws ApiError
     */
    public getShiftsRotas({
        startDate,
        endDate,
        taxonomyIds,
        resourceIds,
        taskIds,
        type = 'all',
        eventsWithoutResourcesInChurchIds,
    }: {
        startDate?: string,
        endDate?: string,
        taxonomyIds?: Array<number>,
        resourceIds?: Array<number>,
        taskIds?: Array<number>,
        type?: 'user' | 'all' | null,
        eventsWithoutResourcesInChurchIds?: Array<number>,
    }): CancelablePromise<GetCalendarFormsSearchResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/shifts/rotas',
            query: {
                'startDate': startDate,
                'endDate': endDate,
                'taxonomyIds': taxonomyIds,
                'resourceIds': resourceIds,
                'taskIds': taskIds,
                'type': type,
                'eventsWithoutResourcesInChurchIds': eventsWithoutResourcesInChurchIds,
            },
        });
    }
    /**
     * GetShiftsRotasLink
     * Retrieve Public Rotas Links
     * @returns EmptyObject
     * @throws ApiError
     */
    public getShiftsRotasLink({
        orderBy = 'lastAccessed',
        desc = true,
        limit = 25,
        offset,
    }: {
        orderBy?: 'name' | 'authorId' | 'updatedById' | 'lastAccessed' | null,
        desc?: boolean | null,
        limit?: number | null,
        offset?: number | null,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/shifts/rotas/link',
            query: {
                'orderBy': orderBy,
                'desc': desc,
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * PostShiftsRotasLink
     * Create Public Rotas
     * @returns EmptyObject
     * @throws ApiError
     */
    public postShiftsRotasLink({
        requestBody,
    }: {
        requestBody?: PostShiftsRotasLinkRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/shifts/rotas/link',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeleteShiftsRotasLinkId
     * Delete Public Rota LInk For A Given ID
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteShiftsRotasLink({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/shifts/rotas/link/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetShiftsRotasLinkId
     * Retrieve Public Rota LInk For A Given ID
     * @returns GetCalendarFormsSearchResponseBody
     * @throws ApiError
     */
    public getShiftsRotasLink1({
        id,
    }: {
        id: string,
    }): CancelablePromise<GetCalendarFormsSearchResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/shifts/rotas/link/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * PutShiftsRotasLinkId
     * Update Public Rotas
     * @returns EmptyObject
     * @throws ApiError
     */
    public putShiftsRotasLink({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PostShiftsRotasLinkRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/shifts/rotas/link/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetShiftsRotasPublicPersonal
     * Retrieve personal scheme from public link
     * @returns RetrieveResourceResponseBody Success-Response
     * @throws ApiError
     */
    public getShiftsRotasPublicPersonal({
        publicUserToken,
        startDate,
        endDate,
    }: {
        publicUserToken: string,
        startDate?: string,
        endDate?: string,
    }): CancelablePromise<RetrieveResourceResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/shifts/rotas/public/personal',
            query: {
                'publicUserToken': publicUserToken,
                'startDate': startDate,
                'endDate': endDate,
            },
        });
    }
    /**
     * GetShiftsRotasPublicId
     * Retrieve Public Rotas For A Given Link ID
     * @returns GetCalendarFormsSearchResponseBody
     * @throws ApiError
     */
    public getShiftsRotasPublic({
        id,
        startDate,
        endDate,
    }: {
        id: string,
        startDate?: string,
        endDate?: string,
    }): CancelablePromise<GetCalendarFormsSearchResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/shifts/rotas/public/{id}',
            path: {
                'id': id,
            },
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
        });
    }
    /**
     * PostShiftsSelfUnassignFromRotas
     * Unassign from Rotas
     * @returns EmptyObject
     * @throws ApiError
     */
    public postShiftsSelfUnassignFromRotas({
        requestBody,
    }: {
        requestBody?: PostShiftsSelfunassignfromrotasRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/shifts/self-unassign-from-rotas',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
