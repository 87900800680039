import NiceModal, { antdModalV5 } from '@ebay/nice-modal-react';
import { ErrorBoundary } from '@sentry/react';
import {
  Button,
  ButtonProps,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Skeleton,
  Space,
  Spin,
} from 'antd';
import React, { FunctionComponent } from 'react';
import { Suspense } from 'react';
import styled from 'styled-components';
import { ItemType } from 'antd/es/menu/hooks/useItems';

import { I18nService } from '../../../services/I18nService';
import { CdClose } from '../Icons';
import { CdErrorPage } from '../cd-result/CdErrorPage';
import { CdDropdown } from '../cd-dropdown/CdDropdown';

import { useCdModal } from './useCdModal';

// We don't want to use overflow-y: scroll, since it will always show the scrollbar on Windows and Linux
// We add positive padding and negative margin to avoid overflow-y: auto causing content to be clipped on the x-axis
// https://stackoverflow.com/questions/6421966/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue
const CustomModal = styled(Modal)<{
  $maxBodyHeight: number;
}>`
  ${(props) => props.$maxBodyHeight && ' top: 20px'};

  .ant-modal-body {
    ${(props) =>
      props.$maxBodyHeight && `max-height: ${props.$maxBodyHeight}vh`};
    ${(props) => props.$maxBodyHeight && 'overflow-y: auto'};
    padding: 16px;
    margin: -16px;
  }
`;

// Difference between Modal and Pop-up: https://ux.stackexchange.com/questions/94423/should-clicking-outside-an-important-modal-close-it
export enum ModalType {
  MODAL = 'modal',
  POPUP = 'pop-up',
}

// Ant Docs here: https://ant.design/components/modal#api
export interface InnerModalProps<Result = unknown> {
  modalType: ModalType;
  title?: string | React.ReactNode;
  width?: number | string;
  okButtonProps?: ButtonProps;
  okText?: string | React.ReactNode;
  cancelText?: string;
  hideOk?: boolean;
  hideCancel?: boolean;
  onOk?: () => Promise<Result> | Result;
  onCancel?: () => unknown;
  maxBodyHeight?: number; // it should be based on 'vh'
  onAlternative?: () => unknown;
  alternativeButtonProps?: ButtonProps;
  alternativeButtonText?: string | React.ReactNode;
  dropdownText?: string;
  dropdownOptions?: ItemType[];
}

export type SetModalPropsType = React.Dispatch<
  React.SetStateAction<InnerModalProps<unknown>>
>;

interface InnerModalType {
  setModalProps: SetModalPropsType;
  loading?: boolean;
  closeModal: () => void;
}

// Makes sure that if the <T> is NOT defined when using createCdModal, no props are required.
export type OptionalIfVoid<T = unknown> = void extends T ? void : T;

// Insert a Functional Component into this function, and it will return a show function which takes T as parameters.
// The createCdModal function should align with https://app.shortcut.com/churchdesk/write/IkRvYyI6I3V1aWQgIjY2NmFkNTkyLTU4NzUtNDYyYS1hYmRmLWE2MGEyMTA0NTI3OCI=
export const createCdModal = <T, Result = unknown>(
  InnerModal: FunctionComponent<T & InnerModalType>,
  options?: InnerModalProps<Result>
) => {
  const createdModal = NiceModal.create((callingProps: T) => {
    const {
      modal,
      disabled,
      crashed,
      modalProps,
      setCrashed,
      onOk,
      onCancel,
      onAlternative,
      setModalProps,
    } = useCdModal<InnerModalProps<Result>, Result>({
      modalType: ModalType.MODAL,
      title: '',
      maxBodyHeight: 80,
      width: 600,
      ...options,
    });

    return (
      <CustomModal
        {...antdModalV5(modal)}
        {...modalProps}
        {...{
          closable: true,
          maskClosable: modalProps.modalType !== ModalType.MODAL,
          keyboard: modalProps.modalType !== ModalType.MODAL,
          closeIcon: <CdClose />,
          okButtonProps: {
            ...modalProps.okButtonProps,
            disabled: disabled || modalProps.okButtonProps?.disabled,
            loading: disabled || modalProps.okButtonProps?.loading,
          },
          onCancel,
          footer: (!modalProps.hideCancel || !modalProps.hideOk) && (
            <ModalFooter
              modalProps={modalProps}
              disabled={disabled}
              crashed={crashed}
              onOk={onOk}
              onCancel={onCancel}
              onAlternative={onAlternative}
            />
          ),
        }}
        destroyOnClose
        $maxBodyHeight={modalProps.maxBodyHeight}
      >
        <ErrorBoundary
          onError={() => setCrashed(true)}
          fallback={<CdErrorPage />}
        >
          <Suspense fallback={<ModalSuspended />}>
            <InnerModal
              setModalProps={setModalProps}
              closeModal={onCancel}
              loading={disabled}
              {...callingProps}
            />
          </Suspense>
        </ErrorBoundary>
      </CustomModal>
    );
  });

  return async (props: OptionalIfVoid<T>) =>
    NiceModal.show<{ result: Result; resolved: boolean }, T, any>(
      createdModal,
      props ? props : {}
    );
};

export const ModalSuspended = () => (
  <Form layout="vertical">
    <div className="ant-modal-header">
      <div className="ant-modal-title">
        <Skeleton
          title={{ width: 200, style: { margin: 0, height: 24 } }}
          paragraph={false}
        />
      </div>
    </div>
    <Divider />
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 32,
      }}
    >
      <Spin size="large" />
    </div>
  </Form>
);

export const ModalFooter = (props: {
  modalProps: {
    hideCancel?: boolean;
    hideOk?: boolean;
    okText?: string | React.ReactNode;
    cancelText?: string | React.ReactNode;
    alternativeButtonText?: string | React.ReactNode;
    okButtonProps?: ButtonProps;
    alternativeButtonProps?: ButtonProps;
    dropdownText?: string;
    dropdownOptions?: ItemType[];
  };
  disabled?: boolean;
  crashed?: boolean;
  onOk?: () => unknown;
  onCancel?: () => unknown;
  onAlternative?: () => unknown;
}) => (
  <Row justify="space-between">
    <Col>
      {props.modalProps.dropdownOptions && (
        <CdDropdown
          items={props.modalProps.dropdownOptions || []}
          placement="topLeft"
        >
          {props.modalProps.dropdownText || I18nService.getString('More')}
        </CdDropdown>
      )}
    </Col>
    <Col>
      <Space>
        {!props.modalProps.hideCancel && (
          <Button key="cancel" onClick={props.onCancel}>
            {props.modalProps.cancelText || I18nService.getString('Cancel')}
          </Button>
        )}
        {props.modalProps.alternativeButtonText && (
          <Button
            {...{
              ...props.modalProps.alternativeButtonProps,
            }}
            disabled={
              props.disabled ||
              props.crashed ||
              props.modalProps.alternativeButtonProps?.disabled
            }
            loading={props.disabled}
            key="extra"
            onClick={props.onAlternative}
          >
            {props.modalProps.alternativeButtonText}
          </Button>
        )}
        {!props.modalProps.hideOk && (
          <Button
            {...{
              ...props.modalProps.okButtonProps,
              type: props.modalProps.okButtonProps?.type || 'primary',
            }}
            disabled={
              props.disabled ||
              props.crashed ||
              props.modalProps.okButtonProps?.disabled
            }
            loading={props.disabled}
            key="ok"
            onClick={props.onOk}
          >
            {props.modalProps.okText || I18nService.getString('Ok')}
          </Button>
        )}
      </Space>
    </Col>
  </Row>
);
