import React, { useEffect } from 'react';
import { Space, Spin } from 'antd';
import { useRecoilValue } from 'recoil';

import { useAssignUserToRotaSchemeModal } from '../event-details/hooks/use-add-user-to rota';
import 'react-image-crop/dist/ReactCrop.css';
import { AddUserToRotaForm } from '../event-details/components/rotas/AddUserToRotaForm';
import { EventQuery } from '../store/events/event';

import { I18nService } from '@/react/services/I18nService';
import {
  ModalType,
  createCdModal,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import { EventIcons } from '@/react/shared/components/Icons';

type AssignUserToRotaInRotaSchemesModalProps = {
  eventId: number;
  taskId: number;
};

export const openAssignUserToRotaInRotaSchemesModal =
  createCdModal<AssignUserToRotaInRotaSchemesModalProps>(
    ({ setModalProps, eventId, taskId }) => {
      const event = useRecoilValue(
        EventQuery({ calendarId: eventId, clientVersion: 3 })
      );
      const {
        form,
        userAssignedList,
        userAssignedIds,
        busyUsers,
        loading,
        handleChange,
        handleRemoveAssignedUser,
        handleOnOk,
        keywordToSearchUsersByName,
        setKeywordToSearchusersByName,
        selectorOptionsUsers,
        filterParishIds,
        setFilterParishIds,
        token,
        startDate,
        allDay,
        isMultiParish,
        rotaDutyDetails,
        note,
        canEditUsers,
      } = useAssignUserToRotaSchemeModal({ event, taskId });
      useEffect(() => {
        setModalProps({
          modalType: ModalType.MODAL,
          title: I18nService.getString('Assign user'),
          width: 600,
          onOk: () => handleOnOk(false),
          okText: I18nService.getString('Save'),
          alternativeButtonText: (
            <Space>
              <EventIcons.Notify />
              {I18nService.getString('Save and notify')}
            </Space>
          ),
          onAlternative: () => handleOnOk(true),
        });
      }, [handleOnOk, setModalProps, event]);
      return (
        <Spin spinning={!event || loading}>
          <AddUserToRotaForm
            form={form}
            startDate={startDate}
            allDay={allDay}
            rotaDutyName={rotaDutyDetails?.title}
            note={note}
            canEditUsers={canEditUsers}
            isMultiParish={isMultiParish}
            filterParishIds={filterParishIds}
            setFilterParishIds={setFilterParishIds}
            handleChange={handleChange}
            selectorOptionsUsers={selectorOptionsUsers}
            required={rotaDutyDetails?.required}
            userAssignedIds={userAssignedIds}
            keywordToSearchUsersByName={keywordToSearchUsersByName}
            setKeywordToSearchusersByName={setKeywordToSearchusersByName}
            userAssignedList={userAssignedList}
            busyUsers={busyUsers}
            handleRemoveAssignedUser={handleRemoveAssignedUser}
            token={token}
            eventTitle={event?.title}
          />
        </Spin>
      );
    }
  );
