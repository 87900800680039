/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TerminalSyncRequestBody = {
    appVersion: string;
    macAddress?: string;
    errorNFCReader?: TerminalSyncRequestBody.errorNFCReader;
    errorStripeTerminalPermissions?: TerminalSyncRequestBody.errorStripeTerminalPermissions;
    errorGPS?: TerminalSyncRequestBody.errorGPS;
};
export namespace TerminalSyncRequestBody {
    export enum errorNFCReader {
        FAULTY = 'faulty',
        MISSING = 'missing',
    }
    export enum errorStripeTerminalPermissions {
        DENIED = 'denied',
        NEVER_ASK_AGAIN = 'never_ask_again',
    }
    export enum errorGPS {
        FAULTY = 'faulty',
        MISSING = 'missing',
    }
}

