/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContributionGetExcelReportResponseBody } from '../models/ContributionGetExcelReportResponseBody';
import type { ContributionsPaymentsResponseBody } from '../models/ContributionsPaymentsResponseBody';
import type { ContributionsSubscriptionsResponseBody } from '../models/ContributionsSubscriptionsResponseBody';
import type { EmptyObject } from '../models/EmptyObject';
import type { GetPContributionsPublicResponseBody } from '../models/GetPContributionsPublicResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ContributionsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * ContributionCreate
     * Create
     * @returns EmptyObject
     * @throws ApiError
     */
    public postContributions({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/contributions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * ContributionsIndex
     * Index
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributions(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions',
        });
    }
    /**
     * GetActiveDonations
     * GetActiveDonations
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributionsActiveDonations(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/active-donations',
        });
    }
    /**
     * Annual_donations
     * print annual donation
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributionsAnnualDonations({
        year,
    }: {
        /**
         * - the year of donation.
         */
        year: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/annual-donations',
            query: {
                'year': year,
            },
        });
    }
    /**
     * ContributionsIndexInternal
     * List Contribution Projects Internally
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributionsInternalList(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/internal/list',
        });
    }
    /**
     * ContributionsMetrics
     * ContributionsMetrics
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributionsMetrics(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/metrics',
        });
    }
    /**
     * DownloadPoster
     * DownloadPoster
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributionsPosterDownload(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/poster/{id}/download',
        });
    }
    /**
     * ContributionsUploadLogo
     * UploadPosterLogo
     * @returns EmptyObject
     * @throws ApiError
     */
    public postContributionsPostersLogoUpload({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/contributions/posters/logo/upload',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * ContributionPublicIndex
     * Index public contributions for an organization.
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributionsPublic(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/public',
        });
    }
    /**
     * RedirectToPaymentView
     * RedirectToQRCodeLink
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributionsPublicQrcode({
        type,
        contributionAlias,
    }: {
        /**
         * - the type to generate the QR code for (contribution | landingPage)
         */
        type: any,
        /**
         * - the alias for the contribution project.
         */
        contributionAlias?: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/public/qrcode',
            query: {
                'contributionAlias': contributionAlias,
                'type': type,
            },
        });
    }
    /**
     * ContributionPublicGet
     * Fetch contribution [PUBLIC]
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributionsPublic1(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/public/{alias}',
        });
    }
    /**
     * ContributionsGetQRCode
     * ContributionsGetQRCode
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributionsQrcode({
        type,
        contributionAlias,
    }: {
        /**
         * - the type to generate the QR code for (contribution | landingPage)
         */
        type: any,
        /**
         * - the alias for the contribution project.
         */
        contributionAlias?: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/qrcode',
            query: {
                'contributionAlias': contributionAlias,
                'type': type,
            },
        });
    }
    /**
     * ContributionGetExcelReport
     * GET EXCEL REPORT
     * @returns ContributionGetExcelReportResponseBody
     * @throws ApiError
     */
    public getContributionsReportDownload({
        from,
        to,
        downloadToken,
    }: {
        /**
         * The date to start exporting
         */
        from: any,
        /**
         * The date to start exporting
         */
        to: any,
        /**
         * The id of the organization (by token, not directly)
         */
        downloadToken: any,
    }): CancelablePromise<ContributionGetExcelReportResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/report/{contributionId}/download',
            query: {
                'from': from,
                'to': to,
                'downloadToken': downloadToken,
            },
        });
    }
    /**
     * GetContributionsContributionidDonationsFormid
     * GetPaymentsInFormSubmissions
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributionsDonations(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/{contributionId}/donations/{formId}',
        });
    }
    /**
     * ExportContribution
     * ExportContribution
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributionsExportFile({
        from,
        to,
        _export,
    }: {
        /**
         * The date to start exporting
         */
        from: any,
        /**
         * The date to start exporting
         */
        to: any,
        /**
         * A flag whether to preview or download exported file.
         */
        _export: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/{contributionId}/export/file',
            query: {
                'from': from,
                'to': to,
                'export': _export,
            },
        });
    }
    /**
     * PreviewContributionExport
     * PreviewContributionExport
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributionsExportPreview({
        from,
        to,
        _export,
    }: {
        /**
         * The date to start exporting
         */
        from: any,
        /**
         * The date to start exporting
         */
        to: any,
        /**
         * A flag whether to preview or download exported file.
         */
        _export: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/{contributionId}/export/preview',
            query: {
                'from': from,
                'to': to,
                'export': _export,
            },
        });
    }
    /**
     * CreatePoster
     * CreatePoster
     * @returns EmptyObject
     * @throws ApiError
     */
    public postContributionsPoster({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/contributions/{contributionId}/poster',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * PosterPreview
     * PosterPreview
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributionsPosterPreview(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/{contributionId}/poster/preview',
        });
    }
    /**
     * GetPosterToken
     * GetPosterToken
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributionsPosterToken(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/{contributionId}/poster/token',
        });
    }
    /**
     * ContributionsSubscriptionStats
     * ContributionsSubscriptions
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributionsSubscriptionsStats(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/{contributionId}/subscriptions/stats',
        });
    }
    /**
     * ContributionsSubscriptionDelete
     * ContributionsSubscriptions
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteContributionsSubscriptions(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/contributions/{contributionId}/subscriptions/{subscriptionId}',
        });
    }
    /**
     * ContributionsSubscriptionsGet
     * ContributionsSubscriptions
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributionsSubscriptionsPayments(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/{contributionId}/subscriptions/{subscriptionId}/payments',
        });
    }
    /**
     * ContributionsSubscriptionDelete
     * ContributionsSubscriptions
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteContributionsSubscriptionsPublic(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/contributions/{contributionId}/subscriptions/{subscriptionId}/public',
        });
    }
    /**
     * ContributionsSubscriptionPublic
     * ContributionsSubscriptions
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributionsSubscriptionsPublic(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/{contributionId}/subscriptions/{subscriptionId}/public',
        });
    }
    /**
     * GetContributionsFormidTicketProject
     * GetFormTickProject
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributionsTicketProject(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/{formId}/ticket/project',
        });
    }
    /**
     * ContributionDelete
     * Delete
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteContributions(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/contributions/{uuid}',
        });
    }
    /**
     * ContributionRetrieve
     * Retrieve
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributions1(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/{uuid}',
        });
    }
    /**
     * ContributionUpdate
     * Update
     * @returns EmptyObject
     * @throws ApiError
     */
    public putContributions({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/contributions/{uuid}',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * ContributionsPayments
     * ContributionsPayments
     * @returns ContributionsPaymentsResponseBody
     * @throws ApiError
     */
    public getContributionsPayments({
        limit,
        pageNumber,
        clientVersion,
    }: {
        /**
         * - the amount of rows returned in a page.
         */
        limit: any,
        /**
         * - the current page number.
         */
        pageNumber: any,
        /**
         * - the client version
         */
        clientVersion: any,
    }): CancelablePromise<ContributionsPaymentsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/{uuid}/payments',
            query: {
                'limit': limit,
                'pageNumber': pageNumber,
                'clientVersion': clientVersion,
            },
        });
    }
    /**
     * ContributionsStats
     * ContributionsStats
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributionsStats(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/{uuid}/stats',
        });
    }
    /**
     * ContributionsSubscriptions
     * ContributionsSubscriptions
     * @returns ContributionsSubscriptionsResponseBody
     * @throws ApiError
     */
    public getContributionsSubscriptions({
        limit,
        page,
    }: {
        /**
         * - the amount of rows returned in a page.
         */
        limit: any,
        /**
         * - the current page number.
         */
        page: any,
    }): CancelablePromise<ContributionsSubscriptionsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/{uuid}/subscriptions',
            query: {
                'limit': limit,
                'page': page,
            },
        });
    }
    /**
     * UploadFile
     * Upload File
     * @returns EmptyObject
     * @throws ApiError
     */
    public postFiles({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/files',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeleteFile
     * Delete File
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteFiles(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/files/{uuid}',
        });
    }
    /**
     * GetFileById
     * Get File
     * @returns EmptyObject
     * @throws ApiError
     */
    public getFiles({
        uuid,
    }: {
        /**
         * - the id of the file.
         */
        uuid: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/files/{uuid}',
            query: {
                'uuid': uuid,
            },
        });
    }
    /**
     * PostOrganizationsAddBankaccount
     * @returns EmptyObject
     * @throws ApiError
     */
    public postOrganizationsAddBankaccount({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organizations/add/bankaccount',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetOrganizationsVerificationLinks
     * @returns EmptyObject
     * @throws ApiError
     */
    public getOrganizationsVerificationLinks(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/verification/links',
        });
    }
    /**
     * GetOrganizationsOrganizationidChargingStatus
     * @returns EmptyObject
     * @throws ApiError
     */
    public getOrganizationsChargingStatus(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{organizationId}/charging/status',
        });
    }
    /**
     * GetOrganizationsOrganizationidStatus
     * Organization Status
     * @returns EmptyObject
     * @throws ApiError
     */
    public getOrganizationsStatus(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{organizationId}/status',
        });
    }
    /**
     * Contributions list (Public)
     * Organization contributions list for pubic access.
     * @returns GetPContributionsPublicResponseBody
     * @throws ApiError
     */
    public getPContributionsPublic(): CancelablePromise<GetPContributionsPublicResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/public',
        });
    }
}
