import React from 'react';
import { Badge } from 'antd';

import { CdTooltip } from './cd-tooltip/CdTooltip';
import { CdVerticalSpace } from './cd-vertical-space/CdVerticalSpace';

export interface ModuleRedDotProps {
  notifications?: string[];
  count?: number;
}

export const NotificationIndicator = (props: ModuleRedDotProps) => (
  <CdTooltip
    title={
      props.notifications?.length > 0 && (
        <CdVerticalSpace size={2}>{props.notifications}</CdVerticalSpace>
      )
    }
    placement="right"
  >
    <div style={{ display: 'flex' }}>
      <Badge
        size="small"
        count={props.count}
        offset={!props.count ? [-5, 5] : [2, 0]}
        dot={!props.count}
      />
    </div>
  </CdTooltip>
);
