import React from 'react';
import { Row, Col, Space } from 'antd';
import moment from 'moment';

import {
  CdExclamationTriangleIcon,
  EventIcons,
  CdCheckIcon,
} from '@/react/shared/components/Icons';
import { I18nService } from '@/react/services/I18nService';

export const EventHasBeenPublished = ({ facebookInfo }) => (
  <Row align="middle" gutter={[0, 8]} style={{ marginTop: '0px' }}>
    <Col span={24}>
      <Row>
        <Space direction="horizontal">
          {I18nService.getString('Shared on Facebook')}
          <EventIcons.FacebookSuccess style={{ color: 'green' }} />
        </Space>
      </Row>
      <Row>{moment(facebookInfo.publishedOn).format('lll')}</Row>
    </Col>
  </Row>
);

export const EventIsBeingPublishedInProgress = () => (
  <Row align="middle" style={{ marginTop: '0px' }}>
    <Col span={2}>
      <CdCheckIcon size="2x" />
    </Col>
    <Col span={22}>
      <Row>
        {I18nService.getString('Your event is being shared to Facebook.')}
      </Row>
    </Col>
  </Row>
);

export const EventIsBeingPublishedFailed = () => (
  <Row align="middle" style={{ marginTop: '0px' }}>
    <Col span={2}>
      <CdExclamationTriangleIcon style={{ color: 'orange' }} size="2x" />
    </Col>
    <Col span={22}>
      <Row>{I18nService.getString('Sharing to Facebook failed.')}</Row>
    </Col>
  </Row>
);
