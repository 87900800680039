import { faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { I18nService } from '../../services/I18nService';
import { isResourceSelected, isUserSelected } from '../hooks';
import { FilterType, SelectedCalendar } from '../store/filters';
import {
  AddCalendar,
  RemoveCalendar,
  ToggleCalendar,
} from '../store/filters/Actions';
import { getCalendar, getParentResourceById } from '../store/filters/Selectors';
import { FeedType } from '../store/main-view/Actions';

import { SearchableFilterItem } from '@/react/calendar/components/SearchableFilterItem';
import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';

/**
 * TODO: This style is repeated in `src/React/Calendar/components/CalendarItem.tsx`!
 * Style definitions should NOT be mixed with structural components!!!
 */
export const StyledFilterItem = styled.div<{
  isDisabled?: boolean;
  hoverBackgroundColor: string;
}>`
  list-style-type: none;
  line-height: 25px;
  ${({ isDisabled }) =>
    isDisabled
      ? `
    .bullet-wrapper {
      opacity: 0.4;
    }`
      : ''}

  .bullet-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor};

      .bullet:not(.bullet-full)::after {
        display: none;
      }

      .bullet-label {
        width: 75%;
      }

      .filter-actions {
        display: flex;
        right: 0;

        i.fa {
          margin: 0 3px;
        }

        a:hover {
          i {
            color: #333;
          }
        }
      }
    }

    .bullet-label {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;
      padding: 8px;
        

      .bullet {
        margin-right: 8px;
        border-radius: 4px;
        width: 16px;
        height: 16px;
        align-self: center;
        min-width: 16px;
        min-height: 16px;
        display: block;
        cursor: pointer;

        &.color-999 {
          background-color: #517488;
          border-color: #517488;
          color: white;
        }

        &.color-998 {
          background-color: #3d5766;
          border-color: #3d5766;
          color: white;
        }

        &.color-888 {
          background-color: #333;
          border-color: #333;
          color: white;
        }

        &:not(.bullet-empty) {
          border: none;

          &::after {
            font: normal normal normal 10px/1 'Font Awesome 5 Pro';
            content: '\f00c';
            color: #fff;
            left: 3px;
            top: -6px;
            position: relative;
            transition: all 0.3s ease-out;
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -ms-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
          }
        }

        &.bullet-empty {
          background-color: transparent;
          border-style: solid;
          border-width: 2px;
        }
      }
      .label {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(100% - 21px);
        padding: 0;
        color: #333;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4;

        &.icon-label {
          width: calc(100% - 35px);
        }

        .label.title {
          display: flex;
          flex-direction: row;
        }

        .name {
          display: block;
          width: 100%;
          font-size: 13px;
          color: #333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
        }

        small {
          width: 100%;
          line-height: 1.4;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .filter-actions {
      display: none;
      padding-right: 5px;

      svg {
        opacity: 0.6;

        &:hover,
        &:focus {
          opacity: 1;
        }
      }
    }
  }
`;

interface DisplayFilterItem {
  id: number | string;
  name: string;
  color?: number;
  image?: string;
  filterType?: FilterType;
  parentResourceId?: number;
  onEdit: () => void;
}

export interface UserItemProps {
  item: SelectedCalendar;
  feedType?: FeedType;
  onEdit?: () => void;
  addingNewCalendar?: boolean;
}

const hasParentResource = (item: DisplayFilterItem): boolean =>
  item.filterType === FilterType.resources &&
  _.isFinite(item && item.parentResourceId);

const FilterItem: FunctionComponent<UserItemProps> = ({
  item,
  feedType,
  onEdit,
  addingNewCalendar,
}) => {
  const dispatch = useDispatch();
  const onClose = useCallback(
    () => dispatch(RemoveCalendar(item)),
    [item, dispatch]
  );
  const onToggle = useCallback(() => {
    if (addingNewCalendar) {
      dispatch(AddCalendar(item));
    } else {
      dispatch(ToggleCalendar(item));
    }
  }, [addingNewCalendar, dispatch, item]);
  const calendar = useSelector(
    getCalendar(item.id, item.filterType),
    _.isEqual
  );
  const parentResource = useSelector(
    getParentResourceById(calendar && calendar.parentResourceId),
    _.isEqual
  );
  const hasGroupedResourcesPackage: boolean = _.get(
    window,
    'cdApp.showChurchSelector'
  );
  const isSelected = useSelector(
    feedType === 'user' ? isUserSelected(item.id) : isResourceSelected(item.id)
  );

  return (
    <SearchableFilterItem
      onLabelClick={() => onToggle()}
      isSelected={isSelected}
      itemData={{ ...item, ...calendar, id: item.id }}
      color={calendar?.color ?? item.color}
      name={calendar ? calendar.name || calendar.email : ''}
      nameSuffix={
        calendar &&
        hasGroupedResourcesPackage &&
        hasParentResource({ ...item, ...calendar }) ? (
          <small>{_.get(parentResource, 'name')}</small>
        ) : null
      }
      feedType={feedType}
      extraActions={
        <>
          {onEdit ? (
            <CdTooltip title={I18nService.getString('Edit')}>
              <FontAwesomeIcon
                icon={faPencilAlt}
                onClick={onEdit}
                style={{ marginRight: 5, cursor: 'pointer' }}
              />
            </CdTooltip>
          ) : null}
          {onClose ? (
            <CdTooltip title={I18nService.getString('Remove')}>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={onClose}
                style={{ marginRight: 5, cursor: 'pointer' }}
              />
            </CdTooltip>
          ) : null}
        </>
      }
    />
  );
};

export default React.memo(FilterItem, _.isEqual);
