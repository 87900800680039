import { mainApi } from '../../api';
import { OrganizationSettings } from '../../organization/types/organization';

import { CdQueryDefinition } from './types';

export const churchQueries = {
  get: () => ({
    queryKey: ['organizationSettings'],
    queryFn: async () => {
      const response = await mainApi.get<OrganizationSettings>('church');
      if (response.ok) {
        return {
          displayOrder:
            localStorage.getItem('churchdesk.peopleSettings.displayOrder') ||
            'firstLast',
          ...response.data,
          newsletterColor: response.data?.newsletterColor || '#000',
        };
      }
      throw response;
    },
  }),
} satisfies CdQueryDefinition;
