/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostPeopleSegmentsRequestBody = {
    name: string;
    setup: {
        comparison?: PostPeopleSegmentsRequestBody.comparison;
        churchIds: Array<number>;
        filters?: Array<any>;
    };
    filterIds?: Array<number>;
    type?: PostPeopleSegmentsRequestBody.type;
};
export namespace PostPeopleSegmentsRequestBody {
    export enum comparison {
        AND = 'AND',
        OR = 'OR',
    }
    export enum type {
        FILTER = 'filter',
        NEWSLETTER = 'newsletter',
        PARTICIPANT = 'participant',
    }
}

