import { isEmpty } from 'lodash';
import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Avatar, Col, Row, Space, Tag, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { I18nService } from '../../services/I18nService';
import { getStateUrl } from '../../services/StateServiceFactory';
import { People } from '../../shared/models/people';

const { Link, Title } = Typography;

const PersonInformationComponent: FunctionComponent<any> = ({
  person,
}: {
  person: People;
}) => (
  <Row gutter={[16, 16]}>
    <Col span={4}>
      {person.picture?.url ? (
        <Avatar src={person.picture.url} size={64} />
      ) : (
        <Avatar icon={<FontAwesomeIcon icon={faUser} />} size={64} />
      )}
    </Col>
    <Col span={20}>
      <Space direction="vertical">
        <Link
          href={getStateUrl('app.private.people.contacts.view', {
            id: person.id,
          })}
        >
          <ConflictingPersonNameTitle level={4}>
            {person.fullName || person.email}
          </ConflictingPersonNameTitle>
        </Link>
        {!isEmpty(person.churches) ? (
          <div>
            <ConflictingPersonChurchesSpan>
              {I18nService.getString('In parishes:')}
            </ConflictingPersonChurchesSpan>
            {person.churches.map((church) => (
              <ConflictingPersonChurchTag key={church.id}>
                {church.name}
              </ConflictingPersonChurchTag>
            ))}
          </div>
        ) : null}
      </Space>
    </Col>
  </Row>
);

const ConflictingPersonNameTitle = styled(Title)`
  &&&& {
    margin-top: 0px;
    color: #777;
  }
`;

const ConflictingPersonChurchesSpan = styled.span`
  padding-right: 8px;
`;

const ConflictingPersonChurchTag = styled(Tag)`
  &&&& {
    color: #777;
    margin-bottom: 8px;
  }
`;

export default PersonInformationComponent;
