import {
  Col,
  Collapse,
  Form,
  Input,
  Row,
  Typography,
  Checkbox,
  Space,
  Button,
  FormInstance,
  Alert,
} from 'antd';
import styled from 'styled-components';
import { cloneDeep, pick, set } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { CdParishSelect } from '../../../../shared/components/cd-perish-select/CdParishSelect';
import { EventAccessContext } from '../../hooks/use-event';

import PreparationAndCleanUpTime from './PreparationAndCleanUpTime';
import { FormItemWithAccess } from './FormItemWithAccess';
import { DistrictField } from './DistrictFieldOfEventLocation';

import { LocationObj } from '@/react/calendar/models/calendar';
import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';
import {
  CdLocationSelect,
  retrieveSelectedChurchesAndResource,
} from '@/react/shared/components/cd-location-select/CdLocationSelect';
import { SelectedResourcesLocation } from '@/react/calendar/event-details/components/form-items/SelectedResourcesLocation';
import { EventIcons } from '@/react/shared/components/Icons';
import { Resources } from '@/react/shared/services/ResourceService';
import { I18nService } from '@/react/services/I18nService';
import { HasMultipleChurches } from '@/react/user/store/user-session';
import { HasPartnership } from '@/react/calendar/store/partnership';
import { getAddressFormat } from '@/react/calendar/event-details/services/address.service';
import { getString } from '@/react/services/GetStringX';

const { Panel } = Collapse;
const { Text } = Typography;

interface ResourceLocationValues {
  resourceIds: number[];
  churchIds: number[];
  locationName: string;
  locationObj: LocationObj;
  prepTimeMinutes: number;
  cleanupTimeMinutes: number;
  fromPopup?: boolean;
  prepAndCleanUp?: boolean;
}

interface ResourceLocationProps {
  value?: ResourceLocationValues;
  onChange?: any;
  resourceIds?: object;
  eventVisibility?: string;
  isAllDayChecked?: boolean;
  form?: FormInstance;
  calendarId?: number;
}

const AddressHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 528px;
  align-items: center;
`;

export const ResourceLocation = (props: ResourceLocationProps) => {
  const resources = useRecoilValue(Resources);
  const isMultiChurch = useRecoilValue(HasMultipleChurches);
  const hasPartnership = useRecoilValue(HasPartnership);
  const [showUseResouceLocation, setShowUseResouceLocation] = useState(false);
  const { eventAccess, disabledByVersionHistory } =
    useContext(EventAccessContext);
  const { address, address2, zipcode, city, state, custom_data } =
    props.value?.locationObj || {};
  const isConnectedToResource = !!custom_data;
  const isAddressFieldsEmpty = !address && !city && !zipcode;
  const isEditingDisabled = props?.calendarId
    ? !eventAccess?.canEdit
    : !eventAccess?.canCreate;

  const findLocationInformation = useCallback(
    (firstResourceId: number) => {
      let firstResource = resources.find(
        (resource) => resource.id === firstResourceId
      );
      if (!firstResource) {
        // Search children
        resources.some((item) => {
          const resourceFound = item.resources?.find(
            (resource) => resource.id === firstResourceId
          );
          if (resourceFound) {
            firstResource = resourceFound;
          }
          return false;
        });
      }
      if (!firstResource) {
        return {};
      }
      const { locationName, location, parentResourceId } = firstResource;
      const overwriteLocation: Partial<ResourceLocationValues> = {
        locationName,
        locationObj: {
          ...pick(location, [
            'address',
            'address2',
            'city',
            'country',
            'state',
            'zipcode',
            'district',
          ]),
          // We add the resourceId to the custom_data so that the backend can
          // see that the location information is from a resource.
          custom_data: { resourceId: firstResourceId },
        },
      };
      if (parentResourceId && props.value.churchIds?.length === 0) {
        overwriteLocation.churchIds = [parentResourceId];
      }
      return overwriteLocation;
    },
    [resources, props.value.churchIds]
  );

  const onChange = useCallback(
    (newValue, path: string, locationFromResource?) => {
      let overwriteLocation: Partial<ResourceLocationValues> = {};
      if (
        props.value.resourceIds.length === 0 &&
        path === 'resourceIds' &&
        isAddressFieldsEmpty
      ) {
        // First resource selected. Overwrrite location information.
        const firstResourceId = newValue[0];
        overwriteLocation = findLocationInformation(firstResourceId);
      }

      if (
        [
          'locationName',
          'locationObj.address',
          'locationObj.address2',
          'locationObj.zipcode',
          'locationObj.city',
          'locationObj.district',
        ].includes(path) &&
        props.value.locationObj
      ) {
        // This should not be removed when the preparation and cleanup time is updated.
        props.value.locationObj.custom_data = undefined;
      }

      if (path === 'useFromLocation') {
        overwriteLocation = findLocationInformation(locationFromResource.id);
      }
      if (path === 'updateLocation') {
        overwriteLocation = findLocationInformation(props.value.resourceIds[0]);
      }
      // CloneDeep to ensure that compare functions sees the new values also when checking for reference.
      props.onChange(
        cloneDeep({
          ...set(props.value, path, newValue),
          ...overwriteLocation,
          fromPopup: false,
        })
      );
    },
    [findLocationInformation, isAddressFieldsEmpty, props]
  );

  useEffect(() => {
    if (props.value.fromPopup && props.value.resourceIds.length > 0) {
      // When the event is created from the popup, we need to check whether
      // location should be prefilled based on the resource.
      const firstResourceId = props.value.resourceIds[0];
      const overwriteLocation = findLocationInformation(firstResourceId);
      props.onChange(
        cloneDeep({
          ...props.value,
          ...overwriteLocation,
          fromPopup: false,
        })
      );
    } else if (props.value.fromPopup) {
      props.onChange(
        cloneDeep({
          ...props.value,
          fromPopup: false,
        })
      );
    }
  }, [findLocationInformation, props]);

  useEffect(() => {
    if (
      props.value.resourceIds &&
      props.value.resourceIds.length === 0 &&
      props.value.prepAndCleanUp
    ) {
      onChange(false, 'prepAndCleanUp');
      onChange(undefined, 'prepTimeMinutes');
      onChange(undefined, 'cleanupTimeMinutes');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value.resourceIds]);

  const isLocationFromResource = useCallback(() => {
    let isLocationFromResource = false;
    for (const resourceId of props.value.resourceIds) {
      const locationFromResource = findLocationInformation(resourceId);
      if (
        props.value.locationObj?.custom_data?.resourceId ===
          locationFromResource?.locationObj?.custom_data.resourceId ||
        props.value.locationName === locationFromResource?.locationName ||
        props.value.locationObj?.address ===
          locationFromResource?.locationObj?.address
      ) {
        isLocationFromResource = true;
        break;
      }
    }
    return isLocationFromResource;
  }, [
    findLocationInformation,
    props.value.locationName,
    props.value.locationObj?.address,
    props.value.locationObj?.custom_data?.resourceId,
    props.value.resourceIds,
  ]);

  if (!props.onChange) return null;
  /**
   * Prep and cleanup time check box handler
   */
  const handleSelectedResourcesLocation = (locationFromResources) => {
    onChange(props.resourceIds, 'useFromLocation', locationFromResources);
    setShowUseResouceLocation(false);
  };
  const handleUpdateResourcesLocation = () => {
    onChange(props.resourceIds, 'updateLocation');
  };
  // This is a unique solution to show the warning icon for EKBO termine customers.
  // All other customers will not see the warning icon and will not trigger the
  // warning from the backend.
  const evangelishTermineIssue =
    hasPartnership &&
    !isAddressFieldsEmpty &&
    !isConnectedToResource &&
    !isLocationFromResource() &&
    props.value.resourceIds.length > 0;

  const handleClearAddress = () => {
    onChange('', 'locationObj.address');
    onChange('', 'locationObj.address2');
    onChange('', 'locationObj.zipcode');
    onChange('', 'locationObj.city');
    onChange('', 'locationObj.district');
  };
  const handlePrepAndCleanUp = (e) => {
    const checked = e.target.checked;
    if (checked) {
      onChange(checked, 'prepAndCleanUp');
    } else {
      onChange(checked, 'prepAndCleanUp');
      onChange(undefined, 'prepTimeMinutes');
      onChange(undefined, 'cleanupTimeMinutes');
    }
  };

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <CdLocationSelect
          placeholder={I18nService.getString('Select resources for the event')}
          value={props.value.resourceIds.map((id) => `resource-${id}`)}
          hideNoParishBookedOption
          onChange={(value) => {
            const resourceIds = retrieveSelectedChurchesAndResource(
              value
            ).resources.map((id) => parseInt(id, 10));
            onChange(resourceIds, 'resourceIds');
          }}
          disabled={
            isEditingDisabled ||
            !eventAccess?.fields.resources.canEdit ||
            disabledByVersionHistory
          }
          showSearch={true}
        />
        <Form.Item noStyle shouldUpdate>
          <CdTooltip
            title={
              !props.value?.resourceIds.length
                ? I18nService.getString('No resources selected.')
                : props.isAllDayChecked
                  ? I18nService.getString('All day is checked.')
                  : null
            }
          >
            <Checkbox
              disabled={
                isEditingDisabled ||
                !props.value?.resourceIds.length ||
                props.isAllDayChecked ||
                !eventAccess?.fields.resources.canEdit ||
                disabledByVersionHistory
              }
              onChange={handlePrepAndCleanUp}
              checked={props.value.prepAndCleanUp}
              style={{ marginTop: '8px' }}
            >
              {I18nService.getString('Add preparation / clean up time')}
            </Checkbox>
          </CdTooltip>
        </Form.Item>
        {props.value.prepAndCleanUp ? (
          <PreparationAndCleanUpTime
            disabled={
              isEditingDisabled ||
              !eventAccess?.fields.resources.canEdit ||
              disabledByVersionHistory
            }
          />
        ) : null}
      </Col>
      {props.value?.resourceIds?.length > 0 &&
        !isLocationFromResource() &&
        (findLocationInformation(props.value.resourceIds[0])?.locationName ||
          findLocationInformation(props.value.resourceIds[0])?.locationObj
            ?.address) && (
          <Col span={24}>
            <Alert
              type="warning"
              message={getString(
                'We noticed that you changed the resources. Do you also want to update the location to <i>{{name}}<i/>',
                {
                  i: (text) => `<i>${text}</i>`,
                  name:
                    findLocationInformation(props.value.resourceIds[0])
                      ?.locationName ||
                    findLocationInformation(props.value.resourceIds[0])
                      ?.locationObj.address,
                }
              )}
              action={
                <Button size="small" onClick={handleUpdateResourcesLocation}>
                  {I18nService.getString('Update location')}
                </Button>
              }
            />
          </Col>
        )}
      {props.value?.resourceIds?.length > 0 &&
        !isLocationFromResource() &&
        !(
          findLocationInformation(props.value.resourceIds[0])?.locationName ||
          findLocationInformation(props.value.resourceIds[0])?.locationObj
            ?.address
        ) && (
          <Col span={24}>
            <Alert
              type="warning"
              message={getString(
                'We noticed that you changed the resources. Do you also want to remove the location <i>{{name}}<i/>',
                {
                  i: (text) => `<i>${text}</i>`,
                  name: props.value?.locationName,
                }
              )}
              action={
                <Button size="small" onClick={handleUpdateResourcesLocation}>
                  {I18nService.getString('Remove location')}
                </Button>
              }
            />
          </Col>
        )}
      <Col span={24}>
        <Collapse expandIconPosition="end">
          <Panel
            header={
              <CdTooltip
                title={
                  evangelishTermineIssue &&
                  I18nService.getString(
                    'The address is different than the address connected to the booked resource. Consider using the resource address instead.'
                  )
                }
                getPopupContainer={(trigger) => trigger.parentElement}
              >
                <Row style={{ width: '100%' }}>
                  <Col flex="28px">
                    <EventIcons.Location />
                  </Col>
                  <Col flex="1 1 0">
                    <Space
                      direction="vertical"
                      style={{ width: '100%', gap: '0px' }}
                    >
                      <Text strong>
                        {props.value.locationName ||
                          I18nService.getString('No location name specified')}
                      </Text>
                      {isAddressFieldsEmpty && (
                        <span>
                          {props.value.locationObj?.string ||
                            I18nService.getString('Address is not specified')}
                        </span>
                      )}
                      {!isAddressFieldsEmpty && (
                        <span>
                          {getAddressFormat({
                            address,
                            address2,
                            zipcode,
                            city,
                            state,
                          })}
                        </span>
                      )}
                    </Space>
                  </Col>
                  {evangelishTermineIssue && (
                    <Col flex="0 1 auto">
                      <Row
                        justify="space-around"
                        align="middle"
                        style={{ height: '100%' }}
                      >
                        <Col>
                          <EventIcons.LocationIssue size="lg" type="warning" />
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </CdTooltip>
            }
            key="1"
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '506px',
              }}
            >
              {props.value.resourceIds.length > 0 &&
                !showUseResouceLocation && (
                  <div>
                    <Button
                      type="link"
                      icon={<EventIcons.ShowResourceSelector />}
                      style={{ padding: '0px' }}
                      onClick={() => setShowUseResouceLocation(true)}
                      disabled={
                        isEditingDisabled ||
                        !eventAccess?.fields.resources.canEdit ||
                        disabledByVersionHistory
                      }
                    >
                      {I18nService.getString('Use location from resource')}
                    </Button>
                  </div>
                )}
              {props.value.resourceIds.length > 0 && showUseResouceLocation && (
                <Form.Item
                  label={I18nService.getString('Use location from resource')}
                  shouldUpdate
                >
                  <SelectedResourcesLocation
                    resources={resources}
                    selectedResourceIds={props.value.resourceIds}
                    handleSelectedResourcesLocation={
                      handleSelectedResourcesLocation
                    }
                  />
                </Form.Item>
              )}
              <Form.Item
                label={I18nService.getString('Location name')}
                shouldUpdate
              >
                <Input
                  value={props.value.locationName}
                  onChange={(e) => onChange(e.target.value, 'locationName')}
                  disabled={
                    isEditingDisabled ||
                    !eventAccess?.fields.locationName.canEdit ||
                    disabledByVersionHistory
                  }
                  placeholder={I18nService.getString('Add location name')}
                />
              </Form.Item>
              <AddressHeader>
                <Typography.Text strong>
                  {I18nService.getString('Street address')}
                </Typography.Text>
                <Button
                  disabled={
                    isEditingDisabled ||
                    !eventAccess?.fields.location.canEdit ||
                    disabledByVersionHistory
                  }
                  type="link"
                  style={{ margin: '8px', height: '24px' }}
                  onClick={handleClearAddress}
                >
                  {I18nService.getString('Clear address')}
                </Button>
              </AddressHeader>
              <Form.Item>
                <Input
                  value={props.value.locationObj?.address}
                  onChange={(e) =>
                    onChange(e.target.value, 'locationObj.address')
                  }
                  disabled={
                    isEditingDisabled ||
                    !eventAccess?.fields.locationObj.canEdit ||
                    disabledByVersionHistory
                  }
                  placeholder={I18nService.getString('Add street address')}
                />
              </Form.Item>

              <Form.Item label={I18nService.getString('Street address 2')}>
                <Input
                  value={props.value.locationObj?.address2}
                  onChange={(e) =>
                    onChange(e.target.value, 'locationObj.address2')
                  }
                  disabled={
                    isEditingDisabled ||
                    !eventAccess?.fields.locationObj.canEdit ||
                    disabledByVersionHistory
                  }
                  placeholder={I18nService.getString('Add street address 2')}
                />
              </Form.Item>
              <Form.Item label={I18nService.getString('District/Area')}>
                <DistrictField
                  onChange={(value) => onChange(value, 'locationObj.district')}
                  value={props.value.locationObj?.district}
                  disabled={
                    isEditingDisabled ||
                    !eventAccess?.fields.locationObj.canEdit ||
                    disabledByVersionHistory
                  }
                />
              </Form.Item>
            </div>
            <Row gutter={16}>
              <Col flex="150px">
                <Form.Item label={I18nService.getString('Postal Code')}>
                  <Input
                    value={props.value.locationObj?.zipcode}
                    onChange={(e) =>
                      onChange(e.target.value, 'locationObj.zipcode')
                    }
                    disabled={
                      isEditingDisabled ||
                      !eventAccess?.fields.locationObj.canEdit ||
                      disabledByVersionHistory
                    }
                    placeholder={I18nService.getString('Add postal code')}
                  />
                </Form.Item>
              </Col>
              <Col flex="auto">
                <Form.Item label={I18nService.getString('City')}>
                  <Input
                    value={props.value.locationObj?.city}
                    onChange={(e) =>
                      onChange(e.target.value, 'locationObj.city')
                    }
                    disabled={
                      isEditingDisabled ||
                      !eventAccess?.fields.locationObj.canEdit ||
                      disabledByVersionHistory
                    }
                    placeholder={I18nService.getString('Add city')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Col>
      {/* Hide MultiChurch in order to conserve the values in the form. */}
      <Col span={24} style={{ display: isMultiChurch ? 'block' : 'none' }}>
        <FormItemWithAccess
          label={
            <Space size={4}>
              {props.eventVisibility !== 'private' ? (
                <Text type="danger">*</Text>
              ) : undefined}
              {I18nService.getString('Parish calendar for event')}
            </Space>
          }
          style={{ marginBottom: '0px' }}
          fieldAccessName="churchIds"
          name={['resourcesLocation', 'churchIds']}
          rules={[
            () => ({
              validator(rule, value) {
                if (
                  props.eventVisibility !== 'private' &&
                  value?.length === 0
                ) {
                  return Promise.reject(
                    I18nService.getString(
                      'Please select a parish calendar for the event.'
                    )
                  );
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <CdParishSelect value={props.value.churchIds} />
        </FormItemWithAccess>
      </Col>
    </Row>
  );
};
