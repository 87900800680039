import { backendApiClient } from '@/react/api';
import {
  PaginatedResult,
  RotaScheme,
} from '@/react/calendar/rota-scheme/types/rotaScheme.types';
import { CancelablePromise } from '@/generated/api';

type GetShiftRotasLinkParams = Parameters<
  typeof backendApiClient.rotas.getShiftsRotasLink
>[0];

export const rotaShiftQueries = {
  getAllSchemes: (
    params: GetShiftRotasLinkParams = {
      desc: false,
      orderBy: 'name',
    }
  ) => ({
    queryKey: ['rotaShift', 'schemes', params],
    queryFn: async () =>
      backendApiClient.rotas.getShiftsRotasLink(
        params
      ) as unknown as CancelablePromise<PaginatedResult<RotaScheme>>,
  }),
};
