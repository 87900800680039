/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ExternalDataImporterService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Manually trigger the reload of the external data source specified
     * @throws ApiError
     */
    public postPeopleReload({
        source,
    }: {
        source: 'ekir-mewis',
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/reload/{source}',
            path: {
                'source': source,
            },
        });
    }
}
