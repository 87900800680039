import { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { SagaIterator } from 'redux-saga';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { groupsLoaded, selectAllGroups } from '../../../shared/store/groups';
import { selectGroupsIds } from '../../../shared/store/groups/groupsSlice';
import { FEvent } from '../../models/calendar';
import {
  reLoadAndUpdateFilterData,
  withUpdateFilterData,
} from '../../sagas/utils';
import CalendarService from '../../services/CalendarService';

import {
  AbsencesActions,
  AbsenceSelectors,
  GroupAbsence,
  selectGroupsAbsences,
} from './absencesSlice';

import { Group } from '@/react/shared/services/GroupsService';

function* loadMultipleAbsences({
  payload,
}: PayloadAction<number[]>): SagaIterator {
  const absences: FEvent[] = yield call(
    CalendarService.loadMultipleAbsences,
    payload
  );
  yield put(AbsencesActions.absencesLoaded(absences));
}

function* selectAllGroupAbsences({ payload }) {
  const groups: Group[] = yield select(selectAllGroups);
  const isAllSelected = yield select(
    AbsenceSelectors.isAllGroupsSelected(payload.lowerCaseSearchName)
  );
  const visibleGroups = payload.lowerCaseSearchName
    ? groups.filter((group) =>
        group.name.toLowerCase().includes(payload.lowerCaseSearchName)
      )
    : groups;
  yield put(
    AbsencesActions.toggleAllGroupAbsenceSuccess({
      groupIds: visibleGroups.map((g) => g.id),
      checked: !isAllSelected,
    })
  );
  yield fork(reLoadAndUpdateFilterData);
}

function* updateAbsenceFiltersOnGroupsRefresh() {
  const selectedAbsenceGroups = selectGroupsAbsences(yield select());
  const groupIds = selectGroupsIds(yield select());
  const result = _.pick(selectedAbsenceGroups, groupIds);
  yield put(AbsencesActions.setGroupAbsences(result as GroupAbsence));
}

export default function* absenceSaga() {
  yield all([
    takeLatest(
      AbsencesActions.toggleGroupAbsence.type,
      reLoadAndUpdateFilterData
    ),
    takeLatest(
      AbsencesActions.toggleMinimizedView.type,
      reLoadAndUpdateFilterData
    ),
    takeLatest(
      AbsencesActions.toggleAllGroupAbsenceRequest.type,
      withUpdateFilterData(selectAllGroupAbsences)
    ),
    takeLatest(AbsencesActions.loadAbsence.type, loadMultipleAbsences),
    takeLatest(
      groupsLoaded.type,
      withUpdateFilterData(updateAbsenceFiltersOnGroupsRefresh)
    ),
  ]);
}
