import { get, isNil } from 'lodash';
import moment from 'moment';
import { Space, Descriptions } from 'antd';
import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

import { Stole, StoleStatusTypes } from '../../models/stole';
import { I18nService } from '../../../services/I18nService';
import { PaymentMethodTypes } from '../../models/intention';

// Props
export interface EventStoleDetailsProps {
  stole: Stole;
}

const EventStoleDetails: FunctionComponent<EventStoleDetailsProps> = ({
  stole,
}) => (
  <Space direction="vertical">
    <Descriptions
      title={I18nService.getString('Details')}
      column={{ xs: 1, sm: 2, md: 3, xl: 4 }}
      size="small"
      layout="horizontal"
    >
      {get(stole, 'formattedReferenceNumber') ? (
        <Descriptions.Item label={I18nService.getString('Number')}>
          {get(stole, 'formattedReferenceNumber')}
        </Descriptions.Item>
      ) : null}
      {get(stole, 'church') ? (
        <Descriptions.Item label={I18nService.getString('Church')}>
          {get(stole, 'church.name')}
        </Descriptions.Item>
      ) : null}
      {get(stole, 'resource') ? (
        <Descriptions.Item label={I18nService.getString('Resource')}>
          {get(stole, 'resource.name')}
        </Descriptions.Item>
      ) : null}
      {get(stole, 'fee.name') ? (
        <Descriptions.Item label={I18nService.getString('Fee')}>
          {get(stole, 'fee.name')}
        </Descriptions.Item>
      ) : null}
      {get(stole, 'fee.amount') ? (
        <Descriptions.Item label={I18nService.getString('Amount')}>
          <Space>
            {get(window, 'cdApp.organization.currency')}
            {get(stole, 'fee.amount')}
          </Space>
        </Descriptions.Item>
      ) : null}
      {get(stole, 'paymentMethod') ? (
        <Descriptions.Item label={I18nService.getString('Payment Method')}>
          {get(stole, 'paymentMethod') === PaymentMethodTypes.PAIDBYCASH
            ? I18nService.getString('Cash')
            : I18nService.getString('Wire transfer')}
        </Descriptions.Item>
      ) : null}
      {!isNil(get(stole, 'paid')) ? (
        <Descriptions.Item label={I18nService.getString('Paid?')}>
          {get(stole, 'paid') ? (
            <FontAwesomeIcon icon={faCheckCircle} />
          ) : (
            <FontAwesomeIcon icon={faTimesCircle} />
          )}
        </Descriptions.Item>
      ) : null}
      {get(stole, 'status') ? (
        <Descriptions.Item label={I18nService.getString('Status')}>
          {get(stole, 'status') === StoleStatusTypes.OPEN
            ? I18nService.getString('Open')
            : null}
          {get(stole, 'status') === StoleStatusTypes.COMPLETED
            ? I18nService.getString('Completed')
            : null}
        </Descriptions.Item>
      ) : null}
      {get(stole, 'stoleText') ? (
        <Descriptions.Item label={I18nService.getString('Stole text')}>
          {get(stole, 'stoleText')}
        </Descriptions.Item>
      ) : null}
      {get(stole, 'comment') ? (
        <Descriptions.Item label={I18nService.getString('Comment')}>
          {get(stole, 'comment')}
        </Descriptions.Item>
      ) : null}
    </Descriptions>
    <Descriptions
      title={I18nService.getString('Activity')}
      column={{ xs: 1, sm: 2, md: 3, xl: 4 }}
      size="small"
      layout="horizontal"
    >
      {get(stole, 'createdAt') ? (
        <Descriptions.Item label={I18nService.getString('Created on')}>
          {moment(get(stole, 'createdAt')).format('lll')}
        </Descriptions.Item>
      ) : null}
      {get(stole, 'updatedBy') ? (
        <Descriptions.Item label={I18nService.getString('Updated by')}>
          {get(stole, 'updatedBy.contact.fullName')}
        </Descriptions.Item>
      ) : null}
      {get(stole, 'updatedAt') ? (
        <Descriptions.Item label={I18nService.getString('Updated on')}>
          {moment(get(stole, 'updatedAt')).format('lll')}
        </Descriptions.Item>
      ) : null}
      {get(stole, 'deletedAt') ? (
        <Descriptions.Item label={I18nService.getString('Deleted on')}>
          {moment(get(stole, 'deletedAt')).format('lll')}
        </Descriptions.Item>
      ) : null}
    </Descriptions>
  </Space>
);

export default EventStoleDetails;
