/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostIntentionSettingsRequestBody = {
    intentionReferenceNumberType: PostIntentionSettingsRequestBody.intentionReferenceNumberType;
    intentionMigrationReferenceNumber?: number | null;
};
export namespace PostIntentionSettingsRequestBody {
    export enum intentionReferenceNumberType {
        PARISH_SERIES = 'parishSeries',
        ORG_SERIES = 'orgSeries',
    }
}

