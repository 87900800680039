import _ from 'lodash';
import moment from 'moment';
import React, { useCallback } from 'react';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { getLocale } from '../../config/store/Selector';
import { AppState } from '../../redux';
import { selectIsUserFiltersLoaded } from '../store/filters/Selectors';
import { GoToDateAction } from '../store/main-view/Actions';

import YearMonthForm from './YearMonthForm';

const StyledDayPicker = styled(DayPicker)`
  &&&& {
    display: flex;
    justify-content: center;
    font-size: 1.1rem;
    overflow: 'initial';
    .DayPicker-wrapper {
      &:focus {
        outline: none;
        pointer-events: none;
      }

      .DayPicker-NavBar {
        .DayPicker-NavButton {
          margin-top: 0;
          font: normal normal normal 14px/1 'Font Awesome 5 Pro';
          background-image: none;
          font-size: 1.6rem;
          display: flex;
          align-items: center;
          justify-content: center;
            

          &:hover,
          &:focus {
            background-color: #eee;
            border-radius: 50%;
            outline: none;
          }
        }
          
        .DayPicker-NavButton--prev {
          top: 0.7em;
          left: 0.5em;
          &::after {
            content: '\\f104';
          }
        }
          
        .DayPicker-NavButton--next {
          top: 0.7em;
          right: 0.5em;
          &::after {
              content: '\\f105';
          }
        }
      }

      .DayPicker-Month {
        .DayPicker-Caption {
            margin-left: 16px;
          .custom-select {
            width: 90px;
            display: inline-block;
              
            &:last-child {
                width: 60px
            }

            select {
              font-size: 1em;
              border: none;
              text-transform: capitalize;
              border-bottom: 1px solid #eee;
              border-radius: 0;
              background: none;

              &:not([multiple]) {
                -webkit-appearance: none;
                -moz-appearance: none;
                background-position: right 50%;
                background-repeat: no-repeat;
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
                padding: 0 0.3em;
                padding-right: 1.5em;
              }

              &:first-child {
                margin-right: 5px;
              }

              &::-ms-expand {
                display: none;
              }

              &:hover,
              &:focus {
                background-color: #eee;
                border-radius: 4px;
                cursor: pointer;
                outline: none;
              }
            }
          }
        }

        .DayPicker-Week {
          .DayPicker-Day--outside {
            background: none !important;
          }
        }
      }

      .DayPicker-Weekday {
        font-size: 1rem;

        abbr[title],
        abbr[data-original-title] {
          cursor: default;
          text-transform: uppercase;
        }
      }

      .DayPicker-WeekNumber {
        display: flex;
        font-size: 1.1rem;
        padding: 0.4em;
        height: 25px;
        margin-right: 2px;
        cursor: default;

        &:hover {
          cursor: default;
          pointer-events: none;
        }
        &:focus {
          outline: none;
          background-color: #eee;
        }
      }

      .DayPicker-Day {
        padding: 0.5em;
        width: 25px;

        /* Remove default hover and focus styles for special dates */
        &:not(.DayPicker-Day--outside):not(.DayPicker-Day--today):not(.DayPicker-Day--selected):hover {
          background-color: #eee !important;
        }
        &:not(.DayPicker-Day--outside):not(.DayPicker-Day--today):not(.DayPicker-Day--selected):focus {
          outline: none;
          background-color: #eee;
        }
        /* Selected day */
        &.DayPicker-Day--selected {
          border-radius: 50%;
          background-color: #008cb633; /* Fallback color */
          background-color: rgba(0, 140, 182, 0.2);
          color: rgb(0, 140, 182) !important;
          outline: none;
        }
        /* Today's date */
        &.DayPicker-Day--today {
          border-radius: 50%;
          background-color: rgb(0, 140, 182) !important;
          color: #fff !important;
          outline: none;
        }
      }
    }
  }
`;

const SelectDatePicker = () => {
  const isUserFiltersLoaded = useSelector(selectIsUserFiltersLoaded);
  const selectedDate = useSelector(
    (state: AppState) => state.calendar.view.selectedDate,
    _.isEqual
  ) as Date;
  const modifiers = {
    selectedDate,
  };
  const modifiersStyles = {
    selectedDate: {},
  };
  const locale = useSelector(getLocale, _.isEqual);
  const dispatch = useDispatch();
  const onDateChanged = useCallback(
    (day): void => {
      if (isUserFiltersLoaded) {
        dispatch(GoToDateAction(day));
      }
    },
    [isUserFiltersLoaded, dispatch]
  );
  return (
    <StyledDayPicker
      showWeekNumbers
      firstDayOfWeek={1}
      selectedDays={[moment(selectedDate).toDate()]}
      localeUtils={MomentLocaleUtils}
      locale={locale}
      modifiers={modifiers}
      modifiersStyles={modifiersStyles}
      onDayClick={onDateChanged}
      month={moment(selectedDate).toDate()}
      captionElement={({ date }) => (
        <YearMonthForm date={date} onChange={onDateChanged} />
      )}
    />
  );
};

export default SelectDatePicker;
