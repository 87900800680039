import { useMutation, useQuery } from '@tanstack/react-query';

import { Taxonomy } from '../../../shared/models/taxonomy';

import {
  getTimeRegistrationReportUsers,
  timeRegistrationReportExport,
} from '@/react/calendar/reports/service/timeRegistrationReport';
import {
  getSearchCalendarAbsenceCategories,
  getSearchCalendarCategories,
  getSearchCalendarGroups,
  getSearchCalendarPrintReport,
  getSearchCalendarUsers,
} from '@/react/calendar/reports/service/searchCalendarReport';
import {
  Groups,
  TimeRegistrationUser,
} from '@/react/calendar/reports/types/report.types';
import { User } from '@/react/user/types/User.types';

export const useTimeRegistrationReportUsers = () => {
  const { data } = useQuery<TimeRegistrationUser[]>({
    queryKey: ['useTimeRegistrationReportUsers'],
    queryFn: async () => await getTimeRegistrationReportUsers(),
  });

  return {
    timeRegistrationReportUsers: data?.sort((a, b) =>
      (a.name || a.email).localeCompare(b.name || b.email)
    ),
  };
};

export const useSearchCalendarUsers = () => {
  const { data } = useQuery<User[]>({
    queryKey: ['useSearchCalendarUsers'],
    queryFn: async () => await getSearchCalendarUsers(),
  });

  return {
    searchCalendarUsers: data,
  };
};

export const useSearchCalendarGroups = () => {
  const { data } = useQuery<Groups[]>({
    queryKey: ['useSearchCalendarGroups'],
    queryFn: async () => await getSearchCalendarGroups(),
  });

  return {
    searchCalendarGroups: data,
  };
};

export const useSearchCalendarCategories = () => {
  const { data } = useQuery<Taxonomy[]>({
    queryKey: ['useSearchCalendarCategories'],
    queryFn: async () => await getSearchCalendarCategories(),
  });

  return {
    searchCalendarCategories: data,
  };
};

export const useSearchCalendarAbsenceCategories = () => {
  const { data } = useQuery<Taxonomy[]>({
    queryKey: ['useSearchCalendarAbsenceCategories'],
    queryFn: async () => await getSearchCalendarAbsenceCategories(),
  });

  return {
    searchCalendarAbsenceCategories: data,
  };
};

export const useTimeRegistrationReportExport = () => {
  const { mutate } = useMutation({
    mutationFn: timeRegistrationReportExport,
  });
  return { exportTimeRegistrationReport: mutate };
};

export const useSearchCalendarPrint = () => {
  const { mutateAsync, isPending } = useMutation({
    mutationFn: getSearchCalendarPrintReport,
  });
  return { searchCalendarPrint: mutateAsync, isLoading: isPending };
};
