/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeleteQueueJobsRequestBody } from '../models/DeleteQueueJobsRequestBody';
import type { GetQueueJobsResponseBody } from '../models/GetQueueJobsResponseBody';
import type { GetQueueTypesResponseBody } from '../models/GetQueueTypesResponseBody';
import type { PostQueueJobsRetryRequestBody } from '../models/PostQueueJobsRetryRequestBody';
import type { PostQueueJobsRetryResponseBody } from '../models/PostQueueJobsRetryResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class QueueService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Retrieve a list of queued jobs
     * Use this endpoint to retrieve a list of queued backend jobs. This is only for internal use, and can only be accessed by user with ChurchDesk Developer role.
     * @returns GetQueueJobsResponseBody
     * @throws ApiError
     */
    public getQueueJobs({
        pagination,
        filters,
    }: {
        pagination: {
            pageSize?: number;
            pageNumber?: number;
        },
        filters?: {
            queues?: (((string | '') | Array<string>) | Record<string, string>);
            classes?: (((string | '') | Array<string>) | Record<string, string>);
            status?: 'pending' | 'scheduled' | 'running' | 'succeeded' | 'failed' | 'retried';
            ids?: Array<number>;
        },
    }): CancelablePromise<GetQueueJobsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/queue/jobs',
            query: {
                'pagination': pagination,
                'filters': filters,
            },
        });
    }
    /**
     * Delete failed jobs filtered by their IDs, queues and/or classes
     * @throws ApiError
     */
    public deleteQueueJobs({
        requestBody,
    }: {
        requestBody?: DeleteQueueJobsRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/queue/jobs',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retry failed jobs filtered by their IDs, queues and/or classes
     * @returns PostQueueJobsRetryResponseBody
     * @throws ApiError
     */
    public postQueueJobsRetry({
        requestBody,
    }: {
        requestBody?: PostQueueJobsRetryRequestBody,
    }): CancelablePromise<PostQueueJobsRetryResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/queue/jobs/retry',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retrieve lists of possible queues and classes
     * @returns GetQueueTypesResponseBody
     * @throws ApiError
     */
    public getQueueTypes(): CancelablePromise<GetQueueTypesResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/queue/types',
        });
    }
}
