/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class RoomsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetRooms
     * Index
     * @returns EmptyObject
     * @throws ApiError
     */
    public getRooms({
        start,
        end,
    }: {
        start?: string | null,
        end?: string | null,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rooms',
            query: {
                'start': start,
                'end': end,
            },
        });
    }
}
