import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { Typography } from 'antd';

const { Text } = Typography;

const ColorItem = styled.div`
  display: inline;
  width: 1.9em;
  font-size: 1.9em;
  cursor: pointer;
  margin-right: 5px;
`;

const ColorContainer = styled.div`
  width: 55%;
`;

import {
  COLOR_INDEX,
  COLOR_INDEX_ORDERING_TABLE,
} from '../../../app/ResourceColors';
import { I18nService } from '../../services/I18nService';

interface ColorSelectorProps {
  onChange?: (index: number) => void;
  value?: number;
}
export const ColorSelector: FunctionComponent<ColorSelectorProps> = ({
  onChange,
  value,
}) => (
  <ColorContainer>
    <Text style={{ color: '#333' }} strong>
      {I18nService.getString('Select color')}
    </Text>
    <div style={{ marginTop: 10 }}>
      {COLOR_INDEX_ORDERING_TABLE.map((index) => (
        <ColorItem
          onClick={(e) => {
            e.stopPropagation();
            onChange(index);
          }}
          key={index}
        >
          <span>
            {index === value ? (
              <FontAwesomeIcon
                size="lg"
                icon={faCheckCircle}
                style={{ color: COLOR_INDEX[index] }}
              />
            ) : (
              <FontAwesomeIcon
                size="lg"
                icon={faCircle}
                style={{ color: COLOR_INDEX[index] }}
              />
            )}
          </span>
        </ColorItem>
      ))}
    </div>
  </ColorContainer>
);
