/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { GetResourcesDistrictResponseBody } from '../models/GetResourcesDistrictResponseBody';
import type { PostResourceRequestBody } from '../models/PostResourceRequestBody';
import type { PostResourcesResponseBody } from '../models/PostResourcesResponseBody';
import type { PutRequestRequestBody } from '../models/PutRequestRequestBody';
import type { PutResourcesResponseBody } from '../models/PutResourcesResponseBody';
import type { PutResourcesUpdatelocationRequestBody } from '../models/PutResourcesUpdatelocationRequestBody';
import type { RetrieveResourceResponseBody } from '../models/RetrieveResourceResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ResourcesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create the calendar resource
     * Use this endpoint to create the calendar resource
     * @returns PostResourcesResponseBody
     * @throws ApiError
     */
    public postResources({
        requestBody,
    }: {
        requestBody?: PostResourceRequestBody,
    }): CancelablePromise<PostResourcesResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/resources',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetFullListOfResources
     * Index
     * @returns EmptyObject
     * @throws ApiError
     */
    public getResources(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/resources',
        });
    }
    /**
     * GetChurches
     * @returns RetrieveResourceResponseBody Success-Response
     * @throws ApiError
     */
    public getResourcesChurches({
        permissionContext,
        accessToken,
        permissionType = 'create',
        version = 2,
    }: {
        permissionContext: 'form' | 'contribution' | 'people' | 'calendar',
        accessToken?: string,
        permissionType?: string,
        version?: 1 | 2 | 3,
    }): CancelablePromise<RetrieveResourceResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/resources/churches',
            query: {
                'access_token': accessToken,
                'permissionContext': permissionContext,
                'permissionType': permissionType,
                'version': version,
            },
        });
    }
    /**
     * PostResourcesCopyResourceNameToLocationName
     * @returns EmptyObject
     * @throws ApiError
     */
    public postResourcesCopyResourceNameToLocationName({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/resources/copy-resource-name-to-location-name',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get the list of districts from resources
     * Use this endpoint to get the list of districts from resources
     * @returns GetResourcesDistrictResponseBody
     * @throws ApiError
     */
    public getResourcesDistrict({
        widgetId,
        type,
    }: {
        widgetId?: string | null,
        type?: 'event' | 'blog' | 'slideshow' | 'donation' | null,
    }): CancelablePromise<GetResourcesDistrictResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/resources/district',
            query: {
                'widgetId': widgetId,
                'type': type,
            },
        });
    }
    /**
     * GetResourceLocations
     * Get locations
     * @returns EmptyObject
     * @throws ApiError
     */
    public getResourcesLocations(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/resources/locations',
        });
    }
    /**
     * UpdateLocation
     * Update Location
     * @returns EmptyObject
     * @throws ApiError
     */
    public putResourcesUpdateLocation({
        requestBody,
    }: {
        requestBody?: PutResourcesUpdatelocationRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/resources/update-location',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeleteResource
     * Delete
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteResources({
        id,
        replacementId,
    }: {
        id: number,
        replacementId?: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/resources/{id}',
            path: {
                'id': id,
            },
            query: {
                'replacementId': replacementId,
            },
        });
    }
    /**
     * RetrieveResource
     * Retrieve
     * @returns RetrieveResourceResponseBody Success-Response
     * @throws ApiError
     */
    public getResources1({
        id,
    }: {
        id: number,
    }): CancelablePromise<RetrieveResourceResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/resources/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Create the calendar resource
     * Use this endpoint to create the calendar resource
     * @returns PutResourcesResponseBody
     * @throws ApiError
     */
    public putResources({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PutRequestRequestBody,
    }): CancelablePromise<PutResourcesResponseBody> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/resources/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
