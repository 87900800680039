import React, { useState } from 'react';
import { Modal } from 'antd';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import 'react-image-crop/dist/ReactCrop.css';
import { Button, Divider } from 'antd';
import styled from 'styled-components';

import useNewsletterList, { SetUpType } from '../hooks/useNewsletterList';

import { I18nService } from '@/react/services/I18nService';
import {
  CdFile,
  CdCSV,
  CdEXCEL,
  CdCheckIcon,
} from '@/react/shared/components/Icons';
import cdApp from '@/react/config';

const Container = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 4px;
`;
const CardButton = styled(Button)`
  &&&& {
    display: flex;
    flex-direction: column;
    height: 160px;
    width: 200px;
    align-items: center;
    justify-content: center;
  }
`;
const NotificationResult = styled.div`
  border: 1px solid #6bd66a;
  background-color: #d0f9d0;
  margin: 8px;
  display: flex;
  gap: 0 16px;
  border-radius: 10px;
`;
interface ExportContactsFromNewsletterOrFilterProps {
  setup: SetUpType;
}

export const ExportContactsFromNewsletterOrFilterModal = NiceModal.create(
  ({ setup }: { setup: SetUpType }) => (
    <ExportContactsFromNewsletterOrFilter setup={setup} />
  )
);

export const ExportContactsFromNewsletterOrFilter = ({
  setup,
}: ExportContactsFromNewsletterOrFilterProps) => {
  const modal = useModal('ExportContactsFromNewsletterOrFilter');
  const [showNotificationResult, setShowNotificationResult] = useState(false);
  const { exportContacts } = useNewsletterList();

  const handleClick = async (fileType: string) => {
    await exportContacts(cdApp.organization.id, setup, fileType);
    setShowNotificationResult(true);
  };
  return (
    <Modal
      {...{
        ...antdModalV5(modal),
        maskClosable: false,
        width: 800,
        title: I18nService.getString(`Choose a file type`),
        bodyStyle: { padding: 16 },
        onCancel: modal.remove,
        okText: I18nService.getString('Save'),
        okButtonProps: { style: { display: 'none' } },
      }}
    >
      <Container>
        {showNotificationResult === false && (
          <>
            <CardButton onClick={() => handleClick('csv')}>
              <CdCSV size="3x" style={{ color: '#1CB4E4' }} />
              <Divider />
              <h5>CSV (.csv)</h5>
            </CardButton>
            <CardButton onClick={() => handleClick('xlsx')}>
              <CdFile size="3x" style={{ color: '#1CB4E4' }} />
              <Divider />
              <h5>Microsoft Excel (.xlsx)</h5>
            </CardButton>
            <CardButton onClick={() => handleClick('ods')}>
              <CdEXCEL size="3x" style={{ color: '#1CB4E4' }} />
              <Divider />
              <h5>Open Document Sheet (.ods)</h5>
            </CardButton>
          </>
        )}
        {showNotificationResult === true && (
          <NotificationResult>
            <CdCheckIcon
              size="3x"
              style={{ color: '#6BD66A', margin: '4px' }}
            />
            <span style={{ color: '#6BD66A', margin: '4px' }}>
              {I18nService.getString(
                'It will take a couple of minutes to gather all contact data. You will receive an email with a download link, when the data is ready to be downloaded.'
              )}
            </span>
          </NotificationResult>
        )}
      </Container>
    </Modal>
  );
};
