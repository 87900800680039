import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { useCalendarSearchContext } from '@/react/calendar/components/sidebar-search/CalendarSearchContext';
import { MyCalendarSelectionState } from '@/react/calendar/store/filters/types';
import { selectMyCalendarsSelectionState } from '@/react/calendar/store/filters/Selectors';
import { Me } from '@/react/shared/models/authentication';
import { getCurrentUser } from '@/react/config/store/Selector';
import FeatureToggleService from '@/react/services/FeatureToggleService';
import { I18nService } from '@/react/services/I18nService';
import { toggleAllMyCalendars as toggleAllMyCalendarsAction } from '@/react/calendar/store/filters/Actions';
import { CalendarSubscription } from '@/react/calendar/models/calendar';

type CalendarState = {
  id?: string;
  type: 'me' | 'collections' | 'national' | 'liturgical' | 'shared';
  isSelected: boolean;
  displayName: string;
  calendar?: CalendarSubscription;
};

export const useMyCalendarsData = () => {
  const [collectionActive, setCollectionActive] = useState<boolean>(false);
  const { lowerCaseSearchName } = useCalendarSearchContext();
  const me: Me = useSelector(getCurrentUser, _.isEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    FeatureToggleService.hasFeature('collection').then(setCollectionActive);
  }, []);

  const selectionState: MyCalendarSelectionState = useSelector(
    selectMyCalendarsSelectionState
  );

  const allCalendars = useMemo(() => {
    const calendarStates: CalendarState[] = [
      {
        type: 'me',
        isSelected: selectionState.me,
        displayName: me.fullName ?? me.email,
      },
      ...(collectionActive
        ? [
            {
              type: 'collections',
              isSelected: selectionState.collections,
              displayName: I18nService.getString('Collection'),
            } satisfies CalendarState,
          ]
        : []),
      {
        type: 'national',
        isSelected: selectionState.national,
        displayName: I18nService.getString('National holidays'),
      },
      {
        type: 'liturgical',
        isSelected: selectionState.liturgical,
        displayName: I18nService.getString('Liturgical calendar'),
      },
      ...selectionState.shared.map(
        (sharedCalendar) =>
          ({
            id: sharedCalendar.calendar.id,
            type: 'shared',
            isSelected: sharedCalendar.isSelected,
            displayName: sharedCalendar.calendar.name,
            calendar: sharedCalendar.calendar,
          }) satisfies CalendarState
      ),
    ];
    return calendarStates;
  }, [
    collectionActive,
    me.email,
    me.fullName,
    selectionState.collections,
    selectionState.liturgical,
    selectionState.me,
    selectionState.national,
    selectionState.shared,
  ]);

  const visibleCalendars = useMemo(() => {
    if (!lowerCaseSearchName) {
      return allCalendars;
    } else {
      return allCalendars.filter((state) =>
        state.displayName.toLowerCase().includes(lowerCaseSearchName)
      );
    }
  }, [allCalendars, lowerCaseSearchName]);

  const isAllSelected = useMemo(() => {
    if (
      visibleCalendars.length > 0 &&
      visibleCalendars.every((s) => s.isSelected)
    ) {
      return true;
    }
    if (visibleCalendars.some((s) => s.isSelected)) {
      return null;
    }
    return false;
  }, [visibleCalendars]);

  const toggleAllMyCalendars = useCallback(() => {
    const targetState: Partial<MyCalendarSelectionState> = {
      shared: [],
    };

    visibleCalendars.forEach((c) => {
      if (c.type === 'shared') {
        targetState.shared.push({
          calendar: c.calendar!,
          isSelected: !isAllSelected,
        });
      } else {
        targetState[c.type] = !isAllSelected;
      }
    });

    allCalendars.forEach((c) => {
      if (c.type === 'shared') {
        if (
          !targetState.shared.some(
            (visibleCal) => c.calendar.id === visibleCal.calendar.id
          )
        ) {
          targetState.shared.push({
            calendar: c.calendar!,
            isSelected: c.isSelected,
          });
        }
      } else if (targetState[c.type] === undefined) {
        targetState[c.type] = c.isSelected;
      }
    });

    dispatch(
      toggleAllMyCalendarsAction(targetState as MyCalendarSelectionState)
    );
  }, [allCalendars, dispatch, isAllSelected, visibleCalendars]);

  return { isAllSelected, collectionActive, toggleAllMyCalendars };
};
