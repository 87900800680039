import { Radio } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';

import { CdVerticalSpace } from '../cd-vertical-space/CdVerticalSpace';

export interface CdRadioGroupProps<T = string | number> {
  value?: T;
  onChange?: (value: T) => void;
  options: { label: string; value: T; disabled?: boolean }[];
  disabled?: boolean;
}

export const CdRadioGroup = <T extends string | number>(
  props: CdRadioGroupProps
) => {
  const [value, _setValue] = useState(props.value || props.options?.[0]?.value);
  const setValue = (value: T) => {
    _setValue(value);
    props.onChange?.(value);
  };

  return (
    <Radio.Group
      value={value}
      onChange={(e) => setValue(e.target.value)}
      disabled={props.disabled}
    >
      <CdVerticalSpace>
        {props.options?.filter(Boolean).map((option) => (
          <StyledRadio
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </StyledRadio>
        ))}
      </CdVerticalSpace>
    </Radio.Group>
  );
};

const StyledRadio = styled(Radio)`
  .ant-radio {
    align-self: start;
    margin-top: 3px;
  }
`;
