import React, { useEffect } from 'react';
import { useRecoilRefresher_UNSTABLE as useRecoilRefresher } from 'recoil';

import { createCdDrawer } from '../../shared/components/cd-drawer/CdDrawer';
import { I18nService } from '../../services/I18nService';
import { SafeGuardingDetailsQuery } from '../store/Safeguarding';

import { EditSafeguardingSuspended } from './EditSafeguardingSuspended';
import { SafeguardingForm } from './SafeguardingForm';

export const showSafeguardingDrawer = createCdDrawer<{
  isEdit: boolean;
  currentUserId: number;
  currentUserOrganizationStatus: string;
}>(
  (props) => {
    const { setDrawerProps } = props;
    const refreshSafeguarding = useRecoilRefresher(
      SafeGuardingDetailsQuery(props.currentUserId)
    );
    useEffect(() => {
      setDrawerProps({
        fallback: <EditSafeguardingSuspended />,
        hideCancel: true,
        hideOk: true,
        onCancel: () => {
          refreshSafeguarding();
        },
      });
    }, [setDrawerProps, refreshSafeguarding]);

    return (
      <SafeguardingForm
        isEdit={props.isEdit}
        currentUserId={props.currentUserId}
        currentUserOrganizationStatus={props.currentUserOrganizationStatus}
        closeDrawer={props.closeDrawer}
      />
    );
  },
  { width: 1000, title: I18nService.getString('Documents and certificates') }
);
