/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { GetAclHasprivilegeResponseBody } from '../models/GetAclHasprivilegeResponseBody';
import type { PostAclCheckprivilegeRequestBody } from '../models/PostAclCheckprivilegeRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AclService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetAcl
     * Check user permission
     * @returns EmptyObject
     * @throws ApiError
     */
    public getAcl(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/acl',
        });
    }
    /**
     * PostAclCheckPrivilege
     * Check whether the user has the privilege in the organization for a given entity.
     * @returns EmptyObject
     * @throws ApiError
     */
    public postAclCheckPrivilege({
        requestBody,
    }: {
        requestBody?: PostAclCheckprivilegeRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/acl/check-privilege',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Check if the user has a given privilege
     * Check if the user has a given privilege in organization or church level
     * @returns GetAclHasprivilegeResponseBody
     * @throws ApiError
     */
    public getAclHasPrivilege({
        privilege,
    }: {
        privilege: string,
    }): CancelablePromise<GetAclHasprivilegeResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/acl/has-privilege',
            query: {
                'privilege': privilege,
            },
        });
    }
    /**
     * GetAclHasPrivilegeIn
     * Return which churches or organization the user has a given privilege in.
     * @returns EmptyObject
     * @throws ApiError
     */
    public getAclHasPrivilegeIn({
        privilege,
    }: {
        privilege?: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/acl/has-privilege-in',
            query: {
                'privilege': privilege,
            },
        });
    }
    /**
     * GetAclHaspackage
     * Check whether the organization has the package
     * @returns EmptyObject
     * @throws ApiError
     */
    public getAclHasPackage(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/acl/hasPackage',
        });
    }
    /**
     * GetAclOrganization
     * Check organization permission
     * @returns EmptyObject
     * @throws ApiError
     */
    public getAclOrganization(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/acl/organization',
        });
    }
}
