import {
  PeopleContactProfileStaticFieldsOptions,
  PeopleContactProfileFields,
} from '../types/people';

import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';

export const getContactProfileInformation = async (contactId): Promise<any> => {
  const response = await mainApi.get<any>(`/people/people/${contactId}`);
  if (!response.ok) {
    handleError(response);
    return undefined;
  }

  return response.data;
};
export const getStaticFields = async (): Promise<any> => {
  const response = await mainApi.get<PeopleContactProfileFields>(
    `/people/people/fields`
  );
  if (!response.ok) {
    handleError(response);
    return undefined;
  }

  return response.data;
};

export const saveContactProfileInformation = async ({
  contactId,
  payload,
}): Promise<any> => {
  const modifiedPayload = {
    ...payload,
    nationalIdentificationNumber: payload.nationalIdentificationNumber?.replace(
      /-/g,
      ''
    ),
  };

  const response = await mainApi.put<any>(
    `/people/people/${contactId}`,
    modifiedPayload
  );
  if (!response.ok) {
    handleError(response);
    return undefined;
  }

  return response.data;
};

export const createContactProfileBasicInformation = async ({
  payload,
  saveAndCreateAnother,
}): Promise<any> => {
  const response = await mainApi.post<any>(`/people/people`, payload);
  if (!response.ok) {
    handleError(response);
    return undefined;
  }

  return { data: response.data, saveAndCreateAnother };
};

export const getStaticFieldsOptions =
  async (): Promise<PeopleContactProfileStaticFieldsOptions> => {
    const response = await mainApi.get<PeopleContactProfileStaticFieldsOptions>(
      `/people/people/static/fields`
    );
    if (!response.ok) {
      handleError(response);
      return undefined;
    }

    return response.data;
  };
