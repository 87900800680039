import moment from 'moment';

import { I18nService } from '@/react/services/I18nService';

const rotaStateParams = {
  start: {
    value: moment().startOf('day').toDate(),
    type: 'date',
    squash: true,
    dynamic: true,
  },

  end: {
    value: moment().add(1, 'month').endOf('day').toDate(),
    type: 'date',
    squash: true,
    dynamic: true,
  },

  categories: {
    value: [],
    type: 'int',
    array: true,
    squash: true,
    dynamic: true,
  },

  tasks: {
    value: [],
    type: 'int',
    array: true,
    squash: true,
    dynamic: true,
  },

  resources: {
    value: [],
    type: 'int',
    array: true,
    squash: true,
    dynamic: true,
  },

  eventsWithoutResourcesInChurchIds: {
    value: [],
    type: 'int',
    array: true,
    squash: true,
    dynamic: true,
  },
};
function checkAccessToCalendar($state, Authorization) {
  'ngInject';

  if (!Authorization.hasPackage('calendar')) {
    return $state.target('app.private.calendar.default', {
      errorCode: 'PACKAGE_NOT_ENABLED',
    });
  }
  if (!Authorization.hasPermission('canAccessCalendar')) {
    return $state.target('app.private.calendar.default', {
      errorCode: 'CALENDAR_ACCESS',
    });
  }
}
checkAccessToCalendar.$inject = ['$state', 'Authorization'];

(function () {
  'use strict';

  angular.module('cdApp').config([
    '$stateProvider',
    function ($stateProvider) {
      let dependencies = {
        calendarGlobalData: function ($http) {
          return $http
            .get(cdApp.config.api.main + '/calendar/data')
            .success(function (data) {
              cdApp.data.set(data);
            });
        },
      };

      $stateProvider
        .state('app.private.calendar', {
          url: '/calendar?showEvent&eventDate&viewEvent',
          template: `
            <cd-app-nav class="app-nav"></cd-app-nav>
            <div class="app-view" ui-view></div>
          `,
          params: {
            showEvent: {
              value: '',
              squash: true,
              dynamic: true,
            },

            viewEvent: {
              value: null,
              type: 'int',
              squash: true,
              dynamic: true,
            },

            eventDate: {
              value: '',
              squash: true,
              dynamic: true,
            },
          },

          abstract: true,
        })
        .state('app.private.calendar.default', {
          url: '',
          component: 'cdrProductLandingPage',
          params: {
            errorCode: null,
            packageName: 'calendar',
          },
          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Calendar');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base)
                  ),
                };
              },
            ],
            errorCode: [
              '$stateParams',
              ($stateParams) => $stateParams.errorCode,
            ],
          },

          onEnter: [
            '$state',
            'Authorization',
            'errorCode',
            function ($state, Authorization, errorCode) {
              if (!errorCode) {
                return $state.target('app.private.calendar.full');
              }
            },
          ],
        })
        .state('app.private.calendar.full', {
          url: '/full',
          component: 'calendarSwitchScreen',
          params: {
            viewEvent: {
              value: null,
              type: 'int',
              squash: true,
              dynamic: true,
            },
          },
          resolve: {
            calendarGlobalData: [
              '$http',
              function ($http) {
                return dependencies.calendarGlobalData($http);
              },
            ],

            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Calendar');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base)
                  ),
                };
              },
            ],
          },
          onEnter: checkAccessToCalendar,
        })
        .state('app.private.calendar.changes', {
          url: '/changes',
          templateUrl:
            '@/app/calendar/calendar-log/calendar-log.component.html',
          controller: 'ExportController',
          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Calendar Changes');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base)
                  ),
                };
              },
            ],
          },
        })
        .state('app.private.calendar.print', {
          url: '/print?{current}&{default}&{startDate}&{endDate}&{groupSelect}&{typeSelect}&{categorySelect}&{resourceSelect}&{userSelect}&{searchText}&{selectedEventsWithoutResourcesInChurchIds}&{startWeekNumber}&{startYear}&{endWeekNumber}&{endYear}&{timeRegistrationUserSelect}&{visibilitySelect}',
          component: 'calendarReports',
          params: {
            // events and activity list params
            default: {
              value: true,
              type: 'bool',
              squash: true,
              dynamic: true,
            },

            startDate: {
              value: moment().startOf('day').toDate(),
              type: 'date',
              squash: true,
              dynamic: true,
            },

            endDate: {
              value: moment().add(1, 'months').toDate(),
              type: 'date',
              squash: true,
              dynamic: true,
            },

            groupSelect: {
              value: [],
              type: 'int',
              array: true,
              squash: true,
              dynamic: true,
            },

            categorySelect: {
              value: [],
              type: 'int',
              array: true,
              squash: true,
              dynamic: true,
            },

            resourceSelect: {
              value: [],
              type: 'string',
              array: true,
              squash: true,
              dynamic: true,
            },

            churchSelect: {
              value: [],
              type: 'int',
              array: true,
              squash: true,
              dynamic: true,
            },

            userSelect: {
              value: [],
              type: 'int',
              array: true,
              squash: true,
              dynamic: true,
            },

            searchText: {
              value: '',
              type: 'string',
              squash: true,
              dynamic: true,
            },

            visibilitySelect: {
              value: [],
              type: 'string',
              squash: true,
              dynamic: true,
            },

            // timeRegistration params
            startWeekNumber: {
              value: moment().week(),
              type: 'int',
              squash: true,
              dynamic: true,
            },
            startYear: {
              value: moment().year(),
              type: 'int',
              squash: true,
              dynamic: true,
            },
            endWeekNumber: {
              value: moment().add(1, 'months').week(),
              type: 'int',
              squash: true,
              dynamic: true,
            },
            endYear: {
              value: moment().add(1, 'months').year(),
              type: 'int',
              squash: true,
              dynamic: true,
            },
            timeRegistrationUserSelect: {
              value: [],
              type: 'int',
              squash: true,
              dynamic: true,
            },
            current: {
              value: 'search',
              type: 'string',
              squash: false,
              dynamic: true,
            },
          },
          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Calendar reports');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString('Calendar reports')
                  ),
                };
              },
            ],
          },
          onEnter: checkAccessToCalendar,
        })

        .state('app.private.calendar.workplanOverview', {
          url: '/work-plan/overview?year&month',
          templateUrl:
            '@/app/calendar/workplan-overview/workplan-overview.component.html',
          controller: 'WorkplanOverviewController',
          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Workplans');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base)
                  ),
                };
              },
            ],
          },
        })
        .state('app.private.calendar.workplan', {
          url: '/work-plan/:uid/:month/:year',
          templateUrl:
            '@/app/calendar/workplan-detail/workplan-detail.component.html',
          controller: 'WorkplanController',
          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Workplan');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base)
                  ),
                };
              },
            ],
          },
        })
        .state('app.private.calendar.group', {
          url: '/group/:gid',
          component: 'calendarSwitchScreen',
          resolve: {
            calendarGlobalData: [
              '$http',
              function ($http) {
                return dependencies.calendarGlobalData($http);
              },
            ],

            $title: [
              'gettextCatalog',
              'routingHelpers',
              function (gettextCatalog, routingHelpers) {
                const base = 'Group Calendar';
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString('Calendar')
                  ),
                };
              },
            ],
          },
        })
        .state('app.private.calendar.absence', {
          url: '/absence/:id',
          templateUrl:
            '@/app/calendar/absence-detail/absence-detail.component.html',
          controller: 'AbsenceController',
          resolve: {
            absenceData: [
              'Calendar',
              '$stateParams',
              function (Calendar, $stateParams) {
                return Calendar.get({
                  id: $stateParams.id,
                  type: 'absence',
                  operation: 'view',
                }).$promise;
              },
            ],

            $title: [
              'absenceData',
              'routingHelpers',
              function (absenceData, routingHelpers) {
                const base = 'Absence Detail';
                return {
                  base,
                  rendered: routingHelpers.buildTitle(absenceData.title),
                };
              },
            ],
          },
        })
        .state('app.private.calendar.absenceRevisions', {
          url: '/absence/:id/revisions',
          templateUrl:
            '@/app/calendar/absence-detail/absence-revisions.component.html',
          controller: 'AbsenceController',
          resolve: {
            absenceData: [
              'Calendar',
              '$stateParams',
              function (Calendar, $stateParams) {
                return Calendar.get({
                  id: $stateParams.id,
                  type: 'absence',
                  operation: 'view',
                }).$promise;
              },
            ],

            $title: [
              'gettextCatalog',
              'absenceData',
              'routingHelpers',
              function (gettextCatalog, absenceData, routingHelpers) {
                const base = 'Absence Revisions';
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString('Versions of {{title}}', {
                      title: absenceData.title,
                    })
                  ),
                };
              },
            ],
          },
        })
        .state('app.private.calendar.absenceRevision', {
          url: '/absence/:id/revision/:rid',
          templateUrl:
            '@/app/calendar/absence-detail/absence-detail.component.html',
          controller: 'AbsenceController',
          resolve: {
            absenceData: [
              'Revision',
              '$stateParams',
              function (Revision, $stateParams) {
                return Revision.get({
                  id: $stateParams.id,
                  rid: $stateParams.rid,
                  type: 'absence',
                }).$promise;
              },
            ],

            $title: [
              'absenceData',
              'routingHelpers',
              function (absenceData, routingHelpers) {
                const base = 'Absence Revision';
                return {
                  base,
                  rendered: routingHelpers.buildTitle(absenceData.data.title),
                };
              },
            ],
          },
        })
        .state('app.private.calendar.feed', {
          url: '/feed/:id',
          templateUrl: '@/app/calendar/feed-detail/feed-detail.component.html',
          controller: 'FeedController',
          resolve: {
            eventData: [
              'Calendar',
              '$stateParams',
              function (Calendar, $stateParams) {
                return Calendar.get({
                  id: $stateParams.id,
                  type: 'feed',
                  operation: 'view',
                }).$promise;
              },
            ],

            $title: [
              'eventData',
              'routingHelpers',
              function (eventData, routingHelpers) {
                const base = 'Event Detail';
                return {
                  base,
                  rendered: routingHelpers.buildTitle(eventData.title),
                };
              },
            ],
          },
        })
        .state('app.private.calendar.externalEvent', {
          url: '/external-event/:id',
          templateUrl:
            '@/app/calendar/external-event-detail/external-event-detail.component.html',
          controller: 'ExternalEventController',
          resolve: {
            eventData: [
              'Calendar',
              '$stateParams',
              function (Calendar, $stateParams) {
                return Calendar.getExternalEvent({
                  id: $stateParams.id,
                }).$promise;
              },
            ],

            $title: [
              'eventData',
              'routingHelpers',
              function (eventData, routingHelpers) {
                const base = 'Event Detail';
                return {
                  base,
                  rendered: routingHelpers.buildTitle(eventData.title),
                };
              },
            ],
          },
        })
        .state('app.private.calendar.eventForceNew', {
          url: '/event2/:id',
          component: 'cdrEventDetailsPage',
          params: {
            id: {
              value: null,
              dynamic: true,
              type: 'int',
            },
          },
          resolve: {
            $title: [
              'routingHelpers',

              function (routingHelpers) {
                const base = 'Event Detail';
                return {
                  base,
                  rendered: routingHelpers.buildTitle(),
                };
              },
            ],
          },
        })
        .state('app.private.calendar.event', {
          url: '/event/:id?{openAddForm}',
          templateUrl:
            '@/app/calendar/event-detail/event-detail.component.html',
          controller: 'EventController',
          params: {
            openAddForm: {
              type: 'bool',
              value: false,
              dynamic: true,
            },
          },

          resolve: {
            eventData: [
              'Calendar',
              '$stateParams',
              function (Calendar, $stateParams) {
                return Calendar.get({
                  id: $stateParams.id,
                  type: 'event',
                  operation: 'view',
                }).$promise;
              },
            ],

            $title: [
              'routingHelpers',
              'eventData',
              function (routingHelpers, eventData) {
                const base = 'Event Detail';
                return {
                  base,
                  rendered: routingHelpers.buildTitle(eventData.title),
                };
              },
            ],
          },
        })
        .state('app.private.calendar.eventRevisions', {
          url: '/event/:id/revisions',
          templateUrl:
            '@/app/calendar/event-detail/event-revisions.component.html',
          controller: 'EventController',
          resolve: {
            eventData: [
              'Calendar',
              '$stateParams',
              function (Calendar, $stateParams) {
                return Calendar.get({
                  id: $stateParams.id,
                  type: 'event',
                  operation: 'view',
                }).$promise;
              },
            ],

            $title: [
              'gettextCatalog',
              'eventData',
              'routingHelpers',
              function (gettextCatalog, eventData, routingHelpers) {
                const base = 'Event Revisions';
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString('Versions of {{title}}', {
                      title: eventData.title,
                    })
                  ),
                };
              },
            ],
          },
        })
        .state('app.private.calendar.eventRevision', {
          url: '/event/:id/revision/:rid',
          templateUrl:
            '@/app/calendar/event-detail/event-detail.component.html',
          controller: 'EventController',
          resolve: {
            eventData: [
              'Revision',
              '$stateParams',
              function (Revision, $stateParams) {
                return Revision.get({
                  id: $stateParams.id,
                  rid: $stateParams.rid,
                  type: 'event',
                }).$promise;
              },
            ],

            $title: [
              'gettextCatalog',
              'eventData',
              'routingHelpers',
              function (gettextCatalog, eventData, routingHelpers) {
                const base = 'Event Revision';
                return {
                  base,
                  rendered: routingHelpers.buildTitle(eventData.data.title),
                };
              },
            ],
          },
        })
        .state('app.private.calendar.poster', {
          url: '/event/:calendarId/poster/:posterId',
          component: 'cdrCalendarPoster',
        })

        /**
         * Rotas
         */ .state('app.private.calendar.rotas', {
          url: '/rotas',
          template: `
            <div class="app-view" ui-view></div>
          `,
        })
        .state('app.private.calendar.rotas.schemes', {
          url: '/schemes',
          component: 'cdRotasLinksState',
          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Rota schemes');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base)
                  ),
                };
              },
            ],
          },
        })
        .state('app.private.calendar.rotas.new', {
          url: '/schemes/new?{start}&{end}&{categories}&{tasks}&{resources}&{eventsWithoutResourcesInChurchIds}&{reloadRota}&{filtersUpdated}&{columnName}',
          component: 'cdRotasState',
          params: rotaStateParams,
          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Rotas');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base)
                  ),
                };
              },
            ],

            isPublic() {
              return false;
            },
          },
        })
        .state('app.private.calendar.rotas.default', {
          url: '/schemes/default?{start}&{end}&{categories}&{tasks}&{resources}&{eventsWithoutResourcesInChurchIds}&{reloadRota}&{filtersUpdated}&{columnName}',
          component: 'cdRotasState',
          params: rotaStateParams,
          resolve: {
            isPublic() {
              return false;
            },
            isDefaultRotaView() {
              return true;
            },
          },
        })
        .state('app.private.calendar.rotas.my', {
          url: '/schemes/my-scheme?{start}&{end}&{categories}&{tasks}&{resources}&{eventsWithoutResourcesInChurchIds}&{reloadRota}&{filtersUpdated}&{columnName}',
          component: 'cdRotasState',
          params: rotaStateParams,
          resolve: {
            isPublic() {
              return false;
            },
            isCurrentUserScheme() {
              return true;
            },
          },
        })
        .state('app.public.rota', {
          url: '/rota/:rotaId?&{start}&{end}',
          component: 'cdRotasState',
          params: {
            start: {
              value: moment().startOf('day').toDate(),
              type: 'date',
              squash: true,
              dynamic: true,
            },

            end: {
              value: moment().add(1, 'month').endOf('day').toDate(),
              type: 'date',
              squash: true,
              dynamic: true,
            },
          },

          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Rotas');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base),
                    false
                  ),
                };
              },
            ],

            isPublic() {
              return true;
            },
          },
        })
        .state('app.public.rota.json', {
          url: '/json',
          component: 'cdRotasState',
          onEnter: [
            '$stateParams',
            function ($stateParams) {
              const startDate = moment().startOf('day').toISOString();
              const endDate = moment()
                .add(1, 'month')
                .endOf('day')
                .toISOString();
              window.location.href =
                cdApp.config.api.main +
                `/shifts/rotas/public/${$stateParams.rotaId}?startDate=${startDate}&endDate=${endDate}`;
            },
          ],
        })
        .state('app.public.personalRotaScheme', {
          url: '/rota/personal?&{token}&{start}&{end}',
          component: 'cdRotasState',
          params: {
            token: {
              type: 'string',
              squash: true,
              dynamic: true,
            },

            start: {
              value: moment().startOf('day').toDate(),
              type: 'date',
              squash: true,
              dynamic: true,
            },

            end: {
              value: moment().add(1, 'month').endOf('day').toDate(),
              type: 'date',
              squash: true,
              dynamic: true,
            },
          },

          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Rota');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base)
                  ),
                };
              },
            ],

            isPublic() {
              return true;
            },
            isPersonalRotaScheme() {
              return true;
            },
          },
        })
        .state('app.private.calendar.rotas.view', {
          url: '/schemes/:rotaLinkId?{start}&{end}&{categories}&{tasks}&{resources}&{eventsWithoutResourcesInChurchIds}&{reloadRota}&{filtersUpdated}&{columnName}',
          component: 'cdRotasState',
          params: {
            start: {
              value: moment().startOf('day').toDate(),
              type: 'date',
              squash: true,
              dynamic: true,
            },

            end: {
              value: moment().add(1, 'month').endOf('day').toDate(),
              type: 'date',
              squash: true,
              dynamic: true,
            },

            rotaLink: null,
          },

          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Edit rota scheme');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base)
                  ),
                };
              },
            ],

            rotaLink: [
              '$stateParams',
              'RotasLinks',
              function ($stateParams, RotasLinks) {
                return (
                  $stateParams.rotaLink ||
                  RotasLinks.get({ id: $stateParams.rotaLinkId }).$promise
                );
              },
            ],

            isPublic() {
              return false;
            },
          },
        })
        .state('app.private.calendar.enquiries', {
          url: '/enquiries/:tab',
          component: 'cdrEnquiriesPage',
          params: {
            type: {
              value: 'tab',
              type: 'string',
            },
          },
          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Enquiries');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base)
                  ),
                };
              },
            ],
            id: [
              '$stateParams',
              function ($stateParams) {
                return $stateParams.id;
              },
            ],
          },
        })
        .state('app.private.calendar.enquiries.view', {
          url: '/:id',
        })
        .state('app.private.calendar.collection', {
          url: '/collection',
          component: 'cdrCollection',
          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Collection');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base)
                  ),
                };
              },
            ],
          },
          onEnter: checkAccessToCalendar,
        })
        .state('app.private.calendar.posters', {
          url: '/posters',
          component: 'cdrPosters',
          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Event Posters & Invites');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base)
                  ),
                };
              },
            ],
          },
        })

        /**
         * Attendance
         */ .state('app.private.calendar.attendance', {
          url: '/attendance?{tab:string}&{page:int}',
          component: 'cdCalendarAttendanceState',
          params: {
            tab: {
              value: 'new',
              dynamic: true,
              squash: true,
            },

            page: {
              value: 1,
              dynamic: true,
              squash: true,
            },
          },

          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Attendance');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base)
                  ),
                };
              },
            ],

            attendanceCategories: [
              'Attendance',
              function (Attendance) {
                return Attendance.getCategories().$promise;
              },
            ],

            attendanceFields: [
              'Attendance',
              function (Attendance) {
                return Attendance.getFields().$promise;
              },
            ],
          },

          onEnter: [
            '$state',
            'Authorization',
            ($state, Authorization) =>
              Authorization.hasPackage('attendance') ||
              $state.target('app.public.errorState', {
                errorCode: 'ACCESS_DENIED',
              }),
          ],
        })
        .state('app.private.calendar.file', {
          url: '/file/:fileId/download',
          resolve: {
            item: [
              '$stateParams',
              'Files',
              function ($stateParams, Files) {
                Files.getDownloadToken({
                  fileId: $stateParams.fileId,
                }).$promise.then(({ token }) => {
                  let downloadLink =
                    cdApp.config.api.main +
                    '/files/v2/download?downloadToken=' +
                    token;
                  downloadLink += '&organizationId=' + cdApp.organization.id;
                  window.location = downloadLink;
                });
              },
            ],
          },
        })
        .state('app.public.personalICalFeed', {
          url: '/calendar/personal-ical-feed-link?{userId:int}&{token:string}&{language:string}',
          component: 'cdPersonalICalFeedState',
          onEnter: [
            '$stateParams',
            'gettextCatalog',
            'Me',
            function ($stateParams, gettextCatalog, Me) {
              /**
               * Wait for the Me endpoint to be resolved in order to get the organization's language if the user
               * is not logged in. Otherwise, it will be set from the user's browser
               * @see app.config.js
               */
              return Me.catch(() =>
                I18nService.setCurrentLanguage($stateParams.language)
              );
            },
          ],
        });
    },
  ]);
})();
