/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  DatePicker,
  Input,
  Button,
  Form,
  Row,
  Col,
  Select,
  InputNumber,
  Space,
  Card,
  Descriptions,
  Checkbox,
} from 'antd';

import { IntentionFee } from '../../../settings/models/fee';
import { Resource } from '../../../shared/models/resource';
import { I18nService } from '../../../services/I18nService';
import { PaymentMethodTypes } from '../../models/intention';
import { getLegat } from '../../redux/foundations/Selectors';
import { paymentMethods } from '../../shared/intentionCommon';
import FoundationService from '../../services/FoundationService';
import { getIntentionFees } from '../../../settings/redux/intention-fees/Selectors';
import { getFilteredFees } from '../../../settings/redux/intention-fees/data.converter';
import ErrorHandlingService from '../../../services/ErrorHandlingService';
import IntentionsOverview from '../../components/intentions/IntentionsOverview';
import { getChurches, getResources } from '../../../shared/store/resources';
import {
  Foundation,
  FoundationFields,
  FoundationStatusTypes,
  FoundationTypes,
} from '../../models/foundation';
import StateServiceFactory, {
  StateParamsServiceFactory,
} from '../../../services/StateServiceFactory';
import {
  clearLegat,
  createFoundation,
  fetchFoundation,
  generateFoundationIntentionsReport,
  updateFoundation,
} from '../../redux/foundations/Actions';

// Antd
const { TextArea } = Input;
const { Option } = Select;

interface CreateLegatStateParam {
  currentState: string;
  id: string;
}

const CreateLegat: FunctionComponent = () => {
  const dispatch = useDispatch();

  const stateService = StateServiceFactory();
  const stateParams = StateParamsServiceFactory<CreateLegatStateParam>();
  const currentState = stateParams.currentState;
  const legatId = stateParams.id;
  const [form] = Form.useForm();

  const datePickerFormat = I18nService.getLongDateFormat();

  // Define labels
  const submitButtonLabel =
    currentState === 'create'
      ? I18nService.getString('Save')
      : I18nService.getString('Update');

  // Retrieve required entities from Redux store
  const resources = useSelector(getResources);
  const churches = useSelector(getChurches);
  const allIntentionFees = useSelector(getIntentionFees);
  const intentionFees: IntentionFee[] = getFilteredFees(allIntentionFees);
  /**
   * TODO: Consider passing on to this view, the legat to be viewed/updated, since all of its required data
   * has already been fetched when listing legate. In this way, an unnecessary asynchronous call to the
   * backend can be avoided, the first "useEffect" block can be avoided, and the logic of the "useEffect" block
   * down below (the one listening on changes of the legat) can be simplified.
   */
  const _legat = useSelector(getLegat);
  const legat = _legat && _legat.asMutable({ deep: true });

  // Define initialization hook and entity reload hooks
  const [churchResources, setChurchResources] = useState<Resource[]>([]);
  const [church, setChurch] = useState<Resource>();

  const [shouldShowPermissionAlert, setShouldShowPermissionAlert] =
    useState<boolean>(false);

  // Retrieve required entities
  useEffect(() => {
    // Initialize legat data in case of creation or update/view
    if (currentState === 'create') {
      dispatch(clearLegat());
    } else {
      dispatch(fetchFoundation({ type: FoundationTypes.LEGATE, id: legatId }));
    }
    // Cleanup function to be executed on component un-mounting
    return () => {
      dispatch(clearLegat());
    };
  }, []);

  // Update the form with the retrieved intention (update/view)
  useEffect(() => {
    if (currentState !== 'create' && legat) {
      setShouldShowPermissionAlert(
        legat.status === FoundationStatusTypes.INACTIVE &&
          legat.intentionsCount > 0
      );

      loadChurchDependentEntities(legat.churchId);

      form.setFieldsValue({
        nextReferenceNumber: _.get(legat, 'formattedReferenceNumber'),
        preferredDate: legat.preferredDate
          ? moment(legat.preferredDate, 'YYYY-MM-DD')
          : null,
        preferredNote: legat.preferredNote,
        intentionText: legat.intentionText,
        internalNote: legat.internalNote,
        startBalance: legat.startBalance,
        remainingBalance: legat.remainingBalance,
        paymentMethod: legat.paymentMethod,
        paid: legat.paid,
        paidAt: legat.paidAt ? moment(legat.paidAt) : null,
        intentionsPerYear: legat.intentionsPerYear,
        startYear: legat.startYear ? moment().year(legat.startYear) : null,
        church: legat.churchId,
        resource: legat.resourceId,
        fee: legat.feeId,
        founder: legat.founder,
      });
    } else {
      form.setFieldsValue({
        founder: null,
        church: null,
        resource: null,
        paymentMethod: PaymentMethodTypes.PAIDBYCASH,
        paid: true,
        paidAt: moment(),
        intentionsPerYear: 1,
        startBalance: null,
        remainingBalance: null,
      });
    }
  }, [legat ? legat.id : null]);

  useEffect(() => {
    if (!_.isEmpty(resources)) {
      if (currentState !== 'create' && legat) {
        loadChurchDependentEntities(legat.churchId);
      }
    }
  }, [_.sortBy(_.map(resources, 'id')).join('-')]);

  // When creating an intention, auto-select the default fee, and if none, use the first fee in the list
  useEffect(() => {
    if (currentState === 'create' && !_.isEmpty(intentionFees)) {
      const defaultFee = _.find(
        intentionFees,
        (intentionFee) => intentionFee.isDefault
      );
      form.setFieldsValue({
        fee: _.get(defaultFee || _.first(intentionFees), 'id'),
      });
    }
  }, [_.sortBy(_.map(intentionFees, 'id')).join('-')]);

  // Form functions
  const updateChurch = (churchId: number) => {
    // Clear form values that depend on the value of the previously selected church ID
    form.setFieldsValue({
      resource: null,
    });

    // Re-load resources and fees
    loadChurchDependentEntities(churchId);

    // Reload the reference number
    if (churchId) {
      FoundationService.getFoundationsNextReferenceNumber(
        churchId,
        FoundationTypes.LEGATE
      )
        .then((value) => {
          form.setFieldsValue({ nextReferenceNumber: value });
        })
        .catch(ErrorHandlingService.handleError);
    }
  };

  const loadChurchDependentEntities = (churchId: number) => {
    // Load church's resources
    const church = _.find(resources, ['id', churchId]);
    const churchResources = _.get(church, 'resources', []);
    const orderedChurchResources = _.orderBy(churchResources, 'name');
    setChurch(church);
    setChurchResources(orderedChurchResources);
  };

  const saveForm = (shouldActivate) => {
    if (shouldActivate) {
      form.setFieldsValue({
        status: FoundationStatusTypes.ACTIVE,
      });
    }

    form.submit();
  };

  const save = (formValues) => {
    const oldChurchId = _.get(legat, 'churchId');
    const oldResourceId = _.get(legat, 'resourceId');
    const oldFeeId = _.get(legat, 'feeId');
    const associations = {
      church: formValues.church
        ? { id: formValues.church }
        : oldChurchId
          ? null
          : undefined,
      resource: formValues.resource
        ? { id: formValues.resource }
        : oldResourceId
          ? null
          : undefined,
      fee: formValues.fee
        ? { id: formValues.fee }
        : oldFeeId
          ? null
          : undefined,
    };

    // Format form inputs
    formValues.startYear = formValues.startYear
      ? formValues.startYear.year()
      : formValues.startYear;
    formValues.preferredDate = formValues.preferredDate
      ? formValues.preferredDate.format('YYYY-MM-DD')
      : formValues.preferredDate;
    formValues.preferredNote = _.isEmpty(formValues.preferredNote)
      ? null
      : formValues.preferredNote;
    formValues.intentionText = _.isEmpty(formValues.intentionText)
      ? null
      : formValues.intentionText;
    formValues.internalNote = _.isEmpty(formValues.internalNote)
      ? null
      : formValues.internalNote;

    /**
     * Manually retrieve the status "hidden" field that might have been set
     * in the case of updating and activating the legat (its value is NOT
     * submitted along with the other values in the "formValues" parameter)
     */
    const status = form.getFieldValue('status');
    const defaultStatus =
      currentState === 'create' ? FoundationStatusTypes.INACTIVE : undefined;
    const payload: Partial<Foundation> = _.extend(
      {
        type: FoundationTypes.LEGATE,
        status: status ? status : defaultStatus,
      },
      formValues,
      associations
    );

    if (currentState === 'create') {
      dispatch(createFoundation(payload));
    } else {
      dispatch(
        updateFoundation({
          id: legatId,
          type: FoundationTypes.LEGATE,
          updatePayload: payload,
        })
      );
    }
  };

  const generateLegatIntentionsReport = () => {
    dispatch(
      generateFoundationIntentionsReport({
        id: legat.id,
        type: FoundationTypes.LEGATE,
      })
    );
  };

  const back = () => {
    stateService.go('app.private.intention.legate.overview', {
      foundationType: FoundationTypes.LEGATE,
    });
  };

  // Get page title depending on current state
  let pageTitle;
  switch (currentState) {
    case 'edit':
      pageTitle = I18nService.getString('Edit legacy');
      break;
    default:
      // Create
      pageTitle = I18nService.getString('Create legacy');
  }

  // Only disable when editing legat and field "canEdit" property is set to false OR when creating legat under a few cases
  const shouldDisable = (fieldName) => {
    if (legat) {
      // Editing
      return !_.get(legat, `fields[${fieldName}].canEdit`, false);
    } else {
      // Creating
      switch (fieldName) {
        case FoundationFields.referenceNumber:
          if (church && church.migrationLegatReferenceNumber) {
            return false;
          }
          return true;
        case FoundationFields.remainingBalance:
          return true;
        default:
          return false;
      }
    }
  };

  // Return the create legate form
  return (
    <React.Fragment>
      <div className="app-main">
        <PageHeader
          style={{ padding: '0px 0px 16px 0px' }}
          className="site-page-header-responsive"
          title={pageTitle}
          extra={
            <Space>
              <Button
                value="save"
                htmlType="button"
                type="primary"
                onClick={() => saveForm(false)}
              >
                {submitButtonLabel}
              </Button>
              {_.get(legat, 'status') === FoundationStatusTypes.INACTIVE ? (
                <Button
                  value="saveAndActivate"
                  htmlType="button"
                  type="primary"
                  onClick={() => saveForm(true)}
                >
                  {I18nService.getString('Update and Activate')}
                </Button>
              ) : null}
              {!_.isNil(legat) ? (
                <Button onClick={() => generateLegatIntentionsReport()}>
                  {I18nService.getString('Completion list')}
                </Button>
              ) : null}
              <Button onClick={back}>{I18nService.getString('Back')}</Button>
            </Space>
          }
        />

        {shouldShowPermissionAlert ? (
          <Alert
            message={I18nService.getString(
              'This legacy has been activated in the past and intentions have been generated for it, therefore some of its fields cannot be updated, in spite of the fact it is currently inactive.'
            )}
            type="warning"
            showIcon={true}
            closable={false}
            style={{ marginBottom: 16 }}
          />
        ) : null}

        <Form
          onFinish={save}
          form={form}
          labelCol={{ xs: 24, sm: 24, md: 6, lg: 8 }}
          wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14 }}
          size="middle"
        >
          <Row>
            {/* Start of left column */}
            <Col xs={24} lg={10} style={{ display: 'flex' }}>
              <Card
                bordered
                style={{ marginBottom: 16, marginRight: 16, flex: 1 }}
              >
                {/* Church - Required */}
                <Form.Item
                  name="church"
                  label={I18nService.getString('Parish')}
                  required={true}
                >
                  <Select
                    onChange={updateChurch}
                    disabled={shouldDisable(FoundationFields.churchId)}
                    allowClear
                    placeholder={I18nService.getString('Select a church')}
                  >
                    {churches &&
                      churches.map((church) => (
                        <Option key={church.id} value={church.id}>
                          {_.get(church, 'name')}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                {/* Resources */}
                <Form.Item
                  name="resource"
                  label={I18nService.getString('Resource')}
                >
                  <Select
                    disabled={
                      _.isEmpty(churchResources) ||
                      shouldDisable(FoundationFields.resourceId)
                    }
                    allowClear
                    placeholder={I18nService.getString('Select a resource')}
                  >
                    {churchResources &&
                      churchResources.map((resource) => (
                        <Option key={resource.id} value={resource.id}>
                          {_.get(resource, 'name')}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                {/* Preferred Date */}
                <Form.Item
                  name="preferredDate"
                  label={I18nService.getString('Desired date')}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    format={datePickerFormat}
                    disabled={shouldDisable(FoundationFields.preferredDate)}
                  />
                </Form.Item>

                {/* Preferred Date Note */}
                <Form.Item
                  name="preferredNote"
                  label={I18nService.getString('Notes for desired date')}
                >
                  <TextArea
                    rows={3}
                    disabled={shouldDisable(FoundationFields.preferredNote)}
                    placeholder={I18nService.getString('Desired date notes')}
                  />
                </Form.Item>

                {/* Intention text - required */}
                <Form.Item
                  name="intentionText"
                  label={I18nService.getString('Intention text')}
                  required={true}
                  style={{ marginBottom: 0 }}
                >
                  <TextArea
                    rows={4}
                    disabled={shouldDisable(FoundationFields.intentionText)}
                    placeholder={I18nService.getString('Intention text')}
                  />
                </Form.Item>
              </Card>
            </Col>

            {/* Start of right column */}
            <Col xs={24} lg={14}>
              <Card bordered style={{ marginBottom: 16 }}>
                <Row justify="space-between" gutter={8} align="bottom">
                  <Col span={11}>
                    {/* Start balance - Required */}
                    <Form.Item
                      name="startBalance"
                      label={I18nService.getString('Sum')}
                      required={true}
                      labelCol={{ span: 12 }}
                    >
                      <Input
                        type="number"
                        prefix={_.get(window, 'cdApp.organization.currency')}
                        style={{
                          width: '100%',
                          maxHeight: 'initial',
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                        disabled={shouldDisable(FoundationFields.startBalance)}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={13}>
                    {/* Next reference number */}
                    <Form.Item
                      name="nextReferenceNumber"
                      label={I18nService.getString('Number')}
                      labelCol={{ span: 8 }}
                    >
                      <Input
                        style={{ width: '100%' }}
                        disabled={shouldDisable(
                          FoundationFields.referenceNumber
                        )}
                        placeholder={I18nService.getString('Number')}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify="space-between" gutter={8} align="bottom">
                  <Col span={11}>
                    {/* Remaining balance - Required */}
                    <Form.Item
                      name="remainingBalance"
                      label={I18nService.getString('Remaining balance')}
                      labelCol={{ span: 12 }}
                    >
                      <Input
                        type="number"
                        prefix={_.get(window, 'cdApp.organization.currency')}
                        style={{
                          width: '100%',
                          maxHeight: 'initial',
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                        placeholder={I18nService.getString('Remaining balance')}
                        disabled={shouldDisable(
                          FoundationFields.remainingBalance
                        )}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={13}>
                    {/* Fee - Required */}
                    <Form.Item
                      name="fee"
                      label={I18nService.getString('Intention type')}
                      required={true}
                      labelCol={{ span: 8 }}
                    >
                      <Select
                        placeholder={I18nService.getString('Select a type')}
                        disabled={
                          _.isEmpty(intentionFees) ||
                          shouldDisable(FoundationFields.feeId)
                        }
                        allowClear
                        style={{ width: '100%' }}
                      >
                        {intentionFees &&
                          intentionFees.map((intentionFee) => (
                            <Option
                              key={intentionFee.id}
                              value={intentionFee.id}
                            >
                              {_.get(intentionFee, 'name')}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify="space-between" gutter={8} align="bottom">
                  <Col span={11}>
                    {/* Intentions per year - Required */}
                    <Form.Item
                      name="intentionsPerYear"
                      label={I18nService.getString('Intentions per year')}
                      required={true}
                      labelCol={{ span: 12 }}
                    >
                      <InputNumber
                        step={1}
                        min={0}
                        style={{ width: '100%' }}
                        disabled={shouldDisable(
                          FoundationFields.intentionsPerYear
                        )}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={13}>
                    {/* Start year */}
                    <Form.Item
                      name="startYear"
                      label={I18nService.getString('Starting year')}
                      labelCol={{ span: 8 }}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        picker="year"
                        disabled={shouldDisable(FoundationFields.startYear)}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify="space-between" gutter={8} align="bottom">
                  <Col span={11}>
                    {/* Payment method */}
                    <Form.Item
                      name="paymentMethod"
                      label={I18nService.getString('Payment method')}
                      style={{ marginBottom: 0 }}
                      labelCol={{ span: 12 }}
                    >
                      <Select
                        placeholder={I18nService.getString(
                          'Select a payment method'
                        )}
                        style={{ width: '100%' }}
                        disabled={
                          _.isEmpty(paymentMethods()) ||
                          shouldDisable(FoundationFields.paymentMethod)
                        }
                        allowClear
                      >
                        {paymentMethods &&
                          paymentMethods().map((paymentMethod) => (
                            <Option
                              key={paymentMethod.id}
                              value={paymentMethod.id}
                            >
                              {_.get(paymentMethod, 'name')}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={13}>
                    <Row
                      justify="space-between"
                      align="middle"
                      style={{ marginBottom: 0 }}
                    >
                      <Col span={8} style={{ textAlign: 'end' }}>
                        <label style={{ marginRight: 8 }}>
                          {I18nService.getString('Payment date')}:
                        </label>
                      </Col>

                      <Col span={16}>
                        <Space direction="horizontal">
                          {/* Payment date */}
                          <Form.Item name="paidAt" style={{ marginBottom: 0 }}>
                            <DatePicker
                              format={datePickerFormat}
                              placeholder={I18nService.getString(
                                'Payment date'
                              )}
                              disabled={shouldDisable(FoundationFields.paidAt)}
                            />
                          </Form.Item>

                          {/* Payment check */}
                          <Form.Item
                            name="paid"
                            valuePropName="checked"
                            style={{ marginBottom: 0 }}
                          >
                            <Checkbox
                              disabled={shouldDisable(FoundationFields.paid)}
                            >
                              {I18nService.getString('Paid?')}
                            </Checkbox>
                          </Form.Item>
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>

              <Card bordered style={{ marginBottom: 16 }}>
                {/* Founder */}
                <Form.Item
                  name="founder"
                  label={I18nService.getString('Founder')}
                >
                  <Input
                    style={{ width: '100%' }}
                    disabled={shouldDisable(FoundationFields.founder)}
                    placeholder={I18nService.getString('Founder')}
                  />
                </Form.Item>

                {/* Internal Note */}
                <Form.Item
                  name="internalNote"
                  label={I18nService.getString('Note')}
                  style={{ marginBottom: 0 }}
                >
                  <TextArea
                    rows={3}
                    placeholder={I18nService.getString(
                      'Additional information'
                    )}
                    disabled={shouldDisable(FoundationFields.internalNote)}
                  />
                </Form.Item>
              </Card>
            </Col>
            {/* Entity metadata */}
            {_.get(legat, 'createdBy') ||
            _.get(legat, 'createdAt') ||
            _.get(legat, 'assignedBy') ||
            _.get(legat, 'assignedAt') ||
            _.get(legat, 'updatedBy') ||
            _.get(legat, 'updatedAt') ||
            _.get(legat, 'deletedAt') ? (
              <Col span={24}>
                <Card bordered style={{ marginBottom: 16 }}>
                  <Descriptions
                    title={I18nService.getString('Activity')}
                    column={{ xs: 1, sm: 2, md: 3, xl: 4 }}
                    size="small"
                    layout="vertical"
                  >
                    {_.get(legat, 'createdBy') ? (
                      <Descriptions.Item
                        label={I18nService.getString('Created by')}
                      >
                        {_.get(legat, 'createdBy.contact.fullName')}
                      </Descriptions.Item>
                    ) : null}
                    {_.get(legat, 'createdAt') ? (
                      <Descriptions.Item
                        label={I18nService.getString('Created on')}
                      >
                        {moment(_.get(legat, 'createdAt')).format('lll')}
                      </Descriptions.Item>
                    ) : null}
                    {_.get(legat, 'assignedBy') ? (
                      <Descriptions.Item
                        label={I18nService.getString('Assigned by')}
                      >
                        {_.get(legat, 'assignedBy.contact.fullName')}
                      </Descriptions.Item>
                    ) : null}
                    {_.get(legat, 'assignedAt') ? (
                      <Descriptions.Item
                        label={I18nService.getString('Assigned on')}
                      >
                        {moment(_.get(legat, 'assignedAt')).format('lll')}
                      </Descriptions.Item>
                    ) : null}
                    {_.get(legat, 'updatedBy') ? (
                      <Descriptions.Item
                        label={I18nService.getString('Updated by')}
                      >
                        {_.get(legat, 'updatedBy.contact.fullName')}
                      </Descriptions.Item>
                    ) : null}
                    {_.get(legat, 'updatedAt') ? (
                      <Descriptions.Item
                        label={I18nService.getString('Updated on')}
                      >
                        {moment(_.get(legat, 'updatedAt')).format('lll')}
                      </Descriptions.Item>
                    ) : null}
                    {_.get(legat, 'deletedAt') ? (
                      <Descriptions.Item
                        label={I18nService.getString('Deleted on')}
                      >
                        {moment(_.get(legat, 'deletedAt')).format('lll')}
                      </Descriptions.Item>
                    ) : null}
                  </Descriptions>
                </Card>
              </Col>
            ) : null}
          </Row>
          {currentState === 'edit' ? (
            <Row>
              <Col span={24}>
                <IntentionsOverview legatId={legatId} />
              </Col>
            </Row>
          ) : null}
        </Form>
      </div>
    </React.Fragment>
  );
};

export default CreateLegat;
