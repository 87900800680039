/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostChurchSettingsRequestBody = {
    smsSenderName?: string;
    organizationEmail?: string;
    siteAddress?: string | null;
    organizationName?: string;
    enableImageCredits?: boolean;
    requireImageCredits?: boolean;
    privacyPolicy?: string | null;
    privacyPolicyText?: string | null;
    imprintLink?: string | null;
    imprintText?: string | null;
    facebook?: {
        fbAccessToken: string;
        fbUserId: string;
        fbPageId: string;
    } | null;
    siteDomain?: string;
    taxPercentage?: number;
    gebCustomerId?: string | null;
    newsletterColor?: string;
    newsletterWidgetEnabled?: boolean;
    donationPrimaryColor?: string;
    donationSecondaryColor?: string;
    donationBackgroundColor?: string;
    showSuggestedEventsOrBlogs?: boolean;
    newsletterListMigrationComplete?: boolean;
    primaryColor?: string;
    cardColor?: string;
    fallbackImageId?: string;
    showImage?: boolean;
    showShadow?: boolean;
    showRoundedCorners?: boolean;
    slideShowFallBackImageId?: string;
    publishToMonoBlog?: boolean;
    monoBlogsMigrationComplete?: boolean;
    font?: string;
    expirationEventInternalNote?: PostChurchSettingsRequestBody.expirationEventInternalNote;
    expirationEventSensitiveNote?: PostChurchSettingsRequestBody.expirationEventSensitiveNote;
    domain?: string | null;
    organizationPartnerMembershipId?: (string | number) | null;
    anniversariesSettings?: {
        anniversariesTypeSetting?: Record<string, {
            label?: string;
            checked: boolean;
            selected?: Array<number>;
        }>;
        usersToNotify?: Array<number>;
    };
};
export namespace PostChurchSettingsRequestBody {
    export enum expirationEventInternalNote {
        '_30' = 30,
        '_60' = 60,
        '_90' = 90,
        '_365' = 365,
        DO_NOT_DELETE_AUTOMATICALLY = 'do_not_delete_automatically',
    }
    export enum expirationEventSensitiveNote {
        '_30' = 30,
        '_60' = 60,
        '_90' = 90,
        '_365' = 365,
        DO_NOT_DELETE_AUTOMATICALLY = 'do_not_delete_automatically',
    }
}

