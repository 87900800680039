import React from 'react';
import { Typography } from 'antd';
import { includes, isArray } from 'lodash';
import { $injector } from 'ngimport';

import { FormSubmissionData } from '../types/enquiry';
import { CdVerticalSpace } from '../../../shared/components/cd-vertical-space/CdVerticalSpace';
import { UibModal } from '../../services/EventPopupService';
import { I18nService } from '../../../services/I18nService';

export interface FormSubmissionViewerProps {
  submissionData: FormSubmissionData;
}

export const FormSubmissionViewer = (props: FormSubmissionViewerProps) => (
  <CdVerticalSpace size={0}>
    {props.submissionData?.filter(Boolean).map((section, index) => (
      <div key={section?.label} style={index ? { marginTop: 20 } : null}>
        {section.isSection ? (
          <Typography.Title level={3} style={{ marginTop: 0 }}>
            {section?.label}
          </Typography.Title>
        ) : (
          <Typography.Text type="secondary">{section?.label}</Typography.Text>
        )}
        <CdVerticalSpace>
          {isArray(section?.value)
            ? section?.value?.map((value) => (
                <CdFormValueField
                  key={value?.label || value?.value}
                  value={value}
                />
              ))
            : section?.value || I18nService.getString('(no answer)')}
        </CdVerticalSpace>
      </div>
    ))}
  </CdVerticalSpace>
);

export const CdFormValueField = ({ value }) => (
  <CdVerticalSpace size={0} key={value?.label || value?.value}>
    {value?.label && (
      <Typography.Text type="secondary">{value?.label}</Typography.Text>
    )}
    {isArray(value?.value)
      ? value?.value.map((file) => <FileInForm file={file} key={file.id} />)
      : value?.value}
    {value?.fileInfo && <FileInForm file={value} />}
    {!value?.value && !value?.fileInfo && I18nService.getString('(no answer)')}
  </CdVerticalSpace>
);

const FileInForm = (props: { file: any }) => (
  <Typography.Link onClick={() => openFilePreviewer(props.file)}>
    {props.file.title}
  </Typography.Link>
);

const openFilePreviewer = (item: any) => {
  const viewableDocuments = ['pdf', 'image', 'audio', 'video'];
  const $uibModal = $injector.get('$uibModal') as UibModal;
  const modalType = includes(viewableDocuments, item.fileInfo.filetype.type)
    ? 'document-viewer-modal'
    : 'document-download-modal';

  return $uibModal.open({
    component: 'cdDocumentViewer',
    windowClass: modalType,
    resolve: {
      item: () => item,
    },
  });
};
