/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PutBookingRequestsRespondRequestBody = {
    comment?: string;
    response: PutBookingRequestsRespondRequestBody.response;
};
export namespace PutBookingRequestsRespondRequestBody {
    export enum response {
        REQUESTED = 'requested',
        ACCEPTED = 'accepted',
        REJECTED = 'rejected',
    }
}

