/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostPeopleMessagesRequestBody = {
    from?: string | null;
    to?: Array<{
        id: number;
        group: 'lists' | 'contacts';
    }>;
    title?: string | null;
    rendered?: string | null;
    content?: string | null;
    churches: Array<{
        id: number;
    }>;
    scheduled?: string | null;
    type: PostPeopleMessagesRequestBody.type;
    anniversary?: {
        uniqueId: string;
        label?: string;
        date?: string;
        yearSince?: number;
        type?: string;
        isSpecial?: boolean;
        inComingDate?: string;
    };
};
export namespace PostPeopleMessagesRequestBody {
    export enum type {
        SMS = 'sms',
        EMAIL = 'email',
    }
}

