import React from 'react';
import { useSelector } from 'react-redux';

import { I18nService } from '../../services/I18nService';
import { useOnToggleCategory } from '../hooks';
import { isCategorySelected } from '../store/filters/Selectors';

import { SearchableFilterItem } from './SearchableFilterItem';

import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';

interface DisplayFilterItem {
  name: string;
  color?: number;
  churchId: number;
  categoryId: number;
  isDisabled: boolean;
}

const ChurchCalendarItem = ({
  name,
  color,
  churchId,
  categoryId,
  isDisabled,
}: DisplayFilterItem) => {
  const onToggleCategory = useOnToggleCategory();
  const isSelected = useSelector(isCategorySelected(categoryId, churchId));
  const content = (
    <SearchableFilterItem
      isDisabled={isDisabled}
      onLabelClick={() =>
        !isDisabled && onToggleCategory({ categoryId, churchId })
      }
      isSelected={isSelected}
      itemData={{ id: categoryId }}
      color={color}
      name={name}
      feedType="categories"
    />
  );
  if (isDisabled) {
    return (
      <CdTooltip
        title={I18nService.getString(
          'This calendar cannot be enabled for this view'
        )}
      >
        {content}
      </CdTooltip>
    );
  }
  return content;
};

export default ChurchCalendarItem;
