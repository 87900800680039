import styled from 'styled-components';

type CleanupTimeProps = {
  cleanupTimeMinutes: number;
  backgroundColor: string;
  textColor: string;
  borderColor: string;
  isPast: boolean;
};

export const CleanupTime = styled.div<Partial<CleanupTimeProps>>`
  margin: 0 -3px 0 -3px;
  height: ${({ cleanupTimeMinutes }) => cleanupTimeMinutes}px;
  padding: 0px 3px;
  border-radius: 0 0 3px 3px !important;
  border-top: 1px solid ${({ borderColor }) => borderColor};
  flex: ${({ cleanupTimeMinutes }) => cleanupTimeMinutes};
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};

  .fc-cleanup-title {
    overflow: hidden;
    white-space: nowrap;
    font-weight: 400;
    font-size: 11px;
    color: ${({ textColor }) => textColor};
  }
  @media screen and (max-height: 699px) {
    padding: 1px;
    margin: -1px -1px 0;
  }
`;
