import { Button, Divider, Space, InputNumber } from 'antd';
import React, { useRef, useState } from 'react';

import { I18nService } from '@/react/services/I18nService';
import { CdPlus } from '@/react/shared/components/Icons';
import { getDecimalSeparator } from '@/react/shared/utils';
import NotificationService from '@/react/services/NotificationService';
import { CdSelect } from '@/react/shared/components/cd-select/CdSelect';
interface SpecialDaysProps {
  value?: number[];
  onChange?: any;
}
export const SpecialDays = (props: SpecialDaysProps) => {
  const separator = getDecimalSeparator();
  const [newSpecialDay, setNewSpecialDay] = useState(null);
  const [options, setOptions] = useState(
    props.value?.map((item) => ({
      label: item.toString().replace('.', separator),
      value: item,
    })) || []
  );
  const inputRef = useRef(null);

  const onInputChange = (value) => {
    setNewSpecialDay(value);
  };
  const handleChange = (value: any) => {
    if (!props.onChange) return;
    props.onChange(value);
  };
  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    if (!newSpecialDay || !props.onChange) {
      return;
    }
    if (options.find((item) => item.value === newSpecialDay)) {
      NotificationService.notifyInfo(
        I18nService.getString('This item already exists')
      );
      setNewSpecialDay(null);
      return;
    }

    setOptions((prev) => [
      ...prev,
      {
        label: newSpecialDay.toString().replace('.', separator),
        value: newSpecialDay,
      },
    ]);
    props.onChange([...props.value, newSpecialDay]);
    setNewSpecialDay(null);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
  return (
    <CdSelect
      style={{ width: '100%' }}
      placeholder={I18nService.getString('Insert the special anniversaries')}
      mode="multiple"
      maxTagCount="responsive"
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
            <InputNumber
              placeholder={I18nService.getString('Enter new item')}
              value={newSpecialDay}
              min={1}
              onChange={onInputChange}
              onKeyDown={(e) => e.stopPropagation()}
              ref={inputRef}
              decimalSeparator={separator}
            />
            <Button type="text" icon={<CdPlus />} onClick={addItem}>
              {I18nService.getString('Add item')}
            </Button>
          </Space>
        </>
      )}
      onChange={handleChange}
      options={options}
      value={props.value?.sort((a: number, b: number) => Number(a) - Number(b))}
      optionFilterProp="value"
      filterSort={(a, b) => Number(a.value) - Number(b.value)}
    />
  );
};
