import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { COLOR_INDEX } from '@/app/ResourceColors';
import { EventPopoverGeneric } from '@/react/calendar/components/EventPopover';
import { I18nService } from '@/react/services/I18nService';
import { navigate } from '@/react/services/StateServiceFactory';
import { Type } from '@/react/calendar/models/calendar';

export const EventCellContent = ({ event }) => (
  <div>
    <p className="u-mb-0 font-bold text-uppercase">
      {moment(event.startDate).format('ddd') +
        ', ' +
        moment(event.startDate).format('ll')}
    </p>
    {event.allDay ? (
      <p className="u-mb-0 font-normal">{I18nService.getString('All day')}</p>
    ) : (
      <p className="u-mb-0 font-normal">
        {moment(event.startDate).format('LT')} -
        {moment(event.endDate).format('LT')}
      </p>
    )}
    <div className="u-pt-5 u-pb-10">{event.title}</div>
    {event.resources &&
      event.resources.map((resource) => (
        <div key={resource + resource.id} className="small">
          <i
            className="fa fa-circle small u-mr-5"
            style={{ color: COLOR_INDEX[resource.color | 0] }}
          />
          {resource.name}
        </div>
      ))}
  </div>
);

const goToEvent = (eventId) => {
  navigate('app.private.calendar.event', { id: eventId });
};

EventCellContent.propTypes = {
  event: PropTypes.object,
};

const EventCell = ({ event, isPublic, style, className, isPrinting }) => {
  const classNames = `${className} rotas-table__event-cell`;
  return (
    <div style={style} className={classNames}>
      {isPublic || isPrinting ? (
        <EventCellContent event={event} />
      ) : (
        <EventPopoverGeneric
          eventId={event.eventId}
          eventType={Type.Event}
          trigger="hover"
        >
          <a tabIndex={-1} onClick={() => goToEvent(event.eventId)}>
            <EventCellContent event={event} />
          </a>
        </EventPopoverGeneric>
      )}
    </div>
  );
};

EventCell.propTypes = {
  event: PropTypes.object.isRequired, // Event object
  isPublic: PropTypes.bool, // this is for the Public view for shared rota links.
  style: PropTypes.object, // the style is passed fro the CellMeasurer.
  className: PropTypes.string, // this is a class name to allow style override from outside.
  isPrinting: PropTypes.bool, // this is to indicate is its in printing mode, its just as isPublic will render only the EventCellContent .
};

export default EventCell;
