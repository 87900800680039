/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GetCalendarBookingRequestsResponseBody2 = {
    id: string;
    organizationId: number;
    bookingOption: {
        id?: string;
        name?: string;
    };
    status: GetCalendarBookingRequestsResponseBody2.status;
    startDate: string;
    endDate: string;
    respondedBy?: {
        id: number;
        email: string;
        contact: {
            id: number;
            firstName?: string | null;
            lastName?: string | null;
            fullName?: string | null;
        };
    } | null;
    users?: Array<{
        id: number;
        email: string;
        contact: {
            id: number;
            firstName?: string | null;
            lastName?: string | null;
            fullName?: string | null;
        };
    }> | null;
    taxonomy: {
        id?: number;
        name: string;
        alias?: string | null;
        config?: {
            type: GetCalendarBookingRequestsResponseBody2.type;
            publicName: string;
            form?: {
                id: string;
                alias: string;
            };
            followUpForm?: {
                id: string;
                alias: string;
            };
            automaticFollowUpForm?: boolean;
            emailTemplates?: {
                confirmation?: string;
                rejection?: string;
            };
            event?: {
                title: string;
                churchIds: Array<number>;
                groupIds?: Array<number>;
                categoryIds: Array<number>;
                visibility: GetCalendarBookingRequestsResponseBody2.visibility;
            };
        };
    };
    resources: Array<{
        id: number;
        name: string;
        color?: number | null;
    }>;
    calendar?: {
        id: number;
        title: string;
    };
    comment?: string | null;
    submissionData: Array<{
        label: string;
        value?: any;
        isSection?: boolean | null;
    }>;
    followUpSubmissionData?: Array<{
        label: string;
        value?: any;
        isSection?: boolean | null;
    }> | null;
};
export namespace GetCalendarBookingRequestsResponseBody2 {
    export enum status {
        REQUESTED = 'requested',
        ACCEPTED = 'accepted',
        REJECTED = 'rejected',
    }
    export enum type {
        SPECIFIC_AVAILABILITY = 'specific-availability',
        GENERAL_AVAILABILITY = 'general-availability',
    }
    export enum visibility {
        PRIVATE = 'private',
        INTERNAL = 'internal',
        PUBLIC = 'public',
    }
}

