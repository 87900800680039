import _ from 'lodash';

import {
  getCommaFormattedNumber,
  handleSuccessMessage,
} from '@/react/shared/utils';
import { getExternalLink } from '@/react/homepage/services/external-link.service';
import FormService from '@/react/forms/services/FormService';
import ErrorHandlingService from '@/react/services/ErrorHandlingService';

(function () {
  'use strict';
  EventController.$inject = [
    'moment',
    '$scope',
    '$stateParams',
    '$uibModal',
    '$http',
    '$state',
    '$location',
    '$timeout',
    '$q',
    'toastr',
    'gettextCatalog',
    'cdApp',
    'Calendar',
    'CalendarComment',
    'Revisions',
    'Users',
    'Notifications',
    'Invitations',
    'Me',
    'eventData',
    'cdResourceColors',
    'Authorization',
    'Attendance',
    'appUtils',
    'FeatureToggleService',
  ];

  function EventController(
    moment,
    $scope,
    $stateParams,
    $uibModal,
    $http,
    $state,
    $location,
    $timeout,
    $q,
    toastr,
    gettextCatalog,
    cdApp,
    Calendar,
    CalendarComment,
    Revisions,
    Users,
    Notifications,
    Invitations,
    Me,
    eventData,
    cdResourceColors,
    Authorization,
    Attendance,
    appUtils,
    FeatureToggleService
  ) {
    $scope.flagLoading = true;
    $scope.flagDisableOldEventForm;
    FeatureToggleService.hasFeature('disable_old_event_form').then(
      (hasFeature) => {
        $scope.flagDisableOldEventForm = hasFeature;
        $scope.flagLoading = false;

        // Legacy feature. This code opens a "Add form" modal for old events which allowed the user to quickly add a form.
        // The selector is only launched if $stateParams.openAddForm is true.
        // The selector should not be opened if the event was created using the new form or if the feature for disabling old forms is active.
        if (
          $scope.event.clientVersion < 2 &&
          !$scope.flagDisableOldEventForm &&
          $stateParams.openAddForm
        ) {
          $scope.addFormToEvent();
        }
      }
    );
    // =============================================================================
    // General stuff.
    // =============================================================================

    $scope.revision = !_.isUndefined($stateParams.rid) ? eventData : null;
    $scope.event = _.isUndefined($stateParams.rid) ? eventData : eventData.data;
    $scope.eventId = $scope.event.id;

    // Add prep and cleanup time if event does not have it (For old revisions)
    if (
      !_.get($scope.event, 'preparationStartDate') ||
      !_.get($scope.event, 'cleanupEndDate')
    ) {
      $scope.event.preparationStartDate = $scope.event.startDate;
      $scope.event.cleanupEndDate = $scope.event.endDate;
    }

    if ($scope.event.locationObj) {
      $scope.event.location = appUtils.formatGoogleLocation(
        $scope.event.locationObj
      );
    }
    $scope.eventIsRecentlyUpdated =
      moment().diff(moment($scope.event.updatedAt), 'seconds') < 60;
    $scope.eventLink = getExternalLink($scope.event.id, 'event');

    $scope.event.comments = _.map($scope.event.comments, function (comment) {
      comment = new CalendarComment(comment);
      comment.calendarId = $scope.event.id;
      return comment;
    });

    $scope.taxonomyIds = _.map(_.keys($scope.event.taxonomies), _.parseInt);

    if (!Authorization.hasPackage('attendance')) {
      $scope.event.attendance = null;
    }

    $scope.canGroupResources = cdApp.showChurchSelector;

    $scope.groupedResources = $scope.canGroupResources
      ? _.flatten([
          _.filter(
            $scope.event.resources,
            (resource) => !_.get(resource, 'parentResourceId')
          ),

          $scope.event.parentResources,
        ])
      : _.map($scope.event.resources, (el, key) => ({ id: key, ...el }));

    $scope.isParent = function (resource) {
      return (
        $scope.canGroupResources &&
        _.some(
          $scope.event.resources,
          (eventResource) => eventResource.parentResourceId === resource?.id
        )
      );
    };

    $scope.getChildren = function (resource) {
      if (!$scope.isParent(resource)) return [];
      return _.filter($scope.event.resources, {
        parentResourceId: _.get(resource, 'id'),
      });
    };

    $scope.getResourceColorClass = function (resource) {
      return `color-${_.get(resource, 'color', 0)}`;
    };

    $scope.getResourceClass = function (resource) {
      return $scope.isParent(resource) ? 'text-muted' : '';
    };

    $scope.isFutureEvent = moment($scope.event.startDate).isAfter();
    $scope.isAboutToStart =
      moment($scope.event.startDate).diff(moment(), 'minutes') <= 30;

    // Prep and Clean up time data
    $scope.hasPrepTime =
      moment($scope.event.startDate).diff(
        $scope.event.preparationStartDate,
        'seconds'
      ) !== 0;
    $scope.hasCleanupTime =
      moment($scope.event.endDate).diff(
        $scope.event.cleanupEndDate,
        'seconds'
      ) !== 0;
    $scope.prepTimeMinutes = moment($scope.event.startDate).diff(
      $scope.event.preparationStartDate,
      'minutes'
    );

    $scope.cleanupTimeMinutes = moment($scope.event.cleanupEndDate).diff(
      $scope.event.endDate,
      'minutes'
    );

    $scope.getPrepTimeLabel = (minutes) => {
      // Show hour
      if (minutes >= 60) {
        const numHours = getCommaFormattedNumber(minutes / 60, 1);
        return gettextCatalog.getPlural(
          numHours,
          '1 hour preparation time',
          '{{numHours}} hours preparation time',
          {
            numHours,
          }
        );
      } else {
        // Show minutes
        return gettextCatalog.getString('{{amount}} minutes preparation time', {
          amount: minutes,
        });
      }
    };

    $scope.getCleanupTimeLabel = (minutes) => {
      // Show hour
      if (minutes >= 60) {
        const numHours = getCommaFormattedNumber(minutes / 60, 1);
        return gettextCatalog.getPlural(
          numHours,
          '1 hour clean up time',
          '{{numHours}} hours clean up time',
          {
            numHours,
          }
        );
      } else {
        // Show minutes
        return gettextCatalog.getString('{{amount}} minutes clean up time', {
          amount: minutes,
        });
      }
    };

    // Transform the users object into an array that can be ordered.
    $scope.usersConverted = _($scope.event.users)
      .mapValues(function (user, id) {
        user.id = parseInt(id, 10);
        return user;
      })
      .values()
      .value();

    const isNewEventForm = $scope.event.clientVersion >= 2;
    if (!isNewEventForm) {
      // Fetch the notification history for the current event.
      Notifications.query(
        {
          entityType: 'calendar',
          entityId: $scope.event.id,
        },

        function (response) {
          $scope.notifications = response.reverse();
        }
      );
    }

    // Get the attendance status of the current user if booked for the event.
    if (!isNewEventForm && (_.has($scope.event.users), Me.id)) {
      Invitations.query(function (response) {
        $scope.booking = _.find(response, { id: $scope.event.id });
      });
    }

    // Fetch revisions only if user has required roles.
    if (!isNewEventForm && $scope.event.canEdit) {
      fetchRevisions();
    }

    // Display the date in different formats.
    $scope.displayDate = function (start, end, allDay, hideEndDate) {
      // It is not all day.
      if (!allDay) {
        // End time is not hidden.
        if (!hideEndDate) {
          // Difference is less than 24 hours, we only show end time.
          if (moment(end).diff(moment(start), 'hours') < 24) {
            return (
              moment(start).format('LLLL') +
              ' ' +
              gettextCatalog.getString('to', {}, 'Date interval') +
              ' ' +
              moment(end).format('LT')
            );
          } else {
            return (
              moment(start).format('LLLL') +
              ' ' +
              gettextCatalog.getString('to', {}, 'Date interval') +
              ' ' +
              moment(end).format('LLLL')
            );
          }
        } else {
          // End date is hidden.
          return moment(start).format('LLLL');
        }
      } else {
        // Difference is less than 24 hours, we show only start date.
        if (moment(end).diff(moment(start), 'hours') < 24) {
          return (
            moment(start).format('dddd, MMMM Do YYYY') +
            ' ' +
            gettextCatalog.getString('(all day)')
          );
        } else {
          return (
            moment(start).format('dddd, MMMM Do YYYY') +
            ' ' +
            gettextCatalog.getString('to', {}, 'Date interval') +
            ' ' +
            moment(end).format('dddd, MMMM Do YYYY')
          );
        }
      }
    };

    // Generate a date from timestamp.
    $scope.Date = function (number, format) {
      return moment(number).format(format);
    };

    // Time ago.
    $scope.timeAgo = function (number) {
      return moment(number).fromNow();
    };

    // Limit notifications to only 5 items to begin with.
    $scope.limit = 5;

    // Load more notification logs per user request.
    $scope.loadMore = function () {
      $scope.limit += 5;
    };

    // Static category/resource colors.
    $scope.colors = cdResourceColors;

    $scope.comments = {};

    // Post comment.
    $scope.postComment = function (comment) {
      if (!comment) {
        return;
      }

      new CalendarComment({
        calendarId: $scope.event.id,
        body: comment,
      }).$save(
        function () {
          toastr.success(
            gettextCatalog.getString('Your comment has been submitted.')
          );

          $state.reload();
        },
        function () {
          toastr.error(
            gettextCatalog.getString('Your comment could not be submitted.')
          );
        }
      );
    };

    // Update attendance status.
    $scope.updateBooking = function (response) {
      Calendar.updateResponse(
        {
          eventId: $scope.booking.id,
          response,
        },

        {}
      ).$promise.then(() => {
        toastr.success(
          gettextCatalog.getString(
            'Your attendance status for "{{event}}" has been updated.',
            {
              event: $scope.booking.title,
            }
          )
        );

        $state.reload();
      });
    };

    $scope.updateAttendance = () => {
      $uibModal
        .open({
          component: 'cdRegisterAttendanceModal',
          resolve: {
            event: () => $scope.event,
            attendanceCategories: () => Attendance.getCategories().$promise,
            attendanceFields: () => Attendance.getFields().$promise,
            attendanceData: () => ({
              attendanceCategoryId: _.get(
                $scope.event,
                'attendance.attendanceCategory.id'
              ),

              vicarId: _.get($scope.event, 'attendance.vicar.id'),
              attendanceFields: _.reduce(
                $scope.event.attendance.attendanceFields,
                (result, field) => {
                  result[field.id] = field.amount;
                  return result;
                },
                {}
              ),
            }),
          },
        })
        .result.then(() => {
          $state.reload();
        });
    };

    $scope.goBackToCalendar = () => {
      $state.go('app.private.calendar.full');
    };

    // =============================================================================
    // Revisions of the current event.
    // =============================================================================

    $scope.isRevision = !_.isUndefined($stateParams.rid);

    // Fetch revisions for the current event.
    function fetchRevisions() {
      Revisions.query(
        {
          entityType: 'event',
          entityId: $stateParams.id,
        },

        function (response) {
          $scope.revisions = response;

          Users.query(function (users) {
            _.each($scope.revisions, function (revision, index) {
              $scope.revisions[index].revisionAuthorName = _.result(
                _.find(users, { id: revision.revisionAuthorId }),
                'name'
              );
            });
          });
        }
      );
    }

    // Revert to another version.
    $scope.revert = function (revision) {
      $uibModal
        .open({
          templateUrl: 'confirmEventRevert.html',
          scope: $scope,
          controller: [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              'ngInject';

              $scope.revision = revision;

              $scope.save = function () {
                let url =
                  cdApp.config.api.main +
                  '/revisions/event/' +
                  $stateParams.id +
                  '/revert/' +
                  revision.id;

                $http.post(url).then(
                  function () {
                    toastr.success(
                      gettextCatalog.getString(
                        '"{{title}}" has been reverted to another version.',
                        {
                          title: $scope.event.title,
                        }
                      )
                    );

                    $uibModalInstance.close();
                  },
                  function () {
                    toastr.error(
                      gettextCatalog.getString(
                        'Something went wrong with the revert.'
                      )
                    );
                  }
                );
              };

              $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
              };
            },
          ],
        })
        .result.then(function () {
          $state.reload();
        });
    };

    // Print calendar
    $scope.print = function () {
      window.print();
    };

    // =============================================================================
    // Event sharing
    // =============================================================================

    $scope.copyLink = () => {
      $uibModal.open({
        templateUrl:
          '@/app/calendar/event-detail/templates/copy-link.modal.html',
        resolve: {
          eventLink: () => $scope.eventLink,
        },

        controllerAs: '$ctrl',
        controller: [
          '$uibModalInstance',
          'eventLink',
          function ($uibModalInstance, eventLink) {
            'ngInject';

            this.eventLink = eventLink;
            this.dismiss = () => {
              $uibModalInstance.dismiss();
            };
          },
        ],
      });
    };

    $scope.viewResponses = () => {
      const url = $state.href('app.private.forms.view', {
        id: _.get($scope.event, 'form.id'),
      });

      window.open(url, '_blank');
    };

    $scope.viewForm = () => {
      window.open(_.get($scope.event, 'form.link'), '_blank');
    };

    $scope.editForm = () => {
      const url = $state.href('app.private.forms.edit', {
        id: _.get($scope.event, 'form.id'),
      });

      window.open(url, '_blank');
    };

    $scope.removeForm = () => {
      FormService.removeFormFromEvent($scope.event.id)
        .then(() => {
          handleSuccessMessage(
            gettextCatalog.getString('Successfully removed form.')
          );

          $state.reload();
        })
        .catch(ErrorHandlingService);
    };

    $scope.hasForm = () => !_.isNil($scope.event.form);

    $scope.addFormToEvent = () => {
      $uibModal
        .open({
          component: 'cdrAddFormToEventModal',
          resolve: {
            calendarId: $scope.event.id,
          },

          backdrop: 'static',
        })
        .result.then(() => {
          $location.path('calendar');
        });
    };

    $scope.getFacebookStatus = () => {
      const { event } = $scope;
      const { publish, publishOn, publishedOn, isScheduled, exportStatus } =
        _.get(event, 'facebook') || {};

      // Event hasn't been shared yet
      if (!publish) {
        const hasPermission = Authorization.hasPermission(
          'canSetVisibilityToPublic'
        );

        const isPublic = event.visibility === 'public';
        const notPastEvent = moment(event.startDate).isAfter(moment());

        // Whether the event can be shared or not
        const canBeShared = hasPermission && isPublic && notPastEvent;
        return canBeShared ? 'can_share' : null;
      } else if (exportStatus === 'failed') {
        return 'failed';
      } else if (publishedOn) {
        return 'published';
      } else if (!isScheduled || moment(publishOn).isBefore(moment())) {
        return 'in_progress';
      } else {
        return 'scheduled';
      }
    };

    $scope.shareOnFacebook = () => {
      $uibModal
        .open({
          component: 'cdShareOnFacebookModal',
          resolve: {
            event: () => angular.copy($scope.event),
          },
        })
        .result.then(() => $state.reload());
    };

    $scope.getEventFacebookUrl = () => {
      const fbPostId = _.get($scope.event, 'facebook.fbPostId');
      if (!fbPostId) return null;
      return `https://www.facebook.com/${fbPostId}`;
    };

    // =============================================================================
    // Modals.
    // =============================================================================

    // Notify users.
    $scope.notify = function (id, user) {
      let that = $scope;

      $uibModal.open({
        templateUrl: '@/app/calendar/event-detail/templates/notify.modal.html',
        controller: [
          '$scope',
          '$uibModalInstance',
          function ($scope, $uibModalInstance) {
            'ngInject';

            $scope.user = _.extend(user, { id: parseInt(id, 10) });

            $scope.send = function () {
              new Notifications({
                entityId: that.event.id,
                entityType: 'calendar',
                userId: $scope.user.id,
              }).$save(
                function () {
                  toastr.info(
                    gettextCatalog.getString(
                      'The notification is being sent...'
                    )
                  );

                  $uibModalInstance.close();
                  $timeout($state.reload, 5000);
                },
                function () {
                  toastr.error(
                    gettextCatalog.getString(
                      'We could not send the notification. Please try again.'
                    )
                  );
                }
              );
            };

            $scope.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };
          },
        ],
      });
    };

    // Subscribe to calendar.
    $scope.subscribe = function () {
      $uibModal.open({
        templateUrl: 'event-detail/templates/subscribe.modal.html',
        controller: [
          '$scope',
          '$uibModalInstance',
          function ($scope, $uibModalInstance) {
            'ngInject';

            $scope.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };
          },
        ],
      });
    };

    // Delete event.
    $scope.deleteEvent = function (entity) {
      $uibModal
        .open({
          component: 'cdEventDeleteModal',
          resolve: {
            event: () => entity,
          },
        })
        .result.then(() => {
          $location.path('calendar');
        });
    };

    // Edit comment.
    $scope.editComment = function (comment, onSuccess) {
      if (!comment || !comment.$save) return;
      comment.$save(function () {
        toastr.success(
          gettextCatalog.getString('The comment has been updated.')
        );

        if (onSuccess) onSuccess();
        $state.reload();
      });
    };

    // Delete comment.
    $scope.deleteComment = function (comment, onSuccess) {
      if (!comment || !comment.$delete) return;
      comment.$delete(function () {
        toastr.success(
          gettextCatalog.getString('The comment has been deleted.')
        );

        if (onSuccess) onSuccess();
        _.remove($scope.event.comments, comment);
      });
    };

    // Export event.
    $scope.export = function (event) {
      $uibModal.open({
        component: 'cdCalendarFeedModal',
        resolve: {
          feedName: () =>
            gettextCatalog.getString('Subscribe to calendar') +
            (_.isEmpty(event.title) ? '' : ` "${event.title}"`),
          feedId: () => event.id,
          feedType: () => 'entity',
          showPublicFeed: () => false,
          token: () =>
            $q((resolve, reject) => {
              $http
                .get(`${cdApp.config.api.main}/ical/token`)
                .then((res) => resolve(res.data))
                .catch(reject);
            }),
        },
      });
    };

    // Sometimes double clicking an event in the calendar triggers
    // the qtip as well, so this just makes sure the qtip is hidden
    // once the event is fully rendered.
    $(function () {
      $('.qtip').qtip('hide');
    });

    // =============================================================================
    // Event planning.
    // =============================================================================

    $scope.eventHasRotas = !_.isEmpty($scope.event.calendar_tasks);

    /**
     * Group shifts per calendar tasks.
     */
    const setRotas = () => {
      $scope.rotas = _.map($scope.event.calendar_tasks, (calendarTask) => ({
        calendarTask,
        shifts: _.filter($scope.event.shifts, {
          task: { id: calendarTask.taskId },
        }),
      }));
    };
    setRotas();

    $scope.onShiftsUpdated = (shifts, calendar_tasks) => {
      $scope.event.calendar_tasks = calendar_tasks;
      $scope.event.shifts = shifts;
      setRotas();
    };
  }

  angular
    .module('cdApp.calendar')
    .controller('EventController', EventController);
})();
