import React, { useEffect } from 'react';
import { Form, Input } from 'antd';

import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import { TagInterface } from '@/react/people/types/tag.type';
import { I18nService } from '@/react/services/I18nService';
import { useCreateFilter } from '@/react/people/tag-list/hooks/useCreateFilter';

export const onCreateFilter = createCdModal<{
  data?: TagInterface;
}>(({ data, setModalProps }) => {
  const { onCreateFilter, createFilterForm, formInitialValue } =
    useCreateFilter({
      data,
    });

  useEffect(() => {
    setModalProps({
      modalType: ModalType.MODAL,
      title: I18nService.getString('Create Filter'),
      okText: I18nService.getString('Create'),
      onOk: onCreateFilter,
    });
  }, [onCreateFilter, setModalProps]);
  return (
    <Form
      layout="vertical"
      form={createFilterForm}
      initialValues={formInitialValue}
    >
      <Form.Item
        name="name"
        label={I18nService.getString('Name')}
        rules={[{ required: true, message: 'Please input filter name!' }]}
      >
        <Input
          placeholder={I18nService.getString(
            'Enter a name for the new filter...'
          )}
        />
      </Form.Item>

      <Form.Item
        name="tagName"
        label={I18nService.getString(
          'The new filter will be created using the following filters :'
        )}
        rules={[{ required: true, message: 'Please input filter name!' }]}
      >
        <Input
          readOnly
          placeholder={I18nService.getString('create one or more tags...')}
        />
      </Form.Item>
    </Form>
  );
});
