import { I18nService } from '@/react/services/I18nService';
export const TIME_OPTIONS = () => [
  {
    value: 5,
    label: I18nService.getString('5 minutes'),
  },
  {
    value: 10,
    label: I18nService.getString('10 minutes'),
  },
  {
    value: 15,
    label: I18nService.getString('15 minutes'),
  },
  {
    value: 20,
    label: I18nService.getString('20 minutes'),
  },
  {
    value: 30,
    label: I18nService.getString('30 minutes'),
  },
  {
    value: 45,
    label: I18nService.getString('45 minutes'),
  },
  {
    value: 60,
    label: I18nService.getString('1 hour'),
  },
  {
    value: 90,
    label: I18nService.getString('1,5 hours'),
  },
  {
    value: 120,
    label: I18nService.getString('2 hours'),
  },
  {
    value: 180,
    label: I18nService.getString('3 hours'),
  },
  {
    value: 240,
    label: I18nService.getString('4 hours'),
  },
  {
    value: 360,
    label: I18nService.getString('6 hours'),
  },
  {
    value: 480,
    label: I18nService.getString('8 hours'),
  },
  {
    value: 720,
    label: I18nService.getString('12 hours'),
  },
  {
    value: 1440,
    label: I18nService.getString('24 hours'),
  },
];
