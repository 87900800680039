import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';

import { handleError } from '@/react/services/ErrorHandlingService';

/**
 * The query client singleton instance used by the application.
 * This is placed in its own file to avoid circular dependencies
 **/
export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      handleError(error);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      handleError(error);
    },
  }),
});
