import _ from 'lodash';
import React from 'react';
import moment, { Moment } from 'moment';
import { Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { ColumnsType } from 'antd/lib/table';

import { I18nService } from '../../../services/I18nService';
import AppUtils from '../../../services/AppUtilsService';
import { CapitalizedDiv } from '../../../shared/antd/StyledAntdComponents';
import {
  PaymentMethodTypes,
  Intention,
  IntentionStatusTypes,
  IntentionPriorityTypes,
} from '../../models/intention';
import { Taxonomy } from '../../../shared/models/taxonomy';
import { Resource } from '../../../shared/models/resource';

class ManageIntentionsTableColumns {
  public getIntentionColumns(): ColumnsType<Record<string, unknown>> {
    return [
      {
        title: I18nService.getString('Number'),
        dataIndex: 'formattedReferenceNumber',
        key: 'formattedReferenceNumber',
      },
      {
        title: I18nService.getString('Intention text'),
        dataIndex: 'intentionText',
        key: 'intentionText',
      },
      {
        title: I18nService.getString('Intention Type'),
        dataIndex: ['fee', 'name'],
        key: 'fee',
      },
      {
        title: I18nService.getString('Churches'),
        dataIndex: 'church',
        key: 'church',
      },
      {
        title: I18nService.getString('Resource'),
        dataIndex: 'resource',
        key: 'resource',
      },
      {
        title: I18nService.getString('Payment method'),
        dataIndex: 'paymentMethod',
        key: 'paymentMethod',
        render: function columnRender(paymentMethod: PaymentMethodTypes) {
          return (
            <div>
              {paymentMethod === PaymentMethodTypes.PAIDBYCASH
                ? I18nService.getString('Cash')
                : I18nService.getString('Wire transfer')}
            </div>
          );
        },
      },
      {
        title: I18nService.getString('Payment status'),
        dataIndex: 'paid',
        key: 'paid',
        align: 'center',
        render: function columnRender(paid: boolean) {
          return (
            <div>
              {paid ? (
                <FontAwesomeIcon icon={faCheckCircle} />
              ) : (
                <FontAwesomeIcon icon={faTimesCircle} />
              )}
            </div>
          );
        },
      },
      {
        title: I18nService.getString('Payment date'),
        dataIndex: 'paidAt',
        key: 'paidAt',
        render: function columnRender(paidAt: Date) {
          return (
            <div>{paidAt ? moment(paidAt).format('YYYY-MM-DD') : null}</div>
          );
        },
      },
      {
        title: I18nService.getString('Status'),
        dataIndex: 'status',
        key: 'status',
        render: function columnRender(status: IntentionStatusTypes) {
          switch (status) {
            case IntentionStatusTypes.ASSIGNED:
              return <div>{I18nService.getString('Assigned')}</div>;
            case IntentionStatusTypes.UNASSIGNED:
              return <div>{I18nService.getString('Unassigned')}</div>;
            case IntentionStatusTypes.COMPLETED:
              return <div>{I18nService.getString('Completed')}</div>;
          }
        },
      },
      {
        title: I18nService.getString('Desired date'),
        dataIndex: 'preferredDate',
        key: 'preferredDate',
      },
      {
        title: I18nService.getString('Notes for desired date'),
        dataIndex: 'preferredNote',
        key: 'preferredNote',
      },
      {
        title: I18nService.getString('Founder'),
        dataIndex: 'founder',
        key: 'founder',
      },
      {
        title: I18nService.getString('Priority'),
        dataIndex: null,
        key: 'priority',
        render: function columnRender(intention: Intention) {
          const priority = intention.priority;
          if (intention.status === IntentionStatusTypes.COMPLETED) {
            return <CapitalizedDiv>{priority}</CapitalizedDiv>;
          } else if (intention.status === IntentionStatusTypes.ASSIGNED) {
            return (
              <div>
                {priority === IntentionPriorityTypes.PERSOLVIERT
                  ? I18nService.getString('Primary')
                  : I18nService.getString('Forwarded')}
              </div>
            );
          } else {
            return <div></div>;
          }
        },
      },
    ];
  }

  public getEventColumns(): ColumnsType<Record<string, unknown>> {
    return [
      {
        title: I18nService.getString('Title'),
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: I18nService.getString('Start Date'),
        dataIndex: 'startDate',
        key: 'startDate',
        render: function columnRender(startDate: Moment) {
          return <div>{moment(startDate).format('LLLL')}</div>;
        },
      },
      {
        title: I18nService.getString('End Date'),
        dataIndex: 'endDate',
        key: 'endDate',
        render: function columnRender(endDate: Moment) {
          return <div>{moment(endDate).format('LLLL')}</div>;
        },
      },
      {
        title: I18nService.getString('Categories'),
        dataIndex: 'taxonomies',
        key: 'taxonomies',
        render: function columnRender(taxonomies: Taxonomy[]) {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {_.map(taxonomies, (taxonomy) => (
                <Space key={taxonomy.id}>
                  <i
                    className={
                      'fa fa-circle fa-lg ' +
                      AppUtils.getTaxonomyColorClass(taxonomy)
                    }
                  />
                  {taxonomy.name}
                </Space>
              ))}
            </div>
          );
        },
      },
      {
        title: I18nService.getString('Parishes'),
        dataIndex: 'churches',
        key: 'churches',
        render: function columnRender(churches: Resource[]) {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {_.map(churches, (church) => (
                <Space key={church.id}>{church.name}</Space>
              ))}
            </div>
          );
        },
      },
      {
        title: 'Ressourcen',
        dataIndex: 'resources',
        key: 'resources',
        render: function columnRender(resources: Resource[]) {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {_.map(resources, (resource) => (
                <Space key={resource.id}>
                  <i
                    className={
                      'fa fa-circle fa-lg ' +
                      AppUtils.getTaxonomyColorClass(resource)
                    }
                  />
                  {resource.name}
                </Space>
              ))}
            </div>
          );
        },
      },
      {
        title: I18nService.getString('# of Intentions'),
        dataIndex: 'noOfAssignedIntentions',
        key: 'noOfAssignedIntentions',
      },
    ];
  }
}

export default new ManageIntentionsTableColumns();
