/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddAttendanceNumbersRequestBody } from '../models/AddAttendanceNumbersRequestBody';
import type { AddAttendanceNumbersResponseBody } from '../models/AddAttendanceNumbersResponseBody';
import type { EmptyObject } from '../models/EmptyObject';
import type { GetAttendanceCategoriesResponseBody } from '../models/GetAttendanceCategoriesResponseBody';
import type { GetAttendanceEventsResponseBody } from '../models/GetAttendanceEventsResponseBody';
import type { GetAttendanceFieldsResponseBody } from '../models/GetAttendanceFieldsResponseBody';
import type { GetAttendanceTotalResponseBody } from '../models/GetAttendanceTotalResponseBody';
import type { ImportAttendanceFromSognDkResponseBody } from '../models/ImportAttendanceFromSognDkResponseBody';
import type { PutAttendanceEventsAmountRequestBody } from '../models/PutAttendanceEventsAmountRequestBody';
import type { PutAttendanceEventsRequestBody } from '../models/PutAttendanceEventsRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AttendanceService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetAttendanceCategories
     * Get Attendance Categories
     * @returns GetAttendanceCategoriesResponseBody
     * @throws ApiError
     */
    public getAttendanceCategories(): CancelablePromise<GetAttendanceCategoriesResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/attendance/categories',
        });
    }
    /**
     * GetAttendanceEvents
     * Get Attendance Events
     * @returns GetAttendanceEventsResponseBody
     * @throws ApiError
     */
    public getAttendanceEvents({
        limit = 25,
        offset,
        alreadyRegistered = false,
        categories,
        resources,
        attendanceCategories,
        until,
        from,
        eventsWithoutResourcesInChurchIds,
        orderBy = 'startDate',
        orderDirection = 'DESC',
    }: {
        limit?: number,
        offset?: number,
        alreadyRegistered?: boolean,
        categories?: Array<number>,
        resources?: Array<number>,
        attendanceCategories?: Array<string>,
        until?: string,
        from?: string,
        eventsWithoutResourcesInChurchIds?: Array<number>,
        orderBy?: 'startDate' | 'title',
        orderDirection?: 'ASC' | 'DESC',
    }): CancelablePromise<GetAttendanceEventsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/attendance/events',
            query: {
                'limit': limit,
                'offset': offset,
                'alreadyRegistered': alreadyRegistered,
                'categories': categories,
                'resources': resources,
                'attendanceCategories': attendanceCategories,
                'until': until,
                'from': from,
                'eventsWithoutResourcesInChurchIds': eventsWithoutResourcesInChurchIds,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
            },
        });
    }
    /**
     * UpdateAttendanceEvents
     * Update Attendance Events
     * @returns EmptyObject
     * @throws ApiError
     */
    public putAttendanceEvents({
        eventId,
        requestBody,
    }: {
        eventId: number,
        requestBody?: PutAttendanceEventsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/attendance/events/{eventId}',
            path: {
                'eventId': eventId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * UpdateAttendanceNumbers
     * Update Attendance Numbers
     * @returns AddAttendanceNumbersResponseBody
     * @throws ApiError
     */
    public putAttendanceEventsAmount({
        eventId,
        attendanceFieldId,
        requestBody,
    }: {
        eventId: number,
        attendanceFieldId: string,
        requestBody?: PutAttendanceEventsAmountRequestBody,
    }): CancelablePromise<AddAttendanceNumbersResponseBody> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/attendance/events/{eventId}/amount/{attendanceFieldId}',
            path: {
                'eventId': eventId,
                'attendanceFieldId': attendanceFieldId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * AddAttendanceNumbers
     * Add Attendance Numbers
     * @returns AddAttendanceNumbersResponseBody
     * @throws ApiError
     */
    public putAttendanceEventsAmountAdd({
        eventId,
        attendanceFieldId,
        requestBody,
    }: {
        eventId: number,
        attendanceFieldId: string,
        requestBody?: AddAttendanceNumbersRequestBody,
    }): CancelablePromise<AddAttendanceNumbersResponseBody> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/attendance/events/{eventId}/amount/{attendanceFieldId}/add',
            path: {
                'eventId': eventId,
                'attendanceFieldId': attendanceFieldId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * SubtractAttendanceNumbers
     * Subtract Attendance Numbers
     * @returns EmptyObject
     * @throws ApiError
     */
    public putAttendanceEventsAmountSubtract({
        eventId,
        attendanceFieldId,
        requestBody,
    }: {
        eventId: number,
        attendanceFieldId: string,
        requestBody?: AddAttendanceNumbersRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/attendance/events/{eventId}/amount/{attendanceFieldId}/subtract',
            path: {
                'eventId': eventId,
                'attendanceFieldId': attendanceFieldId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * ExportAttendanceData
     * Get Attendance Export Data
     * @returns EmptyObject
     * @throws ApiError
     */
    public getAttendanceExportData({
        exportToken,
        categories,
        resources,
        attendanceCategories,
        until,
        from,
        fileType = 'csv',
        eventsWithoutResourcesInChurchIds,
    }: {
        exportToken: string,
        categories?: Array<number>,
        resources?: Array<number>,
        attendanceCategories?: Array<string>,
        until?: string,
        from?: string,
        fileType?: 'csv' | 'ods' | 'xlsx',
        eventsWithoutResourcesInChurchIds?: Array<number>,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/attendance/export/data',
            query: {
                'exportToken': exportToken,
                'categories': categories,
                'resources': resources,
                'attendanceCategories': attendanceCategories,
                'until': until,
                'from': from,
                'fileType': fileType,
                'eventsWithoutResourcesInChurchIds': eventsWithoutResourcesInChurchIds,
            },
        });
    }
    /**
     * ExportAttendanceToken
     * Get Attendance Export Token
     * @returns EmptyObject
     * @throws ApiError
     */
    public getAttendanceExportToken(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/attendance/export/token',
        });
    }
    /**
     * GetAttendanceFields
     * Get Attendance Fields
     * @returns GetAttendanceFieldsResponseBody
     * @throws ApiError
     */
    public getAttendanceFields(): CancelablePromise<GetAttendanceFieldsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/attendance/fields',
        });
    }
    /**
     * GetStartedWithAttendance
     * Get Started with Attendance
     * @returns EmptyObject
     * @throws ApiError
     */
    public putAttendanceGetStarted({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/attendance/get-started',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * ImportAttendanceFromSognDk
     * Import Attendance from Sogn.dk
     * @returns ImportAttendanceFromSognDkResponseBody
     * @throws ApiError
     */
    public putAttendanceImportSogndk({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<ImportAttendanceFromSognDkResponseBody> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/attendance/import/sogndk',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetAttendanceInsights
     * Get Attendance Insights
     * @returns EmptyObject
     * @throws ApiError
     */
    public getAttendanceInsights({
        categories,
        resources,
        attendanceCategories,
        year = 2025,
        eventsWithoutResourcesInChurchIds,
    }: {
        categories?: Array<number>,
        resources?: Array<number>,
        attendanceCategories?: Array<string>,
        year?: number,
        eventsWithoutResourcesInChurchIds?: Array<number>,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/attendance/insights',
            query: {
                'categories': categories,
                'resources': resources,
                'attendanceCategories': attendanceCategories,
                'year': year,
                'eventsWithoutResourcesInChurchIds': eventsWithoutResourcesInChurchIds,
            },
        });
    }
    /**
     * GetAttendanceTotal
     * Get Attendance Total
     * @returns GetAttendanceTotalResponseBody
     * @throws ApiError
     */
    public getAttendanceTotal({
        categories,
        resources,
        attendanceCategories,
        until,
        from,
        eventsWithoutResourcesInChurchIds,
    }: {
        categories?: Array<number>,
        resources?: Array<number>,
        attendanceCategories?: Array<string>,
        until?: string,
        from?: string,
        eventsWithoutResourcesInChurchIds?: Array<number>,
    }): CancelablePromise<GetAttendanceTotalResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/attendance/total',
            query: {
                'categories': categories,
                'resources': resources,
                'attendanceCategories': attendanceCategories,
                'until': until,
                'from': from,
                'eventsWithoutResourcesInChurchIds': eventsWithoutResourcesInChurchIds,
            },
        });
    }
}
