import { Form, Space } from 'antd';
import React from 'react';
import { FormInstance } from 'antd/lib';

import { CdUserSelect } from '../../../groups/components/CdUserSelect';
import { CategoryType } from '../../../shared/models/category';
import { CdEventCategorySelect } from '../../event-details/components/CdEventCategorySelect';

import { BookingOptionsSelect } from './BookingOptionsSelect';

export const EnquiriesFilterForm = (props: { form: FormInstance }) => (
  <Form form={props.form} layout="vertical">
    <Space style={{ marginBottom: 16 }}>
      <Form.Item name="availabilityCategoryIds" noStyle>
        <CdEventCategorySelect
          mode="multiple"
          maxTagCount="responsive"
          selectType="filter"
          type={CategoryType.availability}
          style={{ width: 200 }}
        />
      </Form.Item>
      <Form.Item dependencies={['availabilityCategoryIds']} noStyle>
        {() => (
          <Form.Item name="bookingOptionIds" noStyle>
            <BookingOptionsSelect
              taxonomyIds={props.form.getFieldValue('availabilityCategoryIds')}
              selectType="filter"
              style={{ width: 200 }}
              disabled={false}
            />
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item name="userIds" noStyle>
        <CdUserSelect
          selectType="filter"
          mode="multiple"
          maxTagCount="responsive"
          showAvatar={false}
          style={{ width: 200 }}
        />
      </Form.Item>
    </Space>
  </Form>
);
