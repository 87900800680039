import ColorPicker from 'rc-color-picker';
import React from 'react';
import { Checkbox, Space, Typography } from 'antd';
import styled from 'styled-components';

import { I18nService } from '@/react/services/I18nService';

export const StyledColorPicker = styled(ColorPicker)`
  &&&& {
    line-height: 0;

    border-radius: 6px;

    span.rc-color-picker-trigger {
      height: 30px;
      width: 30px;
      display: inline-block;
      border-radius: 6px;
      border: 2px solid white;

      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
      :hover {
        box-shadow: 0 0 0 1px #1fa1c2;
      }
    }
  }
`;
const ColorDiv = styled.div`
  display: flex;
  gap: 14px;
  padding: 0px;
  align-items: center;
`;

const TransparentBox = styled.div`
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
  border-radius: 6px;

  div {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABGdBTUEAALGPC/xhBQAAAFpJREFUWAntljEKADAIA23p6v//qQ+wfUEcCu1yriEgp0FHRJSJcnehmmWm1Dv/lO4HIg1AAAKjTqm03ea88zMCCEDgO4HV5bS757f+7wRoAAIQ4B9gByAAgQ3pfiDmXmAeEwAAAABJRU5ErkJggg==')
      0% 0% / 28px;
    height: 30px;
    width: 30px;
    border-radius: 6px;
    border: 2px solid white;
  }
`;

const { Link } = Typography;
const TRANSPARENT_COLOR = '#ffffff00';

export const CdrColorPicker = ({
  title,
  value,
  onChange,
  transparentControls,
  defaultColor,
}: {
  title?: string | React.ReactNode;
  value?: string;
  onChange?: (color: string) => void;
  transparentControls?: boolean;
  defaultColor?: string;
}) => {
  const isTransparent = TRANSPARENT_COLOR === value;

  const switchOnChange = () => {
    const colorValue = defaultColor ? defaultColor : '#ff0000';

    onChange(isTransparent ? colorValue : TRANSPARENT_COLOR);
  };

  const switchtToDefaultColor = () => {
    onChange(defaultColor);
  };

  return (
    <ColorDiv>
      {isTransparent && (
        <TransparentBox>
          <div />
        </TransparentBox>
      )}
      {!isTransparent && (
        <StyledColorPicker
          onChange={({ color }) => onChange(color)}
          color={value || defaultColor}
          enableAlpha={false}
        />
      )}
      {(transparentControls || defaultColor) && (
        <Space direction="horizontal" align="center">
          {transparentControls && (
            <Checkbox checked={isTransparent} onClick={() => switchOnChange()}>
              {I18nService.getString('Make transparent')}
            </Checkbox>
          )}
          {value && value !== TRANSPARENT_COLOR && value !== defaultColor && (
            <Link onClick={() => switchtToDefaultColor()}>
              {I18nService.getString('Revert back to your default color.')}
            </Link>
          )}
        </Space>
      )}
      {title && <Typography.Text strong>{title}</Typography.Text>}
    </ColorDiv>
  );
};
