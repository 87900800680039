import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Space, Divider } from 'antd';

import CdrEmptyState from '@/react/shared/components/cd-result/CdrEmptyState';
import { I18nService } from '@/react/services/I18nService';
import { CdLock } from '@/react/shared/components/Icons';
import { CdVerticalSpace } from '@/react/shared/components/cd-vertical-space/CdVerticalSpace';

export const Container = styled.main`
  border: 1px solid #d8ded8;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.05);
  margin-bottom: 18px;
  padding-bottom: 16px;
`;
export const Header = styled.header`
  font-weight: 700;
  padding: 12px 0 0 16px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
`;

export const InformationCard = ({
  title,
  canView,
  hiddenTitle,
  children,
  icon,
  headerExtraElements,
}: {
  title: string;
  canView: boolean;
  hiddenTitle: string;
  children: any;
  icon: ReactNode;
  headerExtraElements?: ReactNode;
}) => (
  <Container>
    <Header>
      <Space>
        {icon}
        {title}
      </Space>
      {headerExtraElements}
    </Header>
    <Divider style={{ marginTop: '12px', marginBottom: '10px' }} />
    {!canView && (
      <CdrEmptyState
        EmptyStateIcon={<CdLock />}
        subtitle={I18nService.getString(
          'You do not have access to view this information'
        )}
        title={hiddenTitle}
      />
    )}
    {canView && (
      <CdVerticalSpace style={{ padding: '0px 16px' }}>
        {children}
      </CdVerticalSpace>
    )}
  </Container>
);
