import { useState } from 'react';
import _ from 'lodash';

import {
  defaultFilterState,
  FilterStateInterface,
  organizationStatus,
} from '../screens/UserList';
export const useUserList = () => {
  const [filterState, setFilterState] =
    useState<FilterStateInterface>(defaultFilterState);
  const [tableData, setTableData] = useState([]);

  const handleSearchUser = (e: any) => {
    setFilterState({ ...filterState, search: e.target.value });
  };
  const handleStatusChange = (status: organizationStatus[]) => {
    setFilterState({ ...filterState, status });
  };
  const handleFilterChange = (userRoleFilter: string[]) => {
    const uniqueRoles = _.uniq(userRoleFilter);
    setFilterState({ ...filterState, roles: uniqueRoles });
  };
  return {
    tableData,
    filterState,
    setFilterState,
    setTableData,
    handleSearchUser,
    handleStatusChange,
    handleFilterChange,
  };
};
