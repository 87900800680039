import { selector, selectorFamily } from 'recoil';
import { useQuery } from '@tanstack/react-query';

import { CdClientSideTableApiSearchParam } from '../../shared/components/cd-client-side-table/types';

import { ConsentTypeQuery } from './consentState';

import { registerRecoilRefresher } from '@/app/cdRecoilRefresher';
import { ApiSearchResult, mainApi } from '@/react/api';
import { Resources } from '@/react/shared/services/ResourceService';
import { Segment } from '@/react/people/types/segment.type';
import { PeopleSearch } from '@/react/people/types/people';

export const PeopleListByTypeQuery = selectorFamily<Segment[], string>({
  key: 'PeopleListByTypeQuery',
  get:
    (type: string) =>
    async ({ getCallback }) => {
      const res = await mainApi.get<Segment[]>('/people/segments');

      registerRecoilRefresher(
        PeopleListByTypeQuery(type),
        getCallback(
          ({ refresh }) =>
            () =>
              refresh(PeopleListByTypeQuery(type))
        )
      );
      let data = res.data.filter((item) => item.type === type);
      if (type === 'filter') {
        data = data.filter((item) => item.filteringType !== 'organization');
      }
      return data;
    },
});

export const usePeopleListByType = (type: string) =>
  useQuery(listQueries.byType(type));

export const PeopleListByTypeAndSearchQuery = selectorFamily<
  ApiSearchResult<NewsletterandFilterDataInterface>,
  PeopleListDataQueryInput
>({
  key: 'PeopleListByTypeAndSearchQuery',
  get:
    ({ type, searchParameter }: PeopleListDataQueryInput) =>
    ({ get }) => {
      const resource = get(Resources);
      const searched = (keyWord) => (l) =>
        l.name.toLowerCase().includes(keyWord.toLowerCase());

      const data = get(PeopleListByTypeQuery(type))
        .map((item) => ({
          ...item,
          churches: item.setup.churchIds.map(
            (id) => resource.find((r) => r.id === id).name + ' '
          ),
          churchIds: item.setup.churchIds.map(
            (id) => resource.find((r) => r.id === id).id
          ),
        }))
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
        .filter(searched(searchParameter));
      return { items: data, total: data.length };
    },
});

export const usePeopleLists = (params: CdClientSideTableApiSearchParam) =>
  useQuery(listQueries.byType(params.extraData.type));

export const PeopleFiltersWhereUserCanAddToList = selector<
  NewsletterandFilterDataInterface[]
>({
  key: 'PeopleFiltersWhereUserCanAddToList',
  get: ({ get }) => {
    const { items } = get(
      PeopleListByTypeAndSearchQuery({ type: 'filter', searchParameter: '' })
    );
    return items.filter((e) => e.canAddPeopleToList);
  },
});

export const HasContactConsented = selectorFamily<
  boolean,
  FilterForHasContactConsented
>({
  key: 'HasContactConsented',
  get:
    ({ contactId }: FilterForHasContactConsented) =>
    async ({ getCallback }) => {
      // Angular JS refresh code
      registerRecoilRefresher(
        HasContactConsented({ contactId }),
        getCallback(
          ({ refresh }) =>
            () =>
              refresh(HasContactConsented({ contactId }))
        )
      );

      const { data }: any = await mainApi.get(`people/people/${contactId}`);
      const defaultConsent = data.consents.find(
        (consent) => consent.consentType.defaultConsent
      );
      return !defaultConsent.deletedAt && defaultConsent.status === 'confirmed';
    },
});

export const PeopleSearchTotal = selectorFamily<
  { total: number; totalMatch: number },
  PeopleSearchTotalInput
>({
  key: 'PeopleSearchTotal',
  get:
    ({ filter }: PeopleSearchTotalInput) =>
    async () => {
      if (!filter) return;
      const response = await mainApi.post<PeopleSearch>(
        'people/people/search',
        {
          orderDirection: 'ASC',
          orderBy: 'firstName',
          limit: 1,
          filter,
        }
      );
      if (response.ok) {
        return {
          totalMatch: response.data.totalMatching,
          total: response.data.total,
        };
      }
      throw response.originalError;
    },
});

export const DefaultConsentTypeName = selector<{
  name: string;
  id: string;
  numberOfContacts: number;
}>({
  key: 'DefaultConsentTypeName',
  get: ({ get }) => {
    const consents = get(ConsentTypeQuery);
    if (consents.items) {
      const defaultConsent = consents.items.find(
        (consent) => consent.defaultConsent === true
      );
      return {
        name: defaultConsent?.label,
        id: defaultConsent?.id,
        numberOfContacts: defaultConsent?.people?.length,
      };
    }
    throw Error;
  },
});

export interface NewsletterandFilterDataInterface extends Segment {
  churches: string[];
  churchIds: number[];
}

export type PeopleSearchTotalInput = {
  filter: any;
  churchIds?: number[];
};
type PeopleListDataQueryInput = {
  type: string;
  searchParameter: string;
};

export type FilterForHasContactConsented = {
  contactId: number;
};

export const listQueries = {
  byType: (type) => ({
    queryKey: ['usePeopleListByType', type],
    queryFn: async () => {
      const res = await mainApi.get<Segment[]>('/people/segments');

      let data = res.data.filter((item) => item.type === type);
      if (type === 'filter') {
        data = data.filter((item) => item.filteringType !== 'organization');
      }

      return {
        items: data,
        total: data.length,
      };
    },
  }),
};
