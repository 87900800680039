import { UseQueryResult } from '@tanstack/react-query';
import { useCallback, useState } from 'react';

import {
  ApiCursorSearchParams,
  ApiCursorSearchResult,
  ApiSearchParams,
  ApiSearchResult,
} from '@/react/api';

export const useCdServerSideTable = <T>(
  props: {
    extraData?: Record<string, any>;
    pageSize: number;
  } & (
    | {
        pagedQuery: (
          params: ApiSearchParams
        ) => UseQueryResult<ApiSearchResult<T>>;
      }
    | {
        cursorQuery: (
          params: ApiCursorSearchParams
        ) => UseQueryResult<ApiCursorSearchResult<T>>;
      }
  )
) => {
  const pageState = useState<number>(1);
  const [pageHistoryState, setPageHistoryState] = useState([]);

  const cursor =
    pageHistoryState?.length === 0 || pageHistoryState === undefined
      ? undefined
      : pageHistoryState[pageHistoryState?.length - 1];

  const queryResult =
    'cursorQuery' in props && props.cursorQuery
      ? props.cursorQuery({
          cursor,
          extraData: props.extraData,
          limit: props.pageSize,
        })
      : 'pagedQuery' in props &&
        props.pagedQuery({
          pageNumber: pageState[0],
          limit: props.pageSize,
          extraData: props.extraData,
        });

  // @ts-ignore
  const cursorValue = queryResult.data?.cursor;

  const handleNext = useCallback(() => {
    setPageHistoryState([...pageHistoryState, cursorValue]);
  }, [pageHistoryState, cursorValue]);

  const handlePrev = useCallback(() => {
    const newHistoryState = [...pageHistoryState];

    // pop the last value from the pageHistory array to get the previous cursor value
    newHistoryState?.length > 0 && newHistoryState.pop();
    setPageHistoryState([...newHistoryState]);
  }, [pageHistoryState, setPageHistoryState]);

  const handleTableChange = useCallback(
    (type) => {
      if (type === 'next') {
        handleNext();
      }

      if (type === 'prev') {
        handlePrev();
      }
    },
    [handleNext, handlePrev]
  );

  return {
    data: queryResult.data,
    isLoading: queryResult.isLoading || queryResult.isPlaceholderData,
    page: pageState,
    handleTableChange,
  };
};
