/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { GetLoginGenerateDownloadTokenResponseBody } from '../models/GetLoginGenerateDownloadTokenResponseBody';
import type { GetLoginTokenExternalClientResponseBody } from '../models/GetLoginTokenExternalClientResponseBody';
import type { GetLoginTokenResponseBody } from '../models/GetLoginTokenResponseBody';
import type { PostLoginForgotRequestBody } from '../models/PostLoginForgotRequestBody';
import type { PostLoginResponseBody } from '../models/PostLoginResponseBody';
import type { PostLoginSsoRequestBody } from '../models/PostLoginSsoRequestBody';
import type { PostLoginSsoResponseBody } from '../models/PostLoginSsoResponseBody';
import type { PostLoginTokenRequestBody } from '../models/PostLoginTokenRequestBody';
import type { PostLoginTokenResponseBody } from '../models/PostLoginTokenResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AuthenticationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * PostLogin
     * Login
     * @returns PostLoginResponseBody
     * @throws ApiError
     */
    public postLogin({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<PostLoginResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * PostLoginForgot
     * Forgot password
     * @returns EmptyObject
     * @throws ApiError
     */
    public postLoginForgot({
        requestBody,
    }: {
        requestBody?: PostLoginForgotRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/login/forgot',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetLoginGenerateDownloadToken
     * short lived token for file downloads
     * @returns GetLoginGenerateDownloadTokenResponseBody
     * @throws ApiError
     */
    public getLoginGenerateDownloadToken({
        identifier,
        entityId,
    }: {
        identifier: string,
        entityId?: string,
    }): CancelablePromise<GetLoginGenerateDownloadTokenResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/login/generate-download-token',
            query: {
                'identifier': identifier,
                'entityId': entityId,
            },
        });
    }
    /**
     * GetLoginResetToken
     * Login reset
     * @returns EmptyObject
     * @throws ApiError
     */
    public getLoginReset({
        token,
    }: {
        /**
         * The reset token received in an email
         */
        token: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/login/reset/{token}',
            query: {
                'token': token,
            },
        });
    }
    /**
     * GetLoginSso
     * Initiate Login to SSO provider
     * @returns EmptyObject
     * @throws ApiError
     */
    public getLoginSso({
        provider,
        env = 'dev',
        flow = 'implicit',
    }: {
        provider: 'mainz' | 'ekkw' | 'ekir' | 'fulda',
        env?: 'dev' | 'staging1' | 'staging2' | 'prod',
        flow?: 'implicit' | 'code',
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/login/sso',
            query: {
                'provider': provider,
                'env': env,
                'flow': flow,
            },
        });
    }
    /**
     * PostLoginSso
     * Validate sso token and generate access token
     * @returns PostLoginSsoResponseBody
     * @throws ApiError
     */
    public postLoginSso({
        requestBody,
    }: {
        requestBody?: PostLoginSsoRequestBody,
    }): CancelablePromise<PostLoginSsoResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/login/sso',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetLoginToken
     * Login Token, Retrieve
     * @returns GetLoginTokenResponseBody
     * @throws ApiError
     */
    public getLoginToken(): CancelablePromise<GetLoginTokenResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/login/token',
        });
    }
    /**
     * PostLoginToken
     * Login Token, Auth.
     * @returns PostLoginTokenResponseBody
     * @throws ApiError
     */
    public postLoginToken({
        requestBody,
    }: {
        requestBody?: PostLoginTokenRequestBody,
    }): CancelablePromise<PostLoginTokenResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/login/token',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetLoginTokenExternalClient
     * Fetch transfer token from an external system
     * @returns GetLoginTokenExternalClientResponseBody
     * @throws ApiError
     */
    public getLoginTokenExternal({
        userEmail,
    }: {
        userEmail: string,
    }): CancelablePromise<GetLoginTokenExternalClientResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/login/token/external/{client}',
            query: {
                'userEmail': userEmail,
            },
        });
    }
}
