/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { GetUsersTimeregistrationsReportResponseBody } from '../models/GetUsersTimeregistrationsReportResponseBody';
import type { GetUsersTimeregistrationsReportUsersResponseBody } from '../models/GetUsersTimeregistrationsReportUsersResponseBody';
import type { GetUsersTimeregistrationsResponseBody } from '../models/GetUsersTimeregistrationsResponseBody';
import type { GetUsersTimeregistrationsSettingsResponseBody } from '../models/GetUsersTimeregistrationsSettingsResponseBody';
import type { GetUsersTimeregistrationsStandardhoursResponseBody } from '../models/GetUsersTimeregistrationsStandardhoursResponseBody';
import type { PostUsersTimeregistrationsResponseBody } from '../models/PostUsersTimeregistrationsResponseBody';
import type { PostUserTimeRegistrationsRequestBody } from '../models/PostUserTimeRegistrationsRequestBody';
import type { PutUsersTimeregistrationsSettingsResponseBody } from '../models/PutUsersTimeregistrationsSettingsResponseBody';
import type { PutUserTimeRegistrationsSettingsRequestBody } from '../models/PutUserTimeRegistrationsSettingsRequestBody';
import type { TimeRegistrationStandardHours } from '../models/TimeRegistrationStandardHours';
import type { TimeRegistrationStandardHours2 } from '../models/TimeRegistrationStandardHours2';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TimeRegistrationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Retrieves a the list of users that the bearer user can generate reports for.
     * @returns GetUsersTimeregistrationsReportUsersResponseBody
     * @throws ApiError
     */
    public getUsersTimeRegistrationsReportUsers(): CancelablePromise<GetUsersTimeregistrationsReportUsersResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/time-registrations/report/users',
        });
    }
    /**
     * Retrieves the time registrations for the given user in the given month.
     * @returns GetUsersTimeregistrationsResponseBody
     * @throws ApiError
     */
    public getUsersTimeRegistrations({
        month,
        year,
        userId,
    }: {
        month: number,
        year: number,
        userId: number,
    }): CancelablePromise<GetUsersTimeregistrationsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{userId}/time-registrations',
            path: {
                'userId': userId,
            },
            query: {
                'month': month,
                'year': year,
            },
        });
    }
    /**
     * Depending on whether the date already contains an entry, this will either update or create a new entry.
     * @returns PostUsersTimeregistrationsResponseBody
     * @throws ApiError
     */
    public postUsersTimeRegistrations({
        userId,
        requestBody,
    }: {
        userId: number,
        requestBody?: PostUserTimeRegistrationsRequestBody,
    }): CancelablePromise<PostUsersTimeregistrationsResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/{userId}/time-registrations',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retrieves the time registrations for the given user, in a format that is optimized for mobile.
     * @returns EmptyObject
     * @throws ApiError
     */
    public getUsersTimeRegistrationsMobile({
        month,
        year,
        userId,
    }: {
        month: number,
        year: number,
        userId: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{userId}/time-registrations/mobile',
            path: {
                'userId': userId,
            },
            query: {
                'month': month,
                'year': year,
            },
        });
    }
    /**
     * Initiates a report generation job for the given user, which they will receive by email.
     * @returns GetUsersTimeregistrationsReportResponseBody
     * @throws ApiError
     */
    public getUsersTimeRegistrationsReport({
        startWeekNumber,
        startYear,
        endWeekNumber,
        endYear,
        userId,
    }: {
        startWeekNumber: number,
        startYear: number,
        endWeekNumber: number,
        endYear: number,
        userId: number,
    }): CancelablePromise<GetUsersTimeregistrationsReportResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{userId}/time-registrations/report',
            path: {
                'userId': userId,
            },
            query: {
                'startWeekNumber': startWeekNumber,
                'startYear': startYear,
                'endWeekNumber': endWeekNumber,
                'endYear': endYear,
            },
        });
    }
    /**
     * Retrieve the time registration settings for the given user.
     * @returns GetUsersTimeregistrationsSettingsResponseBody
     * @throws ApiError
     */
    public getUsersTimeRegistrationsSettings({
        userId,
    }: {
        userId: number,
    }): CancelablePromise<GetUsersTimeregistrationsSettingsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{userId}/time-registrations/settings',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * Updates the time registration settings for the given user.
     * @returns PutUsersTimeregistrationsSettingsResponseBody
     * @throws ApiError
     */
    public putUsersTimeRegistrationsSettings({
        userId,
        requestBody,
    }: {
        userId: number,
        requestBody?: PutUserTimeRegistrationsSettingsRequestBody,
    }): CancelablePromise<PutUsersTimeregistrationsSettingsResponseBody> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/users/{userId}/time-registrations/settings',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retrieves the standard hours for the given user.
     * @returns GetUsersTimeregistrationsStandardhoursResponseBody
     * @throws ApiError
     */
    public getUsersTimeRegistrationsStandardHours({
        userId,
    }: {
        userId: number,
    }): CancelablePromise<GetUsersTimeregistrationsStandardhoursResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{userId}/time-registrations/standard-hours',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * Updates the standard hours for the given user. These will be used for future time registrations.
     * @returns TimeRegistrationStandardHours2
     * @throws ApiError
     */
    public putUsersTimeRegistrationsStandardHours({
        userId,
        requestBody,
    }: {
        userId: number,
        requestBody?: TimeRegistrationStandardHours,
    }): CancelablePromise<TimeRegistrationStandardHours2> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/users/{userId}/time-registrations/standard-hours',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
