/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostIntentionStolesRequestBody = {
    stoleText: string;
    comment?: string | null;
    church: {
        id: number;
    };
    fee: {
        id: string;
    };
    resource?: {
        id: number;
    } | null;
    person?: {
        id: number;
    } | null;
    founder?: string | null;
    paymentMethod?: PostIntentionStolesRequestBody.paymentMethod;
    paid?: boolean | null;
    paidAt?: string | null;
    calendarId: number;
};
export namespace PostIntentionStolesRequestBody {
    export enum paymentMethod {
        CASH = 'cash',
        BANK = 'bank',
    }
}

