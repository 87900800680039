/* eslint-disable react/display-name */
import React, { FunctionComponent } from 'react';

import AssignUserRolesModal from '../components/assign-user-roles/AssignUserRolesModal';

const assignUserRolesModalWrap: FunctionComponent<null> = () => (
  <AssignUserRolesModal />
);

export default assignUserRolesModalWrap;
