import { Skeleton } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { I18nService } from '../../services/I18nService';
import { selectIsUserFiltersLoaded } from '../store/filters/Selectors';
import { CdCalendarFilterCollapse } from '../containers/calendar-filter/CdCalendarFilterCollapse';

import CalendarsSelect from './CalendarsSelect';
import { FilterSectionHeader } from './FilterSectionHeader';

import { useSelectableCalendars } from '@/react/calendar/hooks/calendar/useSelectableCalendars';

const UsersAndRoomsCalendars = () => {
  const isUserFiltersLoaded = useSelector(selectIsUserFiltersLoaded);

  const { calendarsToRender, areAllSelected, isDuringSearch, toggleSelectAll } =
    useSelectableCalendars();

  return (
    <CdCalendarFilterCollapse
      defaultActiveKey={['USER_ROOM_CALENDARS']}
      $transparentBackground
      items={[
        {
          key: 'USER_ROOM_CALENDARS',
          label: isUserFiltersLoaded ? (
            <FilterSectionHeader
              name={I18nService.getString('User & room calendars')}
              isAllSelected={areAllSelected}
              onClick={toggleSelectAll}
              isDisabled={false}
            />
          ) : (
            <div style={{ padding: '0 16px' }}>
              <Skeleton
                title={false}
                paragraph={{ rows: 4, width: '100%' }}
                loading
                active
              />
            </div>
          ),
          children: isUserFiltersLoaded ? (
            <div>
              <CalendarsSelect
                calendarsToRender={calendarsToRender}
                isDuringSearch={isDuringSearch}
              />
            </div>
          ) : (
            <div style={{ padding: '0 16px' }}>
              <Skeleton
                title={false}
                paragraph={{ rows: 4, width: '100%' }}
                loading
                active
              />
            </div>
          ),
        },
      ]}
    ></CdCalendarFilterCollapse>
  );
};

export default UsersAndRoomsCalendars;
