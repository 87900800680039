/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GetTerminalResponseBody2 = {
    id: string;
    organizationId: number | null;
    name: string;
    location: string | null;
    version: string | null;
    lastSeenAt: string;
    macAddress?: string | null;
    kioskId?: string | null;
    status: GetTerminalResponseBody2.status;
    projects: Array<{
        id: string;
        name: string;
    }>;
    batteryLevel?: number | null;
    isCharging?: boolean | null;
    geoLocation?: {
        lng?: number | null;
        lat?: number | null;
    } | null;
    canEdit: boolean;
    canDelete: boolean;
};
export namespace GetTerminalResponseBody2 {
    export enum status {
        ONLINE = 'online',
        OFFLINE = 'offline',
    }
}

