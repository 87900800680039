import { Button, Image, Space, Typography, Row, Col } from 'antd';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { I18nService } from '@/react/services/I18nService';
import { LanguageState } from '@/react/user/store/user-session';
import { getCRMContactDetails } from '@/react/shared/services/CRMContactDetailsService';
import { ChurchSettingsState } from '@/react/organization/store/church';
import { CdSalesButton } from '@/react/shared/components/cd-buttons/CdSalesButton';

const { Title, Paragraph } = Typography;
export const StudioLandingPage = () => {
  const language = useRecoilValue(LanguageState);
  const churchSettings = useRecoilValue(ChurchSettingsState);
  const accountManager = churchSettings.accountManager;
  const { bookingPhone } = getCRMContactDetails(language);
  let bookingImage;
  switch (language) {
    case 'da':
      bookingImage =
        'https://uploads-ssl.webflow.com/5c4f6a50824440c0a3f37aa9/614b312144b74d81ab18e72e_ChurchDesk%20Studio%20Templates.png';
      break;
    case 'de':
      bookingImage =
        'https://uploads-ssl.webflow.com/5c4f6a50824440c0a3f37aa9/614dd42b8fc4ce31ad224863_ChurchDesk-Studio-Templates-(DE).png';
      break;

    default:
      bookingImage =
        'https://uploads-ssl.webflow.com/5c4f6a50824440c0a3f37aa9/614dd24913b107170fcf5e2e_ChurchDesk-Studio-Templates-(EN).png';
      break;
  }
  return (
    <Row>
      <Col span={12}>
        <Title level={3}>
          {I18nService.getString('Design your own newsletters and invitations')}
        </Title>
        <Paragraph>
          {I18nService.getString(
            'ChurchDesk Studio gives you freedom to design your own newsletters, invitations and flyers. It comes with great templates and our drag and drop editor is simple to use'
          )}
        </Paragraph>
        <Title level={3}>
          {I18nService.getString('Use one of our templates or create your own')}
        </Title>
        <Paragraph>
          {I18nService.getString(
            'We have templates for everything ranging from newsletters to personal invitations to your concerts. You can also design and save your own templates.'
          )}
        </Paragraph>
        <Title level={3}>
          {I18nService.getString('Make a poster or flyer for your event')}
        </Title>
        <Paragraph>
          {I18nService.getString(
            'With Studio you will be able to create beautiful posters and flyers directly from the events in your calendar. It will never be easier to make flyers or posters. Click, edit and PDF it for easy printing.'
          )}
        </Paragraph>
        <Space style={{ marginTop: '20px' }}>
          {accountManager ? (
            <CdSalesButton
              href={accountManager?.bookingLink}
              target="_blank"
              rel="noreferrer"
            >
              {I18nService.getString('Book a demonstration')}
            </CdSalesButton>
          ) : null}
          <Button
            type="link"
            onClick={() => window.open(`tel:${bookingPhone}`)}
          >
            {I18nService.getString('or call us at {{phone}}', {
              phone: bookingPhone,
            })}
          </Button>
        </Space>
      </Col>
      <Col span={12} style={{ textAlign: 'center' }}>
        <Image height={400} preview={false} src={bookingImage} />
      </Col>
    </Row>
  );
};
