import { Button } from 'antd';
import React, { Suspense } from 'react';
import { useRecoilValue } from 'recoil';

import { UserHasPrivilege } from '../../user/store/permissions';

import { showBookingPageSettingsModal } from './components/modal/BookingPageSettingsModal';

import { I18nService } from '@/react/services/I18nService';
import { BookingPagesTable } from '@/react/settings/booking-pages/components/BookingPagesTable';
import { CdPage } from '@/react/shared/components/cd-page/CdPage';

export const BookingPagesPage = () => (
  <Suspense>
    <BookingPagesPageInner />
  </Suspense>
);

const BookingPagesPageInner = () => {
  const canCreate = useRecoilValue(
    UserHasPrivilege('taxonomy.availability.create')
  );

  return (
    <CdPage
      pageHeaderProps={{
        title: I18nService.getString('Booking Pages'),
        subTitle: I18nService.getString('Manage your booking pages'),
        extra: canCreate && (
          <Button
            type="primary"
            onClick={() => showBookingPageSettingsModal({})}
          >
            {I18nService.getString('New booking page')}
          </Button>
        ),
      }}
    >
      <BookingPagesTable />
    </CdPage>
  );
};
