import { ManageSubscription } from '../store/ManageSubscriptionState';

import { mainApi } from '@/react/api';
import NotificationService from '@/react/services/NotificationService';
import { I18nService } from '@/react/services/I18nService';

const useManageSubscription = () => {
  const confirmSubscriptions = async (body: object, token) => {
    const response = await mainApi.post<ManageSubscription[]>(
      `/people/people/manage-subscriptions?token=${token}`,
      body
    );
    if (!response.ok) {
      NotificationService.notifyError((response as any)?.data?.message);
      throw response;
    } else {
      NotificationService.notifySuccess(
        I18nService.getString('Successfully saved!')
      );
    }
  };

  return {
    confirmSubscriptions,
  };
};

export default useManageSubscription;
