/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeletePortalCategoriesMappingRequestBody } from '../models/DeletePortalCategoriesMappingRequestBody';
import type { EmptyObject } from '../models/EmptyObject';
import type { GetPortalDetailsWithOrganizationsResponseBody } from '../models/GetPortalDetailsWithOrganizationsResponseBody';
import type { GetPortalOrganizationsWithTaxonomiesResponseBody } from '../models/GetPortalOrganizationsWithTaxonomiesResponseBody';
import type { GetPortalWithOrganizationsAndCategoriesResponseBody } from '../models/GetPortalWithOrganizationsAndCategoriesResponseBody';
import type { PostPortalCategoriesMappingRequestBody } from '../models/PostPortalCategoriesMappingRequestBody';
import type { PostPortalCategoriesRequestBody } from '../models/PostPortalCategoriesRequestBody';
import type { PostPortalOrganizationsCategoryMappingRequestBody } from '../models/PostPortalOrganizationsCategoryMappingRequestBody';
import type { PostPortalOrganizationsHostRequestBody } from '../models/PostPortalOrganizationsHostRequestBody';
import type { PostPortalOrganizationsRequestBody } from '../models/PostPortalOrganizationsRequestBody';
import type { PostPortalPortalsRequestBody } from '../models/PostPortalPortalsRequestBody';
import type { PostPortalReportsExportRequestBody } from '../models/PostPortalReportsExportRequestBody';
import type { PostPortalReportsRequestBody } from '../models/PostPortalReportsRequestBody';
import type { PostPortalReportsSaveRequestBody } from '../models/PostPortalReportsSaveRequestBody';
import type { PostPortalWidgetsRequestBody } from '../models/PostPortalWidgetsRequestBody';
import type { PutPortalCategoriesRequestBody } from '../models/PutPortalCategoriesRequestBody';
import type { PutPortalWidgetsRequestBody } from '../models/PutPortalWidgetsRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PortalService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * CreatePortalCategory
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPortalCategories({
        requestBody,
    }: {
        requestBody?: PostPortalCategoriesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/portal/categories',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetFullListOfPortalCategories
     * Index
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPortalCategoriesAll({
        portalId,
    }: {
        portalId: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portal/categories/all/{portalId}',
            path: {
                'portalId': portalId,
            },
        });
    }
    /**
     * AddTaxonomyMappingsToPortalCategory
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPortalCategoriesMapping({
        requestBody,
    }: {
        requestBody?: PostPortalCategoriesMappingRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/portal/categories/mapping',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetTaxonomyMappingToPortalCategory
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPortalCategoriesMapping({
        categoryId,
    }: {
        categoryId?: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portal/categories/mapping/{categoryId}',
            path: {
                'categoryId': categoryId,
            },
        });
    }
    /**
     * RemoveTaxonomyMappingsFromPortalCategory
     * @returns EmptyObject
     * @throws ApiError
     */
    public deletePortalCategoriesMapping({
        portalCategoryId,
        requestBody,
    }: {
        portalCategoryId: string,
        requestBody?: DeletePortalCategoriesMappingRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/portal/categories/mapping/{portalCategoryId}',
            path: {
                'portalCategoryId': portalCategoryId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeletePortalCategory
     * @returns EmptyObject
     * @throws ApiError
     */
    public deletePortalCategories({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/portal/categories/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetPortalCategory
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPortalCategories({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portal/categories/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * UpdatePortalCategory
     * @returns EmptyObject
     * @throws ApiError
     */
    public putPortalCategories({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PutPortalCategoriesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/portal/categories/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetPortalEvents
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPortalEventsPublic({
        portalId,
        startDate,
        endDate,
        portalCategoryIds,
        centerLat,
        centerLng,
        radius,
        orderCriteria = 'beginning',
        limit,
        offset,
    }: {
        portalId: string,
        startDate: string,
        endDate: string,
        portalCategoryIds?: Array<string>,
        centerLat?: number,
        centerLng?: number,
        radius?: number,
        orderCriteria?: 'beginning' | 'proximity',
        limit?: number,
        offset?: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portal/events/public',
            query: {
                'portalId': portalId,
                'startDate': startDate,
                'endDate': endDate,
                'portalCategoryIds': portalCategoryIds,
                'centerLat': centerLat,
                'centerLng': centerLng,
                'radius': radius,
                'orderCriteria': orderCriteria,
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * GetPortalEvent
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPortalEventsPublic1({
        portalId,
        id,
    }: {
        portalId: string,
        id: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portal/events/public/{id}',
            path: {
                'id': id,
            },
            query: {
                'portalId': portalId,
            },
        });
    }
    /**
     * AddOrganizationsToPortal
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPortalOrganizations({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PostPortalOrganizationsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/portal/organizations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * RemoveOrganizationsFromPortal
     * @returns EmptyObject
     * @throws ApiError
     */
    public deletePortalOrganizations({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PostPortalOrganizationsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/portal/organizations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * PortalCategoryMappingForOrganization
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPortalOrganizationsCategoryMapping({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PostPortalOrganizationsCategoryMappingRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/portal/organizations/{id}/category/mapping',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * AssignPortalHost
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPortalOrganizationsHost({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PostPortalOrganizationsHostRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/portal/organizations/{id}/host',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * RemovePortalHost
     * @returns EmptyObject
     * @throws ApiError
     */
    public deletePortalOrganizationsHost({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PostPortalOrganizationsHostRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/portal/organizations/{id}/host',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * CreatePortal
     * Create portal
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPortalPortals({
        requestBody,
    }: {
        requestBody?: PostPortalPortalsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/portal/portals',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetFullListOfPortals
     * Index
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPortalPortals(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portal/portals',
        });
    }
    /**
     * GetPortalAltar
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPortalPortalsAltar({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portal/portals/altar/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetPortal
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPortalPortalsOrganization(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portal/portals/organization',
        });
    }
    /**
     * GetPortalAltar
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPortalPortalsV2Altar({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portal/portals/v2/altar/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetPortalDetailsWithOrganizations
     * Get Portal Details with Organizations
     * @returns GetPortalDetailsWithOrganizationsResponseBody
     * @throws ApiError
     */
    public getPortalPortalsV2({
        id,
    }: {
        id: string,
    }): CancelablePromise<GetPortalDetailsWithOrganizationsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portal/portals/v2/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetPortalWithOrganizationsAndCategories
     * Get Portal with Organizations, Categories, and Taxonomies
     * @returns GetPortalWithOrganizationsAndCategoriesResponseBody
     * @throws ApiError
     */
    public getPortalPortalsV2OrganizationsAndCategories({
        id,
    }: {
        id: string,
    }): CancelablePromise<GetPortalWithOrganizationsAndCategoriesResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portal/portals/v2/{id}/organizations-and-categories',
            path: {
                'id': id,
            },
        });
    }
    /**
     * DeletePortal
     * Delete portal
     * @returns EmptyObject
     * @throws ApiError
     */
    public deletePortalPortals({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/portal/portals/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetPortal
     * Get portal
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPortalPortals1({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portal/portals/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * UpdatePortal
     * Update portal
     * @returns EmptyObject
     * @throws ApiError
     */
    public putPortalPortals({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PostPortalPortalsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/portal/portals/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetPortalOrganizationsWithTaxonomies
     * Get Portal Organizations with Taxonomies
     * @returns GetPortalOrganizationsWithTaxonomiesResponseBody
     * @throws ApiError
     */
    public getPortalPortalsOrganizations({
        limit,
        page,
        id,
    }: {
        limit: number,
        page: number,
        id: string,
    }): CancelablePromise<GetPortalOrganizationsWithTaxonomiesResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portal/portals/{id}/organizations',
            path: {
                'id': id,
            },
            query: {
                'limit': limit,
                'page': page,
            },
        });
    }
    /**
     * GetPortalWithOrganizationsAndCategories
     * Get Portal with Organizations and Categories
     * @returns GetPortalWithOrganizationsAndCategoriesResponseBody
     * @throws ApiError
     */
    public getPortalPortalsOrganizationsAndCategories({
        id,
    }: {
        id: string,
    }): CancelablePromise<GetPortalWithOrganizationsAndCategoriesResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portal/portals/{id}/organizations-and-categories',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetPortalPublic
     * GET portal for public view
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPortalPortalsPublic({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portal/portals/{id}/public',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Portal
     * Get Portal reports
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPortalReports({
        requestBody,
    }: {
        requestBody?: PostPortalReportsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/portal/reports',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Portal
     * GET saved Portal reports
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPortalReports(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portal/reports',
        });
    }
    /**
     * Portal
     * Save export job
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPortalReportsExport({
        requestBody,
    }: {
        requestBody?: PostPortalReportsExportRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/portal/reports/export',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Portal
     * CREATE Portal report
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPortalReportsSave({
        requestBody,
    }: {
        requestBody?: PostPortalReportsSaveRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/portal/reports/save',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Portal
     * Update Portal report
     * @returns EmptyObject
     * @throws ApiError
     */
    public putPortalReports({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PostPortalReportsSaveRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/portal/reports/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Portal
     * Update Portal report
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPortalReports1({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portal/reports/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Portal
     * Update Portal report
     * @returns EmptyObject
     * @throws ApiError
     */
    public deletePortalReports({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/portal/reports/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Portal
     * Download export
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPortalReportsDownload({
        exportToken,
        id,
    }: {
        exportToken: string,
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portal/reports/{id}/download',
            path: {
                'id': id,
            },
            query: {
                'exportToken': exportToken,
            },
        });
    }
    /**
     * CreatePortalWidget
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPortalWidgets({
        requestBody,
    }: {
        requestBody?: PostPortalWidgetsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/portal/widgets',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetFullListOfPortalWidgets
     * Index
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPortalWidgetsAll({
        portalId,
    }: {
        portalId: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portal/widgets/all/{portalId}',
            path: {
                'portalId': portalId,
            },
        });
    }
    /**
     * GetPortalWidgetPublic
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPortalWidgetsPublic({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portal/widgets/public/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * DeletePortalWidget
     * @returns EmptyObject
     * @throws ApiError
     */
    public deletePortalWidgets({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/portal/widgets/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetPortalWidget
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPortalWidgets({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portal/widgets/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * UpdatePortalWidget
     * @returns EmptyObject
     * @throws ApiError
     */
    public putPortalWidgets({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PutPortalWidgetsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/portal/widgets/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
