import { useCallback } from 'react';
import React from 'react';
import { Space } from 'antd';

import { showBookingPageSettingsModal } from '../components/modal/BookingPageSettingsModal';
import { CdVerticalSpace } from '../../../shared/components/cd-vertical-space/CdVerticalSpace';
import NotificationService from '../../../services/NotificationService';
import cdApp from '../../../config';
import { Taxonomy } from '../../../shared/models/taxonomy';

import {
  useDeleteTaxonomy,
  useTaxonomies,
} from '@/react/calendar/hooks/use-taxonomies';
import { I18nService } from '@/react/services/I18nService';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import { CategoryType } from '@/react/shared/models/category';

export const useBookingPagesActions = () => {
  const { taxonomies, isLoading } = useTaxonomies(CategoryType.availability);
  const { deleteTaxonomy } = useDeleteTaxonomy();
  const deleteBookingPage = useCallback(
    async (taxonomy: Taxonomy) => {
      await showConfirmModal({
        title: I18nService.getString('Delete booking page'),
        message: (
          <CdVerticalSpace>
            {I18nService.getString(
              'Are you sure you want to delete this booking page?'
            )}
            <CdVerticalSpace size={0}>
              <Space>
                {I18nService.getString('Type') + ':'}
                {taxonomy.name}
              </Space>
              <Space>
                {I18nService.getString('Public name') + ':'}
                {taxonomy.config?.publicName}
              </Space>
            </CdVerticalSpace>
          </CdVerticalSpace>
        ),
        okText: I18nService.getString('Delete'),
        showIcon: false,
        okButtonProps: {
          danger: true,
        },
        onOk: async () => deleteTaxonomy(taxonomy.id),
      });
    },
    [deleteTaxonomy]
  );

  const editBookingPage = useCallback(
    async (taxonomy: Taxonomy) =>
      showBookingPageSettingsModal({ taxonomyId: taxonomy.id }),
    []
  );

  const copyLink = async (taxonomy: Taxonomy) => {
    try {
      await navigator.clipboard.writeText(
        `${cdApp.references.bookingPage}${cdApp.organization.publicId}/${taxonomy.alias}`
      );
      NotificationService.notifySuccess(
        I18nService.getString('Link copied to clipboard')
      );
    } catch (_) {
      NotificationService.notifyError(
        I18nService.getString('Failed to copy link to clipboard')
      );
    }
  };

  return {
    copyLink,
    taxonomies,
    deleteBookingPage,
    editBookingPage,
    isLoading,
  };
};
