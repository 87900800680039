import { Col, Space, Typography } from 'antd';
import React, { Suspense } from 'react';
import { useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { I18nService } from '../../../services/I18nService';
import {
  CdSelect,
  CdSelectProps,
} from '../../../shared/components/cd-select/CdSelect';
import { PortalCategoryType } from '../../../portal/state/portal';
import { taxonomiesQueries } from '../../../shared/queries/taxonomies';

import { COLOR_INDEX } from '@/app/ResourceColors';
import { CdCircleFilled } from '@/react/shared/components/Icons';
import { CategoryType } from '@/react/shared/models/category';

interface CdEventCategorySelectProps
  extends Pick<
    CdSelectProps,
    | 'value'
    | 'onChange'
    | 'mode'
    | 'disabled'
    | 'placeholder'
    | 'style'
    | 'maxTagCount'
    | 'autoFocus'
    | 'selectType'
  > {
  type?: CategoryType | PortalCategoryType;
  showSecondaryText?: boolean;
  selectType?: 'default' | 'filter';
  usage?: string;
}

export const CdEventCategorySelect = ({
  showSecondaryText,
  usage,
  ...props
}: CdEventCategorySelectProps) => (
  <Suspense fallback={<CdSelect title={title} {...props} disabled={true} />}>
    {/* Passing showSecondaryText to fallback CdSelect creates console error */}
    <CdEventCategorySelectInner
      showSecondaryText={showSecondaryText}
      usage={usage}
      {...props}
    />
  </Suspense>
);

export const CdEventCategorySelectInner = ({
  type,
  showSecondaryText = true,
  usage,
  ...rest
}: CdEventCategorySelectProps) => {
  const categoriesQuery = useSuspenseQuery(
    taxonomiesQueries.getAllByType(type || CategoryType.event, usage)
  );
  const selectCategoriesData = useMemo(
    () =>
      (categoriesQuery.data || []).map(
        ({ id, name, color, registerAttendance }) => ({
          value: id,
          searchText: name,
          label: (
            <Space>
              {type !== CategoryType.availability && (
                <CdCircleFilled
                  style={{
                    color: COLOR_INDEX[color],
                  }}
                />
              )}
              <Typography.Text>{name}</Typography.Text>
              {showSecondaryText && (
                <Col flex="auto" style={{ textAlign: 'right' }}>
                  <Typography.Text italic type="secondary">
                    {registerAttendance
                      ? I18nService.getString('Tracking attendance')
                      : undefined}
                  </Typography.Text>
                </Col>
              )}
            </Space>
          ),
        })
      ),

    [categoriesQuery.data, type, showSecondaryText]
  );

  return (
    <CdSelect
      {...rest}
      disabled={categoriesQuery.isLoading || rest.disabled}
      loading={categoriesQuery.isLoading}
      title={title}
      allowClear={rest.mode === 'multiple'}
      options={selectCategoriesData}
    />
  );
};

const title = (count: number) =>
  count === 0
    ? I18nService.getString('All types')
    : I18nService.getPlural(count, '1 type', '{{ $count }} types', {
        $count: count,
      });
