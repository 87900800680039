/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContributionGetExcelReportResponseBody } from '../models/ContributionGetExcelReportResponseBody';
import type { EmptyObject } from '../models/EmptyObject';
import type { GetSafeguardUsersResponseBody } from '../models/GetSafeguardUsersResponseBody';
import type { GetSafeguardV2UsersResponseBody } from '../models/GetSafeguardV2UsersResponseBody';
import type { PutSafeguardUsersInfoRequestBody } from '../models/PutSafeguardUsersInfoRequestBody';
import type { SafeguardDoc } from '../models/SafeguardDoc';
import type { SafeguardGetAllAdminsResponseBody } from '../models/SafeguardGetAllAdminsResponseBody';
import type { SafeguardGetPortalOverviewResponseBody } from '../models/SafeguardGetPortalOverviewResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SafeguardService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Insert a certificate of conduct / course / document
     * @returns EmptyObject
     * @throws ApiError
     */
    public postSafeguard({
        userId,
        requestBody,
    }: {
        userId: number,
        requestBody?: SafeguardDoc,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/safeguard',
            query: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * SafeguardGetAllAdmins
     * ADMINS INDEX
     * @returns SafeguardGetAllAdminsResponseBody
     * @throws ApiError
     */
    public getSafeguardAdmins({
        portalId,
        organizationId,
    }: {
        portalId: string,
        organizationId: number,
    }): CancelablePromise<SafeguardGetAllAdminsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/safeguard/admins/{organizationId}',
            path: {
                'organizationId': organizationId,
            },
            query: {
                'portalId': portalId,
            },
        });
    }
    /**
     * SafeguardUploadFile
     * UPLOAD FILE
     * @returns EmptyObject
     * @throws ApiError
     */
    public postSafeguardFileUpload({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/safeguard/file/upload',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * SafeguardGetFile
     * RETRIEVE FILE
     * @returns EmptyObject
     * @throws ApiError
     */
    public getSafeguardFile({
        downloadToken,
        action = 'download',
    }: {
        downloadToken: string,
        action?: 'download' | 'view' | 'raw',
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/safeguard/file/{action}',
            path: {
                'action': action,
            },
            query: {
                'downloadToken': downloadToken,
            },
        });
    }
    /**
     * SafeguardGetPortalOverview
     * PORTAL OVERVIEW
     * @returns SafeguardGetPortalOverviewResponseBody
     * @throws ApiError
     */
    public getSafeguardPortal({
        portalId,
        orderDirection = 'ASC',
        limit = 10,
        pageNumber = 1,
    }: {
        portalId: string,
        orderDirection?: 'ASC' | 'DESC',
        limit?: number,
        pageNumber?: number,
    }): CancelablePromise<SafeguardGetPortalOverviewResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/safeguard/portal/{portalId}',
            path: {
                'portalId': portalId,
            },
            query: {
                'orderDirection': orderDirection,
                'limit': limit,
                'pageNumber': pageNumber,
            },
        });
    }
    /**
     * SafeguardGetExcelReport
     * GET EXCEL REPORT
     * @returns ContributionGetExcelReportResponseBody
     * @throws ApiError
     */
    public getSafeguardReport({
        downloadToken,
    }: {
        downloadToken: string,
    }): CancelablePromise<ContributionGetExcelReportResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/safeguard/report',
            query: {
                'downloadToken': downloadToken,
            },
        });
    }
    /**
     * SafeguardGetExcelReportDownloadToken
     * GET A DOWNLOAD TOKEN FOR AN EXCEL REPORT
     * @returns EmptyObject
     * @throws ApiError
     */
    public getSafeguardReportDownloadToken(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/safeguard/report/download-token',
        });
    }
    /**
     * Get the details of a single user
     * @returns GetSafeguardUsersResponseBody
     * @throws ApiError
     */
    public getSafeguardUsers({
        userId,
    }: {
        userId: number,
    }): CancelablePromise<GetSafeguardUsersResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/safeguard/users/{userId}',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * Upsert the basic information of a single user
     * @returns EmptyObject
     * @throws ApiError
     */
    public putSafeguardUsersInfo({
        userId,
        requestBody,
    }: {
        userId: number,
        requestBody?: PutSafeguardUsersInfoRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/safeguard/users/{userId}/info',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all users for the current organization
     * @returns GetSafeguardV2UsersResponseBody
     * @throws ApiError
     */
    public getSafeguardV2Users({
        search = '',
        userStatuses,
        filter = 'all',
        cursor = '',
        limit = 10,
        filters,
    }: {
        search?: string,
        userStatuses?: ((('active' | 'blocked' | 'createdWithoutLogin' | '') | Array<'active' | 'blocked' | 'createdWithoutLogin'>) | Record<string, 'active' | 'blocked' | 'createdWithoutLogin'>),
        filter?: 'all' | 'missing' | 'soonToExpire',
        cursor?: string,
        limit?: number,
        filters?: {
            userIds?: (((number | '') | Array<number>) | Record<string, number>);
            churchIds?: (((number | '') | Array<number>) | Record<string, number>);
            groupIds?: (((number | '') | Array<number>) | Record<string, number>);
            search?: string | null;
            summaryState?: 'all' | 'missing' | 'soonToExpire';
            userStatuses?: ((('active' | 'blocked' | 'createdWithoutLogin' | '') | Array<'active' | 'blocked' | 'createdWithoutLogin'>) | Record<string, 'active' | 'blocked' | 'createdWithoutLogin'>);
            userTypes?: ((('employee' | 'volunteer' | '') | Array<'employee' | 'volunteer'>) | Record<string, 'employee' | 'volunteer'>);
        },
    }): CancelablePromise<GetSafeguardV2UsersResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/safeguard/v2/users',
            query: {
                'search': search,
                'userStatuses': userStatuses,
                'filter': filter,
                'cursor': cursor,
                'limit': limit,
                'filters': filters,
            },
        });
    }
    /**
     * SafeguardDeleteCertificateOfConductOrCourseOrDocument
     * DELETE A CERTIFICATE OF CONDUCT / COURSE / DOCUMENT
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteSafeguard({
        docId,
    }: {
        docId: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/safeguard/{docId}',
            path: {
                'docId': docId,
            },
        });
    }
}
