import { Button } from 'antd';
import React, { useCallback } from 'react';

import { useRotaAndIntentionState } from '../../hooks/use-rota-and-intention-state';

import { showEditRotaDutyModal } from './EditRotaDutyModal';

import { CdDeleteIcon, CdEditIcon } from '@/react/shared/components/Icons';
import { I18nService } from '@/react/services/I18nService';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import { showConfirmInputModal } from '@/react/shared/components/cd-confirm-input-modal/CdConfirmInputModal';

export const RotaDutiesEditDropDown = ({ rota, isSeriesEvent, disabled }) => {
  const { updateRotaTableStateToRemoveTheRotaDuty } =
    useRotaAndIntentionState();

  const onEdit = () => {
    showEditRotaDutyModal({
      rota: rota,
      isSeriesEvent: undefined,
    });
  };

  const onRemove = useCallback(() => {
    updateRotaTableStateToRemoveTheRotaDuty(rota.taskId);
  }, [rota.taskId, updateRotaTableStateToRemoveTheRotaDuty]);
  const onDelete = () => {
    if (isSeriesEvent) {
      showConfirmInputModal({
        title: I18nService.getString('Remove a rota duty'),
        message: (
          <span
            style={{ marginRight: '2px' }}
            dangerouslySetInnerHTML={{
              __html: I18nService.getString(
                'Are you sure to remove {{dutyName}}?',
                {
                  dutyName: `<i>${rota.title}</i>`,
                }
              ),
            }}
          ></span>
        ),
        onOk: onRemove,
        okButtonProps: { danger: true, type: 'default' },
        okText: I18nService.getString('Remove'),
        confirmType: 'checkbox',
        confirmLabel: (
          <span
            style={{ marginRight: '2px' }}
            dangerouslySetInnerHTML={{
              __html: I18nService.getString(
                'Remove {{dutyName}} from all active events in the event series?',
                {
                  dutyName: `<i>${rota.title}</i>`,
                }
              ),
            }}
          ></span>
        ),
      });
    } else {
      showConfirmModal({
        title: I18nService.getString('Remove a rota duty'),
        message: (
          <span
            style={{ marginRight: '2px' }}
            dangerouslySetInnerHTML={{
              __html: I18nService.getString(
                'Are you sure to remove {{dutyName}}?',
                {
                  dutyName: `<i>${rota.title}</i>`,
                }
              ),
            }}
          ></span>
        ),
        onOk: onRemove,
        okButtonProps: { danger: true, type: 'default' },
        okText: I18nService.getString('Remove'),
      });
    }
  };
  return (
    <div
      style={{
        border: '1px solid #E5E4E2',
        padding: '4px',
        borderRadius: '4px',
      }}
    >
      <Button onClick={onEdit} icon={<CdEditIcon />} disabled={disabled}>
        {I18nService.getString('Edit')}
      </Button>
      <Button onClick={onDelete} icon={<CdDeleteIcon />} disabled={disabled}>
        {I18nService.getString('Remove')}
      </Button>
    </div>
  );
};
