import { create } from 'apisauce';
import { AxiosRequestConfig } from 'axios';
import _ from 'lodash';
import qs from 'qs';

import cdApp from '../config';
import AuthenticationService from '../services/AuthenticationService';

import { ApiRequestOptions } from '@/generated/api/core/ApiRequestOptions';
import { FetchHttpRequest } from '@/generated/api/core/FetchHttpRequest';
import { BackendApiClient, CancelablePromise } from '@/generated/api';

// Page pagination
export interface ApiSearchParams extends Record<string, any> {
  pageNumber: number;
  limit: number;
  extraData?: any;
}

export interface ApiSearchResult<T> {
  items: T[];
  total: number;
  access?: boolean;
}

// Cursor pagination
export interface ApiCursorSearchParams extends Record<string, any> {
  cursor: string;
  limit: number;
  extraData?: any;
}

export interface ApiCursorSearchResult<T> {
  items: T[];
  cursor: string;
  access?: boolean;
}

/** ApiSauce would parse query object as:
 * filters=%7B%22status%22:[%22active%22]%7D  -->  filters={"status":["active"]}
 * instead of:
 * filters%5Bstatus%5D%5B0%5D=active  -->  filters[status][0]: active
 * which express (qs.js) expects. Joi previously supported parsing string to object automatically
 * but this has been removed: https://github.com/hapijs/joi/issues/2124 */
const paramsSerializer = (params: any) =>
  qs.stringify(_.omitBy(params, _.isNil));

const mainApi = create({ baseURL: cdApp.config?.api.main, paramsSerializer });
const formsApi = create({ baseURL: cdApp.config?.api.forms, paramsSerializer });
const rruleApi = create({ baseURL: cdApp.config?.api.rrule, paramsSerializer });
const contributionsApi = create({
  baseURL: cdApp.config?.api.payments,
  paramsSerializer,
});
const portalWidgetService = create({
  baseURL: cdApp.config?.api.portal,
  paramsSerializer,
});
const partnershipApi = create({
  baseURL: cdApp.config?.api.partners,
  paramsSerializer,
});

const fillInHeaders = (headers: Record<string, any>) => {
  const accessToken = AuthenticationService.getAccessToken();
  if (accessToken) {
    headers['Authorization'] =
      headers['Authorization'] || `Bearer ${accessToken}`;
  }
  // App version
  headers['X-CD-Version'] = new Date(cdApp.version).toUTCString();

  // Logged in user and masquerading user, if applicable
  if (cdApp.me) {
    headers['X-CD-User'] = cdApp.me.id;

    if (cdApp.me.masquerading) {
      headers['X-CD-Masquerading-User'] = cdApp.me.masqueradingUser;
    }
  }
};

const transformRequest = (request: AxiosRequestConfig): void => {
  request.params = request.params || {};
  request.headers = request.headers || {};

  const skipAuthorization =
    _.get(request.params, 'skipAuthorization', false) === true;
  delete request.params.skipAuthorization;

  if (!skipAuthorization) {
    // append organizationId to all internal API requests
    if (request.url.indexOf('organizationId=') === -1) {
      request.params.organizationId =
        request.params.organizationId ||
        _.get(window, 'churchdeskOrganizationId');
    }
  }
  fillInHeaders(request.headers);
  if (skipAuthorization) {
    delete request.headers['Authorization'];
  }
};

mainApi.addRequestTransform(transformRequest);
formsApi.addRequestTransform(transformRequest);
contributionsApi.addRequestTransform(transformRequest);
partnershipApi.addRequestTransform(transformRequest);

class FetchRequestInterceptor extends FetchHttpRequest {
  override request<T>(options: ApiRequestOptions): CancelablePromise<T> {
    const headers = options.headers || {};
    const query = options.query || {};
    query.organizationId =
      query.organizationId || _.get(window, 'churchdeskOrganizationId');

    fillInHeaders(headers);
    return super.request({
      ...options,
      query,
      headers,
    });
  }
}

const backendApiClient = new BackendApiClient(
  {
    // In storybook or tests, the main path is `/` and it does not work well with this client
    BASE: cdApp.config?.api.main === '/' ? '' : cdApp.config?.api.main,
  },
  FetchRequestInterceptor
);

export {
  mainApi,
  partnershipApi,
  formsApi,
  rruleApi,
  contributionsApi,
  portalWidgetService,
  backendApiClient,
};
