/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostPPaymentsInitiatepaymentRequestBody = {
    amount: number;
    contributionId: string;
    paymentType: PostPPaymentsInitiatepaymentRequestBody.paymentType;
    ip?: string;
    terminalId?: string;
};
export namespace PostPPaymentsInitiatepaymentRequestBody {
    export enum paymentType {
        CARD = 'CARD',
        TERMINAL = 'TERMINAL',
        APPLEPAY = 'APPLEPAY',
        GOOGLEPAY = 'GOOGLEPAY',
        SEPA = 'SEPA',
        MOBILEPAY = 'MOBILEPAY',
    }
}

