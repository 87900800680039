import { useRecoilValue, useRecoilCallback } from 'recoil';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { ApiResponse } from 'apisauce';
import NiceModal from '@ebay/nice-modal-react';
import { Moment } from 'moment';
import { uniqBy } from 'lodash';

import { mainApi } from '@/react/api';
import NotificationService from '@/react/services/NotificationService';
import { getStateUrl, navigate } from '@/react/services/StateServiceFactory';
import { I18nService } from '@/react/services/I18nService';
import { getCrop, saveCrop } from '@/react/files/store/images';
import {
  AddMessageFile,
  MessageByIdQuery,
  MessageStatisticsByIdPermission,
  peopleMessageIdState,
  RemoveMessageFile,
} from '@/react/people/store/message';
import {
  PeopleMessage,
  MessageType,
  PeopleMessageFile,
} from '@/react/people/types/message';

export interface AttachFileInterface
  extends Pick<
    UploadRequestOption<PeopleMessageFile>,
    'onSuccess' | 'onProgress' | 'onError' | 'file'
  > {
  fileName?: string;
}
function recipientReducer(
  accumulator: { id: number; group: string }[],
  currentValue: string
) {
  if (currentValue.includes('person-')) {
    accumulator.push({
      id: parseInt(currentValue.replace('person-', ''), 10),
      group: 'contacts',
    });
  }
  if (currentValue.includes('segment-')) {
    accumulator.push({
      id: parseInt(currentValue.replace('segment-', ''), 10),
      group: 'lists',
    });
  }
  return accumulator;
}

function mapMessageFromState(message) {
  return {
    ...message,
    to: message.to
      .map((target) =>
        target.setup ? `segment-${target.id}` : `person-${target.id}`
      )
      .reduce(recipientReducer, []),
  };
}

function mapMessageFromForm(message) {
  return {
    ...message,
    id: message.id,
    churches: message.churches?.map((item: number) => ({ id: item })),
    to: message.to?.reduce(recipientReducer, []),
  };
}

function navigateToMessage(
  messageId: number,
  type: MessageType,
  reload = false
): void {
  navigate(
    'app.private.people.messages.editorV2.view',
    { messageId, type },
    { reload }
  );
}

const sanitizePayload = (
  payload: PeopleMessage | undefined,
  type: MessageType
): PeopleMessage => ({
  ...payload,
  type,
  forms: uniqBy(payload?.forms, 'id'),
});

export default function useMessage(
  id: number,
  type: MessageType,
  sourceInfo?: any
) {
  const message = sanitizePayload(useRecoilValue(MessageByIdQuery(id)), type);

  const showShareNewsletterModal = useRecoilCallback(
    () => (sent: boolean, when?: Date | Moment) =>
      NiceModal.show('ShareNewsletterModal', { message, sent, when }),
    [message]
  );

  const saveMessage = useRecoilCallback(
    ({ set }) =>
      async (payload: PeopleMessage) => {
        let response: ApiResponse<PeopleMessage, PeopleMessage>;
        const processedPayload = {
          ...sanitizePayload(payload, type),
          scheduled: undefined,
          sent: undefined,
          anniversary: sourceInfo?.anniversary,
        };
        if (!id) {
          response = await mainApi.post<PeopleMessage>(
            '/v2/people/messages',
            mapMessageFromForm(processedPayload)
          );

          if (response.ok) {
            navigateToMessage(response.data.id, response.data.type);
          }
        } else {
          response = await mainApi.put<PeopleMessage>(
            `/v2/people/messages/${id}`,
            mapMessageFromForm(processedPayload)
          );
        }
        if (!response.ok) {
          NotificationService.notifyError((response as any)?.data?.message);
          throw response;
        }
        set(MessageByIdQuery(response.data.id), response.data);
        set(peopleMessageIdState, response.data.id);
        NotificationService.notifySuccess(
          I18nService.getString('Message saved')
        );
        return response.data;
      },
    [id, sourceInfo?.anniversary, type]
  );

  const cloneMessage = useRecoilCallback(
    ({ set }) =>
      async () => {
        const appendCopyLabel = ` ${I18nService.getString('(copy)')}`;
        const response = await mainApi.post<PeopleMessage>(
          '/v2/people/messages',
          mapMessageFromState({
            ...message,
            title: message.title + appendCopyLabel,
            sent: undefined,
            scheduled: undefined,
            id: undefined,
            uuid: undefined,
          })
        );
        if (!response.ok) {
          NotificationService.notifyError((response as any)?.data?.message);
          throw response;
        }
        if (type === MessageType.SIMPLE && message?.picture?.id) {
          const coverImageCrop = await getCrop({
            fileId: message.picture.id,
            context: `people-message-${message.id}`,
          });
          await saveCrop({
            fileId: message.picture.id,
            context: `people-message-${response.data.id}`,
            bounds: 'people-message-simple',
            crop: coverImageCrop.data['people-message-simple'],
          });
        }
        set(MessageByIdQuery(response.data.id), response.data);
        set(peopleMessageIdState, response.data.id);
        const redirectUrl = getStateUrl(
          'app.private.people.messages.editorV2.view',
          {
            messageId: response.data.id,
            type: response.data.type,
          }
        );
        // Full refresh until we figure out why recoils state gets corrupted.
        location.href = redirectUrl;
      },
    [message, type]
  );

  const refetchMessage = useRecoilCallback(
    ({ set }) =>
      async (rerender = false) => {
        const response = await mainApi.get<PeopleMessage>(
          `/v2/people/messages/${id}?rerender=${rerender}`
        );
        if (!response.ok) {
          NotificationService.notifyError((response as any)?.data?.message);
          throw response;
        }
        set(MessageByIdQuery(response.data.id), response.data);
      },
    [id]
  );

  const sendMessage = useRecoilCallback(
    ({ set }) =>
      async (_message: PeopleMessage, when?: Date | Moment) => {
        const messageCopy = {
          ..._message,
          scheduled: (when || new Date()).toISOString(),
        };
        const response = await mainApi.put<PeopleMessage>(
          `/v2/people/messages/${_message.id}`,
          mapMessageFromState(messageCopy)
        );
        if (!response.ok) {
          NotificationService.notifyError((response as any)?.data?.message);
          throw response;
        }
        if (!when) {
          NotificationService.notifySuccess(
            I18nService.getString('Message is sending')
          );
        } else {
          NotificationService.notifySuccess(
            I18nService.getString('Your message has been scheduled for sending')
          );
        }
        if (
          _message.type === MessageType.ADVANCED ||
          _message.type === MessageType.SIMPLE
        ) {
          showShareNewsletterModal(!when, when);
        }
        set(MessageByIdQuery(response.data.id), response.data);
      },
    [showShareNewsletterModal]
  );

  const sendTestMessage = useRecoilCallback(
    () => async (_message: PeopleMessage) => {
      const response = await mainApi.post('/people/messages/test', {
        id: _message.id,
      });
      if (!response.ok) {
        NotificationService.notifyError((response as any)?.data?.message);
        throw response;
      }
      NotificationService.notifySuccess(
        I18nService.getString('Test message sent')
      );
    },
    []
  );

  const deleteMessage = useRecoilCallback(
    ({ reset }) =>
      async () => {
        const response = await mainApi.delete(`/v2/people/messages/${id}`);
        if (response.ok) {
          reset(MessageByIdQuery(id));
          return;
        } else {
          NotificationService.notifyError((response as any)?.data?.message);
          throw response;
        }
      },
    [id]
  );

  const attachFile = useRecoilCallback(
    ({ set }) =>
      async ({
        onSuccess,
        onProgress,
        onError,
        file,
        fileName,
      }: AttachFileInterface) => {
        const fileForm = new FormData();
        // Add file while uploading.
        set(AddMessageFile(id), file as RcFile);
        fileForm.append('file', file as any, fileName);
        try {
          const res = await mainApi.post<PeopleMessageFile>(
            `/v2/people/messages/${id}/files`,
            fileForm,
            {
              onUploadProgress: (e) =>
                onProgress({ percent: (e.loaded / e.total) * 100, ...e }),
            }
          );
          if (res.ok) {
            onSuccess(res.data, undefined);
            // Remove temp file after upload succeeded.
            set(RemoveMessageFile(id), file as RcFile);
            // Add the file received from the backend.
            set(AddMessageFile(id), res.data);
            return res.data;
          }

          let errorMessage: string = (res?.data as any)?.message;
          if (res.status === 406) {
            errorMessage = I18nService.getString(
              'The file type is not allowed.'
            );
          }
          NotificationService.notifyError(errorMessage);
          onError(new Error(errorMessage));
        } catch (e) {
          NotificationService.notifyError((e as any)?.data?.message);
          onError(e);
        }
      },
    [id]
  );

  const deleteFile = useRecoilCallback(
    ({ set }) =>
      async (file: UploadFile & PeopleMessageFile) => {
        const res = await mainApi.delete(
          `/v2/people/messages/${id}/files/${file.id}`
        );
        if (res.ok) {
          set(RemoveMessageFile(id), file);
          return res.ok;
        } else {
          NotificationService.notifyError((res as any)?.data?.message);
          throw res;
        }
      },
    [id]
  );

  const canViewStatistics = useRecoilValue(MessageStatisticsByIdPermission(id));

  return {
    attachFile,
    cloneMessage,
    deleteFile,
    deleteMessage,
    message,
    saveMessage,
    sendMessage,
    canViewStatistics,
    sendTestMessage,
    refetchMessage,
  };
}
