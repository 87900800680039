import React from 'react';
import { Alert, Form, Input, Typography } from 'antd';

import {
  createCdModal,
  ModalType,
} from '../../shared/components/cd-modal-new/CdModal2';
import { I18nService } from '../../services/I18nService';
import { GroupSelect } from '../../settings/booking-pages/components/GroupSelect';
import { CdCheckbox } from '../../shared/components/cd-checkbox/CdCheckbox';
import { CdVerticalSpace } from '../../shared/components/cd-vertical-space/CdVerticalSpace';
import { CdWarningIcon } from '../../shared/components/Icons';
import { mustEqual, required } from '../../shared/antd/validations/common';
import { useDeleteGroupModal } from '../hooks/useDeleteGroupModal';

export const showDeleteGroupModal = createCdModal<{
  groupId: number;
  groupName: string;
}>(
  ({ setModalProps, groupId, groupName }) => {
    const {
      form,
      transfer,
      bookingEnquiriesV2Enabled,
      bookingPagesThatWillLoseAvailability,
    } = useDeleteGroupModal(setModalProps, groupId);

    return (
      <Form form={form} initialValues={{ transfer: true }} layout="vertical">
        <Typography.Paragraph>
          {I18nService.getString(
            'Are you sure you want to delete this group? This action cannot be undone.'
          )}
        </Typography.Paragraph>
        <Form.Item name="transfer" valuePropName="checked">
          <CdCheckbox>
            {I18nService.getString('Transfer all content to another group')}
          </CdCheckbox>
        </Form.Item>
        {transfer ? (
          <Form.Item
            name="targetId"
            label={I18nService.getString('Replacement group')}
            rules={[required()]}
          >
            <GroupSelect placeholder="" omitIds={[groupId]} mode={null} />
          </Form.Item>
        ) : (
          <CdVerticalSpace>
            <Alert
              type="warning"
              message={
                <Typography.Text type="warning">
                  <b>{I18nService.getString('Warning!')}</b>{' '}
                  {I18nService.getString(
                    'All content belonging to this group, including events, files, blog posts and messages will be lost.'
                  )}
                </Typography.Text>
              }
              icon={<CdWarningIcon type="warning" style={{ marginRight: 8 }} />}
              showIcon
            />
            {bookingEnquiriesV2Enabled &&
              bookingPagesThatWillLoseAvailability.length > 0 && (
                <Alert
                  message={
                    <Typography.Text type="warning">
                      <b>{I18nService.getString('Warning!')}</b>{' '}
                      {I18nService.getString(
                        'This group and its members will no longer be available for the following booking pages:'
                      )}
                      <ul style={{ margin: 0 }}>
                        {bookingPagesThatWillLoseAvailability.map((page) => (
                          <li key={page.id}>{page.name}</li>
                        ))}
                      </ul>
                    </Typography.Text>
                  }
                  type="warning"
                  icon={
                    <CdWarningIcon type="warning" style={{ marginRight: 8 }} />
                  }
                  showIcon
                />
              )}
            <Form.Item
              name="name"
              label={I18nService.getString(
                'Please enter the name of the group you are deleting to confirm ({{groupName}})',
                {
                  groupName,
                }
              )}
              rules={[
                required(),
                mustEqual(
                  groupName,
                  I18nService.getString(
                    'The text you have entered does not match the group name.'
                  )
                ),
              ]}
            >
              <Input />
            </Form.Item>
          </CdVerticalSpace>
        )}
      </Form>
    );
  },
  {
    title: I18nService.getString('Delete group'),
    modalType: ModalType.POPUP,
    okText: I18nService.getString('Delete'),
    okButtonProps: { danger: true },
  }
);
