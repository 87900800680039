/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { GetCalendarFormsSearchResponseBody } from '../models/GetCalendarFormsSearchResponseBody';
import type { GetUsersAnnouncementsResponseBody } from '../models/GetUsersAnnouncementsResponseBody';
import type { GetUsersGroupsResponseBody } from '../models/GetUsersGroupsResponseBody';
import type { GetUsersResponseBody2 } from '../models/GetUsersResponseBody2';
import type { GetUsersSlimResponseBody } from '../models/GetUsersSlimResponseBody';
import type { GetUsersUseridResponseBody } from '../models/GetUsersUseridResponseBody';
import type { PermissionsRequestBody } from '../models/PermissionsRequestBody';
import type { PermissionsResponseBody } from '../models/PermissionsResponseBody';
import type { PostUserRequestBody } from '../models/PostUserRequestBody';
import type { PostUsersContactRequestBody } from '../models/PostUsersContactRequestBody';
import type { PostUsersIntercomRequestBody } from '../models/PostUsersIntercomRequestBody';
import type { PostUsersResponseBody } from '../models/PostUsersResponseBody';
import type { PutUsersChurchesRequestBody } from '../models/PutUsersChurchesRequestBody';
import type { PutUsersIdRequestBody } from '../models/PutUsersIdRequestBody';
import type { PutUsersRequestBody } from '../models/PutUsersRequestBody';
import type { PutUsersRolesRequestBody } from '../models/PutUsersRolesRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UsersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Lists the users in the given organization
     * @returns GetUsersResponseBody2
     * @throws ApiError
     */
    public getUsers(): CancelablePromise<GetUsersResponseBody2> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users',
        });
    }
    /**
     * Creates a new ChurchDesk user in an organization.
     * @returns PostUsersResponseBody
     * @throws ApiError
     */
    public postUsers({
        requestBody,
    }: {
        requestBody?: PostUserRequestBody,
    }): CancelablePromise<PostUsersResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetUsersAnnouncements
     * Announcements
     * @returns GetUsersAnnouncementsResponseBody
     * @throws ApiError
     */
    public getUsersAnnouncements(): CancelablePromise<GetUsersAnnouncementsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/announcements',
        });
    }
    /**
     * PutUsersAnnouncements
     * Announcements
     * @returns EmptyObject
     * @throws ApiError
     */
    public putUsersAnnouncements({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/users/announcements',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetUsersDashboardFeed
     * Dashboard feed
     * @returns EmptyObject
     * @throws ApiError
     */
    public getUsersDashboardFeed(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/dashboard/feed',
        });
    }
    /**
     * Retrieves the groups that the has access to.
     * @returns GetUsersGroupsResponseBody
     * @throws ApiError
     */
    public getUsersGroups({
        filter = 'my-groups',
    }: {
        filter?: 'my-groups' | 'view-absence',
    }): CancelablePromise<GetUsersGroupsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/groups',
            query: {
                'filter': filter,
            },
        });
    }
    /**
     * PostUsersIntercom
     * Intercom
     * @returns EmptyObject
     * @throws ApiError
     */
    public postUsersIntercom({
        requestBody,
    }: {
        requestBody?: PostUsersIntercomRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/intercom',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetUsersJobs
     * Jobs
     * @returns EmptyObject
     * @throws ApiError
     */
    public getUsersJobs({
        countryIso2 = 'GB',
    }: {
        countryIso2?: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/jobs',
            query: {
                'countryIso2': countryIso2,
            },
        });
    }
    /**
     * GetUsersLogout
     * Log out
     * @returns EmptyObject
     * @throws ApiError
     */
    public getUsersLogout(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/logout',
        });
    }
    /**
     * GetUsersMe
     * Me
     * @returns GetCalendarFormsSearchResponseBody
     * @throws ApiError
     */
    public getUsersMe({
        slim = false,
        accessToken,
    }: {
        slim?: boolean,
        accessToken?: string,
    }): CancelablePromise<GetCalendarFormsSearchResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/me',
            query: {
                'slim': slim,
                'access_token': accessToken,
            },
        });
    }
    /**
     * GetUsersNotifications
     * My notifications
     * @returns EmptyObject
     * @throws ApiError
     */
    public getUsersNotifications(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/notifications',
        });
    }
    /**
     * GetUsersSlim
     * Index User Slim
     * @returns GetUsersSlimResponseBody
     * @throws ApiError
     */
    public getUsersSlim(): CancelablePromise<GetUsersSlimResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/slim',
        });
    }
    /**
     * DeleteUsersId
     * Delete
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteUsers({
        id,
        accessToken,
    }: {
        id: number,
        accessToken?: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/{id}',
            path: {
                'id': id,
            },
            query: {
                'access_token': accessToken,
            },
        });
    }
    /**
     * PutUsersId
     * Update
     * @returns EmptyObject
     * @throws ApiError
     */
    public putUsers({
        requestBody,
    }: {
        requestBody?: PutUsersIdRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/users/{id}',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * PostUsersIdContact
     * Contact
     * @returns EmptyObject
     * @throws ApiError
     */
    public postUsersContact({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PostUsersContactRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/{id}/contact',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * UploadUserPicture
     * User Picture
     * @returns EmptyObject
     * @throws ApiError
     */
    public postUsersUploadPicture({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/{id}/upload/picture',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Gets the selected user object
     * @returns GetUsersUseridResponseBody
     * @throws ApiError
     */
    public getUsers1({
        userId,
    }: {
        userId: number,
    }): CancelablePromise<GetUsersUseridResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{userId}',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * Update an existing user
     * @throws ApiError
     */
    public putUsers1({
        userId,
        requestBody,
    }: {
        userId: number,
        requestBody?: PutUsersRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/users/{userId}',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get_Church_Memberships
     * @returns EmptyObject
     * @throws ApiError
     */
    public getUsersChurches({
        userId,
    }: {
        userId: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{userId}/churches',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * Update_Church_Memberships
     * @returns EmptyObject
     * @throws ApiError
     */
    public putUsersChurches({
        userId,
        requestBody,
    }: {
        userId: number,
        requestBody?: PutUsersChurchesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/users/{userId}/churches',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetUsersUseridFeed
     * User activity feed
     * @returns EmptyObject
     * @throws ApiError
     */
    public getUsersFeed({
        id,
        userId,
    }: {
        /**
         * The id of the organization.
         */
        id: any,
        /**
         * The user id in which you want to list the activity from.
         */
        userId: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{userId}/feed',
            query: {
                'id': id,
                'userId': userId,
            },
        });
    }
    /**
     * Permissions
     * @returns EmptyObject
     * @throws ApiError
     */
    public putUsersPermissions({
        requestBody,
    }: {
        requestBody?: PermissionsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/users/{userId}/permissions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Permissions
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteUsersPermissions(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/{userId}/permissions',
        });
    }
    /**
     * Permissions
     * @returns PermissionsResponseBody
     * @throws ApiError
     */
    public getUsersPermissions({
        userId,
    }: {
        /**
         * The user identifier for the given user.
         */
        userId: any,
    }): CancelablePromise<PermissionsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{userId}/permissions',
            query: {
                'userId': userId,
            },
        });
    }
    /**
     * PostUsersUseridResendWelcomeEmail
     * Resend Welcome Email
     * @returns EmptyObject
     * @throws ApiError
     */
    public postUsersResendWelcomeEmail({
        userId,
        accessToken,
        requestBody,
    }: {
        userId: number,
        accessToken?: string,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/{userId}/resend/welcome-email',
            path: {
                'userId': userId,
            },
            query: {
                'access_token': accessToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get_Roles
     * @returns EmptyObject
     * @throws ApiError
     */
    public getUsersRoles({
        userId,
    }: {
        userId: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{userId}/roles',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * Update_Roles
     * @returns EmptyObject
     * @throws ApiError
     */
    public putUsersRoles({
        userId,
        requestBody,
    }: {
        userId: number,
        requestBody?: PutUsersRolesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/users/{userId}/roles',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
