import { Input } from 'antd';
import React, { useMemo } from 'react';
import { useDebounceValue } from 'usehooks-ts';

import { useHasPrivilege } from '../../../user/store/permissions';
import { OrderType } from '../../../shared/models/table';
import CdClientSideTable from '../../../shared/components/cd-client-side-table/CdClientSideTable';
import { useTaxonomiesClientside } from '../../../calendar/hooks/use-taxonomies';
import { CategoryType } from '../../../shared/models/category';
import { useFlag } from '../../../shared/hooks/useFlag';
import { CdDropdown } from '../../../shared/components/cd-dropdown/CdDropdown';
import { Taxonomy } from '../../../shared/models/taxonomy';

import { showBookingPagePreviewModal } from './PreviewBookingPage';

import { I18nService } from '@/react/services/I18nService';
import { useBookingPagesActions } from '@/react/settings/booking-pages/hooks/UseBookingPagesActions';
import {
  CdCodeIcon,
  CdDeleteIcon,
  CdEditIcon,
  CdLink,
  CdSearch,
  CdTableIcon,
} from '@/react/shared/components/Icons';
import { ColumnType } from '@/react/shared/components/cd-table';

export const BookingPagesTable = () => {
  const { editBookingPage, deleteBookingPage, copyLink } =
    useBookingPagesActions();
  const [search, setSearch] = useDebounceValue('', 300);
  const bookingEnquiriesV2Enabled = useFlag('booking_enquiries_v2');
  const canEdit = useHasPrivilege('taxonomy.availability.edit');
  const canDelete = useHasPrivilege('taxonomy.availability.edit');

  const columns: ColumnType<Taxonomy>[] = useMemo(
    () => [
      {
        key: 'name',
        title: I18nService.getString('Type'),
        orderType: OrderType.STRING,
        defaultSortOrder: 'ascend',
        dataIndex: 'name',
        width: 400,
      },
      {
        key: 'config',
        title: I18nService.getString('Public name'),
        orderType: OrderType.STRING,
        dataIndex: ['config', 'publicName'],
      },
      {
        key: 'actions',
        width: 1,
        render: (_, bookingPage) => (
          <CdDropdown
            size="small"
            items={[
              {
                key: 'edit',
                label: I18nService.getString('Edit'),
                icon: <CdEditIcon />,
                onClick: () => editBookingPage(bookingPage),
                disabled: !canEdit,
              },
              {
                key: 'copyLink',
                label: I18nService.getString('Copy shareable link'),
                icon: <CdLink />,
                onClick: () => copyLink(bookingPage),
              },
              bookingEnquiriesV2Enabled && {
                key: 'copyCode',
                label: I18nService.getString('Copy code'),
                icon: <CdCodeIcon />,
                onClick: () =>
                  showBookingPagePreviewModal({ taxonomy: bookingPage }),
              },
              { type: 'divider' },
              {
                key: 'delete',
                label: I18nService.getString('Delete'),
                icon: <CdDeleteIcon />,
                danger: true,
                onClick: () => deleteBookingPage(bookingPage),
                disabled: !canDelete,
              },
            ]}
          >
            {I18nService.getString('Actions')}
          </CdDropdown>
        ),
      },
    ],
    [
      bookingEnquiriesV2Enabled,
      canDelete,
      canEdit,
      copyLink,
      deleteBookingPage,
      editBookingPage,
    ]
  );

  return (
    <CdClientSideTable<Taxonomy>
      filterForm={
        <Input
          defaultValue={search}
          prefix={<CdSearch type="secondary" />}
          onChange={(e) => setSearch(e.target.value)}
          style={{ width: 300 }}
          placeholder={I18nService.getString('Search...')}
        />
      }
      columns={columns}
      querySource={useTaxonomiesClientside}
      searchParams={{ type: CategoryType.availability, search }}
      emptyStateProps={{
        title: I18nService.getString('No booking pages'),
        EmptyStateIcon: <CdTableIcon />,
      }}
    />
  );
};
