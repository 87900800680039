import {
  Button,
  Form,
  FormInstance,
  Input,
  Space,
  Switch,
  Typography,
} from 'antd';
import React from 'react';
import styled from 'styled-components';

import { CdTooltip } from '../../../../shared/components/cd-tooltip/CdTooltip';
import { useFlag } from '../../../../shared/hooks/useFlag';
import { CdRadioGroup } from '../../../../shared/components/cd-radio-group/CdRadioGroup';
import { AvailabilityTaxonomyFormData } from '../../types/availability-taxonomy.types';
import { GroupSelect } from '../GroupSelect';

import { CdEventCategorySelect } from '@/react/calendar/event-details/components/CdEventCategorySelect';
import { ClientEventVisibility } from '@/react/calendar/types/event';
import cdApp from '@/react/config';
import { I18nService } from '@/react/services/I18nService';
import { getStateUrl } from '@/react/services/StateServiceFactory';
import { VisibilitySelect } from '@/react/settings/booking-pages/components/VisibilitySelect';
import { checkForDuplicateTaxonomyAlias } from '@/react/settings/booking-pages/utils/validation';
import {
  aliasValidator,
  debouncedValidation,
  maxLength,
  required,
} from '@/react/shared/antd/validations/common';
import { CdParishSelect } from '@/react/shared/components/cd-perish-select/CdParishSelect';
import { CdVerticalSpace } from '@/react/shared/components/cd-vertical-space/CdVerticalSpace';
import { BookingFormData } from '@/react/shared/models/taxonomy';

// AntD in the current version that we are using does not allow to mix vertical and horizontal form items
// so this is an override to make it possible
const HorizontalFormItem = styled(Form.Item)`
    margin-top: 24px;
    &&& > * {
        flex-direction: row;
        flex-wrap: nowrap;

        & > *:first-child {
            flex: 0 0 150px;
        }

        & > *:last-child {
            flex: 1 1 0;
        }
    }
`;

type DetailsFormProps = {
  form: FormInstance<AvailabilityTaxonomyFormData>;
  formData?: BookingFormData;
  followUpForm?: BookingFormData;
  initialValues?: any;
  isEditMode?: boolean;
};

export const DetailsForm = ({
  form,
  formData,
  followUpForm,
  initialValues,
  isEditMode,
}: DetailsFormProps) => {
  const visibility = Form.useWatch('visibility', form);
  const alias = Form.useWatch('alias', form);
  const automaticFollowUpForm = Form.useWatch('automaticFollowUpForm', form);
  const bookingEnquiriesV2Enabled = useFlag('booking_enquiries_v2');

  const showChurchSelector = window?.cdApp?.showChurchSelector;

  return (
    <>
      <CdVerticalSpace>
        <Typography.Title level={3}>
          {I18nService.getString('General settings')}
        </Typography.Title>

        <Typography.Text type="secondary">
          {I18nService.getString(
            'Available times can be requested through a public booking page where individuals can choose from available time slots.'
          )}
        </Typography.Text>

        <Form.Item
          name="name"
          label={I18nService.getString('Enquiry type e.g. funeral')}
          rules={[required(), maxLength(255)]}
        >
          <Input placeholder={I18nService.getString('E.g. funeral...')} />
        </Form.Item>
      </CdVerticalSpace>
      <Form.Item
        name="availabilityType"
        label={I18nService.getString('Availability for the booking page')}
      >
        <CdRadioGroup
          disabled={isEditMode}
          options={[
            bookingEnquiriesV2Enabled && {
              label: I18nService.getString(
                'General availability (Create a general weekly schedule for availability)'
              ),
              value: 'general-availability',
            },
            {
              label: I18nService.getString(
                'Specific availability (Create specific time slots for availability in the calendar)'
              ),
              value: 'specific-availability',
            },
            !bookingEnquiriesV2Enabled && {
              label: I18nService.getString(
                'General availability (Create a general weekly schedule for availability)'
              ),
              value: 'general-availability',
              disabled: true,
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        name={['groups']}
        label={I18nService.getString('Group(s)')}
        extra={I18nService.getString(
          'Members can be made available for booking, and can manage enquiries.'
        )}
        rules={[required()]}
      >
        <GroupSelect />
      </Form.Item>
      <Typography.Title level={3}>
        {I18nService.getString('Booking page information')}
      </Typography.Title>
      <Form.Item
        name="bookingName"
        label={I18nService.getString('Public name')}
        rules={[required(), maxLength(255)]}
      >
        <Input
          placeholder={I18nService.getString('Public name...')}
          onBlur={(e) => {
            if (!form.getFieldValue('alias')?.length) {
              const defaultAlias = (
                e.target.value
                  .toString()
                  .replace(/ /g, '-')
                  .toLowerCase()
                  .match(/[a-z0-9-]+/) || []
              ).join('');

              form.setFieldValue('alias', defaultAlias);
              form.validateFields(['alias']);
            }
          }}
        />
      </Form.Item>
      <Form.Item
        name="bookingDescription"
        label={I18nService.getString('Description')}
        rules={[maxLength(3000)]}
      >
        <Input.TextArea
          rows={4}
          placeholder={I18nService.getString('Add a description...')}
        />
      </Form.Item>
      <Form.Item
        name="alias"
        label={I18nService.getString('URL of the booking page')}
        rules={[
          aliasValidator,
          required(),
          initialValues.alias !== alias &&
            debouncedValidation(checkForDuplicateTaxonomyAlias, 500),
        ]}
      >
        <Input
          addonBefore={`${cdApp.references.bookingPage}${cdApp.organization.publicId}/`}
          placeholder={I18nService.getString('alias')}
        />
      </Form.Item>

      <Typography.Title level={3}>
        {I18nService.getString(
          'Event settings for confirmed booking enquiries'
        )}
      </Typography.Title>
      <Typography.Text type="secondary">
        {I18nService.getString(
          'Confirmed requests will create the following event:'
        )}
      </Typography.Text>
      {showChurchSelector && (
        <HorizontalFormItem
          name="churchIds"
          label={I18nService.getString('Parish')}
          rules={[required()]}
        >
          <CdParishSelect style={{ width: '100%' }} />
        </HorizontalFormItem>
      )}
      <HorizontalFormItem
        name="categoryId"
        label={I18nService.getString('Event category')}
        rules={[required()]}
      >
        <CdEventCategorySelect
          placeholder={I18nService.getString('Add main category')}
          usage="calendar"
        />
      </HorizontalFormItem>
      <HorizontalFormItem
        name="visibility"
        label={I18nService.getString('Visibility')}
        rules={[required()]}
      >
        <VisibilitySelect />
      </HorizontalFormItem>
      {visibility === ClientEventVisibility.INTERNAL_GROUPS && (
        <HorizontalFormItem
          name={['visibilityGroups']}
          label={I18nService.getString('Groups')}
          rules={[required()]}
        >
          <GroupSelect aria-label="visibilityGroups" />
        </HorizontalFormItem>
      )}

      <Typography.Title level={3}>
        {I18nService.getString('Enquiry Form for the booking page')}
      </Typography.Title>
      <Typography.Text type="secondary">
        {I18nService.getString(
          'Users select a date and time, complete the form, and submit their enquiry. Click "{{button}}" to enable or edit the form.',
          { button: I18nService.getString('Save and continue editing') }
        )}
      </Typography.Text>
      <Typography.Title level={4}>
        {I18nService.getString('Initial enquiry form')}
      </Typography.Title>
      <Form.Item>
        <CdTooltip
          title={
            !formData &&
            I18nService.getString(
              'A form will be generated once the booking page is saved.'
            )
          }
        >
          <Space>
            <Button
              href={getStateUrl('app.private.forms.edit', formData)}
              target="_blank"
              disabled={!formData}
            >
              {I18nService.getString('Edit form')}
            </Button>
            <Button
              href={`${cdApp.references.formViewer}/f/${formData?.alias}`}
              disabled={!formData}
              target="_blank"
            >
              {I18nService.getString('View')}
            </Button>
          </Space>
        </CdTooltip>
      </Form.Item>
      {bookingEnquiriesV2Enabled && (
        <>
          <Space>
            <Typography.Text>
              {I18nService.getString('Enable an automatic follow-up form')}
            </Typography.Text>
            <Form.Item
              name="automaticFollowUpForm"
              valuePropName="checked"
              noStyle
            >
              <Switch />
            </Form.Item>
          </Space>
          {automaticFollowUpForm && (
            <>
              <Typography.Title level={4}>
                {I18nService.getString('Follow up form')}
              </Typography.Title>
              <Form.Item
                extra={I18nService.getString(
                  'This form will be sent out automatically if an enquiry is confirmed. Example: An enquiry for a room rental is approved and then the user automatically receives a link to the form asking for more needed information and payment of the deposit.'
                )}
              >
                <CdTooltip
                  title={
                    !followUpForm &&
                    I18nService.getString(
                      'A form will be generated once the booking page is saved.'
                    )
                  }
                >
                  <Space style={{ marginBottom: 8 }}>
                    <Button
                      href={getStateUrl('app.private.forms.edit', followUpForm)}
                      target="_blank"
                      disabled={!followUpForm}
                    >
                      {I18nService.getString('Edit form')}
                    </Button>
                    <Button
                      href={`${cdApp.references.formViewer}/f/${followUpForm?.alias}`}
                      disabled={!followUpForm}
                      target="_blank"
                    >
                      {I18nService.getString('View')}
                    </Button>
                  </Space>
                </CdTooltip>
              </Form.Item>
            </>
          )}
          <Typography.Title level={3}>
            {I18nService.getString('Emails')}
          </Typography.Title>
          <Form.Item
            label={I18nService.getString('Default text for confirmation email')}
            name={['emailTemplates', 'confirmation']}
          >
            <Input.TextArea
              autoSize={{ minRows: 3, maxRows: 3 }}
              maxLength={500}
              showCount
              placeholder={I18nService.getString(
                'Please provide a message for approved enquiries...'
              )}
            />
          </Form.Item>
          <Form.Item
            label={I18nService.getString(
              'Default text for ‘not possible’ email'
            )}
            name={['emailTemplates', 'rejection']}
          >
            <Input.TextArea
              autoSize={{ minRows: 3, maxRows: 3 }}
              maxLength={500}
              showCount
              placeholder={I18nService.getString(
                'Please provide a message for rejected enquiries...'
              )}
            />
          </Form.Item>
        </>
      )}
    </>
  );
};
