import React from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { CdRenameFilter } from '@/react/shared/components/Icons';
import { I18nService } from '@/react/services/I18nService';
import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';

const RenameNewsletterButton = ({ id, name, type, setup }) => {
  const showRenameModal = (
    listId: number,
    listName: string,
    type: string,
    setup: object
  ) => {
    NiceModal.show('RenameNewsletterOrFilter', {
      listId,
      listName,
      type,
      setup,
    });
  };
  return (
    <CdTooltip
      placement="left"
      title={I18nService.getString('Edit list')}
      style={{ fontSize: '12px' }}
    >
      <CdRenameFilter onClick={() => showRenameModal(id, name, type, setup)} />
    </CdTooltip>
  );
};

export default RenameNewsletterButton;
