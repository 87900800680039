import React from 'react';
import { Input } from 'antd';

import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import { I18nService } from '@/react/services/I18nService';
import { CdServerSideTable } from '@/react/shared/components/cd-server-side-table/CdServerSideTable';
import { CdDuplicateContactIcon } from '@/react/shared/components/Icons';
import { DuplicateContact } from '@/react/people/duplicate/types/Types';
import { GetDuplicateContactColumns } from '@/react/people/duplicate/components/GetDuplicateContactColumns';
import { getDuplicateContacts } from '@/react/people/duplicate/store/duplicateContacts';

export const DuplicateContactsWrapper = () => {
  const [search, setSearch] = React.useState('');
  const handleSearchUser = (e: any) => {
    setSearch(e.target.value);
  };
  return (
    <CdPage
      pageHeaderProps={{
        title: I18nService.getString(
          'Manage duplicate contacts',
          null,
          'Merge Duplicate Contacts'
        ),
        subTitle: I18nService.getString(
          'View and take action on duplicate contacts.',
          null,
          'Merge Duplicate Contacts'
        ),
      }}
    >
      <CdServerSideTable<DuplicateContact>
        searchParams={{ search }}
        pagedQuery={getDuplicateContacts()}
        columns={GetDuplicateContactColumns()}
        rowKey="id"
        emptyStateProps={{
          EmptyStateIcon: <CdDuplicateContactIcon />,
          title: I18nService.getString(
            'No duplicate contacts found',
            null,
            'Merge Duplicate Contacts'
          ),
        }}
        filterForm={
          <Input.Search
            placeholder={I18nService.getString('Search')}
            allowClear
            onChange={handleSearchUser}
          />
        }
      />
    </CdPage>
  );
};
