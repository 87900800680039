import { EventApi } from '@fullcalendar/core';
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { IntentionsInfo } from './IntentionsInfo';

import { getColorBasedOnColorIndex } from '@/react/calendar/components/event-item/colors';
import { getDuration } from '@/react/calendar/components/event-item/utils';
import { EventType } from '@/react/calendar/models/calendar';
import { I18nService } from '@/react/services/I18nService';
import { CdIntentionsIcon } from '@/react/shared/components/Icons';
import { Resource } from '@/react/shared/models/resource';

const ResourceUl = styled.ul<{
  isHorizontal: boolean;
  count: number;
  currentView: string;
  showIntentions: boolean;
  hasIntentions: boolean;
}>`
  display: flex;
  flex-direction: ${({ isHorizontal }) => (isHorizontal ? 'row' : 'column')};
  margin-bottom: 0px;
  margin-top: 0px;
  padding-left: 0px;
  align-items: flex-start;
  .item {
    display: flex;
    align-items: center;
    max-width: ${(props) =>
      (props.currentView === 'timeGridDay' ||
        props.currentView === 'resourceTimeGridDay') &&
      props.isHorizontal
        ? 'auto'
        : !props.isHorizontal
          ? '100%'
          : props.count === 1
            ? '100%'
            : props.count === 2
              ? '50%'
              : '30%'};
    margin-right: 5px;
    span {
      white-space: nowrap;
      overflow: hidden;
      font-size: 12px;
      font-weight: 400;
    }
  }
`;

const Bullet = styled.div`
  min-width: 3px;
  min-height: 10px;
  display: block;
  border-radius: 2px;
  background: ${(props) => props.color};
  margin-right: 2px;
  @media print {
    background: ${(props) => props.color} !important;
    box-shadow: inset 0 0 0 1px #555 !important;
  }
`;

function isResourcesHorizontal(
  currentView: string,
  event: EventApi,
  duration: number,
  showIntentions: boolean,
  hasIntentions: boolean
) {
  if (currentView === 'dayGridMonth') return true;
  if (
    currentView === 'timeGridDay' ||
    currentView === 'timeGridWeek' ||
    currentView === 'resourceTimeGridDay'
  ) {
    if (event.allDay) return true;
    if (duration >= 0.5 && duration <= 1) return true;
    if (duration <= 1.5 && showIntentions && hasIntentions) return true;
    if (duration > 1) return false;
  }
  return true;
}

function getResourcesToRender(
  currentView: string,
  event: EventApi,
  duration: number,
  resources: Resource[],
  isResourceMoreThanThree: boolean,
  titleHeight: number,
  isResourceHorizontal: boolean,
  mustShowIntention: boolean
) {
  if (currentView === 'dayGridMonth') {
    return resources.slice(0, isResourceMoreThanThree ? 2 : 3);
  }
  if (
    currentView === 'timeGridDay' ||
    currentView === 'timeGridWeek' ||
    currentView === 'resourceTimeGridDay'
  ) {
    if (event.allDay) {
      return resources.slice(0, isResourceMoreThanThree ? 2 : 3);
    }
    if (duration > 0.5 && duration <= 1) {
      return resources.slice(0, isResourceMoreThanThree ? 2 : 3);
    }
    if (isResourceHorizontal) {
      return resources.slice(0, isResourceMoreThanThree ? 2 : 3);
    }
    if (duration > 1) {
      let maxResourcesToShow = (27 * 2 * duration - (titleHeight + 15)) / 15;
      if (mustShowIntention) maxResourcesToShow--;
      return maxResourcesToShow < resources.length
        ? resources.slice(0, maxResourcesToShow)
        : resources;
    }
  }
  return [];
}

type ExtraInfoProps = {
  event: EventApi;
  currentView: string;
  showResources?: boolean;
  showIntentions?: boolean;
  hasIntentions?: boolean;
  titleHeight?: number;
  isResizing?: boolean;
  resources?: Resource[];
};

export const ExtraInfo = ({
  event,
  currentView,
  showResources,
  showIntentions,
  resources,
  titleHeight,
  isResizing,
  hasIntentions,
}: ExtraInfoProps) => {
  const duration = getDuration(event, isResizing);
  const noOfIntentions = _.get(event, 'extendedProps.noOfIntentions');
  const isResourceHorizontal = isResourcesHorizontal(
    currentView,
    event,
    duration,
    showIntentions,
    hasIntentions
  );
  const isResourceMOreThanThree = resources.length > 3;
  const resourcesToRender = getResourcesToRender(
    currentView,
    event,
    duration,
    resources,
    isResourceMOreThanThree,
    titleHeight,
    isResourceHorizontal,
    showIntentions && hasIntentions
  );

  // Remove a resource if vertical display to make room for intentions
  if (
    hasIntentions &&
    showIntentions &&
    !isResourceHorizontal &&
    resources.length > resourcesToRender.length
  ) {
    resourcesToRender.pop();
  }
  return (
    <div className="fc-extra-content">
      {showResources &&
        resources &&
        resources.length > 0 &&
        !_.isEmpty(resources) && (
          <ResourceUl
            isHorizontal={isResourceHorizontal}
            count={resources.length}
            currentView={currentView}
            showIntentions={showIntentions}
            hasIntentions={hasIntentions}
          >
            {event.allDay && noOfIntentions > 0 && (
              <li>
                <div className="resource-line-intentions">
                  <CdIntentionsIcon size="xs" />
                  {1}
                </div>
              </li>
            )}
            {resourcesToRender.map((resource) => (
              <li className="item" key={resource.id + 'key'}>
                {event.extendedProps.type !== EventType.Availability && (
                  <Bullet
                    title={resource.name}
                    color={
                      event.extendedProps.isPast
                        ? getColorBasedOnColorIndex(resource.color).mainLight
                        : getColorBasedOnColorIndex(resource.color).main
                    }
                  />
                )}
                <span title={resource.name}>{resource.name}</span>
              </li>
            ))}
            {resources.length > resourcesToRender.length ? (
              <li className="item">
                <span>+ {resources.length - resourcesToRender.length}</span>
                {I18nService.getString('more')}
              </li>
            ) : null}
          </ResourceUl>
        )}
      {/* Intention details */}
      {showIntentions && hasIntentions && <IntentionsInfo event={event} />}
    </div>
  );
};
