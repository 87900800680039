/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChurchMiscellaneousInformationResponseBody } from '../models/ChurchMiscellaneousInformationResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class InfoService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * ChurchMiscellaneousInformation
     * Information
     * @returns ChurchMiscellaneousInformationResponseBody Success-Response: Image Exists
     * @throws ApiError
     */
    public getInfo({
        partnerToken,
        imageFormat,
    }: {
        /**
         * Partner API identifier.
         */
        partnerToken: any,
        /**
         * The list of formats you want to generate for the image. `Default: span4_16-9`
         */
        imageFormat: any,
    }): CancelablePromise<ChurchMiscellaneousInformationResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/info',
            query: {
                'partnerToken': partnerToken,
                'imageFormat': imageFormat,
            },
        });
    }
}
