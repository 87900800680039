/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import { AclService } from './services/AclService';
import { AltarService } from './services/AltarService';
import { AnalyticsService } from './services/AnalyticsService';
import { AttendanceService } from './services/AttendanceService';
import { AuthenticationService } from './services/AuthenticationService';
import { BlogsService } from './services/BlogsService';
import { BookingsService } from './services/BookingsService';
import { CalendarService } from './services/CalendarService';
import { CalendarPosterService } from './services/CalendarPosterService';
import { ChurchEventsService } from './services/ChurchEventsService';
import { ChurchscreenService } from './services/ChurchscreenService';
import { CollaborationService } from './services/CollaborationService';
import { CollectionCalendarService } from './services/CollectionCalendarService';
import { CommentService } from './services/CommentService';
import { ConsentsService } from './services/ConsentsService';
import { ContributionsService } from './services/ContributionsService';
import { CrispService } from './services/CrispService';
import { DefaultService } from './services/DefaultService';
import { DenominationsService } from './services/DenominationsService';
import { DevicesService } from './services/DevicesService';
import { DictionaryService } from './services/DictionaryService';
import { DiocesesService } from './services/DiocesesService';
import { DuplicatesService } from './services/DuplicatesService';
import { EventsService } from './services/EventsService';
import { ExternalDataImporterService } from './services/ExternalDataImporterService';
import { FilesService } from './services/FilesService';
import { FileSystemService } from './services/FileSystemService';
import { FoldersService } from './services/FoldersService';
import { GalleriesService } from './services/GalleriesService';
import { GroupsService } from './services/GroupsService';
import { ICalService } from './services/ICalService';
import { IndexService } from './services/IndexService';
import { InfoService } from './services/InfoService';
import { IntentionService } from './services/IntentionService';
import { LegalDocumentService } from './services/LegalDocumentService';
import { LinkitService } from './services/LinkitService';
import { MessagesService } from './services/MessagesService';
import { NotificationsService } from './services/NotificationsService';
import { OrganizationService } from './services/OrganizationService';
import { OrganizationsService } from './services/OrganizationsService';
import { ParseService } from './services/ParseService';
import { PartnerFilesService } from './services/PartnerFilesService';
import { PartnersService } from './services/PartnersService';
import { PaymentService } from './services/PaymentService';
import { PaymentsService } from './services/PaymentsService';
import { PeopleService } from './services/PeopleService';
import { PeopleChurchSettingsService } from './services/PeopleChurchSettingsService';
import { PeopleImportService } from './services/PeopleImportService';
import { PeopleMessageTemplateService } from './services/PeopleMessageTemplateService';
import { PeoplePublicService } from './services/PeoplePublicService';
import { PlansService } from './services/PlansService';
import { PortalService } from './services/PortalService';
import { ProductMetricsService } from './services/ProductMetricsService';
import { QueueService } from './services/QueueService';
import { ResourcesService } from './services/ResourcesService';
import { RevisionsService } from './services/RevisionsService';
import { RolesService } from './services/RolesService';
import { RoomsService } from './services/RoomsService';
import { RotasService } from './services/RotasService';
import { SafeguardService } from './services/SafeguardService';
import { SlideshowService } from './services/SlideshowService';
import { SogndkService } from './services/SogndkService';
import { StatisticsService } from './services/StatisticsService';
import { StripeService } from './services/StripeService';
import { TasksService } from './services/TasksService';
import { TaxonomiesService } from './services/TaxonomiesService';
import { TerminalService } from './services/TerminalService';
import { TimeRegistrationService } from './services/TimeRegistrationService';
import { TransfersService } from './services/TransfersService';
import { UsersService } from './services/UsersService';
import { WatermelondbService } from './services/WatermelondbService';
import { WebhookService } from './services/WebhookService';
import { WebsiteService } from './services/WebsiteService';
import { WorkplanService } from './services/WorkplanService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class BackendApiClient {
    public readonly acl: AclService;
    public readonly altar: AltarService;
    public readonly analytics: AnalyticsService;
    public readonly attendance: AttendanceService;
    public readonly authentication: AuthenticationService;
    public readonly blogs: BlogsService;
    public readonly bookings: BookingsService;
    public readonly calendar: CalendarService;
    public readonly calendarPoster: CalendarPosterService;
    public readonly churchEvents: ChurchEventsService;
    public readonly churchscreen: ChurchscreenService;
    public readonly collaboration: CollaborationService;
    public readonly collectionCalendar: CollectionCalendarService;
    public readonly comment: CommentService;
    public readonly consents: ConsentsService;
    public readonly contributions: ContributionsService;
    public readonly crisp: CrispService;
    public readonly default: DefaultService;
    public readonly denominations: DenominationsService;
    public readonly devices: DevicesService;
    public readonly dictionary: DictionaryService;
    public readonly dioceses: DiocesesService;
    public readonly duplicates: DuplicatesService;
    public readonly events: EventsService;
    public readonly externalDataImporter: ExternalDataImporterService;
    public readonly files: FilesService;
    public readonly fileSystem: FileSystemService;
    public readonly folders: FoldersService;
    public readonly galleries: GalleriesService;
    public readonly groups: GroupsService;
    public readonly iCal: ICalService;
    public readonly index: IndexService;
    public readonly info: InfoService;
    public readonly intention: IntentionService;
    public readonly legalDocument: LegalDocumentService;
    public readonly linkit: LinkitService;
    public readonly messages: MessagesService;
    public readonly notifications: NotificationsService;
    public readonly organization: OrganizationService;
    public readonly organizations: OrganizationsService;
    public readonly parse: ParseService;
    public readonly partnerFiles: PartnerFilesService;
    public readonly partners: PartnersService;
    public readonly payment: PaymentService;
    public readonly payments: PaymentsService;
    public readonly people: PeopleService;
    public readonly peopleChurchSettings: PeopleChurchSettingsService;
    public readonly peopleImport: PeopleImportService;
    public readonly peopleMessageTemplate: PeopleMessageTemplateService;
    public readonly peoplePublic: PeoplePublicService;
    public readonly plans: PlansService;
    public readonly portal: PortalService;
    public readonly productMetrics: ProductMetricsService;
    public readonly queue: QueueService;
    public readonly resources: ResourcesService;
    public readonly revisions: RevisionsService;
    public readonly roles: RolesService;
    public readonly rooms: RoomsService;
    public readonly rotas: RotasService;
    public readonly safeguard: SafeguardService;
    public readonly slideshow: SlideshowService;
    public readonly sogndk: SogndkService;
    public readonly statistics: StatisticsService;
    public readonly stripe: StripeService;
    public readonly tasks: TasksService;
    public readonly taxonomies: TaxonomiesService;
    public readonly terminal: TerminalService;
    public readonly timeRegistration: TimeRegistrationService;
    public readonly transfers: TransfersService;
    public readonly users: UsersService;
    public readonly watermelondb: WatermelondbService;
    public readonly webhook: WebhookService;
    public readonly website: WebsiteService;
    public readonly workplan: WorkplanService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.acl = new AclService(this.request);
        this.altar = new AltarService(this.request);
        this.analytics = new AnalyticsService(this.request);
        this.attendance = new AttendanceService(this.request);
        this.authentication = new AuthenticationService(this.request);
        this.blogs = new BlogsService(this.request);
        this.bookings = new BookingsService(this.request);
        this.calendar = new CalendarService(this.request);
        this.calendarPoster = new CalendarPosterService(this.request);
        this.churchEvents = new ChurchEventsService(this.request);
        this.churchscreen = new ChurchscreenService(this.request);
        this.collaboration = new CollaborationService(this.request);
        this.collectionCalendar = new CollectionCalendarService(this.request);
        this.comment = new CommentService(this.request);
        this.consents = new ConsentsService(this.request);
        this.contributions = new ContributionsService(this.request);
        this.crisp = new CrispService(this.request);
        this.default = new DefaultService(this.request);
        this.denominations = new DenominationsService(this.request);
        this.devices = new DevicesService(this.request);
        this.dictionary = new DictionaryService(this.request);
        this.dioceses = new DiocesesService(this.request);
        this.duplicates = new DuplicatesService(this.request);
        this.events = new EventsService(this.request);
        this.externalDataImporter = new ExternalDataImporterService(this.request);
        this.files = new FilesService(this.request);
        this.fileSystem = new FileSystemService(this.request);
        this.folders = new FoldersService(this.request);
        this.galleries = new GalleriesService(this.request);
        this.groups = new GroupsService(this.request);
        this.iCal = new ICalService(this.request);
        this.index = new IndexService(this.request);
        this.info = new InfoService(this.request);
        this.intention = new IntentionService(this.request);
        this.legalDocument = new LegalDocumentService(this.request);
        this.linkit = new LinkitService(this.request);
        this.messages = new MessagesService(this.request);
        this.notifications = new NotificationsService(this.request);
        this.organization = new OrganizationService(this.request);
        this.organizations = new OrganizationsService(this.request);
        this.parse = new ParseService(this.request);
        this.partnerFiles = new PartnerFilesService(this.request);
        this.partners = new PartnersService(this.request);
        this.payment = new PaymentService(this.request);
        this.payments = new PaymentsService(this.request);
        this.people = new PeopleService(this.request);
        this.peopleChurchSettings = new PeopleChurchSettingsService(this.request);
        this.peopleImport = new PeopleImportService(this.request);
        this.peopleMessageTemplate = new PeopleMessageTemplateService(this.request);
        this.peoplePublic = new PeoplePublicService(this.request);
        this.plans = new PlansService(this.request);
        this.portal = new PortalService(this.request);
        this.productMetrics = new ProductMetricsService(this.request);
        this.queue = new QueueService(this.request);
        this.resources = new ResourcesService(this.request);
        this.revisions = new RevisionsService(this.request);
        this.roles = new RolesService(this.request);
        this.rooms = new RoomsService(this.request);
        this.rotas = new RotasService(this.request);
        this.safeguard = new SafeguardService(this.request);
        this.slideshow = new SlideshowService(this.request);
        this.sogndk = new SogndkService(this.request);
        this.statistics = new StatisticsService(this.request);
        this.stripe = new StripeService(this.request);
        this.tasks = new TasksService(this.request);
        this.taxonomies = new TaxonomiesService(this.request);
        this.terminal = new TerminalService(this.request);
        this.timeRegistration = new TimeRegistrationService(this.request);
        this.transfers = new TransfersService(this.request);
        this.users = new UsersService(this.request);
        this.watermelondb = new WatermelondbService(this.request);
        this.webhook = new WebhookService(this.request);
        this.website = new WebsiteService(this.request);
        this.workplan = new WorkplanService(this.request);
    }
}

