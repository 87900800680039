import { ApiResponse } from 'apisauce';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { FormListSearchCache } from '../store/form';
import { FormFromSearch } from '../types';

import { formsApi } from '@/react/api';
import { SelectedOrganization } from '@/react/organization/store/organization';
import { handleError } from '@/react/services/ErrorHandlingService';
import { navigate } from '@/react/services/StateServiceFactory';
import { I18nService } from '@/react/services/I18nService';

export default function useCdForm() {
  const organization = useRecoilValue(SelectedOrganization);

  const refreshFormListSearch = useRecoilCallback(
    ({ set }) =>
      async () => {
        set(FormListSearchCache, (value) => value + 1);
      },
    []
  );

  const update = useRecoilCallback(
    () => async (form: FormFromSearch) => {
      const { ok, data } = await formsApi.put<FormFromSearch>(
        `/forms/${form.id}`,
        form
      );
      if (ok) {
        refreshFormListSearch();
      } else {
        handleError(data as unknown as ApiResponse<any>);
      }
    },
    [refreshFormListSearch]
  );

  const duplicate = useRecoilCallback(
    () => async (form: FormFromSearch) => {
      const { ok, data } = await formsApi.post(`/forms/${form.id}/duplicate`, {
        language: organization.locale.language,
        countryIso: organization.countryIso2,
        title: I18nService.getString('{{formTitle}} (copy)', {
          formTitle: form.title,
        }),
      });

      if (ok) {
        navigate('app.private.forms.view', data);
        refreshFormListSearch();
      } else {
        handleError(data as unknown as ApiResponse<any>);
      }
    },
    [organization, refreshFormListSearch]
  );

  const close = useRecoilCallback(
    () => async (form: FormFromSearch) => {
      const { ok, data } = await formsApi.put(`/forms/${form.id}`, {
        ...form,
        churches: form.churches.map(({ id }) => id),
        active: false,
      });

      if (ok) {
        refreshFormListSearch();
      } else {
        handleError(data as unknown as ApiResponse<any>);
      }
    },
    [refreshFormListSearch]
  );

  const open = useRecoilCallback(
    () => async (form: FormFromSearch) => {
      const { ok, data } = await formsApi.put(`/forms/${form.id}`, {
        ...form,
        churches: form.churches.map(({ id }) => id),
        active: true,
      });

      if (ok) {
        refreshFormListSearch();
      } else {
        handleError(data as unknown as ApiResponse<any>);
      }
    },
    [refreshFormListSearch]
  );

  const deleteForm = useRecoilCallback(
    () => async (form: FormFromSearch) => {
      const { ok, data } = await formsApi.delete(`/forms/${form.id}`);

      if (ok) {
        refreshFormListSearch();
      } else {
        handleError(data as unknown as ApiResponse<any>);
      }
    },
    [refreshFormListSearch]
  );

  return { update, duplicate, close, open, deleteForm };
}
