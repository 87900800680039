/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostPartnersResponseBody = {
    id: number;
    name: string;
    machineName: string;
    uri: string;
    categoryUrl: string;
    visibility: PostPartnersResponseBody.visibility;
    pushFromAll: boolean;
    country: string;
    token: string;
    createdAt: string;
    updatedAt: string;
};
export namespace PostPartnersResponseBody {
    export enum visibility {
        BOTH = 'both',
        GROUP = 'group',
        WEB = 'web',
    }
}

