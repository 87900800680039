/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PutPeoplePeopleTagsRequestBody = {
    people?: Array<number>;
    filter?: {
        churchIds?: Array<number>;
        comparison?: PutPeoplePeopleTagsRequestBody.comparison;
        filters?: Array<any>;
    };
    tags?: {
        add?: Array<number>;
        remove?: Array<number>;
    };
};
export namespace PutPeoplePeopleTagsRequestBody {
    export enum comparison {
        AND = 'AND',
        OR = 'OR',
    }
}

