import {
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
} from 'recoil';

import {
  GetEventInvitations,
  GetEventsPosterAndInvitations,
} from '../store/invitations';

import { mainApi } from '@/react/api';
import NotificationService from '@/react/services/NotificationService';
import { I18nService } from '@/react/services/I18nService';

export const useEventPosterAndInvitations = (eventId) => {
  const invitationsRefresher = useRecoilRefresher(
    GetEventsPosterAndInvitations({ calendarId: eventId })
  );
  const EventInvitationsSource = GetEventInvitations({ eventId });

  const deleteInvitation = useRecoilCallback(
    () => async (id: number) => {
      const response = await mainApi.delete(`/v2/people/messages/${id}`);
      if (!response.ok) {
        NotificationService.notifyError((response as any)?.data?.message);
        throw response;
      } else {
        NotificationService.notifySuccess(
          I18nService.getString('Invitation deleted successfully')
        );
        invitationsRefresher();
        return response.data;
      }
    },
    [invitationsRefresher]
  );
  return {
    deleteInvitation,
    EventInvitationsSource,
  };
};
