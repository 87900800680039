/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { _GetAConsentTypeResponseBody } from '../models/_GetAConsentTypeResponseBody';
import type { _GetDefaultConsentTypeResponseBody } from '../models/_GetDefaultConsentTypeResponseBody';
import type { EmptyObject } from '../models/EmptyObject';
import type { GetAllConsentTypesResponseBody } from '../models/GetAllConsentTypesResponseBody';
import type { GetCalendarFormsSearchResponseBody } from '../models/GetCalendarFormsSearchResponseBody';
import type { GetPeopleOccupationsResponseBody } from '../models/GetPeopleOccupationsResponseBody';
import type { GetPeoplePeopleAnniversariesNotifyusersResponseBody } from '../models/GetPeoplePeopleAnniversariesNotifyusersResponseBody';
import type { GetPeoplePeopleAnniversariesResponseBody } from '../models/GetPeoplePeopleAnniversariesResponseBody';
import type { GetPeoplePeopleAnniversariesSettingsResponseBody } from '../models/GetPeoplePeopleAnniversariesSettingsResponseBody';
import type { GetPeopleResponseBody } from '../models/GetPeopleResponseBody';
import type { GetSingleMessageReportResponseBody } from '../models/GetSingleMessageReportResponseBody';
import type { getSubscriptionsDataResponseBody } from '../models/getSubscriptionsDataResponseBody';
import type { PeopleUpdateMessageRequestBody } from '../models/PeopleUpdateMessageRequestBody';
import type { PostPeopleConsenttypesRequestBody } from '../models/PostPeopleConsenttypesRequestBody';
import type { PostPeopleExportDataRequestBody } from '../models/PostPeopleExportDataRequestBody';
import type { PostPeopleMessagesCalculateRecipientsRequestBody } from '../models/PostPeopleMessagesCalculateRecipientsRequestBody';
import type { PostPeopleMessagesRequestBody } from '../models/PostPeopleMessagesRequestBody';
import type { PostPeoplePastoralnotesRequestBody } from '../models/PostPeoplePastoralnotesRequestBody';
import type { PostPeoplePeopleAnniversariesWeb2printRequestBody } from '../models/PostPeoplePeopleAnniversariesWeb2printRequestBody';
import type { PostPeoplePeopleCheckconflictingpeopleRequestBody } from '../models/PostPeoplePeopleCheckconflictingpeopleRequestBody';
import type { PostPeoplePeopleDeleteRequestBody } from '../models/PostPeoplePeopleDeleteRequestBody';
import type { PostPeoplePeopleDeleteResponseBody } from '../models/PostPeoplePeopleDeleteResponseBody';
import type { PostPeoplePeopleFieldsSensitiveRequestBody } from '../models/PostPeoplePeopleFieldsSensitiveRequestBody';
import type { PostPeoplePeopleManagesubscriptionsRequestBody } from '../models/PostPeoplePeopleManagesubscriptionsRequestBody';
import type { PostPeoplePeopleSearchRequestBody } from '../models/PostPeoplePeopleSearchRequestBody';
import type { PostPeoplePeopleSubscribeRequestBody } from '../models/PostPeoplePeopleSubscribeRequestBody';
import type { PostPeoplePeopleUnsubscribeRequestBody } from '../models/PostPeoplePeopleUnsubscribeRequestBody';
import type { PostPeopleSegmentsAddpeopleRequestBody } from '../models/PostPeopleSegmentsAddpeopleRequestBody';
import type { PostPeopleSegmentsNewsletterlistsSubscribeRequestBody } from '../models/PostPeopleSegmentsNewsletterlistsSubscribeRequestBody';
import type { PostPeopleSegmentsRequestBody } from '../models/PostPeopleSegmentsRequestBody';
import type { PostPeopleTagsRequestBody } from '../models/PostPeopleTagsRequestBody';
import type { PutPeopleConsenttypesRequestBody } from '../models/PutPeopleConsenttypesRequestBody';
import type { PutPeoplePeopleChurchesRequestBody } from '../models/PutPeoplePeopleChurchesRequestBody';
import type { PutPeoplePeoplePeoplelistsRequestBody } from '../models/PutPeoplePeoplePeoplelistsRequestBody';
import type { PutPeoplePeopleResubscribeRequestBody } from '../models/PutPeoplePeopleResubscribeRequestBody';
import type { PutPeoplePeopleTagsRequestBody } from '../models/PutPeoplePeopleTagsRequestBody';
import type { PutPeopleSegmentsRequestBody } from '../models/PutPeopleSegmentsRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PeopleService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * CreateConsentType
     * Create Consent Type
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeopleConsentTypes({
        requestBody,
    }: {
        requestBody?: PostPeopleConsenttypesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/consent-types',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetAllConsentTypes
     * Consent Types Index
     * @returns GetAllConsentTypesResponseBody
     * @throws ApiError
     */
    public getPeopleConsentTypes(): CancelablePromise<GetAllConsentTypesResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/consent-types',
        });
    }
    /**
     * :_GetDefaultConsentType
     * Get default consent type from demo content
     * @returns _GetDefaultConsentTypeResponseBody
     * @throws ApiError
     */
    public getPeopleConsentTypesDefault(): CancelablePromise<_GetDefaultConsentTypeResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/consent-types/default',
        });
    }
    /**
     * GetConsentTypesInternal
     * Consent Types Index
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeopleConsentTypesInternalList({
        apiKey,
    }: {
        apiKey: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/consent-types/internal/list',
            query: {
                'apiKey': apiKey,
            },
        });
    }
    /**
     * DeleteConsentType
     * Delete Consent Type
     * @returns EmptyObject
     * @throws ApiError
     */
    public deletePeopleConsentTypes(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/people/consent-types/{id}',
        });
    }
    /**
     * UpdateConsentType
     * Update Consent Type
     * @returns EmptyObject
     * @throws ApiError
     */
    public putPeopleConsentTypes({
        requestBody,
    }: {
        requestBody?: PutPeopleConsenttypesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/people/consent-types/{id}',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * :_GetAConsentType
     * Get consent type
     * @returns _GetAConsentTypeResponseBody
     * @throws ApiError
     */
    public getPeopleConsentTypesPublic({
        id,
    }: {
        id: string,
    }): CancelablePromise<_GetAConsentTypeResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/consent-types/{id}/public',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetPeopleIndexInCSVFormat
     * Start export of people to CSV
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeopleExportData({
        accessToken,
        requestBody,
    }: {
        accessToken?: string,
        requestBody?: PostPeopleExportDataRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/export/data',
            query: {
                'access_token': accessToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * PeopleDataExportToken
     * Get People Data Export Token
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeopleExportToken(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/export/token',
        });
    }
    /**
     * GetPeopleExportIdCheck
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeopleExportCheck({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/export/{id}/check',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetPeopleExportIdDownload
     * Download file
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeopleExportDownload({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/export/{id}/download',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetAllMessages
     * MESSAGES INDEX
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeopleMessages({
        search,
        limit = 10,
        offset,
        status,
        churchIds,
    }: {
        search?: string,
        limit?: number,
        offset?: number,
        status?: 'sent' | 'scheduled' | 'draft',
        churchIds?: Array<number>,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/messages',
            query: {
                'search': search,
                'limit': limit,
                'offset': offset,
                'status': status,
                'churchIds': churchIds,
            },
        });
    }
    /**
     * PeopleCreateMessage
     * CREATE MESSAGE
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeopleMessages({
        requestBody,
    }: {
        requestBody?: PostPeopleMessagesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/messages',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * CalculateMessageRecipients
     * Calculate number of recipients
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeopleMessagesCalculateRecipients({
        requestBody,
    }: {
        requestBody?: PostPeopleMessagesCalculateRecipientsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/messages/calculate/recipients',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetPeopleMessagesHealth
     * GET Messages Health
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeopleMessagesHealth({
        newerThanIntervalInMinutes = 180,
        olderThanIntervalInMinutes = 30,
    }: {
        newerThanIntervalInMinutes?: number,
        olderThanIntervalInMinutes?: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/messages/health',
            query: {
                'newerThanIntervalInMinutes': newerThanIntervalInMinutes,
                'olderThanIntervalInMinutes': olderThanIntervalInMinutes,
            },
        });
    }
    /**
     * GetPeopleMessagesMigrationEntitytypeMethodChurches
     * Migrate churches for given entities
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeopleMessagesMigrationChurches({
        apiKey,
        entityType,
        method,
    }: {
        apiKey: string,
        entityType: 'people-messages' | 'forms' | 'contributions' | 'drupal',
        method: 'add' | 'remove' | 'list',
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/messages/migration/{entityType}/{method}/churches',
            path: {
                'entityType': entityType,
                'method': method,
            },
            query: {
                'apiKey': apiKey,
            },
        });
    }
    /**
     * SendTestMessage
     * SEND TEST MESSAGE
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeopleMessagesTest({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/messages/test',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeleteMessage
     * DELETE MESSAGE
     * @returns EmptyObject
     * @throws ApiError
     */
    public deletePeopleMessages({
        id,
    }: {
        id: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/people/messages/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetSingleMessage
     * GET MESSAGE
     * @returns GetCalendarFormsSearchResponseBody
     * @throws ApiError
     */
    public getPeopleMessages1(): CancelablePromise<GetCalendarFormsSearchResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/messages/{id}',
        });
    }
    /**
     * PeopleUpdateMessage
     * UPDATE MESSAGE
     * @returns EmptyObject
     * @throws ApiError
     */
    public putPeopleMessages({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PostPeopleMessagesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/people/messages/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetSingleMessageReport
     * GET MESSAGE REPORT
     * @returns GetSingleMessageReportResponseBody
     * @throws ApiError
     */
    public getPeopleMessagesReport(): CancelablePromise<GetSingleMessageReportResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/messages/{id}/report',
        });
    }
    /**
     * GetPeopleOccupations
     * Occupations
     * @returns GetPeopleOccupationsResponseBody
     * @throws ApiError
     */
    public getPeopleOccupations(): CancelablePromise<GetPeopleOccupationsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/occupations',
        });
    }
    /**
     * GetPeoplePastoralNotes
     * Pastoral-Notes Index
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeoplePastoralNotes({
        limit = 20,
        offset,
        orderBy = 'createdAt',
        orderDirection = 'DESC',
        search,
        mode,
    }: {
        limit?: number,
        offset?: number,
        orderBy?: 'createdAt' | 'updatedAt' | 'firstName',
        orderDirection?: 'DESC' | 'ASC',
        search?: string,
        mode?: ('my-notes' | 'all-notes' | 'my-notes'),
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/pastoral-notes',
            query: {
                'limit': limit,
                'offset': offset,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
                'search': search,
                'mode': mode,
            },
        });
    }
    /**
     * CreatePeople
     * PEOPLE CREATE
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeoplePeople({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/people',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retrieve a list of anniversaries
     * Use this endpoint to retrieve a list of anniversaries.
     * @returns GetPeoplePeopleAnniversariesResponseBody
     * @throws ApiError
     */
    public getPeoplePeopleAnniversaries({
        filters = {},
    }: {
        filters?: {
            anniversaryType?: string;
            id?: Array<string>;
            startDate?: string;
            endDate?: string;
            ageMin?: number;
            ageMax?: number;
        },
    }): CancelablePromise<GetPeoplePeopleAnniversariesResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/people/anniversaries',
            query: {
                'filters': filters,
            },
        });
    }
    /**
     * Retrieves a list of users that can access to Contact and be notify for anniversaries.
     * @returns GetPeoplePeopleAnniversariesNotifyusersResponseBody
     * @throws ApiError
     */
    public getPeoplePeopleAnniversariesNotifyUsers({
        parishIds,
    }: {
        parishIds?: Array<number>,
    }): CancelablePromise<GetPeoplePeopleAnniversariesNotifyusersResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/people/anniversaries/notify-users',
            query: {
                'parishIds': parishIds,
            },
        });
    }
    /**
     * Retrieve settings for anniversaries
     * Use this endpoint to retrieve the settings of anniversaries.
     * @returns GetPeoplePeopleAnniversariesSettingsResponseBody
     * @throws ApiError
     */
    public getPeoplePeopleAnniversariesSettings({
        includeAllAnniversariesField = 'false',
    }: {
        includeAllAnniversariesField?: string,
    }): CancelablePromise<GetPeoplePeopleAnniversariesSettingsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/people/anniversaries/settings',
            query: {
                'includeAllAnniversariesField': includeAllAnniversariesField,
            },
        });
    }
    /**
     * Get OKR Web2Print export
     * @throws ApiError
     */
    public postPeoplePeopleAnniversariesWeb2Print({
        exportToken,
        requestBody,
    }: {
        exportToken: string,
        requestBody?: PostPeoplePeopleAnniversariesWeb2printRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/people/anniversaries/web2print',
            query: {
                'exportToken': exportToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * PostPeoplePeopleCheckConflictingPeople
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeoplePeopleCheckConflictingPeople({
        requestBody,
    }: {
        requestBody?: PostPeoplePeopleCheckconflictingpeopleRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/people/check-conflicting-people',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * BulkAddChurches
     * MULTIPLE PEOPLE CHURCH UPDATE
     * @returns EmptyObject
     * @throws ApiError
     */
    public putPeoplePeopleChurches({
        accessToken,
        requestBody,
    }: {
        accessToken?: string,
        requestBody?: PutPeoplePeopleChurchesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/people/people/churches',
            query: {
                'access_token': accessToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * PostPeoplePeopleDelete
     * @returns PostPeoplePeopleDeleteResponseBody
     * @throws ApiError
     */
    public postPeoplePeopleDelete({
        accessToken,
        requestBody,
    }: {
        accessToken?: string,
        requestBody?: PostPeoplePeopleDeleteRequestBody,
    }): CancelablePromise<PostPeoplePeopleDeleteResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/people/delete',
            query: {
                'access_token': accessToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetPeoplePeopleExportId
     * GDPR Export
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeoplePeopleExport({
        exportToken,
        id,
    }: {
        exportToken: string,
        id: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/people/export/{id}',
            path: {
                'id': id,
            },
            query: {
                'exportToken': exportToken,
            },
        });
    }
    /**
     * GetPeoplePeopleFields
     * @returns GetCalendarFormsSearchResponseBody
     * @throws ApiError
     */
    public getPeoplePeopleFields(): CancelablePromise<GetCalendarFormsSearchResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/people/fields',
        });
    }
    /**
     * PostPeoplePeopleFieldsSensitive
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeoplePeopleFieldsSensitive({
        requestBody,
    }: {
        requestBody?: PostPeoplePeopleFieldsSensitiveRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/people/fields/sensitive',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetPeoplePeopleFilters
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeoplePeopleFilters(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/people/filters',
        });
    }
    /**
     * GetCustomLifeEventFields
     * GET CUSTOM LIFE EVENT FIELDS
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeoplePeopleLifeEventFields(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/people/life-event-fields',
        });
    }
    /**
     * getSubscriptionsData
     * GET SUBSCRIPTIONS DATA
     * @returns getSubscriptionsDataResponseBody
     * @throws ApiError
     */
    public getPeoplePeopleManageSubscriptions({
        token,
    }: {
        token: string,
    }): CancelablePromise<getSubscriptionsDataResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/people/manage-subscriptions',
            query: {
                'token': token,
            },
        });
    }
    /**
     * manageSubscriptions
     * MANAGE SUBSCRIPTIONS
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeoplePeopleManageSubscriptions({
        token,
        requestBody,
    }: {
        token: string,
        requestBody?: PostPeoplePeopleManagesubscriptionsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/people/manage-subscriptions',
            query: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * PeopleListsUpdate
     * MULTIPLE PEOPLE LISTS UPDATE
     * @returns EmptyObject
     * @throws ApiError
     */
    public putPeoplePeoplePeopleLists({
        requestBody,
    }: {
        requestBody?: PutPeoplePeoplePeoplelistsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/people/people/people-lists',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * searchPeople
     * PEOPLE SEARCH
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeoplePeopleSearch({
        requestBody,
    }: {
        requestBody?: PostPeoplePeopleSearchRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/people/search',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetPeoplePeopleStaticFields
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeoplePeopleStaticFields(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/people/static/fields',
        });
    }
    /**
     * SubscribeToPeople
     * PEOPLE SUBSCRIBE
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeoplePeopleSubscribe({
        requestBody,
    }: {
        requestBody?: PostPeoplePeopleSubscribeRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/people/subscribe',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * TagsUpdate
     * MULTIPLE PEOPLE TAGS UPDATE
     * @returns EmptyObject
     * @throws ApiError
     */
    public putPeoplePeopleTags({
        accessToken,
        requestBody,
    }: {
        accessToken?: string,
        requestBody?: PutPeoplePeopleTagsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/people/people/tags',
            query: {
                'access_token': accessToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * PeopleUploadPicture
     * UPLOAD PICTURE
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeoplePeopleUpload({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/people/upload',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * CreateUpdate
     * PEOPLE UPDATE
     * @returns EmptyObject
     * @throws ApiError
     */
    public putPeoplePeople({
        id,
        accessToken,
        requestBody,
    }: {
        id: number,
        accessToken?: string,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/people/people/{id}',
            path: {
                'id': id,
            },
            query: {
                'access_token': accessToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeletePeople
     * PEOPLE DELETE
     * @returns EmptyObject
     * @throws ApiError
     */
    public deletePeoplePeople({
        id,
    }: {
        id: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/people/people/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetPeople
     * GET PEOPLE
     * @returns GetPeopleResponseBody
     * @throws ApiError
     */
    public getPeoplePeople({
        id,
    }: {
        id: number,
    }): CancelablePromise<GetPeopleResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/people/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetActivityPerPeople
     * PEOPLE ACTIVITY
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeoplePeopleActivities({
        id,
        limit = 20,
        offset,
    }: {
        id: number,
        limit?: number,
        offset?: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/people/{id}/activities',
            path: {
                'id': id,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * PeopleConfirmSignup
     * Confirm sign up to people module
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeoplePeopleConfirm({
        accessToken,
        id,
        requestBody,
    }: {
        accessToken?: string,
        id?: number,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/people/{id}/confirm',
            path: {
                'id': id,
            },
            query: {
                'access_token': accessToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetPeoplePeopleIdContactsSharingEmail
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeoplePeopleContactsSharingEmail({
        messageId,
        outsideAccess,
        id,
    }: {
        messageId: number,
        outsideAccess: string,
        id: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/people/{id}/contacts-sharing-email',
            path: {
                'id': id,
            },
            query: {
                'messageId': messageId,
                'outsideAccess': outsideAccess,
            },
        });
    }
    /**
     * CreateUpdateExtend
     * PEOPLE UPDATE EXTEND Only
     * @returns EmptyObject
     * @throws ApiError
     */
    public putPeoplePeopleExtend({
        id,
        accessToken,
        extendOnDuplicate = false,
        requestBody,
    }: {
        id: number,
        accessToken?: string,
        extendOnDuplicate?: boolean,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/people/people/{id}/extend',
            path: {
                'id': id,
            },
            query: {
                'access_token': accessToken,
                'extendOnDuplicate': extendOnDuplicate,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * People
     * @returns EmptyObject
     * @throws ApiError
     */
    public putPeoplePeopleReSubscribe({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PutPeoplePeopleResubscribeRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/people/people/{id}/re-subscribe',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * PeopleUnSubscribe
     * UN-SUBSCRIBE PERSON
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeoplePeopleUnsubscribe({
        id,
        requestBody,
    }: {
        id?: number,
        requestBody?: PostPeoplePeopleUnsubscribeRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/people/{id}/unsubscribe',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * PostPeoplePeoplePersonidForceSync
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeoplePeopleForceSync({
        personId,
        requestBody,
    }: {
        personId: number,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/people/{personId}/force-sync',
            path: {
                'personId': personId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetPeoplePeopleSubmissionidContacts
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeoplePeopleContacts({
        formId,
        submissionId,
    }: {
        formId: string,
        submissionId: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/people/{submissionId}/contacts',
            path: {
                'submissionId': submissionId,
            },
            query: {
                'formId': formId,
            },
        });
    }
    /**
     * CreateASegment
     * CREATE SEGMENT
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeopleSegments({
        requestBody,
    }: {
        requestBody?: PostPeopleSegmentsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/segments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetAllSegments
     * SEGMENT INDEX
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeopleSegments(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/segments',
        });
    }
    /**
     * PeopleListAddPeople
     * ADD PEOPLE TO PEOPLE LIST
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeopleSegmentsAddPeople({
        requestBody,
    }: {
        requestBody?: PostPeopleSegmentsAddpeopleRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/segments/add-people',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * SubscribeToNewsletterList
     * NEWSLETTER LISTS SUBSCRIBE
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeopleSegmentsNewsletterListsSubscribe({
        requestBody,
    }: {
        requestBody?: PostPeopleSegmentsNewsletterlistsSubscribeRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/segments/newsletter-lists/subscribe',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetAllNewsletterListsPublic
     * GET PUBLIC NEWSLETTER LISTS
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeopleSegmentsPublicNewsletterLists(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/segments/public/newsletter-lists',
        });
    }
    /**
     * DeleteASegment
     * DELETE SEGMENT
     * @returns EmptyObject
     * @throws ApiError
     */
    public deletePeopleSegments({
        id,
    }: {
        id: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/people/segments/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * UpdateASegment
     * UPDATE SEGMENT
     * @returns EmptyObject
     * @throws ApiError
     */
    public putPeopleSegments({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PutPeopleSegmentsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/people/segments/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * CreateTag
     * CREATE TAG
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeopleTags({
        requestBody,
    }: {
        requestBody?: PostPeopleTagsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/tags',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetAllTags
     * TAGS INDEX
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeopleTags({
        accessToken,
    }: {
        accessToken?: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/tags',
            query: {
                'access_token': accessToken,
            },
        });
    }
    /**
     * DeleteTag
     * DELETE TAG
     * @returns EmptyObject
     * @throws ApiError
     */
    public deletePeopleTags(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/people/tags/{id}',
        });
    }
    /**
     * UpdateTag
     * UPDATE TAG
     * @returns EmptyObject
     * @throws ApiError
     */
    public putPeopleTags({
        requestBody,
    }: {
        requestBody?: PostPeopleTagsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/people/tags/{id}',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetPeoplePersonidPastoralNotes
     * Pastoral-Notes Retrieve Person
     * @returns GetCalendarFormsSearchResponseBody
     * @throws ApiError
     */
    public getPeoplePastoralNotes1({
        personId,
    }: {
        personId: number,
    }): CancelablePromise<GetCalendarFormsSearchResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/{personId}/pastoral-notes',
            path: {
                'personId': personId,
            },
        });
    }
    /**
     * PostPeoplePersonidPastoralNotes
     * Pastoral-Notes Create
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeoplePastoralNotes({
        personId,
        requestBody,
    }: {
        personId: number,
        requestBody?: PostPeoplePastoralnotesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/{personId}/pastoral-notes',
            path: {
                'personId': personId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeletePeoplePersonidPastoralNotesNoteid
     * Pastoral-Notes Delete
     * @returns EmptyObject
     * @throws ApiError
     */
    public deletePeoplePastoralNotes({
        noteId,
        personId,
    }: {
        noteId: number,
        personId: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/people/{personId}/pastoral-notes/{noteId}',
            path: {
                'noteId': noteId,
                'personId': personId,
            },
        });
    }
    /**
     * GetPeoplePersonidPastoralNotesNoteid
     * Pastoral-Notes Retrieve
     * @returns GetCalendarFormsSearchResponseBody
     * @throws ApiError
     */
    public getPeoplePastoralNotes2({
        noteId,
        personId,
    }: {
        noteId: number,
        personId: number,
    }): CancelablePromise<GetCalendarFormsSearchResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/{personId}/pastoral-notes/{noteId}',
            path: {
                'noteId': noteId,
                'personId': personId,
            },
        });
    }
    /**
     * PutPeoplePersonidPastoralNotesNoteid
     * Pastoral-Notes Update
     * @returns EmptyObject
     * @throws ApiError
     */
    public putPeoplePastoralNotes({
        noteId,
        personId,
        requestBody,
    }: {
        noteId: number,
        personId: number,
        requestBody?: PostPeoplePastoralnotesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/people/{personId}/pastoral-notes/{noteId}',
            path: {
                'noteId': noteId,
                'personId': personId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetPeoplePersonidPastoralNotesNoteidRevisions
     * Pastoral-Notes Revisions
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeoplePastoralNotesRevisions({
        noteId,
        personId,
    }: {
        noteId: number,
        personId: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/{personId}/pastoral-notes/{noteId}/revisions',
            path: {
                'noteId': noteId,
                'personId': personId,
            },
        });
    }
    /**
     * GetAllMessages
     * MESSAGES INDEX
     * @returns EmptyObject
     * @throws ApiError
     */
    public getV2PeopleMessages(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/people/messages/',
        });
    }
    /**
     * PeopleCreateMessage
     * CREATE MESSAGE
     * @returns EmptyObject
     * @throws ApiError
     */
    public postV2PeopleMessages({
        requestBody,
    }: {
        requestBody?: PeopleUpdateMessageRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/people/messages/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get available attributes list
     * @throws ApiError
     */
    public getV2PeopleMessagesAttributes({
        type = 'message',
    }: {
        type?: 'message' | 'anniversary' | 'contributions' | 'contributions_web',
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/people/messages/attributes',
            query: {
                'type': type,
            },
        });
    }
    /**
     * DeleteSingleMessage
     * DELETE MESSAGE
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteV2PeopleMessages({
        id,
    }: {
        id: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/people/messages/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetSingleMessage
     * GET MESSAGE
     * @returns EmptyObject
     * @throws ApiError
     */
    public getV2PeopleMessages1({
        id,
        rerender = false,
    }: {
        id: (number | 'new'),
        rerender?: boolean,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/people/messages/{id}',
            path: {
                'id': id,
            },
            query: {
                'rerender': rerender,
            },
        });
    }
    /**
     * PeopleUpdateMessage
     * UPDATE MESSAGE
     * @returns EmptyObject
     * @throws ApiError
     */
    public putV2PeopleMessages({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PeopleUpdateMessageRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/people/messages/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * PeopleCreatePeopleMessageFile
     * @returns EmptyObject
     * @throws ApiError
     */
    public postV2PeopleMessagesFiles({
        messageId,
        requestBody,
    }: {
        messageId: number,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/people/messages/{messageId}/files',
            path: {
                'messageId': messageId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeleteSinglePeopleMessageFile
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteV2PeopleMessagesFiles(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/people/messages/{messageId}/files/{id}',
        });
    }
    /**
     * GetSingleMessageShareableView
     * GET MESSAGE SHAREABLE VIEW
     * @returns EmptyObject
     * @throws ApiError
     */
    public getV2PeopleMessagesShare({
        uuid,
    }: {
        uuid: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/people/messages/{uuid}/share',
            path: {
                'uuid': uuid,
            },
        });
    }
}
