import { useCallback } from 'react';
import { Form } from 'antd';

import { showEnquiryDrawer } from '../components/EnquiryDrawer';
import { showApproveDeclineEnquiryModal } from '../components/ApproveDeclineEnquiryModal';
import { useFlag } from '../../../shared/hooks/useFlag';
import { mainApi } from '../../../api';
import { handleError } from '../../../services/ErrorHandlingService';
import { I18nService } from '../../../services/I18nService';
import NotificationService from '../../../services/NotificationService';
import { showConfirmModal } from '../../../shared/components/cd-confirm-modal/CdConfirmModal';
import StateServiceFactory from '../../../services/StateServiceFactory';

export const useEnquiriesPage = () => {
  const [form] = Form.useForm();
  const searchParams = Form.useWatch([], form);
  const state = StateServiceFactory();
  const bookingEnquiriesV2 = useFlag('booking_enquiries_v2');

  // We reload the page because at this point it is not easy to get the sidebar to update the count
  const refreshEnquiries = useCallback(() => state.reload(), [state]);

  const viewEnquiry = useCallback(
    (id: string) => showEnquiryDrawer({ id }),
    []
  );

  const approveEnquiry = useCallback(
    (id: string) =>
      bookingEnquiriesV2
        ? showApproveDeclineEnquiryModal({ id, respond: 'accepted' })
        : showConfirmModal({
            title: I18nService.getString('Approve enquiry'),
            message: I18nService.getString(
              'Please confirm that the enquiry is possible and that you have accepted. The enquirer will be informed accordingly.'
            ),
            onOk: async () => {
              const response = await mainApi.put(
                `/calendar/booking/requests/${id}/respond`,
                {
                  response: 'accepted',
                }
              );
              if (response.ok) {
                NotificationService.notifySuccess(
                  I18nService.getString('Enquiry approved')
                );
                refreshEnquiries();
              } else {
                handleError(response);
              }
            },
          }).then(({ resolved }) => {
            if (!resolved) return { preventClose: true };
          }),
    [bookingEnquiriesV2, refreshEnquiries]
  );

  const declineEnquiry = useCallback(
    (id: string) =>
      bookingEnquiriesV2
        ? showApproveDeclineEnquiryModal({ id, respond: 'rejected' }).then(
            ({ resolved }) => {
              if (!resolved) return { preventClose: true };
            }
          )
        : showConfirmModal({
            title: I18nService.getString('Decline enquiry'),
            message: I18nService.getString(
              'Please confirm that the enquiry is not possible. The enquirer will be informed accordingly.'
            ),
            onOk: async () => {
              const response = await mainApi.put(
                `/calendar/booking/requests/${id}/respond`,
                {
                  response: 'rejected',
                }
              );
              if (response.ok) {
                NotificationService.notifySuccess(
                  I18nService.getString('Enquiry declined')
                );
                refreshEnquiries();
              } else {
                handleError(response);
              }
            },
          }).then(({ resolved }) => {
            if (!resolved) return { preventClose: true };
          }),
    [bookingEnquiriesV2, refreshEnquiries]
  );

  return {
    form,
    searchParams,
    viewEnquiry,
    approveEnquiry,
    declineEnquiry,
    refreshEnquiries,
  };
};
