import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Skeleton, Typography } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from 'redux-saga-modal';

import { I18nService } from '../../services/I18nService';
import { CdCalendarFilterCollapse } from '../containers/calendar-filter/CdCalendarFilterCollapse';
import MODAL_TYPES from '../sagas/ModalTypes';
import { selectIsUserFiltersLoaded } from '../store/filters/Selectors';
import { selectIsInResourceView } from '../store/main-view/Selectors';

import CalendarSubscriptions from './CalendarSubscriptions';
import CollectionFilter from './CollectionFilter';
import { FilterSectionHeader } from './FilterSectionHeader';
import HolidaysFilter from './HolidaysFilter';
import PersonalCalendarFilter from './PersonalCalendarFilter';

import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';
import { useMyCalendarsData } from '@/react/calendar/components/hooks/useMyCalendarsData';

const CalendarSubscriptionButton = () => {
  const dispatch = useDispatch();
  return (
    <Button
      shape="circle"
      type="text"
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        dispatch(showModal(MODAL_TYPES.CALENDAR_SUBSCRIPTION_MODAL));
      }}
    >
      <CdTooltip title={I18nService.getString('Add external calendar')}>
        <Typography.Text>
          <FontAwesomeIcon icon={faPlus} />
        </Typography.Text>
      </CdTooltip>
    </Button>
  );
};

const MyCalenders = () => {
  const isUserFiltersLoaded = useSelector(selectIsUserFiltersLoaded);
  const isInResourceView = useSelector(selectIsInResourceView);

  const { collectionActive, isAllSelected, toggleAllMyCalendars } =
    useMyCalendarsData();
  return (
    <CdCalendarFilterCollapse
      defaultActiveKey="MY_CALENDARS"
      $transparentBackground
      items={[
        {
          key: 'MY_CALENDARS',
          label: isUserFiltersLoaded ? (
            <FilterSectionHeader
              isDisabled={isInResourceView}
              name={I18nService.getString('My calendars')}
              isAllSelected={isAllSelected}
              onClick={toggleAllMyCalendars}
              addonAfter={!isInResourceView && <CalendarSubscriptionButton />}
            />
          ) : (
            <div style={{ padding: '0 16px' }}>
              <Skeleton
                title={{ width: '80%' }}
                paragraph={false}
                loading
                active
              />
            </div>
          ),
          children: isUserFiltersLoaded ? (
            <div>
              <PersonalCalendarFilter />
              <HolidaysFilter isDisabled={isInResourceView} />
              {collectionActive && (
                <CollectionFilter isDisabled={isInResourceView} />
              )}
              <CalendarSubscriptions isDisabled={isInResourceView} />
            </div>
          ) : (
            <div style={{ padding: '0 16px' }}>
              <Skeleton
                title={false}
                paragraph={{ rows: 4, width: '100%' }}
                loading
                active
              />
            </div>
          ),
        },
      ]}
    />
  );
};

export default MyCalenders;
