import { Progress, Space, Spin, Typography, Row, Col, Button } from 'antd';
import React, { Suspense } from 'react';
import styled from 'styled-components';

import { I18nService } from '@/react/services/I18nService';
import CdTable from '@/react/shared/components/cd-table';
import { ProgressModalTableColumn } from '@/react/safeguarding/safeguarding/ProgressModalTableColumn';
import { percentage } from '@/react/safeguarding/common/Helpers';
import {
  CdBulkUploadFailed,
  CdBulkUploadFilter,
  CdCopyIcon,
} from '@/react/shared/components/Icons';
import { useUserUploadProgress } from '@/react/safeguarding/hooks/useUserUploadProgress';

const StyledProgress = styled(Progress)<{
  $processing: boolean;
}>`
    width: 100%;
    .ant-progress-text {
      padding-left: ${(props) => (props.$processing ? '0px' : '14px')}; 
    }
    &&&&.ant-progress-line{
        margin-inline-end: 0px;
    }
`;

export const UserUploadProgress = ({ processing, queue }) => {
  const {
    onlyShowFailed,
    setOnlyShowFailed,
    copyTableToClipboard,
    filteredData,
    tableData,
  } = useUserUploadProgress(queue);

  return (
    <Suspense fallback={<Spin />}>
      <Row
        justify="center"
        align="middle"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Col span="24" style={{ display: 'flex', justifyContent: 'center' }}>
          <StyledProgress
            $processing={processing}
            key="uploadingProgressBar"
            percent={percentage(queue.current, queue.rows.length)}
          />
        </Col>
        <Col span="24" style={{ display: 'flex', justifyContent: 'center' }}>
          {processing ? (
            <Typography.Text>
              <Space>
                <Typography.Text strong>
                  {I18nService.getString('Creating users')}
                </Typography.Text>
                <Typography.Text>
                  ({queue.current}/{queue.rows.length})
                </Typography.Text>
              </Space>
            </Typography.Text>
          ) : (
            <Space direction="horizontal">
              <Space direction="horizontal">
                <Typography.Title level={5}>
                  {I18nService.getString('Successfully Uploaded')}
                </Typography.Title>
                <Typography.Text type="success">
                  {queue.rows.filter((row) => row.state === 'success').length}
                </Typography.Text>
              </Space>
              <Space direction="horizontal">
                <Typography.Title level={5}>
                  {I18nService.getString('Failed to upload')}
                </Typography.Title>
                <Typography.Text type="danger">
                  {queue.rows.filter((row) => row.state === 'failed').length}
                </Typography.Text>
              </Space>
            </Space>
          )}
        </Col>
        {/* filter button */}
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'right',
            marginBottom: '8px',
          }}
        >
          <Space direction="horizontal">
            <Button icon={<CdCopyIcon />} onClick={copyTableToClipboard}>
              {I18nService.getString('Copy table to clipboard')}
            </Button>
            {onlyShowFailed ? (
              <Button
                icon={<CdBulkUploadFilter />}
                onClick={() => setOnlyShowFailed(false)}
              >
                {I18nService.getString('Show all')}
              </Button>
            ) : (
              <Button
                icon={<CdBulkUploadFailed />}
                onClick={() => setOnlyShowFailed(true)}
              >
                {I18nService.getString('Show failed')}
              </Button>
            )}
          </Space>
        </Col>
        {/* table view  of the row  */}
        <Col span="24">
          <CdTable
            noStyle
            data={{ items: filteredData, total: tableData.length }}
            emptyStateProps={{
              title: I18nService.getString('No row found'),
            }}
            columns={ProgressModalTableColumn()}
          />
        </Col>
      </Row>
    </Suspense>
  );
};
