import resourceService from '../services/ResourceService';

import { CdQueryDefinition } from './types';

export const resourcesQueries = {
  getAll: () => ({
    queryKey: ['resources'],
    queryFn: async () => resourceService.getResources(),
    staleTime: Infinity,
  }),
} satisfies CdQueryDefinition;
