import React, { useEffect } from 'react';
import { Col, Row, Space } from 'antd';

import { SimplePeopleForm } from '../../SimplePeopleForm';
import { useCreateContactProfileBasicInformation } from '../../hooks/useContactProfileInformation';

import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import { I18nService } from '@/react/services/I18nService';
import { CdUserPlusIcon } from '@/react/shared/components/Icons';

export const CreateNewContactWithBasicInformationModal = createCdModal<{
  createMode?: boolean;
}>(({ setModalProps, createMode }) => {
  const {
    form,
    setConflictingPeopleByEmail,
    setConflictingPeopleByPhone,
    showChurchSelector,
    churches,
    handleSave,
  } = useCreateContactProfileBasicInformation();
  useEffect(() => {
    setModalProps({
      modalType: ModalType.MODAL,
      title: I18nService.getString('Create new contact'),
      width: 600,
      alternativeButtonText: (
        <Space>
          <CdUserPlusIcon />
          {I18nService.getString('Save and create another')}
        </Space>
      ),
      onAlternative: async () => {
        await handleSave(true);
        form.resetFields();
        return { preventClose: true };
      },
      okText: I18nService.getString('Save'),
      onOk: async () => {
        await handleSave(false);
      },
    });
  }, [form, handleSave, setModalProps]);
  return (
    <Row>
      <Col span={24}>
        <SimplePeopleForm
          person={null}
          createMode={createMode}
          form={form}
          setConflictingPeopleByEmail={setConflictingPeopleByEmail}
          setConflictingPeopleByPhone={setConflictingPeopleByPhone}
          salutations={[]}
          showChurchSelector={showChurchSelector}
          churches={churches}
        />
      </Col>
    </Row>
  );
});
