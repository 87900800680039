import React from 'react';
import { Card, Col, Divider, Radio, Row, Space, Typography } from 'antd';
import moment from 'moment';
import { every, isNull } from 'lodash';
import styled from 'styled-components';

import { ContactType } from '../types/Types';

import { CHURCHDESK_COLOR } from '@/react/shared/components/Icons';
import { CdVerticalSpace } from '@/react/shared/components/cd-vertical-space/CdVerticalSpace';
import { I18nService } from '@/react/services/I18nService';
import { getStateUrl } from '@/react/services/StateServiceFactory';
import { formatAddress } from '@/react/shared/utils';
import { CdUserAvatar } from '@/react/user/components/cd-user-avatar/CdUserAvatar';
import { CdOpenInNewWindow } from '@/react/shared/components/Icons';

interface ContactDetailsProps {
  contact: ContactType;
  primary?: boolean;
  hideRadio?: boolean;
}
const StyledRadio = styled(Radio)`
    :where(.ant-radio-wrapper) span {
        padding-inline-end: 0 !important;
    }
`;

export const ContactDetails = (props: ContactDetailsProps) => {
  const { contact, primary, hideRadio } = props;

  return (
    <Card
      style={{
        borderColor: primary ? CHURCHDESK_COLOR : undefined,
        backgroundColor: primary ? '#E5F5F8' : undefined,
      }}
    >
      <Row>
        <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
          {!hideRadio && (
            <StyledRadio
              value={contact?.churchDeskId}
              style={{ marginRight: '0px' }}
            >
              <CdUserAvatar picture={contact?.picture} />
            </StyledRadio>
          )}
          {hideRadio && <CdUserAvatar picture={contact?.picture} />}
          <Typography.Link
            href={getStateUrl('app.private.people.contacts.view', {
              id: contact?.churchDeskId,
            })}
            target="_blank"
          >
            <Space direction="horizontal" style={{ fontWeight: 600 }}>
              {`${contact?.firstName} ${contact?.lastName?.toLowerCase()}` ||
                contact?.email}
              <CdOpenInNewWindow />
            </Space>
          </Typography.Link>
        </Col>
        <Divider style={{ marginBottom: '18px' }} />
        <Col span={24}>
          <CdVerticalSpace>
            <CdVerticalSpace size={0}>
              <Typography.Text type="secondary">
                {I18nService.getString('ChurchDesk ID')}
              </Typography.Text>
              {contact?.churchDeskId ? contact?.churchDeskId : '--'}
            </CdVerticalSpace>

            <CdVerticalSpace size={0}>
              <Typography.Text type="secondary">
                {I18nService.getString('External member registry ID')}
              </Typography.Text>
              {contact?.memberRegistryId ? contact?.memberRegistryId : '--'}
            </CdVerticalSpace>
            <CdVerticalSpace size={0}>
              <Typography.Text type="secondary">
                {I18nService.getString('Email')}
              </Typography.Text>
              {contact?.email ? contact?.email : '--'}
            </CdVerticalSpace>
            <CdVerticalSpace size={0}>
              <Typography.Text type="secondary">
                {I18nService.getString('Phone number')}
              </Typography.Text>
              {contact?.phoneNumber ? contact?.phoneNumber : '--'}
            </CdVerticalSpace>
            <CdVerticalSpace size={0}>
              <Typography.Text type="secondary">
                {I18nService.getString('Address')}
              </Typography.Text>
              {!every(contact?.address, isNull) ? (
                <Typography.Text ellipsis={{ tooltip: true }}>
                  {formatAddress({
                    street: contact?.address?.street,
                    postCode: contact?.address?.postCode,
                    city: contact?.address?.city,
                    countryIso2: contact?.address.country?.toLowerCase(),
                  })}
                </Typography.Text>
              ) : (
                '--'
              )}
            </CdVerticalSpace>
            <CdVerticalSpace size={0}>
              <Typography.Text type="secondary">
                {I18nService.getString('Contact created')}
              </Typography.Text>
              {contact?.contactCreated
                ? moment(contact?.contactCreated).format('LLL')
                : '--'}
            </CdVerticalSpace>
          </CdVerticalSpace>
        </Col>
      </Row>
    </Card>
  );
};
