import moment from 'moment';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Dropdown, Space } from 'antd';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { map } from 'lodash';

import { Column } from '../../../shared/models/table';
import AppUtils from '../../../services/AppUtilsService';
import { I18nService } from '../../../services/I18nService';
import { IntentionFee } from '../../models/fee';
import { getOrganizationCurrencySymbol } from '../../../shared/utils';
import { deleteIntentionFee } from '../../redux/intention-fees/Actions';
import { Taxonomy } from '../../../shared/models/taxonomy';

import {
  CdAngleDown,
  CdDeleteIcon,
  CdEditIcon,
} from '@/react/shared/components/Icons';
import { showConfirmModalDeprecated } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import { navigate } from '@/react/services/StateServiceFactory';

export const GetIntentionFeeColumns = (currentTab): Partial<Column>[] => {
  const dispatch = useDispatch();

  const editIntentionFee = (fee: IntentionFee) => {
    navigate('app.private.settings.fees.edit', {
      id: fee.id,
      type: currentTab,
      currentState: 'edit',
    });
  };

  const deleteFee = (fee: IntentionFee) => {
    showConfirmModalDeprecated({
      title: I18nService.getString('Delete Intention Fee'),
      message: I18nService.getString(
        'Do you want to delete the fee {{name}}?',
        {
          name: fee.name,
        }
      ),
      onOk() {
        dispatch(deleteIntentionFee({ id: fee.id, type: currentTab as any }));
      },
    });
  };

  const getIntentionFeeActionsMenu = (intentionFee: IntentionFee) => [
    {
      key: 'edit',
      label: I18nService.getString('Edit'),
      onClick: () => editIntentionFee(intentionFee),
      icon: <CdEditIcon />,
    },
    {
      key: 'delete',
      label: I18nService.getString('Delete'),
      onClick: () => deleteFee(intentionFee),
      icon: <CdDeleteIcon />,
    },
  ];
  return [
    {
      title: I18nService.getString('Name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: I18nService.getString('Description'),
      dataIndex: 'description',
      key: 'description',
      render: (text) =>
        text && <span style={{ whiteSpace: 'pre' }}>{text}</span>,
    },
    {
      title: I18nService.getString('Amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: function columnRender(amount: number) {
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {getOrganizationCurrencySymbol() + amount}
          </div>
        );
      },
    },
    {
      title: I18nService.getString('Active'),
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: function columnRender(active: boolean) {
        return (
          <div>
            {active ? (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ marginRight: 8 }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ marginRight: 8 }}
              />
            )}
          </div>
        );
      },
    },
    {
      title: I18nService.getString('Default'),
      dataIndex: 'isDefault',
      key: 'isDefault',
      align: 'center',
      render: function columnRender(isDefault: boolean) {
        return (
          <div>
            {isDefault ? (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ marginRight: 8 }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ marginRight: 8 }}
              />
            )}
          </div>
        );
      },
    },
    {
      title: I18nService.getString('Categories'),
      dataIndex: 'taxonomies',
      key: 'taxonomies',
      render: function columnRender(taxonomies: Taxonomy[]) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {map(taxonomies, (taxonomy) => (
              <Space key={taxonomy.id}>
                <i
                  className={
                    'fa fa-circle fa-lg ' +
                    AppUtils.getTaxonomyColorClass(taxonomy)
                  }
                />
                {taxonomy.name}
              </Space>
            ))}
          </div>
        );
      },
    },
    {
      title: I18nService.getString('Created at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: function columnRender(createdAt: Date) {
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {moment(createdAt).format('ddd, ll')}
          </div>
        );
      },
    },
    {
      title: null,
      dataIndex: null,
      key: 'actionsMenu',
      fixed: 'right',
      render: function columnRender(intentionFee: IntentionFee) {
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Dropdown
              menu={{ items: getIntentionFeeActionsMenu(intentionFee) }}
              trigger={['click']}
            >
              <Button size="small">
                <Space>
                  {I18nService.getString('Actions')}
                  <CdAngleDown />
                </Space>
              </Button>
            </Dropdown>
          </div>
        );
      },
    },
  ];
};
