import React from 'react';
import { Typography } from 'antd';

import { getButtonText, getEmptyStateText } from '../common/Helpers';
import CdSimpleTable from '../../shared/components/cd-simple-table/CdSimpleTable';
import { TableDetailsSelector } from '../store/Safeguarding';

import { SafeguardingFileUploadButton } from './SafeguardingFileUploadButton';
import { showSafeguardingFileUploadModal } from './SafeGuardingFileUploadModal';

import { I18nService } from '@/react/services/I18nService';

export const FileDisplayTable = (props: {
  currentUserId: number;
  fileType: string;
  tableColumns: any;
  value?: any;
  isEdit?: boolean;
}) => {
  const addRecordButtonHandler = () =>
    showSafeguardingFileUploadModal({
      fileType: props?.fileType,
      currentUserId: props?.currentUserId,
    });

  return (
    <CdSimpleTable<any>
      columns={props.tableColumns}
      noStyle
      recoilSource={TableDetailsSelector({
        fileType: props.fileType,
        userId: props.currentUserId,
      })}
      emptyStateProps={{
        title: getEmptyStateText(props.fileType),
      }}
      tableDescription={
        props.fileType === 'certificateOfConduct' && (
          <Typography.Text>
            {I18nService.getString(
              'For people working in the country on an exchange, posting an equivalent document can be uploaded here instead.'
            )}
          </Typography.Text>
        )
      }
      filterForm={
        props.isEdit && (
          <SafeguardingFileUploadButton
            buttonText={getButtonText(props.fileType)}
            buttonHandler={addRecordButtonHandler}
          />
        )
      }
    />
  );
};
