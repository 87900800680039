import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { TableProps } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Row,
  Table as AntTable,
  Typography,
  theme,
} from 'antd';
import { find } from 'lodash';

import ColumnSelect from '../ColumnSelect';
import CdrEmptyState from '../cd-result/CdrEmptyState';

import actionMenu from './ActionsMenu';
import { CdPagination } from './CdPagination';
import {
  getSelectedColumnsFromLocalStorage,
  saveSelectedColumnsToLocalStorage,
} from './store';

import {
  CdTableProps,
  ColumnType,
} from '@/react/shared/components/cd-table/types';
import {
  CdAngleDown,
  CdColumns,
  CdExclamationTriangleIcon,
} from '@/react/shared/components/Icons';
import { I18nService } from '@/react/services/I18nService';
import { OrderDirection, TableOrdering } from '@/react/shared/models/table';

export const Table = styled<any>(AntTable)`
  .errorRow {
    background-color: #f2dede;
    }
  .ant-table-row-bg-error {
      background-color: ${(props) => props.$token.colorErrorBg};
      border: 1px solid ${(props) => props.$token.colorErrorBorder};
  }
  .ant-table-row-bg-warning {
      background-color: ${() => (props) => props.$token.colorInfoBg};
      border: 1px solid ${(props) => props.$token.colorInfoBorder};
  }
  ${({ $rowStyles }) => ($rowStyles ? $rowStyles : '')}
`;
const { useToken } = theme;
function CdTable<T extends Record<string, any> = any>({
  columns,
  data,
  actionButtons = [],
  onTableChange = () => {},
  headingText = '',
  tableDescription = '',
  rowSelection,
  isLoading = false,
  filterForm,
  noStyle = false,
  emptyStateProps,
  rowStyles,
  rowKey,
  rowClassName,
  page,
  pageSize = 30,
  OverridePagination,
  resetSorting,
  onRow = () => {},
  columnSelectorKey,
}: CdTableProps<T>) {
  const { token } = useToken();
  const getInitialColumns = (show: boolean = true) =>
    columns
      .filter(({ hidden }) => !hidden)
      .map(({ key, dataIndex }) => key || dataIndex)
      .reduce((acc, key) => {
        acc[key.toString()] = show;
        return acc;
      }, {});

  const [selectedColumns, internalSetSelectedColumns] = useState<
    Record<string, boolean>
  >({
    ...getInitialColumns(),
    ...getSelectedColumnsFromLocalStorage(columnSelectorKey),
  });
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [pageNumber, setPageNumber] = page || useState<number>(1);
  const [tableOrdering, setTableOrdering] = useState<TableOrdering>(null);
  const [dropDownVisible, setDropDownVisible] = useState<T | boolean>(false);

  const churchdeskTableDefaults: Partial<TableProps<T>> = {
    size: 'small',
    bordered: true,
    rowKey: rowKey || 'id',
    scroll: { x: true },
    rowClassName: rowClassName
      ? rowClassName
      : (data) => (data?.deletedAt ? 'errorRow' : null),
  };

  useEffect(() => {
    const columnDetails = find(
      columns,
      (column) => column.defaultSortOrder
    ) as ColumnType<T>;
    if (columnDetails) {
      handleOrderingChange(null, null, {
        field: columnDetails.dataIndex as string,
        order: columnDetails.defaultSortOrder,
      });
    }
    // when we have dynamic columns, we need to update the selected columns
    setSelectedColumns({
      ...getInitialColumns(),
      ...getSelectedColumnsFromLocalStorage(columnSelectorKey),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const setSelectedColumns = (selectedColumns: Record<string, boolean>) => {
    internalSetSelectedColumns(selectedColumns);
    if (columnSelectorKey) {
      saveSelectedColumnsToLocalStorage(columnSelectorKey, selectedColumns);
    }
  };

  const handleOrderingChange = (
    _newPagination: any,
    _filters: any,
    sorter: SorterResult<T>
  ) => {
    if (sorter.field && sorter.order) {
      const columnDetails = find(
        columns,
        (column) => column.dataIndex === sorter.field
      ) as ColumnType<T>;
      const newTableOrdering: TableOrdering = {
        orderBy: sorter.field.toLocaleString(),
        orderDirection:
          sorter.order === 'ascend'
            ? OrderDirection.ASC
            : sorter.order === 'descend'
              ? OrderDirection.DESC
              : null,
        orderType: columnDetails.orderType,
      };

      setTableOrdering(newTableOrdering);
      onTableChange(pageNumber, pageSize, newTableOrdering, 'ordering');
    } else if (resetSorting) {
      setTableOrdering(null);
      onTableChange(pageNumber, pageSize, null, 'ordering');
    }
  };

  const handlePaginationChange = (newPageNumber: number) => {
    setPageNumber(newPageNumber);
    onTableChange(newPageNumber, pageSize, tableOrdering, 'pagination');
  };

  const actionColumn: ColumnType<T> = useMemo(
    () => ({
      title: null,
      dataIndex: null,
      key: 'actionsMenu',
      fixed: 'right',
      width: 120,
      render: function columnRender(dataItem: T) {
        return (
          <Dropdown
            onOpenChange={(visible) =>
              visible
                ? setDropDownVisible(dataItem)
                : setDropDownVisible(visible)
            }
            menu={{
              items: actionMenu({
                actionButtons,
                setDropDownVisible,
                item: dataItem,
              }),
            }}
            open={dropDownVisible === dataItem}
          >
            <Button size="small" onClick={() => setDropDownVisible(dataItem)}>
              {I18nService.getString('Actions')} <CdAngleDown />
            </Button>
          </Dropdown>
        );
      },
    }),
    [actionButtons, dropDownVisible]
  );

  const filterCheckbox = useMemo(
    () =>
      columns.map(({ title, key, dataIndex }) => ({
        label: title as React.ReactNode,
        value: (key || dataIndex).toString(),
      })),
    [columns]
  );

  const activeColumns = useMemo(
    () =>
      columns
        .filter(
          ({ key, dataIndex }) => selectedColumns[(key || dataIndex).toString()]
        )
        .concat(actionButtons.length ? actionColumn : [])
        .map((column) => ({
          ...column,
          showSorterTooltip: column.showSorterTooltip || false,
        })),
    [columns, actionButtons.length, selectedColumns, actionColumn]
  );

  const activeColumnsKeys = useMemo(
    () =>
      activeColumns.map(({ key, dataIndex }) => (key || dataIndex).toString()),
    [activeColumns]
  );

  return (
    <>
      {/* Filter row */}
      {filterForm && (
        <Card
          style={{ boxShadow: 'none' }}
          bodyStyle={{ padding: noStyle ? '0' : '24px', boxShadow: 'none' }}
          bordered={!noStyle}
        >
          <Row justify="space-between">
            <Col>{tableDescription}</Col>
            <Col>{filterForm}</Col>
          </Row>
        </Card>
      )}
      {/* Num results and column Selector */}
      <Card
        style={{ boxShadow: 'none' }}
        bodyStyle={{ padding: noStyle ? '0' : '24px', boxShadow: 'none' }}
        bordered={!noStyle}
      >
        <Row wrap={false}>
          {headingText && (
            <Col flex="none">
              <Typography.Title level={3} style={{ marginTop: 8 }}>
                {headingText}
              </Typography.Title>
            </Col>
          )}
          {columnSelectorKey && (
            <Col flex="auto" className="display-none-in-print">
              <div style={{ textAlign: 'right', marginBottom: '8px' }}>
                <ColumnSelect
                  value={activeColumnsKeys}
                  items={filterCheckbox}
                  onChange={(selectedColumns) => {
                    setSelectedColumns({
                      ...getInitialColumns(false),
                      ...selectedColumns.reduce((acc, key) => {
                        acc[key.toString()] = true;
                        return acc;
                      }, {}),
                    });
                  }}
                  buttonLabel={I18nService.getString('Show columns')}
                  buttonIcon={<CdColumns />}
                />
              </div>
            </Col>
          )}
        </Row>
        {data.items?.length > 0 || isLoading ? (
          <Table
            $token={token}
            {...churchdeskTableDefaults}
            columns={activeColumns}
            dataSource={data?.items}
            onChange={handleOrderingChange}
            pagination={false}
            // @ts-ignore
            $rowStyles={rowStyles}
            // preserveSelectedRowKeys is used to keep the selected rows when the table is re-rendered
            rowSelection={
              rowSelection && {
                ...rowSelection,
                preserveSelectedRowKeys: true,
              }
            }
            bordered
            loading={isLoading}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            onRow={onRow}
          />
        ) : (
          <Table
            $token={token}
            bordered
            size={churchdeskTableDefaults.size}
            columns={activeColumns}
            scroll={churchdeskTableDefaults.scroll}
            locale={{
              emptyText:
                data.access === false ? (
                  <CdrEmptyState
                    EmptyStateIcon={<CdExclamationTriangleIcon />}
                    title={I18nService.getString(
                      'Sorry, you are not authorized to access this information.'
                    )}
                    subtitle={I18nService.getString(
                      'Please request access permission from your organization administrator.'
                    )}
                  />
                ) : (
                  <CdrEmptyState {...emptyStateProps} />
                ),
            }}
          ></Table>
        )}
        <CdPagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          data={data}
          handlePaginationChange={handlePaginationChange}
          OverridePagination={OverridePagination}
          isLoading={isLoading}
        />
      </Card>
    </>
  );
}

export default CdTable;
