/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class StatisticsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetStatsEvents
     * Events
     * @returns EmptyObject
     * @throws ApiError
     */
    public getStatsEvents({
        apiKey,
    }: {
        /**
         * The apiKey needed to pass authentication.
         */
        apiKey: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stats/events',
            query: {
                'apiKey': apiKey,
            },
        });
    }
    /**
     * GetStatsLogins
     * Logins
     * @returns EmptyObject
     * @throws ApiError
     */
    public getStatsLogins({
        apiKey,
    }: {
        /**
         * The apiKey needed to pass authentication.
         */
        apiKey: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stats/logins',
            query: {
                'apiKey': apiKey,
            },
        });
    }
    /**
     * GetStatsPeople
     * People
     * @returns EmptyObject
     * @throws ApiError
     */
    public getStatsPeople({
        apiKey,
        full,
    }: {
        /**
         * The apiKey needed to pass authentication.
         */
        apiKey: any,
        /**
         * When set to 1, all data is fetched.
         */
        full: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stats/people',
            query: {
                'apiKey': apiKey,
                'full': full,
            },
        });
    }
    /**
     * GetStatsPeopleMessages
     * People Messages
     * @returns EmptyObject
     * @throws ApiError
     */
    public getStatsPeopleMessages({
        apiKey,
    }: {
        /**
         * The apiKey needed to pass authentication.
         */
        apiKey: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stats/people-messages',
            query: {
                'apiKey': apiKey,
            },
        });
    }
    /**
     * GetStatsPeopleMessagesSent
     * People Messages Sent
     * @returns EmptyObject
     * @throws ApiError
     */
    public getStatsPeopleMessagesSent({
        apiKey,
    }: {
        /**
         * The apiKey needed to pass authentication.
         */
        apiKey: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stats/people-messages-sent',
            query: {
                'apiKey': apiKey,
            },
        });
    }
    /**
     * GetStatsPeopleSmsSent
     * People SMS Sent
     * @returns EmptyObject
     * @throws ApiError
     */
    public getStatsPeopleSmsSent({
        apiKey,
    }: {
        /**
         * The apiKey needed to pass authentication.
         */
        apiKey: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stats/people-sms-sent',
            query: {
                'apiKey': apiKey,
            },
        });
    }
    /**
     * GetStatsUsers
     * Users
     * @returns EmptyObject
     * @throws ApiError
     */
    public getStatsUsers({
        apiKey,
    }: {
        /**
         * The apiKey needed to pass authentication.
         */
        apiKey: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stats/users',
            query: {
                'apiKey': apiKey,
            },
        });
    }
}
