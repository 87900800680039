export interface PeopleMessageTemplateFromList {
  id: string;
  title: string;
  templateSource: TemplateAuthor;
  screenshotUrl: string;
  author: {
    email: string;
    contact: {
      id: number;
      fullName: string;
      firstName: string;
      lastName: string;
    };
    id: number;
  };
}

export interface PeopleMessageTemplate {
  id: string;
  title: string;
  rendered: any;
  content: any;
  templateSource: TemplateAuthor;
  portalId: string;
}

export enum TemplateType {
  CALENDAR_POSTER = 'calendar-poster',
  PEOPLE_MESSAGE_ADVANCED = 'advanced',
}

export enum TemplateAuthor {
  USER = 'user',
  CHURCHDESK = 'churchdesk',
  PORTAL = 'portal',
}
