import React from 'react';

import { I18nService } from '@/react/services/I18nService';

type GetStringFunction = typeof I18nService.getString;
type TranslationProps = {
  text: Parameters<GetStringFunction>[0];
  params?: Parameters<GetStringFunction>[1];
  context?: Parameters<GetStringFunction>[2];
};

// Why this component exists?
// When I18nService.getString is left in global context it gets executed
// on application startup when translations are not yet fetched, so it this case
// it always returns a default, english, translation
// to fix it we are wrapping that executing in a React component which is executed
// when element is mounted to React tree (and we should already have translations)
export const Translation = ({ text, params, context }: TranslationProps) => (
  <>{I18nService.getString(text, params, context)}</>
);

export const FormattedTranslation = (props: { text: string }) => {
  const sections = [];
  const strongs = props.text.split('<strong>');

  strongs.forEach((text, index) => {
    if (index === 0) {
      sections.push({ text, type: 'text' });
      return;
    }
    const [strongText, rest] = text.split('</strong>');
    sections.push({ text: strongText, type: 'strong' });
    if (rest?.length > 0) {
      sections.push({ text: rest, type: 'text' });
    }
  });

  return (
    <span>
      {sections.map((section, index) => {
        if (section.type === 'text') {
          return <span key={index}>{section.text}</span>;
        } else if (section.type === 'strong') {
          return <strong key={index}>{section.text}</strong>;
        }
      })}
    </span>
  );
};
