/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChurchLegalAddress } from '../models/ChurchLegalAddress';
import type { ChurchLegalAddress2 } from '../models/ChurchLegalAddress2';
import type { EmptyObject } from '../models/EmptyObject';
import type { GetPaymentsConnectionTokenResponseBody } from '../models/GetPaymentsConnectionTokenResponseBody';
import type { OnboardingUser } from '../models/OnboardingUser';
import type { OnboardingUser2 } from '../models/OnboardingUser2';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PaymentsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * ContributionGetExcelReportDownloadToken
     * GET A DOWNLOAD TOKEN FOR contribution EXCEL REPORT
     * @returns EmptyObject
     * @throws ApiError
     */
    public getContributionsReportDownloadToken(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/contributions/report/download-token',
        });
    }
    /**
     * Put Stripe church legal address
     * Put the legal address of the church for Stripe compliance
     * @throws ApiError
     */
    public putPOrganizationsChurchLegalAddress({
        organizationId,
        requestBody,
    }: {
        organizationId: number,
        requestBody?: ChurchLegalAddress,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/p/organizations/{organizationId}/churchLegalAddress',
            path: {
                'organizationId': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Stripe church legal address
     * Get the legal address of the church for Stripe compliance
     * @returns ChurchLegalAddress2
     * @throws ApiError
     */
    public getPOrganizationsChurchLegalAddress({
        organizationId,
    }: {
        organizationId: number,
    }): CancelablePromise<ChurchLegalAddress2> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/organizations/{organizationId}/churchLegalAddress',
            path: {
                'organizationId': organizationId,
            },
        });
    }
    /**
     * Put Stripe onboarding user data
     * Put the data of the user which is doing the Stripe onboarding
     * @throws ApiError
     */
    public putPOrganizationsOnboardingUser({
        organizationId,
        requestBody,
    }: {
        organizationId: number,
        requestBody?: OnboardingUser,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/p/organizations/{organizationId}/onboardingUser',
            path: {
                'organizationId': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Stripe onboarding user data
     * Get the data of the user which is doing the Stripe onboarding
     * @returns OnboardingUser2
     * @throws ApiError
     */
    public getPOrganizationsOnboardingUser({
        organizationId,
    }: {
        organizationId: number,
    }): CancelablePromise<OnboardingUser2> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/organizations/{organizationId}/onboardingUser',
            path: {
                'organizationId': organizationId,
            },
        });
    }
    /**
     * GetPaymentsConnectionToken
     * Terminal connection token
     * @returns GetPaymentsConnectionTokenResponseBody
     * @throws ApiError
     */
    public getPaymentsConnectionToken(): CancelablePromise<GetPaymentsConnectionTokenResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payments/connection-token',
        });
    }
    /**
     * PostPaymentsIdRefund
     * Refund a payment
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPaymentsRefund({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payments/{id}/refund',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
