import React, { createContext, useState } from 'react';

type CalendarSearchContextData = {
  lowerCaseSearchName: string;
  setLowerCaseSearchName: (name: string) => void;
};

export const CalendarSearchContext = createContext<CalendarSearchContextData>({
  lowerCaseSearchName: '',
  setLowerCaseSearchName: () => {},
});

export const CalendarSearchProvider = ({
  children,
}: { children: React.ReactNode }) => {
  const [lowerCaseSearchName, setLowerCaseSearchName] = useState('');

  return (
    <CalendarSearchContext.Provider
      value={{ lowerCaseSearchName, setLowerCaseSearchName }}
    >
      {children}
    </CalendarSearchContext.Provider>
  );
};

export const useCalendarSearchContext = () => {
  const { lowerCaseSearchName, setLowerCaseSearchName } = React.useContext(
    CalendarSearchContext
  );

  return {
    lowerCaseSearchName,
    setLowerCaseSearchName,
    isDuringSearch: Boolean(lowerCaseSearchName),
  };
};
