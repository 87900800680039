import { Form, Select, Checkbox } from 'antd';
import { isEqual } from 'lodash';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { CdLock } from '../../../../shared/components/Icons';
import { I18nService } from '../../../../services/I18nService';
import { UserChurchesWithPermissionQuery } from '../../../../user/store/permissions';

import InputWithEmojiButton from './InputWithEmojiButton';
import SenderSelector from './SenderSelector';
import RecipientCalculator from './RecipientCalculator';
import RecipientSelector from './recipient-selector/RecipientSelector';

import { ChurchSettingsState } from '@/react/organization/store/church';

// eslint-disable-next-line arrow-body-style
const MessageFormDetails = ({ isSent }: { isSent: boolean }) => {
  const churchesLoadable = useRecoilValueLoadable(
    UserChurchesWithPermissionQuery({
      permissionContext: 'people',
      permissionType: 'communicate',
    })
  );

  const churchSettings = useRecoilValue(ChurchSettingsState);
  const defaultFormProps = { labelCol: { lg: { span: 3 } } };
  const showChurchSelector = window?.cdApp?.showChurchSelector;

  return (
    <>
      <Form.Item
        name="from"
        initialValue="user"
        label={I18nService.getString('From')}
        {...defaultFormProps}
        rules={[
          {
            required: true,
            message: I18nService.getString(
              'Please choose the sender of the message.'
            ),
          },
          {
            validator: (_, value) =>
              value === 'church' && !churchSettings.organizationEmail
                ? Promise.reject(
                    new Error(
                      I18nService.getString(
                        'Please go to settings and provide a main e-mail address for sending.'
                      )
                    )
                  )
                : Promise.resolve(),
          },
        ]}
        required
      >
        <SenderSelector disabled={isSent} />
      </Form.Item>
      <Form.Item
        name="to"
        label={I18nService.getString('To', {}, 'People message to field')}
        {...defaultFormProps}
        rules={[
          {
            required: true,
            message: I18nService.getString('Please select the recipients.'),
          },
        ]}
        required
      >
        <RecipientSelector disabled={isSent} />
      </Form.Item>
      <Form.Item
        name="title"
        label={I18nService.getString('Subject')}
        {...defaultFormProps}
        required
        rules={[
          {
            required: true,
            message: I18nService.getString('Please write a subject.'),
          },
        ]}
      >
        <InputWithEmojiButton disabled={isSent} />
      </Form.Item>
      {showChurchSelector && (
        <Form.Item
          name="churches"
          label={I18nService.getString('Parish')}
          {...defaultFormProps}
          rules={[
            {
              required: true,
              message: I18nService.getString(
                'Please select whom to share the message with.'
              ),
            },
          ]}
          help={
            <>
              <CdLock />{' '}
              {I18nService.getString(
                'The message is available to users in these parishes'
              )}
            </>
          }
          required
        >
          <Select
            mode="multiple"
            allowClear
            filterOption={(
              input: string,
              option: {
                key: string;
                value: number;
                children: string;
                disabled: boolean;
              }
            ) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder={I18nService.getString(
              'Choose one or more parishes...'
            )}
            loading={churchesLoadable.state === 'loading'}
            disabled={isSent}
          >
            {churchesLoadable.state === 'hasValue' &&
              churchesLoadable.contents?.map(({ id, access, name }) => (
                <Select.Option value={id} key={id} disabled={!access}>
                  {name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        label={<span style={{ display: 'none' }}></span>}
        colon={false}
        shouldUpdate={(prevValues, currentValues) =>
          !isEqual(prevValues.to, currentValues.to)
        }
        {...defaultFormProps}
      >
        {({ getFieldValue }) => {
          const toValue: string[] = getFieldValue('to') || [];
          return (
            toValue.length > 0 && (
              <RecipientCalculator toValue={toValue} type="email" />
            )
          );
        }}
      </Form.Item>
      <Form.Item
        name="notifyOnEmailDeliveryFailure"
        valuePropName="checked"
        wrapperCol={{ offset: 3 }}
      >
        <Checkbox style={{ fontWeight: 'normal' }} disabled={isSent}>
          {I18nService.getString(
            'Get an email notification if the message fails to be delivered to any recipients. You will receive an email two hours after the message has been sent.'
          )}
        </Checkbox>
      </Form.Item>
    </>
  );
};

export default MessageFormDetails;
