import { mainApi } from '../../api';

export const locationNameSuggestions: () => Promise<string[]> = async () => {
  const response = await mainApi.get<string[]>(
    '/calendar/location-name/suggestions'
  );
  if (response.ok) {
    return response.data;
  }
  throw response.data;
};

export const districtNameSuggestions = async () => {
  const response = await mainApi.get<{ count: number; items: string[] }>(
    '/resources/district'
  );
  if (response.ok) {
    return response.data.items;
  }
  throw response.data;
};
