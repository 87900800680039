import { Button, ButtonProps, ConfigProvider } from 'antd';
import React from 'react';

import { CdMasquerade } from '../Icons';
import { CdTooltip } from '../cd-tooltip/CdTooltip';

import cdApp from '@/react/config';

export const CdMasqueradeButton = (props: ButtonProps) => {
  if (cdApp.me.masquerading || cdApp.config.environment === 'development') {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#722ed1',
          },
        }}
      >
        <CdTooltip title="Only visible for ChurchDesk Employees">
          <Button
            {...props}
            target="_blank"
            rel="noreferrer"
            icon={<CdMasquerade />}
          >
            {props.children}
          </Button>
        </CdTooltip>
      </ConfigProvider>
    );
  } else {
    return <></>;
  }
};
