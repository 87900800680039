import { useRecoilCallback } from 'recoil';
import { UploadRequestOption } from 'rc-upload/lib/interface';

import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';
import NotificationService from '@/react/services/NotificationService';
import { I18nService } from '@/react/services/I18nService';

interface UploadLogoResponse {
  exceededPixelSize: number;
  url: string;
  uuid: string;
}

export interface AttachFileInterface
  extends Pick<
    UploadRequestOption<UploadLogoResponse>,
    'onSuccess' | 'onProgress' | 'onError' | 'file'
  > {
  fileName?: string;
}

const usePoster = () => {
  const uploadLogo = useRecoilCallback(
    () =>
      async ({
        onSuccess,
        onProgress,
        onError,
        file,
        fileName,
      }: AttachFileInterface) => {
        const fileForm = new FormData();
        fileForm.append('file', file as any, fileName);
        try {
          const res = await mainApi.post<UploadLogoResponse>(
            '/p/contributions/posters/logo/upload',
            fileForm,
            {
              onUploadProgress: (e) =>
                onProgress({ percent: (e.loaded / e.total) * 100, ...e }),
            }
          );
          onSuccess(res.data, undefined);
          return res.data;
        } catch (e) {
          onError(e);
        }
      },
    []
  );
  const createPoster = useRecoilCallback(
    () => async (contributionId, organizationId, size, imageId) => {
      const settings = {
        backgroundColor: 'white',
        fontColor: 'black',
        actionColor: '#27AE60',
      };

      const response = await mainApi.post<any>(
        `/p/contributions/${contributionId}/poster`,
        {
          organizationId,
          size,
          settings,
          imageId: `${imageId}`,
        }
      );
      if (!response.ok) {
        handleError(response);
        throw response;
      } else {
        NotificationService.notifySuccess(
          I18nService.getString('Poster created succesfully')
        );
        return response.data;
      }
    },
    []
  );
  return { uploadLogo, createPoster };
};

export default usePoster;
