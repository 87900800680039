import styled from 'styled-components';
import React from 'react';
import { Col, Row, Spin, Typography } from 'antd';

import { useAnniversaryNotifyUsers } from '../hooks/useAnniversary';

import { CdParishSelect } from '@/react/shared/components/cd-perish-select/CdParishSelect';
import { I18nService } from '@/react/services/I18nService';
import { CdGroupUserSelect } from '@/react/shared/components/cd-group-user-select/CdGroupUserSelect';
import { CdClose } from '@/react/shared/components/Icons';
import { CdUserAvatar } from '@/react/user/components/cd-user-avatar/CdUserAvatar';
import { CdVerticalSpace } from '@/react/shared/components/cd-vertical-space/CdVerticalSpace';

const RowContainer = styled(Row)`
  &&&& {
    height: 56px;
    display: flex;
    align-items: center;
    &:hover {
      background-color: #eff0ed;
    }
  }
`;

interface AnniversaryNotifyUsersProps {
  value?: any;
  onChange?: any;
}
export const AnniversaryNotifyUsers = (props: AnniversaryNotifyUsersProps) => {
  const {
    isMultiParish,
    usersOptions,
    showUsers,
    handleRemoveUser,
    keywordToSearchUsersByName,
    setKeywordToSearchusersByName,
    parishIds,
    setParishIds,
    handleChange,
    loading,
  } = useAnniversaryNotifyUsers(props);
  return (
    <Spin spinning={loading}>
      {isMultiParish && (
        <CdVerticalSpace>
          <Typography.Text strong>
            {I18nService.getString('Filter users by parish')}:
          </Typography.Text>
          <CdParishSelect
            value={parishIds}
            onChange={setParishIds}
            style={{ width: '100%', marginBottom: '8px' }}
          />
        </CdVerticalSpace>
      )}
      <CdGroupUserSelect
        placeholder={I18nService.getString('Search user')}
        users={usersOptions}
        keyword={keywordToSearchUsersByName}
        setKeyword={setKeywordToSearchusersByName}
        onChange={handleChange}
        value={null}
        style={{ marginBottom: '8px' }}
      />
      {showUsers?.length > 0 &&
        showUsers.map((user) => (
          <RowContainer key={user.id}>
            <Col span={23}>
              <CdUserAvatar
                light
                name={user.name || user.email}
                picture={user.picture}
              />
            </Col>
            <Col
              span={1}
              style={{ cursor: 'pointer', textAlign: 'right' }}
              onClick={() => {
                handleRemoveUser(user.id);
              }}
            >
              <CdClose type="secondary" style={{ paddingRight: '10px' }} />
            </Col>
          </RowContainer>
        ))}
    </Spin>
  );
};
