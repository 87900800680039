/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostPPaymentsTicketsRequestBody = {
    paymentType: PostPPaymentsTicketsRequestBody.paymentType;
    email: string;
    payerEmail: string;
    payerFirstName: string;
    payerLastName?: string;
};
export namespace PostPPaymentsTicketsRequestBody {
    export enum paymentType {
        CARD = 'CARD',
        SEPA = 'SEPA',
    }
}

