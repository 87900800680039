/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, DatePicker, FormInstance } from 'antd';

import { I18nService } from '../../../services/I18nService';
import { editingCollectionSelector } from '../../redux/collection/Selectors';
import {
  createCollection,
  putCollection,
  setEditId,
} from '../../redux/collection/Actions';

export default function CollectionForm({ form }: { form: FormInstance }) {
  const dispatch = useDispatch();
  const editingCollection = useSelector(editingCollectionSelector);

  React.useEffect(
    () =>
      editingCollection &&
      form.setFieldsValue({
        ...editingCollection,
        date: moment(editingCollection.date),
      }),
    [editingCollection]
  );

  const saveCollection = React.useCallback((collection) => {
    if (!editingCollection) {
      dispatch(
        createCollection({
          ...collection,
          date: collection.date.format('YYYY-MM-DD'),
        })
      );
    } else {
      dispatch(
        putCollection({
          ...editingCollection,
          ...collection,
          date: collection.date.format('YYYY-MM-DD'),
        })
      );
    }
    form.resetFields();
    dispatch(setEditId(false));
  }, []);

  return (
    <Form
      name="CollectionForm"
      form={form}
      preserve={false}
      layout="vertical"
      labelCol={{ offset: 0, span: 6 }}
      onFinish={saveCollection}
    >
      <Form.Item
        label={I18nService.getString('Purpose')}
        name="purpose"
        required
      >
        <Input />
      </Form.Item>
      <Form.Item label={I18nService.getString('Name of day')} name="nameOfDay">
        <Input />
      </Form.Item>
      <Form.Item label={I18nService.getString('Number')} name="number">
        <Input />
      </Form.Item>
      <Form.Item label={I18nService.getString('Date')} name="date" required>
        <DatePicker />
      </Form.Item>
    </Form>
  );
}
