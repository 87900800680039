import { Button, Dropdown, MenuProps, Space, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useRotaLinkActions } from '@/react/calendar/rota-scheme/hooks/useRotaLinkActions';
import { RotaScheme } from '@/react/calendar/rota-scheme/types/rotaScheme.types';
import { I18nService } from '@/react/services/I18nService';
import { getStateUrl } from '@/react/services/StateServiceFactory';
import {
  CdAlertInfo,
  CdCogs,
  CdDeleteIcon,
  CdLink,
  CdLockSolid,
  CdMoreIcon,
  CdTableIcon,
} from '@/react/shared/components/Icons';
import CdTable, { ColumnType } from '@/react/shared/components/cd-table';
import { rotaShiftQueries } from '@/react/shared/queries/rotas/shifts';

const moreItems: MenuProps['items'] = [
  {
    key: 'settings',
    label: I18nService.getString('Scheme settings'),
    icon: <CdCogs />,
  },
  {
    key: 'delete',
    label: I18nService.getString('Delete'),
    icon: <CdDeleteIcon />,
    danger: true,
  },
];

const ALL_ROTAS_ITEM = { id: 'all-rotas', name: 'All rotas' } as RotaScheme;

export const RotaSchemeTable = () => {
  const { data: rotas } = useQuery(rotaShiftQueries.getAllSchemes());
  const { copyShareableLink, editSettings, deleteScheme } =
    useRotaLinkActions();

  const columns: ColumnType<RotaScheme>[] = useMemo(
    () => [
      {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',

        render: (_, rotaScheme) => {
          if (rotaScheme === ALL_ROTAS_ITEM) {
            return (
              <a href={getStateUrl('app.private.calendar.rotas.default')}>
                <Space>
                  {I18nService.getString('All rotas (default rota scheme)')}
                  <CdLockSolid type="secondary" />
                </Space>
              </a>
            );
          }
          return (
            <a
              href={getStateUrl('app.private.calendar.rotas.view', {
                rotaLinkId: rotaScheme.id,
                rotaLink: rotaScheme,
              })}
            >
              {rotaScheme.name}
            </a>
          );
        },
        sorter: (a, b, sortOrder) => {
          // a custom implementation to make sure that all rotas item is always on top
          if (a === ALL_ROTAS_ITEM) {
            return sortOrder === 'ascend' ? -1 : 1;
          }
          if (b === ALL_ROTAS_ITEM) {
            return sortOrder === 'ascend' ? 1 : -1;
          }
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        },
        defaultSortOrder: 'ascend',
      },
      {
        key: 'actions',
        title: '',
        width: 200,
        render: (_, rotaScheme) => {
          if (rotaScheme === ALL_ROTAS_ITEM) {
            return (
              <Typography.Text italic type="secondary">
                <Space>
                  <CdAlertInfo />
                  {I18nService.getString('The default scheme shows all rotas')}
                </Space>
              </Typography.Text>
            );
          }
          return (
            <Space>
              <Button
                type="primary"
                icon={<CdLink />}
                onClick={() => copyShareableLink(rotaScheme)}
              >
                {I18nService.getString('Copy shareable link')}
              </Button>
              <Dropdown
                menu={{
                  items: moreItems,
                  onClick: (item) => {
                    if (item.key === 'settings') {
                      void editSettings(rotaScheme);
                    } else if (item.key === 'delete') {
                      void deleteScheme(rotaScheme);
                    }
                  },
                }}
              >
                <Button>
                  <Space>
                    {I18nService.getString('More')}
                    <CdMoreIcon />
                  </Space>
                </Button>
              </Dropdown>
            </Space>
          );
        },
      },
    ],
    [copyShareableLink, deleteScheme, editSettings]
  );

  return (
    <CdTable<RotaScheme>
      columns={columns}
      data={{
        items: [ALL_ROTAS_ITEM, ...(rotas?.items ?? [])],
        total: rotas?.count ?? 0,
      }}
      emptyStateProps={{
        // these props are required, but due to ALL_ROTAS_ITEM
        // this table is actually never empty
        title: I18nService.getString('No rotas'),
        EmptyStateIcon: <CdTableIcon />,
      }}
    />
  );
};
