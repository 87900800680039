/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetPublicBlogsResponseBody } from '../models/GetPublicBlogsResponseBody';
import type { GetPublicEventsResponseBody } from '../models/GetPublicEventsResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CollaborationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetPublicBlogs
     * Public Blogs
     * @returns GetPublicBlogsResponseBody
     * @throws ApiError
     */
    public getCollaborationBlogView({
        imageFormat,
        offset,
        limit,
        filters,
        filtersOrganizationId,
        requireImage,
        maxItems,
        filtersCategories,
        filtersTitle,
    }: {
        /**
         * The image style to be returned. If not provided, no image will be returned.
         */
        imageFormat: any,
        /**
         * The offset to start returning blogs from. Used for pagination.
         */
        offset: any,
        /**
         * The number of blogs to return.
         */
        limit: any,
        /**
         * Custom properties to filter blogs by.
         */
        filters: any,
        /**
         * The organization the blogs belong to.
         */
        filtersOrganizationId: any,
        /**
         * Get only blogs that have an image.
         */
        requireImage?: any,
        /**
         * The maximum number of blogs to select.
         */
        maxItems?: any,
        /**
         * A list of categories to filter by (from the associated organization).
         */
        filtersCategories?: any,
        /**
         * A string to filter blogs by their title.
         */
        filtersTitle?: any,
    }): CancelablePromise<GetPublicBlogsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/collaboration/blog-view',
            query: {
                'requireImage': requireImage,
                'imageFormat': imageFormat,
                'offset': offset,
                'limit': limit,
                'maxItems': maxItems,
                'filters': filters,
                'filters.organizationId': filtersOrganizationId,
                'filters.categories': filtersCategories,
                'filters.title': filtersTitle,
            },
        });
    }
    /**
     * GetPublicEvents
     * Public Events
     * @returns GetPublicEventsResponseBody
     * @throws ApiError
     */
    public getCollaborationCalendarView({
        version = 2,
        limit = 20,
        offset,
        startDate,
        rollingDays,
        endDate,
        requireImage = 'false',
        imageFormat = 'span3_16-9',
        stripHtml = 'false',
    }: {
        version?: 1 | 2,
        limit?: number,
        offset?: number,
        startDate?: string,
        rollingDays?: number,
        endDate?: string,
        requireImage?: 'true' | 'false' | '1' | '0' | '' | null,
        imageFormat?: string,
        stripHtml?: 'true' | 'false' | '1' | '0' | '' | null,
    }): CancelablePromise<GetPublicEventsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/collaboration/calendar-view',
            query: {
                'version': version,
                'limit': limit,
                'offset': offset,
                'startDate': startDate,
                'rollingDays': rollingDays,
                'endDate': endDate,
                'requireImage': requireImage,
                'imageFormat': imageFormat,
                'stripHtml': stripHtml,
            },
        });
    }
}
