import { Form } from 'antd';
import { useEffect } from 'react';

import {
  ModalType,
  SetModalPropsType,
} from '../../shared/components/cd-modal-new/CdModal2';
import { I18nService } from '../../services/I18nService';
import cdApp from '../../config';
import { showConfirmModal } from '../../shared/components/cd-confirm-modal/CdConfirmModal';
import { mainApi } from '../../api';
import NotificationService from '../../services/NotificationService';
import { showAcceptLegalTermsModal } from '../components/shared/AcceptLegalTermsModal';

import StateServiceFactory from '@/react/services/StateServiceFactory';
import { useStripeAccount } from '@/react/organization/store/organization';

/**
 * Custom hook to check if the user has accepted the legal terms for setting up payments.
 *
 * It determines if the user has completed the payment setup
 * process and, if not, prompts the user to accept the legal terms via a modal.
 *
 * @returns {Object} An object containing:
 * - `missingSetupForPayment` (boolean): Indicates whether the user has completed the payment initial setup process selecting the relevant country.
 */
export const useCheckAcceptLegalTerms = () => {
  const stateService = StateServiceFactory();
  const {
    stripeAccountCreated,
    stripeAccountIsLoading,
    invalidate,
    stripeAccountRestricted,
  } = useStripeAccount();

  useEffect(() => {
    const onboard = async () => {
      const { resolved } = await showAcceptLegalTermsModal();
      if (!resolved) stateService.go('app.private.settings.other');
      else {
        invalidate();
      }
    };
    if (!stripeAccountCreated && !stripeAccountIsLoading) {
      onboard();
    }
  }, [stateService, invalidate, stripeAccountCreated, stripeAccountIsLoading]);

  return { stripeAccountRestricted, stripeAccountIsLoading };
};

export const useAcceptLegalTermsModal = (setModalProps: SetModalPropsType) => {
  const [form] = Form.useForm();
  useEffect(() => {
    setModalProps({
      modalType: ModalType.POPUP,
      title: I18nService.getString('Where is your church located?'),
      width: 500,
      onOk: async () =>
        form.validateFields().then(async (values: { country: string }) => {
          const selectedCountry = values.country;

          if (selectedCountry !== cdApp.organization.countryIso2) {
            const { resolved } = await showConfirmModal({
              title: I18nService.getString(''),
              message: I18nService.getString(
                'You selected a different country ({{ selectedCountry }}) than the country of your organization ({{ orgCountry }}). Are you sure you want to continue?',
                {
                  selectedCountry: selectedCountry.toUpperCase(),
                  orgCountry: cdApp.organization.countryIso2.toUpperCase(),
                },
                'Selecting the country for the organization in Stripe'
              ),
              okText: I18nService.getString('Continue'),
            });
            if (!resolved) throw new Error();
          }

          const response = await mainApi.post('/organizations/account', {
            country: selectedCountry,
            tosAcceptance: true,
          });

          if (response.ok) {
            NotificationService.notifySuccess(
              I18nService.getString('Terms of service accepted')
            );
          }
        }),
      okText: I18nService.getString('Confirm'),
    });
  }, [form, setModalProps]);

  return { form };
};
