import { Tabs, Space, TabsProps } from 'antd';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import { CdCircleNumber } from '../Icons';
import { CdCard } from '../CdCard';

export interface CdTab {
  key?: string;
  showTabNumber?: boolean;
  icon?: React.ReactNode;
  title: string;
  subtitle?: string;
  content: React.ReactNode;
}

export interface CdTabsProps
  extends Pick<
    TabsProps,
    'activeKey' | 'onChange' | 'tabBarExtraContent' | 'defaultActiveKey'
  > {
  fluidHeight?: boolean;
  noInnerPadding?: boolean;
  centered?: boolean;
  style?: CSSProperties;
  items: CdTab[];
}

export const CdTabs = ({
  items,
  fluidHeight,
  noInnerPadding,
  ...props
}: CdTabsProps) => (
  <StyledTabs
    {...props}
    $fluidHeight={fluidHeight}
    $noInnerPadding={noInnerPadding}
    size="large"
    type="card"
    tabBarStyle={{ marginBottom: 0 }}
    items={items
      .filter((item) => !!item)
      .map((item, index) => ({
        key: item.key || index.toString(),
        label: (
          <CdTabPaneTab
            tabNumber={item.showTabNumber ? index + 1 : undefined}
            title={item.title}
            subTitle={item.subtitle}
            icon={item.icon}
          />
        ),
        children: <CdCard $fill>{item.content}</CdCard>,
      }))}
  />
);

const StyledTabs = styled(Tabs)<{
  $fluidHeight?: boolean;
  $noInnerPadding?: boolean;
}>`
  &&&& {
    flex: 1 1;
    overflow: ${({ $fluidHeight }) => ($fluidHeight ? 'auto' : 'hidden')};
    height: ${({ $fluidHeight }) => ($fluidHeight ? '100%' : 'auto')};

    .ant-tabs-content {
      height: ${({ $fluidHeight }) => ($fluidHeight ? '100%' : 'auto')};
    }
    .ant-tabs-nav-list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      box-sizing: content-box;
      -webkit-box-sizing: content-box;
    }
    .ant-tabs-tab {
      width: 100%;
    }
    .ant-tabs-tabpane {
      height: ${({ $fluidHeight }) => ($fluidHeight ? '100%' : 'auto')};
    }
    .ant-tabs-extra-content {
      margin-left: 16px;
    }
    .ant-card-body {
      padding: ${({ $noInnerPadding }) => $noInnerPadding && '0'};
    }
    .ant-tabs-nav-operations {
      display: none !important;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: none;
    }
  }
`;

const CdTabPaneTab = ({
  tabNumber,
  icon,
  title,
  subTitle,
}: {
  icon?: React.ReactNode;
  tabNumber?: number;
  title: string;
  subTitle?: string;
}) => (
  <Space style={{ margin: '8px' }} align="center">
    {icon}
    {tabNumber && <CdCircleNumber label={tabNumber} />}
    <div>
      <div style={{ fontWeight: 'bold', fontSize: '16px' }}>{title}</div>
      {subTitle && (
        <div style={{ fontSize: '13px', whiteSpace: 'normal' }}>{subTitle}</div>
      )}
    </div>
  </Space>
);
