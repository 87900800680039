import React from 'react';
import { Select } from 'antd';
import styled from 'styled-components';

import mountingContainer from '../../containers/mountContainer';

import { I18nService } from '@/react/services/I18nService';

const StyledH5 = styled.h5`
  color: #777;
  font-size: 15px;
  margin: 0;
  text-transform: uppercase;
  padding: 10px 0;
`;

const FormatSelection: React.FC<{
  defaultValue: string;
  onChange: (value: string) => void;
}> = ({ defaultValue, onChange }) => {
  const formatSelection = [
    {
      id: 'thumbnail',
      name: I18nService.getString('Thumbnail (80 x 80)'),
      width: 80,
      height: 80,
    },
    {
      id: 'medium',
      name: I18nService.getString('Medium (Max width: 370px)'),
      width: 370,
      height: 278,
    },
    {
      id: 'large',
      name: I18nService.getString('Large (Max width: 700px)'),
      width: 700,
      height: 525,
    },
  ];
  return (
    <React.Fragment>
      <StyledH5>{I18nService.getString('Display settings')}</StyledH5>
      <Select
        defaultValue={defaultValue}
        onSelect={onChange}
        getPopupContainer={mountingContainer}
      >
        {formatSelection.map((item) => (
          <Select.Option key={item.id} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    </React.Fragment>
  );
};

export default FormatSelection;
