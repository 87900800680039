/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostPortalReportsExportRequestBody = {
    from: string;
    until: string;
    portalOrganizationIds?: Array<number>;
    portalCategoryIds?: Array<string>;
    churchIds?: Array<number>;
    exportType?: PostPortalReportsExportRequestBody.exportType;
};
export namespace PostPortalReportsExportRequestBody {
    export enum exportType {
        CSV = 'csv',
        XLSX = 'xlsx',
    }
}

