import {
  Button,
  Collapse,
  CollapseProps,
  Row,
  Space,
  Switch,
  Typography,
} from 'antd';
import React from 'react';

import {
  FormBookingHours,
  ScheduleItem,
} from '../types/availability-taxonomy.types';
import { doesIntervalsOverlap } from '../utils/validation';
import { useWeeklyScheduleEvents } from '../hooks/useWeeklyScheduleEvents';

import { I18nService } from '@/react/services/I18nService';
import { Translation } from '@/react/shared/antd/validations/Translation';
import {
  CdDeleteIcon,
  CdExclamationTriangleIcon,
  CdPlus,
  CdTime,
} from '@/react/shared/components/Icons';
import { CdTimePicker } from '@/react/shared/components/cd-time-picker/CdTimePicker';
import { Weekdays } from '@/react/shared/models/taxonomy';

export const TRANSLATED_WEEKDAYS: Record<Weekdays, React.ReactNode> = {
  [Weekdays.Monday]: <Translation text="Monday" />,
  [Weekdays.Tuesday]: <Translation text="Tuesday" />,
  [Weekdays.Wednesday]: <Translation text="Wednesday" />,
  [Weekdays.Thursday]: <Translation text="Thursday" />,
  [Weekdays.Friday]: <Translation text="Friday" />,
  [Weekdays.Saturday]: <Translation text="Saturday" />,
  [Weekdays.Sunday]: <Translation text="Sunday" />,
};

type WeeklyScheduleProps = {
  value?: FormBookingHours;
  onChange?: (value: FormBookingHours) => void;
};

export const WeeklySchedule = ({ value, onChange }: WeeklyScheduleProps) => {
  const {
    expandedWeekdays,
    setExpandedWeekdays,
    onWeekdayEnabledChange,
    onTimeChange,
    onTimeRangeRemove,
    onTimeRangeAdd,
  } = useWeeklyScheduleEvents(value, onChange);

  const items: CollapseProps['items'] = Object.values(Weekdays).map(
    (weekDay) => {
      const currentData = value[weekDay];
      const overlap = doesIntervalsOverlap(currentData.data, weekDay);

      return {
        key: weekDay,
        label: (
          <Space>
            <div onClick={(e) => e.stopPropagation()}>
              <Switch
                checked={currentData.enabled}
                onChange={onWeekdayEnabledChange(weekDay)}
              />
            </div>
            <Typography.Text type={overlap ? 'danger' : undefined}>
              {TRANSLATED_WEEKDAYS[weekDay]}
            </Typography.Text>
            {overlap && <CdExclamationTriangleIcon type="danger" />}
            <Typography.Text
              type="secondary"
              style={{ maxWidth: 280 }}
              ellipsis
            >
              {currentData.enabled &&
                currentData.data?.length &&
                currentData.data
                  .map((item) => `${item.start} - ${item.end}`)
                  .join(', ')}
            </Typography.Text>
          </Space>
        ),
        collapsible: currentData.enabled ? undefined : 'disabled',
        showArrow: currentData.enabled,
        children: (
          <Space direction="vertical">
            {currentData.data.map((item: ScheduleItem, index) => (
              <Row
                align="middle"
                key={index + item.start?.toString() + item.end?.toString()}
              >
                <Space>
                  <CdTimePicker
                    id={'start' + weekDay + index}
                    value={item.start}
                    onChange={(value) =>
                      onTimeChange(weekDay, item)(value, 'start')
                    }
                    suffix={
                      <Typography.Text type="secondary">
                        <CdTime />
                      </Typography.Text>
                    }
                    beginLimit="00:00"
                    width={150}
                    status={
                      overlap?.intervals?.includes(item) ? 'error' : undefined
                    }
                  />
                  <Typography.Text>
                    {I18nService.getString(
                      'to',
                      undefined,
                      'indicating time span between two times e.g: 7:00 to 9:00'
                    )}
                  </Typography.Text>
                  <CdTimePicker
                    id={'end' + weekDay + index}
                    value={item.end}
                    onChange={(value) =>
                      onTimeChange(weekDay, item)(value, 'end')
                    }
                    suffix={
                      <Typography.Text type="secondary">
                        <CdTime />
                      </Typography.Text>
                    }
                    beginLimit="00:00"
                    width={150}
                    status={
                      overlap?.intervals?.includes(item) ? 'error' : undefined
                    }
                  />

                  <Button
                    type="text"
                    danger
                    icon={<CdDeleteIcon />}
                    style={{
                      visibility:
                        currentData.data.length > 1 ? 'visible' : 'hidden',
                    }}
                    onClick={onTimeRangeRemove(weekDay, item)}
                  />

                  {index === currentData.data.length - 1 && (
                    <Button
                      type="text"
                      icon={<CdPlus />}
                      onClick={onTimeRangeAdd(weekDay)}
                    />
                  )}
                </Space>
              </Row>
            ))}
            {overlap && (
              <Typography.Text type="danger">
                {overlap.type === 'same' &&
                  I18nService.getString(
                    'The start and end of an interval cannot be the same'
                  )}
                {overlap.type === 'overlap' &&
                  I18nService.getString(
                    'An interval is overlapping with another'
                  )}
                {overlap.type === 'start-higher' &&
                  I18nService.getString(
                    'An interval has a end time that is earlier than the start time'
                  )}
              </Typography.Text>
            )}
          </Space>
        ),
      };
    }
  );
  return (
    <Collapse
      items={items}
      expandIconPosition="end"
      activeKey={expandedWeekdays}
      onChange={setExpandedWeekdays as any}
    />
  );
};
