/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PutCalendarRequestBody = {
    visibility?: PutCalendarRequestBody.visibility;
    clientVersion?: number;
    preparationStartDate?: string | null;
    startDate?: string;
    endDate?: string;
    cleanupEndDate?: string | null;
    description?: string | null;
    summary?: string | null;
    rrule?: string | null;
    timezone?: string | null;
    taxonomies?: Array<number> | null;
    resources?: Array<number>;
    users?: Array<number>;
    files?: Array<{
        id: number;
    }>;
    calendar_tasks?: Array<{
        taskId: number;
        required?: number | null;
        note?: string | null;
    }>;
    shifts?: Array<{
        userId: number;
        taskId: number;
    }>;
    groupIds?: Array<number>;
    allowDoubleBooking?: boolean;
    sendNotifications?: boolean;
    notificationTarget?: (string | {
        taskId: number;
    });
    facebook?: ({
        publish?: any;
        isScheduled?: any;
    } | {
        publish?: any;
        isScheduled?: any;
        schedulingOptions?: {
            duration: number;
            durationUnit: PutCalendarRequestBody.durationUnit;
            hour: number;
            minute: number;
        };
        message?: string | null;
    }) | null;
    stole?: {
        stoleText: string;
        comment?: string | null;
        church: {
            id: number;
        };
        fee: {
            id: string;
        };
        resource?: {
            id: number;
        } | null;
        person?: {
            id: number;
        } | null;
        founder?: string | null;
        paymentMethod?: PutCalendarRequestBody.paymentMethod;
        paid?: boolean | null;
        paidAt?: string | null;
    } | null;
    form?: {
        id?: string;
        configuration?: {
            startDate?: string | null;
            endDate?: string | null;
            paymentMethod?: PutCalendarRequestBody.paymentMethod | null;
            numberOfTickets: number;
            maxTicketsPerOrder: number;
            pricePerTickets?: number;
            isPhoneNumberRequired?: boolean;
            askForAddress?: boolean;
            consentTypeIds?: Array<string>;
        };
    } | null;
    shiftsAndIntentions?: Array<{
        calendarId: number;
        shifts?: Array<{
            taskId: number;
            note?: string | null;
            users?: Array<{
                id: number;
            }>;
        }>;
        intentions?: Array<{
            id: string;
            priority?: 'persolviert' | 'weiterleitung';
        }>;
    }>;
    rotas?: Array<{
        taskId: number;
        required?: number | null;
    }>;
    /**
     * An alias for a URL, consisting only of English letters, numbers, and dashes, without any spaces or special characters.
     */
    alias?: string | null;
};
export namespace PutCalendarRequestBody {
    export enum visibility {
        INTERNAL = 'internal',
        PUBLIC = 'public',
        PRIVATE = 'private',
    }
    export enum durationUnit {
        DAY = 'day',
        WEEK = 'week',
        MONTH = 'month',
    }
    export enum paymentMethod {
        CASH = 'cash',
        BANK = 'bank',
    }
}

