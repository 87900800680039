import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { filter, isEmpty, lowerCase } from 'lodash';

import { organizationStatus } from '../screens/UserList';

import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';
import { CdClientSideTableApiSearchParam } from '@/react/shared/components/cd-client-side-table/types';
import { handleSuccessMessage } from '@/react/shared/utils';
import NotificationService from '@/react/services/NotificationService';
import { I18nService } from '@/react/services/I18nService';
import { sortString } from '@/react/shared/utils/sorter';
import { User } from '@/react/user/types/User.types';

export const useFilteredUserList = (params: CdClientSideTableApiSearchParam) =>
  useQuery({
    queryKey: ['useFilteredUserList', params],
    queryFn: async () => {
      const response = await mainApi.get<User[]>(`/users`);
      if (!response.ok) {
        handleError(response);
        return { items: [], total: 0 };
      }
      const currentFilter = params.extraData;
      const filteredData = filter(
        response.data,
        (user) =>
          currentFilter.status.includes(user.organizationStatus) &&
          (lowerCase(user.email).includes(lowerCase(currentFilter.search)) ||
            lowerCase(user.name).includes(lowerCase(currentFilter.search))) &&
          (isEmpty(currentFilter.roles) ||
            user.roles.some((role) => currentFilter.roles.includes(role)))
      ).sort((a, b) => sortString(a.name, b.name));
      return { items: filteredData, total: filteredData?.length };
    },
  });
export const useMutateTimeRegistration = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: async ({
      currentUserId,
      timeRegistrationEnabled,
      initialTimeBalance,
    }: {
      currentUserId: number;
      timeRegistrationEnabled: boolean;
      initialTimeBalance: number;
    }) => {
      const response = await mainApi.put(
        `/users/${currentUserId}/time-registrations/settings`,
        {
          timeRegistrationEnabled,
          initialTimeBalance,
        }
      );
      if (!response.ok) {
        handleError(response);
        throw response;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['useFilteredUserList'],
      });
      handleSuccessMessage(
        I18nService.getString('Time registration was saved successfully.')
      );
    },
  });

  return {
    updateTimeBalance: mutate,
  };
};

export const useSendUserEmail = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: async ({
      userId,
      subject,
      message,
    }: { userId: number; subject: string; message: string }) => {
      const response = await mainApi.post(`/users/${userId}/contact`, {
        subject: subject,
        message: message,
      });
      if (!response.ok) {
        handleError(response);
        return;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['useFilteredUserList'],
      });
      NotificationService.notifySuccess(
        I18nService.getString('Your message has been sent.')
      );
    },
  });
  return {
    sendUserEmail: mutate,
  };
};

export const useResendUserEmailInvite = () => {
  const { mutate } = useMutation({
    mutationFn: async ({
      userId,
      userStatus,
    }: { userId: number; userStatus: string }) => {
      const response = await mainApi.post(
        `/users/${userId}/resend/welcome-email`
      );
      if (!response.ok) {
        handleError(response);
        return;
      } else {
        userStatus === organizationStatus.CREATED_WITHOUT_LOGIN
          ? handleSuccessMessage('The invite has been sent.')
          : handleSuccessMessage('The invite has been resent.');
      }
    },
  });
  return {
    resendUserEmailInvite: mutate,
  };
};

export const useRemoveUserFromCurrentOrganization = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: async (userId: number) => {
      const response = await mainApi.delete(`/users/${userId}`);
      if (!response.ok) {
        handleError(response);
        return;
      } else {
        handleSuccessMessage('The user has been removed.');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['useFilteredUserList'],
      });
    },
  });
  return {
    handleRemoveUserFromCurrentOrganization: mutate,
  };
};

export const useUpdateUserStatus = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: async ({
      userId,
      userStatus,
    }: {
      userId: number;
      userStatus: number;
    }) => {
      const response = await mainApi.put(`/users/${userId}`, {
        status: userStatus,
      });
      if (!response.ok) {
        handleError(response);
        return;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['useFilteredUserList'],
      });
      NotificationService.notifySuccess(
        I18nService.getString('The user status has been changed.')
      );
    },
    onError: () => {
      NotificationService.notifyError(
        I18nService.getString('The user status could not be changed.')
      );
    },
  });
  return {
    mutateUserStatus: mutate,
  };
};

export const useGetUserParishRoles = (userId: number) => {
  const { data, isFetching } = useQuery({
    queryKey: ['useGetUserParishRoles', userId],
    queryFn: async () => {
      const response = await mainApi.get<any[]>(`/users/${userId}/churches`);
      if (!response.ok) {
        handleError(response);
        return {
          churchSwitches: {},
          church: {},
        };
      }

      const { churchSwitches, church } = response.data.reduce(
        (acc, parishRole) => {
          parishRole.roles.forEach((role) => {
            const churchId = parishRole.church.id;
            const roleId = role.id;

            acc.churchSwitches[roleId] = true;

            if (!acc.church[roleId]) {
              acc.church[roleId] = [churchId];
            } else {
              acc.church[roleId].push(churchId);
            }
          });

          return acc;
        },
        {
          churchSwitches: {},
          church: {},
        }
      );

      return {
        churchSwitches,
        church,
      };
    },
  });

  return {
    churchSwitches: data?.churchSwitches,
    church: data?.church,
    isLoadingUserPermissions: isFetching,
  };
};

export const useGetUserOrganizationRoles = (userId: number) => {
  const { data, isFetching } = useQuery({
    queryKey: ['useGetUserOrganizationRoles', userId],
    queryFn: async () => {
      const response = await mainApi.get<any[]>(`/users/${userId}/roles`);
      if (!response.ok) {
        handleError(response);
        return [];
      }

      return response.data.reduce((acc, { id }) => {
        acc[id] = true;
        return acc;
      }, {});
    },
  });

  return {
    organizationRoles: data,
    isLoadingUserOrgRoles: isFetching,
  };
};

export const useMutateUserPermissions = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: async ({
      userId,
      payloadAsArray,
    }: {
      userId: number;
      payloadAsArray: string[];
    }) => {
      const response = await mainApi.put(
        `/users/${userId}/churches`,
        payloadAsArray
      );
      if (!response.ok) {
        NotificationService.notifyError((response as any)?.data?.message);
        throw response;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['useFilteredUserList'],
      });
      NotificationService.notifySuccess(
        I18nService.getString('User permission saved.')
      );
    },
  });

  return {
    updateUserPermissions: mutate,
  };
};

export const useMutateOrganizationRoles = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: async ({
      userId,
      payload,
    }: {
      userId: number;
      payload: any;
    }) => {
      const response = await mainApi.put(`/users/${userId}/roles`, payload);
      if (!response.ok) {
        handleError(response);
        throw response;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['useFilteredUserList'],
      });
    },
  });

  return {
    updateUserOrgRoles: mutate,
  };
};
