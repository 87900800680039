import { mainApi } from '../../api';
import { Taxonomy } from '../../shared/models/taxonomy';

class TaxonomiesService {
  public loadData: () => Promise<Taxonomy[]> = async () => {
    const response = await mainApi.get(`/taxonomies`);
    if (!response.ok) {
      throw { code: response.status, error: response.originalError };
    }
    return response.data as Taxonomy[];
  };
}

export default new TaxonomiesService();

export enum WeekdayName {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}
