import {
  createAction,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';

import { AppState } from '../../../redux';
import {
  createActionDispatcherHook,
  createActionValueHook,
} from '../../../redux/utils';
import { Group } from '../../../shared/services/GroupsService';
import { selectAllGroups, selectGroupIds } from '../../../shared/store/groups';
import { selectUserById } from '../../../user/redux/selectors';
import { FEvent } from '../../models/calendar';

import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';

// Types
export interface GroupAbsence {
  [groupId: number]: boolean;
}
export interface AbsenceState {
  groupAbsences: GroupAbsence;
  isMinimizedView: boolean;
  openedAbsencePopover: string | false;
  absences: EntityState<FEvent>;
}

// Entities
const absencesAdapter = createEntityAdapter<FEvent>({
  selectId: (absence) => absence.id,
});

// Initial State
const initialState: AbsenceState = {
  groupAbsences: {},
  isMinimizedView: true,
  openedAbsencePopover: false,
  absences: absencesAdapter.getInitialState(),
};

// Slice
const absenceSlice = createSlice({
  name: 'absences',
  initialState,
  reducers: {
    initializeGroupAbsence(
      state: AbsenceState,
      action: PayloadAction<AbsenceState>
    ) {
      const { groupAbsences, isMinimizedView } = action.payload;
      state.groupAbsences = groupAbsences;
      state.isMinimizedView = isMinimizedView;
    },
    toggleGroupAbsence(state: AbsenceState, action: PayloadAction<number>) {
      state.groupAbsences[action.payload] =
        !state.groupAbsences[action.payload];
    },
    toggleMinimizedView(state: AbsenceState) {
      state.isMinimizedView = !state.isMinimizedView;
    },
    toggleAllGroupAbsenceRequest() {},
    toggleAllGroupAbsenceSuccess(
      state: AbsenceState,
      action: PayloadAction<{ groupIds: number[]; checked: boolean }>
    ) {
      const { groupIds, checked } = action.payload;
      _.forEach(groupIds, (groupId) => {
        state.groupAbsences[groupId] = checked;
      });
    },
    absencesLoaded(state: AbsenceState, { payload }: PayloadAction<FEvent[]>) {
      state.absences = absencesAdapter.setAll(state.absences, payload);
    },
    changeOpenedAbsencePopover(
      state: AbsenceState,
      { payload }: PayloadAction<string | false>
    ) {
      state.openedAbsencePopover = payload;
    },
    setGroupAbsences(
      state: AbsenceState,
      { payload }: PayloadAction<GroupAbsence>
    ) {
      state.groupAbsences = payload;
    },
  },
});

export const absenceReducer = absenceSlice.reducer;

// Actions

export const AbsencesActions = {
  loadAbsence: createAction<number[]>('absences/loadAbsence'),
  ...absenceSlice.actions,
};

// ToDo export Selectors as a single const
// Selectors
const absencesSelectors = absencesAdapter.getSelectors<AppState>(
  (state) => state.calendar.absences.absences
);

const currentUserId = (state: AppState) => state.config.me.id;

const absenceEntities = absencesSelectors.selectEntities;

const absenceById = (absenceId: number) => (state: AppState) =>
  absencesSelectors.selectById(state, absenceId);

const absenceUser = (absenceId: number) => (state: AppState) => {
  const absence = absencesSelectors.selectById(state, absenceId);
  return absence && absence.users && absence.users[0]
    ? // @ts-ignore: Typings are wrong.
      selectUserById(absence.users[0].id)(state)
    : false;
};

const absencesRootSelector = (state: AppState) => state.calendar.absences;

export const isMinimizedView = createSelector(
  absencesRootSelector,
  (absences) => absences.isMinimizedView
);

const isGroupedAbsenceOpened = (id: string) =>
  createSelector(
    absencesRootSelector,
    (absences) => absences.openedAbsencePopover === id
  );

const isGroupAbsenceActive = (groupId: number) =>
  createSelector(
    absencesRootSelector,
    (absences) => absences.groupAbsences[groupId]
  );

const allAbsenceGroups = createSelector(selectAllGroups, (groups) =>
  _.filter(groups, (group: Group) => !group.absenceDisabled)
);

const myGroups = createSelector(
  currentUserId,
  selectAllGroups,
  (currentUseId, groups) =>
    groups &&
    _.sortBy(
      groups.filter((group) => group.members.includes(currentUseId)),
      (group) => _.toUpper(group.name)
    )
);

const otherGroups = createSelector(
  currentUserId,
  allAbsenceGroups,
  (currentUseId, groups) =>
    _.sortBy(
      groups.filter((group) => !group.members.includes(currentUseId)),
      (group) => _.toUpper(group.name)
    )
);

export const selectGroupsAbsences = createSelector(
  absencesRootSelector,
  (absences) => absences.groupAbsences
);

const allGroupsIds = selectGroupIds;
// get selected group ids if they are not removed from groups
const selectedGroupIds = createSelector(
  selectGroupsAbsences,
  allGroupsIds,
  (groupAbsences, groupIds) =>
    _.map(
      _.pickBy(_.pick(groupAbsences, groupIds), (value) => value),
      (_value, key) => parseInt(key)
    )
);

export const isAllGroupsSelected = (lowerCaseSearchTerm: string) =>
  createSelector(
    selectedGroupIds,
    selectAllGroups,
    (currentSelected, allGroups) => {
      const visibleGroups = !lowerCaseSearchTerm
        ? allGroups
        : allGroups.filter((group) =>
            group.name.toLowerCase().includes(lowerCaseSearchTerm)
          );
      const visibleSelected = currentSelected.filter((groupId) =>
        visibleGroups.some((g) => g.id === groupId)
      );
      const isAllSelected =
        visibleSelected.length > 0 &&
        visibleSelected.length === visibleGroups.length;
      return isAllSelected
        ? isAllSelected
        : visibleSelected.length > 0
          ? null
          : false;
    }
  );

export const AbsenceSelectors = {
  absenceEntities,
  absenceById,
  absenceUser,
  absencesRootSelector,
  isMinimizedView,
  isGroupedAbsenceOpened,
  isGroupAbsenceActive,
  allAbsenceGroups,
  myGroups,
  otherGroups,
  groupsAbsences: selectGroupsAbsences,
  allGroupsIds,
  selectedGroupIds,
  isAllGroupsSelected,
};

// Hooks
export const useIsMinimizedView = createActionValueHook(
  AbsencesActions.toggleMinimizedView,
  isMinimizedView
);

export const useDispatchToggleIsMinimizedView = createActionDispatcherHook(
  AbsencesActions.toggleMinimizedView
);

export const useIsAllGroupsSelected = (lowerCaseSearchTerm?: string) =>
  createActionValueHook(
    AbsencesActions.toggleAllGroupAbsenceRequest,
    isAllGroupsSelected(lowerCaseSearchTerm)
  );

export const useLoadMultipleAbsencesDispatcher = createActionDispatcherHook(
  AbsencesActions.loadAbsence
);

export const useGetMyGroups = () => useSelector(myGroups);

export const useGetMyAbsenceGroups = () => {
  const query = useQuery({
    queryKey: ['useGetMyAbsenceGroups'],
    queryFn: () => getGroups('my-groups'),
  });

  return query.data;
};

export const useGetViewAbsenceGroups = () => {
  const query = useQuery({
    queryKey: ['useGetViewAbsenceGroups'],
    queryFn: () => getGroups('view-absence'),
  });

  return query.data;
};

export const useToggleOpenedAbsencePopover = createActionDispatcherHook(
  AbsencesActions.changeOpenedAbsencePopover
);

const Filters = ['my-groups', 'view-absence'] as const;
export const getGroups = async (filter: (typeof Filters)[number]) => {
  try {
    const response = await mainApi.get<
      { id: number; name: string; organizationId: number }[]
    >(`/users/groups?filter=${filter}`);
    if (!response.ok) throw response.data;
    return response.data;
  } catch (err) {
    handleError(err);
    return [];
  }
};
