import { locationNameArticle } from '@/react/homepage/utils/support-articles';
(function () {
  'use strict';

  function ResourcesController(
    _,
    $scope,
    $rootScope,
    $window,
    $uibModal,
    Taxonomies,
    Resources,
    $state,
    gettextCatalog,
    Partnership,
    cdApp,
    Authorization,
    appUtils
  ) {
    Partnership.categories.query().$promise.then(function (categories) {
      $scope.partnerships = categories;
    });
    $scope.hasIntentionsPackage = Authorization.hasPackage('intentions');

    function getSortedResources(resources) {
      const standaloneResources = _.filter(
        resources,
        (resource) => !_.get(resource, 'type')
      );

      const sortedStandaloneResources = _.sortBy(
        standaloneResources,
        (standaloneResource) => _.toLower(_.get(standaloneResource, 'name'))
      );

      const churches = _.filter(
        resources,
        (resource) => _.get(resource, 'type') === 'church'
      );

      let sortedChurches = [];
      let singleChurchResources = [];
      if (_.size(churches) > 1) {
        sortedChurches = _.sortBy(churches, (church) =>
          _.toLower(_.get(church, 'name'))
        );

        _.each(sortedChurches, (sortedChurch) => {
          const childResources = _.get(sortedChurch, 'resources');
          const sortedChildResources = _.sortBy(
            childResources,
            (childResource) => _.toLower(_.get(childResource, 'name'))
          );

          _.set(sortedChurch, 'resources', sortedChildResources);
        });
      } else if (_.size(churches) === 1) {
        singleChurchResources = _.chain(churches)
          .first()
          .get('resources')
          .sortBy((childResource) => _.toLower(_.get(childResource, 'name')))
          .map((childResource) => _.omit(childResource, 'parentResourceId'))
          .value();
      }
      return [
        ...sortedChurches,
        ...singleChurchResources,
        ...sortedStandaloneResources,
      ];
    }
    function getTreeFlatData(resources) {
      const sortedResources = getSortedResources(resources);
      const items = _.reduce(
        sortedResources,
        (result, parent) => {
          if (!Array.isArray(result)) {
            result.isLooseResource =
              _.isNil(result.parentResourceId) && result.type !== 'church';
            result.isParent =
              _.isNil(result.parentResourceId) && result.type === 'church';
            const childrenFirst = result.resources
              ? _.get(result, 'resources')
              : [];
            result = result.isLooseResource
              ? [result, ...childrenFirst]
              : [result, ...childrenFirst, { isSeparator: true }];
          }
          parent.isLooseResource =
            _.isNil(parent.parentResourceId) && parent.type !== 'church';
          parent.isParent =
            _.isNil(parent.parentResourceId) && parent.type === 'church';
          const children = parent.resources ? _.get(parent, 'resources') : [];
          return parent.isLooseResource
            ? [...result, parent, ...children]
            : [...result, parent, ...children, { isSeparator: true }];
        },
        []
      );

      $scope.hasPubliclyVisible = _.some(items, 'isRoom');
      return items;
    }

    $scope.loading = true;
    function initData() {
      Resources.query(function (list) {
        $scope.list.resources = getTreeFlatData(list);
        $scope.loading = false;
      });
    }

    $scope.colors = Taxonomies.colors;
    $scope.list = {};
    $scope.showChurchSelector = cdApp.showChurchSelector;
    $scope.appUtils = appUtils;
    initData();

    /* ============================================================================
     ** Modals
     ** ==========================================================================*/

    // Delete taxonomies.
    $scope.delete = (taxonomy) => {
      $state.go('app.private.settings.resources.delete', {
        id: taxonomy.id,
      });
    };

    // Create taxonomies.
    $scope.create = function (type) {
      const hasMultiParishPackage = Authorization.hasPackage('multiParish');
      // Display new customer multi parish modal if they are trying to create their first church and they dont have multi parish
      if (type === 'church' && !hasMultiParishPackage) {
        $uibModal
          .open({
            component: 'cdNewMultiParishModal',
          })
          .result.then(() => {
            $state.go('app.private.settings.resources.create', { type });
          });
      } else {
        $state.go('app.private.settings.resources.create', { type });
      }
    };

    // Edit taxonomies.
    $scope.edit = function (taxonomy) {
      const type = taxonomy.type === 'church' ? 'church' : 'resource';
      $state.go('app.private.settings.resources.edit', {
        id: taxonomy.id,
        type,
      });
    };

    /**
     * Export
     */
    $scope.export = function (type, taxonomy) {
      $uibModal.open({
        component: 'cdCalendarFeedModal',
        resolve: {
          feedName: () =>
            gettextCatalog.getString('Subscribe to calendar') +
            (_.isEmpty(taxonomy.name) ? '' : ` "${taxonomy.name}"`),
          feedId: () => taxonomy.id,
          feedType: () => type,
          showPublicFeed: () => true,
          token: [
            '$q',
            '$http',
            ($q, $http) =>
              $q((resolve, reject) => {
                $http
                  .get(`${cdApp.config.api.main}/ical/token`)
                  .then((res) => resolve(res.data))
                  .catch(reject);
              }),
          ],
        },
      });
    };

    $scope.supportArticle = function () {
      const organizationLanguage = _.get(cdApp, 'organization.locale.language');
      const helpLink = locationNameArticle(organizationLanguage);

      // Redirect to connect onboarding form.
      if (helpLink) {
        $window.open(helpLink, '_blank');
      }
    };

    /**
     * Copy resource name to location name
     */
    $scope.copyResourceName = function () {
      Resources.copyResourceNameToLocationName().$promise.then(() => {
        // Make sure to load fully, to hide the button.
        $window.location.reload();
      });
    };

    $rootScope.$on('RefreshTaxonomies', initData);
  }

  ResourcesController.$inject = [
    '_',
    '$scope',
    '$rootScope',
    '$window',
    '$uibModal',
    'Taxonomies',
    'Resources',
    '$state',
    'gettextCatalog',
    'Partnership',
    'cdApp',
    'Authorization',
    'appUtils',
  ];

  angular
    .module('cdApp.settings')
    .controller('ResourcesController', ResourcesController);
})();
