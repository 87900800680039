import React, { Suspense } from 'react';

import AuthorizationService from '../services/AuthorizationService';
import AuthenticationService from '../services/AuthenticationService';
import { I18nService } from '../services/I18nService';
import { CdFallbackPage } from '../shared/components/CdFallbackPage';

import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import cdApp from '@/react/config';
export const ProductLandingPage = ({
  packageName,
}: { packageName: string }) => {
  let language = cdApp.organization.locale?.language;
  const needSSOLogin =
    cdApp.organization?.hasMemberRegistryIntegration &&
    cdApp.me?.authMethod !== 'sso';
  if (language === 'en-gb') {
    language = 'en';
  }
  // @ts-ignore
  const hasPackage = AuthorizationService.hasPackage(packageName);
  let iframeUrl: string = '';
  switch (packageName) {
    case 'calendar':
      iframeUrl = `https://churchdesk.com/${language}/app-calendar/`;
      break;
    case 'people':
      iframeUrl = `https://churchdesk.com/${language}/app-people/`;
      break;
    case 'forms':
      iframeUrl = `https://churchdesk.com/${language}/app-forms/`;
      break;
    case 'contributions':
      iframeUrl = `https://churchdesk.com/${language}/app-payments/`;
      break;
    case 'intentions':
      iframeUrl = 'https://churchdesk.com/de/app-intentionen/';
      break;
    case 'homepage':
      iframeUrl = `https://churchdesk.com/${language}/app-website/`;
      break;
    case 'safeguarding':
      iframeUrl = 'https://churchdesk.com/de/app-safeguarding/';
      break;
    case 'intranet':
      iframeUrl = `https://churchdesk.com/${language}/app-groups/`;
      break;
    case 'rotas':
      iframeUrl = `https://churchdesk.com/${language}/app-rota/`;
      break;
    default:
      break;
  }
  const getPackageName = (keyName: string) => {
    let name: string = '';
    switch (keyName) {
      case 'calendar':
        name = I18nService.getString('Calendar', {}, 'The name of the app');
        break;
      case 'people':
        name = I18nService.getString('People', {}, 'The name of the app');
        break;
      case 'forms':
        name = I18nService.getString('Forms', {}, 'The name of the app');
        break;
      case 'contributions':
        name = I18nService.getString(
          'Contributions',
          {},
          'The name of the app'
        );
        break;
      case 'intentions':
        name = I18nService.getString('Intentions', {}, 'The name of the app');
        break;
      case 'homepage':
        name = I18nService.getString('Website', {}, 'The name of the app');
        break;
      case 'safeguarding':
        name = I18nService.getString('Safeguarding', {}, 'The name of the app');
        break;
      case 'intranet':
        name = I18nService.getString('Rotas', {}, 'The name of the app');
        break;
      case 'anniversary':
        name = I18nService.getString('Anniversary', {}, 'The name of the app');
        break;
      default:
        break;
    }
    return name;
  };

  return !hasPackage ? (
    <iframe
      data-testid="productLandingPageIframe"
      src={iframeUrl}
      style={{
        width: '100%',
        height: '100%',
        border: '0px',
      }}
    ></iframe>
  ) : (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {needSSOLogin && packageName === 'people' ? (
        <SSOLandingPageForPeopleAccessDenied />
      ) : (
        <CdFallbackPage
          title={I18nService.getString(
            'You do not have access to {{product}}.',
            {
              product: getPackageName(packageName),
            }
          )}
          subtitle={I18nService.getString(
            'Please ask your organization administrator to grant you the {{product}} access permission.',
            {
              product: getPackageName(packageName),
            }
          )}
          hideButton={true}
          hasBody={false}
        />
      )}
    </div>
  );
};

export const ProductLandingPageWrapper = ({ $stateParams }) => (
  <Suspense fallback={<CdrPageLoader marginLess />}>
    <ProductLandingPage packageName={$stateParams?.packageName} />
  </Suspense>
);

const SSOLandingPageForPeopleAccessDenied = () => (
  <CdFallbackPage
    title={I18nService.getString(
      'Only users who login with SSO can view/access extended contact data in the people module'
    )}
    subtitle={I18nService.getString('Please login again with SSO.')}
    hideButton={false}
    hasBody={false}
    buttonText={I18nService.getString('Login with SSO')}
    onButtonClick={() => AuthenticationService.logout(true)}
  />
);
