import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../redux';
import { I18nService } from '../../services/I18nService';
import { ToggleShowCollections } from '../store/main-view/Actions';

import { SearchableFilterItem } from '@/react/calendar/components/SearchableFilterItem';

const CollectionFilter: FunctionComponent<{ isDisabled: boolean }> = ({
  isDisabled,
}) => {
  const dispatch = useDispatch();
  const onToggleCollections = useCallback(
    () => dispatch(ToggleShowCollections()),
    [dispatch]
  );
  const showCollecte = useSelector(
    (state: AppState) => state.calendar.view.showCollections
  );
  return (
    <div>
      <SearchableFilterItem
        isDisabled={isDisabled}
        onLabelClick={() => {
          if (isDisabled) return;
          onToggleCollections();
        }}
        isSelected={showCollecte}
        color="fallback"
        name={I18nService.getString('Collection')}
      />
    </div>
  );
};

export default CollectionFilter;
