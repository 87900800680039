/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PostPContributionsPosterRequestBody } from '../models/PostPContributionsPosterRequestBody';
import type { PostPContributionsRequestBody } from '../models/PostPContributionsRequestBody';
import type { PostPPaymentsCompletepaymentRequestBody } from '../models/PostPPaymentsCompletepaymentRequestBody';
import type { PostPPaymentsInitiatepaymentRequestBody } from '../models/PostPPaymentsInitiatepaymentRequestBody';
import type { PostPPaymentsRequestBody } from '../models/PostPPaymentsRequestBody';
import type { PostPPaymentsTicketsRequestBody } from '../models/PostPPaymentsTicketsRequestBody';
import type { PostPV2PaymentsCompletepaymentRequestBody } from '../models/PostPV2PaymentsCompletepaymentRequestBody';
import type { PutOrganizationsCapsRequestBody } from '../models/PutOrganizationsCapsRequestBody';
import type { PutOrganizationsDioceseRequestBody } from '../models/PutOrganizationsDioceseRequestBody';
import type { PutPContributionsRequestBody } from '../models/PutPContributionsRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DefaultService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @throws ApiError
     */
    public getApiEvents(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/events/{id}',
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV200Blogs(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2.0.0/blogs',
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV200BlogsCategories(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2.0.0/blogs/categories',
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV200Events(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2.0.0/events',
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV200EventsCategories(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2.0.0/events/categories',
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV200EventsResources(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2.0.0/events/resources',
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV200Events1({
        partnerToken,
    }: {
        partnerToken: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2.0.0/events/{id}',
            query: {
                'partnerToken': partnerToken,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV200Galleries(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2.0.0/galleries',
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV200Galleries1(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2.0.0/galleries/{id}',
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV200Info(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2.0.0/info',
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV200Messages(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2.0.0/messages',
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV200Newsletters(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2.0.0/newsletters',
        });
    }
    /**
     * @throws ApiError
     */
    public postApiV200NewslettersSubscribe(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2.0.0/newsletters/subscribe',
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV300Blogs(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v3.0.0/blogs',
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV300BlogsCategories(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v3.0.0/blogs/categories',
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV300Events(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v3.0.0/events',
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV300EventsCategories(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v3.0.0/events/categories',
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV300EventsResources(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v3.0.0/events/resources',
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV300Events1({
        partnerToken,
    }: {
        partnerToken: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v3.0.0/events/{id}',
            query: {
                'partnerToken': partnerToken,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV300Galleries(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v3.0.0/galleries',
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV300Galleries1(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v3.0.0/galleries/{id}',
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV300Info(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v3.0.0/info',
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV300Messages(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v3.0.0/messages',
        });
    }
    /**
     * @throws ApiError
     */
    public getApiV300Newsletters(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v3.0.0/newsletters',
        });
    }
    /**
     * @throws ApiError
     */
    public postApiV300NewslettersSubscribe(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v3.0.0/newsletters/subscribe',
        });
    }
    /**
     * @throws ApiError
     */
    public getCalendarPrint({
        includeSensitiveInfo,
        id,
    }: {
        includeSensitiveInfo: boolean,
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/{id}/print',
            path: {
                'id': id,
            },
            query: {
                'includeSensitiveInfo': includeSensitiveInfo,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getCalendarPrintDownloadToken({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/{id}/print-download-token',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getChurcheventsRedirectDirectjoin534B072C4Eb25(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/churchevents-redirect/directjoin/534/b072c4eb25',
        });
    }
    /**
     * @throws ApiError
     */
    public getChurcheventsRedirect(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/churchevents-redirect/{ignoredPath}',
        });
    }
    /**
     * @throws ApiError
     */
    public getChurchscreenBlogs(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/churchscreen/blogs',
        });
    }
    /**
     * @throws ApiError
     */
    public getChurchscreenBlogsCategories(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/churchscreen/blogs/categories',
        });
    }
    /**
     * @throws ApiError
     */
    public getChurchscreenEvents(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/churchscreen/events',
        });
    }
    /**
     * @throws ApiError
     */
    public getChurchscreenEventsCategories(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/churchscreen/events/categories',
        });
    }
    /**
     * @throws ApiError
     */
    public getChurchscreenEventsResources(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/churchscreen/events/resources',
        });
    }
    /**
     * @throws ApiError
     */
    public getChurchscreenEvents1({
        partnerToken,
    }: {
        partnerToken: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/churchscreen/events/{id}',
            query: {
                'partnerToken': partnerToken,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getChurchscreenGalleries(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/churchscreen/galleries',
        });
    }
    /**
     * @throws ApiError
     */
    public getChurchscreenGalleries1(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/churchscreen/galleries/{id}',
        });
    }
    /**
     * @throws ApiError
     */
    public getChurchscreenInfo(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/churchscreen/info',
        });
    }
    /**
     * @throws ApiError
     */
    public getChurchscreenMessages(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/churchscreen/messages',
        });
    }
    /**
     * @throws ApiError
     */
    public getChurchscreenNewsletters(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/churchscreen/newsletters',
        });
    }
    /**
     * @throws ApiError
     */
    public postChurchscreenNewslettersSubscribe(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/churchscreen/newsletters/subscribe',
        });
    }
    /**
     * @throws ApiError
     */
    public getP(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p',
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributions(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions',
        });
    }
    /**
     * @throws ApiError
     */
    public postPContributions({
        requestBody,
    }: {
        requestBody?: PostPContributionsRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/p/contributions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsActiveDonations(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/active-donations',
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsAnnualDonations({
        year,
    }: {
        year: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/annual-donations',
            query: {
                'year': year,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsInternalList(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/internal/list',
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsMetrics({
        apiKey,
        organizationIDs,
    }: {
        apiKey: string,
        organizationIDs: (Array<number> | number),
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/metrics',
            query: {
                'apiKey': apiKey,
                'organizationIDs': organizationIDs,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsPosterDownload(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/poster/{id}/download',
        });
    }
    /**
     * @throws ApiError
     */
    public postPContributionsPostersLogoUpload(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/p/contributions/posters/logo/upload',
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsPublicQrcode({
        contributionAlias,
        type = 'contribution',
    }: {
        contributionAlias?: string,
        type?: 'contribution' | 'landingPage',
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/public/qrcode',
            query: {
                'contributionAlias': contributionAlias,
                'type': type,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsPublic(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/public/{alias}',
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsQrcode({
        contributionAlias,
        type = 'contribution',
    }: {
        contributionAlias?: string,
        type?: 'contribution' | 'landingPage',
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/qrcode',
            query: {
                'contributionAlias': contributionAlias,
                'type': type,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsReportDownloadToken(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/report/download-token',
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsReportDownload({
        downloadToken,
        from,
        to,
    }: {
        downloadToken: string,
        from: string,
        to: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/report/{contributionId}/download',
            query: {
                'downloadToken': downloadToken,
                'from': from,
                'to': to,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsDonations(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/{contributionId}/donations/{formId}',
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsExportFile(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/{contributionId}/export/file',
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsExportPreview(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/{contributionId}/export/preview',
        });
    }
    /**
     * @throws ApiError
     */
    public postPContributionsPoster({
        requestBody,
    }: {
        requestBody?: PostPContributionsPosterRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/p/contributions/{contributionId}/poster',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsPosterPreview({
        backgroundColor,
        fontColor,
        actionColor,
        imageId,
    }: {
        backgroundColor: string,
        fontColor: string,
        actionColor: string,
        imageId?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/{contributionId}/poster/preview',
            query: {
                'backgroundColor': backgroundColor,
                'fontColor': fontColor,
                'actionColor': actionColor,
                'imageId': imageId,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsPosterToken(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/{contributionId}/poster/token',
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsSubscriptionsStats({
        contributionId,
    }: {
        contributionId: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/{contributionId}/subscriptions/stats',
            path: {
                'contributionId': contributionId,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public deletePContributionsSubscriptions({
        contributionId,
        subscriptionId,
    }: {
        contributionId: string,
        subscriptionId: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/p/contributions/{contributionId}/subscriptions/{subscriptionId}',
            path: {
                'contributionId': contributionId,
                'subscriptionId': subscriptionId,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsSubscriptionsPayments({
        contributionId,
        subscriptionId,
        limit = 20,
        pageNumber = 1,
    }: {
        contributionId: string,
        subscriptionId: string,
        limit?: number,
        pageNumber?: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/{contributionId}/subscriptions/{subscriptionId}/payments',
            path: {
                'contributionId': contributionId,
                'subscriptionId': subscriptionId,
            },
            query: {
                'limit': limit,
                'pageNumber': pageNumber,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsSubscriptionsPublic({
        contributionId,
        subscriptionId,
    }: {
        contributionId: string,
        subscriptionId: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/{contributionId}/subscriptions/{subscriptionId}/public',
            path: {
                'contributionId': contributionId,
                'subscriptionId': subscriptionId,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public deletePContributionsSubscriptionsPublic({
        token,
        contributionId,
        subscriptionId,
    }: {
        token: string,
        contributionId: string,
        subscriptionId: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/p/contributions/{contributionId}/subscriptions/{subscriptionId}/public',
            path: {
                'contributionId': contributionId,
                'subscriptionId': subscriptionId,
            },
            query: {
                'token': token,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsTicketProject(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/{formId}/ticket/project',
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributions1(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/{uuid}',
        });
    }
    /**
     * @throws ApiError
     */
    public putPContributions({
        requestBody,
    }: {
        requestBody?: PutPContributionsRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/p/contributions/{uuid}',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @throws ApiError
     */
    public deletePContributions(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/p/contributions/{uuid}',
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsPayments({
        limit = 20,
        pageNumber = 1,
        filters,
        clientVersion = 1,
        uuid,
    }: {
        limit?: number,
        pageNumber?: number,
        filters?: {
            terminalId?: string;
            paymentTypes?: Array<'CARD' | 'TERMINAL' | 'APPLEPAY' | 'GOOGLEPAY' | 'SEPA' | 'MOBILEPAY'>;
        },
        clientVersion?: number,
        uuid?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/{uuid}/payments',
            path: {
                'uuid': uuid,
            },
            query: {
                'limit': limit,
                'pageNumber': pageNumber,
                'filters': filters,
                'clientVersion': clientVersion,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsStats(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/{uuid}/stats',
        });
    }
    /**
     * @throws ApiError
     */
    public getPContributionsSubscriptions({
        limit = 20,
        pageNumber = 1,
        filters,
        uuid,
    }: {
        limit?: number,
        pageNumber?: number,
        filters?: {
            status?: Array<string>;
        },
        uuid?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/contributions/{uuid}/subscriptions',
            path: {
                'uuid': uuid,
            },
            query: {
                'limit': limit,
                'pageNumber': pageNumber,
                'filters': filters,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public postPFiles(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/p/files',
        });
    }
    /**
     * @throws ApiError
     */
    public getPFiles(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/files/{uuid}',
        });
    }
    /**
     * @throws ApiError
     */
    public deletePFiles(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/p/files/{uuid}',
        });
    }
    /**
     * @throws ApiError
     */
    public postPOrganizationsAddBankaccount(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/p/organizations/add/bankaccount',
        });
    }
    /**
     * @throws ApiError
     */
    public getPOrganizationsVerificationLinks(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/organizations/verification/links',
        });
    }
    /**
     * @throws ApiError
     */
    public getPOrganizationsChargingStatus(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/organizations/{organizationId}/charging/status',
        });
    }
    /**
     * @throws ApiError
     */
    public getPOrganizationsStatus(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/organizations/{organizationId}/status',
        });
    }
    /**
     * @throws ApiError
     */
    public getPOrganizationsStripeSession(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/organizations/{organizationId}/stripe/session',
        });
    }
    /**
     * @throws ApiError
     */
    public getPOrganizationsStripeSessionNotification(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/organizations/{organizationId}/stripe/sessionNotification',
        });
    }
    /**
     * @throws ApiError
     */
    public postPPayments({
        requestBody,
    }: {
        requestBody?: PostPPaymentsRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/p/payments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @throws ApiError
     */
    public getPPaymentsConfig(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/payments/config',
        });
    }
    /**
     * @throws ApiError
     */
    public getPPaymentsConnectionToken(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/payments/connection-token',
        });
    }
    /**
     * @throws ApiError
     */
    public postPPaymentsInitiatePayment({
        requestBody,
    }: {
        requestBody?: PostPPaymentsInitiatepaymentRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/p/payments/initiate-payment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @throws ApiError
     */
    public getPPaymentsSubmissionsData(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/payments/submissions/data',
        });
    }
    /**
     * @throws ApiError
     */
    public getPPaymentsSummary(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/payments/summary',
        });
    }
    /**
     * @throws ApiError
     */
    public postPPaymentsTickets({
        requestBody,
    }: {
        requestBody?: PostPPaymentsTicketsRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/p/payments/tickets',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @throws ApiError
     */
    public postPPaymentsCompletePayment({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PostPPaymentsCompletepaymentRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/p/payments/{id}/complete-payment',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @throws ApiError
     */
    public postPPaymentsRefund({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/p/payments/{id}/refund',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getPPaymentsData(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/payments/{submissionId}/data',
        });
    }
    /**
     * @throws ApiError
     */
    public getPTransfersBalanceHistory(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/transfers/balance/history',
        });
    }
    /**
     * @throws ApiError
     */
    public getPTransfersBalanceHistoryDownloadToken(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/transfers/balance/history/download-token',
        });
    }
    /**
     * @throws ApiError
     */
    public getPTransfersBalanceHistoryExport(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/transfers/balance/history/export',
        });
    }
    /**
     * @throws ApiError
     */
    public getPTransfersBalanceHistoryReportDownload({
        latest,
        earliest,
        fileType,
        downloadToken,
    }: {
        latest: number,
        earliest: number,
        fileType: string,
        downloadToken: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/transfers/balance/history/report/download',
            query: {
                'latest': latest,
                'earliest': earliest,
                'fileType': fileType,
                'downloadToken': downloadToken,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getPTransfersReportInvoiceDownloadToken(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/transfers/report-invoice/download-token',
        });
    }
    /**
     * @throws ApiError
     */
    public getPTransfersExport(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/transfers/{transferId}/export',
        });
    }
    /**
     * @throws ApiError
     */
    public getPTransfersFeesInvoice({
        transferId,
    }: {
        transferId: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/transfers/{transferId}/fees/invoice',
            path: {
                'transferId': transferId,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getPTransfersFeesInvoiceDownload({
        downloadToken,
        transferId,
    }: {
        downloadToken: string,
        transferId: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/transfers/{transferId}/fees/invoice/download',
            path: {
                'transferId': transferId,
            },
            query: {
                'downloadToken': downloadToken,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getPTransfersReport({
        transferId,
    }: {
        transferId: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/transfers/{transferId}/report',
            path: {
                'transferId': transferId,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getPTransfersReportDownload({
        downloadToken,
        transferId,
    }: {
        downloadToken: string,
        transferId: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/transfers/{transferId}/report/download',
            path: {
                'transferId': transferId,
            },
            query: {
                'downloadToken': downloadToken,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getPTransfersTransactions(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/transfers/{transferId}/transactions',
        });
    }
    /**
     * @throws ApiError
     */
    public postPV2PaymentsCompletePayment({
        requestBody,
    }: {
        requestBody?: PostPV2PaymentsCompletepaymentRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/p/v2/payments/complete-payment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @throws ApiError
     */
    public postPWebhookStripe(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/p/webhook/stripe',
        });
    }
    /**
     * @throws ApiError
     */
    public postPWebhookStripeAccounts(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/p/webhook/stripe/accounts',
        });
    }
    /**
     * @throws ApiError
     */
    public deletePushNotification({
        deviceToken,
    }: {
        deviceToken: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/push-notification/{deviceToken}',
            path: {
                'deviceToken': deviceToken,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public postPushNotification({
        deviceToken,
        deviceType,
        environment,
    }: {
        deviceToken: string,
        deviceType: 'android' | 'ios' | 'expo',
        environment: 'dev' | 'staging' | 'prod',
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/push-notification/{deviceToken}/{deviceType}/{environment}',
            path: {
                'deviceToken': deviceToken,
                'deviceType': deviceType,
                'environment': environment,
            },
        });
    }
    /**
     * New endpoint
     * @returns any New response
     * @throws ApiError
     */
    public getUrl(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/url',
        });
    }
    /**
     * @throws ApiError
     */
    public getV2Organizations({
        limit,
        offset,
        orderBy = 'createdAt',
        orderDirection = 'DESC',
        filters,
    }: {
        limit?: number,
        offset?: number,
        orderBy?: 'name' | 'createdAt' | 'id',
        orderDirection?: 'ASC' | 'DESC',
        filters?: {
            search?: string;
            countries?: Array<string>;
            dioceses?: Array<string>;
            customerTypes?: Array<string>;
            accountManagers?: Array<number>;
            plans?: Array<number>;
            addons?: Array<string>;
            locked?: boolean;
        },
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations',
            query: {
                'limit': limit,
                'offset': offset,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
                'filters': filters,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public postV2OrganizationsAccount(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/organizations/account',
        });
    }
    /**
     * @throws ApiError
     */
    public postV2OrganizationsAddRoleTemplates(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/organizations/add/role/templates',
        });
    }
    /**
     * @throws ApiError
     */
    public getV2OrganizationsBilling(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/billing',
        });
    }
    /**
     * @throws ApiError
     */
    public getV2OrganizationsBilling1({
        stripeCustomerId,
    }: {
        stripeCustomerId: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/billing/{stripeCustomerId}',
            path: {
                'stripeCustomerId': stripeCustomerId,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public postV2OrganizationsHubspot(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/organizations/hubspot',
        });
    }
    /**
     * @throws ApiError
     */
    public getV2OrganizationsPackageCollaborationPackage(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/package/collaborationPackage',
        });
    }
    /**
     * @throws ApiError
     */
    public getV2OrganizationsSearch({
        name,
        country,
        limit = 10,
    }: {
        name?: string,
        country?: string,
        limit?: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/search',
            query: {
                'name': name,
                'country': country,
                'limit': limit,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getV2OrganizationsSubscription(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/subscription',
        });
    }
    /**
     * @throws ApiError
     */
    public postV2OrganizationsUpload({
        type,
        mode = 'upload',
    }: {
        type: 'main' | 'people' | 'mobile' | 'favicon',
        mode?: 'upload' | 'reset',
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/organizations/upload',
            query: {
                'type': type,
                'mode': mode,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public deleteV2Organizations(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/organizations/{id}',
        });
    }
    /**
     * @throws ApiError
     */
    public getV2OrganizationsAccount(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/{id}/account',
        });
    }
    /**
     * @throws ApiError
     */
    public putV2OrganizationsAccount(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/organizations/{id}/account',
        });
    }
    /**
     * @throws ApiError
     */
    public getV2OrganizationsAccountHasMvr(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/{id}/account/has-mvr',
        });
    }
    /**
     * @throws ApiError
     */
    public getV2OrganizationsAccountInfo(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/{id}/account/info',
        });
    }
    /**
     * @throws ApiError
     */
    public getV2OrganizationsCaps(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/{id}/caps',
        });
    }
    /**
     * @throws ApiError
     */
    public putV2OrganizationsCaps({
        requestBody,
    }: {
        requestBody?: PutOrganizationsCapsRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/organizations/{id}/caps',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @throws ApiError
     */
    public putV2OrganizationsDiocese({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PutOrganizationsDioceseRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/organizations/{id}/diocese',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @throws ApiError
     */
    public getV2OrganizationsDiocese({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/{id}/diocese',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getV2OrganizationsInfo(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/{id}/info',
        });
    }
    /**
     * @throws ApiError
     */
    public getV2OrganizationsPublic(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/{id}/public',
        });
    }
    /**
     * @throws ApiError
     */
    public postV2OrganizationsAccountIdentityDocument(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/organizations/{organizationId}/account/identity/document',
        });
    }
    /**
     * @throws ApiError
     */
    public getV2OrganizationsAlias({
        path,
        drupalAccessToken,
        organizationId,
    }: {
        path: string,
        drupalAccessToken: string,
        organizationId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/{organizationId}/alias',
            path: {
                'organizationId': organizationId,
            },
            query: {
                'path': path,
                'drupal_access_token': drupalAccessToken,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public postV2OrganizationsIndexPeople({
        organizationId,
    }: {
        organizationId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/organizations/{organizationId}/index-people',
            path: {
                'organizationId': organizationId,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getV2OrganizationsLegalDocument({
        organizationId,
    }: {
        organizationId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/{organizationId}/legal-document',
            path: {
                'organizationId': organizationId,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public postV2OrganizationsLegalDocument({
        organizationId,
        legalDocumentId,
    }: {
        organizationId: number,
        legalDocumentId: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/organizations/{organizationId}/legal-document/{legalDocumentId}',
            path: {
                'organizationId': organizationId,
                'legalDocumentId': legalDocumentId,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public putV2OrganizationsSettings(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/organizations/{organizationId}/settings',
        });
    }
    /**
     * @throws ApiError
     */
    public getV2OrganizationsSettings(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/{organizationId}/settings',
        });
    }
    /**
     * @throws ApiError
     */
    public getV2OrganizationsStripeAccount(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/{organizationId}/stripe/account',
        });
    }
    /**
     * @throws ApiError
     */
    public getV2OrganizationsUsersGroups(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/{organizationId}/users/{userId}/groups',
        });
    }
    /**
     * @throws ApiError
     */
    public getV2PeopleMessages(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/people/messages',
        });
    }
    /**
     * @throws ApiError
     */
    public postV2PeopleMessages(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/people/messages',
        });
    }
    /**
     * @throws ApiError
     */
    public deleteV2PeopleMessagesFiles({
        id,
        peopleMessageId,
    }: {
        id: number,
        peopleMessageId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/people/messages/{peopleMessageId}/files/{id}',
            path: {
                'id': id,
                'peopleMessageId': peopleMessageId,
            },
        });
    }
    /**
     * @throws ApiError
     */
    public getWidgetsLists(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/widgets/lists',
        });
    }
}
