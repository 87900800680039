import React, { Suspense } from 'react';
import { Spin, Typography } from 'antd';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import 'react-image-crop/dist/ReactCrop.css';
import styled from 'styled-components';
import { useRecoilValueLoadable } from 'recoil';

import { useOrganization } from '../../organization/hooks/useOrganization';
import {
  DefaultConsentTypeName,
  NewsletterandFilterDataInterface,
  PeopleSearchTotal,
} from '../store/newsletterListState';
import useNewsletterList from '../hooks/useNewsletterList';
import useManageContacts from '../hooks/useManageContacts';

import CdModal from '@/react/shared/components/cd-modal/CdModal';
import { CdWarningIcon, CdUploadIcon } from '@/react/shared/components/Icons';
import { navigate } from '@/react/services/StateServiceFactory';
import { I18nService } from '@/react/services/I18nService';

const { Paragraph, Title } = Typography;

const Container = styled.main`
  padding: 16px 16px 0px;
  display: flex;
  gap: 0 0px;
`;
interface ConfirmRegisterConsentOnContactsProps
  extends Record<string, unknown> {
  filter?: NewsletterandFilterDataInterface;
  filterSetup?: { churchIds: number[]; comparison: string; filters: [] };
  name?: string;
  setup?: {
    churchIds: number[];
  };
  filterIds?: number[];
  type?: string;
  destinationUrl?: string;
  newsletterWidgetEnabled?: boolean;
  parent: string;
  listId?: number[];
  typeOfListAddedContactsFrom?: string;
  resaultForAddAndRemoveNewsletters?: { add: number[]; remove: number[] };
  personIds?: number[];
}

export const ConfirmRegisterConsentOnContactsModal = NiceModal.create(
  ({
    filter,
    filterSetup,
    name,
    setup,
    filterIds,
    type,
    destinationUrl,
    newsletterWidgetEnabled,
    parent,
    listId,
    typeOfListAddedContactsFrom,
    resaultForAddAndRemoveNewsletters,
    personIds,
  }: ConfirmRegisterConsentOnContactsProps) => (
    <Suspense fallback={<CdUploadIcon />}>
      <ConfirmRegisterConsentOnContacts
        filter={filter}
        filterSetup={filterSetup}
        name={name}
        setup={setup}
        filterIds={filterIds}
        type={type}
        destinationUrl={destinationUrl}
        newsletterWidgetEnabled={newsletterWidgetEnabled}
        parent={parent}
        listId={listId}
        typeOfListAddedContactsFrom={typeOfListAddedContactsFrom}
        resaultForAddAndRemoveNewsletters={resaultForAddAndRemoveNewsletters}
        personIds={personIds}
      />
    </Suspense>
  )
);

export const ConfirmRegisterConsentOnContacts = ({
  filter,
  filterSetup,
  name,
  setup,
  filterIds,
  type,
  destinationUrl,
  newsletterWidgetEnabled,
  parent,
  listId,
  typeOfListAddedContactsFrom,
  resaultForAddAndRemoveNewsletters,
  personIds,
}: ConfirmRegisterConsentOnContactsProps) => {
  const modal = useModal('ConfirmRegisterConsentOnContacts');
  const { createList, addContactsToLists } = useNewsletterList();
  const { updateSettings } = useOrganization();
  const { manageNewsletterAndParticipantLists } = useManageContacts();
  const deaultConsentLoadable = useRecoilValueLoadable(DefaultConsentTypeName);

  const numberOfcontactsLoadable = useRecoilValueLoadable(
    PeopleSearchTotal({
      filter: filterSetup ? filterSetup : filter?.setup,
      churchIds: filter?.churchIds,
    })
  );
  const loading =
    numberOfcontactsLoadable.state === 'loading' ||
    deaultConsentLoadable.state === 'loading';
  const deaultConsentName =
    deaultConsentLoadable.state === 'hasValue' &&
    deaultConsentLoadable.contents.name;
  const numberOfcontacts = personIds
    ? personIds.length
    : numberOfcontactsLoadable.state === 'hasValue' &&
      numberOfcontactsLoadable.contents.totalMatch;

  const onFinish = async () => {
    if (parent === 'ConfirmManageContacts') {
      await manageNewsletterAndParticipantLists({
        filter: filterSetup,
        peopleLists: resaultForAddAndRemoveNewsletters,
        personIds,
      });
    }
    if (parent === 'addContactToListModal') {
      await addContactsToLists({
        filterIds:
          typeOfListAddedContactsFrom === 'filter' ? [filter.id] : undefined,
        fromPeopleListIds:
          typeOfListAddedContactsFrom === 'filter' ? undefined : [filter.id],
        toPeopleListIds: listId,
      });
    }
    if (parent === 'createNewsletterModal') {
      await createList(name, setup, filterIds, type);
      if (newsletterWidgetEnabled) {
        await updateSettings({
          newsletterWidgetEnabled,
        });
      }
    }
    if (parent === 'migrationPage') {
      await createList(name, setup, filterIds, type);
      await updateSettings({
        newsletterWidgetEnabled,
        newsletterListMigrationComplete: true,
      });
      navigate(destinationUrl);
    }
  };
  return (
    <CdModal
      title=""
      modal={modal}
      okText={I18nService.getString('Approve')}
      onOk={onFinish}
    >
      <Spin spinning={loading}>
        <Container className="ant-modal-confirm-body">
          <CdWarningIcon size="2x" type="warning" />
          <article>
            <Title level={5} style={{ marginTop: '0px' }}>
              {I18nService.getString('Registering a consent on contacts')}
            </Title>
            <Paragraph>
              {I18nService.getPlural(
                numberOfcontacts,
                'You have chosen 1 contact that will be subscribed to the selected newsletter list. This contact might not have consented to  "{{deaultConsentName}}". By subscribing this contact to the selected newsletter list the contact will be registered as having consented to "{{deaultConsentName}}".',
                'You have chosen {{$count | number }} contacts that will be subscribed to the selected newsletter list. Some of these contacts might not have consented to  "{{deaultConsentName}}". By subscribing these contacts to the selected newsletter list the contacts will be registered as having consented to "{{deaultConsentName}}".',
                {
                  $count: numberOfcontacts,
                  deaultConsentName,
                }
              )}
            </Paragraph>
          </article>
        </Container>
      </Spin>
    </CdModal>
  );
};
