import { includes, reduce, sortBy } from 'lodash';
import { selector, selectorFamily } from 'recoil';

import { mainApi } from '../../api';
import { Segment, SegmentType } from '../types/segment.type';

import { registerRecoilRefresher } from '@/app/cdRecoilRefresher';
import { I18nService } from '@/react/services/I18nService';

export const peopleSegmentsState = selector<Segment[]>({
  key: 'peopleSegments',
  get: async ({ getCallback }) => {
    const response = await mainApi.get<Segment[]>('people/segments');

    registerRecoilRefresher(
      peopleSegmentsState,
      getCallback(
        ({ refresh }) =>
          () =>
            refresh(peopleSegmentsState)
      )
    );

    if (response.ok) {
      return reduce(
        response.data,
        (accumulator, segment) => {
          if (segment.name === 'all') {
            segment.name = I18nService.getString(
              'All',
              undefined,
              'All contacts list. People'
            );
          }
          accumulator.push(segment);
          return accumulator;
        },
        []
      );
    }
    throw response;
  },
});

export const peopleSegmentsByTypeQuery = selectorFamily<
  Segment[],
  SegmentType[]
>({
  key: 'peopleSegmentsByTypeQuery',
  get:
    (type: SegmentType[]) =>
    ({ get }) => {
      const segments = get(peopleSegmentsState);
      return segments.filter((segment) =>
        includes(type, segment.filteringType)
      );
    },
});

export const peopleSegmentsQuery = selectorFamily<
  Segment[],
  { search?: string; type: SegmentType[] }
>({
  key: 'peopleSegmentsQuery',
  get:
    ({ search, type }: { search?: string; type: SegmentType[] }) =>
    ({ get }) => {
      const segments = get(peopleSegmentsByTypeQuery(type));

      if (!search) {
        return sortBy(segments, 'name');
      }
      return sortBy(
        segments.filter((segment) =>
          segment.name.toLowerCase().includes(search.toLowerCase())
        ),
        'name'
      );
    },
});
