import { List, Typography } from 'antd';
import React from 'react';

import { DetailedEvent } from '../models/calendar';
import { I18nService } from '../../services/I18nService';
import { CdAttachmentIcon } from '../../shared/components/Icons';

import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';

const { Text } = Typography;

const FilesSection = ({ event }: { event: DetailedEvent }) => {
  const files = event.files;
  if (!files || files.length === 0) return null;

  return (
    <div style={{ padding: '8px 0' }}>
      <Text strong style={{ padding: '0 16px' }}>
        {I18nService.getString('Files')}
      </Text>
      <div style={{ padding: '0 16px' }}>
        <List
          itemLayout="horizontal"
          dataSource={files}
          renderItem={(file) => (
            <List.Item key={file.id} style={{ justifyContent: 'left' }}>
              <CdAttachmentIcon style={{ marginRight: '8px' }} />
              {file.canDownload ? (
                <a href={file.downloadUrl} target="_blank" rel="noreferrer">
                  {file.fileName}
                </a>
              ) : (
                <CdTooltip
                  title={I18nService.getString(
                    'You cannot download this file as you are not a member of its group'
                  )}
                >
                  {file.fileName}
                </CdTooltip>
              )}
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default FilesSection;
