/* eslint-disable react/display-name */
import React, { Suspense } from 'react';
import { Space, Input } from 'antd';

import { I18nService } from '../../services/I18nService';
import { UserRoleFilterComponent } from '../components/UserRoleFilterComponent';
import { GetUserTableColumns } from '../components/GetUserTableColumns';
import { useFilteredUserList } from '../store/users';
import { useUserList } from '../hooks/useUserList';

import CdClientSideTable from '@/react/shared/components/cd-client-side-table/CdClientSideTable';
import { OrganizationUserStatus } from '@/react/safeguarding/safeguarding/OrganizationUserStatus';
import { User } from '@/react/user/types/User.types';

export enum organizationStatus {
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  CREATED_WITHOUT_LOGIN = 'createdWithoutLogin',
}
export interface FilterStateInterface {
  status: organizationStatus[];
  search: string;
  roles: number[] | string[];
}

export const defaultFilterState = {
  status: [organizationStatus.ACTIVE],
  search: '',
  roles: [],
};

export const getRowClassName = (record) => {
  if (record.organizationStatus === organizationStatus.BLOCKED) {
    return 'ant-table-row-bg-error';
  } else if (
    record.organizationStatus === organizationStatus.CREATED_WITHOUT_LOGIN
  ) {
    return 'ant-table-row-bg-warning';
  } else {
    return '';
  }
};

const { Search } = Input;

const UserListTable = () => {
  const {
    filterState,
    handleSearchUser,
    handleStatusChange,
    handleFilterChange,
  } = useUserList();

  return (
    <CdClientSideTable<User>
      searchParams={{
        search: filterState.search,
        roles: filterState.roles,
        status: filterState.status,
      }}
      querySource={useFilteredUserList}
      columns={GetUserTableColumns()}
      emptyStateProps={{
        title: I18nService.getString('No users'),
      }}
      filterForm={
        <Space size="small">
          <UserRoleFilterComponent
            filterHandler={handleFilterChange}
            hasMultiChurchNewRoles={true}
          />

          <OrganizationUserStatus
            status={filterState.status}
            statusHandler={handleStatusChange}
          />
          <Search
            placeholder={I18nService.getString('Search')}
            allowClear
            onChange={handleSearchUser}
          />
        </Space>
      }
      rowClassName={getRowClassName}
    />
  );
};

export const UserList = () => (
  <Suspense>
    <UserListTable />
  </Suspense>
);
