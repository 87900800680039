import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import { TagListWrapper } from '@/react/people/tag-list/component/TagListWrapper';

angular
  .module('cdApp.people')
  .component(
    'cdrTagList',
    react2angular(sharedContext.use(TagListWrapper), [], [])
  );
