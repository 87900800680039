import React from 'react';
import { AutoComplete, SelectProps, Spin } from 'antd';

import { useResourceDistrict } from '../../hooks/use-resource-distric';

import { I18nService } from '@/react/services/I18nService';
interface DistrictFieldProps {
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
}
export const DistrictField = (props: DistrictFieldProps) => {
  const { value, onChange } = props;
  const { districts, isLoading } = useResourceDistrict();
  const options: SelectProps['options'] = districts?.map(
    (district: string) => ({
      label: district,
      value: district,
    })
  );
  return (
    <Spin spinning={isLoading}>
      <AutoComplete
        options={options}
        value={value}
        onChange={(value) => onChange(value)}
        placeholder={I18nService.getString('Add district/area')}
        disabled={props.disabled}
        filterOption={true}
      />
    </Spin>
  );
};
