import React from 'react';
import { Typography } from 'antd';

import { CdVerticalSpace } from '../../../shared/components/cd-vertical-space/CdVerticalSpace';
import { getFormattedDate } from '../../components/pop-overs/popover-helpers';

export const DateIntervalRender = (props: {
  startDate: string;
  endDate: string;
  allDay?: boolean;
  type?: 'inline' | 'block';
}) => {
  const time = getFormattedDate({
    startDate: props.startDate,
    endDate: props.endDate,
    allDay: props.allDay,
  });

  return props.type === 'inline' ? (
    <Typography.Text>{`${time.title} ${time.subtitle}`}</Typography.Text>
  ) : (
    <CdVerticalSpace size={0}>
      <Typography.Text strong>{time.title}</Typography.Text>
      {time.subtitle && (
        <Typography.Text type="secondary">{time.subtitle}</Typography.Text>
      )}
    </CdVerticalSpace>
  );
};
