import React from 'react';
import { Space } from 'antd';

import { I18nService } from '../../services/I18nService';
import { sortString } from '../../shared/utils/sorter';

import {
  CdSelect,
  CdSelectProps,
} from '@/react/shared/components/cd-select/CdSelect';
import { CdUserAvatar } from '@/react/user/components/cd-user-avatar/CdUserAvatar';
import { useOrganizationUsersSlim } from '@/react/organization/store/organization';

interface CdUserSelectProps
  extends Omit<CdSelectProps, 'options' | 'placeholder'> {
  omitIds?: number[];
  includeIds?: number[];
  showAvatar?: boolean;
  placeholder?: string;
}

export const CdUserSelect = ({
  showAvatar,
  includeIds,
  omitIds,
  ...props
}: CdUserSelectProps) => {
  const { users, isLoading } = useOrganizationUsersSlim();

  return (
    <CdSelect
      title={(count) =>
        count === 0
          ? I18nService.getString('All users')
          : I18nService.getPlural(count, '1 user', '{{ $count }} users', {
              $count: count,
            })
      }
      placeholder={I18nService.getString('Choose a user.')}
      options={users
        .filter((user) => user.status === 'active')
        .filter(
          (user) =>
            !includeIds ||
            includeIds?.length === 0 ||
            includeIds?.includes(user.id)
        )
        .filter((user) => !omitIds?.includes(user.id))
        .map((user) => ({
          label: (
            <Space>
              {showAvatar !== false && (
                <CdUserAvatar picture={user.picture} size={30} />
              )}
              {user.name || user.email}
            </Space>
          ),
          value: user.id,
          name: user.name,
          email: user.email,
        }))}
      filterOption={(input, option) =>
        option?.name?.toLowerCase().includes(input?.toLowerCase() || '') ||
        option?.email?.toLowerCase().includes(input?.toLowerCase() || '')
      }
      filterSort={(a, b) => sortString(a.name || a.email, b.name || b.email)}
      disabled={isLoading}
      {...props}
    />
  );
};
