import React from 'react';
import { Button } from 'antd';

import { I18nService } from '../../../services/I18nService';
import { CdEyeIcon } from '../../../shared/components/Icons';
import { useEnquiries } from '../hooks/use-enquiries';
import { useEnquiriesPage } from '../hooks/use-enquiries-page';
import { CdServerSideTable } from '../../../shared/components/cd-server-side-table/CdServerSideTable';
import { useFlag } from '../../../shared/hooks/useFlag';

import { commonColumns } from './OpenEnquiriesTab';
import { EnquiriesFilterForm } from './EnquiriesFilterForm';

export const ClosedEnquiriesTab = () => {
  const { viewEnquiry, form, searchParams } = useEnquiriesPage();
  const bookingEnquiriesV2Enabled = useFlag('booking_enquiries_v2');

  return (
    <CdServerSideTable
      pagedQuery={useEnquiries}
      searchParams={{ statuses: ['accepted', 'rejected'], ...searchParams }}
      noStyle
      filterForm={
        bookingEnquiriesV2Enabled && <EnquiriesFilterForm form={form} />
      }
      columns={[
        ...commonColumns(),
        {
          title: I18nService.getString(
            'Responded by',
            null,
            'Name of the user who responded to the enquiry'
          ),
          dataIndex: 'respondedBy',
          render: (respondedBy) => respondedBy?.contact?.fullName,
        },
        {
          key: 'actions',
          width: 1,
          render: (_, record) => (
            <Button
              icon={<CdEyeIcon />}
              onClick={() => viewEnquiry(record.id)}
              size="small"
            >
              {I18nService.getString('View')}
            </Button>
          ),
        },
      ]}
      emptyStateProps={{
        title: I18nService.getString('No closed enquiries'),
      }}
    />
  );
};
