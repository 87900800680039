import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { flagrQueries } from '../queries/flagr';

export const useFlag = (key: string) => {
  const query = useQuery(flagrQueries.getFlag(key));

  return query.data || false;
};

export const useSuspensedFlag = (key: string) => {
  const query = useSuspenseQuery(flagrQueries.getFlag(key));

  return query.data || false;
};
