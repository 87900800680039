import React from 'react';
import { Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

import ElementWrapper from './ElementWrapper';

import { I18nService } from '@/react/services/I18nService';

const { Text } = Typography;

const Visibility: React.FC<{ visibility: string }> = ({ visibility }) => {
  const icon = visibility === 'web' ? faEye : faEyeSlash;
  const visibilityText =
    visibility === 'web'
      ? I18nService.getString('Visible on the website')
      : I18nService.getString('Visible only to the group');
  const type = visibility === 'web' ? null : 'warning';

  return (
    <ElementWrapper>
      <FontAwesomeIcon icon={icon} />
      <Text type={type}>{visibilityText}</Text>
    </ElementWrapper>
  );
};

export default Visibility;
