/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { PostPeopleConsentsEmailConfirmRequestBody } from '../models/PostPeopleConsentsEmailConfirmRequestBody';
import type { PostPeopleConsentsRequestBody } from '../models/PostPeopleConsentsRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ConsentsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * CreateConsent
     * Create Consent
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeopleConsents({
        requestBody,
    }: {
        requestBody?: PostPeopleConsentsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/consents',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * ConfirmConsents
     * Confirm Consents
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeopleConsentsEmailConfirm({
        token,
    }: {
        token: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/consents/email/confirm',
            query: {
                'token': token,
            },
        });
    }
    /**
     * ConfirmConsentsEmail
     * Send Consent Confirmation E-mail
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPeopleConsentsEmailConfirm({
        apiKey,
        requestBody,
    }: {
        apiKey?: '4?nXn9dwzt*cc8QV',
        requestBody?: PostPeopleConsentsEmailConfirmRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/consents/email/confirm',
            query: {
                'apiKey': apiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeleteConsent
     * Delete Consent
     * @returns EmptyObject
     * @throws ApiError
     */
    public deletePeopleConsents({
        consentId,
    }: {
        consentId: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/people/consents/{consentId}',
            path: {
                'consentId': consentId,
            },
        });
    }
    /**
     * GetPeopleConsentsPersonidSourceid
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeopleConsents({
        personId,
        sourceId,
    }: {
        personId: number,
        sourceId: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/consents/{personId}/{sourceId}',
            path: {
                'personId': personId,
                'sourceId': sourceId,
            },
        });
    }
}
