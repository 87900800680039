/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContributionGetExcelReportResponseBody } from '../models/ContributionGetExcelReportResponseBody';
import type { EmptyObject } from '../models/EmptyObject';
import type { GetPTransfersResponseBody } from '../models/GetPTransfersResponseBody';
import type { GetTransfersResponseBody } from '../models/GetTransfersResponseBody';
import type { RetrieveResourceResponseBody } from '../models/RetrieveResourceResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TransfersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get previous and upcoming transfers
     * @returns GetPTransfersResponseBody
     * @throws ApiError
     */
    public getPTransfers({
        limit = 10,
    }: {
        limit?: number,
    }): CancelablePromise<GetPTransfersResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/p/transfers',
            query: {
                'limit': limit,
            },
        });
    }
    /**
     * GetTransfers
     * Transfer list
     * @returns GetTransfersResponseBody Success-Response
     * @throws ApiError
     */
    public getTransfers({
        loadAll,
        limit,
    }: {
        /**
         * Load all data
         */
        loadAll?: any,
        /**
         * Load all data
         */
        limit?: any,
    }): CancelablePromise<GetTransfersResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/transfers',
            query: {
                'loadAll': loadAll,
                'limit': limit,
            },
        });
    }
    /**
     * GetTransfersBalanceHistory
     * Upcoming transfer transactions
     * @returns RetrieveResourceResponseBody Success-Response
     * @throws ApiError
     */
    public getTransfersBalanceHistory({
        loadAll,
        limit,
        latest,
        earliest,
    }: {
        loadAll: any,
        limit: any,
        latest: any,
        earliest: any,
    }): CancelablePromise<RetrieveResourceResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/transfers/balance/history',
            query: {
                'loadAll': loadAll,
                'limit': limit,
                'latest': latest,
                'earliest': earliest,
            },
        });
    }
    /**
     * UpcomingTransferReportDownloadToken
     * GET A DOWNLOAD TOKEN FOR AN EXCEL REPORT
     * @returns EmptyObject
     * @throws ApiError
     */
    public getTransfersBalanceHistoryDownloadToken(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/transfers/balance/history/download-token',
        });
    }
    /**
     * GetTransfersBalanceHistoryExport
     * Upcoming transfer transactions export
     * @returns EmptyObject
     * @throws ApiError
     */
    public getTransfersBalanceHistoryExport({
        latest,
        earliest,
        fileType,
    }: {
        latest: any,
        earliest: any,
        fileType: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/transfers/balance/history/export',
            query: {
                'latest': latest,
                'earliest': earliest,
                'fileType': fileType,
            },
        });
    }
    /**
     * UpcomingTransferReportDownload
     * GET EXCEL REPORT
     * @returns ContributionGetExcelReportResponseBody
     * @throws ApiError
     */
    public getTransfersBalanceHistoryReportDownload({
        latest,
        earliest,
        fileType,
        downloadToken,
    }: {
        /**
         * The date to start exporting
         */
        latest: any,
        /**
         * The date to start exporting
         */
        earliest: any,
        /**
         * The type of file to export
         */
        fileType: any,
        /**
         * The id of the organization (by token, not directly)
         */
        downloadToken: any,
    }): CancelablePromise<ContributionGetExcelReportResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/transfers/balance/history/report/download',
            query: {
                'latest': latest,
                'earliest': earliest,
                'fileType': fileType,
                'downloadToken': downloadToken,
            },
        });
    }
    /**
     * TransferReportInvoiceDownloadToken
     * GET A DOWNLOAD TOKEN FOR AN EXCEL REPORT
     * @returns EmptyObject
     * @throws ApiError
     */
    public getTransfersReportInvoiceDownloadToken(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/transfers/report-invoice/download-token',
        });
    }
    /**
     * GetTransfersTransferidExport
     * Transfer export
     * @returns EmptyObject
     * @throws ApiError
     */
    public getTransfersExport(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/transfers/{transferId}/export',
        });
    }
    /**
     * GetTransfersTransferidFeesInvoice
     * Fees invoice
     * @returns EmptyObject
     * @throws ApiError
     */
    public getTransfersFeesInvoice(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/transfers/{transferId}/fees/invoice',
        });
    }
    /**
     * TransferInvoiceDownload
     * GET EXCEL REPORT
     * @returns ContributionGetExcelReportResponseBody
     * @throws ApiError
     */
    public getTransfersFeesInvoiceDownload({
        downloadToken,
    }: {
        /**
         * The id of the organization (by token, not directly)
         */
        downloadToken: any,
    }): CancelablePromise<ContributionGetExcelReportResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/transfers/{transferId}/fees/invoice/download',
            query: {
                'downloadToken': downloadToken,
            },
        });
    }
    /**
     * GetTransfersTransferidReport
     * Transfer report
     * @returns EmptyObject
     * @throws ApiError
     */
    public getTransfersReport(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/transfers/{transferId}/report',
        });
    }
    /**
     * TransferReportDownload
     * GET EXCEL REPORT
     * @returns ContributionGetExcelReportResponseBody
     * @throws ApiError
     */
    public getTransfersReportDownload({
        downloadToken,
    }: {
        /**
         * The id of the organization (by token, not directly)
         */
        downloadToken: any,
    }): CancelablePromise<ContributionGetExcelReportResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/transfers/{transferId}/report/download',
            query: {
                'downloadToken': downloadToken,
            },
        });
    }
    /**
     * GetTransfersTransferidTransactions
     * Transfer transactions
     * @returns RetrieveResourceResponseBody Success-Response
     * @throws ApiError
     */
    public getTransfersTransactions({
        loadAll,
        limit,
    }: {
        loadAll: any,
        limit: any,
    }): CancelablePromise<RetrieveResourceResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/transfers/{transferId}/transactions',
            query: {
                'loadAll': loadAll,
                'limit': limit,
            },
        });
    }
}
