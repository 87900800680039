// Ensure that the timezone is on this list: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones and is not red.
export const languageMapping: Record<
  string,
  { name: string; timezone: string; countryIso2: string }
> = {
  en: {
    name: 'English (US)',
    timezone: 'America/New_York',
    countryIso2: 'us',
  },
  'en-gb': {
    name: 'English (UK)',
    timezone: 'Europe/London',
    countryIso2: 'gb',
  },
  da: {
    name: 'Danish',
    timezone: 'Europe/Copenhagen',
    countryIso2: 'dk',
  },
  de: {
    name: 'German',
    timezone: 'Europe/Berlin',
    countryIso2: 'de',
  },
  sv: {
    name: 'Swedish',
    timezone: 'Europe/Stockholm',
    countryIso2: 'se',
  },
  nl: {
    name: 'Dutch (Only dates in widgets)',
    timezone: 'Europe/Brussels',
    countryIso2: 'nl',
  },
  fr: {
    name: 'French',
    timezone: 'Europe/Paris',
    countryIso2: 'fr',
  },
};
