'use strict';

class DeletePeopleModalComponent {
  constructor($uibModal, _, toastr, gettextCatalog, People) {
    'ngInject';

    this._ = _;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.$uibModal = $uibModal;
    this.People = People;
  }

  deletePeople() {
    const count = this.resolve.count;
    const payload = this.resolve.payload;

    this.People.multiDelete(
      {},
      payload,
      (response) => {
        let deletedIds = _.get(response, 'deletedIds');
        let succeeded = 0;

        if (_.isString(deletedIds)) succeeded = 1;
        if (_.isArray(deletedIds)) succeeded = deletedIds.length;

        let failed = count - succeeded;

        if (succeeded) {
          this.toastr.success(
            this.gettextCatalog.getPlural(
              succeeded,
              'Contact deleted successfully.',
              '{{count}} contacts deleted successfully.',
              {
                count: succeeded,
              }
            )
          );
        }
        if (failed) {
          this.toastr.error(
            this.gettextCatalog.getPlural(
              failed,
              'Contact could not be deleted.',
              '{{count}} contacts could not be deleted.',
              {
                count: failed,
              }
            )
          );
        }

        this.close();
      },
      (error) => {
        const errorMessage = _.get(error, 'data.message');
        this.toastr.error(
          errorMessage ||
            this.gettextCatalog.getPlural(
              count,
              'An error occurred and the contact could not be deleted.',
              'An error occurred and {{count}} contacts could not be deleted.',
              { count }
            )
        );

        this.close();
      }
    );
  }
}

DeletePeopleModalComponent.$inject = [
  '$uibModal',
  '_',
  'toastr',
  'gettextCatalog',
  'People',
];

angular.module('cdApp.people').component('cdPeopleDeleteModal', {
  templateUrl:
    '@/app/people/shared/components/delete-people/delete-people.component.html',
  controller: DeletePeopleModalComponent,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
