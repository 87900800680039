'use strict';

import { I18nService } from '@/react/services/I18nService';
import LanguageService from '@/react/organization/services/language.service';

class AuthenticationService {
  constructor(
    $q,
    $http,
    $state,
    $window,
    $location,
    localStorageService,
    toastr,
    Organizations,
    gettextCatalog,
    redactorOptions,
    amMoment,
    cdApp,
    _,
    jwtHelper,
    moment,
    iso,
    iso4217
  ) {
    'ngInject';

    this.$q = $q;
    this.$http = $http;
    this.$state = $state;
    this.$window = $window;
    this.$location = $location;
    this.localStorageService = localStorageService;
    this.toastr = toastr;
    this.Organizations = Organizations;
    this.gettextCatalog = gettextCatalog;
    this.redactorOptions = redactorOptions;
    this.amMoment = amMoment;
    this.cdApp = cdApp;
    this._ = _;
    this.jwtHelper = jwtHelper;
    this.moment = moment;
    this.iso = iso;
    this.iso4217 = iso4217;
  }

  /**
   * Get the current user
   *
   * @returns {Promise}
   */
  getCurrentUser() {
    const deferred = this.$q.defer();

    this.$http
      .get(`${this.cdApp.config.api.main}/users/me?slim=true`)
      .then((response) => {
        const organization = this._.find(response.data.organizations, {
          id: this.$window.churchdeskOrganizationId,
        });

        // _.get does not return the default value when countryIso2=null
        const countryFromOrganization =
          _.get(organization, 'countryIso2', 'us') || 'us';
        const country = countryFromOrganization.toUpperCase();
        const currencyCode = this.iso.findCountryByCode(country).currency;

        _.set(organization, 'currencyIso', currencyCode);
        _.set(
          organization,
          'currency',
          this.iso4217.getCurrencyByCode(currencyCode).symbol || '$'
        );

        this._.assign(deferred.promise, response.data);
        this.cdApp.me = response.data;
        this.cdApp.me.isChurchDeskEmployee =
          cdApp.me.masquerading || cdApp.me.email.includes('@churchdesk.com');
        this.cdApp.organization = organization;
        // Check whether the church selector should be shown. This is a good place to run the check since all churches are available.
        // Usage: cdApp.showChurchSelector - cdApp is a global variable.
        this.cdApp.showChurchSelector =
          organization &&
          organization.churches &&
          organization.churches.length > 1;
        const data = {
          ...response.data,
          me: response.data,
          organization: organization,
        };
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const organizationId = urlParams.get('organizationId');

        if (organizationId) {
          // Override language mode, ignore language from the Me endpoint and instead fetch public organization info to get the language

          LanguageService.getLanguage(organizationId).then((response) => {
            this.redactorOptions.lang =
              response.language === 'en-gb' ? 'en' : response.language;
            this.amMoment.changeLocale(response.language);
            $.extend(
              $.fn.select2.defaults,
              $.fn.select2.locales[response.language]
                ? $.fn.select2.locales[response.language]
                : $.fn.select2.locales['en']
            );
            I18nService.setCurrentLanguage(response.language)
              .then(() => {
                deferred.resolve(data);
              })
              .catch(() => {
                // Continue bootstrap of App even if the language download fails.
                deferred.resolve(data);
              });
          });
        } else {
          const lang = _.get(this.cdApp, 'organization.locale.language');
          this.redactorOptions.lang = lang === 'en-gb' ? 'en' : lang;
          this.amMoment.changeLocale(lang);
          $.extend(
            $.fn.select2.defaults,
            $.fn.select2.locales[lang]
              ? $.fn.select2.locales[lang]
              : $.fn.select2.locales['en']
          );
          I18nService.setCurrentLanguage(lang)
            .then(() => {
              deferred.resolve(data);
            })
            .catch(() => {
              // Continue bootstrap of App even if the language download fails.
              deferred.resolve(data);
            });
        }
      }, deferred.reject);

    return deferred.promise;
  }

  /**
   * Return the access token from storage
   *
   * @param {Boolean} ignoreMasquerade Do not return the masquerade access token even if it exists
   * @returns {String}
   */
  getAccessToken(ignoreMasquerade = false) {
    const { moment } = this;

    const accessToken = this.localStorageService.get('accessToken');
    const masqueradeAccessToken = this.localStorageService.get(
      'masqueradeAccessToken'
    );

    // If a masquerade access token exists, check whether it expired. If it has,
    // return the regular access token, otherwise return the masquerade access token
    if (!ignoreMasquerade && masqueradeAccessToken) {
      const masqueradeAccessTokenExpired = moment(
        this.jwtHelper.getTokenExpirationDate(masqueradeAccessToken)
      ).isBefore();
      return masqueradeAccessTokenExpired ? accessToken : masqueradeAccessToken;
    }

    // If the caller specified to ignore any masquerade access token, return the regular one.
    return accessToken;
  }

  /**
   * Remove the access token from storage
   */
  removeAccessToken() {
    if (this.localStorageService.get('masqueradeAccessToken')) {
      this.localStorageService.remove('masqueradeAccessToken');
    } else {
      this.localStorageService.remove('accessToken');
    }
  }

  /**
   * Log in a user
   *
   * @param {String} email - The user's e-mail address
   * @param {String} password - The user's password in plain text
   *
   * @returns {Promise}
   */
  login(email, password) {
    return this.$q((resolve, reject) => {
      this.$http
        .post(`${this.cdApp.config.api.main}/login`, {
          username: email,
          password: password,
          clientId: 4,
        })
        .then(
          (response) => {
            this.localStorageService.set(
              'accessToken',
              response.data.accessToken
            );

            resolve();
          },
          (error) => {
            let message;

            if (error.status === 401) {
              message = this.gettextCatalog.getString(
                'The e-mail and password you entered do not match.'
              );
            } else if (error.status === 426) {
              message = this.gettextCatalog.getString(
                'You attempted to login too many times. Try again later or request a new password.'
              );
            } else {
              message = this.gettextCatalog.getString(
                'Something went wrong. Please try again or contact our support.'
              );
            }

            reject({
              status: error.status,
              message: message,
            });
          }
        );
    });
  }

  /**
   * Log out a user
   *
   * @param {Boolean} redirectToLogin Whether to redirect to the login page after logging out
   * @returns {Promise}
   */
  logout(redirectToLogin = false) {
    return this.$http
      .get(`${this.cdApp.config.api.main}/users/logout`)
      .then(() => {
        this.removeAccessToken();

        if (redirectToLogin) {
          window.location = this.$state.href(
            'app.public.login.form',
            {},
            { absolute: true }
          );
        }
      });
  }

  /**
   * Request a new temporary login token
   *
   * @param {Object} (userToMasquerade) - Optional user to be masqueraded
   * @param {Number} (organizationId) - Optional organization Id
   *
   * @return {Promise}
   */
  getLoginToken(userToMasquerade, organizationId) {
    if (!this.getAccessToken()) {
      throw new Error(
        'A login token cannot be generated without an access token.'
      );
    }

    return this.$http.get(`${this.cdApp.config.api.main}/login/token`, {
      params: {
        masquerade: this._.get(userToMasquerade, 'id', userToMasquerade),
        organizationId,
      },
    });
  }

  /**
   * Request an access token from a login token. Useful for masquerading.
   *
   * @param {String} loginToken The one-time, temporary login token
   */
  requestAccessToken(loginToken) {
    return this.$http.post(`${this.cdApp.config.api.main}/login/token`, {
      loginToken,
    });
  }

  /**
   * Validate a temporary reset token
   *
   * @param {String} token - The temporary token to be validate
   *
   * @return {Promise}
   */
  validateToken(token) {
    return this.$http.get(`${this.cdApp.config.api.main}/login/reset/${token}`);
  }

  /**
   * Request a password reset link
   *
   * @param {String} email - The email to request the reset for
   *
   * @return {Promise}
   */
  forgotPassword(email) {
    return this.$http.post(`${this.cdApp.config.api.main}/login/forgot`, {
      email,
    });
  }

  /**
   * Reset the user's password
   *
   * @param {Object} data - Object containing the new password.
   *
   * @return {Promise}
   */
  resetPassword(data) {
    const { _, cdApp, $q, $http, localStorageService, gettextCatalog } = this;

    const accessToken = data.accessToken;

    return $q((resolve, reject) => {
      // The organizationId is not important for resetting the password. https://app.shortcut.com/churchdesk/story/32154/user-can-not-change-password
      // We briefly remove it to ensure that if a bad organizationId has poisoned the localStorage, this won't fail on the org check.
      window.churchdeskOrganizationId = null;
      $http
        .put(
          `${cdApp.config.api.main}/users/${data.id}`,
          {
            newPassword: data.password,
            resetPasswordToken: data.resetToken,
          },

          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then(
          (response) => {
            localStorageService.set('accessToken', accessToken);

            resolve({
              status: response.status,
              message: gettextCatalog.getString(
                'Your password has been changed.'
              ),
            });
          },
          (error) => {
            reject({
              status: error.status,
              message:
                _.get(error, 'data.message') ||
                gettextCatalog.getString(
                  'Something went wrong while changing your password. Please try again.'
                ),
            });
          }
        );
    });
  }

  /**
   * Redirect to another module
   *
   * @param {Object} organizationId - The organization to redirect to
   * @param {String} moduleName - The module to redirect to
   * @param {String} continueTo - The path to redirect to
   *
   * @return undefined
   */
  redirectExternal(organizationId, moduleName, continueTo) {
    let organizationObject =
      this.cdApp.me &&
      this._.find(this.cdApp.me.organizations, { id: organizationId });
    let tasks = [this.getLoginToken(undefined, organizationId)];
    let url;

    if (organizationId && !organizationObject) {
      tasks.push(this.Organizations.get({ id: organizationId }).$promise);
    }
    this.$q.all(tasks).then(([tokenRes, organizationRes]) => {
      const loginToken = tokenRes.data.loginToken;
      if (organizationRes) organizationObject = organizationRes;

      if (this._.has(this.cdApp.references, moduleName)) {
        url = new Uri(this.cdApp.references[moduleName]);
      } else if (moduleName === 'homepage') {
        url = new Uri(organizationObject.aegirInstallationUrl);
      } else if (
        moduleName &&
        organizationObject &&
        organizationObject.modules
      ) {
        url = new Uri(organizationObject.modules[moduleName]);
      } else if (continueTo) {
        return this.$state.go('app.public.login.form', {
          continue: continueTo,
        });
      } else {
        return this.redirect(organizationId);
      }

      url.setPath(`${url.path()}/login`);
      url.setAnchor(`loginToken=${loginToken}`);

      if (continueTo) {
        url.addQueryParam('continue', continueTo);
      }
      window.location = url.toString();
    });
  }

  /**
   * Redirect to app after logging in
   *
   * @param {object|number} organization The organization id or full organization object
   * @param {string} continueTo The path to continue to
   */
  redirect(organization, continueTo) {
    const organizationObject = this._.isNumber(organization)
      ? this._.find(this.cdApp.me.organizations, { id: organization })
      : organization;
    const organizationId = this._.get(organizationObject, 'id');
    window.churchdeskOrganizationId = organizationId;

    if (!this.localStorageService.get('organization')) {
      this.localStorageService.set('organization', organizationId);
    }
    sessionStorage.setItem('churchdesk.organization', organizationId);

    // Take the user where they initially wanted to go, if they weren't logged in
    if (continueTo) {
      return window.location.replace(decodeURIComponent(continueTo));
    }

    // Otherwise by default go to the Dashboard
    window.location = this.$state.href(
      'app.private.dashboard.default',
      { oid: organizationId },
      { absolute: true }
    );
  }

  /**
   * Masquerade a user using a temporary token
   *
   * @param {String} token The temporary logintoken used to request an access token
   * @param {Number} organizationId The id of the organization being masqueraded
   * @param {string} moduleName The name of the module to go to
   */
  handleMasquerade(token, organizationId, moduleName) {
    // Remove any existing masquerade access token
    this.localStorageService.remove('masqueradeAccessToken');

    return this.requestAccessToken(token)
      .then((tokenRes) => {
        this.localStorageService.set(
          'masqueradeAccessToken',
          tokenRes.data.accessToken
        );

        this.Organizations.get({ id: organizationId }).$promise.then(
          (organization) => {
            if (moduleName === 'homepage') {
              this.redirect(organization, `/o/${organizationId}/website/list`);
            } else {
              this.redirect(organization, `/o/${organizationId}/${moduleName}`);
            }
          }
        );
      })
      .catch((error) => {
        this.toastr.error(
          _.get(error, 'data.message') ||
            'Something went wrong while masquerading. Contact the developers.'
        );
      });
  }
}
AuthenticationService.$inject = [
  '$q',
  '$http',
  '$state',
  '$window',
  '$location',
  'localStorageService',
  'toastr',
  'Organizations',
  'gettextCatalog',
  'redactorOptions',
  'amMoment',
  'cdApp',
  '_',
  'jwtHelper',
  'moment',
  'iso',
  'iso4217',
];

angular
  .module('cdApp.shared')
  .service('AuthenticationService', AuthenticationService)
  .service('Me', [
    'AuthenticationService',
    function (AuthenticationService) {
      return AuthenticationService.getCurrentUser();
    },
  ]);
