import React from 'react';
import { Button, Divider, Input, Space, Spin, Typography } from 'antd';

import {
  createCdModal,
  ModalType,
} from '../../../shared/components/cd-modal-new/CdModal2';
import { I18nService } from '../../../services/I18nService';
import {
  CdArrowRight,
  CdApproveEnquiryIcon,
  CdDeclineEnquiryIcon,
  CdExclamationTriangleIcon,
} from '../../../shared/components/Icons';
import { useApproveDeclineEnquiryModal } from '../hooks/use-approve-decline-enquiry-modal';
import NotificationService from '../../../services/NotificationService';

import { FormSubmissionViewer } from './FormSubmissionViewer';
import { DateIntervalRender } from './DateIntervalRender';

export interface ApproveDeclineEnquiryModalProps {
  respond: 'accepted' | 'rejected';
  id: string;
}

const { Title } = Typography;

export const showApproveDeclineEnquiryModal =
  createCdModal<ApproveDeclineEnquiryModalProps>(
    ({ setModalProps, respond, id }) => {
      const { isLoading, enquiry, message, setMessage, bookingLink } =
        useApproveDeclineEnquiryModal({ setModalProps, respond, id });

      return (
        <Spin spinning={isLoading}>
          {enquiry && (
            <>
              <Title>
                <Space>
                  {respond === 'accepted' ? (
                    <CdApproveEnquiryIcon type="success" />
                  ) : (
                    <CdDeclineEnquiryIcon type="danger" />
                  )}
                  {respond === 'accepted'
                    ? I18nService.getString('Your enquiry has been accepted')
                    : I18nService.getString('Change of date necessary')}
                </Space>
              </Title>
              <Input.TextArea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                autoSize={{ minRows: 5, maxRows: 5 }}
                maxLength={500}
                showCount
              />
              {respond === 'accepted' &&
                enquiry.taxonomy.config.automaticFollowUpForm && (
                  <>
                    <Title>
                      <Space>
                        <CdExclamationTriangleIcon type="warning" />
                        {I18nService.getString('Next step')}
                      </Space>
                    </Title>
                    <Typography.Paragraph>
                      {I18nService.getString(
                        'We require a few more details to finalize your booking. Please fill out the form below at your earliest convenience to help us move forward smoothly.'
                      )}
                    </Typography.Paragraph>
                    <Button
                      type="primary"
                      onClick={() =>
                        NotificationService.notifyInfo(
                          I18nService.getString(
                            'This is a preview, you cannot answer the form for the requester'
                          )
                        )
                      }
                      target="_blank"
                    >
                      <Space>
                        {I18nService.getString('Open form')}
                        <CdArrowRight />
                      </Space>
                    </Button>
                  </>
                )}
              {respond === 'rejected' && (
                <>
                  <Title>
                    {I18nService.getString('Find another date and time')}
                  </Title>
                  <Button type="primary" href={bookingLink} target="_blank">
                    <Space>
                      {I18nService.getString('Go to page')}
                      <CdArrowRight />
                    </Space>
                  </Button>
                </>
              )}

              <Divider />
              <Title level={2}>
                {I18nService.getString('Copy of your Enquiry')}
              </Title>
              <FormSubmissionViewer
                submissionData={[
                  {
                    label: I18nService.getString('Date'),
                    value: (
                      <DateIntervalRender
                        startDate={enquiry.startDate}
                        endDate={enquiry.endDate}
                        type="inline"
                      />
                    ),
                  },
                  ...enquiry?.submissionData,
                ]}
              />
            </>
          )}
        </Spin>
      );
    },
    {
      width: 600,
      title: I18nService.getString('Preview response'),
      okText: I18nService.getString('Submit response'),
      modalType: ModalType.MODAL,
    }
  );
