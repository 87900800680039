import React from 'react';
import { Alert, Typography } from 'antd';

import {
  createCdModal,
  ModalType,
} from '../../shared/components/cd-modal-new/CdModal2';
import { I18nService } from '../../services/I18nService';
import { GroupMember } from '../groups.types';
import { CdVerticalSpace } from '../../shared/components/cd-vertical-space/CdVerticalSpace';
import { CdWarningIcon } from '../../shared/components/Icons';
import { useRemoveMemberModal } from '../hooks/useRemoveMemberModal';
import { FormattedTranslation } from '../../shared/antd/validations/Translation';

export const showRemoveMemberModal = createCdModal<{
  user: GroupMember;
  groupId: number;
}>(
  ({ setModalProps, user, groupId }) => {
    const { bookingEnquiriesV2Enabled, bookingPagesThatWillLoseAvailability } =
      useRemoveMemberModal(setModalProps, user, groupId);

    return (
      <CdVerticalSpace>
        {user.name ? (
          <FormattedTranslation
            text={I18nService.getString(
              'Are you sure you want to remove <strong>{{name}}</strong> from this group?',
              { name: user.name }
            )}
          />
        ) : (
          I18nService.getString(
            'Are you sure you want to remove yourself from this group?'
          )
        )}
        {bookingEnquiriesV2Enabled &&
          bookingPagesThatWillLoseAvailability.length > 0 && (
            <Alert
              message={
                <Typography.Text type="warning">
                  <b>{I18nService.getString('Warning!')}</b>{' '}
                  {I18nService.getString(
                    'This user will no longer be available for the following booking pages:'
                  )}
                  <ul style={{ margin: 0 }}>
                    {bookingPagesThatWillLoseAvailability.map((page) => (
                      <li key={page.id}>{page.name}</li>
                    ))}
                  </ul>
                </Typography.Text>
              }
              type="warning"
              icon={<CdWarningIcon type="warning" style={{ marginRight: 8 }} />}
              showIcon
            />
          )}
      </CdVerticalSpace>
    );
  },
  {
    title: I18nService.getString('Remove member?'),
    modalType: ModalType.POPUP,
    okText: I18nService.getString('Remove'),
    okButtonProps: { danger: true },
  }
);
