import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import {
  getStandardWorkingHours,
  getUserTimeRegistrationCalendar,
  saveStandardWorkingHours,
  userTimeRegistrationSaveDayHours,
} from './timeRegistration';

import { handleSuccessMessage } from '@/react/shared/utils';
import { I18nService } from '@/react/services/I18nService';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';

type UseCalendarQueryKey = [string, { month: number; year: number }];

export const useTimeRegistration = ({
  month,
  year,
}: { month: number; year: number }) => {
  const { data } = useQuery({
    queryKey: [
      'useTimeRegistration',
      { month: month, year: year },
    ] as UseCalendarQueryKey,
    queryFn: async () => await getUserTimeRegistrationCalendar({ month, year }),
  });

  return {
    timeRegistrationCalendarData: data,
  };
};

export const useDayHoursSave = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: userTimeRegistrationSaveDayHours,
    onSuccess: () => {
      // invalidate the calendar query
      queryClient.invalidateQueries({
        queryKey: ['useTimeRegistration'],
      });
      handleSuccessMessage(
        I18nService.getString('Your time registration was saved successfully.')
      );
    },
  });
  return {
    mutateDayHours: mutate,
    isWorkingHoursSaving: isPending,
    isWorkingHoursSaved: isSuccess,
  };
};

export const useStandardWorkingHours = () => {
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: ['getStandardWorkingHours'],
    queryFn: async () => await getStandardWorkingHours(),
  });

  const { mutate } = useMutation({
    mutationFn: saveStandardWorkingHours,
    onSuccess: (data) => {
      if (data) {
        handleSuccessMessage(
          I18nService.getString(
            'Your standard working hours were updated successfully.'
          )
        );
      }
      // invalidate the calendar query
      queryClient.invalidateQueries({
        queryKey: ['useTimeRegistration'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getStandardWorkingHours'],
      });
    },
  });

  const mutateStandardWorkingHours = useCallback(
    async (standardWorkingHours) => {
      const totalStandardWorkingHours = Object.values(
        standardWorkingHours
      ).reduce((acc: number, value: number) => acc + value, 0);

      if (standardWorkingHours) {
        if (totalStandardWorkingHours === 0) {
          const { resolved } = await showConfirmModal({
            title: I18nService.getString('Warning'),
            message: I18nService.getString(
              'Setting all of your standard working hours to 0.00 means that we will not be able to calculate your time balance over time, since your registered hours are compared to your standard working hours.'
            ),
            onOk: () => mutate(standardWorkingHours),
          });
          // Hack until CdModal3 is ready
          if (!resolved) throw new Error('User cancelled the operation');
        } else {
          mutate(standardWorkingHours);
        }
      }
    },
    [mutate]
  );

  return {
    standardWorkingHoursData: data,
    isLoading,
    mutateStandardWorkingHours,
  };
};
