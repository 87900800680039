import React, { Suspense } from 'react';

import { CdPage } from '../../shared/components/cd-page/CdPage';
import { I18nService } from '../../services/I18nService';
import CdrPageLoader from '../../shared/components/CdrPageLoader';
import { CdFallbackPage } from '../../shared/components/CdFallbackPage';
import {
  CdAnalyticsCheck,
  CdAnalyticsInfo,
} from '../../shared/components/Icons';
import cdApp from '../../config';

import { WebsiteAnalytics } from './Analytics';
import { useWebsitesAnalytics } from './hooks/use-websites-analytics';

import AuthorizationService from '@/react/services/AuthorizationService';

export const WebsiteAnalyticsPage = () => {
  const language = cdApp.organization.locale.language;
  const { websitesWithAnalytics } = useWebsitesAnalytics();
  const cookiesAndAnalyticsEnabled =
    AuthorizationService.hasPackage('monoCookies');
  let cookiesAndAnalyticsUrl;
  switch (language) {
    case 'da':
    case 'se':
      cookiesAndAnalyticsUrl =
        'https://www.churchdesk.com/da/produkt/cookielosning-og-besogssgtatistik';
      break;

    case 'de':
    case 'fr':
      cookiesAndAnalyticsUrl =
        'https://www.churchdesk.com/de/produkt/cookies-und-analytics';
      break;

    case 'en':
      cookiesAndAnalyticsUrl =
        'https://www.churchdesk.com/en/product/cookies-and-analytics';
      break;

    default:
      cookiesAndAnalyticsUrl =
        'https://www.churchdesk.com/en/product/cookies-and-analytics';
      break;
  }
  const messages = [
    {
      key: 'analyticsMessage1',
      icon: <CdAnalyticsCheck size="2x" style={{ color: '#008FB4' }} />,
      messageSubTitle: I18nService.getString(
        'Automated cookie compliance that stays up-to-date with changing legislation. All unnecessary cookies gets automaticly blocked before a visitor loads the page and your cookie policy gets automatically updated as well.'
      ),
    },
    {
      key: 'analyticsMessage2',
      icon: <CdAnalyticsInfo size="2x" style={{ color: '#008FB4' }} />,
      messageSubTitle: I18nService.getString(
        'Finally you can get valuable insights into your website traffic and visits while fully respecting your visitors privacy. There is no need to install anything - everything works out of the box.'
      ),
    },
  ];
  return (
    <CdPage
      isMarginless={true}
      pageHeaderProps={{
        title: I18nService.getString('Analytics'),
        subTitle: I18nService.getString('View analytics for your websites'),
      }}
    >
      {cookiesAndAnalyticsEnabled ? (
        <WebsiteAnalytics websitesWithAnalytics={websitesWithAnalytics} />
      ) : (
        <CdFallbackPage
          title={I18nService.getString('Cookies & Analytics add-on')}
          subtitle={I18nService.getString(
            'Our Cookie and analytics tool enables automated GDPR compliance as well as giving you privacy-friendly website analytics.'
          )}
          messages={messages}
          buttonText={I18nService.getString('Get Cookie & Analytics')}
          buttonType="primary"
          buttonUrl={cookiesAndAnalyticsUrl}
        />
      )}
    </CdPage>
  );
};
export const WebsiteAnalyticsPageWrapper = () => (
  <Suspense fallback={<CdrPageLoader />}>
    <WebsiteAnalyticsPage />
  </Suspense>
);
