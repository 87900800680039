/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PutPeoplePeopleChurchesRequestBody = {
    people?: Array<number>;
    filter?: {
        churchIds?: Array<number>;
        comparison?: PutPeoplePeopleChurchesRequestBody.comparison;
        filters?: Array<any>;
    };
    churches?: {
        add?: Array<number>;
        remove?: Array<number>;
    };
};
export namespace PutPeoplePeopleChurchesRequestBody {
    export enum comparison {
        AND = 'AND',
        OR = 'OR',
    }
}

