/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ChurchEventsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * RedirectChurchEvents
     * ChurchEvents Redirect
     * @returns EmptyObject
     * @throws ApiError
     */
    public getChurcheventsRedirect(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/churchevents-redirect',
        });
    }
}
