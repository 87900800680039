/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PutSafeguardUsersInfoRequestBody = {
    type: PutSafeguardUsersInfoRequestBody.type;
    workAndResponsibilities: string;
    parishIds?: Array<number>;
    safeguardRequirement: PutSafeguardUsersInfoRequestBody.safeguardRequirement;
    street?: string | null;
    postCode?: string | null;
    city?: string | null;
    countryIso2?: string | null;
};
export namespace PutSafeguardUsersInfoRequestBody {
    export enum type {
        EMPLOYEE = 'employee',
        VOLUNTEER = 'volunteer',
    }
    export enum safeguardRequirement {
        NOT_REQUIRED = 'not required',
        VERIFIED_BY_THIRD_PARTY = 'verified by third party',
        MINIMUM = 'minimum',
        BASIC = 'basic',
        BASIC_PLUS = 'basic plus',
        EXTENDED = 'extended',
        EXTENDED_PLUS = 'extended plus',
    }
}

