import moment from 'moment-timezone';

import { I18nService } from '../../../services/I18nService';
import cdApp from '../../../config';
import { clientToBackendVisibility } from '../../helpers/visibility';
import { EventType } from '../../models/calendar';
import { CreateEventData } from '../../services/EventPopupService';
import { ClientEventVisibility } from '../../types/event';
import { extractResourceIds } from '../../helpers/resources';

export const getFormattedDate = (event: {
  startDate: string;
  endDate: string;
  allDay: boolean;
}): { title: string; subtitle?: string } => {
  const isAllDay = event.allDay;
  const isSingleDay = moment(event.endDate).diff(event.startDate, 'days') === 0;
  const allDayString = I18nService.getString('(all day)');
  const { timezone, language } = cdApp.organization.locale;

  const startDateMoment = moment(event.startDate).tz(timezone).locale(language);
  const endDateMoment = moment(event.endDate).tz(timezone).locale(language);

  if (isSingleDay) {
    if (isAllDay) {
      return {
        title: `${startDateMoment.format('dddd, LL')} ${allDayString}`,
      };
    }

    // Not all day
    const date = startDateMoment.format('dddd, LL');
    const startTime = startDateMoment.format('LT');
    const endTime = endDateMoment.format('LT');
    return { title: date, subtitle: `${startTime} - ${endTime}` };
  } else {
    if (isAllDay) {
      const startDate = startDateMoment.format('LL');
      const endDate = endDateMoment.format('LL');
      return { title: `${startDate} - ${endDate} ${allDayString}` };
    }

    // Not all day
    const startDate = startDateMoment.format('LLL');
    const endDate = endDateMoment.format('LLL');
    return { title: `${startDate} - ${endDate}` };
  }
};

export const generatePayloadFromValues = (
  values: any,
  initial: CreateEventData
) => {
  const timezone = moment.tz.guess();
  const type = values.type as EventType;

  const payload: Record<string, unknown> = {
    startDate: moment(initial.startDate).toISOString(),
    endDate: moment(initial.endDate).toISOString(),
    allDay: initial.allDay,
    hideEndTime: true,
    mainCategory: values.mainCategory,
    timezone,
    clientVersion: 2,
    type,
  };

  if (type === EventType.Event) {
    payload.title = values.title;
    payload.groupIds = values.groupIds;
    payload.visibility = clientToBackendVisibility(values.visibility);
    payload.resources = extractResourceIds(values.resources);

    if (values.visibility !== ClientEventVisibility.PRIVATE) {
      payload.churchIds = values.churchIds || [
        cdApp.organization.churches[0].id,
      ];
    }
  }

  if (type === EventType.Absence) {
    payload.users = [values.users];
    payload.groupIds = [values.groupId];
  }

  if (type === EventType.Availability) {
    payload.users = [values.users];
    payload.bookingOptionIds = values.bookingOptionIds;
  }

  return payload;
};
