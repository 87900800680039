import { PaymentMethodTypes } from '../models/intention';
import { I18nService } from '../../services/I18nService';

export const paymentMethods = () => [
  { id: PaymentMethodTypes.PAIDBYCASH, name: I18nService.getString('Cash') },
  {
    id: PaymentMethodTypes.PAIDBYBANK,
    name: I18nService.getString('Wire transfer'),
  },
];
