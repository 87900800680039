/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostV2PeopleMessagetemplatesRequestBody = {
    title?: string;
    rendered?: string;
    content?: any;
    authorId?: number;
    type?: PostV2PeopleMessagetemplatesRequestBody.type;
    portalId?: string | null;
};
export namespace PostV2PeopleMessagetemplatesRequestBody {
    export enum type {
        ADVANCED = 'advanced',
        CALENDAR_POSTER = 'calendar-poster',
        EVENT_INVITATION = 'event-invitation',
    }
}

