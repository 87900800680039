/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostPeopleSegmentsAddpeopleRequestBody = {
    peopleNewsletterListIds?: Array<number>;
    toPeopleListIds?: Array<number>;
    filterIds?: Array<number>;
    personIds?: Array<number>;
    setup?: {
        comparison?: PostPeopleSegmentsAddpeopleRequestBody.comparison;
        churchIds?: Array<number>;
        filters?: Array<any>;
    };
    fromPeopleListIds?: Array<number>;
};
export namespace PostPeopleSegmentsAddpeopleRequestBody {
    export enum comparison {
        AND = 'AND',
        OR = 'OR',
    }
}

