import {
  districtNameSuggestions,
  locationNameSuggestions,
} from '../services/LocationService';

import { CdQueryDefinition } from './types';

export const locationQueries = {
  getLocationNameSuggestions: () => ({
    queryKey: ['locationNameSuggestions'],
    queryFn: async () => await locationNameSuggestions(),
  }),
  getDistrictNameSuggestions: () => ({
    queryKey: ['districtNameSuggestions'],
    queryFn: async () => await districtNameSuggestions(),
  }),
} satisfies CdQueryDefinition;
