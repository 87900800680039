import { Skeleton, Tag } from 'antd';
import React from 'react';

import { useResources } from '../../organization/store/organization';

export const ParishTagCollection = (props: { churchIds: number[] }) => {
  const { resources, isLoading } = useResources();

  return (
    <>
      {!isLoading ? (
        resources
          ?.filter((resource) => props.churchIds?.includes(resource?.id))
          .map((church, i) => <Tag key={i}>{church.name}</Tag>)
      ) : (
        <Skeleton
          title={false}
          paragraph={{ rows: 1, style: { margin: 0 } }}
          loading
          active
        />
      )}
    </>
  );
};
