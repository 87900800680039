/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GetUsersTimeregistrationsReportResponseBody = {
    id: string;
    type: GetUsersTimeregistrationsReportResponseBody.type;
};
export namespace GetUsersTimeregistrationsReportResponseBody {
    export enum type {
        TIME_REGISTRATION_REPORT = 'time-registration-report',
    }
}

