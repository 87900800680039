import React from 'react';
import styled from 'styled-components';
import { Checkbox, Divider, Form, Input, Space } from 'antd';

import { ParticipantLists } from '../types/people';

import { I18nService } from '@/react/services/I18nService';
import { CdLock, CdParticipantList } from '@/react/shared/components/Icons';
import CdrEmptyState from '@/react/shared/components/cd-result/CdrEmptyState';

const Container = styled.main`
  border: 1px solid #d8ded8;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.05);
  margin-bottom: 18px;
  padding-bottom: 16px;
`;
const Header = styled.header`
  font-weight: 700;
  padding: 12px 0 0 16px;
  margin-bottom: 0;
`;

const ParticipatingInComponent = ({
  participantLists,
  updateParticipatingIn,
  access,
}: {
  participantLists: ParticipantLists[];
  updateParticipatingIn: (values: {
    participantLists: ParticipantLists[];
  }) => void;
  access: { canView: boolean; canEdit: boolean };
}) => {
  const [form] = Form.useForm();
  const canViewParticipantLists =
    access.canView && Array.isArray(participantLists);
  const sortedParticipantLists = participantLists.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );

  return (
    <Container>
      <Header>
        <Space>
          <CdParticipantList />
          {I18nService.getString('Participating in')}
        </Space>
      </Header>
      <Divider style={{ marginTop: '12px', marginBottom: '10px' }} />
      {!canViewParticipantLists && (
        <CdrEmptyState
          EmptyStateIcon={<CdLock />}
          subtitle={I18nService.getString(
            'You do not have access to view this information'
          )}
          title={I18nService.getString('Hidden')}
        />
      )}
      <Form
        style={{ marginTop: '0px' }}
        layout="horizontal"
        form={form}
        onFieldsChange={() => {
          updateParticipatingIn(form.getFieldsValue());
        }}
      >
        {canViewParticipantLists &&
          sortedParticipantLists?.map((participant, index) => (
            <React.Fragment key={participant.id}>
              <Form.Item
                name={['participantLists', index, 'id']}
                initialValue={participant.id}
                hidden
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['participantLists', index, 'name']}
                initialValue={participant.name}
                hidden
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['participantLists', index, 'subscribed']}
                valuePropName="checked"
                initialValue={participant.subscribed}
                style={{ marginBottom: '0px', paddingLeft: '16px' }}
              >
                <Checkbox
                  style={{ fontWeight: 'lighter' }}
                  disabled={!access.canEdit}
                >
                  {participant.name}
                </Checkbox>
              </Form.Item>
            </React.Fragment>
          ))}
      </Form>
    </Container>
  );
};

export default ParticipatingInComponent;
