import { useDebounceCallback } from 'usehooks-ts';
import { useEffect } from 'react';

import { DuplicateContact } from '../types/Types';

import { useContactsBySearch } from '@/react/people/duplicate/store/duplicateContacts';

export const useContacts = (duplicateRecordData: DuplicateContact) => {
  const { getContactsListData, isPending, contactListData } =
    useContactsBySearch({
      initialFirstName: duplicateRecordData.contactTwo?.firstName,
      initialLastName: duplicateRecordData.contactTwo?.lastName,
      initialEmail: duplicateRecordData.contactTwo?.email,
    });

  const debouncedGetContactsList = useDebounceCallback(
    getContactsListData,
    500
  );
  useEffect(() => {
    debouncedGetContactsList('');
  }, [debouncedGetContactsList]);

  const formattedOptions: { label: string; value: string; picture: string }[] =
    contactListData
      ?.map((item: any) => {
        const firstName =
          item.fields.find((field) => field.property === 'firstName')?.value ||
          '';
        const lastName =
          item.fields.find((field) => field.property === 'lastName')?.value ||
          '';
        const fullName = `${firstName} ${lastName}`.trim();
        const email =
          item.fields.find((field) => field.property === 'email')?.value || '';

        return {
          label: fullName || email,
          value: item.id,
          picture: item?.picture,
          name: fullName,
          email: email,
        };
      })
      .filter(
        (item) => item.value !== duplicateRecordData.contactTwo?.churchDeskId
      ) || [];

  return {
    isLoading: isPending,
    contactOptions: formattedOptions,
    getContactsList: debouncedGetContactsList,
    contactListData,
  };
};
