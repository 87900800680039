import { useCallback } from 'react';
import { Form } from 'antd';

import { useSendUserEmail } from '../store/users';

export const useSendEmailModal = (userId: number) => {
  const [sendUserEmailForm] = Form.useForm();
  const { sendUserEmail } = useSendUserEmail();
  const sendEmailHandler = useCallback(async () => {
    const formValues = await sendUserEmailForm.validateFields();
    sendUserEmail({ userId, ...formValues });
  }, [sendUserEmail, sendUserEmailForm, userId]);

  return {
    sendUserEmailForm,
    sendEmailHandler,
  };
};
