import {
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
} from 'recoil';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import {
  listQueries,
  PeopleListByTypeAndSearchQuery,
} from '../store/newsletterListState';
import { Segment, SegmentFilterComponent } from '../types/segment.type';
import { peopleSegmentsState } from '../store/peopleSegment';
import later from '../services/promiseTimeout';
import StateServiceFactory from '../../services/StateServiceFactory';

import { I18nService } from '@/react/services/I18nService';
import NotificationService from '@/react/services/NotificationService';
import { mainApi } from '@/react/api';

const useNewsletterList = () => {
  const queryClient = useQueryClient();
  const state = StateServiceFactory();

  const refreshLFilterList_RECOIL = useRecoilRefresher(
    PeopleListByTypeAndSearchQuery({ type: 'filter', searchParameter: '' })
  );
  const refreshNewletterList_RECOIL = useRecoilRefresher(
    PeopleListByTypeAndSearchQuery({ type: 'newsletter', searchParameter: '' })
  );
  const refreshParticipantList_RECOIL = useRecoilRefresher(
    PeopleListByTypeAndSearchQuery({ type: 'participant', searchParameter: '' })
  );

  const refreshList = useCallback(
    (type: string, skipTabChange?: boolean) => {
      queryClient.invalidateQueries(listQueries.byType(type));
      if (type === 'filter') {
        refreshLFilterList_RECOIL();
      } else if (type === 'newsletter') {
        refreshNewletterList_RECOIL();
      } else if (type === 'participant') {
        refreshParticipantList_RECOIL();
      }
      if (
        state.params.activeTab !== type &&
        state.current.name === 'app.private.people.lists2' &&
        !skipTabChange
      ) {
        state.go(
          'app.private.people.lists2',
          { activeTab: type },
          { reload: true }
        );
      }
    },
    [
      queryClient,
      refreshLFilterList_RECOIL,
      refreshNewletterList_RECOIL,
      refreshParticipantList_RECOIL,
      state,
    ]
  );

  const deleteList = useRecoilCallback(
    () => async (listId: number, type: string) => {
      const response = await mainApi.delete<Segment>(
        `/people/segments/${listId}`
      );
      if (!response.ok) {
        NotificationService.notifyError((response as any)?.data?.message);
        throw response;
      } else {
        NotificationService.notifySuccess(
          I18nService.getString('List was deleted!')
        );
        refreshList(type);
      }
    },
    [refreshList]
  );

  const createList = useRecoilCallback(
    ({ refresh }) =>
      async (
        name: string,
        setup: {
          churchIds: number[];
        },
        filterIds: number[],
        type: string
      ) => {
        const response = await mainApi.post<Segment>(`/people/segments`, {
          name,
          setup,
          filterIds,
          type,
        });
        if (!response.ok) {
          NotificationService.notifyError((response as any)?.data?.message);
          throw response;
        } else {
          if (filterIds) await later(5000);
          NotificationService.notifySuccess(
            I18nService.getString('List was created!')
          );
          refresh(peopleSegmentsState);
          refreshList(type);
        }
      },
    [refreshList]
  );

  const editListName = useRecoilCallback(
    () =>
      async (name: string, setup: SetUpType, listId: number, type: string) => {
        const response = await mainApi.put<Segment>(
          `/people/segments/${listId}`,
          {
            name,
            setup,
          }
        );
        if (!response.ok) {
          NotificationService.notifyError((response as any)?.data?.message);
          throw response;
        } else {
          NotificationService.notifySuccess(
            I18nService.getString('List was updated!')
          );
          refreshList(type);
        }
      },
    [refreshList]
  );
  const exportContacts = useRecoilCallback(
    () =>
      async (organizationId: number, filter: SetUpType, fileType: string) => {
        const response = await mainApi.post<any>(
          `/people/export/data?organizationId=${organizationId}`,
          {
            filter,
            fileType,
          }
        );
        if (!response.ok) {
          NotificationService.notifyError((response as any)?.data?.message);
          throw response;
        } else {
          NotificationService.notifySuccess(
            I18nService.getString(
              'An email will be sent to you when the export is ready.'
            )
          );
        }
      },
    []
  );
  //
  const addContactsToLists = useRecoilCallback(
    () =>
      async (body: {
        filterIds: number[];
        fromPeopleListIds: number[];
        toPeopleListIds: number[];
      }) => {
        const response = await mainApi.post<Segment>(
          `/people/segments/add-people`,
          body
        );
        if (!response.ok) {
          NotificationService.notifyError((response as any)?.data?.message);
          throw response;
        } else {
          await later(5000);
          NotificationService.notifySuccess(
            I18nService.getString('Contacts added!')
          );
        }
        refreshList('newsletter', true);
        refreshList('participant', true);
      },
    [refreshList]
  );

  return {
    deleteList,
    createList,
    addContactsToLists,
    editListName,
    exportContacts,
  };
};

export default useNewsletterList;

export interface SetUpType {
  churchIds?: number[];
  comparison: string;
  filters: SegmentFilterComponent[];
}
