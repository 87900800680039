/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSiteResponseBody } from '../models/CreateSiteResponseBody';
import type { EmptyObject } from '../models/EmptyObject';
import type { Get_sitesResponseBody } from '../models/Get_sitesResponseBody';
import type { GetBlogOrEventResponseBody } from '../models/GetBlogOrEventResponseBody';
import type { GetPublicWidgetDataResponseBody } from '../models/GetPublicWidgetDataResponseBody';
import type { GetSitesIdLoginResponseBody } from '../models/GetSitesIdLoginResponseBody';
import type { GetWidgetPublicMonoApiResponseBody } from '../models/GetWidgetPublicMonoApiResponseBody';
import type { GetWidgetsResponseBody } from '../models/GetWidgetsResponseBody';
import type { PostSitesDomainRequestBody } from '../models/PostSitesDomainRequestBody';
import type { PostSitesIdDomainResponseBody } from '../models/PostSitesIdDomainResponseBody';
import type { PostSitesRequestBody } from '../models/PostSitesRequestBody';
import type { PostWidgetsMigrationRequestBody } from '../models/PostWidgetsMigrationRequestBody';
import type { PostWidgetsRequestBody } from '../models/PostWidgetsRequestBody';
import type { PutWidgetsRequestBody } from '../models/PutWidgetsRequestBody';
import type { WidgetMigrationResponseBody } from '../models/WidgetMigrationResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class WebsiteService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * CreateSite
     * Create site
     * @returns CreateSiteResponseBody
     * @throws ApiError
     */
    public postSites({
        requestBody,
    }: {
        requestBody?: PostSitesRequestBody,
    }): CancelablePromise<CreateSiteResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sites',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get_sites
     * Get sites
     * @returns Get_sitesResponseBody
     * @throws ApiError
     */
    public getSites(): CancelablePromise<Get_sitesResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sites',
        });
    }
    /**
     * DeleteSitesId
     * Delete site
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteSites({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/sites/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * PostSitesIdDomain
     * Add domain
     * @returns PostSitesIdDomainResponseBody
     * @throws ApiError
     */
    public postSitesDomain({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PostSitesDomainRequestBody,
    }): CancelablePromise<PostSitesIdDomainResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sites/{id}/domain',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetSitesIdDomains
     * Get domains
     * @returns EmptyObject
     * @throws ApiError
     */
    public getSitesDomains({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sites/{id}/domains',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetSitesIdLogin
     * Login to site
     * @returns GetSitesIdLoginResponseBody
     * @throws ApiError
     */
    public getSitesLogin({
        id,
    }: {
        id: string,
    }): CancelablePromise<GetSitesIdLoginResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sites/{id}/login',
            path: {
                'id': id,
            },
        });
    }
    /**
     * PostSitesSiteidDomainDomainidDefault
     * @returns EmptyObject
     * @throws ApiError
     */
    public postSitesDomainDefault({
        siteId,
        domainId,
        requestBody,
    }: {
        siteId: string,
        domainId: string,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sites/{siteId}/domain/{domainId}/default',
            path: {
                'siteId': siteId,
                'domainId': domainId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeleteSitesSiteidDomainDomainidRemove
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteSitesDomainRemove({
        siteId,
        domainId,
    }: {
        siteId: string,
        domainId: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/sites/{siteId}/domain/{domainId}/remove',
            path: {
                'siteId': siteId,
                'domainId': domainId,
            },
        });
    }
    /**
     * GetSitesSiteidDomainDomainidStatus
     * @returns EmptyObject
     * @throws ApiError
     */
    public getSitesDomainStatus({
        siteId,
        domainId,
    }: {
        siteId: string,
        domainId: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sites/{siteId}/domain/{domainId}/status',
            path: {
                'siteId': siteId,
                'domainId': domainId,
            },
        });
    }
    /**
     * CreateWidget
     * Create widgets
     * @returns EmptyObject
     * @throws ApiError
     */
    public postWidgets({
        requestBody,
    }: {
        requestBody?: PostWidgetsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/widgets',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetWidgets
     * Widgets list
     * @returns GetWidgetsResponseBody
     * @throws ApiError
     */
    public getWidgets(): CancelablePromise<GetWidgetsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/widgets',
        });
    }
    /**
     * GetWidgetPublicMonoApi
     * Get widget list for monoapi
     * @returns GetWidgetPublicMonoApiResponseBody
     * @throws ApiError
     */
    public getWidgetsList(): CancelablePromise<GetWidgetPublicMonoApiResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/widgets/list',
        });
    }
    /**
     * WidgetMigration
     * Migrate widget configuration from drupal
     * @returns WidgetMigrationResponseBody
     * @throws ApiError
     */
    public postWidgetsMigration({
        requestBody,
    }: {
        requestBody?: PostWidgetsMigrationRequestBody,
    }): CancelablePromise<WidgetMigrationResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/widgets/migration',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * UpdateWidget
     * Update widget
     * @returns EmptyObject
     * @throws ApiError
     */
    public putWidgets({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PutWidgetsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/widgets/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * deleteWidget
     * Delete widget
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteWidgets({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/widgets/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Get Public widget data
     * Use it to get public widget data for widget type event, blog or slideshow.
     * @returns GetPublicWidgetDataResponseBody Success-Response for type slideshows:
     * @throws ApiError
     */
    public getWidgetsPublic({
        type,
        id,
        pageNumber,
        from,
        until,
        categories,
        parishes,
        siteId,
        districts,
        filterType = 'or',
    }: {
        type: 'event' | 'blog' | 'slideshow' | 'donation',
        id: string,
        pageNumber?: number | null,
        from?: string | null,
        until?: string | null,
        categories?: Array<(number | string)> | null,
        parishes?: Array<number> | null,
        siteId?: number | null,
        districts?: Array<string> | null,
        filterType?: 'or' | 'and',
    }): CancelablePromise<GetPublicWidgetDataResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/widgets/{id}/public',
            path: {
                'id': id,
            },
            query: {
                'type': type,
                'pageNumber': pageNumber,
                'from': from,
                'until': until,
                'categories': categories,
                'parishes': parishes,
                'siteId': siteId,
                'districts': districts,
                'filterType': filterType,
            },
        });
    }
    /**
     * GetBlogOrEvent
     * @returns GetBlogOrEventResponseBody Success-Response for type blog:
     * @throws ApiError
     */
    public getWidgets1({
        id,
        type,
    }: {
        id: string,
        type: 'blog' | 'event',
    }): CancelablePromise<GetBlogOrEventResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/widgets/{type}/{id}',
            path: {
                'id': id,
                'type': type,
            },
        });
    }
}
