import _ from 'lodash';
import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';
import React, { FunctionComponent, useState } from 'react';
import { Button, Form, Select, Input, DatePicker, InputNumber } from 'antd';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { IntentionFee } from '../../../settings/models/fee';
import { I18nService } from '../../../services/I18nService';
import { paymentMethods } from '../../shared/intentionCommon';
import { getStoleFees } from '../../../settings/redux/intention-fees/Selectors';
import { getChurches } from '../../../shared/store/resources';
import { getFilteredFees } from '../../../settings/redux/intention-fees/data.converter';
import { StyledInlineForm } from '../../../shared/antd/StyledAntdComponents';
const DatePickerMoment =
  DatePicker.generatePicker<Moment>(momentGenerateConfig);
// Antd
const { Option } = Select;

// Props
interface StolesOverviewFiltersProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: Function;
}

const StolesOverviewFilters: FunctionComponent<StolesOverviewFiltersProps> = ({
  onChange,
}) => {
  const churches = useSelector(getChurches);
  const allStoleFees = useSelector(getStoleFees);
  const [stoleYear, setStoleYear] = useState<Moment>();
  const [numberFrom, setNumberFrom] = useState<number>();
  const [numberTo, setNumberTo] = useState<number>();
  const stolesFees: IntentionFee[] = getFilteredFees(allStoleFees);
  const [form] = Form.useForm();

  const search = (formValues) => {
    const filters = {
      churchIds: _.isEmpty(formValues.churchIds)
        ? undefined
        : formValues.churchIds,
      feeIds: _.isEmpty(formValues.feeIds) ? undefined : formValues.feeIds,
      paymentMethods: _.isEmpty(formValues.paymentMethods)
        ? undefined
        : formValues.paymentMethods,
      referenceNumberFrom: numberFrom ? numberFrom : undefined,
      referenceNumberTo: numberTo ? numberTo : undefined,
      referenceNumberYear: stoleYear ? moment(stoleYear).year() : undefined,
      searchValue: _.get(formValues, 'searchText')
        ? formValues.searchText
        : undefined,
    };
    onChange(filters);
  };

  return (
    <React.Fragment>
      <StyledInlineForm
        id="stolesFiltersForm"
        onFinish={search}
        form={form}
        layout="inline"
        style={{ marginBottom: 10 }}
      >
        {/* Church filter */}
        <Form.Item name="churchIds">
          <Select
            mode="multiple"
            placeholder={I18nService.getString('Parishes')}
            allowClear
            style={{ minWidth: 150 }}
            maxTagPlaceholder={(selectedItems) => {
              if (selectedItems.length > 1) {
                return `${selectedItems.length} ${I18nService.getString(
                  'Parishes'
                )}`;
              } else {
                return `${selectedItems.length} ${I18nService.getString(
                  'Parish'
                )}`;
              }
            }}
            maxTagCount={0}
          >
            {churches &&
              churches.map((church) => (
                <Option key={church.id} value={church.id}>
                  {_.get(church, 'name')}
                </Option>
              ))}
          </Select>
        </Form.Item>
        {/* Stoles types */}
        <Form.Item name="feeIds">
          <Select
            mode="multiple"
            placeholder={I18nService.getString('Stole type')}
            allowClear
            style={{ minWidth: 150 }}
            maxTagPlaceholder={(selectedItems) => {
              if (selectedItems.length > 1) {
                return `${selectedItems.length} ${I18nService.getString(
                  'Types'
                )}`;
              } else {
                return `${selectedItems.length} ${I18nService.getString(
                  'Type'
                )}`;
              }
            }}
            maxTagCount={0}
          >
            {(stolesFees || []).map((fee) => (
              <Option key={fee.id} value={fee.id}>
                {_.get(fee, 'name')}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* Payment methods */}
        <Form.Item name="paymentMethods">
          <Select
            mode="multiple"
            placeholder={I18nService.getString('Payment methods')}
            allowClear
            style={{ minWidth: 150 }}
            maxTagPlaceholder={(selectedItems) => {
              if (selectedItems.length > 1) {
                return `${selectedItems.length} ${I18nService.getString(
                  'Methods'
                )}`;
              } else {
                return `${selectedItems.length} ${I18nService.getString(
                  'Method'
                )}`;
              }
            }}
            maxTagCount={0}
          >
            {(paymentMethods() || []).map((paymentMethod) => (
              <Option key={paymentMethod.id} value={paymentMethod.id}>
                {_.get(paymentMethod, 'name')}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* Founder or intention text */}
        <Form.Item name="searchText">
          <Input placeholder={I18nService.getString('Text...')} allowClear />
        </Form.Item>
        {/* Search on number */}
        <Form.Item
          name="numberSearch"
          label={I18nService.getString('Search on number')}
          style={{ minWidth: 'max-content', maxWidth: 'fit-content' }}
        >
          <Input.Group compact style={{ display: 'flex' }}>
            {/* Number from */}
            <InputNumber
              style={{ width: 100, textAlign: 'center' }}
              placeholder={I18nService.getString('Minimum')}
              onChange={(numberFrom: number) => setNumberFrom(numberFrom)}
            />
            <Input
              style={{
                width: 45,
                borderRight: 0,
                pointerEvents: 'none',
                textAlign: 'center',
              }}
              placeholder={I18nService.getString('to')}
              disabled
            />
            {/* Number tp */}
            <InputNumber
              style={{
                width: 100,
                textAlign: 'center',
              }}
              placeholder={I18nService.getString('Maximum')}
              onChange={(numberTo: number) => setNumberTo(numberTo)}
            />
            <Input
              style={{
                width: 30,
                borderRight: 0,
                pointerEvents: 'none',
              }}
              placeholder="-"
              disabled
            />
            {/* Year */}
            <DatePickerMoment
              placeholder={I18nService.getString('Year')}
              picker="year"
              onChange={(stoleYear) => setStoleYear(stoleYear)}
            />
          </Input.Group>
        </Form.Item>
        {/* Search */}
        <Form.Item style={{ minWidth: 90 }}>
          <Button form="stolesFiltersForm" htmlType="submit" type="primary">
            <FontAwesomeIcon icon={faSearch} style={{ marginRight: 8 }} />
            {I18nService.getString('Search')}
          </Button>
        </Form.Item>
      </StyledInlineForm>
    </React.Fragment>
  );
};

export default StolesOverviewFilters;
