import { useState } from 'react';

import {
  getSafeguardingEmploymentText,
  getSafeguardingLevelText,
  useTableTranslations,
} from '../common/Helpers';

import { I18nService } from '@/react/services/I18nService';
import NotificationService from '@/react/services/NotificationService';
import { UserUploadData } from '@/react/safeguarding/types/SafeguardingTypes';

export const useUserUploadProgress = (queue) => {
  const translations = useTableTranslations();
  const tableData = queue.rows.map((row: any) => ({
    ...row.data,
    errors: row.errors,
    addressUpdateStatus: row?.addressUpdateStatus,
  }));
  const [onlyShowFailed, setOnlyShowFailed] = useState(false);
  const filteredData: UserUploadData[] = onlyShowFailed
    ? tableData.filter((item: UserUploadData) => item.errors.length > 0)
    : tableData;

  const copyTableToClipboard = async () => {
    try {
      const translatedErrors = I18nService.getString('Errors');

      let rows = '';

      queue.rows.forEach((row) => {
        rows += `
        <tr>
          <td>${row.data.firstName || ''}</td>
          <td>${row.data.lastName || ''}</td>
          <td>${row.data.email || ''}</td>
          <td>${row.data.street || ''}</td>
          <td>${row.data.postCode || ''}</td>
          <td>${row.data.city || ''}</td>
          <td>${row.data.country || ''}</td>
          <td>${getSafeguardingLevelText(row.data.safeguardRequirement || '')}</td>
          <td>${getSafeguardingEmploymentText(row.data.type || '')}</td>
          <td>${row.data.workAndResponsibilities || ''}</td>
          <td style="color: red;">${row.errors.toString()}</td>
        </tr>
      `;
      });

      const type = 'text/html';
      const data = new Blob(
        [
          `<table>
          <thead>
            <tr>
              <th>${translations.firstName}</th>
              <th>${translations.lastName}</th>
              <th>${translations.email}</th>
              <th>${translations.street}</th>
              <th>${translations.postCode}</th>
              <th>${translations.city}</th>
              <th>${translations.country}</th>
              <th>${translations.safeguardRequirement}</th>
              <th>${translations.type}</th>
              <th>${translations.workAndResponsibilities}</th>
              <th>${translatedErrors}</th>
            </tr>
          </thead>
          <tbody>
            ${rows}
          </tbody>
        </table>`,
        ],
        { type }
      );

      const tableClipboard = new ClipboardItem({ [type]: data });

      await navigator.clipboard.write([tableClipboard]);
      NotificationService.notifySuccess(
        I18nService.getString('{{count}} Rows copied to clipboard', {
          count: queue.rows.length,
        })
      );
    } catch (error: unknown) {
      NotificationService.notifyError((error as Error).message);
    }
  };
  return {
    tableData,
    onlyShowFailed,
    setOnlyShowFailed,
    filteredData,
    copyTableToClipboard,
  };
};
