/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { GetCalendarFormsSearchResponseBody } from '../models/GetCalendarFormsSearchResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PartnerFilesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetPartnerFiles
     * Get images for the given partner
     * @returns GetCalendarFormsSearchResponseBody
     * @throws ApiError
     */
    public getPartnerFiles({
        partner,
        offset,
        limit = 1000,
        searchValue,
        publication,
        tags,
        type,
    }: {
        partner: 'geb',
        offset?: number,
        limit?: number,
        searchValue?: string | null,
        publication?: Array<string>,
        tags?: Array<string>,
        type?: Array<string>,
    }): CancelablePromise<GetCalendarFormsSearchResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner-files',
            query: {
                'partner': partner,
                'offset': offset,
                'limit': limit,
                'searchValue': searchValue,
                'publication': publication,
                'tags': tags,
                'type': type,
            },
        });
    }
    /**
     * GetPartnerFilesFilters
     * GET publication filters
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPartnerFilesFilters({
        partner = 'geb',
    }: {
        partner?: 'geb',
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner-files/filters',
            query: {
                'partner': partner,
            },
        });
    }
    /**
     * GetPartnerFilesIsCustomer
     * GET Check if has access
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPartnerFilesIsCustomer({
        partner = 'geb',
    }: {
        partner?: 'geb',
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner-files/is-customer',
            query: {
                'partner': partner,
            },
        });
    }
    /**
     * PostPartnerFilesFileidImportToOrganization
     * Import partner file to organization.
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPartnerFilesImportToOrganization({
        partner,
        fileId,
        requestBody,
    }: {
        partner: 'geb',
        fileId: string,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/partner-files/{fileId}/import-to-organization',
            path: {
                'fileId': fileId,
            },
            query: {
                'partner': partner,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetPartnerFilesSeriesidSeries
     * Get files in the given series
     * @returns GetCalendarFormsSearchResponseBody
     * @throws ApiError
     */
    public getPartnerFilesSeries({
        partner,
        seriesId,
    }: {
        partner: 'geb',
        seriesId: string,
    }): CancelablePromise<GetCalendarFormsSearchResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner-files/{seriesId}/series',
            path: {
                'seriesId': seriesId,
            },
            query: {
                'partner': partner,
            },
        });
    }
}
