import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createNewTaxonomy, updateTaxonomy } from '../services/taxonomy';
import { handleError } from '../../../services/ErrorHandlingService';
import { taxonomiesQueries } from '../../../shared/queries/taxonomies';

import { Taxonomy } from '@/react/shared/models/taxonomy';

export const useSaveNewTaxonomyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (taxonomy: Partial<Taxonomy>) => createNewTaxonomy(taxonomy),
    onSettled: () => queryClient.invalidateQueries(taxonomiesQueries.getAll()),
    onError: (error) => handleError(error),
  });
};

export const useUpdateTaxonomyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (taxonomy: Partial<Taxonomy>) => updateTaxonomy(taxonomy),
    onSettled: () => queryClient.invalidateQueries(taxonomiesQueries.getAll()),
    onError: (error) => handleError(error),
  });
};
