import angular from 'angular';

import { AbsencesActions } from '../react/calendar/store/absences/absencesSlice';

import { hideAllModals } from '@/react/angular/ReactModalBridge';

AppController.$inject = [
  '$transitions',
  '$rootScope',
  '$scope',
  '$state',
  '$uibModal',
  'gettextCatalog',
  'hotkeys',
  'Me',
  'Authorization',
  'AuthenticationService',
  '$ngRedux',
  'Calendar',
];

function AppController(
  $transitions,
  $rootScope,
  $scope,
  $state,
  $uibModal,
  gettextCatalog,
  hotkeys,
  Me,
  Authorization,
  AuthenticationService,
  $ngRedux,
  Calendar
) {
  const mapDispatchToScope = (dispatch) => ({
    closePopover: ($event) => {
      $event.stopPropagation();
      dispatch(AbsencesActions.changeOpenedAbsencePopover(false));
    },
  });

  let unsubscribe = $ngRedux.connect(
    (state) => ({
      isNewCalendar: state.calendarSettings.newCalendarView,
    }),

    mapDispatchToScope
  )($scope);

  $scope.$on('$destroy', unsubscribe);
  // Define other actions
  $transitions.onSuccess({}, () => {
    $rootScope.currentModule =
      _.split($state.current.name, '.')[2] || 'intranet';
    if ($rootScope.currentModule === 'intranet') {
      $rootScope.currentModule = 'groups';
    }
    if ($rootScope.currentModule === 'homepage') {
      $rootScope.currentModule = 'website';
    }
  });

  // Track previous state
  $rootScope.$on(
    '$stateChangeStart',
    function (event, toState, toStateParams, fromState, fromParams) {
      $rootScope.previousState = fromState;
      $rootScope.previousStateParams = fromParams;
      hideAllModals();
    }
  );

  $scope.addUser = function () {
    $uibModal
      .open({
        component: 'cdCreateUserModal',
        size: 'lg',
        windowClass: 'modal-ui-select',
      })
      .result.then(function (user) {
        $state.go('app.private.settings.users.detail', { id: user.id });
      });
  };

  /**
   * Open the form to create content.
   *
   * @param {String} type - The type of content ('event', 'absence', 'blog').
   * @param {String} operation - The operation to perform ('edit', 'create', 'copy').
   * @param {Object} entity - The data to populate the form with.
   * @param {Object} options - Additional options
   * @param {String} options.scrollTo - The id of the element to scroll to automatically
   * @param {Function} callback - A function to call when the modal is closed
   */
  $rootScope.openCreateContentWindow = (
    type,
    operation,
    entity,
    options = {},
    callback
  ) => {
    if (!operation) {
      operation = 'create';
    }
    if (!_.includes(['event', 'absence', 'blog', 'availability'], type)) {
      throw new Error(
        type +
          ' is an incorrect content type. Expected `event`, `absence` or `blog`.'
      );
    }
    if (!_.includes(['edit', 'create', 'copy'], operation)) {
      throw new Error(
        operation +
          ' is an incorrect operation. Expected `create`, `copy` or `edit`.'
      );
    }

    const modalClass = [
      'create-content-modal',
      'cd-large-modal',
      'modal-ui-select',
    ];

    if (_.get(entity, 'id')) {
      modalClass.push(`hp-update-${type}-modal`);
    } else {
      modalClass.push(`hp-create-${type}-modal`);
    }

    if (_.get(entity, 'location') && _.get(entity, 'locationObj.string')) {
      entity.location = _.get(entity, 'locationObj.string');
    }

    $uibModal
      .open({
        templateUrl:
          '@/app/shared/components/create-content/create-content.html',
        controller: 'CreateContentController',
        windowClass: modalClass.join(' '),
        backdrop: 'static',
        backdropClass: 'create-content-backdrop',
        animation: false,
        keyboard: false,
        resolve: {
          resolvedOptions: () => ({
            type,
            operation,
            options,
          }),

          resolvedEntity: () =>
            _.get(entity, 'id') && (type === 'event' || type === 'absence')
              ? Calendar.get({
                  id: _.get(entity, 'id'),
                  type,
                  operation,
                }).$promise
              : entity,
        },
      })
      .result.then((result) => {
        if (callback) {
          return callback();
        }
        // Prevent reloading if user is on /calendar/full AND they have activated the new calendar
        const isViewingNewCalendar =
          $scope.isNewCalendar &&
          $state.current.name === 'app.private.calendar.full';
        if (result === 'reloadState' && !isViewingNewCalendar) {
          $state.reload();
        }
      });
  };

  // =============================================================================
  // ChurchDesk wide shortcuts.
  // =============================================================================

  // Wait for permissions to be loaded
  Me.then(function () {
    if (Authorization.hasPermission('canAccessCalendar')) {
      // Create an event.
      hotkeys.add({
        combo: 'c e',
        description: gettextCatalog.getString('Create a new event'),
        action: 'keydown',
        callback: function () {
          $rootScope.openCreateContentWindow('event');
        },
      });
    }

    if (
      Authorization.hasPackage('intranet') &&
      Authorization.hasPermission('canCreateBlog')
    ) {
      // Create a blog.
      hotkeys.add({
        combo: 'c b',
        description: gettextCatalog.getString('Create a new blog'),
        action: 'keydown',
        callback: function () {
          $rootScope.openCreateContentWindow('blog');
        },
      });
    }

    if (Authorization.hasPermission('canCreateAbsence')) {
      // Create an absence.
      hotkeys.add({
        combo: 'c a',
        description: gettextCatalog.getString('Create a new absence'),
        action: 'keydown',
        callback: function () {
          $rootScope.openCreateContentWindow('absence');
        },
      });
    }

    if (Authorization.hasPermission('canAccessCalendar')) {
      // Go to the calendar.
      hotkeys.add({
        combo: 'g c',
        description: gettextCatalog.getString('Go to the calendar'),
        action: 'keydown',
        callback: function () {
          $state.go('app.private.calendar.full');
        },
      });
    }

    if (Authorization.hasPackage('homepage')) {
      // Go to the homepage.
      hotkeys.add({
        combo: 'g h',
        description: gettextCatalog.getString('Go to the homepage'),
        action: 'keydown',
        callback: function () {
          $state.go('app.private.people.people-announcement', {
            continue: 'homepage',
          });
        },
      });
    }
  });
}

angular.module('cdApp').controller('AppController', AppController);
