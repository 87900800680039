import React from 'react';
import { Modal, Form, Space, FormProps, Input } from 'antd';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';

import { useTemplates } from '../../hooks/useTemplates';

import { CdNewTemplate } from '@/react/shared/components/Icons';
import { I18nService } from '@/react/services/I18nService';

export const SaveNewTemplateModal = NiceModal.create(
  ({ currentPortalId }: { currentPortalId?: string }) => {
    const { saveTemplate } = useTemplates();
    const [form] = Form.useForm();
    const formItemLayout: FormProps = {
      layout: 'vertical',
    };
    const modal = useModal('SaveNewTemplate');
    return (
      <>
        <Modal
          {...antdModalV5(modal)}
          title={
            <Space>
              <CdNewTemplate />
              {currentPortalId
                ? I18nService.getString('Create new portal template')
                : I18nService.getString('Create new template')}
            </Space>
          }
          maskTransitionName="maskTransitionName"
          destroyOnClose
          onOk={() =>
            form.validateFields().then(async (values) => {
              await saveTemplate(values.title, currentPortalId);
              modal.hide();
            })
          }
          okText={I18nService.getString('Create')}
          cancelText={I18nService.getString('Cancel')}
        >
          <Form
            name="people_message_template_modal"
            form={form}
            {...formItemLayout}
          >
            <Form.Item>
              {currentPortalId
                ? I18nService.getString(
                    'Choose a name for new portal template. The template will be visible for everyone in the portal.'
                  )
                : I18nService.getString(
                    'Choose a name for your new template. The template will be visible for everyone in your organization.'
                  )}
            </Form.Item>
            <Form.Item
              name="title"
              label={I18nService.getString('Title')}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
);
NiceModal.register('SaveNewTemplate', SaveNewTemplateModal);
