import { Form, Col, Row, Input, Layout, Spin } from 'antd';
import React, { Suspense, useEffect, useState } from 'react';

import { Statistics } from '../statistics/Statistics';
import { useMailEditor, useMessage } from '../../hooks';
import MessageFormDetails from '../shared/containers/MessageDetails';

import { MobilePreview } from './MobilePreview';
import { UnlayerEditor } from './UnlayerEditor';
import NavigationButtons from './NavigationButtons';

import { I18nService } from '@/react/services/I18nService';
import { CdTabs } from '@/react/shared/components/cd-tabs/CdTabs';
import {
  MessageStatisticsFilters,
  MessageType,
  EntityType,
} from '@/react/people/types/message';
import { CdDesktop, CdMobile } from '@/react/shared/components/Icons';
import { SaveNewTemplateModal } from '@/react/people/message-editor/advanced-editor/SaveNewTemplateModal';
import { IframeMailViewer } from '@/react/people/message-editor/shared/containers/IframeMailViewer';
import { CdNotFound } from '@/react/shared/components/cd-result/CdNotFound';

const AdvancedMessageEditor = ({
  messageId,
  to,
  type,
  statisticsFilter,
}: {
  messageId?: number;
  to: any[];
  type: MessageType;
  statisticsFilter: MessageStatisticsFilters;
}) => {
  const [current, setCurrent] = useState('design');
  const [form] = Form.useForm();
  const {
    message,
    saveMessage,
    deleteMessage,
    cloneMessage,
    sendMessage,
    sendTestMessage,
    attachFile,
    refetchMessage,
  } = useMessage(messageId, type);

  const { debouncedOnFormChange } = useMailEditor({
    type: type,
    form,
    message,
    saveMessage,
    to,
  });
  const entityType =
    to?.[0]?.anniversary || message?.anniversary?.uniqueId
      ? EntityType.ANNIVERSARY
      : EntityType.MESSAGE;
  const isScheduled = message?.scheduled;
  const isSent = message?.sent;

  useEffect(() => {
    if (isScheduled) setCurrent('statistics');
  }, [isScheduled]);

  const onChange = (current) => {
    setCurrent(current);
  };

  if (!message?.id && messageId) {
    return (
      <CdNotFound
        title={I18nService.getString('Not found')}
        subTitle={I18nService.getString('The message could not be found.')}
      />
    );
  }

  const themeItems = [
    {
      key: '1',
      icon: <CdDesktop />,
      title: I18nService.getString('Desktop'),
      content: (
        <div style={{ flexGrow: 1 }}>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <IframeMailViewer
                minHeight="748px"
                value={getFieldValue('rendered')}
              />
            )}
          </Form.Item>
        </div>
      ),
    },
    {
      key: '2',
      icon: <CdMobile />,
      title: I18nService.getString('Mobile'),
      content: (
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => (
            <MobilePreview value={getFieldValue('rendered')} />
          )}
        </Form.Item>
      ),
    },
  ];

  const designTab = {
    key: 'design',
    title: I18nService.getString('Design'),
    subtitle: I18nService.getString(`Create any design you'd like`),
    showTabNumber: true,
    content: (
      <>
        <Form.Item hidden={true} name="rendered">
          <Input />
        </Form.Item>
        <Form.Item name="content" noStyle>
          <UnlayerEditor
            form={form}
            attachFile={attachFile}
            displayMode="email"
            entityType={entityType}
          />
        </Form.Item>
      </>
    ),
  };

  const tabItems = [
    {
      key: 'toAndFrom',
      title: I18nService.getString('To and from'),
      subtitle: I18nService.getString('Message details'),
      showTabNumber: true,
      content: (
        <div style={{ padding: 24 }}>
          <Row justify="center">
            <Col style={{ maxWidth: '800px' }} flex="1 1 auto">
              <MessageFormDetails isSent={!!isSent} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <CdTabs
                centered
                style={{ overflow: 'auto' }}
                items={themeItems}
              />
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  const statisticTab = {
    key: 'statistics',
    title: I18nService.getString('Statistics'),
    subtitle: I18nService.getString('Analyze the performance of your message'),
    showTabNumber: true,
    content: (
      <Suspense
        fallback={
          <div style={{ textAlign: 'center' }}>
            <Spin />
          </div>
        }
      >
        <Statistics
          message={message}
          refetchMessage={refetchMessage}
          defaultFilter={statisticsFilter}
        />
      </Suspense>
    ),
  };

  if (!isSent) tabItems.splice(0, 0, designTab);
  if (isScheduled) tabItems.push(statisticTab);

  return (
    <Layout.Content className="app-main" style={{ padding: 0 }}>
      <SaveNewTemplateModal id="SaveNewTemplate" />
      <Form
        form={form}
        style={{ height: '100%' }}
        labelCol={{ span: 2 }}
        onValuesChange={debouncedOnFormChange}
      >
        <CdTabs
          activeKey={current}
          onChange={onChange}
          noInnerPadding
          fluidHeight
          tabBarExtraContent={{
            right: (
              <NavigationButtons
                message={message}
                handleCopy={cloneMessage}
                handleDelete={deleteMessage}
                sendMessage={sendMessage}
                sendTestMessage={sendTestMessage}
                form={form}
                saveMessage={saveMessage}
                formValidationError={() => setCurrent('toAndFrom')}
                debouncedOnFormChange={debouncedOnFormChange}
              />
            ),
          }}
          items={tabItems}
        />
      </Form>
    </Layout.Content>
  );
};

export default AdvancedMessageEditor;
