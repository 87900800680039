import React, { Suspense } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Button, Dropdown, MenuProps } from 'antd';
import { ButtonType } from 'antd/es/button';
import { useRecoilValue } from 'recoil';
import { $injector } from 'ngimport';
import { SizeType } from 'antd/es/config-provider/SizeContext';

import { MessageType, PeopleMessageRecipient } from '../../../types/message';

import { showTemplateSelector } from './TemplateSelector';

import {
  CdStudio,
  CdMessage,
  CdBasic,
  CdMobile,
  CdAngleDown,
  CdStudioEventInvitation,
  CdPrint,
} from '@/react/shared/components/Icons';
import { I18nService } from '@/react/services/I18nService';
import { getStateUrl, navigate } from '@/react/services/StateServiceFactory';
import { UserHasPrivilege } from '@/react/user/store/permissions';
import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';
import cdApp from '@/react/config';

export const PeopleMessagesAngular = () =>
  $injector.get('PeopleMessages') as any;

interface MessageListCreateDropdownProps {
  fetchRecipientsFromContext?: {
    ref: (messageType: string) => PeopleMessageRecipient[];
  };
  recipients?: PeopleMessageRecipient[];
  buttonLabel?: string;
  buttonDisabled?: boolean;
  buttonSize?: SizeType;
  recipientsHasSMS?: boolean;
  recipientsHasEmail?: boolean;
  visibleNewsletter?: boolean;
  visibleInvitation?: boolean;
  disableStudio?: boolean;
  isGlobalSelect?: boolean;
  buttonType?: ButtonType;
  // Before navigate can be used to close a modal.
  beforeNavigate?: () => void;
  // Important. openInNewTab only works with contacts and not segments in the "recipients" attribute
  openInNewTab?: boolean;
  extraItem?: { value: string; label: string };
}
const MessageListCreateDropdown = (props: MessageListCreateDropdownProps) => {
  const {
    recipientsHasSMS = true,
    recipientsHasEmail = true,
    visibleInvitation = true,
    visibleNewsletter = true,
    disableStudio = false,
    buttonType = 'primary',
    fetchRecipientsFromContext,
    recipients,
    beforeNavigate,
    openInNewTab,
    extraItem,
  } = props;
  const canCommunicate = useRecoilValue(
    UserHasPrivilege('people_message.actions.send')
  );
  const handleClick = ({ key }) => {
    const to: any[] = (fetchRecipientsFromContext?.ref(key) || []).concat(
      recipients || []
    );
    beforeNavigate && beforeNavigate();

    switch (key) {
      case MessageType.BASIC:
      case MessageType.SIMPLE:
        if (openInNewTab) {
          const url = getStateUrl('app.private.people.messages.editorV2.view', {
            type: key,
            contactIds: to.map((c) => c.id),
          });
          window.open(url, '_blank');
        } else {
          navigate('app.private.people.messages.editorV2.view', {
            type: key,
            to,
            sourceInfo: to[0]?.anniversary && {
              anniversary: to.map((recipient) => ({
                personId: recipient.id,
                uniqueId: recipient.uniqueId,
                type: recipient.anniversary.type,
                label: recipient.anniversary.label,
                date: recipient.anniversary.date,
                yearSince: recipient.anniversary.yearSince,
                inComingDate: recipient.anniversary.inComingDate,
              })),
            },
          });
        }
        break;
      case MessageType.SMS:
        let messageObject = undefined;
        if (fetchRecipientsFromContext?.ref || recipients) {
          messageObject = PeopleMessagesAngular().empty();
          messageObject.type = key;
          messageObject.to = to;
          if (to[0]?.anniversary) {
            messageObject.sourceInfo = {
              anniversary: to.map((recipient) => ({
                personId: recipient.id,
                uniqueId: recipient.uniqueId,
                type: recipient.anniversary.type,
                label: recipient.anniversary.label,
                date: recipient.anniversary.date,
                yearSince: recipient.anniversary.yearSince,
                inComingDate: recipient.anniversary.inComingDate,
              })),
            };
          }
        }

        navigate('app.private.people.messages.create', {
          type: key,
          messageObject,
        });
        break;
      case MessageType.EVENT_INVITATION:
        NiceModal.show('CalendarEmailPosterTemplates', { recipients: to });
        break;
      case MessageType.ADVANCED:
        showTemplateSelector({
          recipients: to,
          sourceInfo: to[0]?.anniversary && {
            anniversary: to.map((recipient) => ({
              personId: recipient.id,
              uniqueId: recipient.uniqueId,
              type: recipient.anniversary.type,
              label: recipient.anniversary.label,
              date: recipient.anniversary.date,
              yearSince: recipient.anniversary.yearSince,
              inComingDate: recipient.anniversary.inComingDate,
            })),
          },
        });

        break;
    }
  };

  // TODO: Implement the extra item click once the ORT Web2Print is implemented
  const handleExtraItemClick = () => {
    // eslint-disable-next-line no-console
    console.log('extraItem', extraItem);
  };

  const items: MenuProps['items'] = extraItem
    ? [
        {
          key: extraItem.value,
          onClick: handleExtraItemClick,
          icon: <CdPrint fixedWidth />,
          label: extraItem.label,
          disabled:
            recipients?.length === 0 ||
            cdApp.organization?.countryIso2 !== 'de',
        },
      ]
    : [];

  if (!disableStudio && recipientsHasEmail) {
    items.push({
      key: MessageType.ADVANCED,
      onClick: handleClick,
      icon: <CdStudio fixedWidth />,
      label: I18nService.getString('Studio'),
      disabled: recipients?.length === 0,
    });
  }
  if (recipientsHasEmail) {
    if (visibleInvitation) {
      items.push({
        key: MessageType.EVENT_INVITATION,
        onClick: handleClick,
        icon: <CdStudioEventInvitation fixedWidth />,
        label: I18nService.getString('Send invitation'),
      });
    }
    if (visibleNewsletter) {
      items.push({
        key: MessageType.SIMPLE,
        onClick: handleClick,
        icon: <CdMessage fixedWidth />,
        label: I18nService.getString('Newsletter'),
      });
    }
    items.push({
      key: MessageType.BASIC,
      onClick: handleClick,
      icon: <CdBasic fixedWidth />,
      label: I18nService.getString('E-mail'),
      disabled: recipients?.length === 0,
    });
  }
  if (recipientsHasSMS) {
    items.push({
      key: MessageType.SMS,
      onClick: handleClick,
      icon: <CdMobile fixedWidth />,
      label: I18nService.getString('Text message'),
      disabled: recipients?.length === 0,
    });
  }
  return (
    <Dropdown
      menu={{ items }}
      disabled={!canCommunicate || props.buttonDisabled}
    >
      <CdTooltip
        title={
          props.buttonDisabled && props.isGlobalSelect
            ? I18nService.getString(
                'Please make a filter or a list if you want to communicate to many contacts at the same time.'
              )
            : undefined
        }
      >
        <Button
          type={buttonType}
          disabled={!canCommunicate || props.buttonDisabled}
          size={props.buttonSize || 'middle'}
        >
          {props.buttonLabel || I18nService.getString('Create')}
          <CdAngleDown />
        </Button>
      </CdTooltip>
    </Dropdown>
  );
};

MessageListCreateDropdown.Skeleton = (props) => (
  <Dropdown menu={null}>
    <Button type="primary" disabled loading size={props.buttonSize || 'middle'}>
      {props.buttonLabel || I18nService.getString('Create')} <CdAngleDown />
    </Button>
  </Dropdown>
);
// @ts-ignore typescript don't understand that a function in javascript is just a fancy object. and we are allowed to set props on it
MessageListCreateDropdown.Skeleton.displayName =
  'MessageListCreateDropdownSkeleton';

export default function MessageListCreateDropdownWithSuspense(
  props: MessageListCreateDropdownProps
) {
  return (
    <Suspense fallback={<MessageListCreateDropdown.Skeleton {...props} />}>
      <MessageListCreateDropdown {...props} />
    </Suspense>
  );
}
