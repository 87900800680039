import angular from 'angular';

angular.module('cdApp.people').run([
  '$templateCache',
  ($templateCache) => {
    $templateCache.put(
      '@/app/people/custom-fields/custom-fields.component.html',
      require('./custom-fields/custom-fields.component.html')
    );

    $templateCache.put(
      '@/app/people/message-editor/editor.html',
      require('./message-editor/editor.html')
    );

    $templateCache.put(
      '@/app/people/message-editor/message-editor.component.html',
      require('./message-editor/message-editor.component.html')
    );

    $templateCache.put(
      '@/app/people/message-editor/modals/add-content/add-content.component.html',
      require('./message-editor/modals/add-content/add-content.component.html')
    );

    $templateCache.put(
      '@/app/people/message-editor/modals/insert-link/insert-link.component.html',
      require('./message-editor/modals/insert-link/insert-link.component.html')
    );

    $templateCache.put(
      '@/app/people/message-editor/modals/schedule-message/schedule-message.component.html',
      require('./message-editor/modals/schedule-message/schedule-message.component.html')
    );

    $templateCache.put(
      '@/app/people/message-editor/themes/azul/email.html',
      require('./message-editor/themes/azul/email.html')
    );

    $templateCache.put(
      '@/app/people/message-editor/themes/azul/events.partial.html',
      require('./message-editor/themes/azul/events.partial.html')
    );

    $templateCache.put(
      '@/app/people/message-editor/themes/azul/forms.partial.html',
      require('./message-editor/themes/azul/forms.partial.html')
    );

    $templateCache.put(
      '@/app/people/message-editor/themes/azul/news.partial.html',
      require('./message-editor/themes/azul/news.partial.html')
    );

    $templateCache.put(
      '@/app/people/message-list/message-list.component.html',
      require('./message-list/message-list.component.html')
    );

    $templateCache.put(
      '@/app/people/message-statistics/message-statistics.component.html',
      require('./message-statistics/message-statistics.component.html')
    );

    $templateCache.put(
      '@/app/people/people-detail/partials/activity-step.html',
      require('./people-detail/partials/activity-step.html')
    );

    $templateCache.put(
      '@/app/people/people-detail/partials/basic-step.html',
      require('./people-detail/partials/basic-step.html')
    );

    $templateCache.put(
      '@/app/people/people-detail/partials/settings-step.html',
      require('./people-detail/partials/settings-step.html')
    );

    $templateCache.put(
      '@/app/people/people-detail/partials/pastoral-notes-step.html',
      require('./people-detail/partials/pastoral-notes-step.html')
    );

    $templateCache.put(
      '@/app/people/people-detail/people-detail.component.html',
      require('./people-detail/people-detail.component.html')
    );

    $templateCache.put(
      '@/app/people/people-export-download/people-export-download.component.html',
      require('./people-export-download/people-export-download.component.html')
    );

    $templateCache.put(
      '@/app/people/people-import/people-import.component.html',
      require('./people-import/people-import.component.html')
    );

    $templateCache.put(
      '@/app/people/people-import-details/people-import-details.component.html',
      require('./people-import-details/people-import-details.component.html')
    );

    $templateCache.put(
      '@/app/people/people-import-history/people-import-history.component.html',
      require('./people-import-history/people-import-history.component.html')
    );

    $templateCache.put(
      '@/app/people/people-list/people-list.component.html',
      require('./people-list/people-list.component.html')
    );

    $templateCache.put(
      '@/app/people/people.component.html',
      require('./people.component.html')
    );

    $templateCache.put(
      '@/app/people/shared/components/create-list/create-list.component.html',
      require('./shared/components/create-list/create-list.component.html')
    );

    $templateCache.put(
      '@/app/people/shared/components/create-people/create-people.component.html',
      require('./shared/components/create-people/create-people.component.html')
    );

    $templateCache.put(
      '@/app/people/shared/components/delete-people/delete-people.component.html',
      require('./shared/components/delete-people/delete-people.component.html')
    );

    $templateCache.put(
      '@/app/people/shared/components/edit-list/edit-list.component.html',
      require('./shared/components/edit-list/edit-list.component.html')
    );

    $templateCache.put(
      '@/app/people/shared/components/edit-people/edit-people.component.html',
      require('./shared/components/edit-people/edit-people.component.html')
    );

    $templateCache.put(
      '@/app/people/shared/components/edit-people/partials/basic.partial.html',
      require('./shared/components/edit-people/partials/basic.partial.html')
    );

    $templateCache.put(
      '@/app/people/shared/components/edit-people/partials/custom.partial.html',
      require('./shared/components/edit-people/partials/custom.partial.html')
    );

    $templateCache.put(
      '@/app/people/shared/components/edit-people/partials/family.partial.html',
      require('./shared/components/edit-people/partials/family.partial.html')
    );

    $templateCache.put(
      '@/app/people/shared/components/edit-people/partials/personal.partial.html',
      require('./shared/components/edit-people/partials/personal.partial.html')
    );

    $templateCache.put(
      '@/app/people/shared/components/export-people/export-people.component.html',
      require('./shared/components/export-people/export-people.component.html')
    );

    $templateCache.put(
      '@/app/people/shared/components/list-switcher/list-switcher.component.html',
      require('./shared/components/list-switcher/list-switcher.component.html')
    );

    $templateCache.put(
      '@/app/people/shared/components/message-sender/message-sender.component.html',
      require('./shared/components/message-sender/message-sender.component.html')
    );

    $templateCache.put(
      '@/app/people/shared/components/multi-tagging/multi-tagging.component.html',
      require('./shared/components/multi-tagging/multi-tagging.component.html')
    );

    $templateCache.put(
      '@/app/people/shared/components/person-consents/person-consents.component.html',
      require('./shared/components/person-consents/person-consents.component.html')
    );

    $templateCache.put(
      '@/app/people/shared/components/profile-information/profile-information.component.html',
      require('./shared/components/profile-information/profile-information.component.html')
    );

    $templateCache.put(
      '@/app/people/shared/components/relation-card/relation-card.component.html',
      require('./shared/components/relation-card/relation-card.component.html')
    );

    $templateCache.put(
      '@/app/people/shared/components/select-tags-dropdown/select-tags-dropdown.html',
      require('./shared/components/select-tags-dropdown/select-tags-dropdown.html')
    );

    $templateCache.put(
      '@/app/people/shared/components/sms-sender-name/sms-sender-name.component.html',
      require('./shared/components/sms-sender-name/sms-sender-name.component.html')
    );

    $templateCache.put(
      '@/app/people/subscription/subscription-confirm.component.html',
      require('./subscription/subscription-confirm.component.html')
    );

    $templateCache.put(
      '@/app/people/subscription/subscription-manage.component.html',
      require('./subscription/subscription-manage.component.html')
    );

    $templateCache.put(
      '@/app/people/shared/components/create-people/confirmation-modal/confirmation-modal.html',
      require('./shared/components/create-people/confirmation-modal/confirmation-modal.html')
    );
  },
]);
