import _ from 'lodash';
import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentUser } from '../../config/store/Selector';
import { AppState } from '../../redux';
import { Me } from '../../shared/models/authentication';
import { ToggleMeFilter } from '../store/filters/Actions';

import { SearchableFilterItem } from '@/react/calendar/components/SearchableFilterItem';

const PersonalCalendarFilter: FunctionComponent = () => {
  const dispatch = useDispatch();
  const onToggle = () => dispatch(ToggleMeFilter());
  const me: Me = useSelector(getCurrentUser, _.isEqual);
  const isSelected: boolean = useSelector(
    (state: AppState) => state.calendar.filters.showMyCalendar
  );
  return (
    <SearchableFilterItem
      onLabelClick={onToggle}
      itemData={{
        id: me.id,
      }}
      color="fallback"
      feedType="user"
      isSelected={isSelected}
      name={me.fullName ? me.fullName : me.email}
    />
  );
};

export default PersonalCalendarFilter;
