import { ReactNode } from 'react';
import { FormInstance } from 'antd';
import { intersection, isArray } from 'lodash';
import React from 'react';

import { useFormValidation } from '../../../../contributions/hooks/useFormValidation';

export const IfFormHasError = (props: {
  form: FormInstance;
  name: string | string[];
  children?: ReactNode;
}) => {
  const validation = useFormValidation(props.form);
  const name = isArray(props.name) ? props.name : [props.name];

  const errors =
    validation.errors?.errorFields?.filter(
      (error) => intersection(name, error.name).length === name.length
    ) || [];

  return <>{errors?.length > 0 && props.children}</>;
};
