/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetCalendarFormsSearchResponseBody } from '../models/GetCalendarFormsSearchResponseBody';
import type { GetEntityRevisionsResponseBody } from '../models/GetEntityRevisionsResponseBody';
import type { RetrieveRevisionResponseBody } from '../models/RetrieveRevisionResponseBody';
import type { RevertRevisionRequestBody } from '../models/RevertRevisionRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class RevisionsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetEntityRevisions
     * Index
     * @returns GetEntityRevisionsResponseBody
     * @throws ApiError
     */
    public getRevisions({
        entityType,
        entityId,
    }: {
        entityType: string,
        entityId: number,
    }): CancelablePromise<GetEntityRevisionsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/revisions/{entityType}/{entityId}',
            path: {
                'entityType': entityType,
                'entityId': entityId,
            },
        });
    }
    /**
     * RevertRevision
     * Revert
     * @returns GetCalendarFormsSearchResponseBody
     * @throws ApiError
     */
    public postRevisionsRevert({
        entityType,
        entityId,
        revisionId,
        clientVersion,
        requestBody,
    }: {
        entityType: string,
        entityId: number,
        revisionId: number,
        clientVersion?: number,
        requestBody?: RevertRevisionRequestBody,
    }): CancelablePromise<GetCalendarFormsSearchResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/revisions/{entityType}/{entityId}/revert/{revisionId}',
            path: {
                'entityType': entityType,
                'entityId': entityId,
                'revisionId': revisionId,
            },
            query: {
                'clientVersion': clientVersion,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * RetrieveRevision
     * Retrieve
     * @returns RetrieveRevisionResponseBody
     * @throws ApiError
     */
    public getRevisionsView({
        entityType,
        entityId,
        revisionId,
        clientVersion,
    }: {
        entityType: string,
        entityId: number,
        revisionId: number,
        clientVersion?: number,
    }): CancelablePromise<RetrieveRevisionResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/revisions/{entityType}/{entityId}/view/{revisionId}',
            path: {
                'entityType': entityType,
                'entityId': entityId,
                'revisionId': revisionId,
            },
            query: {
                'clientVersion': clientVersion,
            },
        });
    }
}
