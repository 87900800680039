/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostIntentionIntentionsRequestBody = {
    intentionText: string;
    intentionYear: number;
    church: {
        id: number;
    };
    fee: {
        id: string;
    };
    acceptedAt: string;
    resource?: {
        id: number;
    } | null;
    person?: {
        id: number;
    } | null;
    founder?: string | null;
    nextReferenceNumber?: string | null;
    paymentMethod?: PostIntentionIntentionsRequestBody.paymentMethod;
    paid?: boolean | null;
    paidAt?: string | null;
    preferredDate?: string | null;
    preferredNote?: string | null;
    status?: PostIntentionIntentionsRequestBody.status;
    comment?: string | null;
    calendarId?: number;
};
export namespace PostIntentionIntentionsRequestBody {
    export enum paymentMethod {
        CASH = 'cash',
        BANK = 'bank',
    }
    export enum status {
        UNASSIGNED = 'unassigned',
        ASSIGNED = 'assigned',
        COMPLETED = 'completed',
    }
}

