import { useQuery } from '@tanstack/react-query';

import { tagsQueries } from '@/react/shared/queries/tags';

export const useManageContactsTag = (searchKeyWord: string) => {
  const tagsQuery = useQuery(tagsQueries.getTagsBySearch(searchKeyWord));
  const { data, isLoading } = tagsQuery;
  return {
    tagsData: data?.items || [],
    tagIsLoading: isLoading,
  };
};
