/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostWidgetsRequestBody = {
    name: string;
    type: PostWidgetsRequestBody.type;
    configuration: any;
};
export namespace PostWidgetsRequestBody {
    export enum type {
        EVENT = 'event',
        BLOG = 'blog',
        SLIDESHOW = 'slideshow',
        DONATION = 'donation',
    }
}

