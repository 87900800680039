/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { Register_Device_TokenRequestBody } from '../models/Register_Device_TokenRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DevicesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Delete_Device_Token
     * Delete device token
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteDevices({
        deviceToken,
    }: {
        deviceToken: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/devices/{deviceToken}',
            path: {
                'deviceToken': deviceToken,
            },
        });
    }
    /**
     * Register_Device_Token
     * Register device token
     * @returns EmptyObject
     * @throws ApiError
     */
    public postDevices({
        deviceToken,
        deviceType,
        environment,
        requestBody,
    }: {
        deviceToken: string,
        deviceType: 'android' | 'ios' | 'expo',
        environment: 'dev' | 'staging' | 'prod',
        requestBody?: Register_Device_TokenRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/devices/{deviceToken}/{deviceType}/{environment}',
            path: {
                'deviceToken': deviceToken,
                'deviceType': deviceType,
                'environment': environment,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
