import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useQuery } from '@tanstack/react-query';

import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import { I18nService } from '@/react/services/I18nService';
import { UserHasPrivilege } from '@/react/user/store/permissions';
import { tagsQueries } from '@/react/shared/queries/tags';
import { CdClientSideTableApiSearchParam } from '@/react/shared/components/cd-client-side-table/types';
import { useDeleteTag } from '@/react/shared/hooks/useTags';

export const useTagList = () => {
  const useTagsClientsideQuery = (params: CdClientSideTableApiSearchParam) =>
    useQuery(tagsQueries.getByParams(params));

  const [searchTag, setSearchTag] = useState<string>('');

  const handleSearch = (value: string) => {
    setSearchTag(value);
  };
  const canManageTags = useRecoilValue(
    UserHasPrivilege('organization.actions.managePeopleTags')
  );
  const canDeleteTags = useRecoilValue(
    UserHasPrivilege('organization.actions.deletePeopleTags')
  );
  const canCreateList = useRecoilValue(
    UserHasPrivilege('organization.people_segment.edit')
  );
  const { deleteTag, isPending } = useDeleteTag();
  const onDeleteTag = useCallback(
    (tagId) => {
      showConfirmModal({
        title: I18nService.getString('Delete tag'),
        message: I18nService.getString(
          'Deleting a tag doesn’t delete the tagged contacts but you’ll no longer be able to search for all contacts with this tag.'
        ),
        okText: I18nService.getString('Delete'),
        okButtonProps: { danger: true },
        onOk: () => deleteTag(tagId),
      });
    },
    [deleteTag]
  );
  return {
    searchTag,
    handleSearch,
    onDeleteTag,
    canManageTags,
    canCreateList,
    canDeleteTags,
    isDeletePending: isPending,
    useTagsClientsideQuery,
  };
};
