'use strict';

import { I18nService } from '@/react/services/I18nService';

class RegisterPageComponent {
  constructor(
    _,
    $q,
    $http,
    $state,
    $stateParams,
    localStorageService,
    AuthenticationService,
    gettextCatalog,
    toastr,
    cdApp
  ) {
    'ngInject';

    this._ = _;
    this.$q = $q;
    this.$http = $http;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.localStorageService = localStorageService;
    this.AuthenticationService = AuthenticationService;
    this.gettextCatalog = gettextCatalog;
    this.toastr = toastr;
    this.cdApp = cdApp;
  }

  $onInit() {
    this.loading = true;
    this.submitting = false;
    this.tokenExpired = false;

    const { token } = this.$stateParams;

    this.AuthenticationService.validateToken(token).then(
      (response) => {
        this.loading = false;

        const data = response.data;
        this.reset = data;
        this.payload = this._.pick(data, ['id', 'email', 'resetPasswordToken']);

        const user = this._.find(data.users, { id: data.id });
        if (user && user.contact) {
          this._.extend(
            this.payload,
            this._.pick(user.contact, ['firstName', 'lastName'])
          );
        }

        const language =
          (data.organization && data.organization.language) ||
          this.gettextCatalog.currentLanguage;

        I18nService.setCurrentLanguage(language);

        let termsLink;
        let privacyLink;
        switch (language) {
          case 'de':
            termsLink = 'https://www.churchdesk.com/de/rechtliches';
            privacyLink = 'https://www.churchdesk.com/de/rechtliches';
            break;
          case 'da':
            termsLink = 'https://www.churchdesk.com/da/jura';
            privacyLink = 'https://www.churchdesk.com/da/jura';
            break;
          default:
            termsLink = 'https://www.churchdesk.com/en/legal';
            privacyLink = 'https://www.churchdesk.com/en/legal';
            break;
        }

        this.acknowledgement = this.gettextCatalog.getString(
          'By clicking <i>Register</i>, you agree to the ChurchDesk <a href="{{termsLink}}" target="_blank">terms & conditions</a> and <a href="{{privacyLink}}" target="_blank">privacy policy</a>.',
          {
            termsLink: `${termsLink}`,
            privacyLink: `${privacyLink}`,
          }
        );
      },
      (error) => {
        this.loading = false;

        if (error.status === 401) {
          this.tokenExpired = true;
        }
      }
    );
  }

  updateUser() {
    this.submitting = true;

    const input = $('input[ng-model="$ctrl.payload.phones"]');

    if (input.intlTelInput('isValidNumber')) {
      this.payload.phones = [
        {
          countryId: input.intlTelInput('getSelectedCountryData').iso2,
          phone: input
            .intlTelInput(
              'getNumber',
              window.intlTelInputUtils.numberFormat.NATIONAL
            )
            .replace(/( )|(\D)/g, ''),
          default: 1,
        },
      ];
    } else {
      delete this.payload.phones;
    }

    this.$q
      .all([
        this.$http.put(
          `${cdApp.config.api.main}/users/${this.payload.id}`,
          this._.pick(this.payload, [
            'id',
            'newPassword',
            'resetPasswordToken',
          ]),

          {
            params: { organizationId: this.reset.organization.id },
            headers: { Authorization: `Bearer ${this.reset.accessToken}` },
          }
        ),

        this.$http.put(
          `${cdApp.config.api.main}/users/${this.payload.id}`,
          this._.omit(this.payload, ['newPassword', 'resetPasswordToken']),
          {
            params: { organizationId: this.reset.organization.id },
            headers: { Authorization: `Bearer ${this.reset.accessToken}` },
          }
        ),
      ])
      .then(() => {
        this.localStorageService.set('accessToken', this.reset.accessToken);
        this.$state.go('app.public.chooseOrganization');
      })
      .catch((error) => {
        this.submitting = false;
        this.toastr.error(
          _.get(error, 'data.message') ||
            'Something went wrong. Please try again.'
        );
      });
  }
}
RegisterPageComponent.$inject = [
  '_',
  '$q',
  '$http',
  '$state',
  '$stateParams',
  'localStorageService',
  'AuthenticationService',
  'gettextCatalog',
  'toastr',
  'cdApp',
];

angular.module('cdApp.shared').component('cdRegisterPage', {
  templateUrl:
    '@/app/shared/components/register-page/register-page.component.html',
  controller: RegisterPageComponent,
});
