/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostIntentionFoundationsRequestBody = {
    type: PostIntentionFoundationsRequestBody.type;
    status: PostIntentionFoundationsRequestBody.status;
    intentionText: string;
    startBalance?: (number | null | number);
    church: {
        id: number;
    };
    fee: {
        id: string;
    };
    resource?: {
        id: number;
    } | null;
    person?: {
        id: number;
    } | null;
    founder?: string | null;
    nextReferenceNumber?: string | null;
    paymentMethod?: PostIntentionFoundationsRequestBody.paymentMethod;
    paid?: boolean | null;
    paidAt?: string | null;
    preferredDate?: string | null;
    preferredNote?: string | null;
    intentionsPerYear?: number | null;
    totalYears?: number | null;
    internalNote?: string | null;
    remainingBalance?: number | null;
    startYear?: number | null;
};
export namespace PostIntentionFoundationsRequestBody {
    export enum type {
        STIFTUNGEN = 'stiftungen',
        LEGATE = 'legate',
    }
    export enum status {
        INACTIVE = 'inactive',
        ACTIVE = 'active',
        COMPLETED = 'completed',
    }
    export enum paymentMethod {
        CASH = 'cash',
        BANK = 'bank',
    }
}

