import {
  Button,
  Col,
  Drawer,
  Form,
  Layout,
  Row,
  Space,
  Spin,
  Steps,
} from 'antd';
import React, { Suspense, useState } from 'react';
import NiceModal, { antdDrawerV5, useModal } from '@ebay/nice-modal-react';
import styled from 'styled-components';

import { I18nService } from '@/react/services/I18nService';
import AuthorizationService from '@/react/services/AuthorizationService';
import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';
import {
  ISelectableCardItem,
  SelectableCards,
} from '@/react/shared/components/SelectableCards';
import { CdCalendar, CdPoster } from '@/react/shared/components/Icons';
import { useCalendarPosterTemplate } from '@/react/calendar/hooks/event/useCalendarPoster';
import CdrEmptyState from '@/react/shared/components/cd-result/CdrEmptyState';
import EventSearchForm, {
  RowSelectionType,
} from '@/react/people/message-editor/newsletter-editor/components/EventSearchForm';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import { CalendarPosterLandingPage } from '@/react/calendar/containers/event/calendar-posters/CalendarPosterLandingPage';

const { Content } = Layout;

const CalendarPosterTemplatesDrawer = styled(Drawer)`
  &&&& {
    .ant-drawer-header {
      padding-bottom: 0px;
    }

    .ant-drawer-body {
      padding: 0px;
      display: flex;
    }

    .ant-layout {
      .ant-layout-sider {
        padding: 8px 24px;
      }
      .ant-layout-content {
        padding: 8px;
        overflow: auto;
      }
    }
  }
`;

export enum StepsEnum {
  SELECT_TEMPLATE_STEP = 0,
  SELECT_EVENT_STEP = 1,
}

export const CalendarPosterTemplates = NiceModal.create(
  ({ calendarId }: { calendarId: number }) => {
    const modal = useModal('CalendarPosterTemplates');
    const { selectTemplate, templates, templatesLoading } =
      useCalendarPosterTemplate({ calendarId });
    const [selectedTemplate, setSelectedTemplate] = useState<string>(null);
    const [processingSelection, setProcessingSelection] =
      useState<boolean>(false);
    const hasStudio = AuthorizationService.hasPackage('studio');
    const [currentStep, onChangeStep] = useState<StepsEnum>(
      StepsEnum.SELECT_TEMPLATE_STEP
    );
    const [form] = Form.useForm();
    const [selectedCalendarId, setSelectedCalendarId] =
      useState<number>(calendarId);

    const confirmTemplateSelection = async () => {
      setProcessingSelection(true);
      if (selectedTemplate) {
        await selectTemplate({
          templateId: selectedTemplate,
          calendarId: selectedCalendarId,
        }).then(modal.hide);
      }
      setProcessingSelection(false);
    };

    const getValidationText = () => {
      if (!selectedTemplate) {
        return I18nService.getString('Please select a poster template.');
      }
      if (!selectedCalendarId) {
        return I18nService.getString(
          'Please select an event to generate the event poster with.'
        );
      }
    };

    const eventSearchFormValuesChange = (changedFields) => {
      if (changedFields.events) {
        setSelectedCalendarId(changedFields.events.map(({ id }) => id)[0]);
      }
    };

    return (
      <CalendarPosterTemplatesDrawer
        {...antdDrawerV5(modal)}
        title={
          <>
            {I18nService.getString('Create poster')}
            <Row>
              <Col span={24}>
                <Steps
                  size="small"
                  current={currentStep}
                  onChange={onChangeStep}
                  type="navigation"
                >
                  <Steps.Step
                    icon={<CdPoster />}
                    title={
                      hasStudio
                        ? I18nService.getString('Select template')
                        : I18nService.getString(
                            'ChurchDesk Studio - An add-on for designing your message'
                          )
                    }
                  />
                  {!calendarId && (
                    <Steps.Step
                      icon={<CdCalendar />}
                      title={I18nService.getString('Select an event')}
                    />
                  )}
                </Steps>
              </Col>
            </Row>
          </>
        }
        width="75vw"
        footerStyle={{ textAlign: 'right' }}
        footer={
          <Space>
            <Button onClick={modal.hide}>
              {I18nService.getString('Cancel')}
            </Button>
            {hasStudio && (
              <>
                {currentStep === StepsEnum.SELECT_TEMPLATE_STEP &&
                  !calendarId && (
                    <Button
                      disabled={!selectedTemplate || processingSelection}
                      onClick={() => onChangeStep(StepsEnum.SELECT_EVENT_STEP)}
                      type="primary"
                    >
                      {I18nService.getString('Next')}
                    </Button>
                  )}
                {(currentStep === StepsEnum.SELECT_EVENT_STEP ||
                  calendarId) && (
                  <CdTooltip title={getValidationText()}>
                    <Button
                      disabled={
                        !selectedTemplate ||
                        !selectedCalendarId ||
                        processingSelection
                      }
                      onClick={confirmTemplateSelection}
                      loading={processingSelection}
                      type="primary"
                    >
                      {I18nService.getString('Create')}
                    </Button>
                  </CdTooltip>
                )}
              </>
            )}
          </Space>
        }
      >
        {(!hasStudio && (
          <Suspense fallback={<Spin />}>
            <Layout style={{ background: 'white' }}>
              <Content style={{ height: '100%', padding: '30px' }}>
                <CalendarPosterLandingPage />
              </Content>
            </Layout>
          </Suspense>
        )) || (
          <Layout>
            <Content style={{ height: '100%' }}>
              {currentStep === StepsEnum.SELECT_EVENT_STEP && (
                <Suspense fallback={<CdrPageLoader />}>
                  <EventSearchForm
                    rowSelectionType={RowSelectionType.RADIO}
                    form={form}
                    onValuesChange={eventSearchFormValuesChange}
                  />
                </Suspense>
              )}
              {currentStep === StepsEnum.SELECT_TEMPLATE_STEP && (
                <SelectableCards
                  loading={templatesLoading}
                  emptyText={
                    <CdrEmptyState
                      title={I18nService.getString('No posters found')}
                      EmptyStateIcon={<CdPoster />}
                    />
                  }
                  dataSource={templates.map(
                    (template) =>
                      ({
                        ...template,
                        isSelectable: true,
                      }) as ISelectableCardItem
                  )}
                  onSelect={setSelectedTemplate}
                  value={selectedTemplate}
                />
              )}
            </Content>
          </Layout>
        )}
      </CalendarPosterTemplatesDrawer>
    );
  }
);
