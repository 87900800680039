import { useMutation, useQueryClient } from '@tanstack/react-query';

import { TagInterface } from '../types/tag.types';
import { handleSuccessMessage } from '../utils';

import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';
import { I18nService } from '@/react/services/I18nService';
import cdApp from '@/react/config';
import { navigate } from '@/react/services/StateServiceFactory';

export const useCreateOrEditTags = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: async ({
      name,
      data,
      isEdit,
      setNewTag,
    }: {
      name: string;
      data?: TagInterface;
      isEdit?: boolean;
      setNewTag?: (number) => void;
    }) => {
      if (isEdit) {
        const res = await mainApi.put<TagInterface>(`/people/tags/${data.id}`, {
          ...data,
          name: name,
        });
        if (!res.ok) {
          handleError(res);
          return undefined;
        } else {
          handleSuccessMessage(
            I18nService.getString('Tag updated successfully.')
          );
        }
      } else {
        const res = await mainApi.post<TagInterface>(`/people/tags`, {
          name: name,
          isTag: true,
        });
        if (!res.ok) {
          handleError(res);
          return undefined;
        } else {
          if (setNewTag) {
            setNewTag(res.data?.id);
          }
          handleSuccessMessage(
            I18nService.getString('Tag created successfully.')
          );
        }
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['tags'],
      });
    },
  });
  return {
    createOrEditTag: mutate,
  };
};

export const useDeleteTag = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: async (tagId: number) => {
      const res = await mainApi.delete(`/people/tags/${tagId}`);
      if (!res.ok) {
        handleError(res);
        return undefined;
      } else {
        handleSuccessMessage(
          I18nService.getString('Tag deleted successfully.')
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['tags'],
      });
    },
  });
  return {
    deleteTag: mutate,
    isPending,
  };
};

export const useCreateTagFilter = () => {
  const { mutate } = useMutation({
    mutationFn: async ({
      filterName,
      data,
    }: { filterName: string; data: TagInterface }) => {
      const res = await mainApi.post(`/people/segments`, {
        name: filterName,
        setup: {
          comparison: 'AND',
          churchIds: cdApp.organization.churches.map((church) => church.id),
          filters: [
            {
              type: 'list',
              property: 'tags',
              operator: 'eq',
              value: data.id,
            },
          ],
        },
      });

      if (!res.ok) {
        handleError(res);
        return undefined;
      } else {
        handleSuccessMessage(
          I18nService.getString('Filter created successfully.')
        );
      }

      return res.data;
    },
    onSuccess: (data: { id: number }) => {
      navigate(`app.private.people.contacts.list`, { list: data.id });
    },
  });
  return {
    createTagFilter: mutate,
  };
};
