import { Col, Radio, Typography, Space } from 'antd';
import React, { useCallback } from 'react';

import { MINIMUM_COLUMN_WIDTH } from '../../calendar/event-details/constants';
import { I18nService } from '../../services/I18nService';

import { WidgetType } from './types/widget.type';

const RadioItem = (props: { label: string; text: string }) => (
  <Space
    size={0}
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: '16px',
    }}
  >
    <Typography.Text style={{ fontSize: '16px', marginBottom: 0 }}>
      {props.label}
    </Typography.Text>
    <Typography.Text style={{ fontSize: '14px' }}>{props.text}</Typography.Text>
  </Space>
);

export const WidgetTypeSelection = ({
  onValueChange,
}: {
  onValueChange: (string) => void;
}) => {
  const onChange = useCallback(
    (e) => {
      onValueChange(e?.target?.value);
    },
    [onValueChange]
  );
  return (
    <Col flex={`${MINIMUM_COLUMN_WIDTH + 16}px`}>
      <Typography.Title level={3}>
        {I18nService.getString('Select widget type')}
      </Typography.Title>

      <Radio.Group
        style={{ display: 'flex', flexDirection: 'column' }}
        onChange={onChange}
      >
        <Radio value={WidgetType.EVENT}>
          <RadioItem
            label={I18nService.getString('Event widget')}
            text={I18nService.getString(
              'List of events automatically updated from the calendar'
            )}
          />
        </Radio>
        <Radio value={WidgetType.BLOG}>
          <RadioItem
            label={I18nService.getString('Blog widget')}
            text={I18nService.getString(
              'List of blogposts automatically updated from your posting'
            )}
          />
        </Radio>
      </Radio.Group>
    </Col>
  );
};
