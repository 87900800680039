/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PutIntentionIntentionsRequestBody = {
    intentionText?: string;
    intentionYear?: number;
    church?: {
        id: number;
    };
    resource?: {
        id: number;
    } | null;
    person?: {
        id: number;
    } | null;
    founder?: string | null;
    fee?: {
        id: string;
    };
    acceptedAt?: string | null;
    nextReferenceNumber?: string | null;
    paymentMethod?: PutIntentionIntentionsRequestBody.paymentMethod;
    paid?: boolean | null;
    paidAt?: string | null;
    status?: PutIntentionIntentionsRequestBody.status;
    preferredDate?: string | null;
    preferredNote?: string | null;
    priority?: PutIntentionIntentionsRequestBody.priority;
    comment?: string | null;
};
export namespace PutIntentionIntentionsRequestBody {
    export enum paymentMethod {
        CASH = 'cash',
        BANK = 'bank',
    }
    export enum status {
        UNASSIGNED = 'unassigned',
        ASSIGNED = 'assigned',
        COMPLETED = 'completed',
    }
    export enum priority {
        PERSOLVIERT = 'persolviert',
        WEITERLEITUNG = 'weiterleitung',
    }
}

