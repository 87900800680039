import { keepPreviousData } from '@tanstack/react-query';

import { ApiSearchParams, ApiSearchResult, mainApi } from '../../../api';
import {
  Enquiry,
  SingleEnquiry,
} from '../../../calendar/availabilities/types/enquiry';
import { handleError } from '../../../services/ErrorHandlingService';
import { CdQueryDefinition } from '../types';

export const calendarBookingQueries = {
  getAll: () => ({
    queryKey: ['enquiries'],
  }),
  getById: (id: string) => ({
    queryKey: ['enquiries', id],
    queryFn: async () => {
      const response = await mainApi.get<SingleEnquiry>(
        `/calendar/booking/requests/${id}`
      );

      if (!response.ok) {
        handleError(response);
      } else {
        return response.data;
      }
    },
  }),
  getByParams: (params: ApiSearchParams) => ({
    queryKey: ['enquiries', params, params?.extraData],
    queryFn: async () => {
      const response = await mainApi.get<ApiSearchResult<Enquiry>>(
        '/calendar/booking/requests',
        {
          limit: params.limit,
          page: params.pageNumber,
          ...params?.extraData,
        }
      );

      if (!response.ok) {
        handleError(response);
        return { items: [], total: 0 };
      }

      return response.data;
    },
    placeholderData: keepPreviousData,
  }),
} satisfies CdQueryDefinition;
