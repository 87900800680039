import { Divider, Input } from 'antd';
import React from 'react';
import { useDebounceCallback } from 'usehooks-ts';

import { useCalendarSearchContext } from '@/react/calendar/components/sidebar-search/CalendarSearchContext';
import { I18nService } from '@/react/services/I18nService';

export const CalendarSearch = () => {
  const { setLowerCaseSearchName } = useCalendarSearchContext();
  const debouncedSearch = useDebounceCallback(
    (value: string) => setLowerCaseSearchName(value.toLocaleLowerCase()),
    300
  );

  return (
    <div>
      <Divider style={{ margin: 0 }} />

      <Input.Search
        placeholder={I18nService.getString('Calendar Search')}
        onSearch={debouncedSearch}
        onChange={(e) => debouncedSearch(e.target.value)}
        width="100%"
        style={{ padding: 8 }}
        allowClear
      />
    </div>
  );
};
