/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostV2OrganizationsRequestBody = {
    countryIso2?: string;
    language?: string;
    timezone: string;
    name: string;
    siteStatus?: PostV2OrganizationsRequestBody.siteStatus;
    dioceseId?: string;
    planId?: number;
    churchesToAdd?: Array<string>;
    churchDeskVAT?: PostV2OrganizationsRequestBody.churchDeskVAT;
};
export namespace PostV2OrganizationsRequestBody {
    export enum siteStatus {
        TRIAL = 'trial',
        CUSTOMER = 'customer',
        DEMO = 'demo',
        DEVELOPER = 'developer',
    }
    export enum churchDeskVAT {
        DE304758017 = 'DE304758017',
        DK32150489 = 'DK32150489',
    }
}

