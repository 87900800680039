import React from 'react';
import { Button } from 'antd';

import { I18nService } from '@/react/services/I18nService';
import { CdOpenInNewWindow } from '@/react/shared/components/Icons';
import { getStateUrl } from '@/react/services/StateServiceFactory';

const EditEventForm = (props: { formId: string; canEdit: boolean }) => {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <Button
      disabled={!props.formId || !props.canEdit}
      icon={<CdOpenInNewWindow />}
      target="_blank"
      onClick={() =>
        openInNewTab(
          getStateUrl('app.private.forms.edit', {
            id: props.formId?.toString(),
            step: 'builder',
          })
        )
      }
    >
      {I18nService.getString('Edit form')}
    </Button>
  );
};

export default EditEventForm;
