import {
  MessageType,
  PeopleMessageContent,
} from '@/react/people/types/message';
import { Organization } from '@/react/shared/models/organization';

export interface CalendarPoster {
  id: number;
  type: MessageType;
  rendered: string;
  createdAt: Date;
  content: PeopleMessageContent;
  organizationId: number;
  organization: Organization;
  author: {
    contactId: number;
    email: string;
    firstName: string;
    fullName: string;
    id: number;
    lastName: string;
  };
  exportToken: string;
  title?: string;
}
export interface SogndkCategories {
  sognDkCategoryId: number;
  sognDkCategoryName: string;
  sognDkCategoryType: string;
}
export interface SogndkParish {
  id: number;
  name: string;
}
export interface SogndkChurches {
  sognDkChurchId: number;
  sognDkChurchName: string;
  sogneName: string;
}
export interface SogndkPriest {
  sognDkPastorId: number;
  sognDkPastorName: string;
  sogneName: string;
}
export enum CalendarPosterExportFormat {
  PDF = 'pdf',
  JPG = 'jpg',
}

export enum CalendarPosterPDFExportSize {
  A3 = 'a3',
  A4 = 'a4',
}

export enum CalendarPosterImageExportSize {
  FULL_SIZE = 'fullsize',
  SOCIAL_MEDIA = 'socialmedia',
}
export enum CalendarEventTabs {
  DETAILS_PAGE = '1',
  ROTA_AND_INTENTIONS = '2',
  SIGN_UPS = 'sign_ups',
  ATTENDANCE = '4',
  POSTER = '5',
  INVITE = '6',
  COMMENTS = '7',
  VERSION_HISTORY = '8',
}
export interface CalendarInvitation {
  id: number;
  type?: MessageType;
  rendered?: string;
  createdAt: Date;
  content?: PeopleMessageContent;
  organizationId?: number;
  organization?: Organization;
  author: {
    contactId?: number;
    email?: string;
    firstName?: string;
    fullName?: string;
    id: number;
    lastName?: string;
  };
}

export interface EventRegistration {
  personId: number;
  submissionId: string;
  email: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  ticketsTotal: number;
  isContactCreated: boolean;
  canDelete: boolean;
  hasPayment: boolean;
}

export interface EventForm {
  id?: string;
  title?: string;
  description?: string;
  link?: string;
  embedCode?: string;
  configuration?: EventFormConfiguration;
}

export interface ApiEventRegistrationResult {
  items: EventRegistration[];
  total: number;
  hasMoreThanOnePeopleSection: boolean;
  hasTickets: boolean;
}

export interface EventFormConfiguration {
  startDate?: Date;
  endDate?: Date;
  paymentMethod: PaymentMethodType;
  numberOfTickets: number;
  maxTicketsPerOrder: number;
  pricePerTickets: number;
  isPhoneNumberRequired?: boolean;
  askForAddress?: boolean;
  consents: string[];
}

export enum PaymentMethodType {
  Online = 'online',
  Cash = 'cash',
}
export interface CalendarStudio {
  allDay: boolean;
  endDate: string;
  id: number;
  invitations: Array<CalendarInvitation>;
  posters: Array<CalendarPoster>;
  startDate: string;
  studioItemsLastUpdatedAt: string;
  title: string;
}

export enum ClientEventVisibility {
  INTERNAL_GROUPS = 'internal-group',
  INTERNAL_ALL = 'internal-all',
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export enum BackendEventVisibility {
  INTERNAL = 'internal',
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export interface EventPostPayload {
  startDate: string;
  endDate: string;
  allDay?: boolean;
  rrule?: string;
  type: string;
  visibility?: string;
  title?: string;
  resources?: number[];
  mainCategory: number;
  groupIds?: number[];
  locationName?: string;
  locationObj?: {
    address: string;
    address2?: string;
    city: string;
    country: string;
    state?: string;
    zipcode: string;
    custom_data?: { resourceId: number };
  };
  users: number[];
  churchIds?: number[];
  timezone?: string;
  clientVersion?: number;
  hideEndTime?: boolean;
  allowDoubleBooking?: boolean;
  bookingOptionIds?: string[];
}
