import {
  Button,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Space,
  Switch,
  Typography,
} from 'antd';
import React from 'react';

import { validationRule } from '../../../../shared/antd/validations/validation-rule';
import { CdUserSelect } from '../../../../groups/components/CdUserSelect';
import { CdRadioGroup } from '../../../../shared/components/cd-radio-group/CdRadioGroup';
import { WeeklySchedule } from '../WeeklySchedule';
import { useOptionFormData } from '../../hooks/useOptionFormData';
import { useSuspensedFlag } from '../../../../shared/hooks/useFlag';
import { AvailabilityTaxonomyFormData } from '../../types/availability-taxonomy.types';
import { trimResourcePrefix } from '../../utils/utils';

import { I18nService } from '@/react/services/I18nService';
import { DurationPicker } from '@/react/settings/booking-pages/components/DurationPicker';
import {
  hasAtLeastOneTimeInterval,
  hasOverlappingTime,
} from '@/react/settings/booking-pages/utils/validation';
import { required } from '@/react/shared/antd/validations/common';
import { CdLocationSelect } from '@/react/shared/components/cd-location-select/CdLocationSelect';
import { CdSelect } from '@/react/shared/components/cd-select/CdSelect';
import { CdVerticalSpace } from '@/react/shared/components/cd-vertical-space/CdVerticalSpace';

type OptionSettingsFormProps = {
  form: FormInstance<AvailabilityTaxonomyFormData>;
  index: number;
  onDeleteBookingOption: (index: number) => void;
};

export const OptionForm = ({
  form,
  index,
  onDeleteBookingOption,
}: OptionSettingsFormProps) => {
  const {
    allowWeeklySchedule,
    availabilityType,
    limitOnEnquiriesIsActive,
    bookingMode,
    groupMemberIds,
    resourceId,
    additionalResourceIds,
  } = useOptionFormData(form, index);
  const bookingEnquiriesV2Enabled = useSuspensedFlag('booking_enquiries_v2');

  return (
    <>
      <Typography.Title level={3}>
        {I18nService.getString('Booking option')}
      </Typography.Title>
      <Form.Item
        name={['bookingOptions', index, 'name']}
        label={I18nService.getString('Option name')}
        rules={[required()]}
      >
        <Input placeholder={I18nService.getString('Option name...')} />
      </Form.Item>
      <Form.Item
        name={['bookingOptions', index, 'description']}
        label={I18nService.getString('Description')}
      >
        <Input.TextArea
          rows={4}
          placeholder={I18nService.getString('Add a description...')}
        />
      </Form.Item>
      {bookingEnquiriesV2Enabled &&
        availabilityType === 'general-availability' && (
          <Form.Item
            name={['bookingOptions', index, 'bookingMode']}
            label={I18nService.getString(
              'This option will book...',
              null,
              'What can be booked on the booking page'
            )}
          >
            <CdRadioGroup
              options={[
                {
                  value: 'usersAndResources',
                  label: I18nService.getString(
                    'Users and resources',
                    null,
                    'What can be booked on the booking page'
                  ),
                },
                {
                  value: 'onlyResources',
                  label: I18nService.getString(
                    'Only resources',
                    null,
                    'What can be booked on the booking page'
                  ),
                },
                {
                  value: 'onlyUsers',
                  label: I18nService.getString(
                    'Only users',
                    null,
                    'What can be booked on the booking page'
                  ),
                },
              ]}
            />
          </Form.Item>
        )}
      {availabilityType === 'general-availability' && (
        <Form.Item
          name={['bookingOptions', index, 'userIds']}
          label={I18nService.getString('Users')}
          extra={
            bookingMode === 'onlyResources'
              ? I18nService.getString(
                  'These users are responsible for approving or declining the incoming enquiries.'
                )
              : I18nService.getString(
                  'These users can be booked for this option. All of them receive the enquiry, but only one needs to approve or decline.'
                )
          }
          rules={[required()]}
        >
          <CdUserSelect
            includeIds={groupMemberIds}
            placeholder={I18nService.getString('Choose one or more users...')}
            mode="multiple"
            showAvatar={false}
          />
        </Form.Item>
      )}
      {bookingEnquiriesV2Enabled &&
        ((bookingMode !== 'onlyResources' &&
          availabilityType === 'general-availability') ||
          availabilityType === 'specific-availability') && (
          <Form.Item
            name={['bookingOptions', index, 'showUsers']}
            label={I18nService.getString('Show users on booking page')}
            valuePropName="checked"
          >
            <Switch defaultChecked />
          </Form.Item>
        )}
      {((bookingMode !== 'onlyUsers' &&
        availabilityType === 'general-availability') ||
        availabilityType === 'specific-availability') && (
        <>
          <Form.Item
            name={['bookingOptions', index, 'resourceId']}
            label={I18nService.getString('Main resource')}
            extra={I18nService.getString(
              'Will be used as location for the event'
            )}
            rules={[required()]}
          >
            <CdLocationSelect
              omitIds={(additionalResourceIds || []).map(trimResourcePrefix)}
              checkable={false}
              hideNoParishBookedOption={true}
            />
          </Form.Item>
          <Form.Item
            name={['bookingOptions', index, 'additionalResourceIds']}
            label={I18nService.getString('Book additional resources')}
          >
            <CdLocationSelect
              omitIds={[trimResourcePrefix(resourceId)]}
              hideNoParishBookedOption={true}
              placeholder={I18nService.getString(
                'Book additional resources...'
              )}
            />
          </Form.Item>
        </>
      )}
      {availabilityType === 'specific-availability' && (
        <Form.Item
          name={['bookingOptions', index, 'allowWeeklySchedule']}
          label={I18nService.getString('Create weekly booking schedule')}
          extra={I18nService.getString(
            'Define allowed booking enquiry time schedule. Example: Cemetery services are only possible between 9AM and 11AM. When not setting the schedule it means there is no limit on availability.'
          )}
          valuePropName="checked"
        >
          <Switch defaultChecked />
        </Form.Item>
      )}
      {(allowWeeklySchedule || availabilityType === 'general-availability') && (
        <>
          <Typography.Title level={3}>
            {I18nService.getString('Weekly schedule')}
          </Typography.Title>
          <Typography.Text type="secondary">
            {I18nService.getString(
              'Define times for which enquiries can be submitted.'
            )}
          </Typography.Text>
          <Form.Item
            name={['bookingOptions', index, 'bookingHours']}
            // We set validateStatus to "success" to prevent all the input fields becoming red on validation error
            validateStatus="success"
            rules={[hasOverlappingTime, hasAtLeastOneTimeInterval]}
            style={{ marginTop: 16 }}
          >
            <WeeklySchedule />
          </Form.Item>
        </>
      )}
      <Typography.Title level={3}>
        {I18nService.getString(
          'Request timing',
          null,
          'e.g. Event duration or minimum notice period for a booking enquiry'
        )}
      </Typography.Title>
      <Form.Item
        name={['bookingOptions', index, 'duration']}
        label={I18nService.getString('Event duration')}
        rules={[
          validationRule((value: { minutes: number; hours: number }) => {
            if (value?.hours * 60 + value?.minutes < 10) {
              throw new Error(
                I18nService.getString(
                  'The event must be atleast {{ minutes }} minutes long',
                  { minutes: 10 }
                )
              );
            }
          }),
        ]}
      >
        <DurationPicker />
      </Form.Item>
      <Form.Item
        name={['bookingOptions', index, 'minimumNoticePeriod']}
        label={I18nService.getString('Minimum notice period')}
        extra={I18nService.getString(
          'Users will only see times after the specified period (e.g., 24 hours from now).'
        )}
      >
        <DurationPicker showDays />
      </Form.Item>

      <Form.Item
        name={['bookingOptions', index, 'startTimeIncrement']}
        label={I18nService.getString('Start time increment')}
      >
        <Input
          min={10}
          max={1440}
          addonAfter={I18nService.getString(
            'min',
            null,
            'Short Name of unit (Minutes). Shown at the end of an input field'
          )}
          style={{ width: 100 }}
        />
      </Form.Item>
      {bookingEnquiriesV2Enabled && (
        <Form.Item>
          <CdVerticalSpace>
            <Space>
              <Typography.Text strong>
                {I18nService.getString('Limit number of enquiries')}
              </Typography.Text>
              <Form.Item
                name={['bookingOptions', index, 'limitOnEnquiriesIsActive']}
                noStyle
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Space>
            {limitOnEnquiriesIsActive && (
              <Space>
                <Form.Item
                  name={['bookingOptions', index, 'enquiryLimit', 'value']}
                  noStyle
                >
                  <InputNumber min={0} precision={0} style={{ width: 60 }} />
                </Form.Item>
                {I18nService.getString(
                  'per',
                  null,
                  'Used in format "[Number] per [Day/Week]"'
                )}
                <Form.Item
                  name={['bookingOptions', index, 'enquiryLimit', 'unit']}
                  noStyle
                >
                  <CdSelect
                    style={{ width: 110 }}
                    allowClear={false}
                    options={[
                      { value: 'day', label: I18nService.getString('day') },
                      { value: 'week', label: I18nService.getString('week') },
                    ]}
                  />
                </Form.Item>
              </Space>
            )}
          </CdVerticalSpace>
        </Form.Item>
      )}
      <Typography.Title level={3}>
        {I18nService.getString('Preparation / clean up time')}
      </Typography.Title>
      <Typography.Paragraph type="secondary">
        {I18nService.getString(
          'Automatically blocks additional time around each enquiry to prevent back-to-back scheduling.'
        )}
      </Typography.Paragraph>
      <Form.Item
        name={['bookingOptions', index, 'bufferBeforeEvent']}
        label={I18nService.getString('Preparation time')}
      >
        <DurationPicker />
      </Form.Item>
      <Form.Item
        name={['bookingOptions', index, 'bufferAfterEvent']}
        label={I18nService.getString('Clean up time')}
      >
        <DurationPicker />
      </Form.Item>
      <Divider />
      <Row justify="center">
        <Button danger type="text" onClick={() => onDeleteBookingOption(index)}>
          {I18nService.getString('Delete option')}
        </Button>
      </Row>
    </>
  );
};
