import { faCalendarTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EventApi } from '@fullcalendar/react';
import { List } from 'antd';
import moment from 'moment';
import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ABSENCE_COLOR } from '../../../app/ResourceColors';
import { getCurrentLanguage } from '../../config/store/Selector';
import { I18nService } from '../../services/I18nService';
import {
  useDispatchToggleIsMinimizedView,
  useLoadMultipleAbsencesDispatcher,
} from '../store/absences/absencesSlice';

import { AbsenceListItem } from './AbsenceListItem';
import { PopoverActionButtons } from './PopoverActionButtons';
import { PopoverContentCard } from './PopoverContentCard';

import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';
import { CdClose, CdUngroupAbsences } from '@/react/shared/components/Icons';

const PopoverTitle = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  font-size: 18px;
  line-height: 1.4;
`;

export const GroupedAbsencesPopoverContent: FunctionComponent<{
  stopPropagation: (e: any) => void;
  event: EventApi;
  absencesIds: number[];
  closeToolTip: (e: any) => void;
}> = (props) => {
  const { stopPropagation, event, absencesIds, closeToolTip } = props;
  const toggleIsMinimizedView = useDispatchToggleIsMinimizedView();
  const language = useSelector(getCurrentLanguage);
  const loadAbsences = useLoadMultipleAbsencesDispatcher();

  useEffect(() => {
    loadAbsences(absencesIds);
  }, [absencesIds, loadAbsences]);
  const title = (
    <div>
      <PopoverActionButtons>
        <CdTooltip
          title={I18nService.getString('Show all absences individually')}
        >
          <a
            className="event-popup__button-expand text-md"
            onClick={toggleIsMinimizedView}
          >
            <CdUngroupAbsences style={{ margin: 5 }} />
            {I18nService.getString('Ungroup absences')}
          </a>
        </CdTooltip>
        <CdTooltip title={I18nService.getString('Close')}>
          <a
            className="event-popup__action-button u-ml-10"
            onClick={closeToolTip}
          >
            <CdClose />
          </a>
        </CdTooltip>
      </PopoverActionButtons>
      <PopoverTitle>
        <FontAwesomeIcon icon={faCalendarTimes} />
        {'\u00A0'}
        {`${event.title}`}
      </PopoverTitle>
      <div style={{ fontSize: 14 }}>
        {moment(event.start).startOf('day').locale(language).format('dddd, LL')}
      </div>
    </div>
  );
  return (
    <PopoverContentCard
      $isPublic={false}
      title={title}
      bordered={false}
      onClick={stopPropagation}
      headStyle={{
        backgroundColor: ABSENCE_COLOR,
      }}
    >
      <div style={{ padding: '0 16px' }}>
        <List
          itemLayout="horizontal"
          dataSource={absencesIds}
          renderItem={(id: number) => <AbsenceListItem id={id} />}
        />
      </div>
    </PopoverContentCard>
  );
};
