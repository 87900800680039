/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostPeopleExportDataRequestBody = {
    people?: Array<number>;
    filter?: {
        churchIds?: Array<number>;
        comparison?: PostPeopleExportDataRequestBody.comparison;
        filters?: Array<any>;
    };
    fileType?: PostPeopleExportDataRequestBody.fileType;
    fields?: Array<string>;
};
export namespace PostPeopleExportDataRequestBody {
    export enum comparison {
        AND = 'AND',
        OR = 'OR',
    }
    export enum fileType {
        XLSX = 'xlsx',
        ODS = 'ods',
        CSV = 'csv',
    }
}

