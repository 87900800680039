import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { openCopyShareableLinkModal } from '@/react/calendar/rota-scheme/CopyShareableLinkModal';
import { openEditSettingsModal } from '@/react/calendar/rota-scheme/SchemeSettingsModal';
import { RotaScheme } from '@/react/calendar/rota-scheme/types/rotaScheme.types';
import { I18nService } from '@/react/services/I18nService';
import NotificationService from '@/react/services/NotificationService';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import { backendApiClient } from '@/react/api';
import { rotaShiftQueries } from '@/react/shared/queries/rotas/shifts';

export const useRotaLinkActions = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: deleteRota } = useMutation({
    mutationFn: (id: string) =>
      backendApiClient.rotas.deleteShiftsRotasLink({ id }),
  });
  const copyShareableLink = useCallback(
    async (rotaScheme: RotaScheme) =>
      openCopyShareableLinkModal({
        title: I18nService.getString('Share your rota'),
        url: `${window.cdApp.references.appUrl}/rota/${rotaScheme.id}`,
      }),
    []
  );

  const editSettings = useCallback(
    async (rotaScheme: RotaScheme) => {
      await openEditSettingsModal({
        rotaScheme,
      });
      await queryClient.invalidateQueries(rotaShiftQueries.getAllSchemes());
    },
    [queryClient]
  );

  const deleteScheme = useCallback(
    async (rotaScheme: RotaScheme) => {
      await showConfirmModal({
        title: I18nService.getString('Delete rota scheme'),
        message: I18nService.getString(
          'Do you want to delete the rota scheme?'
        ),
        okText: I18nService.getString('Delete'),
        showIcon: false,
        okButtonProps: {
          danger: true,
        },
        onOk: async () => {
          await deleteRota(rotaScheme.id, {
            onSuccess: async () => {
              try {
                NotificationService.notifySuccess(
                  I18nService.getString('Rota scheme deleted successfully')
                );
              } catch (e) {}
              return queryClient.invalidateQueries(
                rotaShiftQueries.getAllSchemes()
              );
            },
          });
        },
      });
    },
    [deleteRota, queryClient]
  );

  return {
    copyShareableLink,
    editSettings,
    deleteScheme,
  };
};
