/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CloneWorkplanRequestBody } from '../models/CloneWorkplanRequestBody';
import type { CreatehWorkplanRequestBody } from '../models/CreatehWorkplanRequestBody';
import type { EmptyObject } from '../models/EmptyObject';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class WorkplanService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * CreatehWorkplan
     * Create
     * @returns EmptyObject
     * @throws ApiError
     */
    public postWorkplan({
        requestBody,
    }: {
        requestBody?: CreatehWorkplanRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/workplan',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * FetchCalendarWorkplan
     * @returns EmptyObject
     * @throws ApiError
     */
    public getWorkplanCalendar(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/workplan/calendar/{year}/{month}/{day}',
        });
    }
    /**
     * CloneWorkplan
     * Clone
     * @returns EmptyObject
     * @throws ApiError
     */
    public postWorkplanClone({
        requestBody,
    }: {
        requestBody?: CloneWorkplanRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/workplan/clone',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * LockWorkPlan
     * Lock workplan
     * @returns EmptyObject
     * @throws ApiError
     */
    public postWorkplanLock({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/workplan/lock',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * WorkplanOrverview
     * Overview
     * @returns EmptyObject
     * @throws ApiError
     */
    public getWorkplanOverview(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/workplan/overview',
        });
    }
    /**
     * FetchWorkplanTypes
     * Fetch types
     * @returns EmptyObject
     * @throws ApiError
     */
    public getWorkplanTypes(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/workplan/types',
        });
    }
    /**
     * FetchWorkplan
     * Fetch user workplan
     * @returns EmptyObject
     * @throws ApiError
     */
    public getWorkplan({
        userId,
        year,
        month,
    }: {
        /**
         * - The id of the user workplan information needs to be fetched.
         */
        userId: any,
        /**
         * - The year from which data needs to be fetched.
         */
        year: any,
        /**
         * - The month of the year to get data from.
         */
        month: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/workplan/{userId}/{month}/{year}',
            query: {
                'userId': userId,
                'year': year,
                'month': month,
            },
        });
    }
}
