/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostPeopleMessagesCalculateRecipientsRequestBody = {
    type: PostPeopleMessagesCalculateRecipientsRequestBody.type;
    segments?: Array<{
        name?: string;
        setup: any;
        type?: 'church' | 'filter' | 'newsletter' | 'participant';
    }>;
    personIds?: Array<number>;
};
export namespace PostPeopleMessagesCalculateRecipientsRequestBody {
    export enum type {
        EMAIL = 'email',
        SMS = 'sms',
        BASIC = 'basic',
        SIMPLE = 'simple',
        ADVANCED = 'advanced',
        CALENDAR_POSTER = 'calendar-poster',
        EVENT_INVITATION = 'event-invitation',
    }
}

