/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LinkitService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * IndexLinkit
     * Index
     * @returns EmptyObject
     * @throws ApiError
     */
    public getLinkit({
        search,
    }: {
        search: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/linkit',
            query: {
                'search': search,
            },
        });
    }
    /**
     * PagesLinkit
     * Pages
     * @returns EmptyObject
     * @throws ApiError
     */
    public getLinkitPages({
        search,
    }: {
        search: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/linkit/pages',
            query: {
                'search': search,
            },
        });
    }
}
