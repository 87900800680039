/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class NotificationsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetNotifications
     * Index
     * @returns EmptyObject
     * @throws ApiError
     */
    public getNotifications({
        entityType,
        entityId,
    }: {
        /**
         * The name of the entity.
         */
        entityType: any,
        /**
         * The id of the entity.
         */
        entityId: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/notifications',
            query: {
                'entityType': entityType,
                'entityId': entityId,
            },
        });
    }
    /**
     * PostNotifications
     * Resend
     * @returns EmptyObject
     * @throws ApiError
     */
    public postNotifications({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/notifications',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * PostNotificationsEmailWebhook
     * Email-Webhook
     * @returns EmptyObject
     * @throws ApiError
     */
    public postNotificationsEmailWebhook({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/notifications/email-webhook',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetNotificationsSmsWebhookId
     * SMS-Webhook
     * @returns EmptyObject
     * @throws ApiError
     */
    public getNotificationsSmsWebhook({
        status,
        id,
    }: {
        /**
         * The CPSMS status code
         */
        status: any,
        /**
         * The id of the notification log Id.
         */
        id: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/notifications/sms-webhook/{id}',
            query: {
                'status': status,
                'id': id,
            },
        });
    }
}
