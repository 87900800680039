import { Button, Card, Col, Form, Input, Row, Space } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { I18nService } from '../../../services/I18nService';
import { CdSelect } from '../../../shared/components/cd-select/CdSelect';
import { EventType } from '../../models/calendar';
import { CdClose } from '../../../shared/components/Icons';
import { isInArray, required } from '../../../shared/antd/validations/common';
import { CdFormSubmitButton } from '../../../shared/components/cd-form-submit-button/CdFormSubmitButton';
import cdApp from '../../../config';
import { CdEventCategorySelect } from '../../event-details/components/CdEventCategorySelect';
import { CdLocationSelect } from '../../../shared/components/cd-location-select/CdLocationSelect';
import { CreateEventData } from '../../services/EventPopupService';
import { CdChurchParishSelect } from '../../../shared/components/selects/cd-church-parish-select/CdChurchParishSelect';
import { BookingOptionsSelect } from '../../availabilities/components/BookingOptionsSelect';
import { DateIntervalRender } from '../../availabilities/components/DateIntervalRender';

import { useCreateEventPopup } from './use-create-event-popup';

import { useFlag } from '@/react/shared/hooks/useFlag';

export interface CreateEventPopupProps {
  event: CreateEventData;
  closePopover: () => void;
}

export const CreateEventPopover = (props: CreateEventPopupProps) => {
  const {
    form,
    type,
    options,
    selectedGroup,
    currentValues,
    onCreate,
    onMoreOptions,
    changeType,
    initialValues,
    showResourceSelector,
  } = useCreateEventPopup(props);
  const funeralCalendarV2Enabled = useFlag('booking_enquiries_v2');

  const userItem = (
    <StyledFormItem
      name="users"
      label={I18nService.getString('User')}
      dependencies={['groupId']}
      rules={[
        required(),
        isInArray(
          selectedGroup?.members,
          I18nService.getString('This user is not part of the group')
        ),
      ]}
    >
      <CdSelect
        options={options.users}
        placeholder={I18nService.getString('Choose a user')}
        allowClear={false}
        autoFocus={type === EventType.Absence}
      />
    </StyledFormItem>
  );

  return (
    <Form
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      colon={false}
      initialValues={initialValues}
    >
      <StyledCard
        bordered={false}
        title={
          <Row justify="space-between">
            <Col>
              <Form.Item name="type" noStyle>
                <Space>
                  <StyledTypeSelectionButton
                    onClick={() => changeType(EventType.Event)}
                    ghost={type !== EventType.Event}
                  >
                    {I18nService.getString('Event')}
                  </StyledTypeSelectionButton>
                  <StyledTypeSelectionButton
                    onClick={() => changeType(EventType.Absence)}
                    ghost={type !== EventType.Absence}
                  >
                    {I18nService.getString('Absence')}
                  </StyledTypeSelectionButton>
                  <StyledTypeSelectionButton
                    onClick={() => changeType(EventType.Availability)}
                    ghost={type !== EventType.Availability}
                  >
                    {I18nService.getString('Availability')}
                  </StyledTypeSelectionButton>
                </Space>
              </Form.Item>
            </Col>
            <Col>
              <IconButton
                type="text"
                shape="circle"
                icon={<CdClose type="white" />}
                color="white"
                onClick={props.closePopover}
              />
            </Col>
          </Row>
        }
      >
        <Row justify="end" style={{ marginBottom: 16 }}>
          <Col span={18}>
            <DateIntervalRender
              startDate={props.event.startDate}
              endDate={props.event.endDate}
              allDay={props.event.allDay}
            />
          </Col>
        </Row>
        {type === EventType.Event && (
          <StyledFormItem
            name="title"
            label={I18nService.getString('Title')}
            rules={[required()]}
          >
            <Input
              placeholder={I18nService.getString('Enter a title.')}
              autoFocus
            />
          </StyledFormItem>
        )}
        {type === EventType.Absence && userItem}
        <StyledFormItem
          // Key required to avoid weird dropdown scrolling behavior
          // See https://app.shortcut.com/churchdesk/story/33189/use-react-selects-in-quick-calendar-entry-create
          key={`${type}-category`}
          name="mainCategory"
          label={
            type === EventType.Availability
              ? I18nService.getString('Type')
              : I18nService.getString('Category')
          }
          rules={[required()]}
        >
          <CdEventCategorySelect
            placeholder={I18nService.getString('Choose a category')}
            type={type}
            autoFocus={type === EventType.Availability}
            usage="calendar"
          />
        </StyledFormItem>
        {type === EventType.Event && showResourceSelector && (
          <StyledFormItem
            name="resources"
            label={I18nService.getString('Resource')}
          >
            <CdLocationSelect
              placeholder={I18nService.getString(
                'Select resources for the event'
              )}
              hideNoParishBookedOption
              showSearch={true}
              maxTagCount="responsive"
            />
          </StyledFormItem>
        )}
        {type === EventType.Event &&
          cdApp.showChurchSelector &&
          currentValues?.visibility !== 'private' && (
            <StyledFormItem
              name="churchIds"
              label={I18nService.getString('Parish')}
              rules={[required()]}
            >
              <CdChurchParishSelect />
            </StyledFormItem>
          )}
        {type === EventType.Event && (
          <StyledFormItem
            name="visibility"
            label={I18nService.getString('Visibility')}
          >
            <CdSelect
              placeholder={I18nService.getString('Choose a visibility')}
              options={options.visibility}
              allowClear={false}
            />
          </StyledFormItem>
        )}
        {(type === EventType.Absence ||
          currentValues?.visibility === 'internal-group' ||
          currentValues?.visibility === 'public') && (
          <StyledFormItem
            name={type === EventType.Absence ? 'groupId' : 'groupIds'}
            label={I18nService.getString('Group')}
          >
            <CdSelect
              options={options.groups}
              mode={type === EventType.Absence ? null : 'multiple'}
              maxTagCount="responsive"
              placeholder={I18nService.getString('Choose a group')}
            />
          </StyledFormItem>
        )}
        {type === EventType.Availability && (
          <StyledFormItem
            name="bookingOptionIds"
            label={I18nService.getString(
              'Options',
              null,
              'Create event popover - Preferably a shorter word (max. 12 characters)'
            )}
            rules={[required()]}
          >
            <BookingOptionsSelect taxonomyIds={[currentValues?.mainCategory]} />
          </StyledFormItem>
        )}
        {type === EventType.Availability && userItem}
        <Row justify="end" style={{ marginTop: 16 }}>
          <Col>
            <Space>
              <CdFormSubmitButton form={form} onClick={onCreate}>
                {type === EventType.Event
                  ? I18nService.getString('Create event')
                  : type === EventType.Absence
                    ? I18nService.getString('Create absence')
                    : I18nService.getString('Create availability')}
              </CdFormSubmitButton>
              {(funeralCalendarV2Enabled ||
                type !== EventType.Availability) && (
                <Button type="link" onClick={onMoreOptions}>
                  {I18nService.getString('More options')}
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </StyledCard>
    </Form>
  );
};

const StyledCard = styled(Card)`
  width: 500px;

  .ant-card-head {
    padding: 16px;
    background-color: #7ab800;
  }

  .ant-card-body {
    padding: 16px;
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 8px;
`;

const IconButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTypeSelectionButton = styled(Button)`
  & {
    transition-duration: 300ms;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.75) !important;
    border-color: white !important;
    color: rgba(0, 0, 0, 0.88) !important;
  }
`;
