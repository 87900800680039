/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SogndkService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * SognDkGetSetting
     * Get Songdk Settings
     * @returns EmptyObject
     * @throws ApiError
     */
    public getSogndk(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sogndk',
        });
    }
    /**
     * SognDkSaveSetting
     * Save Setting
     * @returns EmptyObject
     * @throws ApiError
     */
    public postSogndk({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sogndk',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * SognDkGetCategories
     * Get Categories
     * @returns EmptyObject
     * @throws ApiError
     */
    public getSogndkCategories(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sogndk/categories',
        });
    }
    /**
     * SognDkGetChurches
     * Get Churches
     * @returns EmptyObject
     * @throws ApiError
     */
    public getSogndkChurches(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sogndk/churches',
        });
    }
    /**
     * SognDkGetPriests
     * Get Priests
     * @returns EmptyObject
     * @throws ApiError
     */
    public getSogndkPriests(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sogndk/priests',
        });
    }
}
