import { Form, Input, Space, Spin } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';

import { I18nService } from '../../services/I18nService';
import { useDomains } from '../hooks/use-domains';
import { supportGetYourWebsiteOnline } from '../utils/support-articles';

import NotificationService from '@/react/services/NotificationService';
import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import cdApp from '@/react/config';

export const showAddDomainModal = createCdModal<{ siteId: string }>(
  ({ setModalProps, siteId, loading }) => {
    const { addDomain } = useDomains(siteId);
    const [formInstance] = Form.useForm();

    const onOk = useCallback(
      () =>
        formInstance
          .validateFields()
          .then((values: { domain: string }) => addDomain(values?.domain))
          .catch((err) => {
            if (!isEmpty(err.errorFields)) {
              err.errorFields?.forEach((field) => {
                field.errors?.forEach((error) => {
                  NotificationService.notifyError(error);
                });
              });
            }
            throw err;
          }),
      [addDomain, formInstance]
    );

    useEffect(() => {
      setModalProps({
        modalType: ModalType.MODAL,
        title: I18nService.getString('Add Existing Domain'),
        width: 500,
        onOk,
      });
    }, [onOk, setModalProps]);

    const domainSupportArticle = supportGetYourWebsiteOnline(
      cdApp?.organization?.locale.language
    );

    return (
      <Spin
        spinning={loading}
        tip={I18nService.getString(
          'Adding domain. This may take up to 2 minutes.'
        )}
      >
        <Form form={formInstance} layout="horizontal">
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <span>
              {I18nService.getString(
                'If you own your domain already you can add it to ChurchDesk.'
              )}
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html: I18nService.getString(
                    'Need help? <a>Learn how to set up custom domain hosting.</a>',
                    {
                      a: (text) =>
                        `<a href="${domainSupportArticle}" target="_blank" rel="noopener noreferrer">${text}</a>`,
                    }
                  ),
                }}
              />
            </span>
            <Form.Item
              name="domain"
              extra={I18nService.getString(
                "Add the domain without 'www'. This will be added automatically."
              )}
              rules={[
                {
                  required: true,
                  message: I18nService.getString('This field is required.'),
                },
                {
                  message: I18nService.getString(
                    'Please enter a valid domain name. Example: my-church.com'
                  ),
                  pattern: new RegExp(
                    /^(((?!(-|(www\.))))(xn--|_)?[a-z0-9-]{0,61}[a-z0-9]{1,1}\.)?(?!www\.)(xn--)?([a-z0-9][a-z0-9\-]{0,60}|[a-z0-9-]{1,30}\.[a-z]{2,})$/i
                  ),
                },
                () => ({
                  validator(_, value) {
                    if (value?.toLowerCase().includes('churchdesk.site')) {
                      return Promise.reject(
                        I18nService.getString(
                          'Please enter a domain name which is not part of churchdesk.site'
                        )
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input placeholder={I18nService.getString('my-church.com')} />
            </Form.Item>
          </Space>
        </Form>
      </Spin>
    );
  }
);
