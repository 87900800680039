import featureToggleService from '../../services/FeatureToggleService';

import { CdQueryDefinition } from './types';

export const flagrQueries = {
  getFlag: (key: string) => ({
    queryKey: ['flagr', key],
    queryFn: () => featureToggleService.hasFeature(key),
    staleTime: Infinity,
  }),
} satisfies CdQueryDefinition;
