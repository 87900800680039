import { Input } from 'antd';
import React from 'react';

import { CdTooltip } from './cd-tooltip/CdTooltip';

import { I18nService } from '@/react/services/I18nService';

export const CdInputAlias = (props: {
  value?: string;
  onChange?: any;
  allowAlias: boolean;
  disabled?: boolean;
}) => (
  <CdTooltip
    title={
      !props.allowAlias
        ? I18nService.getString(
            'Alternative URL is only available for public events.'
          )
        : null
    }
  >
    <Input
      addonBefore={I18nService.getString('www.your-church.com/b/')}
      disabled={!props.allowAlias || props.disabled}
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
    />
  </CdTooltip>
);
