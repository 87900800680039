import { Button, Dropdown, MenuProps, Space, Spin, Typography } from 'antd';
import React, { Suspense } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { useRecoilValue } from 'recoil';

import { UpdatedPermissionQuery } from '../../store/events/event';

import { CalendarPosterLandingPage } from './calendar-posters/CalendarPosterLandingPage';
import { CalendarPosterPrintDropDown } from './calendar-posters/CalendarPosterPrintDropDown';

import {
  CdAngleDown,
  CdDeleteIcon,
  CdEditIcon,
  CdPoster,
  CdTime,
} from '@/react/shared/components/Icons';
import { I18nService } from '@/react/services/I18nService';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import { navigate } from '@/react/services/StateServiceFactory';
import { useDeleteCalendarPoster } from '@/react/calendar/hooks/event/useCalendarPoster';
import AuthorizationService from '@/react/services/AuthorizationService';
import { ColumnType } from '@/react/shared/components/cd-table/types';
import CdSimpleTable from '@/react/shared/components/cd-simple-table/CdSimpleTable';
import { GetEventPosters } from '@/react/calendar/store/events/calendarPoster';
import { CalendarPoster } from '@/react/calendar/types/event';
import { CdUserAvatar } from '@/react/user/components/cd-user-avatar/CdUserAvatar';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';

const { Link } = Typography;

export const CalendarPosterList = (props: {
  eventId: number;
  isFromEventDetailsPage?: boolean;
  handleCreatePoster?: () => void;
  handleEditPoster?: (id: number) => void;
}) => (
  <Suspense fallback={<CdrPageLoader />}>
    <CalendarPosterListContent {...props} />
  </Suspense>
);

const CalendarPosterListContent = ({
  eventId,
  isFromEventDetailsPage,
  handleCreatePoster,
  handleEditPoster,
}: {
  eventId: number;
  isFromEventDetailsPage?: boolean;
  handleCreatePoster?: () => void;
  handleEditPoster?: (id: number) => void;
}) => {
  const PostersResource = GetEventPosters({ eventId });
  const { canEdit, canCreate } = useRecoilValue(
    UpdatedPermissionQuery({
      eventId,
    })
  );
  const openTemplateSelector = () => {
    if (isFromEventDetailsPage) {
      handleCreatePoster();
      return;
    }
    NiceModal.show('CalendarPosterTemplates', { calendarId: eventId });
  };
  const { deletePoster } = useDeleteCalendarPoster({
    eventId,
  });
  const hasStudio = AuthorizationService.hasPackage('studio');

  const confirmDeleteModal = (posterId: number) =>
    showConfirmModal({
      title: I18nService.getString('Delete poster'),
      message: I18nService.getString('Do you want to delete the poster?'),
      icon: <CdDeleteIcon />,
      okButtonProps: { danger: true },
      okText: I18nService.getString('Delete'),
      onOk: () => deletePoster({ posterId }),
    });

  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: I18nService.getString('Edit'),
      icon: <CdEditIcon />,
    },
    {
      key: 'delete',
      label: I18nService.getString('Delete'),
      icon: <CdDeleteIcon />,
    },
  ];
  const handleDropDownClick = (option, item: CalendarPoster) => {
    switch (option.key) {
      case 'edit':
        if (isFromEventDetailsPage) {
          handleEditPoster(item.id);
          break;
        }
        navigate('app.private.calendar.poster', {
          posterId: item.id,
          calendarId: eventId,
        });
        break;
      case 'delete':
        confirmDeleteModal(item.id);
        break;
      default:
        break;
    }
  };
  const handleViewThePoster = (posterId: number) => {
    if (isFromEventDetailsPage) {
      handleEditPoster(posterId);
      return;
    }
    navigate('app.private.calendar.poster', {
      posterId: posterId,
      calendarId: eventId,
    });
  };
  const posterTableColumns: ColumnType<CalendarPoster>[] = [
    {
      title: I18nService.getString('Created Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      render: (createdAt, record: CalendarPoster) => (
        <Link onClick={() => handleViewThePoster(record.id)}>
          <Space>
            <CdTime />
            {I18nService.formatDate(createdAt, 'lll')}
          </Space>
        </Link>
      ),
    },
    {
      title: I18nService.getString('Author'),
      dataIndex: 'author',
      key: 'author',
      width: 200,
      render: (author) => (
        <CdUserAvatar picture={author.picture} name={author.name} />
      ),
    },
    {
      title: null,
      dataIndex: null,
      key: 'actionsMenu',
      fixed: 'right',
      width: 200,
      align: 'center',
      render: (poster) => (
        <Space>
          <CalendarPosterPrintDropDown
            buttonSize="small"
            exportToken={poster.exportToken}
            calendarId={eventId}
            key={`print-button-${eventId}`}
          />
          <Dropdown
            menu={{
              items,
              onClick: (option) => handleDropDownClick(option, poster),
            }}
          >
            <Button size="small">
              {I18nService.getString('More')} <CdAngleDown />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    (hasStudio && (
      <CdSimpleTable<CalendarPoster>
        noStyle
        recoilSource={PostersResource}
        emptyStateProps={{
          title: I18nService.getString('No posters found'),
          buttonText: I18nService.getString('Create poster'),
          buttonType: 'default',
          EmptyStateIcon: <CdPoster />,
          buttonDisabled: !(canEdit || canCreate),
          onButtonClick: () => openTemplateSelector(),
        }}
        columns={posterTableColumns}
      />
    )) || (
      <Suspense fallback={<Spin />}>
        <CalendarPosterLandingPage />
      </Suspense>
    )
  );
};
