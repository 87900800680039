import React, { useEffect } from 'react';
import { Col, Row, Form, Input } from 'antd';

import { AddressInterface } from '../types/SafeguardingTypes';

import { I18nService } from '@/react/services/I18nService';
import {
  ModalType,
  createCdModal,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import { CdCountrySelect } from '@/react/shared/components/cd-country-select/CdCountrySelect';

type AddressModalProps = {
  address: AddressInterface;
  userFullName: string;
  onAddressSaveHandler: (values: AddressInterface) => void;
};

export const showUserAddressModal = createCdModal<AddressModalProps>(
  ({ setModalProps, address, userFullName, onAddressSaveHandler }) => {
    const [userAddressForm] = Form.useForm();
    useEffect(() => {
      setModalProps({
        title: I18nService.getString('Add address for {{user}}', {
          user: userFullName,
        }),
        modalType: ModalType.MODAL,
        width: 500,
        onOk: () => {
          userAddressForm
            .validateFields()
            .then((values) => onAddressSaveHandler(values));
        },
      });
    }, [
      setModalProps,
      address,
      userFullName,
      onAddressSaveHandler,
      userAddressForm,
    ]);

    return (
      <Row>
        <Col span={24}>
          <Form
            id="userAddressForm"
            layout="vertical"
            initialValues={address}
            form={userAddressForm}
          >
            <Form.Item name="street" label={I18nService.getString('Street')}>
              <Input placeholder={I18nService.getString('Street name')} />
            </Form.Item>
            <Form.Item
              name="postCode"
              label={I18nService.getString('Postcode')}
            >
              <Input
                type="number"
                placeholder={I18nService.getString('Post code')}
              />
            </Form.Item>
            <Form.Item name="city" label={I18nService.getString('City')}>
              <Input placeholder={I18nService.getString('City name')} />
            </Form.Item>
            <Form.Item
              name="countryIso2"
              label={I18nService.getString('Country')}
            >
              <CdCountrySelect
                placeholder={I18nService.getString('Country name')}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    );
  }
);
