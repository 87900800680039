import React from 'react';

import { FilterType } from '../store/filters';

import { showCalendarSubscribeModal } from '@/react/calendar/components/CalendarSubscribeModal';
import { StyledFilterItem } from '@/react/calendar/components/FilterItem';
import {
  CALENDAR_EVENT_COLORS,
  CalendarColorType,
  getColorBasedOnColorIndex,
} from '@/react/calendar/components/event-item/colors';
import { useCalendarSearchContext } from '@/react/calendar/components/sidebar-search/CalendarSearchContext';
import { FeedType } from '@/react/calendar/store/main-view/Actions';
import { I18nService } from '@/react/services/I18nService';
import { CdExternalLink } from '@/react/shared/components/Icons';
import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';

type ItemData = {
  id?: string | number;
  filterType?: FilterType;
};

type SearchableFilterItemProps = {
  onLabelClick: () => void;
  itemData?: ItemData;
  color: number | CalendarColorType;
  isSelected: boolean;
  isDisabled?: boolean;
  namePrefix?: React.ReactNode;
  name: string;
  nameSuffix?: React.ReactNode;
  feedType?: FeedType;
  extraActions?: React.ReactNode;
};

export const SearchableFilterItem = ({
  onLabelClick,
  isSelected,
  isDisabled = false,
  itemData = {},
  name,
  nameSuffix,
  feedType,
  extraActions,
  color,
}: SearchableFilterItemProps) => {
  const { lowerCaseSearchName, isDuringSearch } = useCalendarSearchContext();

  if (isDuringSearch) {
    const lowerCaseName = name.toLowerCase();
    if (!lowerCaseName.includes(lowerCaseSearchName)) {
      return null;
    }
  }

  const fallBackColors = CALENDAR_EVENT_COLORS.fallback;
  const colors =
    itemData.filterType === FilterType.users
      ? fallBackColors
      : getColorBasedOnColorIndex(color);

  return (
    <StyledFilterItem
      isDisabled={isDisabled}
      hoverBackgroundColor={colors.backgroundLight}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <div className="bullet-wrapper">
        <div
          className="bullet-label"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            if (!isDisabled) {
              onLabelClick();
            }
          }}
        >
          <span
            className={`bullet ${isSelected ? 'bullet-full' : 'bullet-empty'}`}
            style={{
              backgroundColor: isSelected ? colors.main : 'transparent',
              borderColor: colors.main,
              color: 'white',
            }}
          />
          <div className="label">
            <span className="name" style={{ color: colors.text }}>
              {name}
            </span>
            {nameSuffix && (
              <span style={{ opacity: 0.8, color: colors.text }}>
                {nameSuffix}
              </span>
            )}
          </div>
        </div>

        <span className="filter-actions">
          {feedType ? (
            <CdTooltip title={I18nService.getString('Subscribe to calendar')}>
              <CdExternalLink
                onClick={() =>
                  showCalendarSubscribeModal({ feedType, feedId: itemData.id })
                }
                style={{
                  marginRight: 5,
                  cursor: 'pointer',
                  color: colors.textLight,
                }}
              />
            </CdTooltip>
          ) : null}
          {extraActions}
        </span>
      </div>
    </StyledFilterItem>
  );
};
