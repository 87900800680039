import React from 'react';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import { compose } from 'redux';
import { Form, Input, Modal } from 'antd';

import useCdFormLabel from '../hooks/useCdFormLabel';

import { I18nService } from '@/react/services/I18nService';

const CreateLabel = NiceModal.create(() => {
  const { create } = useCdFormLabel();
  const modal = useModal('CreateLabel');
  const [form] = Form.useForm();

  return (
    <Modal
      {...antdModalV5(modal)}
      title={I18nService.getString('Create new label')}
      onOk={compose(modal.hide, form.submit)}
    >
      <Form form={form} onFinish={({ label }) => create(label)}>
        <Form.Item
          name="label"
          help={I18nService.getString(
            'You can use labels to keep you forms organized. E.g. for “Concerts” etc.'
          )}
          label={I18nService.getString('Name')}
        >
          <Input type="text" />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default CreateLabel;
