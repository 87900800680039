import React, { useCallback, useEffect, useState } from 'react';
import { Input, Radio, Select, Spin, Form } from 'antd';
import 'react-image-crop/dist/ReactCrop.css';
import styled from 'styled-components';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { PeopleFiltersWhereUserCanAddToList } from '../store/newsletterListState';
import useNewsletterList from '../hooks/useNewsletterList';
import {
  createCdModal,
  ModalType,
} from '../../shared/components/cd-modal-new/CdModal2';

import { I18nService } from '@/react/services/I18nService';
import { HasMultipleChurches } from '@/react/user/store/user-session';
import { UserChurchesWithPermissionQuery } from '@/react/user/store/permissions';

const RadioButtonArea = styled(Radio.Group)`
  &&&& {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    row-gap: '4px';
  }
`;
const { Option } = Select;

export const showCreateParticipantList = createCdModal(({ setModalProps }) => {
  const [form] = Form.useForm();
  const [radioButtonsValue, setRadioButtonsValue] = useState(1);

  const [enableFilter, setEnableFilter] = useState(true);
  const { createList } = useNewsletterList();

  const hasMultipleChurches = useRecoilValue(HasMultipleChurches);
  const parishesLoadable = useRecoilValueLoadable(
    UserChurchesWithPermissionQuery({
      permissionContext: 'people',
      permissionType: 'access',
    })
  );
  const filtersLoadable = useRecoilValueLoadable(
    PeopleFiltersWhereUserCanAddToList
  );

  const parishes =
    parishesLoadable.state === 'hasValue' ? parishesLoadable.contents : [];
  const filters =
    filtersLoadable.state === 'hasValue' ? filtersLoadable.contents : [];
  const loading =
    parishesLoadable.state === 'loading' || filtersLoadable.state === 'loading';

  const onToggleFilter = (e) => {
    setRadioButtonsValue(e.target.value);
    if (radioButtonsValue === 2) {
      setEnableFilter(true);
      form.setFieldsValue({
        filterIds: undefined,
      });
    } else {
      setEnableFilter(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      setup: {
        churchIds: parishes.map((p) => p.id),
      },
    });

    // eslint-disable-next-line
  }, [parishes]);

  const createParticipantListHandle = useCallback(async () => {
    await createList(
      form.getFieldValue('name'),
      form.getFieldValue('setup'),
      form.getFieldValue('filterIds'),
      'participant'
    );
  }, [createList, form]);

  useEffect(
    () =>
      setModalProps({
        title: I18nService.getString('Create participant list'),
        okText: I18nService.getString('Create'),
        onOk: createParticipantListHandle,
        modalType: ModalType.MODAL,
      }),
    [createParticipantListHandle, setModalProps]
  );

  return (
    <Form layout="vertical" form={form}>
      <Spin spinning={loading}>
        <Form.Item
          name="name"
          label={I18nService.getString('Title')}
          rules={[
            {
              required: true,
              message: I18nService.getString('Please input a title.'),
            },
          ]}
          help={I18nService.getString(
            'This is the title as it will appear on where people subscribe to your participant list'
          )}
          style={{ width: '100%', marginBottom: '24px' }}
        >
          <Input />
        </Form.Item>
        {filters?.length > 0 && (
          <Form.Item
            label={I18nService.getString(
              'Will you start by adding contacts as subscribers to this participant list?'
            )}
          >
            <RadioButtonArea
              onChange={onToggleFilter}
              value={radioButtonsValue}
            >
              <Radio value={1} style={{ color: '#4F4F4F' }}>
                {I18nService.getString(
                  'No. Create the participant list without adding subscribers'
                )}
              </Radio>
              <Radio value={2} style={{ color: '#4F4F4F' }}>
                {I18nService.getString(
                  'Yes. Add contacts as subscribers from a current filter'
                )}
              </Radio>
            </RadioButtonArea>

            <Form.Item name="filterIds" noStyle>
              <Select
                placeholder={I18nService.getString(
                  'Select filter for adding contacts as subscribers'
                )}
                disabled={enableFilter}
                style={{ width: '100%' }}
                allowClear
              >
                {filters?.map((filter) => (
                  <Option value={filter.id} key={filter.id}>
                    {filter.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item>
        )}

        {hasMultipleChurches && (
          <>
            <Form.Item
              name={['setup', 'churchIds']}
              label={I18nService.getString(
                'Parish access and contact creation'
              )}
              help={I18nService.getString(
                'Choose which parish(es) should be able to use this participant list. When a new contact signs-up to this participant list the contact will be associated with the selected parishes.'
              )}
            >
              <Select
                placeholder={I18nService.getString('Please select')}
                mode="multiple"
                style={{ width: '100%' }}
              >
                {parishes.map((parish, i) => (
                  <Option value={parish.id} key={parish.name + `${i}`}>
                    {parish.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}
      </Spin>
    </Form>
  );
});
