import { CdQueryDefinition } from '../types';

import { mainApi } from '@/react/api';
import { EntityType } from '@/react/people/types/message';
import { handleError } from '@/react/services/ErrorHandlingService';

export const messageAttributesQueries = {
  get: (entityType: EntityType) => ({
    queryKey: ['useMessageAttributes', entityType],
    queryFn: async () => {
      const response = await mainApi.get<any>(
        '/v2/people/messages/attributes',
        {
          type: entityType,
        }
      );
      if (!response.ok) {
        handleError(response);
        return [];
      }
      return response.data;
    },
  }),
} as CdQueryDefinition;
