import { Button, FormInstance, Space } from 'antd';
import { capitalize } from 'lodash';
import React, { PropsWithChildren, useState } from 'react';
import { SizeType } from 'antd/es/config-provider/SizeContext';

import { useFormValidation } from '../../../contributions/hooks/useFormValidation';
import { CdTooltip } from '../cd-tooltip/CdTooltip';
import { I18nService } from '../../../services/I18nService';

// STORYBOOK - TODO

export const CdFormSubmitButton = (
  props: {
    onClick?: () => Promise<void> | void;
    size?: SizeType;
    form?: FormInstance;
  } & PropsWithChildren
) => {
  const { isValid, errors } = useFormValidation(props.form);
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <CdTooltip
      title={
        !isValid && (
          <Space direction="vertical" align="start" size={0}>
            {I18nService.getString('Not all fields are filled correctly')}
            {errors.errorFields?.map((error) => (
              <Space key={error.name.join()} size="small">
                -
                {I18nService.getString(
                  capitalize(
                    error.name.join(' ').replace(/([a-z0-9])([A-Z])/g, '$1 $2')
                  )
                )}
              </Space>
            ))}
          </Space>
        )
      }
    >
      <Button
        onClick={async () => {
          setIsSubmitting(true);
          try {
            await props.onClick?.();
          } finally {
            setIsSubmitting(false);
          }
        }}
        size={props.size}
        disabled={!isValid}
        loading={isSubmitting}
        type="primary"
      >
        {props.children}
      </Button>
    </CdTooltip>
  );
};
