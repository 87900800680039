import { useMutation, useQuery } from '@tanstack/react-query';

import {
  createContactProfileBasicInformation,
  getContactProfileInformation,
  getStaticFields,
  getStaticFieldsOptions,
  saveContactProfileInformation,
} from '../store/contactProfileInformation';

import { handleSuccessMessage } from '@/react/shared/utils';
import { navigate } from '@/react/services/StateServiceFactory';
import { I18nService } from '@/react/services/I18nService';

type ContactProfileInformationKey = [string, number];
export const useUpdateContactProfileInfo = (contactId) => {
  const { mutateAsync } = useMutation({
    mutationFn: saveContactProfileInformation,
    onSuccess: () => {
      handleSuccessMessage(
        I18nService.getString('The information was saved successfully.')
      );
      navigate(
        `app.private.people.contacts.view`,
        { id: contactId },
        { reload: true }
      );
    },
  });
  return { updateContactInfo: mutateAsync };
};
export const useGetProfileInfo = (contactId) => {
  const { data, isPending, isRefetching } = useQuery({
    queryKey: [
      'useContactProfileInformation',
      contactId,
    ] as ContactProfileInformationKey,
    queryFn: async () => await getContactProfileInformation(contactId),
  });

  return {
    profileInfo: data,
    isPending,
    isRefetching,
  };
};
export const useGetStaticFields = () => {
  const { data, isPending, isRefetching } = useQuery({
    queryKey: ['useGetStaticFields'],
    queryFn: async () => await getStaticFields(),
  });

  return {
    staticFields: data,
    isPending,
    isRefetching,
  };
};

export const useSaveContactProfileInfo = () => {
  const { mutateAsync } = useMutation({
    mutationFn: createContactProfileBasicInformation,
    onSuccess: (res) => {
      if (!res.saveAndCreateAnother) {
        navigate(
          `app.private.people.contacts.view`,
          { id: res.data.id },
          { reload: true }
        );
      }
    },
  });
  return { createContact: mutateAsync };
};
export const useGetStaticFieldsOptions = () => {
  const { data, isPending, isRefetching } = useQuery({
    queryKey: ['useGetStaticFieldsOptions'],
    queryFn: async () => await getStaticFieldsOptions(),
  });

  return {
    staticFieldsOptions: data,
    isPending,
    isRefetching,
  };
};
