/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { Get_fileResponseBody } from '../models/Get_fileResponseBody';
import type { Get_filesResponseBody } from '../models/Get_filesResponseBody';
import type { GetFileResponseBody } from '../models/GetFileResponseBody';
import type { PostFilesCreateZipRequestBody } from '../models/PostFilesCreateZipRequestBody';
import type { PostFilesFormsUploadRequestBody } from '../models/PostFilesFormsUploadRequestBody';
import type { PostFilesUploadRequestBody } from '../models/PostFilesUploadRequestBody';
import type { PutFilesCropinformationRequestBody } from '../models/PutFilesCropinformationRequestBody';
import type { PutFilesRequestBody } from '../models/PutFilesRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FilesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get_files
     * Get files
     * @returns Get_filesResponseBody HTTP/1.1 200 OK
     * @throws ApiError
     */
    public getFiles({
        path,
        page,
        order,
        filter,
    }: {
        path?: string,
        page?: {
            number?: number;
            limit?: number;
        },
        order?: {
            by?: string;
            direction?: string;
        },
        filter?: string,
    }): CancelablePromise<Get_filesResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/files',
            query: {
                'path': path,
                'page': page,
                'order': order,
                'filter': filter,
            },
        });
    }
    /**
     * PostFilesCreateZip
     * Prepare files in zip
     * @returns EmptyObject
     * @throws ApiError
     */
    public postFilesCreateZip({
        requestBody,
    }: {
        requestBody?: PostFilesCreateZipRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/files/create/zip',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetFilesDownloadToken
     * Get a download token
     * @returns EmptyObject
     * @throws ApiError
     */
    public getFilesDownloadToken({
        fileId,
    }: {
        fileId: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/files/download/token',
            query: {
                'fileId': fileId,
            },
        });
    }
    /**
     * Download a file from the export service.
     * @throws ApiError
     */
    public getFilesExportDownload({
        exportToken,
        exportType,
        id,
    }: {
        exportToken: string,
        exportType: string,
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/files/export/{id}/download',
            path: {
                'id': id,
            },
            query: {
                'exportToken': exportToken,
                'exportType': exportType,
            },
        });
    }
    /**
     * PostFilesFormsUpload
     * @returns EmptyObject
     * @throws ApiError
     */
    public postFilesFormsUpload({
        requestBody,
    }: {
        requestBody?: PostFilesFormsUploadRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/files/forms/upload',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetFilesFormsIdoruuidAction
     * @returns EmptyObject
     * @throws ApiError
     */
    public getFilesForms({
        idOrUuid,
        action,
        accessToken,
    }: {
        idOrUuid: string,
        action: string,
        accessToken?: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/files/forms/{idOrUuid}/{action}',
            path: {
                'idOrUuid': idOrUuid,
                'action': action,
            },
            query: {
                'access_token': accessToken,
            },
        });
    }
    /**
     * Health-check
     * Health-check
     * @returns EmptyObject
     * @throws ApiError
     */
    public getFilesHealthCheck(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/files/health-check',
        });
    }
    /**
     * MultiDeleteFiles
     * Multidelete
     * @returns EmptyObject
     * @throws ApiError
     */
    public postFilesMultidelete({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/files/multidelete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetTags
     * Tags
     * @returns EmptyObject
     * @throws ApiError
     */
    public getFilesTags(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/files/tags',
        });
    }
    /**
     * UploadFile
     * Upload
     * @returns EmptyObject
     * @throws ApiError
     */
    public postFilesUpload({
        requestBody,
    }: {
        requestBody?: PostFilesUploadRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/files/upload',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetUploadValidators
     * UploadValidators
     * @returns EmptyObject
     * @throws ApiError
     */
    public getFilesUploadvalidators({
        gallery,
    }: {
        /**
         * True if validation criteria is for gallery otherwise false
         */
        gallery: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/files/uploadvalidators',
            query: {
                'gallery': gallery,
            },
        });
    }
    /**
     * ViewOrDownload_v2
     * VIEW Or DOWNLOAD OR RAW
     * @returns EmptyObject
     * @throws ApiError
     */
    public getFilesV2({
        downloadToken,
        action = 'download',
    }: {
        downloadToken: string,
        action?: 'download' | 'view' | 'raw',
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/files/v2/{action}',
            path: {
                'action': action,
            },
            query: {
                'downloadToken': downloadToken,
            },
        });
    }
    /**
     * GetFilesZipDownload
     * Download the created zip
     * @returns EmptyObject
     * @throws ApiError
     */
    public getFilesZipDownload({
        exportToken,
    }: {
        exportToken: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/files/zip/download',
            query: {
                'exportToken': exportToken,
            },
        });
    }
    /**
     * DeleteFile
     * Delete
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteFiles(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/files/{idOrUuid}',
        });
    }
    /**
     * GetFile
     * RETRIEVE
     * @returns GetFileResponseBody
     * @throws ApiError
     */
    public getFiles1(): CancelablePromise<GetFileResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/files/{idOrUuid}',
        });
    }
    /**
     * UpdateFile
     * UPDATE
     * @returns EmptyObject
     * @throws ApiError
     */
    public putFiles({
        accessToken,
        requestBody,
    }: {
        accessToken?: string,
        requestBody?: PutFilesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/files/{idOrUuid}',
            query: {
                'access_token': accessToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * ViewOrDownload
     * VIEW Or DOWNLOAD
     * @returns EmptyObject
     * @throws ApiError
     */
    public getFiles2({
        idOrUuid,
        accessToken,
        action,
    }: {
        idOrUuid: string,
        accessToken?: string | null,
        action?: 'download' | 'view',
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/files/{idOrUuid}/{action}',
            path: {
                'idOrUuid': idOrUuid,
                'action': action,
            },
            query: {
                'access_token': accessToken,
            },
        });
    }
    /**
     * GetFilesIdCropInformation
     * Crop Information, Retrieve
     * @returns EmptyObject
     * @throws ApiError
     */
    public getFilesCropInformation({
        context,
        accessToken,
    }: {
        context: string,
        accessToken?: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/files/{id}/crop-information',
            query: {
                'access_token': accessToken,
                'context': context,
            },
        });
    }
    /**
     * PutFilesIdCropInformation
     * Crop Information, Update
     * @returns EmptyObject
     * @throws ApiError
     */
    public putFilesCropInformation({
        accessToken,
        requestBody,
    }: {
        accessToken?: string,
        requestBody?: PutFilesCropinformationRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/files/{id}/crop-information',
            query: {
                'access_token': accessToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get_file
     * Get updated file path
     * @returns Get_fileResponseBody HTTP/1.1 200 OK
     * @throws ApiError
     */
    public postFilesMigration({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: EmptyObject,
    }): CancelablePromise<Get_fileResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/files/{id}/migration',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
