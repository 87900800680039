import React, { Suspense } from 'react';
import { Button } from 'antd';
import NiceModal from '@ebay/nice-modal-react';

import { I18nService } from '../../services/I18nService';
import { useWebsitesAnalytics } from '../widgets/hooks/use-websites-analytics';
import AuthorizationService from '../../services/AuthorizationService';
import CdrPageLoader from '../../shared/components/CdrPageLoader';

import { ManageWebsiteList } from './ManageWebsiteList';

import cdApp from '@/react/config';
import { CdSalesButton } from '@/react/shared/components/cd-buttons/CdSalesButton';
import { CdPage } from '@/react/shared/components/cd-page/CdPage';

export const WebsiteLandingPage = () => {
  const language = cdApp.organization.locale.language;
  const { websitesWithAnalytics } = useWebsitesAnalytics();
  const cookiesAndAnalyticsEnabled =
    websitesWithAnalytics.items.length > 0 &&
    AuthorizationService.hasPackage('monoCookies');

  let webDesignUrl;
  let cookiesAndAnalyticsUrl;
  switch (language) {
    case 'da':
    case 'se':
      webDesignUrl = 'https://www.churchdesk.com/da/webdesign-service';
      cookiesAndAnalyticsUrl =
        'https://www.churchdesk.com/da/produkt/cookielosning-og-besogssgtatistik';
      break;

    case 'de':
    case 'fr':
      webDesignUrl = 'https://www.churchdesk.com/de/webdesign-service';
      cookiesAndAnalyticsUrl =
        'https://www.churchdesk.com/de/produkt/cookies-und-analytics';
      break;

    case 'en':
      webDesignUrl = 'https://www.churchdesk.com/en/webdesign-service';
      cookiesAndAnalyticsUrl =
        'https://www.churchdesk.com/en/product/cookies-and-analytics';
      break;

    default:
      webDesignUrl = 'https://www.churchdesk.com/en/webdesign-service';
      cookiesAndAnalyticsUrl =
        'https://www.churchdesk.com/en/product/cookies-and-analytics';
      break;
  }

  const cdPageExtraButtons = [
    <CdSalesButton
      key="getANewWebDesign"
      href={webDesignUrl}
      rel="noopener noreferrer"
      target="_blank"
    >
      {I18nService.getString('Get a new web design')}
    </CdSalesButton>,
    <Button
      key="preview"
      onClick={() =>
        NiceModal.show('CreateWebsiteDrawer', { previewOnly: true })
      }
    >
      {I18nService.getString('Preview all templates')}
    </Button>,
  ];
  if (!cookiesAndAnalyticsEnabled) {
    cdPageExtraButtons.push(
      <Button
        key="cookiesAndAnalytics"
        href={cookiesAndAnalyticsUrl}
        rel="noopener noreferrer"
        target="_blank"
      >
        {I18nService.getString('Get Cookies & Analytics')}
      </Button>
    );
  }

  return (
    <CdPage
      pageHeaderProps={{
        title: I18nService.getString('ChurchDesk Websites'),
        subTitle: I18nService.getString('Manage your websites here'),
        extra: cdPageExtraButtons,
      }}
    >
      <ManageWebsiteList />
    </CdPage>
  );
};
export const WebsiteLandingPageWrapper = () => (
  <Suspense fallback={<CdrPageLoader marginLess />}>
    <WebsiteLandingPage />
  </Suspense>
);
