import { useMutation } from '@tanstack/react-query';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect } from 'react';

import { backendApiClient } from '../../api';
import { useTaxonomies } from '../../calendar/hooks/use-taxonomies';
import { I18nService } from '../../services/I18nService';
import NotificationService from '../../services/NotificationService';
import StateServiceFactory from '../../services/StateServiceFactory';
import {
  ModalType,
  SetModalPropsType,
} from '../../shared/components/cd-modal-new/CdModal2';
import { useFlag } from '../../shared/hooks/useFlag';
import { CategoryType } from '../../shared/models/category';
import { useFormValidation } from '../../contributions/hooks/useFormValidation';

export const useDeleteGroupModal = (
  setModalProps: SetModalPropsType,
  groupId
) => {
  const [form] = useForm();
  const { isValid } = useFormValidation(form);
  const transfer = Form.useWatch('transfer', form);
  const bookingEnquiriesV2Enabled = useFlag('booking_enquiries_v2');
  const state = StateServiceFactory();

  const { taxonomies } = useTaxonomies(CategoryType.availability);
  const bookingPagesThatWillLoseAvailability = taxonomies.filter((taxonomy) =>
    taxonomy.groups.map((group) => group.id).includes(groupId)
  );

  const deleteGroup = useMutation({
    mutationFn: async ({ id, targetId }: { id: number; targetId: number }) => {
      await backendApiClient.groups.deleteGroups({
        id,
        targetId,
      });
      state.go('app.private.intranet.groups');
      if (targetId) {
        NotificationService.notifySuccess('All content has been transferred.');
      }
      NotificationService.notifySuccess('The group has been deleted.');
    },
  });

  useEffect(() => {
    setModalProps({
      modalType: ModalType.POPUP,
      okText: transfer
        ? I18nService.getString('Delete and move content')
        : I18nService.getString('Delete'),
      onOk: async () => {
        const values = await form.validateFields();
        deleteGroup.mutate({
          id: groupId,
          targetId: values.targetId,
        });
      },
      okButtonProps: { disabled: !isValid, danger: true },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteGroup.mutate, form, groupId, setModalProps, transfer, isValid]);

  return {
    form,
    transfer,
    bookingEnquiriesV2Enabled,
    bookingPagesThatWillLoseAvailability,
  };
};
