import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsInResourceView } from '../../store/main-view/Selectors';
import { useTaxonomies } from '../../hooks/use-taxonomies';
import { useAvailabilitiesFilter } from '../../store/availabilities/availabilitiesSlice';
import { selectIsUserFiltersLoaded } from '../../store/filters/Selectors';

import { FilterSection } from './FilterSection';

import { CategoryType } from '@/react/shared/models/category';
import { I18nService } from '@/react/services/I18nService';

export const AvailabilityCalendarFilters = () => {
  const isInResourceView = useSelector(selectIsInResourceView);
  const { taxonomies, isLoading } = useTaxonomies(
    CategoryType.availability,
    'calendar'
  );
  const [value, setValue] = useAvailabilitiesFilter();
  const isUserFiltersLoaded = useSelector(selectIsUserFiltersLoaded);

  return (
    <FilterSection
      title={I18nService.getString('All availabilities')}
      value={value}
      onChange={setValue}
      options={taxonomies}
      isLoading={isLoading || !isUserFiltersLoaded}
      isDisabled={isInResourceView}
    />
  );
};
