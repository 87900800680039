import { loadStripeDynamically } from '@/app/contributions/shared/load-stripe-dynamically';
import { I18nService } from '@/react/services/I18nService';
(function () {
  'use strict';

  const checkAccessToModule = ($state, Authorization) => {
    'ngInject';
    if (!Authorization.hasPackage('forms')) {
      return $state.target('app.private.forms.default', {
        errorCode: 'PACKAGE_NOT_ENABLED',
      });
    }
    if (!Authorization.hasPermission('canAccessForms')) {
      return $state.target('app.private.forms.default', {
        errorCode: 'ACCESS_DENIED',
      });
    }
  };
  checkAccessToModule.$inject = ['$state', 'Authorization'];

  const prepareFormBuilder = async (
    $state,
    Authorization,
    formObject,
    peopleStaticFields,
    peopleStaticFieldsOptions,
    peopleCustomFields,
    consentTypes,
    newsletters,
    formioCustomService,
    formioComponents,
    gettextCatalog,
    $window
  ) => {
    'ngInject';

    // Check access
    if (!Authorization.hasPermission('canAccessForms')) {
      return $state.target('app.private.forms.default', {
        errorCode: 'ACCESS_DENIED',
      });
    }
    // Configure people static fields
    const peopleStaticFieldsConfiguration = _.mapValues(
      peopleStaticFields.toJSON(),
      (value) => value.sensitive
    );

    formioCustomService.configurePeopleStaticFields(
      peopleStaticFieldsConfiguration
    );

    // Fetch people static fields options to populate the dropdown or multiple choice options
    _.each(peopleStaticFieldsOptions, (options, key) => {
      const componentKey = `people${_.upperFirst(key)}`;
      const componentObject = _.get(formioComponents.components, componentKey);
      if (!componentObject) return;

      const componentType = componentObject.settings.fieldType;
      const valuesPath =
        componentType === 'dropdown'
          ? 'settings.data.values'
          : 'settings.values';

      // If any static fields don't contain any options, remove the corresponding form component
      const mappedOptions = _.map(options, (label, value) => ({
        label,
        value,
      }));

      if (_.isEmpty(mappedOptions)) {
        _.unset(formioComponents.components, componentKey);
      } else {
        _.set(componentObject, valuesPath, mappedOptions);
      }
    });

    // Generate formio-valid components from the consent types

    const consentTypesComponents = _.map(
      consentTypes,
      formioCustomService.buildConsentTypeComponent
    );

    const dynamicPeopleComponents = _.compact(
      _.concat(peopleCustomFields.components, consentTypesComponents)
    );

    formioCustomService.registerCustomComponents(
      dynamicPeopleComponents,
      'people',
      gettextCatalog
    );

    // Generate formio-valid components from the newsletters
    const newsletterLists = newsletters?.newsletterLists || [];
    const consent = consentTypes.find(
      (consent) => consent.defaultConsent === true
    );

    const newsletterListsWithDefaultConsent = newsletterLists.map(
      (newsletter) => ({
        ...newsletter,
        newsletterDefaultConsent: consent,
      })
    );

    const newslettersComponents = _.map(
      newsletterListsWithDefaultConsent,
      formioCustomService.buildNewslettersComponent
    );

    const dynamicPeopleNewsletterComponents = _.compact(
      _.concat(newslettersComponents, peopleCustomFields.components)
    );

    formioCustomService.registerCustomComponents(
      dynamicPeopleNewsletterComponents,
      'people',
      gettextCatalog
    );

    // Add consent types component to the default components that are added along with a person section
    formioCustomService.setDefaultComponentsOfPeople(true);

    if (Authorization.hasPackage('contributions')) {
      await loadStripeDynamically($window);
    }

    // Remove custom fields that have been deleted, from the existing form
    if (formObject.id) {
      const existingCustomFields = _.flatMapDepth(
        peopleCustomFields.components,
        (field) =>
          field.components ? _.map(field.components, 'key') : field.key,
        1
      );

      _.each(formObject.components, (component) => {
        if (component.type !== 'person') return;

        // Remove components that are People custom fields and that are no longer found in the custom fields data source
        component.components = _.reject(
          component.components,
          (deepComponent) => {
            const isCustomField = _.startsWith(
              deepComponent.type,
              'peopleCustomField'
            );

            const matchingCustomFieldExists = _.includes(
              existingCustomFields,
              _.get(deepComponent, 'churchdesk.people.componentType')
            );

            return isCustomField && !matchingCustomFieldExists;
          }
        );
      });
    }
  };
  prepareFormBuilder.$inject = [
    '$state',
    'Authorization',
    'formObject',
    'peopleStaticFields',
    'peopleStaticFieldsOptions',
    'peopleCustomFields',
    'consentTypes',
    'newsletters',
    'formioCustomService',
    'formioComponents',
    'gettextCatalog',
    '$window',
  ];

  angular.module('cdApp').config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('app.private.forms', {
          abstract: true,
          url: '/forms',
          template: `
            <cd-app-nav class="app-nav"></cd-app-nav>
            <div class="app-view" ui-view></div>
          `,
        })
        .state('app.private.forms.default', {
          url: '',
          component: 'cdrProductLandingPage',
          params: {
            errorCode: null,
            packageName: 'forms',
          },

          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Forms');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base)
                  ),
                };
              },
            ],

            errorCode: [
              '$stateParams',
              ($stateParams) => $stateParams.errorCode,
            ],
          },

          onEnter: [
            '$state',
            'errorCode',
            async function ($state, errorCode) {
              if (!errorCode) {
                return $state.go('app.private.forms.list');
              }
            },
          ],
        })
        .state('app.private.forms.create', {
          url: '/new?step',
          component: 'cdFormBuilder',
          params: {
            step: {
              value: 'builder',
              dynamic: true,
            },
          },
          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Create form');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base)
                  ),
                };
              },
            ],
            formObject: [
              'Forms',
              function (Forms) {
                return Forms.init();
              },
            ],
            formResponses() {
              return null;
            },
            peopleTags: [
              'PeopleTags',
              function (PeopleTags) {
                return PeopleTags.query().$promise;
              },
            ],
            peopleStaticFields: [
              'People',
              function (People) {
                return People.getStaticFields().$promise;
              },
            ],
            peopleStaticFieldsOptions: [
              'People',
              function (People) {
                return People.getStaticFieldsOptions().$promise;
              },
            ],
            newsletters: [
              'PeopleSegments',
              function (PeopleSegments) {
                return PeopleSegments.getPublicNewsletterLists().$promise;
              },
            ],
            peopleCustomFields: [
              'PeopleCustomFields',
              function (PeopleCustomFields) {
                return PeopleCustomFields.get().$promise;
              },
            ],
            consentTypes: [
              'ConsentType',
              function (ConsentType) {
                return ConsentType.query().$promise;
              },
            ],
            previousState: [
              '$state',
              function ($state) {
                return $state.current.name
                  ? { name: $state.current.name, params: $state.params }
                  : null;
              },
            ],
          },
          onEnter: prepareFormBuilder,
        })

        .state('app.private.forms.edit', {
          url: '/:id/edit?step',
          component: 'cdFormBuilder',
          params: {
            step: {
              value: 'builder',
              dynamic: true,
            },
          },

          resolve: {
            $title: [
              'gettextCatalog',
              'routingHelpers',
              'formObject',
              function (gettextCatalog, routingHelpers, formObject) {
                const base = 'Edit form';
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString('Edit {{ formTitle }}', {
                      formTitle: formObject.title,
                    })
                  ),
                };
              },
            ],

            formObject: [
              '$stateParams',
              'Forms',
              function ($stateParams, Forms) {
                return Forms.get({ id: $stateParams.id }).$promise;
              },
            ],

            formResponses: [
              '$stateParams',
              'FormResponses',
              function ($stateParams, FormResponses) {
                return FormResponses.query({ formId: $stateParams.id })
                  .$promise;
              },
            ],

            peopleTags: [
              'PeopleTags',
              function (PeopleTags) {
                return PeopleTags.query().$promise;
              },
            ],

            peopleStaticFields: [
              'People',
              function (People) {
                return People.getStaticFields().$promise;
              },
            ],

            peopleStaticFieldsOptions: [
              'People',
              function (People) {
                return People.getStaticFieldsOptions().$promise;
              },
            ],

            newsletters: [
              'PeopleSegments',
              function (PeopleSegments) {
                return PeopleSegments.getPublicNewsletterLists().$promise;
              },
            ],

            peopleCustomFields: [
              'PeopleCustomFields',
              function (PeopleCustomFields) {
                return PeopleCustomFields.get().$promise;
              },
            ],

            consentTypes: [
              'ConsentType',
              function (ConsentType) {
                return ConsentType.query().$promise;
              },
            ],

            previousState: [
              '$state',
              function ($state) {
                return $state.current.name
                  ? { name: $state.current.name, params: $state.params }
                  : null;
              },
            ],
          },

          onEnter: prepareFormBuilder,
        })
        .state('app.private.forms.list', {
          url: '/list/{active:open|closed}?{labelId:string}&{myForms:bool}&{churches:int}',
          params: {
            active: { value: 'open', dynamic: true },
            labelId: { value: null, dynamic: true },
            myForms: { value: true, dynamic: true },
            churches: { value: [], dynamic: true, array: true },
          },
          component: 'formList',
          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('All forms');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base)
                  ),
                };
              },
            ],
          },
          onEnter: checkAccessToModule,
        })
        .state('app.private.forms.labels', {
          url: '/labels',
          component: 'cdrFormLabels',
          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Labels');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base)
                  ),
                };
              },
            ],
          },
          onEnter: checkAccessToModule,
        })
        .state('app.private.forms.view', {
          url: '/:id',
          component: 'cdViewForm',
          resolve: {
            formObject: [
              '$stateParams',
              'Forms',
              function ($stateParams, Forms) {
                return Forms.get({ id: $stateParams.id }).$promise;
              },
            ],

            formResponses: [
              '$stateParams',
              'FormResponses',
              function ($stateParams, FormResponses) {
                return FormResponses.query({ formId: $stateParams.id })
                  .$promise;
              },
            ],

            $title: [
              'routingHelpers',
              'formObject',
              function (routingHelpers, formObject) {
                const base = 'Form detail';
                return {
                  base,
                  rendered: routingHelpers.buildTitle(formObject.title),
                };
              },
            ],
          },

          onEnter: checkAccessToModule,
        })
        .state('app.private.forms.responses', {
          url: '/:formId/responses/:responseId',
          component: 'cdViewFormResponses',
          params: {
            form: null,
            formResponses: null,
            staticFields: null,
            staticFieldsOptions: null,
          },

          resolve: {
            $title: [
              'routingHelpers',
              'gettextCatalog',
              '$stateParams',
              function (routingHelpers, gettextCatalog, $stateParams) {
                const base = 'Form response';
                const formTitle = _.get($stateParams.form, 'title');
                const title = !formTitle
                  ? gettextCatalog.getString('Responses to form')
                  : gettextCatalog.getString('Responses to {{ formTitle }}', {
                      formTitle,
                    });

                return { base, rendered: routingHelpers.buildTitle(title) };
              },
            ],
          },

          onEnter: checkAccessToModule,
        })
        .state('app.private.forms.editResponse', {
          url: '/:formId/responses/:responseId/edit',
          component: 'cdEditFormResponse',
          resolve: {
            formObject: [
              '$stateParams',
              'Forms',
              function ($stateParams, Forms) {
                return Forms.get({ id: $stateParams.formId, action: 'edit' })
                  .$promise;
              },
            ],

            formResponse: [
              '$stateParams',
              'FormResponses',
              function ($stateParams, FormResponses) {
                return FormResponses.get({
                  id: $stateParams.responseId,
                  formId: $stateParams.formId,
                }).$promise;
              },
            ],

            userPermissions: [
              'Users',
              'Me',
              function (Users, Me) {
                return Users.getPermissions({ id: Me.id }).$promise;
              },
            ],

            peopleStaticFields: [
              'People',
              function (People) {
                return People.getStaticFields().$promise;
              },
            ],

            peopleStaticFieldsOptions: [
              'People',
              function (People) {
                return People.getStaticFieldsOptions().$promise;
              },
            ],

            newsletters: [
              'PeopleSegments',
              function (PeopleSegments) {
                return PeopleSegments.getPublicNewsletterLists().$promise;
              },
            ],

            peopleCustomFields: [
              'PeopleCustomFields',
              function (PeopleCustomFields) {
                return PeopleCustomFields.get().$promise;
              },
            ],

            consentTypes: [
              'ConsentType',
              function (ConsentType) {
                return ConsentType.query().$promise;
              },
            ],

            $title: [
              'routingHelpers',
              'gettextCatalog',
              'formObject',
              function (routingHelpers, gettextCatalog, formObject) {
                const base = 'Edit response';
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString('Edit form response', {
                      formTitle: formObject.title,
                    })
                  ),
                };
              },
            ],
          },

          onEnter: prepareFormBuilder,
        })
        .state('app.public.cancelFreeTicket', {
          url: '/forms/:formId/responses/:submissionId/cancel?{organizationId:int}&{formName:string}&{token:string}&{lang:string}',
          component: 'cdCancelFreeTickets',
          resolve: {
            isPublic() {
              return true;
            },
          },

          onEnter: [
            '$stateParams',
            function ($stateParams) {
              return I18nService.setCurrentLanguage($stateParams.lang);
            },
          ],
        });
    },
  ]);
})();
