import React from 'react';
import { Button, Typography } from 'antd';

import { useReSubscribeToLists } from '../hooks/useReSubscribeToList';

import { List } from './MesssageSettings';

import { I18nService } from '@/react/services/I18nService';
import { showConfirmModalDeprecated } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';

export const ResubscribeToListsButton = ({
  personName,
  updateReceiveCommunicationFromFilters,
  communicateFromFilters,
  list,
  contactId,
}: {
  personName: string;
  updateReceiveCommunicationFromFilters?: () => void;
  communicateFromFilters: boolean;
  list?: List;
  contactId?: number;
}) => {
  const { reSubscribeToList } = useReSubscribeToLists(contactId);

  const confirmResubscribeToListsModal = () =>
    showConfirmModalDeprecated({
      title: I18nService.getString('Resubscribe'),
      message: messageContent(
        personName,
        communicateFromFilters,
        list?.listName
      ),
      okType: 'primary',
      okText: I18nService.getString('Resubscribe'),
      onOk: async () => {
        if (!communicateFromFilters) {
          await updateReceiveCommunicationFromFilters();
        } else {
          await reSubscribeToList({ listId: list.listId, personId: contactId });
        }
      },
    });

  return (
    <Button type="link" onClick={() => confirmResubscribeToListsModal()}>
      {I18nService.getString('Resubscribe')}
    </Button>
  );
};

const messageContent = (
  personName: string,
  communicateFromFilters: boolean,
  listName: string
) => {
  const text = I18nService.getString(
    ' @@name@@ will receive messages from the list @@listName@@ again.',
    {
      name: '@@name@@',
      listName: '@@listName@@',
    }
  );
  const [prefix, rest] = text.split('@@name@@');
  const [middle, suffix] = rest.split('@@listName@@');
  const boldPersonName = <Typography.Text strong>{personName}</Typography.Text>;
  const boldListName = (
    <Typography.Text strong>
      {communicateFromFilters
        ? listName
        : 'All practical email-based information'}
    </Typography.Text>
  );
  return (
    <span>
      {prefix}
      {boldPersonName}
      {middle}
      {boldListName}
      {suffix}
    </span>
  );
};
