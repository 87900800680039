import { Button, Modal, Form } from 'antd';
import React, { FunctionComponent, useEffect } from 'react';
import { sagaModal, destroyModal } from 'redux-saga-modal';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import MODAL_TYPES from '../../redux/modal-types';
import { I18nService } from '../../../services/I18nService';
import { useFetchUserRoles } from '../../screens/hooks';
import { getUserOldRoles } from '../../redux/selectors';

import AssignUserPermissions from './AssignUserPermissions';

const UserPermissionsModal: FunctionComponent<{
  isOpen: boolean;
  userId: number;
}> = ({ isOpen, userId }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const userOldRoles = useSelector(getUserOldRoles);

  const fetchUserOldRoles = useFetchUserRoles(userId);
  // This loads to much information!
  useEffect(() => {
    if (!isOpen) return null;
    fetchUserOldRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (isEmpty(userOldRoles)) return;
    form.setFieldsValue({ oldRoles: userOldRoles });
  }, [userOldRoles, form]);

  const closeModal = () => {
    dispatch(destroyModal(MODAL_TYPES.USER_ROLES_SUMMARY));
  };

  return (
    <Modal
      open={true}
      width="70vw"
      onCancel={closeModal}
      footer={
        <Button onClick={closeModal}>{I18nService.getString('Cancel')}</Button>
      }
    >
      <div style={{ maxHeight: '65vh', overflowY: 'auto' }}>
        <Form form={form} layout="vertical">
          <AssignUserPermissions fieldsDisabled={true} />
        </Form>
      </div>
    </Modal>
  );
};

export default sagaModal({
  // A unique name for the modal
  name: MODAL_TYPES.USER_ROLES_SUMMARY,
})(UserPermissionsModal);
