/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PutPortalWidgetsRequestBody = {
    name: string;
    type: PutPortalWidgetsRequestBody.type;
    configuration: any;
};
export namespace PutPortalWidgetsRequestBody {
    export enum type {
        SEARCH = 'search',
        TEASER = 'teaser',
    }
}

