/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PutPartnerRequestBody = {
    name: string;
    machineName: string;
    country: string;
    uri: string;
    categoryUrl: string;
    visibility: PutPartnerRequestBody.visibility;
};
export namespace PutPartnerRequestBody {
    export enum visibility {
        BOTH = 'both',
        GROUP = 'group',
        WEB = 'web',
    }
}

