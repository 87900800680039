import React from 'react';
import CountryPhoneInput, {
  ConfigProvider,
  CountryPhoneInputProps,
} from 'antd-country-phone-input';
import styled from 'styled-components';
import {
  CountryCode,
  getCountryCallingCode,
  parsePhoneNumber,
} from 'libphonenumber-js';

import CdCountryFlag from './cd-country-flag/CdCountryFlag';

import { getCountryList } from '@/react/services/locale/country.service';
import cdApp from '@/react/config';

const StyledComponent = styled.div`
  .antd-country-phone-input {
    .ant-select-selection-item {
      display: flex;
      flex-direction: row;
    }
  }
`;
/**
 * This can be used together with Antd Form to set the initial value of the CdPhoneInput field showing a relevant flag.
 */
export const getInitialValueCdPhoneInput = () => {
  const currentCountry = cdApp?.organization.countryIso2.toUpperCase();
  const defaultShort = ['DK', 'DE', 'FR', 'NO', 'GB'].includes(currentCountry)
    ? currentCountry
    : undefined;
  return {
    short: defaultShort,
  };
};

/**
 * This function is used to get the string value of the phone number from the CdPhoneInput field.
 * @param input
 * @returns
 */
export const getStringCdPhoneInput = (input: {
  phone: string;
  short: string;
  code: number;
}) => '+' + input.code + input.phone;

/**
 * Formats a phone number to the input value required for the CdPhoneInput field.
 * Usefull for when setting the field value from an external source.
 *
 * @param number - The phone number .
 * @param country - Optional. The country code (ISO 3166-1 alpha-2) to use for formatting.
 * @param countryCallingCode - Optional. The country calling code to use for formatting.
 * @returns An object required by the CdPhoneInput field containing the formatted phone number, the country code, and the country calling code.
 */
export const formatCdPhoneInputValue = (
  number: string,
  country?: string,
  countryCallingCode?: number
) => {
  const phoneNumber = parsePhoneNumber(
    number,
    country?.toUpperCase() as CountryCode
  );
  const short = country || phoneNumber?.country;
  const code =
    countryCallingCode || country
      ? parseInt(
          getCountryCallingCode(country.toUpperCase() as CountryCode),
          10
        )
      : phoneNumber.countryCallingCode;

  return { phone: number, short: short?.toUpperCase(), code };
};

export const CdPhoneInput = (props: CountryPhoneInputProps) => (
  <ConfigProvider
    locale={getCountryList()}
    areaMapper={(area) => ({
      ...area,
      emoji: (
        <CdCountryFlag
          countryIso2={area.short.toLowerCase()}
          style={{ marginRight: '5px', alignSelf: 'center' }}
        />
      ),
    })}
  >
    <StyledComponent>
      <CountryPhoneInput inline {...props} />
    </StyledComponent>
  </ConfigProvider>
);

export interface CdPhone {
  phone?: string;
  code?: number; // eg. 45
  short: CountryCode; // Capitalized countryIso2
}
