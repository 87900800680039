import React, { PropsWithChildren, useEffect } from 'react';
import { Collapse, Space, Spin, Typography } from 'antd';
import { isNull } from 'lodash';

import { createCdDrawer } from '../../../shared/components/cd-drawer/CdDrawer';
import { ModalType } from '../../../shared/components/cd-modal-new/CdModal2';
import { I18nService } from '../../../services/I18nService';
import { useSingleEnquiry } from '../hooks/use-enquiries';
import { useEnquiriesPage } from '../hooks/use-enquiries-page';
import { COLOR_INDEX } from '../../../../app/ResourceColors';
import {
  CdCircleFilled,
  CdExclamationTriangleIcon,
} from '../../../shared/components/Icons';
import { CdVerticalSpace } from '../../../shared/components/cd-vertical-space/CdVerticalSpace';
import { routes } from '../../../shared/routes';

import { FormSubmissionViewer } from './FormSubmissionViewer';
import { DateIntervalRender } from './DateIntervalRender';
import { EnquiryStatusIcon } from './EnquiryStatus';

export const showEnquiryDrawer = createCdDrawer<{ id: string }>(
  ({ id, setDrawerProps }) => {
    const { approveEnquiry, declineEnquiry } = useEnquiriesPage();
    const query = useSingleEnquiry(id);
    const enquiry = query.data;

    useEffect(
      () =>
        setDrawerProps({
          modalType: ModalType.POPUP,
          okText: I18nService.getString('Approve', null, 'Enquiry'),
          alternativeButtonText: I18nService.getString(
            'Decline',
            null,
            'Enquiry'
          ),
          hideOk: enquiry?.status !== 'requested',
          hideCancel: true,
          onOk: () => approveEnquiry(id),
          onAlternative: () => declineEnquiry(id),
        }),
      [approveEnquiry, declineEnquiry, enquiry?.status, id, setDrawerProps]
    );

    return (
      <Spin spinning={query.isLoading}>
        {enquiry && (
          <>
            <Typography.Title style={{ marginTop: 0 }}>
              {I18nService.getString('Enquiry for {{type}}', {
                type: enquiry.taxonomy.name,
              })}
            </Typography.Title>
            <DateIntervalRender
              startDate={enquiry.startDate}
              endDate={enquiry.endDate}
            />
            <Section title={I18nService.getString('Status')}>
              <CdVerticalSpace>
                <EnquiryStatusIcon status={enquiry?.status} />
                {enquiry?.status === 'accepted' &&
                  (enquiry?.calendar?.id ? (
                    <Typography.Link
                      href={routes.calendar.eventDrawer(enquiry?.calendar?.id)}
                    >
                      {I18nService.getString('Go to event')}
                    </Typography.Link>
                  ) : (
                    <Typography.Text type="warning">
                      <Space>
                        <CdExclamationTriangleIcon />
                        {I18nService.getString(
                          'Associated event has been deleted'
                        )}
                      </Space>
                    </Typography.Text>
                  ))}
              </CdVerticalSpace>
            </Section>
            {enquiry?.comment && (
              <Section
                title={I18nService.getString('Response sent to requester')}
              >
                <Typography.Paragraph
                  className="pre-line"
                  ellipsis={{
                    rows: 2,
                    expandable: true,
                    symbol: I18nService.getString('Read more'),
                  }}
                >
                  {enquiry.comment}
                </Typography.Paragraph>
              </Section>
            )}
            <Section title={I18nService.getString('Booking option')}>
              <CdVerticalSpace size={0}>
                <Typography.Text>{enquiry.bookingOption.name}</Typography.Text>
                {enquiry.resources?.map((resource) => (
                  <Space key={resource.id}>
                    {!isNull(resource.color) && (
                      <CdCircleFilled
                        style={{
                          color: COLOR_INDEX[resource.color],
                        }}
                      />
                    )}
                    {resource.name}
                  </Space>
                ))}
              </CdVerticalSpace>
            </Section>
            {enquiry.users?.length > 0 && (
              <Section title={I18nService.getString('Users')}>
                {enquiry.users
                  .map((user) => user.contact?.fullName || user.email)
                  .join(', ')}
              </Section>
            )}
            <Section>
              {enquiry.followUpSubmissionData ||
              enquiry.taxonomy.config.automaticFollowUpForm ? (
                <Collapse
                  defaultActiveKey={['initialSubmission']}
                  items={[
                    {
                      key: 'initialSubmission',
                      label: I18nService.getString('Enquiry'),
                      children: (
                        <FormSubmissionViewer
                          submissionData={enquiry?.submissionData}
                        />
                      ),
                    },
                    {
                      key: 'followUpSubmission',
                      label: (
                        <Space>
                          {I18nService.getString('Follow-up')}
                          {!enquiry?.followUpSubmissionData && (
                            <Typography.Text type="warning">
                              <Space>
                                <CdExclamationTriangleIcon />
                                {I18nService.getString('Not received yet')}
                              </Space>
                            </Typography.Text>
                          )}
                        </Space>
                      ),
                      children: enquiry?.followUpSubmissionData ? (
                        <FormSubmissionViewer
                          submissionData={enquiry.followUpSubmissionData}
                        />
                      ) : (
                        <Typography.Text type="secondary">
                          {I18nService.getString(
                            'No follow-up submission received'
                          )}
                        </Typography.Text>
                      ),
                    },
                  ]}
                  style={{ marginTop: 20 }}
                />
              ) : (
                <div style={{ marginTop: 20 }}>
                  <FormSubmissionViewer
                    submissionData={enquiry?.submissionData}
                  />
                </div>
              )}
            </Section>
          </>
        )}
      </Spin>
    );
  }
);

const Section = (props: PropsWithChildren & { title?: string }) => (
  <>
    {props.title && (
      <Typography.Title level={3}>{props.title}</Typography.Title>
    )}
    {props.children}
  </>
);
