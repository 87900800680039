/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChurchscreenBlogCategoriesResponseBody } from '../models/ChurchscreenBlogCategoriesResponseBody';
import type { DeleteBlogsBlogidCommentsIdRequestBody } from '../models/DeleteBlogsBlogidCommentsIdRequestBody';
import type { EmptyObject } from '../models/EmptyObject';
import type { GetBlogsResponseBody } from '../models/GetBlogsResponseBody';
import type { PostBlogsBlogidCommentsRequestBody } from '../models/PostBlogsBlogidCommentsRequestBody';
import type { PostBlogsRequestBody } from '../models/PostBlogsRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class BlogsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetBlogs
     * Index
     * @returns GetBlogsResponseBody
     * @throws ApiError
     */
    public getBlogs({
        groupId,
        limit,
        offset,
        search,
        cid,
        order,
        orderField,
        imageRequire,
        imageFormat,
    }: {
        groupId?: number,
        limit?: number,
        offset?: number,
        search?: string,
        cid?: Array<number>,
        order?: string,
        orderField?: string,
        imageRequire?: boolean,
        imageFormat?: string,
    }): CancelablePromise<GetBlogsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/blogs',
            query: {
                'groupId': groupId,
                'limit': limit,
                'offset': offset,
                'search': search,
                'cid': cid,
                'order': order,
                'orderField': orderField,
                'imageRequire': imageRequire,
                'imageFormat': imageFormat,
            },
        });
    }
    /**
     * PostBlogs
     * Create
     * @returns EmptyObject
     * @throws ApiError
     */
    public postBlogs({
        requestBody,
    }: {
        requestBody?: PostBlogsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/blogs',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * ChurchscreenBlogCategories
     * Categories, Index
     * @returns ChurchscreenBlogCategoriesResponseBody Success-Response: Success
     * @throws ApiError
     */
    public getBlogsCategories({
        partnerToken,
    }: {
        /**
         * Partner API identifier.
         */
        partnerToken: any,
    }): CancelablePromise<ChurchscreenBlogCategoriesResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/blogs/categories',
            query: {
                'partnerToken': partnerToken,
            },
        });
    }
    /**
     * PostBlogsBlogidComments
     * Comment Create
     * @returns EmptyObject
     * @throws ApiError
     */
    public postBlogsComments({
        blogId,
        requestBody,
    }: {
        blogId: number,
        requestBody?: PostBlogsBlogidCommentsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/blogs/{blogId}/comments',
            path: {
                'blogId': blogId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeleteBlogsBlogidCommentsId
     * Comment Delete
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteBlogsComments({
        blogId,
        id,
    }: {
        blogId: number,
        id: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/blogs/{blogId}/comments/{id}',
            path: {
                'blogId': blogId,
                'id': id,
            },
        });
    }
    /**
     * PutBlogsBlogidCommentsId
     * Comment Update
     * @returns EmptyObject
     * @throws ApiError
     */
    public putBlogsComments({
        blogId,
        id,
        requestBody,
    }: {
        blogId: number,
        id: number,
        requestBody?: DeleteBlogsBlogidCommentsIdRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/blogs/{blogId}/comments/{id}',
            path: {
                'blogId': blogId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeleteBlogsId
     * Delete
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteBlogs({
        id,
    }: {
        id: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/blogs/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetBlogsId
     * Retrieve
     * @returns EmptyObject
     * @throws ApiError
     */
    public getBlogs1({
        id,
        imageFormat,
    }: {
        id: number,
        imageFormat?: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/blogs/{id}',
            path: {
                'id': id,
            },
            query: {
                'imageFormat': imageFormat,
            },
        });
    }
    /**
     * PutBlogsId
     * Update
     * @returns EmptyObject
     * @throws ApiError
     */
    public putBlogs({
        requestBody,
    }: {
        requestBody?: PostBlogsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/blogs/{id}',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
