import AuthorizationService from '../../services/AuthorizationService';
import { I18nService } from '../../services/I18nService';
import { BackendEventVisibility, ClientEventVisibility } from '../types/event';

export const getVisibilityOptions = () =>
  [
    {
      value: ClientEventVisibility.PUBLIC,
      label: I18nService.getString('Public'),
      visible: AuthorizationService.hasPermission('canSetVisibilityToPublic'),
    },
    {
      value: ClientEventVisibility.INTERNAL_ALL,
      label: I18nService.getString('All users'),
      visible: AuthorizationService.hasPermission(
        'canSetVisibilityToInternalAll'
      ),
    },
    {
      value: ClientEventVisibility.INTERNAL_GROUPS,
      label: I18nService.getString('Groups'),
      visible: AuthorizationService.hasPermission(
        'canSetVisibilityToInternalGroup'
      ),
    },
    {
      value: ClientEventVisibility.PRIVATE,
      label: I18nService.getString('Private'),
      visible: true,
    },
  ].filter((option) => option.visible);

export const getInitialEventVisibility = () =>
  AuthorizationService.hasPermission('canSetVisibilityToInternalAll')
    ? ClientEventVisibility.INTERNAL_ALL
    : !AuthorizationService.hasPermission('canSetVisibilityToInternalAll') &&
        AuthorizationService.hasPermission('canSetVisibilityToInternalGroup')
      ? ClientEventVisibility.INTERNAL_GROUPS
      : ClientEventVisibility.PRIVATE;

export const clientToBackendVisibility = (visibility: ClientEventVisibility) =>
  visibility === ClientEventVisibility.INTERNAL_GROUPS ||
  visibility === ClientEventVisibility.INTERNAL_ALL
    ? BackendEventVisibility.INTERNAL
    : (visibility as unknown as BackendEventVisibility);
