import { useState } from 'react';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import {
  getAnniversarySettings,
  getAnniversaryUsersToNotify,
} from '../store/anniversary-settings';
import { AnniversaryInterface } from '../../../calendar/types/anniversaryTypes';

import cdApp from '@/react/config';
import { useFlag } from '@/react/shared/hooks/useFlag';
export default function useAnniversary() {
  const anniversaryFilterUpgrade = useFlag('anniversary_filter_upgrade');
  const [selectedContactAnniversaries, setSelectedContactAnniversaries] =
    useState<AnniversaryInterface[]>([]);
  const [anniversaryType, setAnniversaryType] = useState<string>(
    localStorage.getItem(
      `churchdesk.anniversaryType.${cdApp.organization?.id}`
    ) || 'allAnniversaries'
  );

  const { data } = useAnniversarySettingsData({
    includeAllAnniversaryType: true,
  });
  const anniversaryTypes = [];
  for (const type in data?.anniversariesTypeSetting) {
    if (data?.anniversariesTypeSetting[type].checked) {
      anniversaryTypes.push({
        label: data?.anniversariesTypeSetting[type].label,
        value: type,
      });
    }
  }
  return {
    anniversaryType,
    anniversaryTypes,
    setAnniversaryType,
    accessDenied: data?.accessDenied,
    selectedContactAnniversaries,
    setSelectedContactAnniversaries,
    anniversaryFilterUpgrade,
  };
}
type AnniversarySettingsKey = [
  string,
  { includeAllAnniversariesField: boolean },
];
type AnniversaryUsersToNotifyKey = [string, { parishIds: number[] }];

export const useAnniversarySettings = () => {
  const anniversarySettings = useAnniversarySettingsData({
    includeAllAnniversaryType: false,
  });
  const loading = anniversarySettings.isLoading;

  const preparedAnniversaryTypes = () => {
    const allAnniversarryType = [];
    for (const type in anniversarySettings?.data?.anniversariesTypeSetting) {
      allAnniversarryType.push({
        label: anniversarySettings?.data?.anniversariesTypeSetting[type].label,
        value: type,
        specialDaysList:
          anniversarySettings.data?.anniversariesTypeSetting[type].selected,
      });
    }
    return allAnniversarryType;
  };

  return {
    loading,
    anniversaryTypes: preparedAnniversaryTypes(),
  };
};
export const usePeopleSettingsAnniversaryData = (organizationSettings) => {
  const anniversarySettings = useAnniversarySettingsData({
    includeAllAnniversaryType: false,
  });
  const anniversariesTypeSettingValues = {};
  if (anniversarySettings && organizationSettings) {
    for (const type in anniversarySettings.data?.anniversariesTypeSetting) {
      anniversariesTypeSettingValues[type] = {
        checked:
          anniversarySettings.data?.anniversariesTypeSetting[type].checked,
        selected:
          anniversarySettings.data?.anniversariesTypeSetting[type].selected,
      };
    }
    organizationSettings = {
      ...organizationSettings,
      anniversariesTypeSetting: anniversariesTypeSettingValues,
      usersToNotify: anniversarySettings?.data?.usersToNotify,
    };
  }
  return { settings: organizationSettings };
};
export const useAnniversarySettingsData = ({
  includeAllAnniversaryType,
}: { includeAllAnniversaryType: boolean }) =>
  useSuspenseQuery({
    queryKey: [
      'useAnniversarySettings',
      { includeAllAnniversariesField: includeAllAnniversaryType },
    ] as AnniversarySettingsKey,
    queryFn: async () =>
      await getAnniversarySettings({
        includeAllAnniversariesField: includeAllAnniversaryType,
      }),
  });

export const useAvailabeleUsersToNotifyForPoepleSettings = (
  parishIds: number[]
) =>
  useQuery({
    queryKey: [
      'useAvailableUsersToNotifyForPeopleSettings',
      { parishIds: parishIds },
    ] as AnniversaryUsersToNotifyKey,
    queryFn: async () => await getAnniversaryUsersToNotify(parishIds),
  });

export const useAnniversaryNotifyUsers = (props) => {
  const isMultiParish = cdApp.organization.churches.length > 1;
  const [keywordToSearchUsersByName, setKeywordToSearchusersByName] =
    useState('');

  const [parishIds, setParishIds] = useState<number[]>([]);
  const usersToNotifySettings =
    useAvailabeleUsersToNotifyForPoepleSettings(parishIds);
  const loading = usersToNotifySettings.isLoading;
  const handleChange = (value) => {
    props.onChange([...props.value, value]);
  };
  const usersOptions = () => {
    const users = usersToNotifySettings.data;
    return users?.filter((u) => !props.value?.includes(u.id));
  };
  const showUser = () => {
    const users = usersToNotifySettings.data;
    return users?.filter((u) => props.value?.includes(u.id));
  };
  const handleRemoveUser = (userId) => {
    props.onChange(props.value?.filter((id) => id !== userId));
  };
  return {
    isMultiParish,
    usersOptions: usersOptions(),
    showUsers: showUser(),
    handleRemoveUser,
    keywordToSearchUsersByName,
    setKeywordToSearchusersByName,
    parishIds,
    setParishIds,
    handleChange,
    loading,
  };
};
