import { Button, ButtonProps } from 'antd';
import React from 'react';

import { CdTooltip } from '../cd-tooltip/CdTooltip';

export const CdRoundIconButton = (
  props: Pick<ButtonProps, 'icon' | 'size' | 'onClick'> & { tooltip?: string }
) => (
  <CdTooltip title={props.tooltip}>
    <Button
      type="text"
      shape="round"
      style={{
        width: 0,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'inline-flex',
      }}
      {...props}
    />
  </CdTooltip>
);
