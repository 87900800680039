import { mainApi } from '../../api';
import { InterfaceGroups } from '../../organization/services/Groups.service';

import { CdQueryDefinition } from './types';

export const groupQueries = {
  getAll: () => ({
    queryKey: ['groups'],
    queryFn: async () => {
      const res = await mainApi.get<InterfaceGroups[]>('/groups');
      if (res.ok) {
        return res.data;
      }
      throw res;
    },
  }),
} satisfies CdQueryDefinition;
