import { useCallback } from 'react';
import { Form } from 'antd';

import { TagInterface } from '@/react/people/types/tag.type';
import { I18nService } from '@/react/services/I18nService';
import { useCreateTagFilter } from '@/react/shared/hooks/useTags';

export const useCreateFilter = ({ data }: { data: TagInterface }) => {
  const [createFilterForm] = Form.useForm();

  const formInitialValue = {
    name: '',
    tagName: `${I18nService.getString('Tags is')} ${data.name}`,
  };

  const { createTagFilter } = useCreateTagFilter();
  const onCreateFilter = useCallback(async () => {
    await createFilterForm.validateFields().then(async (value) => {
      createTagFilter({
        filterName: value.name,
        data,
      });
    });
  }, [createFilterForm, data, createTagFilter]);

  return {
    onCreateFilter,
    createFilterForm,
    formInitialValue,
  };
};
