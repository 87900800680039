import AuthorizationService from '../../services/AuthorizationService';

import { CdQueryDefinition } from './types';

export const aclQueries = {
  hasPrivilege: (privilege: string) => ({
    queryKey: ['useHasPrivilege', privilege],
    queryFn: () => AuthorizationService.hasPrivilege(privilege),
    staleTime: Infinity,
  }),
} satisfies CdQueryDefinition;
