import React, { useEffect, useCallback, useRef, useState } from 'react';
import { FormInstance } from 'antd';
import EmailEditor from 'react-email-editor';
import { useSetRecoilState } from 'recoil';
import NiceModal from '@ebay/nice-modal-react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { MessageAttribute } from '../shared/containers/Redactor';

import { unlayerLoadingCSS, unlayerLoadingHTML } from './UnlayerIframeSpinner';

import { I18nService } from '@/react/services/I18nService';
import NotificationService from '@/react/services/NotificationService';
import { EntityType, PeopleMessageFile } from '@/react/people/types/message';
import { AttachFileInterface } from '@/react/people/hooks/useMessage';
import { unlayerControlState } from '@/react/people/store/message';
import { FilePickerComponentProps } from '@/react/calendar/file-picker-library/FilePickerComponent';
import cdApp from '@/react/config';
import { messageAttributesQueries } from '@/react/shared/queries/people-messages/messageAttributes';

interface IUnlayerEditorData {
  body: any;
  counters: any;
  schemaVersion: number;
}

interface UnlayerEditorProps {
  value?: IUnlayerEditorData;
  onChange?: (data: any) => void;
  attachFile: (props: AttachFileInterface) => Promise<PeopleMessageFile>;
  form: FormInstance;
  displayMode: 'email' | 'web';
  enableVideoPreview?: boolean;
  entityType?: EntityType;
}

export const UnlayerEditor = ({
  value,
  onChange,
  form,
  attachFile,
  enableVideoPreview = true,
  displayMode = 'email',
  entityType = EntityType.MESSAGE,
}: UnlayerEditorProps) => {
  const emailEditorRef = useRef(null);
  const [hasValueBeenSet, setValueBeenSet] = useState<boolean>(false);
  const [editorReady, setEditorReady] = useState<boolean>(false);
  const setUnlayerControlState = useSetRecoilState(unlayerControlState);

  const onEditorChange = useCallback(() => {
    emailEditorRef.current.editor.exportHtml(function (data) {
      form.setFieldsValue({ rendered: data.html });
      onChange(data.design);
    });
    // When adding more variables to the deps array it requires us to re-add the event listener multiple times.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailEditorRef]);

  const setDefaultValue = useCallback((content) => {
    const unlayer = emailEditorRef?.current?.editor;
    if (unlayer && content?.schemaVersion) {
      unlayer?.loadDesign(content);
      setValueBeenSet(true);
    }
  }, []);

  useEffect(() => {
    if (value && editorReady && !hasValueBeenSet) {
      // setInitialSetValueDelay(500);
      setDefaultValue(value);
    }
  }, [value, editorReady, hasValueBeenSet, setDefaultValue]);

  useEffect(() => {
    setUnlayerControlState({ setDefaultValue });

    // On component unmount
    return () => {
      setUnlayerControlState(null);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const unlayer = emailEditorRef?.current?.editor;
      unlayer && unlayer.removeEventListener('design:updated', onEditorChange);
    };
  }, [emailEditorRef, onEditorChange, setDefaultValue, setUnlayerControlState]);

  const handleFileUpload = useCallback(
    (file, done) => {
      const onProgress = ({ percent }: { percent: number }) => {
        if (percent < 100) {
          done({ progress: percent });
        }
      };
      const onSuccess = (data: PeopleMessageFile) => {
        done({ progress: 100, url: data.url });
      };
      const onError = (e) => {
        done({ progress: 100, url: '' });
        NotificationService.notifySuccess(
          'Something went wrong uploading the file'
        );
        throw e;
      };
      // Unlayer sends file names without an extension. The following ensures that an extension is added.
      // An extension is required by the backend to pass validation.
      const fileFromUnlayer = file.attachments[0];
      const extension = mimeToExtension(fileFromUnlayer.type);
      const pos = fileFromUnlayer.name.lastIndexOf('.');
      const fileName =
        fileFromUnlayer.name.substr(
          0,
          pos < 0 ? fileFromUnlayer.name.length : pos
        ) + `.${extension}`;

      attachFile({
        file: fileFromUnlayer,
        fileName,
        onSuccess,
        onProgress,
        onError,
      });
    },
    [attachFile]
  );

  const messageAttributes = useSuspenseQuery(
    messageAttributesQueries.get(entityType)
  );
  const messageAttributesData = messageAttributes.data as MessageAttribute[];

  const selectImage = useCallback(async (data, done) => {
    // Open CD file archive
    await NiceModal.show<FilePickerComponentProps>('FilePickerComponentModal', {
      isUsedFromEditor: false,
    } as FilePickerComponentProps).then((result: any) => {
      if (displayMode === 'web') {
        // For posters we use the raw image file.
        done({
          url: `${cdApp.config.api.main}/files/${result.file.uuid}/view`,
        });
      } else {
        done({ url: result.file.fileInfo.large.url });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditorLoad = () => {
    const unlayer = emailEditorRef?.current?.editor;
    if (unlayer) {
      unlayer.addEventListener('design:updated', onEditorChange);
      unlayer.registerCallback('image', handleFileUpload);
      unlayer.registerCallback('selectImage', selectImage);
      setEditorReady(true);
    }
  };
  const transformAttributes = (attributes: MessageAttribute[]) =>
    attributes.reduce(
      (acc, attr) => ({
        ...acc,
        [attr.value]: {
          name: attr.label,
          value: attr.value,
          sample: attr.example,
        },
      }),
      {}
    );
  const mergeTags = transformAttributes(messageAttributesData);

  return (
    <>
      <EmailEditor
        ref={emailEditorRef}
        onReady={onEditorLoad}
        projectId={24058}
        options={{
          locale: getUnlayerLanguage(I18nService.getOrganizationLanguage()),
          displayMode,
          appearance: {
            theme: 'modern_light',
          },
          features: {
            stockImages: {
              enabled: true,
              safeSearch: true,
              defaultSearchTerm: 'flowers',
            },
            imageEditor: true,
            preheaderText: displayMode === 'email',
            preview: false,
            userUploads: false,
          },
          editor: {
            autoSelectOnDrop: true,
          },
          specialLinks: {
            unsub: {
              name: I18nService.getString('Unsubscribe'),
              href: '[unsubscribe:link]',
              target: '_blank',
            },
          },
          mergeTags: mergeTags,
        }}
        appearance={{
          loader: {
            html: unlayerLoadingHTML,
            css: unlayerLoadingCSS,
          },
        }}
        tools={{
          html: {
            enabled: false,
          },
          menu: {
            enabled: false,
          },
          video: {
            enabled: enableVideoPreview,
          },
        }}
      />
    </>
  );
};

const getUnlayerLanguage = (language: string) => {
  switch (language) {
    case 'da':
      return 'da-DA';
    case 'de':
      return 'de-DE';
    case 'sv':
      return 'sv-SE';
    default:
      return 'en-US';
  }
};

const mimeToExtension = (mime: string) => {
  const mimes = {
    'image/bmp': {
      extensions: ['bmp'],
    },
    'image/gif': {
      extensions: ['gif'],
    },
    'image/jpeg': {
      extensions: ['jpeg', 'jpg', 'jpe'],
    },
    'image/jpg': {
      extensions: ['jpeg', 'jpg', 'jpe'],
    },
    'image/png': {
      extensions: ['png'],
    },
    'image/webp': {
      extensions: ['webp'],
    },
    'image/x-icon': {
      extensions: ['ico'],
    },
  };
  return mimes[mime].extensions[0];
};
