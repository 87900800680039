/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PutIntentionFoundationsChangestatusRequestBody = {
    status: PutIntentionFoundationsChangestatusRequestBody.status;
    startYear?: number | null;
};
export namespace PutIntentionFoundationsChangestatusRequestBody {
    export enum status {
        INACTIVE = 'inactive',
        ACTIVE = 'active',
        COMPLETED = 'completed',
    }
}

