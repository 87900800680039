import React, { Suspense, useState, useCallback } from 'react';
import { Menu, Dropdown, Button, Input, Space } from 'antd';
import NiceModal from '@ebay/nice-modal-react';
import { BrowserRouter, useSearchParams } from 'react-router';

import CdClientSideTable from '../../shared/components/cd-client-side-table/CdClientSideTable';
import cdApp from '../../config';
import { Segment } from '../types/segment.type';
import { OrderType } from '../../shared/models/table';

import { showCreateNewsletterListModal } from './CreateNewsletterList';
import { showCreateParticipantList } from './CreateParticipantList';
import { ParishTagCollection } from './ParishTagCollection';

import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import { CdTabs } from '@/react/shared/components/cd-tabs/CdTabs';
import { I18nService } from '@/react/services/I18nService';
import { ColumnType } from '@/react/shared/components/cd-table';
import {
  CdSearch,
  CdFilterIcon,
  CdNewsletterListIcon,
  CdDeleteIcon,
  CdEyeIcon,
  CdDownloadIcon,
  CdEditIcon,
  CdUserPlusIcon,
  CdAngleDown,
  CdRenameFilter,
  CdUsers,
  CdParticipantList,
} from '@/react/shared/components/Icons';
import {
  NewsletterandFilterDataInterface,
  usePeopleLists,
} from '@/react/people/store/newsletterListState';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import { getStateUrl, navigate } from '@/react/services/StateServiceFactory';
import MessageListCreateDropdown from '@/react/people/message-editor/shared/containers/MessageListCreateDropdown';
import useNewsletterList, {
  SetUpType,
} from '@/react/people/hooks/useNewsletterList';
import { useHasPrivilege } from '@/react/user/store/permissions';
import { showConfirmModalDeprecated } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';

export const NewsletterList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchKeyWord, setSearchKeyWord] = useState('');
  const { deleteList } = useNewsletterList();
  const canCreateEditLists = useHasPrivilege(
    'organization.people_segment.edit'
  );
  const canDeleteLists = useHasPrivilege('organization.people_segment.delete');
  const canExport = useHasPrivilege('person.actions.export');

  const showRenameModal = (
    listId: number,
    listName: string,
    type: string,
    setup: object
  ) => {
    NiceModal.show('RenameNewsletterOrFilter', {
      listId,
      listName,
      type,
      setup,
    });
  };
  const showExportContactsFromNewsletterOrFilterModal = (setup: SetUpType) => {
    NiceModal.show('ExportContactsFromNewsletterOrFilter', {
      setup,
    });
  };
  const menu = () => (
    <Menu>
      <Menu.Item key="createfilter" icon={<CdFilterIcon fixedWidth />}>
        <a
          href={getStateUrl(`app.private.people.contacts.list`, {
            createList: true,
          })}
        >
          {I18nService.getString('Create filter')}
        </a>
      </Menu.Item>
      <Menu.Item
        key="createList"
        onClick={() => showCreateNewsletterListModal()}
        icon={<CdNewsletterListIcon fixedWidth />}
      >
        {I18nService.getString('Create newsletter list')}
      </Menu.Item>
      <Menu.Item
        key="createParticipantList"
        onClick={() => showCreateParticipantList()}
        icon={<CdParticipantList fixedWidth />}
      >
        {I18nService.getString('Create participant list')}
      </Menu.Item>
    </Menu>
  );

  const moreMenu = (
    id: number,
    name: string,
    type: string,
    setup: SetUpType,
    canAddPeopleToList: boolean,
    filter: NewsletterandFilterDataInterface
  ) => (
    <Menu key="moremenu">
      <Menu.Item key="seecontact" icon={<CdEyeIcon fixedWidth />}>
        <a href={getStateUrl(`app.private.people.contacts.list`, { list: id })}>
          {I18nService.getString('See contacts')}
        </a>
      </Menu.Item>
      {canCreateEditLists && (
        <Menu.Item
          key="rename"
          icon={<CdRenameFilter fixedWidth />}
          onClick={() => showRenameModal(id, name, type, setup)}
        >
          {I18nService.getString('Rename')}
        </Menu.Item>
      )}
      {type === 'filter' && canCreateEditLists && (
        <Menu.Item
          key="editFilters"
          icon={<CdEditIcon fixedWidth />}
          onClick={() =>
            navigate('app.private.people.contacts.list', {
              list: id,
              editing: true,
            })
          }
        >
          {I18nService.getString('Edit filter')}
        </Menu.Item>
      )}
      {type !== 'filter' && canAddPeopleToList && (
        <Menu.Item
          key="addcontacttolist"
          icon={<CdUserPlusIcon fixedWidth />}
          onClick={() => showAddContactsToListsModal(filter)}
        >
          {I18nService.getString('Add contacts in bulk')}
        </Menu.Item>
      )}

      {canExport && (
        <Menu.Item
          key="exportcontact"
          icon={<CdDownloadIcon fixedWidth />}
          onClick={() => showExportContactsFromNewsletterOrFilterModal(setup)}
        >
          {I18nService.getString('Export contacts')}
        </Menu.Item>
      )}
      {canDeleteLists && (
        <Menu.Item
          key="deletefilter"
          icon={<CdDeleteIcon fixedWidth />}
          onClick={() => confirmDeleteModal(id, name, type)}
        >
          {type === 'filter'
            ? I18nService.getString('Delete filter')
            : type === 'newsletter'
              ? I18nService.getString('Delete newsletter list')
              : I18nService.getString('Delete participant list')}
        </Menu.Item>
      )}
    </Menu>
  );

  const confirmDeleteModal = (listId: number, listName: string, type: string) =>
    showConfirmModalDeprecated({
      title: I18nService.getString(`Delete "{{listName}}"?`, {
        listName,
      }),
      message: I18nService.getString(
        `Do you want to delete "{{listName}}"? Deleting a list will not delete the contacts in the list.`,
        {
          listName,
        }
      ),
      icon: <CdDeleteIcon />,
      okType: 'danger',
      okText: I18nService.getString('Delete'),
      onOk: async () => {
        await deleteList(listId, type);
      },
    });

  const showAddContactsToListsModal = useCallback(
    (filter: NewsletterandFilterDataInterface) => {
      NiceModal.show('AddContactsToLists', { filter });
    },
    []
  );

  const tabItems = [
    {
      key: 'filter',
      icon: <CdFilterIcon />,
      title: I18nService.getString(`Filters`),
      subtitle: I18nService.getString(
        `Contacts that match a set of filter criteria. This is automatically updated`
      ),
      content: (
        <CdClientSideTable<Segment>
          noStyle
          columns={getColumns(moreMenu)}
          querySource={usePeopleLists}
          searchParams={{ type: 'filter', search: searchKeyWord }}
          emptyStateProps={{
            title: I18nService.getString('No lists found.'),
            EmptyStateIcon: <CdParticipantList />,
          }}
        />
      ),
    },
    {
      key: 'participant',
      icon: <CdParticipantList />,
      title: I18nService.getString('Participant Lists'),
      subtitle: I18nService.getString(
        'Easily manage lists of participants for events or classes'
      ),
      content: (
        <CdClientSideTable<Segment>
          noStyle
          querySource={usePeopleLists}
          searchParams={{ type: 'participant', search: searchKeyWord }}
          columns={getColumns(moreMenu, true)}
          emptyStateProps={{
            title: I18nService.getString('No lists found.'),
            EmptyStateIcon: <CdParticipantList />,
          }}
        />
      ),
    },
    {
      key: 'newsletter',
      icon: <CdNewsletterListIcon />,
      title: I18nService.getString('Newsletter Lists'),
      subtitle: I18nService.getString(
        'Let people easily sign-up to your newsletter lists from your website or forms.'
      ),
      content: (
        <CdClientSideTable<Segment>
          noStyle
          querySource={usePeopleLists}
          searchParams={{ type: 'newsletter', search: searchKeyWord }}
          columns={getColumns(moreMenu, true)}
          emptyStateProps={{
            title: I18nService.getString('No lists found.'),
            EmptyStateIcon: <CdParticipantList />,
          }}
        />
      ),
    },
  ];

  return (
    <CdPage
      pageHeaderProps={{
        extra: [
          <Space key="menu">
            <Input
              placeholder={I18nService.getString('Search for list')}
              value={searchKeyWord}
              onChange={(e) => setSearchKeyWord(e.target.value)}
            />
            <Button icon={<CdSearch />}>
              {I18nService.getString('Search')}
            </Button>

            {canCreateEditLists && (
              <Dropdown
                key="create"
                overlay={menu}
                placement="bottomLeft"
                arrow
              >
                <Button type="primary">
                  <Space>
                    {I18nService.getString('Create')}
                    <CdAngleDown />
                  </Space>
                </Button>
              </Dropdown>
            )}
          </Space>,
        ],
        title: I18nService.getString('All lists'),
      }}
    >
      <CdTabs
        activeKey={
          tabItems.find((tab) => tab.key === searchParams.get('activeTab'))
            ? searchParams.get('activeTab')
            : 'filter'
        }
        onChange={(value) => {
          setSearchParams({ activeTab: value });
        }}
        fluidHeight
        items={tabItems}
      />
    </CdPage>
  );
};

export default function NewsletterListHOC() {
  return (
    <BrowserRouter>
      <Suspense fallback={<CdrPageLoader />} key="newsletterlisthoc">
        <NewsletterList />
      </Suspense>
    </BrowserRouter>
  );
}

const getColumns = (moreMenu, showContactCounter = false) =>
  [
    {
      dataIndex: 'name',
      key: 'name',
      title: I18nService.getString('Name'),
      orderType: OrderType.STRING,
      defaultSortOrder: 'ascend',
      width: 400,
      render: (text, record) => (
        <a
          href={getStateUrl(`app.private.people.contacts.list`, {
            list: record.id,
          })}
        >
          {record.name}
        </a>
      ),
    },
    showContactCounter && {
      dataIndex: 'peopleCount',
      key: 'peopleCount',
      title: I18nService.getString('Number of contacts'),
      width: 200,
      render: (peopleCount) => (
        <Space align="center">
          <CdUsers />
          {I18nService.getPlural(
            peopleCount || 0,
            '1 contact',
            '{{ $count | number }} contacts',
            {
              $count: peopleCount || 0,
            }
          )}
        </Space>
      ),
    },
    cdApp.showChurchSelector && {
      dataIndex: 'setup',
      key: 'setup',
      title: I18nService.getString('Parishes'),
      render: (setup) => <ParishTagCollection churchIds={setup?.churchIds} />,
    },
    {
      key: 'buttons',
      width: 1,
      render: (_, item: NewsletterandFilterDataInterface) => (
        <>
          <Space key="dropdown">
            <MessageListCreateDropdown
              buttonLabel={I18nService.getString('Send Message')}
              buttonSize="small"
              recipients={[item]}
            />
            <Dropdown
              overlay={() =>
                moreMenu(
                  item.id,
                  item.name,
                  item.type,
                  item.setup,
                  item.canAddPeopleToList,
                  item
                )
              }
              placement="bottomLeft"
              key="dropdownsendmessage"
            >
              <Button size="small">
                {I18nService.getString('More')} <CdAngleDown />
              </Button>
            </Dropdown>
          </Space>
        </>
      ),
    },
  ].filter(Boolean) as ColumnType<NewsletterandFilterDataInterface>[];
