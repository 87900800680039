/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostBookingRespondRequestBody = {
    comment?: string;
    response: PostBookingRespondRequestBody.response;
};
export namespace PostBookingRespondRequestBody {
    export enum response {
        REQUESTED = 'requested',
        ACCEPTED = 'accepted',
        REJECTED = 'rejected',
    }
}

