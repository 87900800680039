/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PutWidgetsRequestBody = {
    type: PutWidgetsRequestBody.type;
    name?: string;
    configuration: any;
};
export namespace PutWidgetsRequestBody {
    export enum type {
        EVENT = 'event',
        BLOG = 'blog',
        SLIDESHOW = 'slideshow',
        DONATION = 'donation',
    }
}

