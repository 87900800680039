import NiceModal, { antdDrawerV5 } from '@ebay/nice-modal-react';
import { ErrorBoundary } from '@sentry/react';
import { ButtonProps, Drawer, DrawerProps, Spin } from 'antd';
import React, { FunctionComponent } from 'react';
import { Suspense } from 'react';
import { ItemType } from 'antd/es/menu/hooks/useItems';

import {
  ModalFooter,
  ModalType,
  OptionalIfVoid,
} from '../cd-modal-new/CdModal2';
import { CdErrorPage } from '../cd-result/CdErrorPage';
import { CdClose } from '../Icons';
import { useCdModal } from '../cd-modal-new/useCdModal';

export interface InnerDrawerProps<Result = unknown> extends DrawerProps {
  modalType?: ModalType;
  fallback?: React.ReactNode;
  title?: string | React.ReactNode;
  width?: number | string;
  okButtonProps?: ButtonProps;
  okText?: string | React.ReactNode;
  cancelText?: string;
  hideOk?: boolean;
  hideCancel?: boolean;
  hideTopBar?: boolean;
  onOk?: () => Promise<Result> | Result;
  onCancel?: () => unknown;
  alternativeButtonProps?: ButtonProps;
  alternativeButtonText?: string | React.ReactNode;
  onAlternative?: () => unknown;
  dropdownText?: string;
  dropdownOptions?: ItemType[];
}

export type SetDrawerPropsType = React.Dispatch<
  React.SetStateAction<InnerDrawerProps<unknown>>
>;

interface InnerDrawerType {
  setDrawerProps: SetDrawerPropsType;
  loading?: boolean;
  closeDrawer: () => void;
}

export const createCdDrawer = <T, Result = unknown>(
  InnerDrawer: FunctionComponent<T & InnerDrawerType>,
  options?: InnerDrawerProps<Result>
) => {
  const createdDrawer = NiceModal.create((callingProps: T) => {
    const {
      modal,
      disabled,
      crashed,
      modalProps,
      setCrashed,
      onOk,
      onCancel,
      setModalProps,
      onAlternative,
    } = useCdModal<InnerDrawerProps<Result>, Result>({
      modalType: ModalType.POPUP,
      title: '',
      width: 500,
      hideTopBar: false,
      ...options,
    });

    const setDrawerProps = setModalProps;
    const drawerProps = modalProps;

    return (
      <Drawer
        {...antdDrawerV5(modal)}
        {...drawerProps}
        {...{
          maskClosable: drawerProps.modalType !== ModalType.MODAL,
          keyboard: drawerProps.modalType !== ModalType.MODAL,
          closable: !drawerProps.hideTopBar,
          closeIcon: <CdClose />,
          okButtonProps: {
            ...drawerProps.okButtonProps,
            disabled: disabled || drawerProps.okButtonProps?.disabled,
            loading: disabled || drawerProps.okButtonProps?.loading,
          },
          // Antd Drawer uses onClose instead of onCancel used by Antd Modal
          onClose: onCancel,
          footer: (!drawerProps.hideCancel || !drawerProps.hideOk) && (
            <ModalFooter
              modalProps={drawerProps}
              disabled={disabled}
              crashed={crashed}
              onOk={onOk}
              onCancel={onCancel}
              onAlternative={onAlternative}
            />
          ),
        }}
        destroyOnClose
      >
        <ErrorBoundary
          onError={() => setCrashed(true)}
          fallback={<CdErrorPage />}
        >
          <Suspense
            fallback={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spin size="large" />
              </div>
            }
          >
            <InnerDrawer
              setDrawerProps={setDrawerProps}
              closeDrawer={onCancel}
              loading={disabled}
              {...callingProps}
            />
          </Suspense>
        </ErrorBoundary>
      </Drawer>
    );
  });

  return async (props: OptionalIfVoid<T>) =>
    NiceModal.show<{ result: Result; resolved: boolean }, T, any>(
      createdDrawer,
      props ? props : {}
    );
};
