import { CdClientSideTableApiSearchParam } from '../components/cd-client-side-table/types';

import { TagInterface } from '@/react/shared/types/tag.types';
import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';

export const tagsQueries = {
  getByParams: (params: CdClientSideTableApiSearchParam) => ({
    queryKey: ['tags', params],
    queryFn: async () => {
      const { searchTag } = params.extraData;
      const response = await mainApi.get<TagInterface[]>(`people/tags`);
      if (!response.ok) {
        handleError(response);
        return { items: [], total: 0 };
      }
      const filteredData = response.data.filter((tag) =>
        tag.name.toLowerCase().includes(searchTag.toLowerCase())
      );
      return {
        items: filteredData.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 0 : -1
        ),
        total: filteredData?.length,
      };
    },
  }),
  getTagsBySearch: (searchParameter?: string) => ({
    queryKey: ['tags', searchParameter],
    queryFn: async () => {
      const response = await mainApi.get<TagInterface[]>(`people/tags`);
      if (!response.ok) {
        handleError(response);
        return { items: [], total: 0 };
      }
      const filteredData = response.data.filter((tag) =>
        tag.name.toLowerCase().includes(searchParameter?.toLowerCase())
      );
      return {
        items: filteredData.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 0 : -1
        ),
        total: filteredData?.length,
      };
    },
  }),
};
