import { useDispatch } from 'react-redux';
import React, { FunctionComponent, useState } from 'react';
import { Form, Modal, InputNumber, Space, Button, Dropdown, Menu } from 'antd';
import { destroyModal, sagaModal } from 'redux-saga-modal';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { I18nService } from '../../../services/I18nService';
import { Intention, IntentionTabNames } from '../../models/intention';
import MODAL_TYPES from '../../sagas/modal-types';
import IntentionService from '../../services/IntentionService';
import { handleSuccessMessage } from '../../../shared/utils';
import { navigate } from '../../../services/StateServiceFactory';
import {
  setIntentionFilters,
  setIntentionsOverviewTab,
} from '../../redux/intentions/Actions';

// Props
export interface CopyIntentionModalProps {
  isOpen: boolean;
  intention: Intention;
}

const CopyIntentionModal: FunctionComponent<CopyIntentionModalProps> = ({
  isOpen,
  intention,
}) => {
  const dispatch = useDispatch();

  const [copyIntentionForm] = Form.useForm();
  const [shouldDisableSubmit, setShouldDisableSubmit] = useState<boolean>(true);

  const copyIntentionFormRules = {
    noOfCopies: [
      {
        required: true,
        message: I18nService.getString(
          'Please enter the number of copies you would like'
        ),
      },
    ],
  };

  const closeModal = () => dispatch(destroyModal(MODAL_TYPES.COPY_INTENTION));

  const copyIntention = (shouldNavigate) => {
    const noOfCopies = copyIntentionForm.getFieldValue('noOfCopies');
    IntentionService.copyIntention(intention.id, noOfCopies).then(() => {
      handleSuccessMessage(
        I18nService.getPlural(
          noOfCopies,
          'Succesfully copied intention',
          'Succesfully copied {{noOfCopies}} intentions',
          {
            noOfCopies,
          }
        )
      );
      if (shouldNavigate) {
        // Set current tab to unassigned
        dispatch(setIntentionsOverviewTab(IntentionTabNames.UNASSIGNED as any));
        // Set intentions filters to show recently created intentions
        dispatch(
          setIntentionFilters({
            churchIds: [intention.churchId],
            acceptanceDateFrom: moment().startOf('day').toDate(),
            acceptanceDateTo: moment().endOf('day').toDate(),
          })
        );
        // Go to intentions overview
        navigate('app.private.intention.overview');
      }
      closeModal();
    });
  };

  // Validate the fields and enable/disable submit button
  const validateForm = () => {
    copyIntentionForm.validateFields().catch((err) => {
      if (isEmpty(err.errorFields)) {
        setShouldDisableSubmit(false);
      } else {
        setShouldDisableSubmit(true);
      }
    });
  };

  const save = () => copyIntention(false);

  const saveAndView = () => copyIntention(true);

  const menu = (
    <Menu onClick={saveAndView}>
      <Menu.Item key="saveAndView">
        {I18nService.getString('Save & View')}
      </Menu.Item>
    </Menu>
  );

  return (
    <Modal
      open={isOpen}
      title={I18nService.getString('Copy Intention')}
      footer={
        <Space>
          <Button onClick={closeModal}>
            {I18nService.getString('Cancel')}
          </Button>
          <Dropdown.Button
            type="primary"
            onClick={save}
            overlay={menu}
            disabled={shouldDisableSubmit}
            icon={<FontAwesomeIcon icon={faAngleDown} />}
          >
            {I18nService.getString('Save')}
          </Dropdown.Button>
        </Space>
      }
      onCancel={closeModal}
      destroyOnClose
      maskTransitionName="maskTransitionName"
    >
      <Form
        id="copyIntentionForm"
        form={copyIntentionForm}
        onValuesChange={validateForm}
      >
        {/* Number of copies */}
        <Form.Item
          name="noOfCopies"
          label={I18nService.getString(
            'How many copies would you like to make?'
          )}
          rules={copyIntentionFormRules.noOfCopies}
        >
          <InputNumber min={1} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default sagaModal({
  name: MODAL_TYPES.COPY_INTENTION,
})(CopyIntentionModal);
