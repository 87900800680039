import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { ApiSearchParams } from '../../../api';
import { calendarBookingQueries } from '../../../shared/queries/calendar/booking';

export const useEnquiries = (params: ApiSearchParams) =>
  useQuery(calendarBookingQueries.getByParams(params));

export const useSingleEnquiry = (id) =>
  useSuspenseQuery(calendarBookingQueries.getById(id));
