import moment from 'moment';
import React from 'react';
import { Space, Typography } from 'antd';

import MessageListCreateDropdown from '../../message-editor/shared/containers/MessageListCreateDropdown';
import { ColumnType } from '../../../shared/components/cd-table';
import { AnniversaryInterface } from '../../../calendar/types/anniversaryTypes';
import { I18nService } from '../../../services/I18nService';
import { OrderType } from '../../../shared/models/table';

import { getStateUrl } from '@/react/services/StateServiceFactory';
import { CdCheckIcon, CdTime } from '@/react/shared/components/Icons';
import CdTag from '@/react/shared/components/cd-tag/CdTag';
import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';

const { Text } = Typography;
export const anniversaryColumns = () => {
  const columns: ColumnType<AnniversaryInterface>[] = [
    {
      dataIndex: 'fullName',
      key: 'fullName',
      title: I18nService.getString('Name'),
      orderType: OrderType.STRING,
      render: (value, record) => (
        <a
          key={record.id}
          href={getStateUrl(`app.private.people.contacts.view`, {
            id: record.id,
          })}
        >
          {value}
        </a>
      ),
    },
    {
      dataIndex: ['anniversary', 'label'],
      title: I18nService.getString('Event type'),
      key: 'anniversary.label',
      orderType: OrderType.STRING,
    },
    {
      dataIndex: ['anniversary', 'date'],
      title: I18nService.getString('Date'),
      render: (date: string) => moment(date).format('LL'),
      key: 'anniversary.date',
      orderType: OrderType.DATE,
    },
    {
      dataIndex: ['anniversary', 'isSpecial'],
      title: I18nService.getString('Special'),
      key: 'anniversary.isSpecial',
      orderType: OrderType.BOOLEAN,
      defaultSortOrder: 'descend',
      render: (value, record) =>
        value ? <CdCheckIcon key={`${record.id}${record.uniqueId}`} /> : '',
    },
    {
      dataIndex: ['anniversary', 'yearSince'],
      title: I18nService.getString('Year since'),
      key: 'anniversary.yearSince',
      orderType: OrderType.NUMBER,
      render: (value) =>
        I18nService.getPlural(value, '1 year', '{{ count }} years', {
          count: value,
        }),
    },
    {
      dataIndex: 'messages',
      title: I18nService.getString('Message status'),
      key: 'messages',
      width: 200,
      render: (messages) => {
        if (messages?.length) {
          return (
            <Space style={{ flexWrap: 'wrap' }}>
              {messages.map((message, index) => (
                <MessageStatus
                  key={message.uniqueId + index}
                  message={message}
                />
              ))}
            </Space>
          );
        } else {
          return null;
        }
      },
    },
    {
      dataIndex: 'buttons',
      title: '',
      key: 'buttons',
      align: 'right',
      width: 100,
      render: (value, record) =>
        record.smsNotification === 'no' || record.emailNotification === 'no' ? (
          <MessageListCreateDropdown
            buttonLabel={I18nService.getString('Send message')}
            buttonSize="small"
            recipients={[record]}
            buttonType="default"
            recipientsHasSMS={record.smsNotification === 'no'}
            recipientsHasEmail={record.emailNotification === 'no'}
            visibleInvitation={false}
            visibleNewsletter={false}
          />
        ) : (
          ''
        ),
    },
  ];
  return columns;
};

const MessageStatus = ({ message }) => {
  const type = message.peopleMessage.sent ? 'success' : null;
  let tagTitle = '';
  let link = '';
  switch (message.peopleMessage.type) {
    case 'sms':
      tagTitle = I18nService.getString('SMS');
      link = getStateUrl(`app.private.people.messages.view`, {
        id: message.peopleMessage.id,
      });
      break;
    case 'advanced':
      tagTitle = I18nService.getString('Studio');
      link = getStateUrl(`app.private.people.messages.editorV2.view`, {
        type: message.peopleMessage.type,
        messageId: message.peopleMessage.id,
        display: 'view',
      });
      break;
    default:
      tagTitle = I18nService.getString('E-Mail');
      link = getStateUrl(`app.private.people.messages.editorV2.view`, {
        type: message.peopleMessage.type,
        messageId: message.peopleMessage.id,
        display: 'view',
      });
      break;
  }
  const TooltipTitle = () => {
    if (message.peopleMessage.sent) {
      return (
        <Space direction="vertical">
          {I18nService.getString('Sent on {{sentDate}}', {
            sentDate: moment(message.peopleMessage.sent).format('l'),
          })}
          <a href={link} style={{ color: 'white' }}>
            {I18nService.getString('Click to view.')}
          </a>
        </Space>
      );
    }
    if (!message.peopleMessage.sent && message.peopleMessage.scheduled) {
      return (
        <Space direction="vertical">
          {I18nService.getString('Scheduled on {{scheduledDate}}', {
            scheduledDate: moment(message.peopleMessage.scheduled).format('l'),
          })}
          <a href={link} style={{ color: 'white' }}>
            {I18nService.getString('Click to view.')}
          </a>
        </Space>
      );
    }
    if (!message.peopleMessage.sent && !message.peopleMessage.scheduled) {
      return (
        <a href={link} style={{ color: 'white' }}>
          {I18nService.getString('Click to view.')}
        </a>
      );
    }
  };
  return (
    <CdTooltip title={<TooltipTitle />}>
      <CdTag type={type}>
        <Space>
          {type === 'success' && <CdCheckIcon />}
          {tagTitle}
          {!message.peopleMessage.sent && !message.peopleMessage.scheduled && (
            <Text type="secondary">{I18nService.getString('draft')}</Text>
          )}
          {!message.peopleMessage.sent && message.peopleMessage.scheduled && (
            <CdTime type="secondary" />
          )}
        </Space>
      </CdTag>
    </CdTooltip>
  );
};
