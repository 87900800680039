import { Button, Col, Form, FormInstance, Input, Row, Space } from 'antd';
import React, { useContext, useState } from 'react';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import { some } from 'lodash';
import styled from 'styled-components';

import { FieldSection } from '../../components/FieldSection';
import { ResourceLocation } from '../../components/form-items/ResourceLocation';
import { CdEventCategorySelect } from '../../components/CdEventCategorySelect';
import CdSharingFacebook, {
  FacebookExportStatus,
} from '../../components/cd-share-facebook/CdShareFacebook';
import { CdImagePicker } from '../../components/CdImagePicker';
import { UserBooking } from '../../components/form-items/user-booking/UserBooking';
import CdSognDk from '../../components/cd-sogndk/CdSognDk';
import {
  MINIMUM_COLUMN_WIDTH,
  MINIMUM_SECOND_COLUMN_WIDTH,
} from '../../constants';
import { FormItemWithAccess } from '../../components/form-items/FormItemWithAccess';
import { EventAccessContext } from '../../hooks/use-event';
import { VisibilityWrapper } from '../../components/visibility/VisibilityWrapper';
import { CdVerticalSpace } from '../../../../shared/components/cd-vertical-space/CdVerticalSpace';
import { debouncedValidation } from '../../../../shared/antd/validations/common';

import { getString } from '@/react/services/GetStringX';
import { CdCheckboxWithIcon } from '@/react/shared/components/cd-checkbox-with-icon/CdCheckboxWithIcon';
import { useCheckAvailableResources } from '@/react/calendar/hooks/event/useCheckAvailableResources';
import { getStateUrl } from '@/react/services/StateServiceFactory';
import { ChurchSettingsState } from '@/react/organization/store/church';
import { CdrUploadFiles } from '@/react/shared/components/CdrUploadFiles';
import { OrganizationSettings } from '@/react/organization/types/organization';
import { DetailedEvent } from '@/react/calendar/models/calendar';
import cdApp from '@/react/config';
import Redactor from '@/react/people/message-editor/shared/containers/Redactor';
import {
  CdCheckIcon,
  CdLock,
  EventIcons,
} from '@/react/shared/components/Icons';
import { I18nService } from '@/react/services/I18nService';
import { CdInputAlias } from '@/react/shared/components/CdInputAlias';
import { useEventAliasValidation } from '@/react/calendar/hooks/event/useEventAliasValidation';
import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';
import AuthorizationService from '@/react/services/AuthorizationService';

const { TextArea } = Input;

const ResourceAndLocationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 528px;
  align-items: center;
`;
export const EventDetails = (props: {
  churchSettings: OrganizationSettings;
  event: DetailedEvent;
  form: FormInstance;
  showConflicts: any;
}) => {
  const { eventAccess, disabledByVersionHistory } =
    useContext(EventAccessContext);
  const eventSettings = useRecoilValue(ChurchSettingsState);
  const { expirationEventInternalNote, expirationEventSensitiveNote } =
    eventSettings;
  const {
    handleDobleBookingResource,
    setIsResourcesAvailable,
    isResourcesAvailable,
  } = useCheckAvailableResources(props.showConflicts);
  const { duplicateAliasLink, validateAlias } = useEventAliasValidation({
    eventId: props.event?.id,
  });
  const sensitiveNoteExpiredDate = moment(props.event?.startDate)
    .add(Number(expirationEventSensitiveNote), 'days')
    .format('ll')
    .toString();
  const internalNoteExpiredDate = moment(props.event?.startDate)
    .add(Number(expirationEventInternalNote), 'days')
    .format('ll')
    .toString();
  const settingUrl = getStateUrl('app.private.settings.other');

  const visibilityChangeValue = props.form.getFieldValue('visibilitySetting');
  const taxonomies = Form.useWatch('taxonomies', props.form);
  const [forceShowAdditionalCategories, setForceShowAdditionalCategories] =
    useState<boolean>(false);

  const showAdditionalCategories =
    some(taxonomies) || forceShowAdditionalCategories;

  return (
    <Row gutter={16}>
      <Col flex={`${MINIMUM_COLUMN_WIDTH + 16}px`}>
        <FieldSection>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.resourcesLocation !== curValues.resourcesLocation ||
              prevValues.date.allDay !== curValues.date.allDay
            }
            noStyle
          >
            {({ getFieldValue }) => {
              const resourceIds =
                getFieldValue('resourcesLocation')?.resourceIds;
              if (resourceIds?.length === 0) {
                setIsResourcesAvailable(false);
              }
              const isAllDayChecked = getFieldValue('date')?.allDay;
              return (
                <div style={{ position: 'relative' }}>
                  <Form.Item
                    name="resourcesLocation"
                    label={
                      <ResourceAndLocationHeader>
                        {I18nService.getString('Resources & location')}
                        {resourceIds?.length > 0 &&
                          eventAccess?.fields.resources.canEdit && (
                            <Space>
                              {isResourcesAvailable && (
                                <Space style={{ color: 'green' }}>
                                  <CdCheckIcon />
                                  {I18nService.getString(
                                    'all resources are available'
                                  )}
                                </Space>
                              )}
                              <Button
                                type="link"
                                style={{ padding: '0px', height: '24px' }}
                                onClick={() =>
                                  handleDobleBookingResource({
                                    resourceIds,
                                    eventDate: getFieldValue('date'),
                                    eventId: props.event?.id,
                                    initialRrule: props.event?.rrule,
                                  })
                                }
                              >
                                {I18nService.getString('Check availability')}
                              </Button>
                            </Space>
                          )}
                      </ResourceAndLocationHeader>
                    }
                  >
                    <ResourceLocation
                      eventVisibility={visibilityChangeValue?.visibility}
                      isAllDayChecked={isAllDayChecked}
                      form={props.form}
                      calendarId={props?.event?.id}
                    />
                  </Form.Item>
                  {!eventAccess.fields?.resources.canEdit && (
                    <div
                      style={{
                        right: '8px',
                        top: '5px',
                        position: 'absolute',
                      }}
                    >
                      <CdTooltip
                        title={I18nService.getString(
                          'You do not have sufficient access to edit this field.'
                        )}
                      >
                        <CdLock style={{ color: '#6f6f6f' }} />
                      </CdTooltip>
                    </div>
                  )}
                </div>
              );
            }}
          </Form.Item>
        </FieldSection>
        <FieldSection>
          <FormItemWithAccess
            name="mainCategory"
            label={I18nService.getString('Main category')}
            rules={[
              {
                required: true,
                message: I18nService.getString('Please choose a category.'),
              },
            ]}
            noErrorMessage
          >
            <CdEventCategorySelect
              placeholder={I18nService.getString('Add main category')}
            />
          </FormItemWithAccess>

          {!showAdditionalCategories && (
            <Button
              type="link"
              icon="+"
              onClick={() => setForceShowAdditionalCategories(true)}
              style={{ paddingLeft: '0px', marginBottom: '24px' }}
              disabled={
                !eventAccess.fields?.otherCategory?.canEdit ||
                disabledByVersionHistory
              }
            >
              {I18nService.getString('Add more categories')}
            </Button>
          )}

          <FormItemWithAccess
            name="taxonomies"
            fieldAccessName="mainCategory"
            style={{
              marginTop: '24px',
              display: showAdditionalCategories ? 'block' : 'none',
            }}
          >
            <CdEventCategorySelect
              placeholder={I18nService.getString('Choose extra categories')}
              mode="multiple"
              showSecondaryText={false}
            />
          </FormItemWithAccess>
        </FieldSection>

        <FieldSection>
          <FormItemWithAccess
            name="internalNote"
            label={I18nService.getString('Internal note and Files')}
            extra={
              expirationEventInternalNote !== null &&
              expirationEventInternalNote !== 'do_not_delete_automatically'
                ? getString(
                    'The internal note will be automatically deleted {{ days }} days after the event on {{ expiredDate }} as configured in your <link>data retention policy</link>',
                    {
                      days: expirationEventInternalNote,
                      expiredDate: internalNoteExpiredDate,
                      link: (text) =>
                        `<a href="${settingUrl}" target='_blank'>${text}</a>`,
                    }
                  )
                : undefined
            }
          >
            <TextArea
              rows={4}
              placeholder={I18nService.getString(
                'Anything written in internal note can be restricted to specific groups under visibility settings.'
              )}
            />
          </FormItemWithAccess>

          <FormItemWithAccess
            name="files"
            valuePropName="initialFiles"
            trigger="onUpdateFiles"
          >
            <CdrUploadFiles
              canEdit={
                eventAccess.fields?.files?.canEdit && !disabledByVersionHistory
              }
            />
          </FormItemWithAccess>
        </FieldSection>

        <FormItemWithAccess
          name="secureInformation"
          label={I18nService.getString('Sensitive information')}
          extra={
            expirationEventSensitiveNote !== null &&
            expirationEventSensitiveNote !== 'do_not_delete_automatically'
              ? getString(
                  'The sensitive information will be automatically deleted {{ days }} days after the event on {{ expiredDate }} as configured in your <link>data retention policy</link>',
                  {
                    days: expirationEventSensitiveNote,
                    expiredDate: sensitiveNoteExpiredDate,
                    link: (text) =>
                      `<a href="${settingUrl}" target='_blank'>${text}</a>`,
                  }
                )
              : undefined
          }
        >
          <TextArea
            rows={3}
            placeholder={I18nService.getString(
              'Anything written here will be visible only to users who can access sensetive information.'
            )}
          />
        </FormItemWithAccess>
        <FormItemWithAccess
          name="contributor"
          label={I18nService.getString('Contributor')}
        >
          <Input
            placeholder={I18nService.getString(
              'List name(s) of the event leader or key contributors'
            )}
          />
        </FormItemWithAccess>

        <FieldSection>
          <Form.Item hidden noStyle name="cropKey">
            <Input />
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.cropKey !== curValues.cropKey
            }
            noStyle
          >
            {({ getFieldValue }) => (
              <FormItemWithAccess
                name="imageId"
                label={I18nService.getString('Image')}
              >
                <CdImagePicker
                  entityId={props.event?.id || getFieldValue('cropKey')}
                  entityType="event"
                  canEdit={
                    eventAccess?.fields?.imageId?.canEdit &&
                    !disabledByVersionHistory
                  }
                />
              </FormItemWithAccess>
            )}
          </Form.Item>
        </FieldSection>

        <FieldSection>
          <FormItemWithAccess
            name="summary"
            label={I18nService.getString('Event short description')}
          >
            <TextArea
              placeholder={I18nService.getString(
                'Add a short description that can be used for newsletters etc.'
              )}
            />
          </FormItemWithAccess>
        </FieldSection>
        <FieldSection>
          <FormItemWithAccess
            name="description"
            label={I18nService.getString('Public event description')}
          >
            <Redactor
              messagePlaceHolderEnabled={false}
              showFontTypeButton={false}
              showFontColorButton={false}
              showFontFormatButton={true}
              showHtmlButton={true}
            />
          </FormItemWithAccess>
        </FieldSection>
        <FieldSection>
          <FormItemWithAccess
            name="visibilitySetting"
            fieldAccessName="visibility"
            label={I18nService.getString('Visibility settings')}
            // Set help to empty string to mute the default help message.
            // Instead this is shown by the VisibilityWrapper component.
            help=""
            rules={[
              () => ({
                validator(rule, value) {
                  if (
                    value?.visibility === 'internal-group' &&
                    (value?.groupIds?.length === 0 || !value?.groupIds)
                  ) {
                    return Promise.reject(
                      I18nService.getString(
                        'A group is required when visibility is set to Groups.'
                      )
                    );
                  }

                  if (
                    value?.visibility === 'public' &&
                    (value?.groupIds?.length === 0 || !value?.groupIds) &&
                    !AuthorizationService.hasPermission(
                      'canSetVisibilityToInternalAll'
                    ) &&
                    !props.event?.id
                  ) {
                    return Promise.reject(
                      I18nService.getString(
                        'Group is mandatory without permission "Share events with all users"'
                      )
                    );
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <VisibilityWrapper
              VisibilityAllowedValues={
                eventAccess?.fields.visibility.allowedValues
              }
              isNewEvent={!!props.event?.id}
            />
          </FormItemWithAccess>
        </FieldSection>

        <FieldSection>
          <Form.Item
            label={I18nService.getString('Channels')}
            style={{ marginBottom: '0px' }}
          >
            <CdVerticalSpace size={16}>
              <FormItemWithAccess
                noStyle
                name="showInSlideshow"
                valuePropName="checked"
              >
                <CdCheckboxWithIcon
                  Icon={EventIcons.Summary}
                  iconSize="small"
                  title={I18nService.getString(
                    'Add event to slideshow on your website.'
                  )}
                  align="start"
                />
              </FormItemWithAccess>
              {cdApp.organization.countryIso2 === 'dk' ? (
                <FormItemWithAccess name="sogndk" noStyle>
                  <CdSognDk
                    eventUpdatedAt={props?.event?.updatedAt}
                    eventId={props.event?.id}
                    currentStatus={props.event?.sogndk.status}
                    currentExported={props.event?.sogndk.export}
                    allowSognDk={
                      visibilityChangeValue?.visibility !== 'private'
                    }
                  />
                </FormItemWithAccess>
              ) : undefined}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.date.startDate !== curValues.date.startDate
                }
              >
                {({ getFieldValue }) => {
                  const startDate = getFieldValue('date').startDate;
                  const facebook = getFieldValue('facebook');
                  return (
                    <FormItemWithAccess
                      name="facebook"
                      dependencies={['date']}
                      style={{ marginBottom: '0px' }}
                      rules={[
                        () => ({
                          validator(rule, value) {
                            if (
                              facebook?.exportStatus ===
                              FacebookExportStatus.POSTED
                            ) {
                              return Promise.resolve();
                            }
                            const validDuration = startDate.diff(
                              moment(),
                              'minutes'
                            );
                            const duration = value?.schedulingOptions?.duration;

                            const hour = value?.schedulingOptions?.hour;
                            const minute = value?.schedulingOptions?.minute;
                            const announceDate = moment(startDate)
                              .add(-duration, 'days')
                              .startOf('day')
                              .add(hour, 'hours')
                              .add(minute, 'minutes');

                            const realDuration = startDate.diff(
                              announceDate,
                              'minutes'
                            );
                            if (
                              value?.isScheduled &&
                              realDuration > validDuration
                            ) {
                              return Promise.reject(
                                I18nService.getString(
                                  "You can't schedule the post in the past"
                                )
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <CdSharingFacebook
                        startDate={getFieldValue('date').startDate}
                        canEdit={eventAccess.fields?.facebook?.canEdit}
                        allowFacebookSharing={
                          visibilityChangeValue?.visibility === 'public'
                        }
                      />
                    </FormItemWithAccess>
                  );
                }}
              </Form.Item>
            </CdVerticalSpace>
          </Form.Item>
        </FieldSection>
        <FieldSection>
          <FormItemWithAccess
            name="price"
            label={I18nService.getString('Price')}
          >
            <Input placeholder={I18nService.getString('Add amount')} />
          </FormItemWithAccess>
        </FieldSection>
        <FieldSection>
          <FormItemWithAccess
            name="alias"
            label={I18nService.getString('Alternative URL')}
            dependencies={['visibilitySetting']}
            extra={
              duplicateAliasLink ? (
                <div style={{ paddingBottom: '16px' }}>
                  {getString(
                    'Click <a>here</a> to see where the alternative URL is used.',
                    {
                      a: (text) => `<a href="${duplicateAliasLink}" target="_blank">
                        ${text}
                      </a>`,
                    }
                  )}
                </div>
              ) : (
                I18nService.getString(
                  'Add your preferred customised event url. Changes to the alternative URL will take 1-2 minutes to take effect on the website.'
                )
              )
            }
            rules={[
              debouncedValidation(validateAlias, 500),
              {
                pattern: /^[a-zA-Z0-9-]+$/,
                message:
                  'Only letters, numbers, and hyphens are allowed. Spaces and other special characters are not allowed.',
              },
            ]}
          >
            <CdInputAlias
              allowAlias={visibilityChangeValue?.visibility === 'public'}
            />
          </FormItemWithAccess>
        </FieldSection>
        {props.event && (
          <FieldSection>
            {I18nService.getString(
              'Created by {{authorName}} on {{createdAt}}',
              {
                authorName: props.event.author.contact.fullName,
                createdAt: I18nService.formatDate(props.event.createdAt, 'lll'),
              }
            )}
          </FieldSection>
        )}
      </Col>
      <Col flex={`${MINIMUM_SECOND_COLUMN_WIDTH + 16}px`}>
        <Row style={{ marginLeft: '24px' }}>
          <Col flex="auto">
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) =>
                prevValues.date.startDate !== curValues.date.startDate ||
                prevValues.date.endDate !== curValues.date.endDate ||
                prevValues.date.rrule !== curValues.date.rrule
              }
            >
              {({ getFieldValue }) => (
                <FormItemWithAccess
                  name="users"
                  label={I18nService.getString('Book users')}
                >
                  <UserBooking
                    eventId={props.event?.id}
                    canEdit={
                      eventAccess?.fields.users.canEdit &&
                      !disabledByVersionHistory
                    }
                    startDate={getFieldValue('date').startDate}
                    endDate={getFieldValue('date').endDate}
                    rrule={getFieldValue('date').rrule}
                    bookedUsers={props.event?.users ? props.event.users : []}
                    initialRrule={props.event?.rrule}
                  />
                </FormItemWithAccess>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
