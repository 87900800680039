import {
  useRecoilCallback,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import NiceModal from '@ebay/nice-modal-react';

import {
  MessageTemplateCacheBuster,
  MessageTemplatesByIdQuery,
  MessageTemplatesByTypeQuery,
} from '../store/message-templates';
import {
  MessageByIdQuery,
  peopleMessageIdState,
  unlayerControlState,
} from '../store/message';
import { showTemplateSelector } from '../message-editor/shared/containers/TemplateSelector';

import {
  MessageType,
  PeopleMessage,
  PeopleMessageRecipient,
} from '@/react/people/types/message';
import { mainApi } from '@/react/api';
import NotificationService from '@/react/services/NotificationService';
import { I18nService } from '@/react/services/I18nService';
import {
  PeopleMessageTemplate,
  TemplateAuthor,
  TemplateType,
} from '@/react/people/types/message-template.type';
import { navigate } from '@/react/services/StateServiceFactory';
import ErrorHandlingService, {
  handleError,
} from '@/react/services/ErrorHandlingService';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import {
  GetCurrentPortal,
  PortalOrganizationsAndCategories,
} from '@/react/portal/state/portal';

function recipientReducer(
  accumulator: { id: number; group: string }[],
  currentValue: string
) {
  if (currentValue.includes('person-')) {
    accumulator.push({
      id: parseInt(currentValue.replace('person-', ''), 10),
      group: 'contacts',
    });
  }
  if (currentValue.includes('segment-')) {
    accumulator.push({
      id: parseInt(currentValue.replace('segment-', ''), 10),
      group: 'lists',
    });
  }
  return accumulator;
}

function mapMessageFromForm(message) {
  return {
    ...message,
    to: message.to
      .map((target) =>
        target.setup ? `segment-${target.id}` : `person-${target.id}`
      )
      .reduce(recipientReducer, []),
  };
}

export const useTemplateslist = ({
  authoredBy,
  type,
}: {
  authoredBy?: TemplateAuthor;
  type?: TemplateType;
}) => {
  const templatesLoadable = useRecoilValueLoadable(
    MessageTemplatesByTypeQuery({ type, authoredBy })
  );
  const templatesLoading = templatesLoadable.state === 'loading';
  const templates =
    templatesLoadable.state === 'hasValue' ? templatesLoadable.contents : [];
  const setTemplateCacheBuster = useSetRecoilState(MessageTemplateCacheBuster);

  const deleteTemplate = useRecoilCallback(
    () => async (templateId: string) => {
      const response = await mainApi.delete<PeopleMessageTemplate>(
        `/v2/people/message-templates/${templateId}`
      );
      if (!response.ok) {
        handleError(response);
        throw response;
      }
      NotificationService.notifySuccess(
        I18nService.getString('Template deleted')
      );
      setTemplateCacheBuster((state) => state + 1);
    },
    [setTemplateCacheBuster]
  );
  return { templates, templatesLoading, deleteTemplate };
};

export const useTemplatesSelectUnlayer = () => {
  const unlayerControl = useRecoilValue(unlayerControlState);

  const selectTemplate = useRecoilCallback(
    ({ snapshot, set }) =>
      async ({
        id,
        recipients,
        sourceInfo,
      }: {
        id: string;
        recipients?: PeopleMessageRecipient[];
        sourceInfo?: any;
      }) => {
        const release = snapshot.retain();
        let response;
        try {
          const messageId = await snapshot.getPromise(peopleMessageIdState);
          const template = await snapshot.getPromise(
            MessageTemplatesByIdQuery(id)
          );
          if (!messageId) {
            response = await mainApi.post<PeopleMessage>(
              '/v2/people/messages',
              {
                ...mapMessageFromForm({ to: recipients }),
                rendered: template.rendered,
                content: template.content,
                type: MessageType.ADVANCED,
                anniversary: sourceInfo?.anniversary,
              }
            );
          } else {
            const message = await snapshot.getPromise(
              MessageByIdQuery(messageId)
            );
            response = await mainApi.put<PeopleMessage>(
              `/v2/people/messages/${messageId}`,
              {
                ...mapMessageFromForm(message),
                rendered: template.rendered,
                content: template.content,
                type: MessageType.ADVANCED,
              }
            );
          }

          if (!response.ok) {
            ErrorHandlingService.handleError(response);
            throw response;
          }
          set(MessageByIdQuery(response.data.id), response.data);
          if (unlayerControl?.setDefaultValue) {
            // When updating the current message that is open in the editor in the background.
            unlayerControl.setDefaultValue(response.data.content);
          } else {
            // When creating a new message
            navigate(
              'app.private.people.messages.editorV2.view',
              { messageId: response.data.id, type: MessageType.ADVANCED },
              { reload: false }
            );
          }
        } finally {
          release();
        }
      },
    [unlayerControl]
  );

  return { selectTemplate };
};

export const useTemplates = () => {
  const currentPortal =
    useRecoilValue<PortalOrganizationsAndCategories>(GetCurrentPortal);
  const openSaveNewTemplate = () => NiceModal.show('SaveNewTemplate');
  const setTemplateCacheBuster = useSetRecoilState(MessageTemplateCacheBuster);

  const openWarningBeforeChangingTemplate = () =>
    showConfirmModal({
      title: I18nService.getString('Confirm template change'),
      message: I18nService.getString(
        'Changing the template will overwrite your current design and all of the content and text you may have added. Please confirm.'
      ),
      onOk: () => {
        showTemplateSelector({});
        // Return null to close the modal when we open the drawer.
        return null;
      },
    });

  const saveTemplate = useRecoilCallback(
    ({ snapshot }) =>
      async (title: string, portalId?: string) => {
        const release = snapshot.retain();
        try {
          const peopleMessageId =
            snapshot.getLoadable(peopleMessageIdState).contents;
          const message = snapshot.getLoadable(
            MessageByIdQuery(peopleMessageId)
          ).contents as PeopleMessage;

          const response = await mainApi.post<PeopleMessageTemplate>(
            '/v2/people/message-templates',
            {
              title,
              content: message.content,
              rendered: message.rendered,
              portalId: portalId,
            } as PeopleMessageTemplate
          );
          if (!response.ok) {
            throw response;
          }
          NotificationService.notifySuccess(
            I18nService.getString('Template saved')
          );
          setTemplateCacheBuster((state) => state + 1);
        } finally {
          release();
        }
      },
    [setTemplateCacheBuster]
  );

  return {
    openWarningBeforeChangingTemplate,
    openSaveNewTemplate,
    saveTemplate,
    currentPortalId: currentPortal?.id,
  };
};
