/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { PostCalendarCollectioncalendarRequestBody } from '../models/PostCalendarCollectioncalendarRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CollectionCalendarService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * getAllDioceseCollectionCalendar
     * @returns EmptyObject
     * @throws ApiError
     */
    public getAltarCollectionCalendar(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/altar/collection-calendar',
        });
    }
    /**
     * importFromCsvFile
     * @returns EmptyObject
     * @throws ApiError
     */
    public postAltarCollectionCalendar({
        dioceseId,
        requestBody,
    }: {
        dioceseId: string,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/altar/collection-calendar',
            query: {
                'dioceseId': dioceseId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * createCollectionCalendar
     * @returns EmptyObject
     * @throws ApiError
     */
    public postCalendarCollectionCalendar({
        requestBody,
    }: {
        requestBody?: PostCalendarCollectioncalendarRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/calendar/collection-calendar',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * getAllCollections
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarCollectionCalendar({
        startDate,
        endDate,
    }: {
        startDate: string,
        endDate: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/collection-calendar',
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
        });
    }
    /**
     * deleteCollectionCalendar
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteCalendarCollectionCalendar(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/calendar/collection-calendar/{id}',
        });
    }
    /**
     * getCollectionCalendar
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarCollectionCalendar1(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/collection-calendar/{id}',
        });
    }
    /**
     * putCollectionCalendar
     * @returns EmptyObject
     * @throws ApiError
     */
    public putCalendarCollectionCalendar({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PostCalendarCollectioncalendarRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/calendar/collection-calendar/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
