import { mainApi } from '../../../api';
import { DetailedEvent } from '../../../calendar/models/calendar';
import { CdQueryDefinition } from '../types';

export const calendarEntryQueries = {
  getById: (eventId: number) => ({
    queryKey: ['calendarEntry', eventId],
    queryFn: async () => {
      if (eventId) {
        const response = await mainApi.get<DetailedEvent>(
          `/calendar/${eventId}`
        );
        if (response.ok) {
          return response.data;
        }
        throw response;
      } else {
        return null;
      }
    },
  }),
} satisfies CdQueryDefinition;
