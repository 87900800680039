import React, { Suspense } from 'react';
import { Col, Form, Row, Spin } from 'antd';

import { MINIMUM_COLUMN_WIDTH } from '../../constants';
import { I18nService } from '../../../../services/I18nService';
import { FieldSection } from '../FieldSection';
import { CdVerticalSpace } from '../../../../shared/components/cd-vertical-space/CdVerticalSpace';

import CommentList from './CommentList';
import CommentEditor from './CommentEditor';

interface CommentsProps {
  eventId: any;
}

const Comments = (props: CommentsProps) => {
  const { eventId } = props;

  return (
    <Row gutter={16}>
      <Col flex={`${MINIMUM_COLUMN_WIDTH + 16}px`}>
        <FieldSection>
          <Form.Item label={I18nService.getString('Comments')}>
            <Suspense fallback={<Spin />}>
              <CdVerticalSpace size={16}>
                <CommentList eventId={eventId} />
                <CommentEditor eventId={eventId} />
              </CdVerticalSpace>
            </Suspense>
          </Form.Item>
        </FieldSection>
      </Col>
    </Row>
  );
};

export default Comments;
