import { Form } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { AvailabilityTaxonomyFormData } from '../types/availability-taxonomy.types';
import { handleError } from '../../../services/ErrorHandlingService';
import { useSuspensedFlag } from '../../../shared/hooks/useFlag';
import { taxonomiesQueries } from '../../../shared/queries/taxonomies';

import {
  useSaveNewTaxonomyMutation,
  useUpdateTaxonomyMutation,
} from './useTaxonomy';

import { I18nService } from '@/react/services/I18nService';
import NotificationService from '@/react/services/NotificationService';
import {
  EMPTY_FORM_DATA,
  convertFormDataToTaxonomy,
  createNewBookingOption,
  existingTaxonomyToFormData,
} from '@/react/settings/booking-pages/utils/utils';

export const useAvailabilityFormData = (
  initialTaxonomyId: number | undefined
) => {
  const [taxonomyId, setTaxonomyId] = useState<number | undefined>(
    initialTaxonomyId
  );
  const [activeItem, setActiveItem] = useState('details');
  const [form] = Form.useForm<AvailabilityTaxonomyFormData>();
  const { data: existingTaxonomy, isLoading: isLoadingExistingTaxonomy } =
    useSuspenseQuery(taxonomiesQueries.getById(taxonomyId));

  const { mutateAsync: saveNewTaxonomy } = useSaveNewTaxonomyMutation();
  const { mutateAsync: updateTaxonomy } = useUpdateTaxonomyMutation();

  const bookingEnquiriesV2Enabled = useSuspensedFlag('booking_enquiries_v2');
  const isEditMode = !!existingTaxonomy;

  const onSave = useCallback(
    async (options?: { continueAfter?: boolean }) => {
      try {
        await form.validateFields({ validateOnly: false });
        if (!existingTaxonomy) {
          const taxonomy = await saveNewTaxonomy(
            convertFormDataToTaxonomy(undefined, form.getFieldsValue(true))
          );
          if (options?.continueAfter) {
            setTaxonomyId(taxonomy.id);
            form.setFieldsValue(existingTaxonomyToFormData(taxonomy));
          }
          NotificationService.notifySuccess(
            I18nService.getString('New booking page successfully created')
          );
        } else {
          const taxonomy = await updateTaxonomy(
            convertFormDataToTaxonomy(
              existingTaxonomy,
              form.getFieldsValue(true)
            )
          );
          if (options?.continueAfter) {
            form.setFieldsValue(existingTaxonomyToFormData(taxonomy));
          }
          NotificationService.notifySuccess(
            I18nService.getString('Booking page updated')
          );
        }
      } catch (error) {
        handleError(error);
        return { preventClose: true };
      }
    },
    [existingTaxonomy, form, saveNewTaxonomy, updateTaxonomy]
  );

  const onSaveAndContinue = useCallback(async () => {
    await onSave({ continueAfter: true });
    return { preventClose: true };
  }, [onSave]);

  const onAddBookingOption = useCallback(() => {
    const newBookingOptions = [
      ...(form.getFieldValue('bookingOptions') ?? []),
      createNewBookingOption(),
    ];
    form.setFieldValue('bookingOptions', newBookingOptions);
    return newBookingOptions.length - 1;
  }, [form]);

  const deleteBookingOption = useCallback(
    (optionIndex: number) => {
      const newOptionsValue = form
        .getFieldsValue(true)
        .bookingOptions.filter((_, index) => index !== optionIndex);
      form.setFieldValue('bookingOptions', [...newOptionsValue]);
      setActiveItem('option-' + (newOptionsValue.length - 1));
    },
    [form]
  );

  const copyBookingOption = useCallback(
    (optionIndex: number) => {
      const bookingOptions = form.getFieldsValue(true).bookingOptions;

      const option = bookingOptions.find((_, index) => index === optionIndex);
      const copiedOption = {
        ...option,
        name: `${option.name} (${I18nService.getString('Copy')})`,
        id: null,
      };

      const newOptionsValue = [...bookingOptions, copiedOption];

      form.setFieldValue('bookingOptions', newOptionsValue);
      setActiveItem('option-' + (newOptionsValue.length - 1));
    },
    [form]
  );

  useEffect(() => {
    if (activeItem === 'add-option') {
      setActiveItem('option-' + onAddBookingOption());
    }
  }, [activeItem, onAddBookingOption]);

  const initialValues = useMemo(
    () =>
      existingTaxonomy
        ? existingTaxonomyToFormData(existingTaxonomy)
        : EMPTY_FORM_DATA(bookingEnquiriesV2Enabled),
    [existingTaxonomy, bookingEnquiriesV2Enabled]
  );

  const currentBookingOptions = form.getFieldValue('bookingOptions');

  return {
    form,
    initialValues,
    onSave,
    onSaveAndContinue,
    copyBookingOption,
    deleteBookingOption,
    isLoadingExistingTaxonomy,
    title:
      existingTaxonomy?.name ?? I18nService.getString('Unnamed booking page'),
    isExistingTaxonomy: !!existingTaxonomy,
    activeItem,
    setActiveItem,
    // AntD form bug that preserves deleted booking options with all fields undefined
    currentBookingOptions: currentBookingOptions,
    formData: existingTaxonomy?.config?.form,
    followUpForm: existingTaxonomy?.config?.followUpForm,
    isEditMode,
  };
};
