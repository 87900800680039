/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PutUsersRequestBody = {
    id?: number;
    email?: string;
    intermediaryEmail?: string | null;
    updatedAt?: string;
    name?: string;
    firstName?: string | null;
    lastName?: string | null;
    lastAccess?: string;
    initials?: string;
    birthday?: string | null;
    picture?: string;
    canEdit?: boolean;
    canDelete?: boolean;
    address?: any;
    phones?: Array<{
        id?: number;
        default?: number;
        countryId?: string;
        phone: string | null;
    }>;
    pictureId?: number | null;
    roles?: Array<number>;
    status?: number;
    organizationStatus?: PutUsersRequestBody.organizationStatus;
    internalNote?: string | null;
    bookingCreatedNotifcation?: {
        email?: boolean;
        sms?: boolean;
        push?: boolean;
    };
    bookingUpdatedNotifcation?: {
        email?: boolean;
        sms?: boolean;
        push?: boolean;
    };
    bookingCanceledNotifcation?: {
        email?: boolean;
        sms?: boolean;
        push?: boolean;
    };
    absenceCreatedNotifcation?: {
        email?: boolean;
        sms?: boolean;
        push?: boolean;
    };
    groupMessageNotifcation?: {
        email?: boolean;
        sms?: boolean;
        push?: boolean;
    };
    userRegisteredNotifcation?: {
        email?: boolean;
        sms?: boolean;
        push?: boolean;
    };
    attendanceTrackingNotification?: {
        email?: boolean;
        sms?: boolean;
        push?: boolean;
    };
    rotaReminderNotification?: {
        email?: boolean;
        sms?: boolean;
        push?: boolean;
    };
    shareBookingsAcrossOrganizations?: boolean | null;
    shareSafeguardingLevelGlobally?: boolean;
    contractType?: string | null;
    currentPassword?: string;
    newPassword?: string;
    resetPasswordToken?: string;
};
export namespace PutUsersRequestBody {
    export enum organizationStatus {
        ACTIVE = 'active',
        BLOCKED = 'blocked',
        CREATED_WITHOUT_LOGIN = 'createdWithoutLogin',
    }
}

