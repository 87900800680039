import React from 'react';
import { Col, Form, Input, Row, Typography } from 'antd';
import { Moment } from 'moment';

import { EventType } from '../../models/calendar';
import { isInArray, required } from '../../../shared/antd/validations/common';
import { I18nService } from '../../../services/I18nService';
import { CdUserSelect } from '../../../groups/components/CdUserSelect';
import { createCdDrawer } from '../../../shared/components/cd-drawer/CdDrawer';
import { FieldSection } from '../../event-details/components/FieldSection';
import { DateInput } from '../../event-details/components/date-input/DateInput';
import { CdEventCategorySelect } from '../../event-details/components/CdEventCategorySelect';
import { useAvailabilityDrawer } from '../hooks/use-availability-drawer';
import { CategoryType } from '../../../shared/models/category';
import cdApp from '../../../config';
import { ModalType } from '../../../shared/components/cd-modal-new/CdModal2';

import { BookingOptionsSelect } from './BookingOptionsSelect';

export type AvailabilityDrawerProps = {
  id?: number;
  mainCategory?: number;
  user?: number;
  bookingOptionIds?: number[];
  date?: {
    startDate: Moment;
    endDate: Moment;
    allDay: boolean;
  };
};

export const showAvailabilityDrawer = createCdDrawer<AvailabilityDrawerProps>(
  ({ setDrawerProps, closeDrawer, loading, ...props }) => {
    const {
      form,
      initialValues,
      userIdsInGroups,
      selectedTaxonomy,
      availabilityFromAPI,
    } = useAvailabilityDrawer(setDrawerProps, closeDrawer, props);

    return (
      <Form form={form} initialValues={initialValues} layout="vertical">
        <Form.Item name="id" hidden noStyle>
          <Input />
        </Form.Item>
        <Form.Item name="type" hidden noStyle>
          <Input />
        </Form.Item>
        <Row gutter={16} wrap={false}>
          <Col flex={1}>
            <FieldSection>
              <Form.Item
                name="date"
                label={I18nService.getString('Date & Time')}
                rules={[
                  required(
                    I18nService.getString('Please select a date and time')
                  ),
                ]}
              >
                <DateInput
                  eventId={availabilityFromAPI?.id}
                  previousRRule={availabilityFromAPI?.rrule}
                  eventType={EventType.Availability}
                />
              </Form.Item>
            </FieldSection>
            <FieldSection>
              <Form.Item
                name="mainCategory"
                label={I18nService.getString('Type')}
                rules={[
                  required(I18nService.getString('Please choose a type')),
                ]}
                extra={
                  selectedTaxonomy && (
                    <Typography.Link
                      href={
                        new URL(
                          `/${cdApp.organization.publicId}/${selectedTaxonomy?.alias}`,
                          cdApp.references.bookingPage
                        ).href
                      }
                      copyable
                      target="_blank"
                    >
                      {
                        new URL(
                          `/${cdApp.organization.publicId}/${selectedTaxonomy?.alias}`,
                          cdApp.references.bookingPage
                        ).href
                      }
                    </Typography.Link>
                  )
                }
              >
                <CdEventCategorySelect
                  placeholder={I18nService.getString('Choose a type...')}
                  showSecondaryText={false}
                  type={CategoryType.availability}
                  usage="calendar"
                  autoFocus
                />
              </Form.Item>
            </FieldSection>
            <FieldSection>
              <Form.Item
                name="bookingOptionIds"
                label={I18nService.getString(
                  'Options',
                  null,
                  'Create event popover - Preferably a shorter word (max. 12 characters)'
                )}
                rules={[
                  required(
                    I18nService.getString(
                      'Please select minimum one booking option'
                    )
                  ),
                ]}
              >
                <BookingOptionsSelect taxonomyIds={[selectedTaxonomy?.id]} />
              </Form.Item>
            </FieldSection>
            <FieldSection>
              <Form.Item
                name="user"
                dependencies={['mainCategory']}
                label={I18nService.getString('User')}
                rules={[
                  required(I18nService.getString('A user is required.')),
                  isInArray(
                    userIdsInGroups.length === 0 ? null : userIdsInGroups,
                    I18nService.getString(
                      'This user is not part of any groups using this availability category'
                    )
                  ),
                ]}
              >
                <CdUserSelect includeIds={userIdsInGroups} showAvatar={false} />
              </Form.Item>
            </FieldSection>
          </Col>
        </Row>
      </Form>
    );
  },
  {
    width: 530,
    modalType: ModalType.MODAL,
  }
);
