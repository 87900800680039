/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PeoplePublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * ChurchscreenSubscribeNewsletter
     * Subscribe
     * @returns EmptyObject
     * @throws ApiError
     */
    public postNewslettersSubscribe({
        partnerToken,
        tags,
        requestBody,
    }: {
        /**
         * Partner API identifier.
         */
        partnerToken: any,
        /**
         * The list of the newsletters ids.
         */
        tags: any,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/newsletters/subscribe',
            query: {
                'partnerToken': partnerToken,
                'tags': tags,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
