import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select } from 'antd';
import _ from 'lodash';
import React, { FunctionComponent, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { I18nService } from '../../services/I18nService';
import { FilterType, SelectedCalendar } from '../store/filters';
import { AddCalendar } from '../store/filters/Actions';
import { getUnselectedCalendars } from '../store/filters/Selectors';

import CalendarItem from './CalendarItem';

const { Option, OptGroup } = Select;

const StyledOptGroup = styled(OptGroup)`
  &&&& {
    padding: 0;

    .calendarSelectOptionItem {
      padding: 0;
    }
  }
`;

const StyledSelect = styled(Select)`
  &&&& {
    width: 100%;

    .ant-select-selection-placeholder {
      color: #333;
    }
  }
`;

const CalendarAutoComplete: FunctionComponent = () => {
  const dispatch = useDispatch();
  // Retrieve and sort unselected calendars
  const disorderedAndUnselectedCalendars: SelectedCalendar[] = useSelector(
    getUnselectedCalendars,
    _.isEqual
  );

  const unselectedUserCalendars = useMemo(
    () =>
      _.sortBy(
        _.filter(disorderedAndUnselectedCalendars, {
          filterType: FilterType.users,
        }),
        (unselectedResourceCalendar) =>
          _.get(unselectedResourceCalendar, 'name')
      ),
    [disorderedAndUnselectedCalendars]
  );
  const unselectedResourceCalendars = useMemo(
    () =>
      _.filter(disorderedAndUnselectedCalendars, {
        filterType: FilterType.resources,
      }),
    [disorderedAndUnselectedCalendars]
  );

  const unselectedCalendars = useMemo(
    () => [
      ..._.sortBy(unselectedResourceCalendars, (unselectedResourceCalendar) =>
        _.get(unselectedResourceCalendar, 'name')
      ),
      ..._.sortBy(unselectedUserCalendars, (unselectedUserCalendar) =>
        _.get(unselectedUserCalendar, 'name')
      ),
    ],
    [unselectedUserCalendars, unselectedResourceCalendars]
  );

  const menu = useMemo(() => {
    const groupedCalendarItems = _.groupBy(
      unselectedCalendars,
      (calendarItem) => calendarItem.filterType
    );
    const hasGroupedResourcesPackage: boolean = _.get(
      window,
      'cdApp.showChurchSelector'
    );
    let groupedResourcesAndUsers = groupedCalendarItems;
    if (hasGroupedResourcesPackage) {
      const resources = groupedCalendarItems[FilterType.resources];
      const groupedResources = _.groupBy(
        resources,
        (resource) => resource.churchName
      );
      delete groupedCalendarItems[FilterType.resources];
      groupedResourcesAndUsers = {
        ...groupedResources,
        ...groupedCalendarItems,
      };
    }
    // `${calendarItem.filterType}:${calendarItem.filterType},id:${calendarItem.id}name:${calendarItem.name}`
    return _(groupedResourcesAndUsers)
      .keys()
      .map((groupedCalendarItemKey) => (
        <StyledOptGroup
          key={groupedCalendarItemKey}
          label={
            groupedCalendarItemKey === FilterType.users
              ? I18nService.getString('Users')
              : groupedCalendarItemKey
          }
        >
          {groupedResourcesAndUsers[groupedCalendarItemKey].map(
            (calendarItem) => (
              <Option
                className="calendarSelectOptionItem"
                key={`${calendarItem.filterType}_${calendarItem.id}`}
                value={JSON.stringify({
                  filterType: calendarItem.filterType,
                  name: calendarItem.name,
                  id: calendarItem.id,
                })}
                title={calendarItem.name}
              >
                <CalendarItem item={calendarItem} />
              </Option>
            )
          )}
        </StyledOptGroup>
      ))
      .value();
  }, [unselectedCalendars]);

  return (
    <StyledSelect
      showSearch
      onSelect={(value: string) => {
        const item: SelectedCalendar = JSON.parse(value);
        dispatch(AddCalendar(item));
      }}
      autoClearSearchValue
      value={null}
      placeholder={
        <>
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />
          {I18nService.getString('Add users & resources...')}
        </>
      }
      suffixIcon={null}
    >
      {menu}
    </StyledSelect>
  );
};

export default CalendarAutoComplete;
