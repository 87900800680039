import React, { useState } from 'react';
import { Button, Typography } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';

import cdApp from '../../../config';
import { I18nService } from '../../../services/I18nService';
import { CdCopyIcon } from '../../../shared/components/Icons';
import {
  createCdModal,
  ModalType,
} from '../../../shared/components/cd-modal-new/CdModal2';
import { CdVerticalSpace } from '../../../shared/components/cd-vertical-space/CdVerticalSpace';
import { CdRadioGroup } from '../../../shared/components/cd-radio-group/CdRadioGroup';
import { Taxonomy } from '../../../shared/models/taxonomy';

export const showBookingPagePreviewModal = createCdModal<{
  taxonomy: Taxonomy;
}>(
  ({ taxonomy }) => {
    const [buttonText, setButtonText] = useState(
      I18nService.getString('Copy code')
    );
    const [bookingOptionId, setBookingOptionId] = useState(
      'all-booking-options'
    );

    const { alias } = taxonomy;
    const baseUrl = cdApp.references.websiteWidgetsUrl;
    const publicOrganizationId = cdApp.organization.publicId;
    const codeTemplate = `<div data-churchdesk-widget-id="${alias}" ${
      bookingOptionId && bookingOptionId !== 'all-booking-options'
        ? `data-churchdesk-booking-option-id="${bookingOptionId}"`
        : ''
    } data-churchdesk-organization-id="${publicOrganizationId}" data-churchdesk-widget-type="booking-page" data-churchdesk-user-embed="true"></div><script src="${baseUrl}/widget-loader.js"></script>`;

    return (
      <CdVerticalSpace>
        {taxonomy?.bookingOptions?.length > 1 && (
          <>
            <Typography.Title level={5}>
              {I18nService.getString('Options to display')}
            </Typography.Title>
            <CdRadioGroup
              value={bookingOptionId}
              onChange={(e) => setBookingOptionId(e?.toString())}
              options={[
                {
                  label: I18nService.getString('All'),
                  value: 'all-booking-options',
                },
                ...taxonomy.bookingOptions.map((option) => ({
                  value: option.id,
                  label: option.name,
                })),
              ]}
            />
          </>
        )}
        <Typography.Title level={5}>
          {I18nService.getString('Copy code')}
        </Typography.Title>
        <Typography.Text style={{ marginBottom: '32px !important' }}>
          {I18nService.getString(
            'Insert the widget on your website by copying this code and pasting it into the HTML of your site.'
          )}
        </Typography.Text>
        <Typography.Paragraph code>{codeTemplate}</Typography.Paragraph>
        <CopyToClipboard
          text={codeTemplate}
          onCopy={() => {
            setButtonText(I18nService.getString('Copied'));
          }}
        >
          <Button>
            <CdCopyIcon />
            {buttonText}
          </Button>
        </CopyToClipboard>
      </CdVerticalSpace>
    );
  },
  {
    title: I18nService.getString('Widget preview'),
    modalType: ModalType.POPUP,
    hideOk: true,
    width: 1000,
  }
);
