/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { PostFoldersRequestBody } from '../models/PostFoldersRequestBody';
import type { PutFoldersRequestBody } from '../models/PutFoldersRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FoldersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create_folder
     * Create
     * @returns EmptyObject
     * @throws ApiError
     */
    public postFolders({
        requestBody,
    }: {
        requestBody?: PostFoldersRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/folders',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetFolders
     * GET ALL FOLDERS
     * @returns EmptyObject
     * @throws ApiError
     */
    public getFolders(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/folders',
        });
    }
    /**
     * MultiDeleteFolders
     * Multidelete
     * @returns EmptyObject
     * @throws ApiError
     */
    public postFoldersMultidelete({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/folders/multidelete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete_folder
     * Delete
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteFolders({
        id,
    }: {
        id: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/folders/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetFolderById
     * GET FOLDER
     * @returns EmptyObject
     * @throws ApiError
     */
    public getFolders1({
        id,
    }: {
        id: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/folders/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Update_folder
     * Update
     * @returns EmptyObject
     * @throws ApiError
     */
    public putFolders({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PutFoldersRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/folders/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
