import {
  AttendanceStatus,
  EventType,
  Type,
} from '@/react/calendar/models/calendar';

export const CALENDAR_EVENT_COLORS = {
  '0': {
    background: '#bfecfa',
    backgroundLight: '#e9f9fe',
    textLight: '#507681',
    main: '#008db6',
    mainLight: '#99d1e2',
    text: '#00475c',
  },
  '1': {
    background: '#ccd1ef',
    backgroundLight: '#edeffa',
    textLight: '#606890',
    main: '#3f51b5',
    mainLight: '#b2b9e1',
    text: '#26316e',
  },
  '2': {
    background: '#cbf2db',
    backgroundLight: '#edfbf3',
    textLight: '#567664',
    main: '#2ecc71',
    mainLight: '#abebc6',
    text: '#114b2a',
  },
  '3': {
    background: '#c7e0d2',
    backgroundLight: '#ecf4f0',
    textLight: '#577564',
    main: '#1e824c',
    mainLight: '#a5cdb7',
    text: '#0f4227',
  },
  '4': {
    background: '#ffbaba',
    backgroundLight: '#ffe7e7',
    main: '#d50000',
    mainLight: '#ee9999',
    text: '#660000',
    textLight: '#a75353',
  },
  '5': {
    background: '#ffebb2',
    backgroundLight: '#fff8e4',
    textLight: '#746b53',
    main: '#f6bf26',
    mainLight: '#fbe5a8',
    text: '#4e3a04',
  },
  '6': {
    background: '#fad0ad',
    backgroundLight: '#fdefe3',
    textLight: '#7e644e',
    main: '#ef6c00',
    mainLight: '#f9c499',
    text: '#5c2900',
  },
  '7': {
    background: '#e7d9eb',
    backgroundLight: '#f7f2f8',
    main: '#9e69af',
    mainLight: '#d8c3df',
    text: '#44294d',
    textLight: '#7F5E8A',
  },
  '8': {
    background: '#e5e5e5',
    backgroundLight: '#f6f6f6',
    main: '#999999',
    mainLight: '#d6d6d6',
    text: '#363636',
    textLight: '#666666',
  },
  '9': {
    background: '#b3b3b3',
    backgroundLight: '#e5e5e5',
    main: '#070a0d',
    mainLight: '#9c9d9e',
    text: '#000000',
    textLight: '#626c74',
  },
  '10': {
    background: '#d0e0fc',
    backgroundLight: '#eff4fe',
    textLight: '#476eae',
    main: '#4285f4',
    mainLight: '#b3cefb',
    text: '#083887',
  },
  '11': {
    background: '#e0e2ef',
    main: '#7986cb',
    mainLight: '#c9cfea',
    backgroundLight: '#f4f5fa',
    text: '#29346b',
    textLight: '#626a8e',
  },
  '12': {
    background: '#ece6f6',
    backgroundLight: '#f9f7fc',
    main: '#b39ddb',
    mainLight: '#e1d8f1',
    text: '#40286c',
    textLight: '#6e5f8b',
  },
  '13': {
    background: '#e3c8ea',
    backgroundLight: '#f5ecf8',
    main: '#8e24aa',
    mainLight: '#d2a7dd',
    text: '#541565',
    textLight: '#82598d',
  },
  '14': {
    background: '#ddd4d1',
    backgroundLight: '#f3f0ef',
    main: '#795548',
    mainLight: '#c9bbb6',
    text: '#1a120f',
    textLight: '#726864',
  },
  '15': {
    background: '#f8d9e6',
    backgroundLight: '#f7f2f8',
    main: '#ad1457',
    mainLight: '#dea1bc',
    text: '#7b0e3e',
    textLight: '#995171',
  },
  '16': {
    background: '#c1e9e6',
    backgroundLight: '#eaf8f7',
    textLight: '#527a76',
    main: '#009688',
    mainLight: '#99d5cf',
    text: '#004741',
  },
  '17': {
    background: '#deecd0',
    backgroundLight: '#f4f9ef',
    textLight: '#71725a',
    main: '#7cb342',
    mainLight: '#e6eaad',
    text: '#31471a',
  },
  '18': {
    background: '#eff2cc',
    backgroundLight: '#fafbed',
    textLight: '#696f1b',
    main: '#c0ca33',
    mainLight: '#e6eaad',
    text: '#3e4110',
  },
  '19': {
    background: '#fef1cd',
    backgroundLight: '#fffaee',
    textLight: '#716d56',
    main: '#e4c441',
    mainLight: '#f4e7b3',
    text: '#473b0a',
  },
  '20': {
    background: '#fbe4bf',
    backgroundLight: '#fef6e9',
    main: '#f09300',
    mainLight: '#f9d499',
    text: '#573500',
    textLight: '#78684f',
  },
  '21': {
    background: '#fcd3c7',
    backgroundLight: '#fef0ec',
    main: '#f4511e',
    mainLight: '#fbb9a5',
    text: '#701f06',
    textLight: '#8a5b4c',
  },
  '22': {
    background: '#f9dedc',
    backgroundLight: '#fdf4f3',
    textLight: '#915955',
    main: '#e67c73',
    mainLight: '#f5cbc7',
    text: '#751c15',
  },
  '23': {
    background: '#fbc0d5',
    backgroundLight: '#fee9f1',
    main: '#d81b60',
    mainLight: '#efa4bf',
    text: '#640d2c',
    textLight: '#965069',
  },
  absences: {
    background: '#fff5f5',
    backgroundLight: '#fffbfb',
    main: '#d50000',
    mainLight: '#FFCCCC',
    text: '#800000',
    textLight: '#aa5555',
  },
  availabilites: {
    background: '#c7d5e2',
    backgroundLight: '#ecf1f5',
    main: '#3c5871',
    mainLight: '#8aa7c1',
    text: '#253646',
    textLight: '#4b6c8c',
  },
  busy: {
    background: '#eee6de',
    backgroundLight: '#f9f7f4',
    main: '#a79b8e',
    mainLight: '#d5cfc9',
    text: '#484038',
    textLight: '#76695c',
  },
  fallback: {
    background: '#e4dde0',
    backgroundLight: '#f6f3f4',
    main: '#947783',
    mainLight: '#d4c9cd',
    text: '#48383f',
    textLight: '#786d72',
  },
  liturgicalCalendar: {
    background: '#cccccc',
    backgroundLight: '#ededed',
    main: '#333333',
    mainLight: '#adadad',
    text: '#22313f',
    textLight: '#626c74',
  },
  nationalHolidays: {
    background: '#d0d8dc',
    backgroundLight: '#eff2f3',
    main: '#436375',
    mainLight: '#8ea1ac',
    text: '#283943',
    textLight: '#5c6970',
  },
} as const;

export type CalendarColorType = keyof typeof CALENDAR_EVENT_COLORS;
export type ColorSettings = (typeof CALENDAR_EVENT_COLORS)[CalendarColorType];

type UsedEventColors = {
  textColor: string;
  lightTextColor: string;
  backgroundColor: string;
  mainColor: string;
  lightColor: string;
  prepAndCleanup?: {
    backgroundColor: string;
  };
};

type EventColorOptions = {
  colorIndex?: undefined;
  isPast: boolean;
  isLiturgicalHoliday: boolean;
  isNationalHoliday: boolean;
  isPlaceHolder: boolean;
  isAbsence: boolean;
  isCollection: boolean;
  isFeed: boolean;
  type: EventType;
  replyStatus?: AttendanceStatus | null;
  name?: string;
};

export const getEventColors = ({
  colorIndex,
  isPast,
  isLiturgicalHoliday,
  isNationalHoliday,
  isPlaceHolder,
  isAbsence,
  isCollection,
  isFeed,
  type,
  replyStatus,
}: EventColorOptions): UsedEventColors => {
  let configKey: keyof typeof CALENDAR_EVENT_COLORS = 'fallback';
  if (isLiturgicalHoliday) {
    configKey = 'liturgicalCalendar';
  } else if (isPlaceHolder) {
    configKey = 'fallback';
  } else if (isNationalHoliday) {
    configKey = 'nationalHolidays';
  } else if (isAbsence && (typeof colorIndex !== 'number' || colorIndex < 0)) {
    configKey = 'absences';
  } else if (isCollection) {
    configKey = 'fallback';
  } else if ((type as any) === Type.GroupedAbsence) {
    configKey = 'fallback';
  } else if (type === EventType.Availability) {
    configKey = 'availabilites';
  } else if (Number.isFinite(colorIndex)) {
    configKey = String(colorIndex) as keyof typeof CALENDAR_EVENT_COLORS;
  }

  const color =
    CALENDAR_EVENT_COLORS[configKey] ?? CALENDAR_EVENT_COLORS.fallback;
  const prepAndCleanup = {
    backgroundColor: color.backgroundLight,
    textColor: color.textLight,
    mainColor: color.mainLight,
  };

  const isMissingOrNegativeReplay =
    [
      AttendanceStatus.Maybe,
      AttendanceStatus.NoAnswer,
      AttendanceStatus.NotGoing,
    ].includes(replyStatus) &&
    !isFeed &&
    type !== EventType.Availability;

  if (isMissingOrNegativeReplay) {
    return {
      backgroundColor: '#FFF',
      textColor: color.text,
      mainColor: color.main,
      lightColor: color.mainLight,
      lightTextColor: color.textLight,
      prepAndCleanup: {
        ...prepAndCleanup,
        backgroundColor: '#FFF',
      },
    };
  }

  if (isPast) {
    return {
      backgroundColor: color.backgroundLight,
      textColor: color.text,
      mainColor: color.main,
      lightColor: color.mainLight,
      lightTextColor: color.textLight,
      prepAndCleanup,
    };
  } else {
    return {
      textColor: color.text,
      backgroundColor: color.background,
      mainColor: color.main,
      lightColor: color.mainLight,
      lightTextColor: color.textLight,
      prepAndCleanup,
    };
  }
};

export const getColorBasedOnColorIndex = (colorIndex: number | string) => {
  const color = CALENDAR_EVENT_COLORS[colorIndex];
  return color || CALENDAR_EVENT_COLORS.fallback;
};
