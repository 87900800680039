/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChurchscreenEventCategoriesResponseBody } from '../models/ChurchscreenEventCategoriesResponseBody';
import type { ChurchscreenEventResourcesResponseBody } from '../models/ChurchscreenEventResourcesResponseBody';
import type { GetEventsResponseBody } from '../models/GetEventsResponseBody';
import type { RetrieveResponseBody } from '../models/RetrieveResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class EventsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetEvents
     * Index
     * @returns GetEventsResponseBody Success-Response: Success
     * @throws ApiError
     */
    public getEvents({
        partnerToken,
        rid,
        cid,
        requireImage,
        imageFormat,
        itemsNumber,
        pageMarker,
        outdoor,
        startDate,
        endDate,
    }: {
        /**
         * Partner API identifier.
         */
        partnerToken: any,
        /**
         * Event resources ids.
         */
        rid?: any,
        /**
         * Event categories ids.
         */
        cid?: any,
        /**
         * Image requirements to contain the image.
         */
        requireImage?: any,
        /**
         * The image format of the returned images. Supported values are: span3_16-9, span4_16-9, span5_16-9, span7_16-9, span12_16-9.
         */
        imageFormat?: any,
        /**
         * Represents the number of events needs to be returned per single page. Maximum 100
         */
        itemsNumber?: any,
        /**
         * Represents the page number in event listing.
         */
        pageMarker?: any,
        /**
         * The true value will list only the events selected for the outdoor ChurchScreen, the false will return all upcoming public events (default).
         */
        outdoor?: any,
        /**
         * The start date from which events are included. Values accepted by Date() should be used. Default is current time.
         */
        startDate?: any,
        /**
         * The end date, to which strip events.
         */
        endDate?: any,
    }): CancelablePromise<GetEventsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events',
            query: {
                'partnerToken': partnerToken,
                'rid': rid,
                'cid': cid,
                'requireImage': requireImage,
                'imageFormat': imageFormat,
                'itemsNumber': itemsNumber,
                'pageMarker': pageMarker,
                'outdoor': outdoor,
                'startDate': startDate,
                'endDate': endDate,
            },
        });
    }
    /**
     * ChurchscreenEventCategories
     * Categories, Index
     * @returns ChurchscreenEventCategoriesResponseBody Success-Response: Success
     * @throws ApiError
     */
    public getEventsCategories({
        partnerToken,
    }: {
        /**
         * Partner API identifier.
         */
        partnerToken: any,
    }): CancelablePromise<ChurchscreenEventCategoriesResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/categories',
            query: {
                'partnerToken': partnerToken,
            },
        });
    }
    /**
     * ChurchscreenEventResources
     * Resources, Index
     * @returns ChurchscreenEventResourcesResponseBody Success-Response: Success
     * @throws ApiError
     */
    public getEventsResources({
        partnerToken,
    }: {
        /**
         * Partner API identifier.
         */
        partnerToken: any,
    }): CancelablePromise<ChurchscreenEventResourcesResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/resources',
            query: {
                'partnerToken': partnerToken,
            },
        });
    }
    /**
     * Retrieve
     * Retrieve
     * @returns RetrieveResponseBody
     * @throws ApiError
     */
    public getEvents1(): CancelablePromise<RetrieveResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/{id}',
        });
    }
}
