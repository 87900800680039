import { mainApi } from '../../api';
import { I18nService } from '../../services/I18nService';
import NotificationService from '../../services/NotificationService';
import { DetailedEvent } from '../models/calendar';
import { EventPostPayload } from '../types/event';

export const postEventAPI = async (payload: EventPostPayload) => {
  const response = await mainApi.post<DetailedEvent>('/calendar', payload);
  if (response.ok) {
    NotificationService.notifySuccess(
      I18nService.getString('The event has been created')
    );
    return response;
  }
  throw response;
};

export const putEventAPI = async (payload: EventPostPayload, id: number) => {
  const response = await mainApi.put<DetailedEvent>(`/calendar/${id}`, payload);
  if (response.ok) {
    NotificationService.notifySuccess(
      I18nService.getString('The event has been updated')
    );
    return response;
  }
  throw response;
};
