import { showModal } from '@/react/angular/ReactModalBridge';

(function () {
  'use strict';

  function GroupMainController(
    $http,
    $scope,
    $state,
    $stateParams,
    $uibModal,
    Groups,
    toastr,
    gettextCatalog,
    Me,
    Picture,
    groupData,
    Authorization
  ) {
    $scope.permissions = groupData.perms || [];
    $scope.permissionsLoading = false;
    $scope.aboutTextLoading = false;
    $scope.group = groupData.group;
    $scope.group.id = $stateParams.gid;
    $scope.groupTitle = groupData.group.name;
    $scope.aboutText = groupData.group.description;
    $scope.group.tasks = groupData.group.tasks || {};
    $scope.group.hasTask = !!_.get(groupData, 'group.tasks[0]');
    $scope.hasIntentionsAccess = Authorization.hasPackage('intentions');

    // Members
    $scope.users = {
      members: groupData.members,
      leaders: _.filter(groupData.members, { isAdmin: true }),
    };

    if ($scope.users.members.length === 0) {
      $scope.users.usersEmpty = true;
    }

    $scope.editGroup = function () {
      $uibModal
        .open({
          component: 'cdCreateGroupModal',
          resolve: {
            group: () => $scope.group,
          },
        })
        .result.then(() => {
          $state.reload();
        });
    };

    $scope.requestLeaveGroup = function () {
      showModal('removeMemberModal', {
        user: { id: Me.id },
        groupId: $scope.group.id,
      });
    };

    $scope.onGroupUpdated = function () {
      $state.reload();
    };

    $scope.removeGroup = function () {
      showModal('deleteGroupModal', {
        groupId: Number($scope.group.id),
        groupName: $scope.group.name,
      });
    };

    $scope.pictureUploader = Picture(
      'groups',
      $stateParams.gid,
      function (file, response) {
        $scope.group.image = response.image;
      }
    );

    $scope.isImageEmpty = function (image) {
      return image === null || image === '';
    };

    $scope.removeImage = function () {
      $scope.group.image = null;

      new Groups($scope.group).$save(
        function () {
          toastr.success(
            gettextCatalog.getString('The group picture has been removed.')
          );
        },
        function (response) {
          toastr.error(
            _.get(response, 'data.message') ||
              gettextCatalog.getString(
                'An error occurred, please try again. If the problem persists, please contact our support.'
              )
          );
        }
      );
    };

    $scope.requestJoinGroup = function (user) {
      Groups.updateUserMembership(
        { groupId: $scope.group.id, userId: user ? user.id : Me.id },
        { status: 'pending' },
        function (data) {
          if (data.status === 'active') {
            $scope.permissions.isPending = false;
            $scope.permissions.isMember = true;
            toastr.success(gettextCatalog.getString('You joined a new group.'));
            $state.reload();
          } else {
            $scope.permissions.isPending = true;
            $scope.permissions.isMember = false;
            toastr.success(
              gettextCatalog.getString(
                'Your request to join the group has been sent.'
              )
            );
          }
        },
        function () {
          toastr.error(gettextCatalog.getString('Request could not be sent.'));
        }
      );
    };
  }

  GroupMainController.$inject = [
    '$http',
    '$scope',
    '$state',
    '$stateParams',
    '$uibModal',
    'Groups',
    'toastr',
    'gettextCatalog',
    'Me',
    'Picture',
    'groupData',
    'Authorization',
  ];

  angular
    .module('cdApp.intranet')
    .controller('GroupMainController', GroupMainController);
})();
