import React, { useEffect } from 'react';

import { CdPage } from '../../shared/components/cd-page/CdPage';
import { I18nService } from '../../services/I18nService';
import { CdTabs } from '../../shared/components/cd-tabs/CdTabs';
import StateServiceFactory from '../../services/StateServiceFactory';

import { OpenEnquiriesTab } from './components/OpenEnquiriesTab';
import { ClosedEnquiriesTab } from './components/ClosedEnquiriesTab';
import { showEnquiryDrawer } from './components/EnquiryDrawer';

export const EnquiriesPage = (props: {
  id?: string;
  $stateParams: { tab: string };
}) => {
  const state = StateServiceFactory();
  useEffect(() => {
    props.id && showEnquiryDrawer({ id: props.id });
  }, [props]);

  return (
    <CdPage
      pageHeaderProps={{
        title: I18nService.getString('Enquiries'),
        subTitle: I18nService.getString(
          'Manage incoming enquiries from booking pages here'
        ),
      }}
    >
      <CdTabs
        activeKey={props.$stateParams.tab || 'open'}
        onChange={(key) =>
          state.go('app.private.calendar.enquiries', { tab: key })
        }
        items={[
          {
            key: 'open',
            title: I18nService.getString('Open enquiries'),
            content: <OpenEnquiriesTab />,
          },
          {
            key: 'closed',
            title: I18nService.getString('Closed enquiries'),
            content: <ClosedEnquiriesTab />,
          },
        ]}
      />
    </CdPage>
  );
};
