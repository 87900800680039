/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class IndexService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get
     * Health check
     * @returns EmptyObject
     * @throws ApiError
     */
    public get(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/',
        });
    }
    /**
     * CLAM_AV_status
     * @returns EmptyObject
     * @throws ApiError
     */
    public getClamavStatus(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clamav/status',
        });
    }
    /**
     * ELASTIC_STATUS_CHECK
     * @returns EmptyObject
     * @throws ApiError
     */
    public getElasticStatus(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/elastic/status',
        });
    }
    /**
     * MAIL_STATUS_CHECK
     * @returns EmptyObject
     * @throws ApiError
     */
    public getMailStatus(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/mail/status',
        });
    }
    /**
     * NAS_STATUS_CHECK
     * @returns EmptyObject
     * @throws ApiError
     */
    public getNasStatus(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/nas/status',
        });
    }
    /**
     * GetRenderEmailtemplate
     * Render email template
     * @returns EmptyObject
     * @throws ApiError
     */
    public getRender(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/render/{emailTemplate}',
        });
    }
    /**
     * Robots
     * @returns EmptyObject
     * @throws ApiError
     */
    public getRobotsTxt(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/robots.txt',
        });
    }
    /**
     * GetTraefik
     * Health check for the load balancer
     * @returns EmptyObject
     * @throws ApiError
     */
    public getTraefik(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/traefik',
        });
    }
}
