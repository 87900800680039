import {
  Button,
  Input,
  Divider,
  Checkbox,
  Typography,
  Row,
  Col,
  Popover,
  Form,
} from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import styled from 'styled-components';
import { DefaultOptionType } from 'antd/lib/select';
import { isBoolean } from 'lodash';

import { CdAngleDown, CdSearch } from '../../Icons';
import { CdVerticalSpace } from '../../cd-vertical-space/CdVerticalSpace';
import { I18nService } from '../../../../services/I18nService';
import { filterOption, filterSort } from '../../cd-select/CdSelect';

type ValueType = string | number;

export interface CdFilterSelectProps {
  value?: ValueType[];
  onChange?: (value: ValueType[], option: DefaultOptionType) => void;
  title?: (count) => React.ReactNode;
  options: DefaultOptionType[];
  size?: SizeType;
  style?: React.CSSProperties;
  filterOption?:
    | boolean
    | ((input: string, option: DefaultOptionType) => boolean);
  filterSort?: (
    optionA: DefaultOptionType,
    optionB: DefaultOptionType
  ) => number;
  disabled?: boolean;
}

export const CdFilterSelect = (props: CdFilterSelectProps) => {
  const [form] = Form.useForm();
  const search = Form.useWatch('search', form);
  const [active, setInnerActive] = useState<ValueType[]>(props.value || []);

  const setActive = useCallback(
    (value: ValueType[]) => {
      setInnerActive(value);
      props.onChange?.(value, null);
    },
    [props]
  );

  const toggle = useCallback(
    (value: ValueType) => {
      if (active.includes(value)) {
        setActive(active.filter((item) => item !== value));
      } else {
        setActive([...active, value]);
      }
    },
    [active, setActive]
  );

  const options = useMemo(
    () =>
      props.options
        ?.filter((option) =>
          isBoolean(props.filterOption)
            ? props.filterOption
            : (props.filterOption || filterOption)(search, option)
        )
        .sort(props.filterSort || filterSort) || [],
    [props.options, props.filterSort, props.filterOption, search]
  );

  const toggleAll = useCallback(
    (value: boolean) => {
      if (value) {
        setActive(options.map((item) => item.value));
      } else {
        setActive([]);
      }
    },
    [options, setActive]
  );

  // useMemo speeds up rendering because of the destroyTooltipOnHide
  const content = useMemo(
    () => (
      <Form form={form}>
        <CdVerticalSpace>
          <Form.Item name="search" noStyle>
            <Input
              prefix={<CdSearch type="secondary" style={{ marginRight: 4 }} />}
              placeholder={I18nService.getString('Search...')}
              allowClear
              autoFocus
            />
          </Form.Item>
          <StyledCheckbox
            indeterminate={
              active.length > 0 && active.length !== options?.length
            }
            checked={active.length === options?.length}
            onChange={(e) => toggleAll(e.target.checked)}
          >
            {I18nService.getString('All')}
          </StyledCheckbox>
          <Divider style={{ margin: 0 }} />
          {options.length > 0 ? (
            options.map((item) => (
              <StyledCheckbox
                key={item.value}
                checked={active.includes(item.value)}
                onChange={() => toggle(item.value)}
                tabIndex={0}
              >
                {item.label}
              </StyledCheckbox>
            ))
          ) : (
            <Typography.Text type="secondary">
              {I18nService.getString('No results found')}
            </Typography.Text>
          )}
        </CdVerticalSpace>
      </Form>
    ),
    [form, active, options, toggleAll, toggle]
  );

  // Using Popover instead of Dropdown since Dropdown has bad tabIndex behavior
  return (
    <Popover
      trigger={['click', 'contextMenu']}
      arrow={false}
      placement="bottomLeft"
      // Makes sure content is placed correctly in the DOM for tabbing
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      overlayInnerStyle={{ maxHeight: 280, width: 300, overflowY: 'auto' }}
      // We need to destroy on hide so autoFocus on Input works on re-open
      destroyTooltipOnHide
      content={content}
    >
      <Button size={props.size} style={props.style} disabled={props.disabled}>
        <Row justify="space-between" gutter={8}>
          <Col>{props.title && props.title?.(active.length)}</Col>
          <Col>
            <CdAngleDown />
          </Col>
        </Row>
      </Button>
    </Popover>
  );
};

const StyledCheckbox = styled(Checkbox)`
  width: 100%;
  font-weight: normal;
`;
