import React, { Suspense } from 'react';
import { Space, Button, Alert, Typography, Col, Row } from 'antd';
import styled from 'styled-components';
import {
  ConnectNotificationBanner,
  ConnectComponentsProvider,
  ConnectAccountManagement,
} from '@stripe/react-connect-js';
import { Card, Spin } from 'antd';
import { useState } from 'react';

import cdApp from '../../config';

import { openStripeOnboardingModal } from './StripeOnboardingModal';

import { I18nService } from '@/react/services/I18nService';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import { usePaymentsSettingsPage } from '@/react/settings/hooks/usePaymentsSettingsPage';
import {
  CdExclamationTriangleIcon,
  CdOpenInNewWindow,
} from '@/react/shared/components/Icons';
import { CdMasqueradeButton } from '@/react/shared/components/cd-buttons/CdMasqueradeButton';
import { CdUserAvatarWithState } from '@/react/user/components/cd-user-avatar/CdUserAvatar';
import { getAddressFormat } from '@/react/calendar/event-details/services/address.service';

const SpacedAlert = styled(Alert)`
  .ant-alert-action {
    padding-left: 8px;
  }
`;

export const PaymentsSettingsPage = () => (
  <Suspense fallback={<CdrPageLoader />}>
    <PageInner />
  </Suspense>
);

const PageInner = () => {
  const {
    stripeAccountData,
    stripeOnboardingCompleted,
    stripeOnboardingStarted,
    openStripeOnboarding,
    stripeAccountIsLoading,
    getSupportArticle,
    stripeConnectInstance,
    stripeOnboardingUser,
    stripeOnboardingUserIsLoading,
    legalInformation,
    legalInformationMissing,
    legalInformationIsLoading,
  } = usePaymentsSettingsPage();

  const title = I18nService.getString('Payment settings');
  const subTitle = I18nService.getString(
    'Provide the necessary information needed to have your monthly contributions sent to your account'
  );
  const [loading, setLoading] = useState(true);
  const [showNotificationsBanner, setShowNotificationsBanner] = useState(false);

  const handleNotificationsChange = (response) => {
    if (response.total > 0) {
      setShowNotificationsBanner(true);
    }
  };

  const onLoaderStart = () => {
    setLoading(false);
  };

  const onLoadError = (loadError) => {
    const componentName = loadError.elementTagName;
    const error = loadError.error;
    // Debugging for developers in case of Stripe Connect component load failure
    // eslint-disable-next-line no-console
    console.log(componentName + ' failed to load');
    // eslint-disable-next-line no-console
    console.log(`${error.type}: ${error.message}`);
  };

  const rowDefaults = { style: { marginBottom: '8px' } };
  const labelColDefaults = { style: { color: '#717171' }, flex: '150px' };
  const isLoading =
    stripeAccountIsLoading ||
    legalInformationIsLoading ||
    stripeOnboardingUserIsLoading;

  return (
    <CdPage
      pageHeaderProps={{
        title,
        subTitle,
        extra: [
          <CdMasqueradeButton
            type="primary"
            target="_blank"
            key="view-in-stripe"
            href={
              cdApp.config.environment === 'production'
                ? `https://dashboard.stripe.com/connect/accounts/${stripeAccountData?.id}`
                : `https://dashboard.stripe.com/test/connect/accounts/${stripeAccountData?.id}`
            }
          >
            {I18nService.getString('View in stripe')}
          </CdMasqueradeButton>,
        ],
      }}
    >
      {isLoading ? (
        <Spin />
      ) : (
        <Space direction="vertical" size={16}>
          {!stripeOnboardingCompleted && (
            <Card>
              <Space direction="vertical">
                {I18nService.getString(
                  'To use ChurchDesk Payments, you need to complete setup with Stripe. This ensures secure and compliant payment processing. During setup you will go through a legally required KYC (know your customer) process directly with Stripe.'
                )}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {stripeOnboardingStarted ? (
                    <Button
                      type="primary"
                      onClick={openStripeOnboarding}
                      icon={<CdOpenInNewWindow />}
                    >
                      {I18nService.getString('Continue')}
                    </Button>
                  ) : (
                    <Button type="primary" onClick={openStripeOnboardingModal}>
                      {I18nService.getString('Start setup')}
                    </Button>
                  )}
                </div>
              </Space>
            </Card>
          )}
          {!legalInformationMissing && showNotificationsBanner && (
            <SpacedAlert
              message={
                <Space>
                  <CdExclamationTriangleIcon
                    type="danger"
                    size="3x"
                    style={{ padding: 16 }}
                  />
                  <Space direction="vertical" size={0}>
                    <Typography.Text strong>
                      {I18nService.getString(
                        'More details are needed for verification'
                      )}
                    </Typography.Text>
                    {I18nService.getString(
                      'Our payment provider, Stripe requires more details to ensure that the person responsible for the church is indeed whom they say they are. Please provide this data to continue using the ChurchDesk payment services.'
                    )}
                  </Space>
                </Space>
              }
              type="error"
              action={
                <Space>
                  <Button href={getSupportArticle()} target="_blank">
                    {I18nService.getString('Click here to get help')}
                  </Button>
                </Space>
              }
            />
          )}
          {!legalInformationMissing && stripeOnboardingCompleted && (
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
              <Space
                direction="vertical"
                size={showNotificationsBanner ? 16 : 0}
                style={{ width: '100%' }}
              >
                <Card
                  style={{
                    display: showNotificationsBanner ? 'block' : 'none',
                  }}
                >
                  <ConnectNotificationBanner
                    onLoadError={onLoadError}
                    collectionOptions={{
                      fields: 'eventually_due',
                    }}
                    onNotificationsChange={handleNotificationsChange}
                  />
                </Card>
                <Row gutter={16}>
                  <Col span={14}>
                    <Card title={I18nService.getString('Stripe Account')}>
                      {loading && <Spin />}
                      <ConnectAccountManagement
                        onLoadError={onLoadError}
                        onLoaderStart={() => onLoaderStart()}
                      />
                    </Card>
                  </Col>

                  <Col span={10}>
                    <Space
                      direction="vertical"
                      size={16}
                      style={{ width: '100%' }}
                    >
                      <Card title={I18nService.getString('Legal information')}>
                        <Row {...rowDefaults}>
                          <Col {...labelColDefaults}>
                            {I18nService.getString('Legal business name')}
                          </Col>
                          <Col>{legalInformation?.legalBusinessName}</Col>
                        </Row>
                        <Row {...rowDefaults}>
                          <Col {...labelColDefaults}>
                            {I18nService.getString('Address')}
                          </Col>
                          <Col>
                            {getAddressFormat({
                              address: legalInformation.line1,
                              address2: legalInformation.line2,
                              zipcode: legalInformation.postCode,
                              city: legalInformation.city,
                            })}
                          </Col>
                        </Row>
                      </Card>
                      {stripeOnboardingUser?.onboardingUserId && (
                        <Card
                          title={I18nService.getString('Stripe admin user')}
                        >
                          <Row {...rowDefaults}>
                            <Col {...labelColDefaults}>
                              {I18nService.getString('User')}
                            </Col>
                            <Col>
                              <CdUserAvatarWithState
                                size="small"
                                userId={stripeOnboardingUser?.onboardingUserId}
                              />
                            </Col>
                          </Row>
                          <Row {...rowDefaults}>
                            <Col {...labelColDefaults}>
                              {I18nService.getString('E-mail')}
                            </Col>
                            <Col>
                              {stripeOnboardingUser?.onboardingUserEmail}
                            </Col>
                          </Row>
                          <Row {...rowDefaults}>
                            <Col {...labelColDefaults}>
                              {I18nService.getString('Phone')}
                            </Col>
                            <Col>
                              {stripeOnboardingUser?.onboardingUserPhone}
                            </Col>
                          </Row>
                        </Card>
                      )}
                    </Space>
                  </Col>
                </Row>
              </Space>
            </ConnectComponentsProvider>
          )}
        </Space>
      )}
    </CdPage>
  );
};
