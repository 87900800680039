import React from 'react';
import { Select, Space } from 'antd';

import { I18nService } from '../../../services/I18nService';
import { AnniversaryInterface } from '../../../calendar/types/anniversaryTypes';
import useAnniversary from '../hooks/useAnniversary';
import { GetPeopleAnniversaryList } from '../store/anniversary';
import MessageListCreateDropdown from '../../message-editor/shared/containers/MessageListCreateDropdown';

import { anniversaryColumns } from './AnniversaryTableColumn';

import CdClientSideTable from '@/react/shared/components/cd-client-side-table/CdClientSideTable';
import cdApp from '@/react/config';

export const Anniversary = () => {
  const {
    setAnniversaryType,
    anniversaryTypes,
    anniversaryType,
    selectedContactAnniversaries,
    setSelectedContactAnniversaries,
    anniversaryFilterUpgrade,
  } = useAnniversary();
  return (
    <CdClientSideTable<AnniversaryInterface>
      searchParams={{ type: anniversaryType }}
      rowKey="uniqueId"
      querySource={GetPeopleAnniversaryList}
      columns={anniversaryColumns()}
      emptyStateProps={{
        title: I18nService.getString('No upcoming anniversary'),
      }}
      rowSelection={{
        type: 'checkbox',
        onSelect: (record) => {
          setSelectedContactAnniversaries([
            ...selectedContactAnniversaries,
            record,
          ]);
        },
      }}
      filterForm={
        <Space>
          <Select
            style={{ width: 180 }}
            onChange={(value) => {
              localStorage.setItem(
                `churchdesk.anniversaryType.${cdApp.organization?.id}`,
                value
              );
              setAnniversaryType(value);
            }}
            value={anniversaryType}
            options={anniversaryTypes}
          />
          {anniversaryFilterUpgrade && (
            <MessageListCreateDropdown
              buttonLabel={I18nService.getString('Bulk actions')}
              recipients={selectedContactAnniversaries}
              buttonType="default"
              visibleInvitation={false}
              visibleNewsletter={false}
              extraItem={{
                value: 'Web2Print',
                label: I18nService.getString('Export ORT Web2Print'),
              }}
            />
          )}
        </Space>
      }
    />
  );
};
