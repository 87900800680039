import { EventApi } from '@fullcalendar/core';
import _ from 'lodash';
import moment from 'moment';

export const getDuration = (event: EventApi, isResizing: boolean): number => {
  let start: Date, end: Date;
  if (!event.start || !event.end) {
    return -1;
  } else if (isResizing) {
    const prepTimeMinutes = _.get(event, 'extendedProps.prepTimeMinutes') || 0;
    const cleanupTimeMinutes =
      _.get(event, 'extendedProps.cleanupTimeMinutes') || 0;
    start = moment(event.start).add(prepTimeMinutes, 'minutes').toDate();
    end = moment(event.end).subtract(cleanupTimeMinutes, 'minutes').toDate();
  } else {
    start = event.extendedProps.originalStartDate;
    end = event.extendedProps.originalEndDate;
  }
  return moment.duration(moment(end).diff(start)).asHours();
};
