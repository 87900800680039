/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { PostWebhookStripeRequestBody } from '../models/PostWebhookStripeRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class WebhookService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * PostWebhookStripe
     * @returns EmptyObject
     * @throws ApiError
     */
    public postWebhookStripe({
        requestBody,
    }: {
        requestBody?: PostWebhookStripeRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/webhook/stripe',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * PostWebhookStripeAccounts
     * @returns EmptyObject
     * @throws ApiError
     */
    public postWebhookStripeAccounts({
        requestBody,
    }: {
        requestBody?: PostWebhookStripeRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/webhook/stripe/accounts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
