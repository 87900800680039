/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeletePartnersResponseBody } from '../models/DeletePartnersResponseBody';
import type { EmptyObject } from '../models/EmptyObject';
import type { GetCategoriesResponseBody } from '../models/GetCategoriesResponseBody';
import type { GetPartnersOrganizationResponseBody } from '../models/GetPartnersOrganizationResponseBody';
import type { GetPartnersOrganizationsResponseBody } from '../models/GetPartnersOrganizationsResponseBody';
import type { GetPartnersResponseBody } from '../models/GetPartnersResponseBody';
import type { GetPartnersResponseBody2 } from '../models/GetPartnersResponseBody2';
import type { IndexResponseBody } from '../models/IndexResponseBody';
import type { PostCategoriesRequestBody } from '../models/PostCategoriesRequestBody';
import type { PostPartnerOrganizationRequestBody } from '../models/PostPartnerOrganizationRequestBody';
import type { PostPartnerOrganizationRequestBody2 } from '../models/PostPartnerOrganizationRequestBody2';
import type { PostPartnersOrganizationResponseBody } from '../models/PostPartnersOrganizationResponseBody';
import type { PostPartnersOrganizationResponseBody2 } from '../models/PostPartnersOrganizationResponseBody2';
import type { PostPartnersRequestBody } from '../models/PostPartnersRequestBody';
import type { PostPartnersResponseBody } from '../models/PostPartnersResponseBody';
import type { PutPartnerRequestBody } from '../models/PutPartnerRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PartnersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get specific category mapping for multiple categories for event fetch.
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCategories({
        eventCategories,
    }: {
        eventCategories: (number | Array<number>),
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/categories',
            query: {
                'eventCategories': eventCategories,
            },
        });
    }
    /**
     * Get specific category mapping.
     * @returns GetCategoriesResponseBody
     * @throws ApiError
     */
    public getCategories1({
        id,
    }: {
        id: number,
    }): CancelablePromise<GetCategoriesResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/categories/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Create/update category mapping.
     * @throws ApiError
     */
    public postCategories({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PostCategoriesRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/categories/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete a category mapping.
     * @throws ApiError
     */
    public deleteCategories({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/categories/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Get Partners List.
     * @returns GetPartnersResponseBody
     * @throws ApiError
     */
    public getPartners(): CancelablePromise<GetPartnersResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partners',
        });
    }
    /**
     * Endpoint to create new partner.
     * @returns PostPartnersResponseBody
     * @throws ApiError
     */
    public postPartners({
        requestBody,
    }: {
        requestBody?: PostPartnersRequestBody,
    }): CancelablePromise<PostPartnersResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/partners',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get the last 200 actions for a partner.
     * @returns IndexResponseBody
     * @throws ApiError
     */
    public getPartnersActions({
        partnerApiKey,
    }: {
        partnerApiKey: string,
    }): CancelablePromise<IndexResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partners/actions',
            query: {
                'partnerAPIKey': partnerApiKey,
            },
        });
    }
    /**
     * Get all external partners categories that the organization has access to. Aggregates Categories from multiple partners.
     * @throws ApiError
     */
    public getPartnersCategories(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partners/categories',
        });
    }
    /**
     * Add an organization to a partner or partners.
     * @returns PostPartnersOrganizationResponseBody
     * @throws ApiError
     */
    public postPartnersOrganization({
        requestBody,
    }: {
        requestBody?: PostPartnerOrganizationRequestBody,
    }): CancelablePromise<PostPartnersOrganizationResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/partners/organization',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Gets all organization that belong to a partner.
     * @returns IndexResponseBody
     * @throws ApiError
     */
    public getPartnersOrganizations({
        partnerApiKey,
    }: {
        partnerApiKey: string,
    }): CancelablePromise<IndexResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partners/organizations',
            query: {
                'partnerAPIKey': partnerApiKey,
            },
        });
    }
    /**
     * Fetch partner information.
     * @returns GetPartnersResponseBody2
     * @throws ApiError
     */
    public getPartners1({
        id,
    }: {
        id: number,
    }): CancelablePromise<GetPartnersResponseBody2> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partners/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Endpoint to create new partner.
     * @throws ApiError
     */
    public putPartners({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PutPartnerRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/partners/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Endpoint to delete the partner.
     * @returns DeletePartnersResponseBody
     * @throws ApiError
     */
    public deletePartners({
        id,
    }: {
        id: number,
    }): CancelablePromise<DeletePartnersResponseBody> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/partners/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Add an organization to a partner or partners.
     * @returns PostPartnersOrganizationResponseBody2
     * @throws ApiError
     */
    public postPartnersOrganization1({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PostPartnerOrganizationRequestBody2,
    }): CancelablePromise<PostPartnersOrganizationResponseBody2> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/partners/{id}/organization',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Add an organization to a partner or partners.
     * @throws ApiError
     */
    public deletePartnersOrganization({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/partners/{id}/organization',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Get list of the partner organizations.
     * @returns GetPartnersOrganizationsResponseBody
     * @throws ApiError
     */
    public getPartnersOrganizations1({
        id,
    }: {
        id: number,
    }): CancelablePromise<GetPartnersOrganizationsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partners/{id}/organizations',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Get all partners that an organization is a member of.
     * @returns GetPartnersOrganizationResponseBody
     * @throws ApiError
     */
    public getPartnersOrganization({
        organizationId,
    }: {
        organizationId: number,
    }): CancelablePromise<GetPartnersOrganizationResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partners/{organizationId}/organization',
            path: {
                'organizationId': organizationId,
            },
        });
    }
}
