/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { PostPlansRequestBody } from '../models/PostPlansRequestBody';
import type { PutPlansRequestBody } from '../models/PutPlansRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PlansService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetPlans
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPlans(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/plans',
        });
    }
    /**
     * PostPlans
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPlans({
        requestBody,
    }: {
        requestBody?: PostPlansRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/plans',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetPlansFeatures
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPlansFeatures(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/plans/features',
        });
    }
    /**
     * DeletePlansPlanid
     * @returns EmptyObject
     * @throws ApiError
     */
    public deletePlans({
        newPlanId,
        planId,
    }: {
        newPlanId: number,
        planId: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/plans/{planId}',
            path: {
                'planId': planId,
            },
            query: {
                'newPlanId': newPlanId,
            },
        });
    }
    /**
     * GetPlansPlanid
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPlans1({
        planId,
    }: {
        planId: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/plans/{planId}',
            path: {
                'planId': planId,
            },
        });
    }
    /**
     * PutPlansPlanid
     * @returns EmptyObject
     * @throws ApiError
     */
    public putPlans({
        planId,
        requestBody,
    }: {
        planId: number,
        requestBody?: PutPlansRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/plans/{planId}',
            path: {
                'planId': planId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
