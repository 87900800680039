/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { PostAltarUsersRequestBody } from '../models/PostAltarUsersRequestBody';
import type { PutAltarEmployeeRequestBody } from '../models/PutAltarEmployeeRequestBody';
import type { PutAltarPermissionsRequestBody } from '../models/PutAltarPermissionsRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AltarService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetBlockedEmails
     * @returns EmptyObject
     * @throws ApiError
     */
    public getAltarBlockedEmails({
        email,
    }: {
        email?: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/altar/blocked-emails',
            query: {
                'email': email,
            },
        });
    }
    /**
     * DeleteBlockedEmail
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteAltarBlockedEmails({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/altar/blocked-emails/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetAltarChurches
     * Get Altar churches
     * @returns EmptyObject
     * @throws ApiError
     */
    public getAltarChurches(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/altar/churches',
        });
    }
    /**
     * GetAltarCustomersCsvIdnxtyb6lbgr4olqlaghmvsBmuvn
     * Customer feed
     * @returns EmptyObject
     * @throws ApiError
     */
    public getAltarCustomersCsvIdNxTyb6LbgR4OLqlaGhMVsbmuVn(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/altar/customers/csv/idNXTyb6LbgR4oLQLAGhMVsbmuVn',
        });
    }
    /**
     * PutAltarEmployee
     * @returns EmptyObject
     * @throws ApiError
     */
    public putAltarEmployee({
        requestBody,
    }: {
        requestBody?: PutAltarEmployeeRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/altar/employee',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeleteAltarEmployeeUserid
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteAltarEmployee({
        userId,
    }: {
        userId: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/altar/employee/{userId}',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * SMS_log
     * SMS log
     * @returns EmptyObject
     * @throws ApiError
     */
    public getAltarLogsSmsCsv(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/altar/logs/sms/csv',
        });
    }
    /**
     * Get_People_Communication_Information
     * @returns EmptyObject
     * @throws ApiError
     */
    public getAltarPeopleDetails(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/altar/people-details',
        });
    }
    /**
     * Approve_a_person_for_sending
     * @returns EmptyObject
     * @throws ApiError
     */
    public postAltarPeopleDetailsApproveEmail({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/altar/people-details/{id}/approve-email',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetAltarPermissions
     * @returns EmptyObject
     * @throws ApiError
     */
    public getAltarPermissions(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/altar/permissions',
        });
    }
    /**
     * AltarGetSitesPoolStatus
     * Get Sites Pool Status
     * @returns EmptyObject
     * @throws ApiError
     */
    public getAltarSitesStatus(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/altar/sites/status',
        });
    }
    /**
     * GetAltarUsers
     * Get Altar users
     * @returns EmptyObject
     * @throws ApiError
     */
    public getAltarUsers(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/altar/users',
        });
    }
    /**
     * PostAltarUsers
     * Create User
     * @returns EmptyObject
     * @throws ApiError
     */
    public postAltarUsers({
        requestBody,
    }: {
        requestBody?: PostAltarUsersRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/altar/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * PutAltarUseridPermissions
     * @returns EmptyObject
     * @throws ApiError
     */
    public putAltarPermissions({
        userId,
        requestBody,
    }: {
        userId: number,
        requestBody?: PutAltarPermissionsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/altar/{userId}/permissions',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
