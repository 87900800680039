import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../redux';
import { AbsenceView, FilterType } from '../store/filters';
import {
  CategoryFilterPayload,
  ToggleCategory,
  ToggleSelectAllCategories,
} from '../store/filters/Actions';
import { getAbsenceView, getShowAbsence } from '../store/filters/Selectors';

export const useAbsenceView = (): AbsenceView => useSelector(getAbsenceView);
export const useShowAbsence = (): boolean => useSelector(getShowAbsence);

export const isResourceSelected = (id: number) =>
  createSelector(
    (state: AppState) => state.calendar.filters.selectedResources,
    (selectedResources) => selectedResources[id]
  );

export const isUserSelected = (id: number) =>
  createSelector(
    (state: AppState) => state.calendar.filters.selectedUsers,
    (selectedUsers) => selectedUsers[id]
  );

export const useGetCalendar = () => {
  const calendarData = useSelector(
    (state: AppState) => state.calendar.filters.calendarData,
    _.isEqual
  );
  return useCallback(
    (calendarId: number, calendarType: FilterType) => {
      switch (calendarType) {
        case FilterType.resources:
          return calendarData.resources[calendarId];
        case FilterType.users:
          return calendarData.users[calendarId];
        default:
          return false;
      }
    },
    [calendarData]
  );
};

export const useToggleSelectAllCategories = () => {
  const dispatch = useDispatch();
  return useCallback(
    (id, lowercaseSearchTerm) => {
      dispatch(
        ToggleSelectAllCategories({ churchId: id, lowercaseSearchTerm })
      );
    },
    [dispatch]
  );
};

export const useOnToggleCategory = () => {
  const dispatch = useDispatch();
  return useCallback(
    (payload: CategoryFilterPayload) => {
      dispatch(ToggleCategory(payload));
    },
    [dispatch]
  );
};

export const useGetIsCategorySelected = () => {
  const selectedChurchCategories = useSelector(
    (state: AppState) => state.calendar.filters.selectedChurchCategories
  );
  return useCallback(
    (churchId: number, categoryId: number) =>
      _.get(selectedChurchCategories, `[${churchId}][${categoryId}]`, false),
    [selectedChurchCategories]
  );
};
