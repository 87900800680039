import React, { Suspense } from 'react';
import { Button, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

import { I18nService } from '../../services/I18nService';
import { showCreateUserModal } from '../components/CreateUserModal';
import { useHasPrivilege } from '../store/permissions';
import CdrPageLoader from '../../shared/components/CdrPageLoader';

import { UserList } from './UserList';

import { CdPage } from '@/react/shared/components/cd-page/CdPage';

export const SettingsUserListPageHOC = () => (
  <Suspense fallback={<CdrPageLoader />}>
    <SettingsUserListPage />
  </Suspense>
);

export const SettingsUserListPage = () => {
  const canCreateUser = useHasPrivilege('user.create');

  return (
    <CdPage
      pageHeaderProps={{
        extra: [
          <Button
            disabled={!canCreateUser}
            type="primary"
            key="create-user"
            onClick={() => showCreateUserModal({})}
          >
            <Space>
              <FontAwesomeIcon icon={faUserPlus} />
              {I18nService.getString('New user')}
            </Space>
          </Button>,
        ],
        title: I18nService.getString('Users'),
        subTitle: I18nService.getString(
          'Create, edit or delete users and manage access and permissions'
        ),
        backIcon: false,
      }}
    >
      <UserList />
    </CdPage>
  );
};
