import { useEffect, useMemo } from 'react';
import { intersection } from 'lodash';
import { FormInstance } from 'antd';

import cdApp from '../../../config';
import AuthorizationService from '../../../services/AuthorizationService';
import { EventType } from '../../models/calendar';

import { useAvailabilities } from './use-availabilities';

export const useCalendarEntry = (
  form: FormInstance,
  currentValues: any,
  isEditMode: boolean
) => {
  const { groupMembers, selectedTaxonomy, isLoading } = useAvailabilities(
    currentValues?.mainCategory
  );
  const canCreateAbsenceAndBook = AuthorizationService.hasPermission(
    'canCreateAbsenceAndBook'
  );

  const users = useMemo(() => {
    if (currentValues?.type === EventType.Availability) {
      return groupMembers.map((userId) => ({
        value: Number(userId),
        label: cdApp.data.calendar.filters.users[userId].name,
      }));
    } else if (canCreateAbsenceAndBook) {
      return Object.keys(cdApp.data.calendar.filters.users).map((userId) => ({
        value: Number(userId),
        label: cdApp.data.calendar.filters.users[userId].name,
      }));
    } else {
      return [{ value: Number(cdApp.me.id), label: cdApp.me.fullName }];
    }
  }, [currentValues?.type, canCreateAbsenceAndBook, groupMembers]);

  useEffect(() => {
    if (currentValues?.type === EventType.Availability) {
      const areSelectedBookingOptionsAllInTaxonomy =
        intersection(
          selectedTaxonomy?.bookingOptions?.map((option) => option.id),
          currentValues?.bookingOptionIds
        ).length === currentValues?.bookingOptionIds?.length;

      // On main category change, clear booking options if they are not in the new taxonomy
      if (
        (!areSelectedBookingOptionsAllInTaxonomy ||
          !currentValues?.mainCategory) &&
        !isLoading
      ) {
        form.setFieldValue('bookingOptionIds', []);
      }

      // On main category change, clear users if they are not in the new taxonomy
      if (
        !users
          .map((user) => user.value)
          .includes(currentValues?.user || currentValues?.users) &&
        !isLoading &&
        !isEditMode
      ) {
        form.setFieldValue('user', null);
        form.setFieldValue('users', null);
      }

      // What happens to the availabilities of blocked users and their requests
      // What happens to users that have been removed from a group tied to a booking page. Are the availabilities still active? Can you still see enquiries as long as it was for you?

      if (currentValues?.mainCategory) {
        form.validateFields(['bookingOptionIds', 'users']);
      }
    }
  }, [
    form,
    currentValues?.mainCategory,
    currentValues?.type,
    currentValues?.users,
    currentValues?.bookingOptionIds,
    selectedTaxonomy?.bookingOptions,
    users,
    currentValues?.user,
    isLoading,
    isEditMode,
  ]);

  return { users };
};
