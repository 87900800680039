import {
  faGlobeAmericas,
  faInbox,
  faLock,
  faRss,
  faUserClock,
} from '@fortawesome/free-solid-svg-icons';
import { faCalendarTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EventApi } from '@fullcalendar/core';
import { Space } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import { AttendanceStatus, EventType } from '../../models/calendar';
import CalendarViewService from '../../services/CalendarViewService';
import { HolyDayTypes } from '../../services/HolidayService';
import { CalendarEventPopover } from '../CalendarEventPopover';
import { useCalendarPopover } from '../pop-overs/use-calendar-popover';

import { ExtraInfo } from './ExtraInfo';

import { CleanupTime } from '@/react/calendar/components/event-item/CleanupTime';
import { EventItemUi } from '@/react/calendar/components/event-item/EventItemUi';
import { PrepTime } from '@/react/calendar/components/event-item/PrepTime';
import { getEventColors } from '@/react/calendar/components/event-item/colors';
import { getDuration } from '@/react/calendar/components/event-item/utils';
import { I18nService } from '@/react/services/I18nService';
import { CdAvailbility } from '@/react/shared/components/Icons';
import { Resource } from '@/react/shared/models/resource';
import { getCommaFormattedNumber } from '@/react/shared/utils';

interface EventProps {
  event: EventApi;
  currentView: string;
  showResources?: boolean;
  showIntentions?: boolean;
  showPrepTime?: boolean;
  hasIntentions?: boolean;
  titleHeight?: number;
  onDoubleClick: (event: EventApi) => void;
  isPlaceHolder?: boolean;
  isResizing?: boolean;
  isDragging?: boolean;
  resources?: Resource[];
  renderPopover?: boolean;
  popoverIndex: string;
}

const getPrepTimeLabel = (type: string, minutes: number): string => {
  if (minutes < 30) return;

  // Show hour
  if (minutes >= 60) {
    const numHours = getCommaFormattedNumber(minutes / 60, 1);
    return type === 'Prep'
      ? I18nService.getPlural(
          parseFloat(numHours.replace(',', '.')),
          '1h. preparation',
          '{{ numHours }}h. preparation',
          {
            numHours,
          }
        )
      : I18nService.getPlural(
          parseFloat(numHours.replace(',', '.')),
          '1h. clean up',
          '{{ numHours }}h. clean up',
          {
            numHours,
          }
        );
  } else {
    // Show minutes
    return type === 'Prep'
      ? I18nService.getString('{{amount}} min. preparation', {
          amount: minutes,
        })
      : I18nService.getString('{{amount}} min. clean up', {
          amount: minutes,
        });
  }
};

const EventItemContent: FunctionComponent<EventProps> = (props) => {
  const {
    event,
    currentView,
    showResources = true,
    onDoubleClick,
    showPrepTime,
    showIntentions,
    isResizing,
    popoverIndex,
  } = props;
  const eventResources = event?.extendedProps?.resources
    ? event.extendedProps.resources
    : {};
  const resources = _.keys(eventResources).map((key) => ({
    id: key,
    ...eventResources[key],
  }));
  const duration = getDuration(event, isResizing);
  const titleRef = useRef<HTMLDivElement>();
  const [titleHeight, setTitleHeight] = useState(0);
  const isNationalHoliday =
    event.extendedProps.holidayType === HolyDayTypes.NATIONAL;
  const isLiturgicalHoliday =
    event.extendedProps.holidayType === HolyDayTypes.LITURGICAL;
  const isPast = event.extendedProps.isPast;
  const colorIndex = event.extendedProps.colorIndex;
  const isAbsence = event?.extendedProps?.type === 'absence';
  const isPlaceHolder = event.title === '' && event.id === '';
  const isFeed = event?.extendedProps?.type === EventType.Feed;
  const isCollection = event?.extendedProps?.type === 'collection';
  const entryType = event?.extendedProps?.type as EventType;

  // Event section lengths
  const prepTimeMinutes = showPrepTime
    ? _.get(event, 'extendedProps.prepTimeMinutes')
    : 0;
  const cleanupTimeMinutes = showPrepTime
    ? _.get(event, 'extendedProps.cleanupTimeMinutes')
    : 0;
  const eventMinutes = duration * 60;

  // Prep time booleans
  const hasPrepTime = showPrepTime ? prepTimeMinutes > 0 : false;
  const hasCleanupTime = showPrepTime ? cleanupTimeMinutes > 0 : false;
  const shouldShowPrepAndCleanupTime = showPrepTime
    ? currentView === 'timeGridDay' ||
      currentView === 'timeGridWeek' ||
      currentView === 'resourceTimeGridDay'
    : false;

  // Intentions
  const hasIntentions =
    _.get(event, 'extendedProps.noOfIntentions') > 0 ||
    _.get(event, 'extendedProps.noOfStoles') > 0;

  const { openTooltip } = useCalendarPopover(popoverIndex);

  useEffect(() => {
    if (titleRef.current && titleRef.current) {
      setTitleHeight(titleRef.current.clientHeight);
    }
  }, [event.start, event.end, event.title, titleHeight]);

  let clickTimeout = null;

  const colors = getEventColors({
    colorIndex,
    isPast,
    isLiturgicalHoliday,
    isNationalHoliday,
    isFeed,
    isCollection,
    isAbsence,
    isPlaceHolder,
    replyStatus: event.extendedProps.status as AttendanceStatus,
    type: event?.extendedProps?.type || EventType.Event,
    name: event.title,
  });

  return (
    <EventItemUi
      id={`cd_event_item_${
        event.id || event.title?.length > 0 ? event.id || event.title : 'new'
      }`}
      event={event}
      type={event?.extendedProps?.type || EventType.Event}
      currentView={currentView}
      showResources={showResources}
      showIntentions={showIntentions}
      showPrepTime={showPrepTime}
      allDay={event.allDay}
      duration={duration}
      hasResources={resources && resources.length > 0}
      hasIntentions={hasIntentions}
      hasPrepTime={hasPrepTime}
      hasCleanupTime={hasCleanupTime}
      prepTimeMinutes={prepTimeMinutes}
      cleanupTimeMinutes={cleanupTimeMinutes}
      eventMinutes={eventMinutes}
      isNationalHoliday={isNationalHoliday}
      isCollection={isCollection}
      isLiturgicalHoliday={isLiturgicalHoliday}
      isPast={isPast}
      isAbsence={isAbsence}
      isFeed={isFeed}
      replyStatus={event.extendedProps.status as AttendanceStatus}
      backgroundColor={colors.backgroundColor}
      borderColor={colors.mainColor}
      borderLightColor={colors.lightColor}
      textColor={colors.textColor}
      onClick={(e) => {
        CalendarViewService.unselect();
        if (clickTimeout !== null) {
          if (event.extendedProps.isMinimizedAbsences) return;
          onDoubleClick(event);
          clearTimeout(clickTimeout);
          clickTimeout = null;
        } else {
          clickTimeout = setTimeout(() => {
            clearTimeout(clickTimeout);
            openTooltip(e);
            clickTimeout = null;
          }, 200);
        }
      }}
      isPlaceHolder={isPlaceHolder}
      isExternal={event.extendedProps.isExternal}
    >
      {hasPrepTime && shouldShowPrepAndCleanupTime ? (
        <PrepTime
          backgroundColor={colors.prepAndCleanup.backgroundColor}
          textColor={colors.lightTextColor}
          borderColor={colors.lightColor}
          prepTimeMinutes={prepTimeMinutes}
        >
          <div className="fc-prep-title u-text-italic">
            {getPrepTimeLabel('Prep', prepTimeMinutes)}
          </div>
        </PrepTime>
      ) : null}
      <div className="fc-content-wrapper">
        <div className="fc-main-content" ref={titleRef}>
          <div className="time-container">
            {' '}
            {!event.allDay &&
            event.extendedProps.holidayType !== HolyDayTypes.LITURGICAL &&
            event.extendedProps.holidayType !== HolyDayTypes.NATIONAL &&
            event.start ? (
              <span className="fc-time">
                {' '}
                {moment(event.start)
                  .add(prepTimeMinutes, 'minutes')
                  .format('HH:mm')}
              </span>
            ) : null}
            {currentView !== 'dayGridMonth' &&
            duration >= 1 &&
            !event.allDay &&
            event.end ? (
              <span className="fc-time">
                {' '}
                -{' '}
                {moment(event.end)
                  .subtract(cleanupTimeMinutes, 'minutes')
                  .format('HH:mm')}{' '}
              </span>
            ) : null}
          </div>
          <Space
            className="fc-title"
            size={2}
            align="start"
            title={event.title}
          >
            {event.extendedProps.visibility === 'public' && (
              <FontAwesomeIcon icon={faGlobeAmericas} />
            )}
            {event.extendedProps.visibility === 'private' && (
              <FontAwesomeIcon icon={faLock} />
            )}
            {event.extendedProps.type === 'absence' && (
              <FontAwesomeIcon icon={faCalendarTimes} />
            )}
            {event.extendedProps.type === EventType.Availability && (
              <CdAvailbility />
            )}
            {event.extendedProps.type === EventType.Feed && (
              <FontAwesomeIcon icon={faRss} />
            )}
            {event.extendedProps.type === 'external' && (
              <FontAwesomeIcon icon={faUserClock} />
            )}
            {event.extendedProps.type === 'collection' && (
              <FontAwesomeIcon icon={faInbox} />
            )}
            {event.title ? event.title : I18nService.getString('(No title)')}
          </Space>
        </div>
        {!(duration <= 0.5 && currentView !== 'dayGridMonth') ? (
          <ExtraInfo
            event={event}
            currentView={currentView}
            resources={
              entryType === EventType.Availability
                ? event.extendedProps.bookingOptions
                : resources
            }
            titleHeight={titleHeight}
            isResizing={isResizing}
            showResources={showResources}
            showIntentions={showIntentions}
            hasIntentions={hasIntentions}
          />
        ) : null}
      </div>
      {hasCleanupTime && shouldShowPrepAndCleanupTime ? (
        <CleanupTime
          backgroundColor={colors.prepAndCleanup.backgroundColor}
          textColor={colors.lightTextColor}
          borderColor={colors.lightColor}
          cleanupTimeMinutes={cleanupTimeMinutes}
        >
          <div className="fc-cleanup-title u-text-italic">
            {getPrepTimeLabel('Clean up', cleanupTimeMinutes)}
          </div>
        </CleanupTime>
      ) : null}
    </EventItemUi>
  );
};

export const EventItem: FunctionComponent<EventProps> = (props) => {
  const { event, popoverIndex } = props;

  return (
    <CalendarEventPopover event={event} popoverIndex={popoverIndex}>
      <EventItemContent {...props} />
    </CalendarEventPopover>
  );
};
