import React, { Suspense, useCallback, useEffect, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useParams,
} from 'react-router';
import { Button, Dropdown, Space } from 'antd';

import { I18nService } from '../../../services/I18nService';
import { CdPage } from '../../../shared/components/cd-page/CdPage';
import { CategoriesTabs } from '../../components/categories/CategoriesTabs';
import { CreateCategoryModal } from '../../components/categories/CreateCategoryModal';
import { DeleteCategoryModal } from '../../components/categories/DeleteCategoryModal';

import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import {
  CdAbsence,
  CdAngleDown,
  CdBlog,
  CdCalendar,
  CdPlus,
} from '@/react/shared/components/Icons';
import { showCalendarSubscribeModal } from '@/react/calendar/components/CalendarSubscribeModal';

export const TaxonomyPageWrapper = () => (
  <Suspense fallback={<CdrPageLoader marginLess />}>
    <BrowserRouter>
      <Routes>
        <Route path="/o/:oid/settings/calendar">
          <Route path=":type/*" element={<TaxonomyPage />} />
        </Route>
      </Routes>
      <Routes>
        <Route path="/o/:oid/settings/calendar">
          <Route path=":type/:action/:id" element={<TaxonomyPageModals />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </Suspense>
);

const TaxonomyPageModals = () => {
  const navigate = useNavigate();
  const { type, id, action, oid } = useParams();
  useEffect(() => {
    if (id) {
      if (action === 'edit') {
        CreateCategoryModal({ type, taxonomyId: id as unknown as number })
          .then(() => {
            navigate(`/o/${oid}/settings/calendar/${type}/view`);
          })
          .then(() => {
            navigate(`/o/${oid}/settings/calendar/${type}/view/`);
          });
      }
      if (action === 'export') {
        showCalendarSubscribeModal({
          feedType: type === 'event' ? 'categories' : 'absences',
          feedId: id,
        }).then(() => {
          navigate(`/o/${oid}/settings/calendar/${type}/view/`);
        });
      }
      if (action === 'delete') {
        DeleteCategoryModal({
          type: type,
          taxonomyId: id as unknown as number,
        }).then(() => {
          navigate(`/o/${oid}/settings/calendar/${type}/view/`);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return <></>;
};

const TaxonomyPage = () => {
  const navigate = useNavigate();
  const { type, oid } = useParams();
  const [currentKey, setCurrentKey] = useState(type);
  const onTabChange = useCallback(
    (key: string) => {
      navigate(`/o/${oid}/settings/calendar/${key}/view/`);
      setCurrentKey(key);
    },
    [navigate, oid]
  );
  return (
    <CdPage
      pageHeaderProps={{
        extra: [
          <Dropdown
            key="taxonomy.create"
            menu={{
              items: [
                {
                  label: I18nService.getString('Event category'),
                  key: 'event',
                  icon: <CdCalendar />,
                  onClick: () => {
                    CreateCategoryModal({ type: 'event' });
                    navigate(`/o/${oid}/settings/calendar/event/1/view/`);
                    setCurrentKey('event');
                  },
                },
                {
                  label: I18nService.getString('Blog category'),
                  key: 'blog',
                  icon: <CdBlog />,
                  onClick: () => {
                    CreateCategoryModal({ type: 'blog' });
                    navigate(`/o/${oid}/settings/calendar/blog/1/view/`);
                    setCurrentKey('blog');
                  },
                },
                {
                  label: I18nService.getString('Absence type'),
                  key: 'absence',
                  icon: <CdAbsence />,
                  onClick: () => {
                    CreateCategoryModal({ type: 'absence' });
                    navigate(`/o/${oid}/settings/calendar/absence/view/`);
                    setCurrentKey('absence');
                  },
                },
              ],
            }}
            destroyPopupOnHide={true}
          >
            <Button type="primary">
              <Space>
                <CdPlus />
                {I18nService.getString('Create')}
                <CdAngleDown />
              </Space>
            </Button>
          </Dropdown>,
        ],
        title: I18nService.getString('Categories'),
        subTitle: I18nService.getString('Manage your categories'),
        backIcon: false,
      }}
    >
      <CategoriesTabs activeKey={currentKey} onTabChange={onTabChange} />
    </CdPage>
  );
};
