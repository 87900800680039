import { Form } from 'antd';
import { useCallback } from 'react';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { $injector } from 'ngimport';
import { compact } from 'lodash';

import { OtherSettings } from '../types/settings.types';

import { mainApi, partnershipApi } from '@/react/api';
import cdApp from '@/react/config';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import { I18nService } from '@/react/services/I18nService';
import NotificationService from '@/react/services/NotificationService';
import { handleError } from '@/react/services/ErrorHandlingService';

export const useOtherSettings = () => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const settingsQuery = useSuspenseQuery({
    queryKey: ['settingsQuery'],
    queryFn: async () => await mainApi.get<OtherSettings>(`/church`),
  });
  const sitesQuery = useSuspenseQuery({
    queryKey: ['sitesQuery'],
    queryFn: async () =>
      await mainApi.get<{
        sitesCap: number;
        sites: { primaryDomain: string }[];
      }>(`/sites`),
  });
  const partnersQuery = useSuspenseQuery({
    queryKey: ['partnersQuery'],
    queryFn: async () =>
      await partnershipApi.get<any[]>(
        `/partners/categories?organizationId=${cdApp.organization.id}`
      ),
  });

  const refreshSettings = useCallback(
    () => queryClient.invalidateQueries({ queryKey: ['settingsQuery'] }),
    [queryClient]
  );

  const onSaveHandler = useCallback(
    () =>
      form
        .validateFields()
        .then(async (values) => {
          const response = await mainApi.post(`/church/settings`, values);
          if (response.ok) {
            NotificationService.notifySuccess(
              I18nService.getString('Your changes have been saved.')
            );
            refreshSettings();
          } else {
            throw response;
          }
        })
        .catch(() =>
          NotificationService.notifyError(
            I18nService.getString('Failed to save your ChurchDesk settings.')
          )
        ),
    [form, refreshSettings]
  );
  const cdLandingPage = `${cdApp.references.landingPage}/${
    cdApp.organization.locale.language || 'en'
  }/${cdApp.organization.id}`;

  const partnersData = partnersQuery.data?.data || [];
  const settingData = settingsQuery.data?.data;
  const initialFormValues = {
    ...settingData,
    organizationName: settingData.name,
    expirationEventInternalNote:
      settingData.expirationEventInternalNote || 'do_not_delete_automatically',
    expirationEventSensitiveNote:
      settingData.expirationEventSensitiveNote || 'do_not_delete_automatically',
    cdLandingPage,
  };

  const organizationPrimaryDomains = compact(
    sitesQuery.data?.data?.sites?.map((site) => site.primaryDomain) || []
  );
  const organizationPrimaryDomainNoMatch = !organizationPrimaryDomains.includes(
    settingData.domain
  );

  const cdAppVersion =
    (cdApp.release?.length > 30
      ? `CF-${cdApp.release.slice(0, 7)}`
      : cdApp.release) || 'no-info';

  const noteSettings = [
    {
      label: I18nService.getString("Don't delete automatically"),
      value: 'do_not_delete_automatically',
    },
    { label: I18nService.getString('30 days'), value: 30 },
    { label: I18nService.getString('60 days'), value: 60 },
    { label: I18nService.getString('90 days'), value: 90 },
    { label: I18nService.getString('365 days'), value: 365 },
  ];

  const canEdit =
    settingData &&
    Object.values(settingData.fields).some(
      (field: { canEdit: boolean }) => field.canEdit
    );

  const renewFacebookLogin = async () => {
    const facebookService: any = $injector.get('facebookService');
    await facebookService.authenticate();
    refreshSettings();
  };

  const disconnectFacebook = async () => {
    await showConfirmModal({
      title: I18nService.getString('Disconnect from Facebook'),
      message: I18nService.getString(
        "Are you sure you want to disconnect your Facebook account? You won't be able to share events to your Facebook page anymore."
      ),
      showIcon: false,
      okButtonProps: { danger: true },
      okText: I18nService.getString('Disconnect'),
      onOk: async () => {
        const response = await mainApi.post(
          `/church/settings?organizationId=${cdApp.organization.id}`,
          { facebook: null }
        );
        if (response.ok) {
          NotificationService.notifySuccess(
            I18nService.getString("You're now disconnected from Facebook.")
          );
        } else {
          handleError(response);
        }
        refreshSettings();
      },
    });
  };

  return {
    form,
    cdAppVersion,
    canEdit,
    settingData,
    organizationPrimaryDomains,
    organizationPrimaryDomainNoMatch,
    partnersData,
    noteSettings,
    onSaveHandler,
    initialFormValues,
    renewFacebookLogin,
    disconnectFacebook,
  };
};
