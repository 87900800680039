import React from 'react';
import { Form, Alert, Typography, Input, Switch, Space } from 'antd';
import { meetsContrastGuidelines, readableColor } from 'polished';
import styled from 'styled-components';
import { FormLabelAlign } from 'antd/es/form/interface';

import { I18nService } from '@/react/services/I18nService';
import ColorSelectorWithReset from '@/react/people/message-editor/newsletter-editor/components/ColorSelectorWithReset';
import { useOrganization } from '@/react/organization/hooks/useOrganization';

const { Text, Title } = Typography;

const ContentButton = styled.div`
  width: 100px;
  padding: 4px 15px;
  color: ${({ theme }) => theme.colors.darkGray};
  display: inline-block;
  border: 1px solid ${(props) => props.color};
`;

const VerticalFormItem = styled(Form.Item)`
  &&&& {
    flex-direction: column;
    .ant-form-item-control {
      flex-direction: column;
      align-items: flex-start;

      .ant-form-item-explain {
        padding-left: 16px;
      }
    }
  }
`;

export const NewsletterSettings = () => {
  const defaultFormItemOptions = {
    labelCol: { span: 8 },
    labelAlign: 'left' as FormLabelAlign,
  };

  return (
    <Space size="large" direction="vertical" style={{ display: 'flex' }}>
      <Form.Item
        name={['content', 'newsletterPreheader']}
        label={I18nService.getString(`Preheader text`)}
        help={I18nService.getString(
          `A preheader is the short summary text that follows the subject line when viewing an email from the inbox.`
        )}
        {...defaultFormItemOptions}
      >
        <Input />
      </Form.Item>

      <Form.Item key="contrast-warning" shouldUpdate noStyle>
        {({ getFieldValue }) => (
          <>
            <VerticalFormItem
              key="color"
              label={I18nService.getString(`Color`)}
              {...defaultFormItemOptions}
              className="form-item-color"
            >
              <Form.Item name={['content', 'newsletterColor']} noStyle>
                <ColorSelectorWithReset />
              </Form.Item>
            </VerticalFormItem>
            <ContrastGuidelinesWarning
              color={getFieldValue(['content', 'newsletterColor'])}
            />

            <Form.Item noStyle>
              <Text>{I18nService.getString('Preview')}</Text>
              <ColorPreview
                color={getFieldValue(['content', 'newsletterColor'])}
              />
            </Form.Item>
          </>
        )}
      </Form.Item>
      <Form.Item noStyle>
        <Title level={4} style={{ paddingBottom: 0 }}>
          {I18nService.getString('Information on events:')}
        </Title>
        <Space direction="vertical">
          <Space>
            <Form.Item
              name={['content', 'newsletterEventLocation']}
              valuePropName="checked"
              noStyle
              label=""
              {...defaultFormItemOptions}
            >
              <Switch />
            </Form.Item>
            {I18nService.getString('Add location information to the event')}
          </Space>
          <Space>
            <Form.Item
              name={['content', 'newsletterShowShortDescription']}
              valuePropName="checked"
              noStyle
              {...defaultFormItemOptions}
            >
              <Switch />
            </Form.Item>
            {I18nService.getString('Add short description to the event')}
          </Space>
        </Space>
      </Form.Item>
    </Space>
  );
};

const ColorPreview = ({ color }) => {
  const {
    organizationSettings: { newsletterColor },
  } = useOrganization();
  return (
    <div style={{ padding: '16px', background: '#e5e5e5' }}>
      <div
        style={{ padding: '16px', background: 'white', textAlign: 'center' }}
      >
        <ContentButton color={color || newsletterColor}>
          {I18nService.getString('Button')}
        </ContentButton>
      </div>
      <div
        style={{
          padding: '16px',
          background: color || newsletterColor,
          textAlign: 'center',
          color: readableColor(color || newsletterColor),
        }}
      >
        {I18nService.getString('Footer')}
      </div>
    </div>
  );
};

const ContrastGuidelinesWarning = ({ color }) => {
  if (!color) return null;
  const contrast = meetsContrastGuidelines(color, '#fff');
  return (
    !contrast.AALarge && (
      <Form.Item label=" " colon={false} noStyle>
        <Alert
          message={I18nService.getString('Warning')}
          description={I18nService.getString(
            'The color selected does not meet recommended guidelines for contrast.'
          )}
          type="warning"
          showIcon
          style={{ marginTop: '8px' }}
        />
      </Form.Item>
    )
  );
};
