import React, { useCallback, useEffect, useState } from 'react';
import { Col, InputNumber, Row } from 'antd';

import 'react-image-crop/dist/ReactCrop.css';
import { useRotaAndIntentionState } from '../../hooks/use-rota-and-intention-state';

import { I18nService } from '@/react/services/I18nService';
import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import NotificationService from '@/react/services/NotificationService';

export const showEditRotaDutyModal = createCdModal<{
  rota: any;
  isSeriesEvent: boolean;
}>(({ setModalProps, rota }) => {
  const [requiredNumber, setRequiredNumber] = useState(rota.required);
  const { updateRotaTableStateToEditTheRotaDuty } = useRotaAndIntentionState();
  const onOk = useCallback(() => {
    if (requiredNumber > 0 || !requiredNumber) {
      updateRotaTableStateToEditTheRotaDuty(rota.taskId, requiredNumber);
    } else {
      setRequiredNumber(rota.required);

      NotificationService.notifyError(
        I18nService.getString(
          'Please specify a number or leave empty for no limit.'
        )
      );
    }
  }, [
    requiredNumber,
    rota.required,
    rota.taskId,
    updateRotaTableStateToEditTheRotaDuty,
  ]);

  useEffect(() => {
    setModalProps({
      modalType: ModalType.MODAL,
      title: (
        <span
          dangerouslySetInnerHTML={{
            __html: I18nService.getString(
              'How many are required for <i>{{dutyName}}</i> at each event?',
              {
                dutyName: rota.title,
              }
            ),
          }}
        ></span>
      ),
      width: 420,
      onOk,
      okText: I18nService.getString('Save'),
    });
  }, [onOk, rota.title, setModalProps]);

  return (
    <Row>
      <Col span={24}>
        <InputNumber
          min={1}
          onChange={(e) => {
            setRequiredNumber(e);
          }}
          defaultValue={rota.required}
          value={requiredNumber}
          placeholder={I18nService.getString('No limit')}
          style={{ width: '100%' }}
        />
      </Col>
    </Row>
  );
});
