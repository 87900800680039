import { useQuery } from '@tanstack/react-query';

import { User } from '../types/User.types';

import { mainApi } from '@/react/api';

export const useUser = (userId?: number) => {
  const query = useQuery({
    queryKey: ['useUser', userId],
    queryFn: async () => {
      if (!userId) {
        return null;
      }
      const response = await mainApi.get<User>(`users/${userId}`);
      if (response.ok) {
        return response.data;
      }
      throw response;
    },
  });

  return { user: query.data, isLoading: query.isLoading };
};
