import { Form } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilCallback } from 'recoil';
import { isEmpty } from 'lodash';

import { mainApi } from '../../../api';
import NotificationService from '../../../services/NotificationService';
import { I18nService } from '../../../services/I18nService';

import { GroupInterface } from './NewGroupModal';
import { createEditPayload, createNewPayload } from './helpers';

import {
  ModalType,
  SetModalPropsType,
} from '@/react/shared/components/cd-modal-new/CdModal2';

export const useNewGroupModal = ({
  isEdit,
  initialValue,
  setModalProps,
  done,
}: {
  isEdit: boolean;
  initialValue: GroupInterface;
  setModalProps: SetModalPropsType;
  done: (any) => void;
}) => {
  const groupId = initialValue?.id;
  const [groupModalForm] = Form.useForm();
  const [rotaName, setRotaName] = useState('');

  useEffect(() => {
    if (isEdit) return;
    if (!isEmpty(rotaName)) {
      groupModalForm.setFieldsValue({
        tasks: [
          {
            title: rotaName,
          },
        ],
      });
    }
  }, [groupModalForm, isEdit, rotaName]);

  const saveGroup = useRecoilCallback(
    () => async (payload) => {
      const response = isEdit
        ? await mainApi.put(`/groups/${groupId}`, payload)
        : await mainApi.post('/groups', payload);

      if (!response.ok) {
        NotificationService.notifyError((response as any)?.data?.message);
        throw response;
      }
      return response.data;
    },
    [groupId, isEdit]
  );

  const formattedInitialValue = {
    ...initialValue,
    hasTask: isEdit ? initialValue?.tasks?.length : true,
    absenceDisabled: !initialValue?.absenceDisabled,
  };

  const onOk = useCallback(async () => {
    await groupModalForm
      .validateFields()
      .then(async (values) => {
        const payload = isEdit
          ? createEditPayload(values, initialValue)
          : createNewPayload(values);
        const response = await saveGroup(payload);
        done(response);
      })
      .catch((err) => {
        if (!isEmpty(err.errorFields)) {
          err.errorFields?.forEach((field) => {
            field.errors?.forEach((error) => {
              NotificationService.notifyError(error);
            });
          });
        }
      });
  }, [done, groupModalForm, initialValue, isEdit, saveGroup]);

  useEffect(() => {
    setModalProps({
      title: isEdit
        ? I18nService.getString('Edit group')
        : I18nService.getString('New group'),
      width: 600,
      onOk,
      okText: I18nService.getString('Save'),
      modalType: ModalType.MODAL,
    });
  }, [isEdit, onOk, setModalProps]);

  return {
    groupModalForm,
    rotaName,
    setRotaName,
    saveGroup,
    formattedInitialValue,
  };
};
