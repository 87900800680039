import React, { useEffect } from 'react';
import { Col, Form, Row, Select, Space, Typography } from 'antd';

import { useDuplicateContact } from '../hooks/useDuplicateContact';

import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import { I18nService } from '@/react/services/I18nService';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import { ContactGroupComponent } from '@/react/people/duplicate/components/ContactGroupComponent';
import { useDuplicateColumns } from '@/react/people/duplicate/hooks/useDuplicateColumns';

export const openDuplicateContactMergeModal = createCdModal<{
  duplicateId?: number;
  contactOneId?: number;
  contactTwoId?: number;
  stateParams?: any;
}>(
  ({ setModalProps, duplicateId, contactOneId, contactTwoId, stateParams }) => {
    const {
      formInitialValue,
      duplicateContactMergeForm,
      rejectDuplicateContactMerge,
      duplicateRecordData,
      isLoading,
      mergeHandler,
      onManualSelectContact,
      manualSecondaryContactDetails,
      manualBaseContactIdFromDetails,
      setManualSecondaryContactDetails,
    } = useDuplicateContact({
      duplicateId,
      contactOneId,
      contactTwoId,
      stateParams,
    });
    const { openRejectConfirmModal } = useDuplicateColumns();

    useEffect(() => {
      setModalProps({
        modalType: ModalType.POPUP,
        title: I18nService.getString('Merge duplicate contacts'),
        width: 900,
        okText: I18nService.getString('Merge'),
        alternativeButtonText: duplicateId && I18nService.getString('Reject'),
        onAlternative: () => duplicateId && openRejectConfirmModal(duplicateId),
        onOk: mergeHandler,
        okButtonProps: {
          disabled:
            manualBaseContactIdFromDetails &&
            !manualSecondaryContactDetails?.churchDeskId,
        },
      });
    }, [
      setModalProps,
      duplicateId,
      mergeHandler,
      rejectDuplicateContactMerge,
      openRejectConfirmModal,
      manualSecondaryContactDetails,
      manualBaseContactIdFromDetails,
    ]);

    return isLoading ? (
      <CdrPageLoader />
    ) : (
      <Form
        form={duplicateContactMergeForm}
        initialValues={formInitialValue}
        colon={false}
        layout="vertical"
        onValuesChange={(changedValues) => {
          if (changedValues.selectedContact) {
            const selectedContact = changedValues.selectedContact;
            const contact =
              duplicateRecordData?.contactOne?.churchDeskId === selectedContact
                ? duplicateRecordData?.contactOne
                : duplicateRecordData?.contactTwo;

            duplicateContactMergeForm.setFieldsValue({
              selectedEmail: contact?.email || '',
            });
            duplicateContactMergeForm.setFieldsValue({
              selectedPhoneNumber: contact?.phoneNumber || '',
            });
          }
        }}
      >
        <Row gutter={[0, 12]}>
          <Col span={24}>
            <Typography.Text style={{ fontWeight: 600 }}>
              {I18nService.getString(
                'Select primary contact to keep',
                null,
                'Merge Duplicate Contacts'
              )}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <Row align="middle">
              <Col span={24}>
                <Form.Item name="selectedContact">
                  <ContactGroupComponent
                    duplicateRecordData={duplicateRecordData}
                    onManualSelectContact={onManualSelectContact}
                    manualSecondaryContactDetails={
                      manualSecondaryContactDetails
                    }
                    setManualSecondaryContactDetails={
                      setManualSecondaryContactDetails
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Typography.Text style={{ fontWeight: 600 }}>
              {I18nService.getString(
                'Confirm the contact information for the final contact',
                null,
                'Merge Duplicate Contacts'
              )}
            </Typography.Text>
          </Col>
          <Col span={12} style={{ marginTop: '12px' }}>
            <Form.Item
              style={{ marginBottom: '12px' }}
              label={I18nService.getString('Email')}
              name="selectedEmail"
              dependencies={['selectedContact']}
            >
              <Select
                disabled={
                  duplicateRecordData?.contactOne?.email ===
                  duplicateRecordData?.contactTwo?.email
                }
                options={Array.from(
                  new Set(
                    [
                      duplicateRecordData?.contactOne?.email,
                      duplicateRecordData?.contactTwo?.email,
                    ].filter(Boolean)
                  )
                ).map((email) => ({
                  value: email,
                  label: email,
                }))}
              />
            </Form.Item>
            <Form.Item
              label={I18nService.getString('Phone')}
              name="selectedPhoneNumber"
              dependencies={['selectedContact']}
            >
              <Select
                disabled={
                  duplicateRecordData?.contactOne?.phoneNumber ===
                  duplicateRecordData?.contactTwo?.phoneNumber
                }
                options={Array.from(
                  new Set(
                    [
                      duplicateRecordData?.contactOne?.phoneNumber,
                      duplicateRecordData?.contactTwo?.phoneNumber,
                    ].filter(Boolean)
                  )
                ).map((phone) => ({
                  value: phone,
                  label: phone,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Typography.Text style={{ fontWeight: 600, marginBottom: '4px' }}>
              {I18nService.getString(
                'What happens when I merge the contacts?',
                null,
                'Merge Duplicate Contacts'
              )}
            </Typography.Text>
            <Space>
              <ul style={{ marginBottom: '12px' }}>
                <li>
                  {I18nService.getString(
                    'Once you merge the contact, this cannot be undone. The duplicate contact will be deleted',
                    undefined,
                    'Merge Duplicate Contacts'
                  )}
                </li>
                <li>
                  {I18nService.getString(
                    'The communication activity for both contacts will be available in the final contact',
                    undefined,
                    'Merge Duplicate Contacts'
                  )}
                </li>
                <li>
                  {I18nService.getString(
                    'All given consents and list memberships will be moved to the final contact.',
                    undefined,
                    'Merge Duplicate Contacts'
                  )}
                </li>
                <li>
                  {I18nService.getString(
                    'No data on the primary contact will be overwritten. Only empty fields on the primary contact will get data from the duplicate contact',
                    undefined,
                    'Merge Duplicate Contacts'
                  )}
                </li>
              </ul>
            </Space>
          </Col>
        </Row>
      </Form>
    );
  }
);
