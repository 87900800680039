import { react2angular } from 'react18-react2angular';

import { NotificationIndicator } from '../../../../react/shared/components/NotificationIndicator';
import sharedContext from '../shared-context-root/shared-context-root.component';

import { getSelectedChurch } from '@/react/calendar/store/filters/Selectors';
import {
  getOrganizationId,
  getOrganizations,
  getCurrentOrganization,
} from '@/react/config/store/Selector';
import PortalService from '@/react/portal/services/portal.service';
import { openingHours } from '@/react/homepage/utils/support-articles';
import { getCRMContactDetails } from '@/react/shared/services/CRMContactDetailsService';
import { navigate } from '@/react/services/StateServiceFactory';
import { showTimeRegistrationDrawer } from '@/react/user/time-registration/TimeRegistrationDrawer';
import { showTimeRegistrationDisabledModal } from '@/react/user/components/TimeRegistrationLandingPage';
import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';

class AppMenuComponent {
  constructor(
    _,
    $ngRedux,
    $rootScope,
    $scope,
    $state,
    Analytics,
    AuthenticationService,
    Authorization,
    cdApp,
    hotkeys,
    BeaconChat,
    gettextCatalog,
    supportDomain,
    FeatureToggleService,
    Church
  ) {
    'ngInject';

    this._ = _;
    this.$ngRedux = $ngRedux;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$state = $state;
    this.Analytics = Analytics;
    this.AuthenticationService = AuthenticationService;
    this.Authorization = Authorization;
    this.cdApp = cdApp;

    this.hotkeys = hotkeys;
    this.BeaconChat = BeaconChat;
    this.gettextCatalog = gettextCatalog;
    this.supportDomain = supportDomain;
    this.FeatureToggleService = FeatureToggleService;
    this.Church = Church;
  }

  $onInit() {
    const peopleEnabled = this.Authorization.hasPackage('people');
    const contributionsEnabled = this.Authorization.hasPackage('contributions');
    const formsEnabled = this.Authorization.hasPackage('forms');
    const groupsEnabled = this.Authorization.hasPackage('intranet');
    const intentionsEnabled = this.Authorization.hasPackage('intentions');
    const homepageEnabled = this.Authorization.hasPackage('homepage');
    const graveyardEnabled = this.Authorization.hasPackage('graveyard');
    const churchEventsEnabled = this.Authorization.hasPackage('churchEvents');
    const safeGuardingEnabled = this.Authorization.hasPackage('safeguarding');
    const calendarEnabled = this.Authorization.hasPackage('calendar');
    const organizationLanguage = _.get(
      this.cdApp,
      'organization.locale.language'
    );
    this.churchEventsEnabled = churchEventsEnabled;
    this.allModules = [
      {
        name: 'dashboard',
        inPlan: true,
        isHidden: false,
        href: this.$state.href('app.private.dashboard.default'),
        icon: 'fa-tachometer',
      },

      {
        name: 'calendar',
        inPlan: calendarEnabled,
        isHidden: false,
        href: this.$state.href('app.private.calendar.default'),
        icon: 'fa-calendar-alt',
      },

      {
        name: 'groups',
        inPlan: groupsEnabled,
        isHidden: false,
        href: this.$state.href('app.private.intranet.default'),
        icon: 'fa-inbox',
      },

      {
        name: 'people',
        inPlan: peopleEnabled,
        isHidden: false,
        href: this.$state.href('app.private.people.default'),
        icon: 'fa-users',
      },

      {
        name: 'contributions',
        inPlan: contributionsEnabled,
        isHidden: false,
        notifications: this.cdApp.status?.reasons,
        href: this.$state.href('app.private.contributions.default'),
        icon: 'fa-money-bill-alt',
      },

      {
        name: 'forms',
        inPlan: formsEnabled,
        isHidden: false,
        href: this.$state.href('app.private.forms.default'),
        icon: 'fa-file-alt',
      },

      {
        name: 'intention',
        inPlan: intentionsEnabled,
        isHidden: !intentionsEnabled,
        href: this.$state.href('app.private.intention.overview'),
        icon: 'fa-tasks',
      },

      {
        name: 'portal',
        inPlan: true,
        isHidden: true,
        href: this.$state.href('app.private.portal.organizations'),
      },

      {
        name: 'website',
        inPlan: homepageEnabled,
        isHidden: false,
        icon: 'fa-globe',
        href: this.$state.href('app.private.website.list'),
      },
      {
        name: 'safeguarding',
        inPlan: safeGuardingEnabled,
        isHidden: organizationLanguage !== 'de',
        href: this.$state.href('app.private.safeguarding.default', {
          filter: 'all',
        }),
      },

      {
        name: 'church-events',
        inPlan: churchEventsEnabled,
        isHidden: !churchEventsEnabled,
      },

      {
        name: 'graveyard',
        inPlan: graveyardEnabled,
        isHidden: !graveyardEnabled || !homepageEnabled,
        href: this.$state.href('app.private.website.default', {
          target: 'graveyard',
        }),

        icon: 'fa-tree',
      },
    ];
    const enabledPackages = [];
    _.forEach(this.cdApp.organization?.packages, (value, key) => {
      if (value.enabled) enabledPackages.push(key);
    });
    // By default we are making homepage availabe with all packages except terminal and contributions (donation box Add-on Terminal)
    const excludeWebsitePackage =
      enabledPackages.length < 4 &&
      enabledPackages.includes('terminal') &&
      enabledPackages.includes('contributions');
    const people = _.find(this.allModules, (page) => page.name === 'people');

    people.href = this.$state.href('app.private.people.people-announcement', {
      continue: 'people',
    });

    this.FeatureToggleService.hasFeature('church_events_blog_redirection').then(
      (hasFeature) => {
        const churchEvents = _.find(
          this.allModules,
          (page) => page.name === 'church-events'
        );
        if (hasFeature) {
          churchEvents.href =
            'https://blog.churchdesk.com/de/wir-verbessern-churchevents';
        } else {
          churchEvents.href = this.$state.href('app.private.churchevents');
        }
      }
    );

    this.Church.get().$promise.then((churchInfo) => {
      this.churchInfo = churchInfo;
      this.accountManager = churchInfo.accountManager;
      const { bookingPhone } = getCRMContactDetails(organizationLanguage);
      // Using this link as churchEvents customers does not have account managers
      this.churchEventsBookingLink = 'https://meetings.hubspot.com/jme4';
      this.churchEventsBookingLinkText =
        this.gettextCatalog.getString('Upgrade');
      this.supportData = getSupportData(organizationLanguage, bookingPhone);
      window.cdUseLandingPage = !!churchInfo.useLandingPage;
      window.cdSiteDomain = churchInfo.siteDomain;
    });

    PortalService.getOrganizationPortals({
      organizationId: window.churchdeskOrganizationId,
    })
      .then((organizationPortals) => {
        // Show the portal module ONLY if the organization is the portal host and user has access
        if (
          organizationPortals.isPortalHost &&
          organizationPortals.userHasPortalAccess
        ) {
          _.find(this.allModules, (page) => page.name === 'portal').isHidden =
            false;
        } else {
          _.find(this.allModules, (page) => page.name === 'portal').isHidden =
            true;
        }
      })
      .catch(() => {
        // Hide portal module if the organization is NOT a portal host
        _.find(this.allModules, (page) => page.name === 'portal').isHidden =
          true;
      });

    // Group modules according to whether they're included in the organization's plan
    this.modules = _.groupBy(this.allModules, (module) =>
      // The website module should always be available that the user can click on it even if it's not included in the plan
      // except for the donation box Add-on Terminal
      module.inPlan || (module.name === 'website' && !excludeWebsitePackage)
        ? 'available'
        : 'notAvailable'
    );

    if (!this.Authorization.hasPermission('canAdministerUsers')) {
      _.unset(this.modules, 'notAvailable');
    }
    /**
     * Support links and contact information
     */
    const blogDomain = 'https://blog.churchdesk.com';
    const websiteDomain = 'https://churchdesk.com';
    const getSupportData = (language, bookingPhone) => {
      const bookMeetingText = this.gettextCatalog.getString('Book a meeting');

      switch (language) {
        case 'da':
          return {
            productUpdatesUrl: `${blogDomain}/da/topic/produkt`,
            supportUrl: 'https://support.churchdesk.com/da/',
            classesUrl: 'https://churchdesk.com/da/service/kurser/',
            coursesUrl: `${websiteDomain}/da/kurser`,
            bookMeeting: {
              url: 'https://in-app-dk.youcanbook.me',
              text: bookMeetingText,
            },
            supportPhone: bookingPhone,
            openingHours: openingHours('da'),
            eventsUrl: `${websiteDomain}/da/community`,
          };

        case 'sv':
          return {
            productUpdatesUrl: `${blogDomain}/da/topic/produkt`,
            supportUrl: 'https://support.churchdesk.com/da/',
            classesUrl: 'https://churchdesk.com/da/service/kurser/',
            coursesUrl: null,
            supportPhone: bookingPhone,
            bookMeeting: {
              url: 'https://in-app-dk.youcanbook.me',
              text: bookMeetingText,
            },
            openingHours: openingHours('sv'),
            eventsUrl: `${websiteDomain}/da/community/`,
          };

        case 'de':
          return {
            productUpdatesUrl: `${blogDomain}/de/topic/neue-funktionen`,
            supportUrl: 'https://support.churchdesk.com/de/',
            classesUrl: 'https://churchdesk.com/de/service/kurse/',
            coursesUrl: null,
            supportPhone: bookingPhone,
            bookMeeting: {
              url: 'https://in-app-de.youcanbook.me',
              text: bookMeetingText,
            },
            openingHours: openingHours('de'),
            eventsUrl: `${websiteDomain}/de/community/`,
          };

        default:
          return {
            productUpdatesUrl: `${blogDomain}/topic/product`,
            supportUrl: 'https://support.churchdesk.com/en/',
            classesUrl: 'https://churchdesk.com/en/service/courses/',
            coursesUrl: null,
            supportPhone: bookingPhone,
            bookMeeting: {
              url: 'https://in-app-en.youcanbook.me',
              text: bookMeetingText,
            },
            openingHours: openingHours('en'),
            eventsUrl: `${websiteDomain}/en/community/`,
          };
      }
    };

    const getSelectedChurchObject = (churches) => {
      if (churches.length > 1) {
        return null;
      } else if (churches.length === 1) {
        return churches[0];
      } else {
        return null;
      }
    };
    // Initialize React binding
    const mapStateToScope = () => (state) => ({
      organizationId: getOrganizationId(state),
      organizations: getOrganizations(state),
      organization: getCurrentOrganization(state),
      selectedChurch: getSelectedChurchObject(getSelectedChurch(state)),
    });

    const unsubscribe = this.$ngRedux.connect(mapStateToScope)(this);

    this.$onDestroy = function () {
      unsubscribe();
    };
  }
  organizationStatus() {
    return this.cdApp.organization?.siteStatus;
  }

  isChurchDeskEmployee() {
    return this.cdApp.me?.isChurchDeskEmployee;
  }

  /**
   * Get the base app url of an organization.
   *
   * @param {Number} oid The id of the organization
   */
  getOrganizationUrl(oid) {
    return this.$state.href(
      'app.private.dashboard.default',
      {
        oid: oid,
      },

      {
        absolute: true,
      }
    );
  }

  openNoticeable() {
    window.noticeable.do('widget:open', window.noticeableId);
  }

  isNoticeableOn() {
    return window.noticeableId;
  }

  async openTimeRegistration() {
    if (this.churchInfo?.timeRegistrationCap > 0) {
      const response = await mainApi.get(
        `/users/${cdApp.me.id}/time-registrations/settings`
      );
      if (response.ok) {
        if (response.data.timeRegistrationEnabled) {
          showTimeRegistrationDrawer();
        } else {
          showTimeRegistrationDisabledModal();
        }
      } else {
        handleError(response);
      }
    } else {
      return navigate('app.private.dashboard.timeRegistration');
    }
  }

  /**
   * Check whether the specified organization is the currently selected organization.
   *
   * @param {Object} organization The organization to be checked
   */
  isOrganizationSelected(organization) {
    return (
      this._.get(organization, 'id') ===
      this._.get(this.cdApp, 'organization.id')
    );
  }

  /**
   * Check whether the specified church is the currently selected church.
   *
   * @param {Object} church The church to be checked
   */
  isChurchSelected(church) {
    return this._.get(church, 'id') === this._.get(this.cdApp, 'church.id');
  }

  isMultiChurch(organization) {
    return (
      organization && organization.churches && organization.churches.length > 1
    );
  }

  /**
   * Set an organization and (optionally) a church as the main organization and church
   * the logged-in user will use for filtering data in the application different views, and
   * if the selected organization changed, reload the page to refresh the page content.
   *
   * @param {Object} organization The organization to be checked
   * @param {Object} [church] The church to be checked
   */
  switchOrganizationAndChurch(organization, church) {
    const previousOrganizationId = this._.get(cdApp, 'organization.id');
    const selectedOrganizationId = this._.get(organization, 'id');

    /**
     * If the organization changes, reload the page (the selected church,
     * if any, will be loaded in the component's constructor).
     */
    if (previousOrganizationId !== selectedOrganizationId) {
      window.location = this.$state.href(
        'app.private.dashboard.default',
        {
          oid: selectedOrganizationId,
        },

        {
          absolute: true,
        }
      );
    } else {
      // If the organization did NOT change, set the church only
      _.set(cdApp, 'church', church);
    }
  }

  /**
   * Toggle the shortcut cheatsheet
   */
  toggleCheatSheet() {
    this.hotkeys.toggleCheatSheet();
  }

  /**
   * Open the Messenger.
   */
  openBeaconChat() {
    if (this.BeaconChat.isBeaconActive()) {
      this.BeaconChat.show();
    } else {
      window.open('mailto:support@churchdesk.com');
    }
  }

  /**
   * Get the icon of the current module
   */
  getCurrentModuleIcon() {
    return this._.result(
      this._.find(this.allModules, { name: this.$rootScope.currentModule }),
      'icon'
    );
  }

  /**
   * Open the create content window
   */
  openCreateContentWindow(type) {
    this.$rootScope.openCreateContentWindow(type);
    this.Analytics.sendFeatureEvent(`create ${type} via green button`, {
      module: this.$rootScope.currentModule,
    });
  }

  /**
   * Log out the user
   */
  logOut() {
    this.AuthenticationService.logout(true);
  }
}
AppMenuComponent.$inject = [
  '_',
  '$ngRedux',
  '$rootScope',
  '$scope',
  '$state',
  'Analytics',
  'AuthenticationService',
  'Authorization',
  'cdApp',
  'hotkeys',
  'BeaconChat',
  'gettextCatalog',
  'supportDomain',
  'FeatureToggleService',
  'Church',
];

angular
  .module('cdApp.shared')
  .component(
    'cdModuleRedDot',
    react2angular(
      sharedContext.use(NotificationIndicator),
      ['notifications', 'count'],
      []
    )
  )
  .component('cdAppMenu', {
    template: require('./app-menu.component.html'),
    controller: AppMenuComponent,
  })
  .directive('cdSetWidth', [
    'Me',
    '$timeout',
    (Me, $timeout) => ({
      link(scope, element) {
        Me.then(() => {
          $timeout(() => {
            const itemsLength = element.children().length;
            element.css('min-width', 150 * itemsLength + 'px');
            element
              .children()
              .each((ind, el) => $(el).addClass('col-sm-' + 12 / itemsLength));
          });
        });
      },
    }),
  ]);
