import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined';
import { Button, Card, Dropdown, MenuProps, Space, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

import cdApp from '@/react/config';
import { getStateUrl, navigate } from '@/react/services/StateServiceFactory';
import {
  CdAngleRight,
  CdBrowserIcon,
  CdEditIcon,
} from '@/react/shared/components/Icons';
import { I18nService } from '@/react/services/I18nService';
import AuthorizationService from '@/react/services/AuthorizationService';

const WebsiteCardStyled = styled(Card)`
  &&&& {
    .cd-old-website-button {
      height: 216px;
      background: #008fb4;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .ant-card-cover {
      position: relative;
    }
    .open-designer {
      position: absolute;
      top: 0;
      height: 100%;
      background: #00000040;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 22px;
      opacity: 0;
      width: 100%;
    }
    &:hover .open-designer {
      opacity: 1;
    }
  }
`;

export const WebsiteCardDrupal = () => {
  const editHomepageUrl = getStateUrl('app.private.website.default');
  const canEditOldWebsite =
    AuthorizationService.hasPermission('canAccessHomepage');

  const items: MenuProps['items'] = [
    {
      label: I18nService.getString('Edit website'),
      icon: <CdEditIcon fixedWidth />,
      onClick: () => {
        canEditOldWebsite && navigate('app.private.website.default');
      },
      key: '0',
    },
  ];

  return (
    <WebsiteCardStyled
      hoverable
      style={{ width: 270 }}
      bodyStyle={{ padding: '0px' }}
      cover={
        <a
          href={canEditOldWebsite && editHomepageUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="cd-old-website-button">
            <CdBrowserIcon
              size="3x"
              style={{ color: 'white', marginTop: '24px', height: '80px' }}
            />
            {(canEditOldWebsite && (
              <Typography.Title
                level={5}
                style={{ color: 'white', marginTop: '0px' }}
              >
                {I18nService.getString('Edit old website')}
              </Typography.Title>
            )) || (
              <Typography.Title
                level={5}
                style={{ color: 'white', marginTop: '0px' }}
              >
                {I18nService.getString('Old website')}
              </Typography.Title>
            )}
            <Typography.Text
              type="secondary"
              style={{ color: 'white', marginTop: '0px' }}
            >
              {I18nService.getString('Will be deleted January 15th')}
            </Typography.Text>
          </div>
          {canEditOldWebsite && (
            <div className="open-designer">
              <Space
                style={{
                  justifyContent: 'center',
                  textAlign: 'center',
                  margin: '0px 16px',
                }}
              >
                {I18nService.getString('Open designer')}
                <CdAngleRight />
              </Space>
            </div>
          )}
        </a>
      }
      actions={[
        <Button
          type="text"
          target="_blank"
          href={`${cdApp.organization.aegirInstallationUrl}`}
          disabled={!canEditOldWebsite}
          key="view"
        >
          {I18nService.getString('View site')}
        </Button>,
        canEditOldWebsite && (
          <Dropdown menu={{ items }} key="actions">
            <Button type="text" onClick={(e) => e.preventDefault()}>
              <Space>
                {I18nService.getString('Actions')}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        ),
      ].filter(Boolean)}
    />
  );
};
