import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image, Modal, Tabs } from 'antd';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled, { createGlobalStyle } from 'styled-components';

import { apiInstance } from '../../api';
import {
  getGebAccess,
  fileArchiveGetTags,
  closeModal,
} from '../../store/actions';
import { loadLookups } from '../../store/actions/lookups';
import FileArchive from '../file-archive';
import Partner from '../partner';

import {
  makeSelectCallback,
  makeSelectIsGebCustomer,
  makeSelectIsUsedFromEditor,
} from './selectors';

import { I18nService } from '@/react/services/I18nService';

const { TabPane } = Tabs;

const GlobalStyle = createGlobalStyle`
  .antd-ns div.ant-select-dropdown {
    z-index: 99999999 !important;
  }
  div.cke_reset_all {
    z-index: 900;
  }

  .cke_dialog_background_cover {
    z-index: 800 !important;
  }
  .antd-ns .ant-select-multiple .ant-select-selection-item {
    background: none;
    border: none;
    margin-right: 0;
    padding-right: 0;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    display: none;
  }
`;

const mapToStateProps = createStructuredSelector({
  callback: makeSelectCallback(),
  isGebCustomer: makeSelectIsGebCustomer(),
  isUsedFromEditor: makeSelectIsUsedFromEditor(),
});

const mapDispatchToProps = (dispatch: any) => ({
  onCloseModal: () => dispatch(closeModal()),
  getGebAccess: (partner: string) => dispatch(getGebAccess({ partner })),
  fileArchiveGetTags: () => dispatch(fileArchiveGetTags()),
});

interface ownProps {
  isUsedFromEditor: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  callback: Function;
  showModal: boolean;
  onCloseModal: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onClose?: () => void;
}

interface Props {
  showModal: boolean;
  isGebCustomer: boolean;
  isUsedFromEditor: boolean;
  onCloseModal: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  callback: any;
  getGebAccess: any;
  onClose?: () => void;
}

const StyledTabsContainer = styled.div<{
  $isGermanCustomer: boolean;
}>`
    .ant-tabs-card {
      .ant-tabs-bar {
        border-color: #fff;
        background-color: #eeeeee;
        margin: 0;
        display: ${(props) => (props.$isGermanCustomer ? 'block' : 'none')};

        .ant-tabs-nav-container {
          height: inherit;

          .ant-tabs-tab {
            height: 55px;
            padding: 10px 16px 10px;
            border-color: transparent;
            background: transparent;
            line-height: 2.5;
            margin: 0;

            &.ant-tabs-tab-active,
            &:hover {
              border-color: #fff;
              background: #fff !important;
            }
          }
        }
      }
    }
  `;

export const FilePicker: React.FC<Props> = ({
  showModal,
  isGebCustomer,
  isUsedFromEditor,
  onCloseModal,
  callback,
  getGebAccess,
  onClose,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    getGebAccess('geb');
    dispatch(loadLookups());
    dispatch(fileArchiveGetTags());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback]);
  const isGermanCustomer = apiInstance.isGermanCustomer();

  const onCancel = () => {
    if (onClose) {
      // Notify the component that launched the FilePicker that it is being closed.
      onClose();
    }
    // Close the modal

    onCloseModal();
  };

  return (
    <div className="antd-ns">
      <StyledModal
        title={I18nService.getString('Image picker')}
        width="95vw"
        centered
        bodyStyle={{ padding: '0' }}
        open={showModal}
        onCancel={onCancel}
        keyboard={true}
        zIndex={1090}
        closeIcon={<FontAwesomeIcon icon={faTimes} />}
        footer={null}
        destroyOnClose={true}
        getContainer={() =>
          document.getElementsByClassName('antd-ns').item(0) as HTMLElement
        }
      >
        <GlobalStyle />
        <StyledTabsContainer $isGermanCustomer={isGermanCustomer}>
          <Tabs type="card" defaultActiveKey="1">
            <TabPane tab={I18nService.getString('Files')} key="1">
              <FileArchive
                callback={callback}
                isUsedFromEditor={isUsedFromEditor}
              />
            </TabPane>
            {isGermanCustomer ? (
              <TabPane
                tab={
                  <Image
                    src="/img/gemeindebrief.png"
                    height={28}
                    preview={false}
                  />
                }
                key="2"
              >
                <Partner
                  partnerId="geb"
                  isGebCustomer={isGebCustomer}
                  callback={callback}
                  isUsedFromEditor={isUsedFromEditor}
                />
              </TabPane>
            ) : null}
          </Tabs>
        </StyledTabsContainer>
      </StyledModal>
    </div>
  );
};

const connectFilePicker: React.ComponentType<ownProps> = connect(
  mapToStateProps,
  mapDispatchToProps
)(FilePicker);
export default connectFilePicker;
