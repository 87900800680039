/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateGroupResponseBody } from '../models/CreateGroupResponseBody';
import type { EmptyObject } from '../models/EmptyObject';
import type { GetOrganizationsOrganizationidSettingsResponseBody } from '../models/GetOrganizationsOrganizationidSettingsResponseBody';
import type { GetOrganizationsOrganizationidUsersUseridGroupsResponseBody } from '../models/GetOrganizationsOrganizationidUsersUseridGroupsResponseBody';
import type { GetOrganizationsPackageCollaborationpackageResponseBody } from '../models/GetOrganizationsPackageCollaborationpackageResponseBody';
import type { GetOrganizationsResponseBody } from '../models/GetOrganizationsResponseBody';
import type { GetV2OrganizationsOrganizationidResponseBody } from '../models/GetV2OrganizationsOrganizationidResponseBody';
import type { PostV2OrganizationsRequestBody } from '../models/PostV2OrganizationsRequestBody';
import type { PostV2OrganizationsResponseBody } from '../models/PostV2OrganizationsResponseBody';
import type { PostV2OrganizationsTestingsandboxRequestBody } from '../models/PostV2OrganizationsTestingsandboxRequestBody';
import type { PutOrganizationsOrganizationidSettingsRequestBody } from '../models/PutOrganizationsOrganizationidSettingsRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class OrganizationsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetOrganizations
     * Index
     * @returns GetOrganizationsResponseBody
     * @throws ApiError
     */
    public getOrganizations({
        limit,
        offset,
        orderBy = 'createdAt',
        orderDirection = 'DESC',
        filters,
    }: {
        limit?: number,
        offset?: number,
        orderBy?: 'name' | 'createdAt' | 'id',
        orderDirection?: 'ASC' | 'DESC',
        filters?: {
            search?: string;
            countries?: Array<string>;
            dioceses?: Array<string>;
            customerTypes?: Array<string>;
            accountManagers?: Array<number>;
            plans?: Array<number>;
            addons?: Array<string>;
            locked?: boolean;
        },
    }): CancelablePromise<GetOrganizationsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations',
            query: {
                'limit': limit,
                'offset': offset,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
                'filters': filters,
            },
        });
    }
    /**
     * PostOrganizationsAddRoleTemplates
     * @returns EmptyObject
     * @throws ApiError
     */
    public postOrganizationsAddRoleTemplates({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organizations/add/role/templates',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetOrganizationsBilling
     * Billing
     * @returns EmptyObject
     * @throws ApiError
     */
    public getOrganizationsBilling(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/billing',
        });
    }
    /**
     * GetOrganizationsPackageCollaborationpackage
     * Index by collaborationPackage
     * @returns GetOrganizationsPackageCollaborationpackageResponseBody
     * @throws ApiError
     */
    public getOrganizationsPackageCollaborationPackage(): CancelablePromise<GetOrganizationsPackageCollaborationpackageResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/package/collaborationPackage',
        });
    }
    /**
     * GetOrganizationsSubscription
     * Subscription, Retrieve
     * @returns EmptyObject
     * @throws ApiError
     */
    public getOrganizationsSubscription(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/subscription',
        });
    }
    /**
     * PostOrganizationsUpload
     * Upload Logo
     * @returns EmptyObject
     * @throws ApiError
     */
    public postOrganizationsUpload({
        type,
        mode = 'upload',
        requestBody,
    }: {
        type: 'main' | 'people' | 'mobile' | 'favicon',
        mode?: 'upload' | 'reset',
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organizations/upload',
            query: {
                'type': type,
                'mode': mode,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeleteOrganizationsId
     * Delete
     * @returns CreateGroupResponseBody
     * @throws ApiError
     */
    public deleteOrganizations(): CancelablePromise<CreateGroupResponseBody> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/organizations/{id}',
        });
    }
    /**
     * PutOrganizationsOrganizationid
     * Update
     * @returns EmptyObject
     * @throws ApiError
     */
    public putOrganizations({
        requestBody,
    }: {
        requestBody?: PutOrganizationsOrganizationidSettingsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/organizations/{organizationId}',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetOrganizationsOrganizationidSettings
     * DEPRECATED Load site settings
     * @returns GetOrganizationsOrganizationidSettingsResponseBody
     * @throws ApiError
     */
    public getOrganizationsSettings({
        setting,
    }: {
        /**
         * An array of setting names
         */
        setting?: any,
    }): CancelablePromise<GetOrganizationsOrganizationidSettingsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{organizationId}/settings',
            query: {
                'setting': setting,
            },
        });
    }
    /**
     * PutOrganizationsOrganizationidSettings
     * DEPRECATED Save site settings
     * @returns EmptyObject
     * @throws ApiError
     */
    public putOrganizationsSettings({
        requestBody,
    }: {
        requestBody?: PutOrganizationsOrganizationidSettingsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/organizations/{organizationId}/settings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetOrganizationsOrganizationidUsersUseridGroups
     * My groups
     * @returns GetOrganizationsOrganizationidUsersUseridGroupsResponseBody
     * @throws ApiError
     */
    public getOrganizationsUsersGroups({
        userId,
    }: {
        /**
         * The id of the user.
         */
        userId: any,
    }): CancelablePromise<GetOrganizationsOrganizationidUsersUseridGroupsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{organizationId}/users/{userId}/groups',
            query: {
                'userId': userId,
            },
        });
    }
    /**
     * PostV2Organizations
     * Create organization
     * @returns PostV2OrganizationsResponseBody
     * @throws ApiError
     */
    public postV2Organizations({
        requestBody,
    }: {
        requestBody?: PostV2OrganizationsRequestBody,
    }): CancelablePromise<PostV2OrganizationsResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/organizations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get entity where alias is already used.
     * @throws ApiError
     */
    public getV2OrganizationsAliasCheck({
        alias,
        entityId,
        entityType,
    }: {
        alias: string,
        entityId?: number,
        entityType?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/alias-check',
            query: {
                'alias': alias,
                'entityId': entityId,
                'entityType': entityType,
            },
        });
    }
    /**
     * GetV2OrganizationsStatistics
     * @returns EmptyObject
     * @throws ApiError
     */
    public getV2OrganizationsStatistics(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/statistics',
        });
    }
    /**
     * PostV2OrganizationsTestingSandbox
     * Create Testing Sandbox
     * @returns EmptyObject
     * @throws ApiError
     */
    public postV2OrganizationsTestingSandbox({
        requestBody,
    }: {
        requestBody?: PostV2OrganizationsTestingsandboxRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/organizations/testing-sandbox',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetV2OrganizationsOrganizationid
     * Retrieve
     * @returns GetV2OrganizationsOrganizationidResponseBody
     * @throws ApiError
     */
    public getV2Organizations(): CancelablePromise<GetV2OrganizationsOrganizationidResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/{organizationId}',
        });
    }
    /**
     * PutV2OrganizationsOrganizationid
     * Update
     * @returns EmptyObject
     * @throws ApiError
     */
    public putV2Organizations({
        organizationId,
        requestBody,
    }: {
        organizationId?: number,
        requestBody?: PutOrganizationsOrganizationidSettingsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/organizations/{organizationId}',
            path: {
                'organizationId': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get organization legal data
     * @throws ApiError
     */
    public getV2OrganizationsAccountInfo({
        organizationId,
    }: {
        organizationId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/{organizationId}/account/info',
            path: {
                'organizationId': organizationId,
            },
        });
    }
    /**
     * Create partner API token
     * @throws ApiError
     */
    public putV2OrganizationsApiToken({
        organizationId,
    }: {
        organizationId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/organizations/{organizationId}/apiToken',
            path: {
                'organizationId': organizationId,
            },
        });
    }
    /**
     * GetV2OrganizationsOrganizationidLocked
     * Locked
     * @returns EmptyObject
     * @throws ApiError
     */
    public getV2OrganizationsLocked(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/organizations/{organizationId}/locked',
        });
    }
}
