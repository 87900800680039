/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ParseService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * PostParse
     * Parse Emails
     * @returns EmptyObject
     * @throws ApiError
     */
    public postParse({
        apiKey,
        requestBody,
    }: {
        /**
         * The apiKey needed to pass authentication.
         */
        apiKey: any,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/parse',
            query: {
                'apiKey': apiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
