import React, { ReactNode } from 'react';
import { Space, Tag } from 'antd';

import { CdTooltip } from '../cd-tooltip/CdTooltip';

import { CdDeveloper } from '@/react/shared/components/Icons';

export const CdDeveloperTag = (props: {
  children?: ReactNode | undefined;
  tooltip: string;
}) => {
  const isDeveloper =
    window.cdApp.me.altarPermissions?.includes('altarDeveloper');
  return (
    isDeveloper && (
      <CdTooltip title={props.tooltip}>
        <Tag color="volcano" style={{ marginInlineEnd: '0px' }}>
          <Space>
            <CdDeveloper />
            <span>{props.children}</span>
          </Space>
        </Tag>
      </CdTooltip>
    )
  );
};
