/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateGroupResponseBody } from '../models/CreateGroupResponseBody';
import type { DeleteGroupRequestBody } from '../models/DeleteGroupRequestBody';
import type { EmptyObject } from '../models/EmptyObject';
import type { GetCalendarFormsSearchResponseBody } from '../models/GetCalendarFormsSearchResponseBody';
import type { GetGroupsIdResponseBody } from '../models/GetGroupsIdResponseBody';
import type { GetOrganizationGroupsResponseBody } from '../models/GetOrganizationGroupsResponseBody';
import type { PutGroupsIdTasksTaskidRequestBody } from '../models/PutGroupsIdTasksTaskidRequestBody';
import type { PutGroupsIdUsersUseridMembershipRequestBody } from '../models/PutGroupsIdUsersUseridMembershipRequestBody';
import type { PutGroupsRequestBody } from '../models/PutGroupsRequestBody';
import type { UpdateGroupResponseBody } from '../models/UpdateGroupResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class GroupsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * CreateGroup
     * Create
     * @returns CreateGroupResponseBody
     * @throws ApiError
     */
    public postGroups({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<CreateGroupResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/groups',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetOrganizationGroups
     * Index
     * @returns GetOrganizationGroupsResponseBody
     * @throws ApiError
     */
    public getGroups(): CancelablePromise<GetOrganizationGroupsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/groups',
        });
    }
    /**
     * GetGroupsGroupidFeed
     * Activity feed
     * @returns EmptyObject
     * @throws ApiError
     */
    public getGroupsFeed({
        groupId,
    }: {
        groupId: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/groups/{groupId}/feed',
            path: {
                'groupId': groupId,
            },
        });
    }
    /**
     * DeleteGroup
     * Delete
     * @returns GetCalendarFormsSearchResponseBody
     * @throws ApiError
     */
    public deleteGroups({
        id,
        targetId,
    }: {
        id: number,
        targetId?: number,
    }): CancelablePromise<GetCalendarFormsSearchResponseBody> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/groups/{id}',
            path: {
                'id': id,
            },
            query: {
                'targetId': targetId,
            },
        });
    }
    /**
     * GetGroupsId
     * Retrieve
     * @returns GetGroupsIdResponseBody
     * @throws ApiError
     */
    public getGroups1({
        id,
    }: {
        id: number,
    }): CancelablePromise<GetGroupsIdResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/groups/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * UpdateGroup
     * Update
     * @returns UpdateGroupResponseBody [data] Success-Response:
     * @throws ApiError
     */
    public putGroups({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PutGroupsRequestBody,
    }): CancelablePromise<UpdateGroupResponseBody> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/groups/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetGroupsIdTasks
     * @returns GetCalendarFormsSearchResponseBody
     * @throws ApiError
     */
    public getGroupsTasks(): CancelablePromise<GetCalendarFormsSearchResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/groups/{id}/tasks',
        });
    }
    /**
     * PostGroupsIdTasks
     * @returns EmptyObject
     * @throws ApiError
     */
    public postGroupsTasks({
        requestBody,
    }: {
        requestBody?: DeleteGroupRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/groups/{id}/tasks',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeleteGroupsIdTasksTaskid
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteGroupsTasks(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/groups/{id}/tasks/{taskId}',
        });
    }
    /**
     * PutGroupsIdTasksTaskid
     * @returns EmptyObject
     * @throws ApiError
     */
    public putGroupsTasks({
        requestBody,
    }: {
        requestBody?: PutGroupsIdTasksTaskidRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/groups/{id}/tasks/{taskId}',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * UploadGroupPicture
     * Group Picture
     * @returns EmptyObject
     * @throws ApiError
     */
    public postGroupsUploadPicture({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/groups/{id}/upload/picture',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * PutGroupsIdUsersUseridMembership
     * Membership
     * @returns EmptyObject
     * @throws ApiError
     */
    public putGroupsUsersMembership({
        requestBody,
    }: {
        requestBody?: PutGroupsIdUsersUseridMembershipRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/groups/{id}/users/{userId}/membership',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
