/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { GetIntentionSettingsResponseBody } from '../models/GetIntentionSettingsResponseBody';
import type { PostIntentionFeesRecipientsRequestBody } from '../models/PostIntentionFeesRecipientsRequestBody';
import type { PostIntentionFeesRequestBody } from '../models/PostIntentionFeesRequestBody';
import type { PostIntentionFoundationsRequestBody } from '../models/PostIntentionFoundationsRequestBody';
import type { PostIntentionIntentionsCopyRequestBody } from '../models/PostIntentionIntentionsCopyRequestBody';
import type { PostIntentionIntentionsMaxprimaryintentionsRequestBody } from '../models/PostIntentionIntentionsMaxprimaryintentionsRequestBody';
import type { PostIntentionIntentionsProcessbillingRequestBody } from '../models/PostIntentionIntentionsProcessbillingRequestBody';
import type { PostIntentionIntentionsRequestBody } from '../models/PostIntentionIntentionsRequestBody';
import type { PostIntentionReporttemplatesRequestBody } from '../models/PostIntentionReporttemplatesRequestBody';
import type { PostIntentionSettingsRequestBody } from '../models/PostIntentionSettingsRequestBody';
import type { PostIntentionStolesProcessbillingRequestBody } from '../models/PostIntentionStolesProcessbillingRequestBody';
import type { PostIntentionStolesRequestBody } from '../models/PostIntentionStolesRequestBody';
import type { PutIntentionFeesRecipientsRequestBody } from '../models/PutIntentionFeesRecipientsRequestBody';
import type { PutIntentionFeesRequestBody } from '../models/PutIntentionFeesRequestBody';
import type { PutIntentionFoundationsChangestatusRequestBody } from '../models/PutIntentionFoundationsChangestatusRequestBody';
import type { PutIntentionFoundationsRequestBody } from '../models/PutIntentionFoundationsRequestBody';
import type { PutIntentionIntentionsRequestBody } from '../models/PutIntentionIntentionsRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class IntentionService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetOrderOfServiceDownloadToken
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarOrderOfServiceDownloadToken(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/order-of-service/download-token',
        });
    }
    /**
     * GenerateOrderOfServiceReport
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarOrderOfServiceReport({
        downloadToken,
        templateId,
        startDate,
        endDate,
        categories,
        groups,
        resources,
        users,
        search,
        groupBy = 'Date/Time/Church',
        orderBy = 'Alphabetical',
        eventsWithoutResourcesInChurchIds,
        additionalEventDataToInclude,
        visibility,
        type = 'event',
    }: {
        downloadToken: string,
        templateId: string,
        startDate: string,
        endDate: string,
        categories?: Array<number> | null,
        groups?: Array<number> | null,
        resources?: Array<number> | null,
        users?: Array<number> | null,
        search?: string | null,
        groupBy?: 'Date/Time/Church' | 'Date/Church/Time' | 'Church/Date/Time' | 'Date/Time/Resource' | 'Date/Resource/Time' | 'Resource/Date/Time' | 'Date/Location/Time' | 'Date/Church/Event',
        orderBy?: 'Alphabetical' | 'Numeric',
        eventsWithoutResourcesInChurchIds?: Array<number>,
        additionalEventDataToInclude?: Array<'contributor' | 'locationName' | 'price' | 'description' | 'allDay' | 'summary' | 'location' | 'priests' | 'intentions' | 'church' | 'resource'>,
        visibility?: 'public' | 'all-users' | 'groups',
        type?: 'event' | 'absence',
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/order-of-service/report',
            query: {
                'downloadToken': downloadToken,
                'templateId': templateId,
                'startDate': startDate,
                'endDate': endDate,
                'categories': categories,
                'groups': groups,
                'resources': resources,
                'users': users,
                'search': search,
                'groupBy': groupBy,
                'orderBy': orderBy,
                'eventsWithoutResourcesInChurchIds': eventsWithoutResourcesInChurchIds,
                'additionalEventDataToInclude': additionalEventDataToInclude,
                'visibility': visibility,
                'type': type,
            },
        });
    }
    /**
     * GetAllAvailableOrderOfServiceTemplates
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarOrderOfServiceTemplates(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/order-of-service/templates',
        });
    }
    /**
     * CreateFee
     * @returns EmptyObject
     * @throws ApiError
     */
    public postIntentionFees({
        requestBody,
    }: {
        requestBody?: PostIntentionFeesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/intention/fees',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetFees
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionFees({
        types,
        activeOnly = false,
        orderBy = 'createdAt',
        orderDirection = 'DESC',
        limit = 100,
        offset,
    }: {
        types?: Array<'intentionen' | 'stolarien'>,
        activeOnly?: boolean,
        orderBy?: string,
        orderDirection?: 'ASC' | 'DESC',
        limit?: number,
        offset?: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/fees',
            query: {
                'types': types,
                'activeOnly': activeOnly,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * CreateFeeRecipient
     * @returns EmptyObject
     * @throws ApiError
     */
    public postIntentionFeesRecipients({
        requestBody,
    }: {
        requestBody?: PostIntentionFeesRecipientsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/intention/fees/recipients',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetFeesRecipients
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionFeesRecipients({
        forwardingRecipientsOnly = false,
        nonForwardingRecipientsOnly = false,
    }: {
        forwardingRecipientsOnly?: boolean,
        nonForwardingRecipientsOnly?: boolean,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/fees/recipients',
            query: {
                'forwardingRecipientsOnly': forwardingRecipientsOnly,
                'nonForwardingRecipientsOnly': nonForwardingRecipientsOnly,
            },
        });
    }
    /**
     * DeleteFeeRecipient
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteIntentionFeesRecipients({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/intention/fees/recipients/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * UpdateFeeRecipient
     * @returns EmptyObject
     * @throws ApiError
     */
    public putIntentionFeesRecipients({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PutIntentionFeesRecipientsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/intention/fees/recipients/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeleteFee
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteIntentionFees({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/intention/fees/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetFee
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionFees1({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/fees/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * UpdateFee
     * @returns EmptyObject
     * @throws ApiError
     */
    public putIntentionFees({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PutIntentionFeesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/intention/fees/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * CreateFoundation
     * @returns EmptyObject
     * @throws ApiError
     */
    public postIntentionFoundations({
        requestBody,
    }: {
        requestBody?: PostIntentionFoundationsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/intention/foundations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetFullListOfFoundations
     * Index
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionFoundations({
        type,
        statuses,
        churchIds,
        feeIds,
        referenceNumberFrom,
        referenceNumberTo,
        searchValue,
        paymentMethods,
        includeDeleted,
        orderBy = 'createdAt',
        orderDirection = 'DESC',
        limit = 100,
        offset,
    }: {
        type: 'stiftungen' | 'legate',
        statuses?: Array<'inactive' | 'active' | 'completed'>,
        churchIds?: Array<number>,
        feeIds?: Array<string>,
        referenceNumberFrom?: number,
        referenceNumberTo?: number,
        searchValue?: string | null,
        paymentMethods?: Array<'cash' | 'bank'>,
        includeDeleted?: boolean,
        orderBy?: string,
        orderDirection?: 'ASC' | 'DESC',
        limit?: number,
        offset?: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/foundations',
            query: {
                'type': type,
                'statuses': statuses,
                'churchIds': churchIds,
                'feeIds': feeIds,
                'referenceNumberFrom': referenceNumberFrom,
                'referenceNumberTo': referenceNumberTo,
                'searchValue': searchValue,
                'paymentMethods': paymentMethods,
                'includeDeleted': includeDeleted,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * FoundationExportData
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionFoundationsExportData({
        exportToken,
        type,
        fileType = 'csv',
        statuses,
        churchIds,
        feeIds,
        referenceNumberFrom,
        referenceNumberTo,
        searchValue,
        paymentMethods,
        includeDeleted,
        orderBy = 'createdAt',
        orderDirection = 'DESC',
    }: {
        exportToken: string,
        type: 'stiftungen' | 'legate',
        fileType?: 'csv' | 'ods' | 'xlsx',
        statuses?: Array<'inactive' | 'active' | 'completed'>,
        churchIds?: Array<number>,
        feeIds?: Array<string>,
        referenceNumberFrom?: number,
        referenceNumberTo?: number,
        searchValue?: string | null,
        paymentMethods?: Array<'cash' | 'bank'>,
        includeDeleted?: boolean,
        orderBy?: string,
        orderDirection?: 'ASC' | 'DESC',
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/foundations/export/data',
            query: {
                'exportToken': exportToken,
                'fileType': fileType,
                'type': type,
                'statuses': statuses,
                'churchIds': churchIds,
                'feeIds': feeIds,
                'referenceNumberFrom': referenceNumberFrom,
                'referenceNumberTo': referenceNumberTo,
                'searchValue': searchValue,
                'paymentMethods': paymentMethods,
                'includeDeleted': includeDeleted,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
            },
        });
    }
    /**
     * FoundationExportToken
     * Get Foundation Export Token
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionFoundationsExportToken(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/foundations/export/token',
        });
    }
    /**
     * FoundationGetNextReferenceNumber
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionFoundationsNextReferenceNumber({
        churchId,
        type,
    }: {
        churchId: number,
        type: 'stiftungen' | 'legate',
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/foundations/next-reference-number',
            query: {
                'churchId': churchId,
                'type': type,
            },
        });
    }
    /**
     * DeleteFoundation
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteIntentionFoundations({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/intention/foundations/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetFoundation
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionFoundations1({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/foundations/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * UpdateFoundation
     * @returns EmptyObject
     * @throws ApiError
     */
    public putIntentionFoundations({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PutIntentionFoundationsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/intention/foundations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * ChangeStatusFoundation
     * @returns EmptyObject
     * @throws ApiError
     */
    public putIntentionFoundationsChangeStatus({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PutIntentionFoundationsChangestatusRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/intention/foundations/{id}/change-status',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * RevertDeletionOfFoundation
     * @returns EmptyObject
     * @throws ApiError
     */
    public postIntentionFoundationsRevertDeletion({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/intention/foundations/{id}/revert-deletion',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetFoundationIntentionsDownloadToken
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionIntentionReportsFoundationIntentionsDownloadToken({
        type,
    }: {
        type: 'stiftungen' | 'legate',
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/intention-reports/foundation-intentions/download-token',
            query: {
                'type': type,
            },
        });
    }
    /**
     * GenerateFoundationIntentionsReport
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionIntentionReportsFoundationIntentionsReport({
        downloadToken,
        foundationId,
        type,
    }: {
        downloadToken: string,
        foundationId: string,
        type: 'stiftungen' | 'legate',
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/intention-reports/foundation-intentions/report',
            query: {
                'downloadToken': downloadToken,
                'foundationId': foundationId,
                'type': type,
            },
        });
    }
    /**
     * GetIntentionBillingDownloadToken
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionIntentionReportsIntentionBillingDownloadToken(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/intention-reports/intention-billing/download-token',
        });
    }
    /**
     * GenerateIntentionBillingReport
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionIntentionReportsIntentionBillingReport({
        downloadToken,
        churchId,
        month,
        year,
    }: {
        downloadToken: string,
        churchId: number,
        month: number,
        year: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/intention-reports/intention-billing/report',
            query: {
                'downloadToken': downloadToken,
                'churchId': churchId,
                'month': month,
                'year': year,
            },
        });
    }
    /**
     * GetIntentionsListDownloadToken
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionIntentionReportsIntentionListDownloadToken(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/intention-reports/intention-list/download-token',
        });
    }
    /**
     * GenerateIntentionListReport
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionIntentionReportsIntentionListReport({
        downloadToken,
        intentionYear,
        churchIds,
    }: {
        downloadToken: string,
        intentionYear: number,
        churchIds?: Array<number>,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/intention-reports/intention-list/report',
            query: {
                'downloadToken': downloadToken,
                'churchIds': churchIds,
                'intentionYear': intentionYear,
            },
        });
    }
    /**
     * GetStoleBillingDownloadToken
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionIntentionReportsStoleBillingDownloadToken(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/intention-reports/stole-billing/download-token',
        });
    }
    /**
     * GenerateStoleBillingReport
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionIntentionReportsStoleBillingReport({
        downloadToken,
        churchId,
        month,
        year,
    }: {
        downloadToken: string,
        churchId: number,
        month: number,
        year: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/intention-reports/stole-billing/report',
            query: {
                'downloadToken': downloadToken,
                'churchId': churchId,
                'month': month,
                'year': year,
            },
        });
    }
    /**
     * CreateIntention
     * @returns EmptyObject
     * @throws ApiError
     */
    public postIntentionIntentions({
        requestBody,
    }: {
        requestBody?: PostIntentionIntentionsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/intention/intentions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetIntentions
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionIntentions({
        statuses,
        includeDeleted,
        churchIds,
        searchValue,
        referenceNumberSourceType,
        referenceNumberFrom,
        referenceNumberTo,
        referenceNumberYear,
        acceptanceYear,
        acceptanceDateFrom,
        acceptanceDateTo,
        calendarDateFrom,
        calendarDateTo,
        completedMonth,
        completedYear,
        forwardingMonth,
        forwardingYear,
        deadlineDate,
        feeIds,
        paymentMethods,
        paid,
        orderBy,
        orderDirection,
        limit = 20,
        offset,
        eventCategoryIds,
    }: {
        statuses?: Array<'unassigned' | 'assigned' | 'completed'>,
        includeDeleted?: boolean,
        churchIds?: Array<number>,
        searchValue?: string | null,
        referenceNumberSourceType?: string | null,
        referenceNumberFrom?: number,
        referenceNumberTo?: number,
        referenceNumberYear?: number,
        acceptanceYear?: number,
        acceptanceDateFrom?: string | null,
        acceptanceDateTo?: string | null,
        calendarDateFrom?: string | null,
        calendarDateTo?: string | null,
        completedMonth?: number,
        completedYear?: number,
        forwardingMonth?: number,
        forwardingYear?: number,
        deadlineDate?: string | null,
        feeIds?: Array<string>,
        paymentMethods?: Array<'cash' | 'bank'>,
        paid?: boolean,
        orderBy?: string,
        orderDirection?: 'ASC' | 'DESC',
        limit?: number,
        offset?: number,
        eventCategoryIds?: Array<number>,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/intentions',
            query: {
                'statuses': statuses,
                'includeDeleted': includeDeleted,
                'churchIds': churchIds,
                'searchValue': searchValue,
                'referenceNumberSourceType': referenceNumberSourceType,
                'referenceNumberFrom': referenceNumberFrom,
                'referenceNumberTo': referenceNumberTo,
                'referenceNumberYear': referenceNumberYear,
                'acceptanceYear': acceptanceYear,
                'acceptanceDateFrom': acceptanceDateFrom,
                'acceptanceDateTo': acceptanceDateTo,
                'calendarDateFrom': calendarDateFrom,
                'calendarDateTo': calendarDateTo,
                'completedMonth': completedMonth,
                'completedYear': completedYear,
                'forwardingMonth': forwardingMonth,
                'forwardingYear': forwardingYear,
                'deadlineDate': deadlineDate,
                'feeIds': feeIds,
                'paymentMethods': paymentMethods,
                'paid': paid,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
                'limit': limit,
                'offset': offset,
                'eventCategoryIds': eventCategoryIds,
            },
        });
    }
    /**
     * GetIntentionsAssignedToEvent
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionIntentionsAssigned({
        calendarId,
    }: {
        calendarId: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/intentions/assigned/{calendarId}',
            path: {
                'calendarId': calendarId,
            },
        });
    }
    /**
     * GetIntentionAssociatedToFoundation
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionIntentionsAssociated({
        parentId,
        statuses,
        includeDeleted = false,
        orderBy,
        orderDirection,
        limit = 100,
        offset,
    }: {
        parentId: string,
        statuses?: Array<'unassigned' | 'assigned' | 'completed'>,
        includeDeleted?: boolean,
        orderBy?: string,
        orderDirection?: 'ASC' | 'DESC',
        limit?: number,
        offset?: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/intentions/associated/{parentId}',
            path: {
                'parentId': parentId,
            },
            query: {
                'statuses': statuses,
                'includeDeleted': includeDeleted,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * GetEventsWithAssignedIntentions
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionIntentionsEventsWithAssignedIntentions({
        churchIds,
        month,
        year,
    }: {
        churchIds: Array<number>,
        month: number,
        year: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/intentions/events-with-assigned-intentions',
            query: {
                'churchIds': churchIds,
                'month': month,
                'year': year,
            },
        });
    }
    /**
     * IntentionExportData
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionIntentionsExportData({
        exportToken,
        fileType = 'csv',
        statuses,
        includeDeleted,
        churchIds,
        searchValue,
        referenceNumberSourceType,
        referenceNumberFrom,
        referenceNumberTo,
        referenceNumberYear,
        acceptanceYear,
        acceptanceDateFrom,
        acceptanceDateTo,
        calendarDateFrom,
        calendarDateTo,
        completedMonth,
        completedYear,
        forwardingMonth,
        forwardingYear,
        deadlineDate,
        feeIds,
        paymentMethods,
        paid,
        orderBy,
        orderDirection,
        limit = 100,
        offset,
    }: {
        exportToken: string,
        fileType?: 'csv' | 'ods' | 'xlsx',
        statuses?: Array<'unassigned' | 'assigned' | 'completed'>,
        includeDeleted?: boolean,
        churchIds?: Array<number>,
        searchValue?: string | null,
        referenceNumberSourceType?: string | null,
        referenceNumberFrom?: number,
        referenceNumberTo?: number,
        referenceNumberYear?: number,
        acceptanceYear?: number,
        acceptanceDateFrom?: string | null,
        acceptanceDateTo?: string | null,
        calendarDateFrom?: string | null,
        calendarDateTo?: string | null,
        completedMonth?: number,
        completedYear?: number,
        forwardingMonth?: number,
        forwardingYear?: number,
        deadlineDate?: string | null,
        feeIds?: Array<string>,
        paymentMethods?: Array<'cash' | 'bank'>,
        paid?: boolean,
        orderBy?: string,
        orderDirection?: 'ASC' | 'DESC',
        limit?: number,
        offset?: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/intentions/export/data',
            query: {
                'exportToken': exportToken,
                'fileType': fileType,
                'statuses': statuses,
                'includeDeleted': includeDeleted,
                'churchIds': churchIds,
                'searchValue': searchValue,
                'referenceNumberSourceType': referenceNumberSourceType,
                'referenceNumberFrom': referenceNumberFrom,
                'referenceNumberTo': referenceNumberTo,
                'referenceNumberYear': referenceNumberYear,
                'acceptanceYear': acceptanceYear,
                'acceptanceDateFrom': acceptanceDateFrom,
                'acceptanceDateTo': acceptanceDateTo,
                'calendarDateFrom': calendarDateFrom,
                'calendarDateTo': calendarDateTo,
                'completedMonth': completedMonth,
                'completedYear': completedYear,
                'forwardingMonth': forwardingMonth,
                'forwardingYear': forwardingYear,
                'deadlineDate': deadlineDate,
                'feeIds': feeIds,
                'paymentMethods': paymentMethods,
                'paid': paid,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * IntentionExportToken
     * Get Intention Export Token
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionIntentionsExportToken(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/intentions/export/token',
        });
    }
    /**
     * GetMaxNumberOfIntentions
     * Get Max No Of Intentions
     * @returns EmptyObject
     * @throws ApiError
     */
    public postIntentionIntentionsMaxPrimaryIntentions({
        requestBody,
    }: {
        requestBody?: PostIntentionIntentionsMaxprimaryintentionsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/intention/intentions/max-primary-intentions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetMaximumNumberOfPrimaryIntentions
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionIntentionsMaxPrimaryIntentions({
        calendarId,
    }: {
        calendarId: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/intentions/max-primary-intentions/{calendarId}',
            path: {
                'calendarId': calendarId,
            },
        });
    }
    /**
     * IntentionGetNextReferenceNumber
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionIntentionsNextReferenceNumber({
        churchId,
        intentionYear,
    }: {
        churchId: number,
        intentionYear: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/intentions/next-reference-number',
            query: {
                'churchId': churchId,
                'intentionYear': intentionYear,
            },
        });
    }
    /**
     * ProcessBillingOfIntentions
     * @returns EmptyObject
     * @throws ApiError
     */
    public postIntentionIntentionsProcessBilling({
        requestBody,
    }: {
        requestBody?: PostIntentionIntentionsProcessbillingRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/intention/intentions/process-billing',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * ResetBillingOfIntentions
     * @returns EmptyObject
     * @throws ApiError
     */
    public postIntentionIntentionsResetBilling({
        requestBody,
    }: {
        requestBody?: PostIntentionStolesProcessbillingRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/intention/intentions/reset-billing',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeleteIntention
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteIntentionIntentions({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/intention/intentions/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetIntention
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionIntentions1({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/intentions/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * UpdateIntention
     * @returns EmptyObject
     * @throws ApiError
     */
    public putIntentionIntentions({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PutIntentionIntentionsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/intention/intentions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * AssignIntentionToEvent
     * @returns EmptyObject
     * @throws ApiError
     */
    public postIntentionIntentionsAssignTo({
        id,
        calendarId,
        requestBody,
    }: {
        id: string,
        calendarId: number,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/intention/intentions/{id}/assign-to/{calendarId}',
            path: {
                'id': id,
                'calendarId': calendarId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * CopyIntention
     * @returns EmptyObject
     * @throws ApiError
     */
    public postIntentionIntentionsCopy({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PostIntentionIntentionsCopyRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/intention/intentions/{id}/copy',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetEventsToAssign
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionIntentionsEventsToAssign({
        startDate,
        endDate,
        id,
        isResourcesRequired,
        searchValue,
        orderBy = 'startDate',
        orderDirection = 'ASC',
        limit = 100,
        offset,
    }: {
        startDate: string,
        endDate: string,
        id: string,
        isResourcesRequired?: boolean,
        searchValue?: string | null,
        orderBy?: string,
        orderDirection?: 'ASC' | 'DESC',
        limit?: number,
        offset?: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/intentions/{id}/events-to-assign',
            path: {
                'id': id,
            },
            query: {
                'startDate': startDate,
                'endDate': endDate,
                'isResourcesRequired': isResourcesRequired,
                'searchValue': searchValue,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * RevertDeletionOfIntention
     * @returns EmptyObject
     * @throws ApiError
     */
    public postIntentionIntentionsRevertDeletion({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/intention/intentions/{id}/revert-deletion',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * UnassignIntentionFromEvent
     * @returns EmptyObject
     * @throws ApiError
     */
    public postIntentionIntentionsUnAssign({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/intention/intentions/{id}/un-assign',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * CreateReportTemplate
     * @returns EmptyObject
     * @throws ApiError
     */
    public postIntentionReportTemplates({
        requestBody,
    }: {
        requestBody?: PostIntentionReporttemplatesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/intention/report-templates',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetReportTemplates
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionReportTemplates({
        types,
        orderBy = 'createdAt',
        orderDirection = 'DESC',
        limit = 100,
        offset,
    }: {
        types?: Array<'gottesdienstordnung'>,
        orderBy?: string,
        orderDirection?: 'ASC' | 'DESC',
        limit?: number,
        offset?: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/report-templates',
            query: {
                'types': types,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * GetReportTemplateDownloadToken
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionReportTemplatesDownloadToken(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/report-templates/download-token',
        });
    }
    /**
     * DeleteReportTemplate
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteIntentionReportTemplates({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/intention/report-templates/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetReportTemplate
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionReportTemplates1({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/report-templates/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * UpdateReportTemplate
     * @returns EmptyObject
     * @throws ApiError
     */
    public putIntentionReportTemplates({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PostIntentionReporttemplatesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/intention/report-templates/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetReportTemplateDownload
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionReportTemplatesDownload({
        downloadToken,
        id,
    }: {
        downloadToken: string,
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/report-templates/{id}/download',
            path: {
                'id': id,
            },
            query: {
                'downloadToken': downloadToken,
            },
        });
    }
    /**
     * GetIntentionSettings
     * Get intention settings
     * @returns GetIntentionSettingsResponseBody
     * @throws ApiError
     */
    public getIntentionSettings(): CancelablePromise<GetIntentionSettingsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/settings',
        });
    }
    /**
     * SaveIntentionSettings
     * Save intention settings
     * @returns EmptyObject
     * @throws ApiError
     */
    public postIntentionSettings({
        requestBody,
    }: {
        requestBody?: PostIntentionSettingsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/intention/settings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * CreateStole
     * @returns EmptyObject
     * @throws ApiError
     */
    public postIntentionStoles({
        requestBody,
    }: {
        requestBody?: PostIntentionStolesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/intention/stoles',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetStoles
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionStoles({
        statuses,
        churchIds,
        feeIds,
        referenceNumberYear,
        referenceNumberFrom,
        referenceNumberTo,
        searchValue,
        paymentMethods,
        includeDeleted,
        orderBy = 'createdAt',
        orderDirection = 'DESC',
        limit = 100,
        offset,
    }: {
        statuses?: Array<'open' | 'completed'>,
        churchIds?: Array<number>,
        feeIds?: Array<string>,
        referenceNumberYear?: number,
        referenceNumberFrom?: number,
        referenceNumberTo?: number,
        searchValue?: string | null,
        paymentMethods?: Array<'cash' | 'bank'>,
        includeDeleted?: boolean,
        orderBy?: string,
        orderDirection?: 'ASC' | 'DESC',
        limit?: number,
        offset?: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/stoles',
            query: {
                'statuses': statuses,
                'churchIds': churchIds,
                'feeIds': feeIds,
                'referenceNumberYear': referenceNumberYear,
                'referenceNumberFrom': referenceNumberFrom,
                'referenceNumberTo': referenceNumberTo,
                'searchValue': searchValue,
                'paymentMethods': paymentMethods,
                'includeDeleted': includeDeleted,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * GetStoleAssignedToEvent
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionStolesAssigned({
        calendarId,
    }: {
        calendarId: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/stoles/assigned/{calendarId}',
            path: {
                'calendarId': calendarId,
            },
        });
    }
    /**
     * GetEventsWithAssignedStoles
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionStolesEventsWithAssignedStoles({
        churchIds,
        month,
        year,
    }: {
        churchIds: Array<number>,
        month: number,
        year: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/stoles/events-with-assigned-stoles',
            query: {
                'churchIds': churchIds,
                'month': month,
                'year': year,
            },
        });
    }
    /**
     * StoleExport
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionStolesExportData({
        exportToken,
        fileType = 'csv',
        statuses,
        churchIds,
        feeIds,
        referenceNumberYear,
        referenceNumberFrom,
        referenceNumberTo,
        searchValue,
        paymentMethods,
        includeDeleted,
        orderBy = 'createdAt',
        orderDirection = 'DESC',
    }: {
        exportToken: string,
        fileType?: 'csv' | 'ods' | 'xlsx',
        statuses?: Array<'open' | 'completed'>,
        churchIds?: Array<number>,
        feeIds?: Array<string>,
        referenceNumberYear?: number,
        referenceNumberFrom?: number,
        referenceNumberTo?: number,
        searchValue?: string | null,
        paymentMethods?: Array<'cash' | 'bank'>,
        includeDeleted?: boolean,
        orderBy?: string,
        orderDirection?: 'ASC' | 'DESC',
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/stoles/export/data',
            query: {
                'exportToken': exportToken,
                'fileType': fileType,
                'statuses': statuses,
                'churchIds': churchIds,
                'feeIds': feeIds,
                'referenceNumberYear': referenceNumberYear,
                'referenceNumberFrom': referenceNumberFrom,
                'referenceNumberTo': referenceNumberTo,
                'searchValue': searchValue,
                'paymentMethods': paymentMethods,
                'includeDeleted': includeDeleted,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
            },
        });
    }
    /**
     * StoleExportToken
     * Get Stole Export Token
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionStolesExportToken(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/stoles/export/token',
        });
    }
    /**
     * StoleGetNextReferenceNumber
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionStolesNextReferenceNumber({
        churchId,
        stoleYear,
    }: {
        churchId: number,
        stoleYear: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/stoles/next-reference-number',
            query: {
                'churchId': churchId,
                'stoleYear': stoleYear,
            },
        });
    }
    /**
     * ProcessBillingOfStoles
     * @returns EmptyObject
     * @throws ApiError
     */
    public postIntentionStolesProcessBilling({
        requestBody,
    }: {
        requestBody?: PostIntentionStolesProcessbillingRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/intention/stoles/process-billing',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * ResetBillingOfStoles
     * @returns EmptyObject
     * @throws ApiError
     */
    public postIntentionStolesResetBilling({
        requestBody,
    }: {
        requestBody?: PostIntentionStolesProcessbillingRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/intention/stoles/reset-billing',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeleteStole
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteIntentionStoles({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/intention/stoles/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetStole
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIntentionStoles1({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/intention/stoles/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * UpdateStole
     * @returns EmptyObject
     * @throws ApiError
     */
    public putIntentionStoles({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PostIntentionStolesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/intention/stoles/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * RevertDeletionOfStole
     * @returns EmptyObject
     * @throws ApiError
     */
    public postIntentionStolesRevertDeletion({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/intention/stoles/{id}/revert-deletion',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
