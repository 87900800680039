const countryTaxRates = {
  at: [20, 13, 10],
  be: [21, 12, 6],
  bg: [20, 9],
  cy: [19, 9, 5],
  cz: [21, 12],
  de: [19, 7],
  dk: [25],
  ee: [22, 9],
  es: [21, 10],
  fi: [25.5, 14, 10],
  fr: [20, 10, 5.5],
  ie: [23, 13.5, 9],
  it: [22, 10, 5],
  nl: [21, 9],
  pl: [23, 8, 5],
  se: [25, 12, 6],
  no: [25, 15, 12, 11.1],
  gb: [20, 5],
};

const countryTaxRatesWithLabels = Object.fromEntries(
  Object.entries(countryTaxRates).map(([countryCode, rates]) => [
    countryCode,
    rates.map((rate) => ({
      value: rate,
      label: `${rate}%`,
    })),
  ])
);

export const getCountryTaxRatesWithLabels = (countryCode) =>
  countryTaxRatesWithLabels[countryCode.toLowerCase()] || [];
