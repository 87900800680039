import { Layout, Radio, Space, Spin } from 'antd';
import React, { Suspense, useCallback, useState } from 'react';
import styled from 'styled-components';

import { I18nService } from '@/react/services/I18nService';
import {
  TemplateAuthor,
  TemplateType,
} from '@/react/people/types/message-template.type';
import {
  useTemplateslist,
  useTemplatesSelectUnlayer,
} from '@/react/people/hooks/useTemplates';
import AuthorizationService from '@/react/services/AuthorizationService';
import { StudioLandingPage } from '@/react/people/message-editor/shared/containers/StudioLandingPage';
import { PeopleMessageRecipient } from '@/react/people/types/message';
import {
  ISelectableCardItem,
  SelectableCards,
} from '@/react/shared/components/SelectableCards';
import { CdDeleteIcon, CdStudio } from '@/react/shared/components/Icons';
import CdrEmptyState from '@/react/shared/components/cd-result/CdrEmptyState';
import { showConfirmModalDeprecated } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import { useGetPortalsInfo } from '@/react/settings/hooks/useCategories';
import { createCdDrawer } from '@/react/shared/components/cd-drawer/CdDrawer';
import { ModalType } from '@/react/shared/components/cd-modal-new/CdModal2';

const { Sider, Content } = Layout;

const StyledContent = styled.div`
  .ant-layout {
    .ant-layout-sider {
      padding: 8px 24px;
    }
    .ant-layout-content {
      padding: 8px;
      overflow: auto;
    }
  }
`;

interface TemplateSelectorProps {
  recipients?: PeopleMessageRecipient[];
  sourceInfo?: any;
}

export const showTemplateSelector = createCdDrawer<TemplateSelectorProps>(
  ({ recipients, sourceInfo, setDrawerProps, closeDrawer }) => {
    const { isPortalMember, isPortalHost } = useGetPortalsInfo();
    const [authoredBy, setAuthoredBy] = useState<TemplateAuthor | 'all'>('all');
    const { templates, templatesLoading, deleteTemplate } = useTemplateslist({
      type: TemplateType.PEOPLE_MESSAGE_ADVANCED,
      authoredBy: authoredBy === 'all' ? null : authoredBy,
    });
    const { selectTemplate } = useTemplatesSelectUnlayer();
    const [selectedTemplate, setSelectedTemplate] = useState<string>(null);
    const [processingSelection, setProcessingSelection] =
      useState<boolean>(false);
    const hasStudio = AuthorizationService.hasPackage('studio');

    const confirmDeleteModal = (templateId: string, templateName: string) =>
      showConfirmModalDeprecated({
        title: I18nService.getString('Delete template'),
        message: I18nService.getString(
          'Confirm that you want to delete this template {{ templateName }} permanently for all users in this organisation. The template can not be restored.',
          {
            templateName,
          }
        ),
        icon: <CdDeleteIcon />,
        okType: 'danger',
        okText: I18nService.getString('Delete'),
        onOk: () => deleteTemplate(templateId),
      });

    const confirmTemplateSelection = useCallback(async () => {
      setProcessingSelection(true);
      if (selectedTemplate) {
        await selectTemplate({
          id: selectedTemplate,
          recipients,
          sourceInfo,
        }).then(closeDrawer);
      }
      setProcessingSelection(false);
    }, [closeDrawer, selectTemplate, selectedTemplate, recipients, sourceInfo]);

    React.useEffect(() => {
      setDrawerProps({
        modalType: ModalType.MODAL,
        title: hasStudio
          ? I18nService.getString('Select template')
          : I18nService.getString(
              'ChurchDesk Studio - An add-on for designing your message'
            ),
        okText: I18nService.getString('Confirm'),
        cancelText: I18nService.getString('Cancel'),
        hideOk: !hasStudio,
        onOk: confirmTemplateSelection,
        okButtonProps: {
          disabled: !selectedTemplate || processingSelection,
          loading: processingSelection,
        },
      });
    }, [
      hasStudio,
      selectedTemplate,
      processingSelection,
      confirmTemplateSelection,
      setDrawerProps,
    ]);

    return (
      <StyledContent>
        {(!hasStudio && (
          <Suspense fallback={<Spin />}>
            <Layout>
              <Content style={{ height: '100%', padding: '30px' }}>
                <StudioLandingPage />
              </Content>
            </Layout>
          </Suspense>
        )) || (
          <Layout>
            <Sider theme="light">
              <Radio.Group
                value={authoredBy}
                onChange={(e) => setAuthoredBy(e.target.value)}
              >
                <Space direction="vertical">
                  <Radio value="all">{I18nService.getString('All')}</Radio>
                  <Radio value={TemplateAuthor.CHURCHDESK}>
                    {I18nService.getString('ChurchDesk Templates')}
                  </Radio>
                  <Radio value={TemplateAuthor.USER}>
                    {I18nService.getString('Your templates')}
                  </Radio>
                  {(isPortalMember || isPortalHost) && (
                    <Radio value={TemplateAuthor.PORTAL}>
                      {I18nService.getString('Portal templates')}
                    </Radio>
                  )}
                </Space>
              </Radio.Group>
            </Sider>
            <Content style={{ height: '100%' }}>
              <SelectableCards
                loading={templatesLoading}
                emptyText={
                  <CdrEmptyState
                    title={I18nService.getString('No templates found')}
                    EmptyStateIcon={<CdStudio />}
                  />
                }
                dataSource={templates.map(
                  (template) =>
                    ({
                      ...template,
                      isSelectable: true,
                      isDeletable:
                        template.templateSource !== TemplateAuthor.CHURCHDESK,
                      subtitle:
                        template.templateSource === TemplateAuthor.CHURCHDESK
                          ? I18nService.getString('By ChurchDesk')
                          : I18nService.getString('By {{ authorName }}', {
                              authorName: template.author?.contact?.fullName,
                            }),
                    }) as ISelectableCardItem
                )}
                onSelect={setSelectedTemplate}
                onDelete={confirmDeleteModal}
                value={selectedTemplate}
              />
            </Content>
          </Layout>
        )}
      </StyledContent>
    );
  },
  {
    width: '75vw',
  }
);
