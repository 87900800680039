/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GetSafeguardUsersResponseBody = {
    userId: number;
    email: string;
    fullName: string;
    birthdayDate: string | null;
    pictureUri: string | null;
    groups: Array<{
        id: number;
        name: string;
    }> | null;
    churches: Array<{
        id: number;
        name: string;
    }> | null;
    safeguardInfo: {
        type: GetSafeguardUsersResponseBody.type;
        workAndResponsibilities: string;
        parishIds?: Array<number>;
        safeguardRequirement: GetSafeguardUsersResponseBody.safeguardRequirement;
    };
    highestValidatedLevelInChurchDesk?: GetSafeguardUsersResponseBody.highestValidatedLevelInChurchDesk | null;
    address?: {
        street?: string | null;
        postCode?: string | null;
        city?: string | null;
        countryIso2?: string | null;
    } | null;
    certificatesOfConduct: Array<{
        reviewDate: string;
        thirdPartyReviewName?: string | null;
        refreshRequired?: boolean;
        refreshDate?: string | null;
        documentWhereabouts: 'returned' | 'destroyed';
        comments?: string | null;
        reviewedBy?: string | null;
        id: string;
    }>;
    courses: Array<{
        reviewDate: string;
        thirdPartyReviewName?: string | null;
        refreshRequired?: boolean;
        refreshDate?: string | null;
        courseTitle: string;
        courseType: 'basic' | 'extended';
        courseDate: string;
        courseDuration?: number | null;
        reviewedBy?: string | null;
        id: string;
        files?: Array<{
            title: string;
            url: string;
        }> | null;
    }>;
    documents: Array<{
        reviewDate: string;
        thirdPartyReviewName?: string | null;
        refreshRequired?: boolean;
        refreshDate?: string | null;
        documentType: 'codeOfConduct' | 'selfDisclosure' | 'combinedDeclaration';
        reviewedBy?: string | null;
        id: string;
        file?: {
            title: string;
            url: string;
        } | null;
    }>;
};
export namespace GetSafeguardUsersResponseBody {
    export enum type {
        EMPLOYEE = 'employee',
        VOLUNTEER = 'volunteer',
    }
    export enum safeguardRequirement {
        NOT_REQUIRED = 'not required',
        VERIFIED_BY_THIRD_PARTY = 'verified by third party',
        MINIMUM = 'minimum',
        BASIC = 'basic',
        BASIC_PLUS = 'basic plus',
        EXTENDED = 'extended',
        EXTENDED_PLUS = 'extended plus',
    }
    export enum highestValidatedLevelInChurchDesk {
        NOT_REQUIRED = 'not required',
        VERIFIED_BY_THIRD_PARTY = 'verified by third party',
        MINIMUM = 'minimum',
        BASIC = 'basic',
        BASIC_PLUS = 'basic plus',
        EXTENDED = 'extended',
        EXTENDED_PLUS = 'extended plus',
    }
}

