/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostPV2PaymentsCompletepaymentRequestBody = {
    payment: {
        amount: number;
        contributionId: string;
        type: PostPV2PaymentsCompletepaymentRequestBody.type;
        frequency?: PostPV2PaymentsCompletepaymentRequestBody.frequency | null;
    };
    payer: {
        email: string;
        firstName: string;
        lastName?: string;
        mobilePhone?: string;
        peopleNewsletterListId?: number;
        giftAid?: boolean;
        cpr?: string;
        address?: {
            street?: string;
            zip?: string;
            country?: string;
            city?: string;
        };
        consentTypeIds?: Array<string>;
    };
};
export namespace PostPV2PaymentsCompletepaymentRequestBody {
    export enum type {
        CARD = 'CARD',
        TERMINAL = 'TERMINAL',
        APPLEPAY = 'APPLEPAY',
        GOOGLEPAY = 'GOOGLEPAY',
        SEPA = 'SEPA',
        MOBILEPAY = 'MOBILEPAY',
    }
    export enum frequency {
        ONE_TIME = 'one_time',
        MONTH = 'month',
    }
}

