import _ from 'lodash';
import Uri from 'jsuri/Uri.js';

import { getChurches } from '../../../react/shared/store/resources/index';
import FormService from '../../../react/forms/services/FormService';

class ViewFormController {
  constructor(
    $rootScope,
    $state,
    $filter,
    $scope,
    gettextCatalog,
    AuthenticationService,
    FormResponses,
    ColumnManager,
    paginationOptions,
    $uibModal,
    Users,
    $ngRedux,
    base64,
    cdApp,
    Authorization,
    FeatureToggleService,
    toastr
  ) {
    'ngInject';

    this.FeatureToggleService = FeatureToggleService;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$filter = $filter;
    this.gettextCatalog = gettextCatalog;
    this.AuthenticationService = AuthenticationService;
    this.FormResponses = FormResponses;
    this.ColumnManager = ColumnManager;
    this.paginationOptions = paginationOptions;
    this.$uibModal = $uibModal;
    this.Users = Users;
    this.$ngRedux = $ngRedux;
    this.base64 = base64;
    this.cdApp = cdApp;
    this.Authorization = Authorization;
    this.$scope = $scope;
    this.toastr = toastr;

    const unsubscribe = $ngRedux.connect(
      this.mapStateToScope,
      this.mapDispatchToScope
    )(this);
    $scope.$on('$destroy', unsubscribe);
  }

  /**
   * Life-cycle hook used for initialization work
   */
  $onInit() {
    this._ = _;

    const {
      cdApp,
      Users,
      gettextCatalog,
      ColumnManager,
      FormResponses,
      paginationOptions,
      Authorization,
    } = this;

    // Construct the responses table columns from the form components
    this.tableColumns = [];

    const form = angular.copy(this.form);

    this.canAccessContributions = Authorization.hasPermission(
      'canAccessContributions'
    );

    this.canAccessContributionsAndHasContributionAttached =
      this.canAccessContributions && this.form.contributionId;
    this.formHasOnlineTickets =
      this.form.paymentMethod === 'online' && this.form.hasTicketsWithPrice();

    // Multi church access
    this.isMultiChurch = this.cdApp.showChurchSelector;

    // No need to show the submit button as a column in the table
    _.remove(
      form.components,
      (component) =>
        component.type === 'button' && component.action === 'submit'
    );

    // flatten the table
    _.forEach(form.components, (column) => {
      if (!column.components) {
        this.tableColumns.push(column);
      }
      _.forEach(column.components, (component) => {
        this.tableColumns.push(
          angular.extend(angular.copy(component), {
            label: _.reject([component.label, column.title], _.isEmpty).join(
              ' - '
            ),

            key: column.key + '.' + component.key,
            parent: column,
          })
        );
      });
    });

    // Add columns for tickets and donation amount if necessary
    if (this.form.hasTicketsWithPrice()) {
      this.tableColumns.push({
        key: 'ticketsTotal',
        label: gettextCatalog.getString('Total ticket price'),
      });
    }
    if (
      Authorization.hasPermission('canAccessContributions') &&
      this.form.contributionId
    ) {
      this.tableColumns.push({
        key: 'donationAmount',
        label: gettextCatalog.getString('Donation'),
      });
    }

    // Configure the column manager with those columns
    const originalColumns = _.map(this.tableColumns, (component) => ({
      property: component.key,
      label: component.label,
      isVisible: true,
      group: gettextCatalog.getString('Columns'),
    }));

    this.columnManager = new ColumnManager({
      name: null,
      columns: originalColumns,
    });

    // Format responses according to their types
    this.formattedResponses = FormResponses.formatResponses(
      this.form,
      this.formResponses
    );

    /**
     * Default sorting
     */
    this.sorting = {
      reverse: false,
    };

    /**
     * Client-side pagination
     */
    this.pageNumber = 1;
    this.paginationStorageKey = 'formResponses';

    const savedValue = paginationOptions.itemsPerPage.get(
      this.paginationStorageKey
    );

    if (!savedValue) {
      this.itemsPerPage = 50;
      paginationOptions.itemsPerPage.set(
        this.paginationStorageKey,
        this.itemsPerPage
      );
    } else {
      this.itemsPerPage = _.parseInt(savedValue);
    }

    // Sort by date of submission
    this.setSorting('createdAt');

    // At what length should the submission fields be truncated
    this.maxSubmissionFieldLength = 30;

    // Set if user is a masquerading
    this.masquerading = cdApp.me.masquerading;

    // Get the user object for the form author
    this.formAuthor = Users.get({ id: this.form.authorId });

    // Get a list of the ticket components in the form
    this.ticketComponents = this.form.getTicketComponents();

    // Get a list of the form people sections in the form
    this.numberOfFormPeopleSections = this.form.countFormPeopleSections();
    if (this.numberOfFormPeopleSections > 0) {
      this.isLoadingFormPeopleSections = true;
      this.form
        .getFormPeopleSections()
        .then(({ data }) => {
          this.formPeopleSections = data;
        })
        .finally(() => {
          this.isLoadingFormPeopleSections = false;
        });
    }

    // Calculate the total tickets (total max tickets and total sold tickets)
    this.totalTickets = { sold: 0, max: 0 };
    _.forEach(this.ticketComponents, (ticket) => {
      const ticketObject = _.get(this.form, `tickets.${ticket.key}`);
      this.totalTickets.sold += ticketObject.sold;
      this.totalTickets.max += ticketObject.max;
    });

    this.createQRCode();
  }

  /**
   * Delete the current form
   */
  deleteForm() {
    const { $state } = this;

    this.form.delete().then(() => {
      $state.go('app.private.forms.list');
    });
  }

  /**
   * Export the form's responses
   * @param {string} [fileType='csv'] - The exported file's type. Could be 'csv', 'xlsx', 'ods'
   */
  exportResponses(fileType) {
    const { cdApp, AuthenticationService } = this;

    if (!fileType) fileType = 'csv';

    const url = new Uri(cdApp.config.api.forms);
    url.setPath('forms/' + _.get(this, 'form.id') + '/export');
    url.addQueryParam('language', _.get(cdApp, 'organization.locale.language'));
    url.addQueryParam('organizationId', _.get(cdApp, 'organization.id'));
    url.addQueryParam('accessToken', AuthenticationService.getAccessToken());
    url.addQueryParam('exportFormat', fileType);

    window.location = url.toString();
  }

  tableHeadClicked(newVal) {
    // if there's nothing to sort, return
    if (!this.filteredResponses || !this.filteredResponses.length) return;
    this.pageNumber = 1;
    this.setSorting(newVal);
  }

  getTableHeaderClass(columnName) {
    if (this.sorting.by !== columnName) return;
    return this.sorting.reverse
      ? 'fa fa-fw fa-sort-down'
      : 'fa fa-fw fa-sort-up';
  }

  setSorting(newVal) {
    if (newVal === this.sorting.by) {
      this.sorting.reverse = !this.sorting.reverse;
    } else {
      this.sorting.by = newVal;
      this.sorting.reverse = true;
    }
    this.filterResponses();
  }

  /**
   * Filter responses based on a search value
   */
  onSearchChanged() {
    this.pageNumber = 1;
    this.filterResponses();
  }

  /**
   * Filter responses based on search, pagination and sort the responses.
   */
  filterResponses() {
    const { $rootScope, $filter } = this;

    // Sort based on the sorting options
    const orderedResponses = _.orderBy(
      this.formattedResponses,
      (response) => {
        const value =
          _.get(response, this.sorting.by) ||
          _.get(response.data, this.sorting.by);
        return _.isString(value) ? _.lowerCase(value) : value;
      },
      this.sorting.reverse ? 'desc' : 'asc'
    );

    // Filter by search query
    this.filteredResponses = $filter('filter')(orderedResponses, this.search);

    // Paginate the responses
    const startAt = (this.pageNumber - 1) * this.itemsPerPage;
    const endAt = this.pageNumber * this.itemsPerPage;
    this.responsesPage = _.slice(this.filteredResponses, startAt, endAt);

    // trigger the column manager
    $rootScope.$broadcast('columnManager:updateLayout');
  }

  /**
   * Change the page
   *
   * @param {Number} page - The new page number
   */
  onPageChanged(page) {
    this.pageNumber = page;
    this.filterResponses();
  }

  /**
   * Change the number of items displayed per page
   *
   * @param {Number} itemsPerPage - The new number of items to be displayed
   */
  onItemsPerPageChanged(itemsPerPage) {
    this.pageNumber = 1;
    this.itemsPerPage = itemsPerPage;
    this.filterResponses();
  }

  /**
   * Duplicate the current form
   */
  duplicateForm() {
    const { $state } = this;

    this.form.duplicate().then((response) => {
      $state.go('app.private.forms.view', { id: _.get(response, 'data.id') });
    });
  }

  /**
   * Go to the full view of a response
   *
   * @param {String} responseId The id of the response
   */
  goToResponse(responseId) {
    const { $state } = this;

    $state.go('app.private.forms.responses', {
      formId: this.form.id,
      responseId,
      form: this.form,
    });
  }

  /**
   * Get the list of churches that the form is added to
   *
   */
  getChurches(churchIds) {
    const { _, churches } = this;
    const churchesInForm = _.filter(churches, (church) =>
      _.includes(churchIds, church.id)
    );

    return churchesInForm;
  }

  copyUrlToClipboard() {
    const { toastr, gettextCatalog } = this;
    const url = document.querySelector('#formUrl');
    url.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        toastr.info(gettextCatalog.getString('Link copied to clipboard.'));
      } else {
        unsuccessful();
      }
    } catch (err) {
      unsuccessful();
    }

    function unsuccessful() {
      toastr.error(
        gettextCatalog.getString(
          "Couldn't copy link. Try right clicking on the link to copy."
        )
      );
    }
  }

  /**
   * Open a modal that shows the details of tickets status
   *
   */
  viewTicketsStatus() {
    const { $uibModal } = this;

    $uibModal.open({
      component: 'formTicketsDetailsModal',
      resolve: {
        ticketComponents: () => this.ticketComponents,
        form: () => this.form,
      },
    });
  }

  /**
   * Filter only visible columns
   */
  filterTableColumns() {
    return (column) =>
      _.result(
        _.find(this.columnManager.columns, { property: column.key }),
        'isVisible'
      );
  }

  /**
   * Sync form submissions to people.
   */
  syncSubmissions() {
    this.form.syncSubmissions();
  }

  /**
   * Go to the contacts list, pre-filtering the contacts
   * that filled the specified form's people section.
   */
  goToContactListFilteringByFormPeopleSection(peopleSectionKey = null) {
    const { $state, base64 } = this;

    const formId = this.form.id;
    const filterCriteria = [
      {
        operator: 'eq',
        value: peopleSectionKey ? `${formId}_${peopleSectionKey}` : formId,
        property: peopleSectionKey ? 'formPeopleSections' : 'forms',
        type: 'list',
      },
    ];

    $state.go('app.private.people.contacts.list', {
      filter: base64.urlencode(angular.toJson(filterCriteria)),
    });
  }

  /**
   * Confirm country to create a stripe account and enable charging for this organization
   * OR fill out the remaining required information from stripe.
   */
  enableCharging() {
    const { $state } = this;
    $state.go('app.private.settings.mvrForm');
  }

  getEmbeddableForm() {
    const { form } = this;
    const embeddedLink = `${form.getUrl()}/embed`;
    const script =
      '<script type="text/javascript">!function(){var e=window.addEventListener?"addEventListener":"attachEvent",t=window[e];window.cdFormEventHandlersRegistered||(t("attachEvent"==e?"onmessage":"message",function(e){if("cdFormEmbed"===e.data.source&&"FORM_RESIZE_HEIGHT"===e.data.action){var t=e.data.formId,d=document.getElementById(t),n=e.data.height+40;d.style.height=n+"px"}},!1),window.cdFormEventHandlersRegistered=!0)}();</script>';
    const style =
      '<style type="text/css">.embed-container { position: relative; overflow: hidden; max-width: 100%; } .embed-container iframe { position: relative; top: 0; left: 0; width: 100%; height: 100%; min-width: 600px; min-height: 600px;} @media (max-width: 600px) {.embed-container iframe {min-width: auto;}} </style>';
    const containerDiv = `<div class='embed-container' id='form__${form.id}'><iframe src='${embeddedLink}' data-cookieconsent="ignore" style='border:0;'></iframe></div>`;
    return `${script}${style}${containerDiv}`;
  }

  createEmbedForm() {
    const { $uibModal } = this;
    $uibModal.open({
      component: 'cdEmbedWidgetModalComponent',
      resolve: {
        code: () => this.getEmbeddableForm(),
      },
    });
  }

  getQRCodeDownloadName() {
    const { title } = this.form;

    return _.snakeCase('QR_' + title);
  }

  createQRCode() {
    const { form } = this;

    FormService.getQRcode(form.path).then((res) => {
      this.formQRcode = res;
      this.$scope.$apply();
    });
  }

  // AngularJS <-> Redux mapping functions

  mapStateToScope = (state) => ({
    churches: getChurches(state),
  });
}
ViewFormController.$inject = [
  '$rootScope',
  '$state',
  '$filter',
  '$scope',
  'gettextCatalog',
  'AuthenticationService',
  'FormResponses',
  'ColumnManager',
  'paginationOptions',
  '$uibModal',
  'Users',
  '$ngRedux',
  'base64',
  'cdApp',
  'Authorization',
  'FeatureToggleService',
  'toastr',
];

angular.module('cdApp.forms').component('cdViewForm', {
  templateUrl: '@/app/forms/view-form/view-form.html',
  controller: ViewFormController,
  bindings: {
    form: '<formObject',
    formResponses: '<',
  },
});
