import _ from 'lodash';
import { createSelector } from 'reselect';

import { COLOR_INDEX } from '../../../../app/ResourceColors';
import { getCurrentCountryCode } from '../../../config/store/Selector';
import { AppState } from '../../../redux';
import { Category } from '../../../shared/models/category';
import { Resource } from '../../../shared/models/resource';
import { getCalendarView } from '../main-view/Selectors';

import { ChurchCategories, FilterType } from '.';

import { getColorBasedOnColorIndex } from '@/react/calendar/components/event-item/colors';
import { MyCalendarSelectionState } from '@/react/calendar/store/filters/types';

export const selectFiltersRoot = (state: AppState) => state.calendar.filters;
export const selectAll = (state: AppState) => state;
export const selectFilterData = createSelector(
  selectFiltersRoot,
  (filters) => filters.filterData
);

export const getCategories = createSelector(
  selectFilterData,
  (filterData) => filterData.categories
);

export const getCategoryById = (id: number) => (state: AppState) =>
  state.calendar.filters.calendarData.categories[id];

export const getParentResources = (state: AppState) =>
  state.calendar.filters.filterData.parentResources;

export const getParentResourceById = (id: number) =>
  createSelector(getParentResources, (parentResources) => parentResources[id]);

export const getUsersFilters = (state: AppState) =>
  state.calendar.filters.filterData.users;

export const getSelectedCalendars = (state: AppState) =>
  state.calendar.filters.selectedCalendars;

export const getSelectedUsers = (state: AppState) => {
  const selectedUsers = state.calendar.filters.selectedUsers;
  return state.calendar.filters.selectedCalendars.filter(
    (calendar) =>
      calendar.filterType === FilterType.users && selectedUsers[calendar.id]
  );
};

const getCalendarData = (state: AppState) =>
  state.calendar.filters.calendarData;

export const getSelectedCalendarsRich = createSelector(
  getSelectedCalendars,
  getCalendarData,
  (selectedCalendars, calendarData) =>
    selectedCalendars.map((s) => {
      const fullItem =
        s.filterType === 'Resources'
          ? calendarData.resources[s.id]
          : calendarData.users[s.id];
      return {
        ...s,
        name: fullItem.name,
      };
    })
);

export const getResources = createSelector(
  getCalendarData,
  (calendarData) => calendarData.resources
);
export const getUsers = createSelector(
  getCalendarData,
  (calendarData) => calendarData.users
);

export const getCalendar = (calendarId: number, calendarType: FilterType) =>
  createSelector(getResources, getUsers, (resources, users) => {
    switch (calendarType) {
      case FilterType.resources:
        return resources[calendarId];
      case FilterType.users:
        return users[calendarId];
      default:
        return false;
    }
  });

const selectSelectedUsers = (state: AppState) =>
  state.calendar.filters.selectedUsers;
const selectSelectedResources = (state: AppState) =>
  state.calendar.filters.selectedResources;
export const isCalendarSelected = (
  calendarId: number,
  calendarType: FilterType
) =>
  createSelector(
    selectSelectedResources,
    selectSelectedUsers,
    (selectedResources, selectedUsers) => {
      switch (calendarType) {
        case FilterType.resources:
          return _.get(selectedResources, `[${calendarId}]`, false);
          break;
        case FilterType.users:
          return _.get(selectedUsers, `[${calendarId}]`, false);
          break;
        default:
          return false;
          break;
      }
    }
  );

export const getUnselectedCalendars = (state: AppState) =>
  state.calendar.filters.unselectedCalendars;

const getSelectedChurchId = (state: AppState) =>
  _.get(state, 'config.organizationData.churchId', null);
const getOrganizationChurches = (state: AppState) =>
  state.config.organization.churches;

export const getSelectedChurch = createSelector(
  getSelectedChurchId,
  getOrganizationChurches,
  (selectedChurchId, organizationChurches): Resource[] => {
    if (selectedChurchId) {
      const selectedChurch = _.find(
        organizationChurches,
        (item) => item.id === selectedChurchId
      );
      if (selectedChurch) {
        return [selectedChurch.asMutable({ deep: true })];
      }
      return [];
    }
    const churches = organizationChurches;
    if (churches) {
      return churches.asMutable({ deep: true });
    }
    return [];
  }
);

export const selectIsUserFiltersLoaded = (state: AppState) =>
  state.calendar.filters.isUserFiltersLoaded;

export const getSelectedChurchCategories = (state: AppState) =>
  state.calendar.filters.selectedChurchCategories;

export const getAllSelectedCategories = (
  churchId: number,
  lowerCaseSearchPhrase: string
) =>
  createSelector(
    getSelectedChurchCategories,
    getCategories,
    (selectedChurchCategories, allCategories) => {
      let visibleCategories = allCategories;
      if (lowerCaseSearchPhrase) {
        visibleCategories = allCategories.filter((category) =>
          category.name.toLowerCase().includes(lowerCaseSearchPhrase)
        );
      }
      const selectedVisibleCategories = visibleCategories.filter(
        (category) => selectedChurchCategories[churchId]?.[category.id] ?? false
      );
      const isAllSelected =
        selectedVisibleCategories.length === visibleCategories.length;

      if (isAllSelected && selectedVisibleCategories.length > 0) {
        return true;
      }
      if (selectedVisibleCategories.length > 0) {
        return null;
      }
      return false;
    }
  );

export const isAllSelected = (
  state: AppState,
  churchId: number | undefined | null
): boolean => {
  const selectedChurchCategories =
    state.calendar.filters.selectedChurchCategories;
  const allCategoriesIds = state.calendar.filters.filterData.categories;
  const currentSelectedCategories = _.keys(
    _.omit(
      _.pickBy(selectedChurchCategories[churchId], (value) => value),
      'isAllSelected'
    )
  );
  const isAllSelected =
    _.size(currentSelectedCategories) === _.size(allCategoriesIds);
  return isAllSelected
    ? isAllSelected
    : currentSelectedCategories.length > 0
      ? null
      : false;
};

export const getAbsenceView = (state: AppState) =>
  state.calendar.filters.absenceView;

export const getShowAbsence = (state: AppState) =>
  state.calendar.filters.showAbsences;

export const getCalendarSubscriptionSavedFilters = (state: AppState) =>
  state.calendar.filters.calendarSubscriptions;

export const getShowMyCalendar = (state: AppState) =>
  state.calendar.filters.showMyCalendar;

export interface Filter {
  id: string; // id + type (Ex. 22-Resources)
  originalId: string; // id
  type: FilterType;
  title: string;
  sortOrder: number;
  color?: string;
}

export const getFilterData = (state: AppState) =>
  state.calendar.filters.filterData;
export const getCurrentUser = (state: AppState) => state.config.me;

// SORT PRIORITY:
// 1) Users - Alphabetically
// 2) Resources - Alphabetically
export const getSelectedFilters = createSelector(
  selectSelectedUsers,
  selectSelectedResources,
  getFilterData,
  getShowMyCalendar,
  getCurrentUser,
  getCalendarView,
  (users, resources, data, showMyCalendar, currentUser, currentView) => {
    // Get selected resources
    if (currentView !== 'resourceTimeGridDay') return [];
    const selectedResources: Filter[] = _.map(
      _.pickBy(resources, (value) => value),
      (value, key) => {
        const selectedResource = _.find(data.resources, ['id', parseInt(key)]);
        const colorIndex = _.get(selectedResource, 'color') || 0;
        const colorSettings = getColorBasedOnColorIndex(colorIndex);
        return {
          id: key + '-' + FilterType.resources,
          originalId: key,
          type: FilterType.resources,
          title: _.get(selectedResource, 'name'),
          sortOrder: 2,
          color: COLOR_INDEX[colorIndex],
          colorSettings,
        };
      }
    );

    // Get selected users
    const selectedUsers: Filter[] = _.map(
      _.pickBy(users, (value) => value),
      (value, key) => {
        const selectedUser = _.find(data.users, ['id', parseInt(key)]);
        if (selectedUser && selectedUser.name) {
          return {
            id: key + '-' + FilterType.users,
            originalId: key,
            type: FilterType.users,
            title: _.get(selectedUser, 'name'),
            sortOrder: 1,
            image: _.get(selectedUser, 'picture'),
          };
        }
      }
    );

    let myCalendar;
    if (showMyCalendar && !_.isEmpty(currentUser)) {
      myCalendar = {
        id: currentUser.id + '-' + FilterType.users,
        originalId: currentUser.id.toString(),
        type: FilterType.users,
        title: currentUser.fullName,
        sortOrder: 1,
        image: _.get(currentUser, 'picture.url'),
      };
      selectedUsers.push(myCalendar);
    }

    // Order by alphabetical order
    // Compact to remove all resources and users that were not found (undefined)
    const sortedSelectedResources = _.sortBy(_.compact(selectedResources), [
      (resource) => resource.title?.toLowerCase(),
    ]);
    const sortedSelectedUsers = _.sortBy(_.compact(selectedUsers), [
      (user) => user.title?.toLowerCase(),
    ]);
    // Combine filters
    const selectedFilters = _.compact(
      _.concat(sortedSelectedResources, sortedSelectedUsers)
    );

    return selectedFilters;
  }
);

export const selectShowMyCalendar = (state: AppState) =>
  state.calendar.filters.showMyCalendar;
export const selectShowLiturgicalHolidays = (state: AppState) =>
  state.calendar.view.showLiturgicalHolidays;
export const selectShowNationalHolidays = (state: AppState) =>
  state.calendar.view.showNationalHolidays;
const selectShowCollections = (state: AppState) =>
  state.calendar.view.showCollections;

export const selectCalendarSubscriptions = (state: AppState) =>
  state.calendar.view.calendarSubscriptions;
export const selectActiveCalendarSubscriptions = (state: AppState) =>
  state.calendar.filters.calendarSubscriptions;

export const selectIsAllCalendarSubscriptionsSelected = createSelector(
  selectCalendarSubscriptions,
  selectActiveCalendarSubscriptions,
  (calendarSubscriptions, activeCalendarSubscriptions) => {
    if (_.isEmpty(calendarSubscriptions)) {
      return undefined;
    }
    if (
      _.every(
        calendarSubscriptions,
        (subscription) => activeCalendarSubscriptions[subscription.id]
      )
    ) {
      return true;
    }
    if (
      _.some(
        calendarSubscriptions,
        (subscription) => activeCalendarSubscriptions[subscription.id]
      )
    ) {
      return null;
    }
    return false;
  }
);

export const selectIsAllHolidaysSelected = createSelector(
  selectShowNationalHolidays,
  selectShowLiturgicalHolidays,
  getCurrentCountryCode,
  (showNationalHolidays, showLiturgicalHolidays, countryCode) => {
    if (countryCode === 'de') {
      if (showNationalHolidays && showLiturgicalHolidays) {
        return true;
      }
      if (showNationalHolidays || showLiturgicalHolidays) {
        return null;
      }
      return false;
    }
    return showNationalHolidays;
  }
);

export const selectMyCalendarsSelectionState = createSelector(
  selectShowMyCalendar,
  selectShowCollections,
  selectShowNationalHolidays,
  selectShowLiturgicalHolidays,
  (state: AppState) => state.calendar.view.nationalHolidays,
  (state: AppState) => state.calendar.view.liturgicalHolidays,
  selectCalendarSubscriptions,
  selectActiveCalendarSubscriptions,
  (
    showMyCalendar,
    showCollections,
    isNationalHolidaysSelected,
    isLiturgicalHolidaysSelected,
    nationalHolidays,
    liturgicalHolidays,
    calendarSubscriptions,
    activeCalendarSubscriptions
  ): MyCalendarSelectionState => {
    const result = {
      me: showMyCalendar,
      collections: showCollections,
      national: isNationalHolidaysSelected,
      liturgical: isLiturgicalHolidaysSelected,
      shared: calendarSubscriptions.asMutable().map((s) => ({
        calendar: s,
        isSelected: activeCalendarSubscriptions[s.id],
      })),
    };
    if (_.isEmpty(nationalHolidays)) {
      delete result.national;
    }
    if (_.isEmpty(liturgicalHolidays)) {
      delete result.liturgical;
    }
    return result;
  }
);

export const getActiveUsersAndRoomsFiltersCount = createSelector(
  getSelectedCalendars,
  selectSelectedUsers,
  selectSelectedResources,
  (selectedCalendars, users, resources) =>
    _.filter(selectedCalendars, (calendar) => {
      if (calendar.filterType === FilterType.users) {
        return users[calendar.id];
      } else {
        return resources[calendar.id];
      }
    }).length
);

export const selectIsAllRoomsAndUsersSelected = createSelector(
  getSelectedCalendars,
  getActiveUsersAndRoomsFiltersCount,
  (selectedCalendars, activeUsersAndRoomsFiltersCount) => {
    if (activeUsersAndRoomsFiltersCount === 0) {
      return false;
    }
    if (selectedCalendars.length === activeUsersAndRoomsFiltersCount) {
      return true;
    }
    return null;
  }
);

export const selectIsAllParishesChurchesSelected = createSelector(
  getSelectedChurch,
  getCategories,
  getSelectedChurchCategories,
  (churches, categories, selectedCategories) => {
    const selectionStatus = _.map(churches, (church) =>
      isAllChurchCategoriesSelected(church.id, categories, selectedCategories)
    );
    if (_.every(selectionStatus, (value) => value)) {
      return true;
    }
    if (_.some(selectionStatus, (value) => value || value === null)) {
      return null;
    }
    if (_.every(selectionStatus, (value) => !value)) {
      return false;
    }
  }
);

const isAllChurchCategoriesSelected = (
  churchId: number,
  categories: Category[],
  selectedChurches: ChurchCategories
): boolean | null => {
  const currentSelectedCategories = _.keys(
    _.omit(
      _.pickBy(selectedChurches[churchId], (value) => value),
      'isAllSelected'
    )
  );
  const isAllSelected = currentSelectedCategories.length === categories.length;
  return isAllSelected
    ? isAllSelected
    : currentSelectedCategories.length > 0
      ? null
      : false;
};

export const isCategorySelected = (categoryId: number, churchId: number) =>
  createSelector(selectFiltersRoot, (filters) =>
    _.get(
      filters,
      `selectedChurchCategories[${churchId}][${categoryId}]`,
      false
    )
  );
