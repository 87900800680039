import { Select, Space } from 'antd';
import React, { ComponentProps, useMemo } from 'react';

import { getVisibilityOptions } from '@/react/calendar/helpers/visibility';
import { ClientEventVisibility } from '@/react/calendar/types/event';
import { I18nService } from '@/react/services/I18nService';
import { EventIcons } from '@/react/shared/components/Icons';

const ICONS: Record<ClientEventVisibility, React.ReactNode> = {
  [ClientEventVisibility.PUBLIC]: <EventIcons.VisibilityPublic />,
  [ClientEventVisibility.INTERNAL_ALL]: <EventIcons.VisibilityChurch />,
  [ClientEventVisibility.INTERNAL_GROUPS]: <EventIcons.VisibilityUser />,
  [ClientEventVisibility.PRIVATE]: <EventIcons.VisibilityLock />,
};

type VisibilitySelectProps = Omit<
  ComponentProps<typeof Select>,
  'placeholder' | 'options'
>;

export const VisibilitySelect = (props: VisibilitySelectProps) => {
  const enhancedOptions = useMemo(
    () =>
      getVisibilityOptions().map((option) => ({
        ...option,
        label: (
          <Space align="center">
            {ICONS[option.value]}
            {I18nService.getString(option.label)}
          </Space>
        ),
      })),
    []
  );

  return (
    <Select
      aria-label="visibility"
      options={enhancedOptions}
      placeholder={I18nService.getString('Choose a visibility setting')}
      {...props}
    />
  );
};
