import {
  faExclamationTriangle,
  faPencilAlt,
  faRss,
  faSyncAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from 'redux-saga-modal';

import { I18nService } from '../../services/I18nService';
import MODAL_TYPES from '../sagas/ModalTypes';
import { ToggleCalendarSubscriptionAction } from '../store/filters/Actions';
import { getCalendarSubscriptionSavedFilters } from '../store/filters/Selectors';
import {
  DeleteCalendarSubscriptionAction,
  ForceSyncCalendarSubscriptionAction,
} from '../store/main-view/Actions';
import { getCalendarSubscriptions } from '../store/main-view/Selectors';

import { SearchableFilterItem } from '@/react/calendar/components/SearchableFilterItem';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';

const CalendarSubscriptions: FunctionComponent<{ isDisabled: boolean }> = ({
  isDisabled,
}) => {
  const dispatch = useDispatch();

  const calendarSubscriptions = useSelector(
    getCalendarSubscriptions,
    _.isEqual
  );
  const calendarSubscriptionsFilters = useSelector(
    getCalendarSubscriptionSavedFilters,
    _.isEqual
  );
  const handleToggle = useCallback(
    (calendarSubscription, isChecked) => {
      dispatch(
        ToggleCalendarSubscriptionAction({
          id: calendarSubscription.id,
          isChecked,
        })
      );
    },
    [dispatch]
  );
  const onClose = useCallback(
    (calendarSubscription) => {
      showConfirmModal({
        title: I18nService.getString(
          'Are you sure you want to remove the calendar "{{name}}"?',
          {
            name: calendarSubscription.name,
          }
        ),
        message: I18nService.getString(
          'If you remove this calendar subscription, all events related to this calendar will be removed from your calendar.'
        ),
        okText: I18nService.getString('Remove'),
        okButtonProps: { danger: true },
        onOk: () => {
          dispatch(DeleteCalendarSubscriptionAction(calendarSubscription.id));
        },
      });
    },
    [dispatch]
  );
  const onEdit = useCallback(
    (calendarSubscription) => {
      dispatch(
        showModal(MODAL_TYPES.CALENDAR_SUBSCRIPTION_MODAL, {
          calendarSubscription,
        })
      );
    },
    [dispatch]
  );

  const onOnForceSync = useCallback(
    (calendarSubscription) => {
      dispatch(ForceSyncCalendarSubscriptionAction(calendarSubscription.id));
    },
    [dispatch]
  );

  const content = (
    <div>
      {calendarSubscriptions
        ? calendarSubscriptions.map((calendarSubscription) => {
            const isChecked =
              !!calendarSubscriptionsFilters[calendarSubscription.id];
            const syncFailed =
              calendarSubscription.isProcessing === -1 ||
              calendarSubscription.noOfAttempts >= 5;
            return (
              <SearchableFilterItem
                key={`calendarSubscription${calendarSubscription.id}`}
                isDisabled={isDisabled}
                onLabelClick={() => {
                  if (isDisabled) return;
                  handleToggle(calendarSubscription, isChecked);
                }}
                isSelected={isChecked}
                itemData={calendarSubscription}
                color={calendarSubscription.color}
                namePrefix={
                  <>
                    {syncFailed ? (
                      <CdTooltip
                        title={I18nService.getString(
                          'The feed failed to update, please click resync to get the latest update.'
                        )}
                      >
                        <FontAwesomeIcon
                          color="#e74c3c"
                          icon={faExclamationTriangle}
                          style={{ marginRight: 5 }}
                        />
                      </CdTooltip>
                    ) : (
                      <FontAwesomeIcon
                        icon={faRss}
                        style={{ marginRight: 5 }}
                      />
                    )}
                  </>
                }
                name={calendarSubscription.name}
                extraActions={
                  <>
                    {syncFailed ? (
                      <CdTooltip title={I18nService.getString('Resync feed')}>
                        <FontAwesomeIcon
                          icon={faSyncAlt}
                          onClick={() => {
                            if (isDisabled) return;
                            onOnForceSync(calendarSubscription);
                          }}
                          style={{ marginRight: 5, cursor: 'pointer' }}
                        />
                      </CdTooltip>
                    ) : null}
                    <CdTooltip title={I18nService.getString('Edit')}>
                      <FontAwesomeIcon
                        icon={faPencilAlt}
                        onClick={() => {
                          if (isDisabled) return;
                          onEdit(calendarSubscription);
                        }}
                        style={{ marginRight: 5, cursor: 'pointer' }}
                      />
                    </CdTooltip>

                    <CdTooltip title={I18nService.getString('Remove')}>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => {
                          if (isDisabled) return;
                          onClose(calendarSubscription);
                        }}
                        style={{ marginRight: 5, cursor: 'pointer' }}
                      />
                    </CdTooltip>
                  </>
                }
              />
            );
          })
        : null}
    </div>
  );

  if (isDisabled) {
    return (
      <CdTooltip
        title={I18nService.getString(
          'This calendar cannot be enabled for this view'
        )}
      >
        {content}
      </CdTooltip>
    );
  }

  return content;
};

export default CalendarSubscriptions;
