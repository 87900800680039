import { EventApi } from '@fullcalendar/core';
import _ from 'lodash';
import styled, { css } from 'styled-components';

import { AttendanceStatus, EventType } from '@/react/calendar/models/calendar';
import { LightenDarkenColor } from '@/react/shared/utils';

interface EventContainerProps {
  event: EventApi;
  currentView: string;
  showResources: boolean;
  showIntentions: boolean;
  hasIntentions: boolean;
  allDay: boolean;
  duration: number;
  hasResources: boolean;
  isLiturgicalHoliday: boolean;
  isNationalHoliday: boolean;
  isPast: boolean;
  isAbsence: boolean;
  replyStatus: AttendanceStatus;
  backgroundColor: string;
  borderColor: string;
  borderLightColor: string;
  textColor: string;
  isFeed: boolean;
  isPlaceHolder: boolean;
  cleanupTimeMinutes: number;
  prepTimeMinutes: number;
  eventMinutes: number;
  hasPrepTime: boolean;
  hasCleanupTime: boolean;
  showPrepTime: boolean;
  isExternal: boolean;
  isCollection: boolean;
  type?: EventType;
}

const isStoleBilled = (event: EventApi) =>
  _.get(event, 'extendedProps.isStolesBilled');

const titleMixin = css<{
  currentView: string;
  duration: number;
  hasResources: boolean;
  showResources: boolean;
  hasIntentions: boolean;
  showIntentions: boolean;
  isAbsence: boolean;
  isFeed: boolean;
  isLiturgicalHoliday: boolean;
  isNationalHoliday: boolean;
  allDay: boolean;
}>`
  ${({
    currentView,
    duration,
    hasResources,
    showResources,
    isAbsence,
    isLiturgicalHoliday,
    isNationalHoliday,
    allDay,
    isFeed,
    hasIntentions,
    showIntentions,
  }) => {
    if (isAbsence && currentView === 'dayGridMonth') {
      return allDay
        ? css`
            white-space: normal;
            --max-lines: 2;
          `
        : css`
            white-space: nowrap;
            --max-lines: 1;
          `;
    }
    if (
      (isAbsence && allDay && isFeed && currentView === 'timeGridWeek') ||
      currentView === 'timeGridDay' ||
      currentView === 'resourceTimeGridDay'
    ) {
      return css`
        white-space: normal;
        --max-lines: 2;
      `;
    }
    if (isLiturgicalHoliday || isNationalHoliday) {
      return showResources || showIntentions
        ? css`
            white-space: normal;
            --max-lines: 2;
          `
        : css`
            white-space: nowrap;
            --max-lines: 1;
          `;
    }
    if (currentView === 'dayGridMonth') {
      return css`
        display: flex;
        align-items: center;
      `;
    }
    if (currentView === 'timeGridWeek') {
      if (duration <= 0.5) {
        return (hasResources && showResources) ||
          (hasIntentions && showIntentions)
          ? css`
              white-space: nowrap;
              overflow: hidden;
              --max-lines: 1;
            `
          : css`
              white-space: nowrap;
              overflow: hidden;
              max-height: ${`${duration * (29 * 2) - 15}px`};
              --max-lines: 1;
            `;
      }
      if (duration > 0.5 && duration < 1) {
        return (hasResources && showResources) ||
          (hasIntentions && showIntentions)
          ? css`
              white-space: nowrap;
              overflow: hidden;
              --max-lines: 1;
            `
          : css`
              white-space: normal;
              overflow: hidden;
              max-height: ${`${duration * (29 * 2) - 15}px`};
              --max-lines: 1;
            `;
      }
      if (duration >= 1 && duration < 1.5) {
        return (hasResources && showResources) ||
          (hasIntentions && showIntentions)
          ? css`
              --max-lines: 1;
              white-space: nowrap;
            `
          : css`
              --max-lines: 2;
            `;
      }
      if (duration >= 1.5 && duration < 2) {
        return (hasResources && showResources) ||
          (hasIntentions && showIntentions)
          ? css`
              --max-lines: 1;
              white-space: nowrap;
            `
          : css`
              --max-lines: 4;
            `;
      }
      if (duration === 2) {
        return (hasResources && showResources) ||
          (hasIntentions && showIntentions)
          ? css`
              --max-lines: 2;
            `
          : css`
              --max-lines: 6;
            `;
      }
      if (duration > 2) {
        return (hasResources && showResources) ||
          (hasIntentions && showIntentions)
          ? css`
              --max-lines: 3;
            `
          : css`
              --max-lines: 10;
            `;
      }
    }
    if (
      currentView === 'timeGridDay' ||
      currentView === 'resourceTimeGridDay'
    ) {
      if (duration > 0.5 && duration <= 1) {
        return (hasResources && showResources) ||
          (hasIntentions && showIntentions)
          ? css`
              white-space: nowrap;
              overflow: hidden;
            `
          : css`
              white-space: normal;
              overflow: hidden;
              max-height: ${`${duration * (29 * 2) - 15}px`};
            `;
      }
      if (duration > 1 && duration <= 1.5) {
        return (hasResources && showResources) ||
          (hasIntentions && showIntentions)
          ? css`
              --max-lines: 1;
            `
          : css`
              --max-lines: 4;
            `;
      }
      if (duration > 1.5 && duration <= 2) {
        return (hasResources && showResources) ||
          (hasIntentions && showIntentions)
          ? css`
              --max-lines: 2;
            `
          : css`
              --max-lines: 6;
            `;
      }
      if (duration > 2) {
        return (hasResources && showResources) ||
          (hasIntentions && showIntentions)
          ? css`
              --max-lines: 3;
            `
          : css`
              --max-lines: 8;
            `;
      }
    }
    return '';
  }}
`;

const heightMixin = css<{
  currentView: string;
  showResources: boolean;
  showIntentions: boolean;
  allDay: boolean;
}>`
  ${({ currentView, allDay, showResources, showIntentions }) => {
    // Month view
    if (currentView === 'dayGridMonth' || allDay) {
      return showResources && showIntentions && !allDay
        ? css`
            height: 54px;
          `
        : showResources || showIntentions
          ? css`
            height: 36px;
          `
          : css`
            height: 100%;
            display: flex;
            align-items: center;
          `;
    }
    // Week & day view
    if (
      (currentView === 'timeGridWeek' ||
        currentView === 'timeGridDay' ||
        currentView === 'resourceTimeGridDay') &&
      !allDay
    ) {
      return css`
        height: 100%;
      `;
    }
    return '';
  }}
`;

const contentMixin = css<{
  currentView: string;
  duration: number;
  allDay: boolean;
  hasResources: boolean;
  showResources: boolean;
  hasIntentions: boolean;
  showIntentions: boolean;
}>`
  ${({
    currentView,
    duration,
    allDay,
    hasResources,
    showResources,
    hasIntentions,
    showIntentions,
  }) => {
    if (currentView === 'dayGridMonth') {
      return css`
        flex-direction: row;
      `;
    }
    if (currentView === 'timeGridWeek') {
      if (duration < 1) {
        return css`
          flex-direction: row;
        `;
      }
      if (duration >= 1 && duration < 1.5) {
        return hasResources && showResources && hasIntentions && showIntentions
          ? css`
              flex-direction: row;
            `
          : css`
              flex-direction: column;
            `;
      }
      if (duration >= 1.5) {
        return css`
          flex-direction: column;
        `;
      }
    }
    if (
      currentView === 'timeGridDay' ||
      currentView === 'resourceTimeGridDay'
    ) {
      if (duration < 1 || allDay) {
        return css`
          flex-direction: row;
        `;
      }
      if (duration >= 1 && duration < 1.5) {
        return hasResources && showResources && hasIntentions && showIntentions
          ? css`
              flex-direction: row;
            `
          : css`
              flex-direction: column;
            `;
      }
      if (duration >= 1.5) {
        return css`
          flex-direction: column;
        `;
      }
    }
    return '';
  }}
`;

const timeMixin = css<{
  currentView: string;
  duration: number;
  allDay: boolean;
}>`
  ${({ currentView, duration, allDay }) => {
    if (currentView === 'timeGridWeek') {
      if (allDay) {
        return css`
          display: none;
        `;
      }
      if (duration >= 0.5 && duration < 1) {
        return css`
          margin-right: 3px;
        `;
      }
      if (duration >= 1) {
        return css`
          margin-right: 3px;
          display: flex;
          margin-bottom: 2px;
        `;
      }
    }
    if (
      currentView === 'timeGridDay' ||
      currentView === 'resourceTimeGridDay'
    ) {
      if (duration >= 0.5 && duration < 1) return css``;
      if (duration >= 1 && !allDay) {
        return css`
          margin-bottom: 2px;
        `;
      }
    }
    return '';
  }}
`;

const flexMixin = css<{
  currentView: string;
  showPrepTime: boolean;
  hasCleanupTime: boolean;
  hasPrepTime: boolean;
}>`
  ${({ currentView, showPrepTime, hasCleanupTime, hasPrepTime }) => {
    // Month view
    if (
      currentView !== 'dayGridMonth' &&
      showPrepTime &&
      (hasCleanupTime || hasPrepTime)
    ) {
      return css`
        display: flex;
        flex-direction: column;
      `;
    } else {
      return css`
        overflow: hidden;
      `;
    }
  }}
`;

export const EventItemUi = styled.div<EventContainerProps>`
  ${heightMixin}
  ${flexMixin}

  ${({ backgroundColor, borderColor, textColor, borderLightColor }) => css`
      background: ${backgroundColor};
      border-left: 3px solid ${borderColor};
      border-top: 1px solid ${borderLightColor};
      border-right: 1px solid ${borderLightColor};
      border-bottom: 1px solid ${borderLightColor};
      color: ${textColor};
  `}
  ${({ replyStatus, backgroundColor, isExternal, type }) => {
    if (
      [
        AttendanceStatus.Maybe,
        AttendanceStatus.NoAnswer,
        AttendanceStatus.NotGoing,
      ].includes(replyStatus) &&
      !isExternal &&
      type !== EventType.Availability
    ) {
      return css`
        background: repeating-linear-gradient(
                -45deg,
                ${backgroundColor} 0px,
                ${backgroundColor} 8px,
                #ffffff 8px,
                #ffffff 16px
        );
      `;
    }
  }}
    
  border-radius: 3px !important;
  padding: 0px 3px;
  min-height: 26px;
  @media screen and (max-height: 699px) {
    padding: 1px;
  }
  @media print {
    color: #555 !important;
    box-shadow: inset 0px 0px 0px 1px #555 !important;
    border-left: 3px solid ${({ borderColor }) => borderColor} !important;
    background: #fff !important;
  }
  .fc-content-wrapper {
    display: flex;
    overflow: hidden;
    flex-direction: column;
      padding: 3px 0;
    flex: ${({ eventMinutes }) => (eventMinutes < 30 ? 30 : eventMinutes)};

    .fc-main-content {
      overflow: hidden;
      display: flex;
      ${contentMixin}
      .time-container {
        ${timeMixin};
        line-height: 1.4rem;
        .fc-time {
          font-weight: 400;
          letter-spacing: 0.1px;
          font-size: 11px;
          margin-right: 3px;
          text-decoration: ${({ replyStatus, isExternal }) =>
            replyStatus === AttendanceStatus.NotGoing && !isExternal
              ? 'line-through'
              : 'none'};
          white-space: nowrap;
        }
      }
      i.fa {
        display: contents;
        margin: 0;
      }
      .fc-title {
        ${titleMixin};
        text-overflow: -o-ellipsis-lastline;
        line-height: 1.4rem;
        max-height: calc(1.4rem * var(--max-lines));
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.1px;
        text-decoration: ${({ replyStatus, isExternal }) =>
          replyStatus === AttendanceStatus.NotGoing && !isExternal
            ? 'line-through'
            : 'none'};

        .svg-inline--fa {
          transform: scale(0.9);

          &.fa-calendar-times {
            transform: scale(1);
          }
        }
      }
    }
    .resource-line-intentions {
      border-radius: 4px;
      background-color: #FADB14;
      padding: 0px 3px;
      margin-right: 3px;
      & > svg {
          margin-right: 3px;
      }
    }
    .fc-extra-content {
      overflow: hidden;
      display: flex;
      flex: 1;
      flex-direction: column;

      .fc-extra-content__intentions {
        display: flex;
        margin-top: 1px;

        .fc-extra-content__intentions__info {
          border-radius: 4px;
          background-color: #FADB14;
            opacity: ${({ isPast }) => (isPast ? '0.5' : '1')};
          padding: 0px 3px;
          font-size: 10px;
          font-family: monospace !important;
          overflow: hidden;
          max-width: ${({ event }) =>
            event.extendedProps.noOfStoles > 0 ? '60%' : 'none'};
          margin-right: 3px;
          white-space: nowrap;
          & > svg {
            margin-right: 3px;
          }
        }

        .fc-extra-content__stoles__info {
          border: 1px solid
            ${({ isPast }) => (isPast ? 'rgba(32, 33, 36, 0.5)' : '#fff')};
          border-radius: 3px;
          background-color: ${({ event, isPast }) =>
            isStoleBilled(event)
              ? 'transparent'
              : isPast
                ? LightenDarkenColor('#f5222d', 50)
                : '#f5222d'};
          padding: 0px 3px;
          font-size: 10px;
          font-family: monospace !important;
          overflow: hidden;
          max-width: ${({ event }) =>
            event.extendedProps.noOfIntentions > 0 ? '40%' : 'none'};
          white-space: nowrap;
        }
      }
    }
  }
`;
