import { Modules, Packages, Facebook } from './authentication';
import { Resource } from './resource';

export enum SiteStatusTypes {
  CHURN = 'churn',
  CUSTOMER = 'customer',
  TRIAL = 'trial',
  DEMO = 'demo',
  DEVELOPER = 'developer',
  STAGING = 'staging',
  AUTOMATED_TESTING = 'automated_testing',
  POOL = 'pool',
}

export enum CountryISO2 {
  GERMANY = 'de',
  DENMARK = 'dk',
  UNITED_KINGDOM = 'gb',
}
export interface OrganizationLocale {
  country: CountryISO2;
  language: string;
  timezone: string;
}

export interface Organization {
  aegirInstallationUrl: string;
  churches: Resource[];
  countryIso2: string;
  currency?: string;
  currencyIso?: string;
  database: string;
  dioceseId: string;
  email: null | string;
  facebook: Facebook | null;
  id: number;
  installationUrl: string;
  intercomOrganizationId: number;
  intermediaryEmail: string;
  language?: string;
  locale: OrganizationLocale;
  modules: Modules;
  myGroups: number[];
  name: string;
  packages: Packages;
  parentOrganization: number;
  permissions: { [key: string]: boolean };
  planId: number;
  settings: OrganizationSettings;
  siteAddress: string;
  siteStatus: string;
  stripeCustomerId: string;
  timezone: string;
  user_organization: UserOrganization;
  categories: CategoryAndResource[];
  resources: CategoryAndResource[];
  logo?: string;
  publicId: string;
  hasMemberRegistryIntegration: boolean;
}

export interface OrganizationSettings {
  enableImageCredits: boolean;
  requireImageCredits: boolean;
}

export interface UserOrganization {
  status: string;
}
interface CategoryAndResource {
  id: number;
  name: string;
}
