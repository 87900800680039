/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PostDeduplicationRejectRequestBody } from '../models/PostDeduplicationRejectRequestBody';
import type { PostPeopleDuplicatesMergeRequestBody } from '../models/PostPeopleDuplicatesMergeRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DuplicatesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get duplicates candidate list for the organization
     * @throws ApiError
     */
    public getPeopleDuplicates({
        pageNumber = 1,
        limit = 10,
        search,
    }: {
        pageNumber?: number,
        limit?: number,
        search?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/duplicates',
            query: {
                'pageNumber': pageNumber,
                'limit': limit,
                'search': search,
            },
        });
    }
    /**
     * Merge duplicates
     * @throws ApiError
     */
    public postPeopleDuplicatesMerge({
        requestBody,
    }: {
        requestBody?: PostPeopleDuplicatesMergeRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/duplicates/merge',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Reject duplicate candidate
     * @throws ApiError
     */
    public postPeopleDuplicatesReject({
        requestBody,
    }: {
        requestBody?: PostDeduplicationRejectRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/duplicates/reject',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get duplicate candidate details
     * @throws ApiError
     */
    public getPeopleDuplicates1({
        contactOneId,
        contactTwoId,
    }: {
        contactOneId: number,
        contactTwoId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/duplicates/{contactOneId}/{contactTwoId}',
            path: {
                'contactOneId': contactOneId,
                'contactTwoId': contactTwoId,
            },
        });
    }
    /**
     * Get duplicate candidate details
     * @throws ApiError
     */
    public getPeopleDuplicates2({
        duplicateId,
    }: {
        duplicateId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/duplicates/{duplicateId}',
            path: {
                'duplicateId': duplicateId,
            },
        });
    }
}
