/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostBlogsRequestBody = {
    body?: string | null;
    summary?: string | null;
    facebook?: ({
        publish?: any;
        isScheduled?: any;
    } | {
        publish?: any;
        isScheduled?: any;
        schedulingOptions?: {
            duration: any;
            durationUnit: PostBlogsRequestBody.durationUnit;
            hour: any;
            minute: any;
        };
        message?: string | null;
    }) | null;
    schedulePublish?: (string | string | null);
    /**
     * An alias for a URL, consisting only of English letters, numbers, and dashes, without any spaces or special characters.
     */
    alias?: string | null;
};
export namespace PostBlogsRequestBody {
    export enum durationUnit {
        DAY = 'day',
    }
}

