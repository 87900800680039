import React, { useCallback } from 'react';
import { Form } from 'antd';
import { useEffect } from 'react';
import { each, get, map } from 'lodash';

import { I18nService } from '@/react/services/I18nService';
import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import AssignUserRoles from '@/react/user/components/assign-user-roles/AssignUserRoles';
import cdApp from '@/react/config';
import {
  useGetUserOrganizationRoles,
  useGetUserParishRoles,
  useMutateOrganizationRoles,
  useMutateUserPermissions,
} from '@/react/user/store/users';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';

export const showUserPermissionsModal = createCdModal<{
  userId: number;
  userName: string;
  userEmail: string;
}>(({ setModalProps, userId, userName, userEmail }) => {
  const churches = cdApp.organization.churches;
  const [form] = Form.useForm();

  const { churchSwitches, church, isLoadingUserPermissions } =
    useGetUserParishRoles(userId);
  const { organizationRoles, isLoadingUserOrgRoles } =
    useGetUserOrganizationRoles(userId);
  const showMultiChurch = get(window, 'cdApp.showChurchSelector');
  const { updateUserPermissions } = useMutateUserPermissions();
  const { updateUserOrgRoles } = useMutateOrganizationRoles();
  const saveRoles = useCallback(
    async (values) => {
      const payload = {};
      let organizationRolePayload = [];
      if (showMultiChurch) {
        each(values.church, (churchIds, roleId) => {
          each(churchIds, (churchId) => {
            if (!payload[churchId]) {
              payload[churchId] = {
                churchId,
                roles: [],
              };
            }
            payload[churchId].roles.push({ id: roleId });
          });
        });

        organizationRolePayload = Object.entries(values.organization)
          .filter(([, value]) => value === true)
          .map(([id]) => ({ id }));
      } else {
        const churchId = churches[0].id;
        payload[churchId] = {
          churchId,
          roles: [],
        };
        each(values.churchSwitches, (isEnabled, roleId) => {
          if (isEnabled) payload[churchId].roles.push({ id: roleId });
        });
        organizationRolePayload = Object.entries(values.organization)
          .filter(([, value]) => value === true)
          .map(([id]) => ({ id }));
      }

      const payloadAsArray = map(payload, (p) => p);
      updateUserPermissions({ userId, payloadAsArray });
      updateUserOrgRoles({ userId, payload: organizationRolePayload });
    },
    [
      showMultiChurch,
      updateUserPermissions,
      userId,
      updateUserOrgRoles,
      churches,
    ]
  );
  const saveUserRoles = useCallback(async () => {
    await form.validateFields().then((values) => {
      saveRoles(values);
    });
  }, [saveRoles, form]);

  useEffect(() => {
    setModalProps({
      modalType: ModalType.MODAL,
      title: I18nService.getString('Edit roles of {{userName}}', {
        userName: userName || userEmail,
      }),
      okText: I18nService.getString('Save'),
      onOk: saveUserRoles,
      width: '80vw',
    });
  }, [setModalProps, userId, userName, userEmail, saveUserRoles]);

  return (
    <>
      {isLoadingUserPermissions || isLoadingUserOrgRoles ? (
        <CdrPageLoader />
      ) : (
        <Form
          form={form}
          initialValues={{
            church,
            churchSwitches,
            organization: organizationRoles,
          }}
          layout="vertical"
        >
          <AssignUserRoles userId={userId} />
        </Form>
      )}
    </>
  );
});
