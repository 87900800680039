import React, { useCallback, useEffect, useState } from 'react';
import { Divider, Space, Switch, Typography, Form } from 'antd';
import NiceModal from '@ebay/nice-modal-react';
import 'react-image-crop/dist/ReactCrop.css';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';

import { useOrganization } from '../../organization/hooks/useOrganization';
import { NewsletterandFilterDataInterface } from '../store/newsletterListState';

import NewsletterListForm from './NewsletterListForm';

import useNewsletterList from '@/react/people/hooks/useNewsletterList';
import { ChurchSettingsState } from '@/react/organization/store/church';
import { CdCheckIcon, CdClose } from '@/react/shared/components/Icons';
import { I18nService } from '@/react/services/I18nService';
import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import { UserChurchesWithPermissionQuery } from '@/react/user/store/permissions';

const { Text, Title } = Typography;
const NewsletterSignupWidgetForm = styled(Form)`
  &&&& {
    display: flex;
    flex-direction: column;
  }
`;

export const showCreateNewsletterListModal = createCdModal(
  ({ setModalProps }) => {
    const [form] = Form.useForm();
    const [selectedFilter, setSelectedFilter] =
      useState<NewsletterandFilterDataInterface>();
    const { createList } = useNewsletterList();
    const { updateSettings } = useOrganization();
    const [newsletterSignupWidgetPartForm] = Form.useForm();
    const churchSettingInformation = useRecoilValue(ChurchSettingsState);
    const parishes = useRecoilValue(
      UserChurchesWithPermissionQuery({
        permissionContext: 'people',
        permissionType: 'access',
      })
    );

    useEffect(() => {
      if (churchSettingInformation) {
        newsletterSignupWidgetPartForm.setFieldsValue(churchSettingInformation);
      }

      // eslint-disable-next-line
    }, [churchSettingInformation]);

    const showConfirm = (
      filter: NewsletterandFilterDataInterface,
      name: string,
      setup: { churchIds: number[] },
      filterIds: number[],
      type: string,
      parent: string,
      newsletterWidgetEnabled: boolean
    ) =>
      NiceModal.show('ConfirmRegisterConsentOnContacts', {
        filter,
        name,
        setup,
        filterIds,
        type,
        parent,
        newsletterWidgetEnabled,
      });

    const createNewsletterListHandle = useCallback(
      async () =>
        form.validateFields().then(async (values) => {
          if (values.filterIds) {
            showConfirm(
              selectedFilter,
              values.name,
              values.setup || { churchIds: parishes.map((p) => p.id) },
              values.filterIds,
              'newsletter',
              'createNewsletterModal',
              churchSettingInformation?.newsletterWidgetEnabled === false &&
                newsletterSignupWidgetPartForm.getFieldValue(
                  'newsletterWidgetEnabled'
                )
            );
          } else {
            await createList(
              values.name,
              values.setup || { churchIds: parishes.map((p) => p.id) },
              undefined,
              'newsletter'
            );
            if (!churchSettingInformation?.newsletterWidgetEnabled) {
              await updateSettings({
                newsletterWidgetEnabled:
                  newsletterSignupWidgetPartForm.getFieldValue(
                    'newsletterWidgetEnabled'
                  ),
              });
            }
          }
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        churchSettingInformation?.newsletterWidgetEnabled,
        createList,
        form,
        newsletterSignupWidgetPartForm,
        selectedFilter,
      ]
    );

    useEffect(
      () =>
        setModalProps({
          title: I18nService.getString('Create newsletter list'),
          modalType: ModalType.MODAL,
          width: 500,
          okText: I18nService.getString('Create'),
          onOk: createNewsletterListHandle,
        }),
      [setModalProps, createNewsletterListHandle]
    );

    return (
      <>
        <NewsletterListForm form={form} setSelectedFilter={setSelectedFilter} />
        {!churchSettingInformation?.newsletterWidgetEnabled && (
          <NewsletterSignupWidgetForm
            layout="vertical"
            form={newsletterSignupWidgetPartForm}
          >
            <Divider />
            <Space style={{ display: 'flex', alignContent: 'center' }}>
              <Form.Item
                name="newsletterWidgetEnabled"
                valuePropName="checked"
                initialValue={false}
                noStyle
              >
                <Switch
                  checkedChildren={<CdCheckIcon />}
                  unCheckedChildren={<CdClose />}
                />
              </Form.Item>
              <Title level={4}>
                {I18nService.getString(
                  'Add the newsletter sign-up widget to your website'
                )}
              </Title>
            </Space>

            <Text style={{ color: '#899499' }}>
              {I18nService.getString(
                `If disabled the newsletter widget will not load on your website even when installed`
              )}
            </Text>
          </NewsletterSignupWidgetForm>
        )}
      </>
    );
  }
);
