import produce from 'immer';
import _ from 'lodash';
import { Reducer } from 'redux';

import createReducer, { CdAction } from '../../../redux/utils';
import { Category } from '../../../shared/models/category';
import { Resource } from '../../../shared/models/resource';
import { FETCH_USERS_SUCCESS } from '../../../user/redux/actions';
import { User } from '../../../user/types/User.types';
import { CalendarFilterData, Filters } from '../../models/calendarData';
import { SET_UNSELECTED_CALENDARS } from '../main-view/Actions';

import {
  ADD_CALENDAR,
  ADD_CATEGORY,
  ADD_RESOURCE,
  ADD_USER,
  AddCalendar,
  AddCategory,
  AddResource,
  AddUser,
  CategoryFilterPayload,
  CHANGE_ABSENCE_VIEW,
  changeAbsenceView,
  CLEAR_FILTERS,
  ClearFilters,
  FETCH_CALENDAR_FILTER_DATA_SUCCESS,
  FetchCalendarFilterDataSuccess,
  INITIALIZE_FILTERS_STATE,
  InitializeFiltersState,
  REMOVE_CALENDAR,
  REMOVE_CATEGORY,
  RemoveCalendar,
  RemoveCategory,
  SELECT_ALL_CATEGORIES,
  SELECT_ALL_CATEGORIES_OF_ORGANIZATION,
  SelectAllCategories,
  SelectAllCategoriesOfOrganization,
  TOGGLE_ABSENCE_VIEW,
  TOGGLE_ALL_MY_CALENDARS,
  TOGGLE_ALL_PARISHES,
  TOGGLE_CALENDAR,
  TOGGLE_CALENDAR_SUBSCRIPTION,
  TOGGLE_CATEGORY,
  TOGGLE_ME_FILTER,
  TOGGLE_USERS_AND_ROOMS_CALENDERS,
  ToggleAllMyCalendars,
  ToggleAllParishes,
  ToggleCalendar,
  ToggleCalendarSubscription,
  ToggleMeFilter,
  UNSELECT_ALL_CATEGORIES,
  UPDATE_CATEGORY_CALENDAR_FILTERS_REQUEST,
  UpdateCategoryCalendarFiltersRequest,
  userFiltersLoaded,
} from './Actions';

import { MyCalendarSelectionState } from '@/react/calendar/store/filters/types';

export enum FilterType {
  users = 'Users',
  resources = 'Resources',
}

export enum AbsenceView {
  fullView = 'fullView',
  minimized = 'minimized',
}

export interface SelectedCalendar {
  id: number;
  filterType: FilterType;
  churchName?: string;
  name?: string;
  image?: string;
  color?: string;
  email?: string;
}

export interface OldChurchCategory {
  churchId: number;
  isAllSelected: boolean | null;
  categoryIds: number[];
}

export interface ChurchCategories {
  [churchId: number]: {
    [categoryId: number]: boolean;
  };
}

export interface FilterState {
  showMyCalendar: boolean;
  selectedCalendars: SelectedCalendar[];
  selectedResources: { [resourceId: number]: boolean };
  selectedUsers: { [userId: number]: boolean };
  selectedChurchCategories: ChurchCategories;
  calendarData: Filters;
  filterData: CalendarFilterData;
  selectedChurches: Resource[];
  absenceView: AbsenceView;
  showAbsences: boolean;
  unselectedCalendars: SelectedCalendar[];
  calendarSubscriptions: { [index: string]: boolean };
  isFilterDataLoaded: boolean;
  isUserFiltersLoaded: boolean;
  isInitializeFirstTime: boolean;
}

export type ImmutableFilterState = FilterState;

const initialState: FilterState = {
  showMyCalendar: true,
  selectedCalendars: [],
  selectedResources: {},
  selectedUsers: {},
  selectedChurchCategories: {},
  calendarData: {
    resources: {},
    categories: {},
    users: {},
    groups: {},
  },
  filterData: {
    categories: [] as Category[],
    parentResources: [] as Resource[],
    resources: [] as Resource[],
    users: [] as unknown as User[],
  },
  selectedChurches: [],
  absenceView: AbsenceView.fullView,
  showAbsences: true,
  unselectedCalendars: [],
  calendarSubscriptions: {},
  isFilterDataLoaded: false,
  isUserFiltersLoaded: false,
  isInitializeFirstTime: false,
};

const fetchCalendarFilterDataSuccess: Reducer<
  ImmutableFilterState,
  FetchCalendarFilterDataSuccess
> = produce((draft, { payload }) => {
  const { categories, parentResources, resources, users } = payload;
  draft.filterData = { categories, parentResources, resources, users };
  draft.calendarData = payload.calendarData;
  draft.isFilterDataLoaded = true;
});
const addCalendar: Reducer<ImmutableFilterState, AddCalendar> = produce(
  (state, { payload }) => {
    if (!payload.id || !payload.filterType) return state;
    switch (payload.filterType) {
      case FilterType.resources:
        state.selectedResources[payload.id] = true;
        break;
      case FilterType.users:
        state.selectedUsers[payload.id] = true;
        break;
      default:
        break;
    }
    if (
      state.selectedCalendars.some(
        (item) =>
          item.id === payload.id && item.filterType === payload.filterType
      )
    ) {
      return;
    }
    state.selectedCalendars.push({
      id: payload.id,
      filterType: payload.filterType,
    });
  }
);

const removeCalendar: Reducer<ImmutableFilterState, RemoveCalendar> = produce(
  (state = initialState, { payload }) => {
    switch (payload.filterType) {
      case FilterType.resources:
        delete state.selectedResources[payload.id];
        break;
      case FilterType.users:
        delete state.selectedUsers[payload.id];
        break;
      default:
        break;
    }
    state.selectedCalendars = state.selectedCalendars.filter(
      (item) =>
        !(item.id === payload.id && item.filterType === payload.filterType)
    );
  }
);

const toggleMeCalendar: Reducer<ImmutableFilterState, ToggleMeFilter> = produce(
  (state) => {
    state.showMyCalendar = !state.showMyCalendar;
  }
);

const toggleCalendar: Reducer<ImmutableFilterState, ToggleCalendar> = produce(
  (state, { payload }) => {
    switch (payload.filterType) {
      case FilterType.resources:
        state.selectedResources[payload.id] =
          !state.selectedResources[payload.id];
        break;
      case FilterType.users:
        state.selectedUsers[payload.id] = !state.selectedUsers[payload.id];
        break;
    }
  }
);

const clearFilters: Reducer<ImmutableFilterState, ClearFilters> = produce(
  (state) => {
    state.selectedCalendars = [];
  }
);

const addCategory: Reducer<ImmutableFilterState, AddCategory> = produce(
  (state, { payload }) => {
    const { categoryId, churchId } = payload;
    // For the first time selecting a church and updating the categories, initialize the churchId contents to empty object
    if (_.isNil(_.get(state.selectedChurchCategories, churchId))) {
      _.set(state.selectedChurchCategories, churchId, {});
    }
    _.set(state.selectedChurchCategories, [churchId, categoryId], true);
  }
);

const updateCalendarFilters: Reducer<
  ImmutableFilterState,
  UpdateCategoryCalendarFiltersRequest
> = (state, { payload }) => {
  const churchIds = payload.churchIds;
  const categoryId: number = _.get(payload, 'category.id');
  let newState = state;
  _.forEach(churchIds, (churchId) => {
    newState = addCategory(
      newState,
      AddCategory({ churchId: churchId, categoryId: categoryId })
    );
  });
  return newState;
};

const removeCategory: Reducer<ImmutableFilterState, RemoveCategory> = produce(
  (state, { payload }) => {
    const { categoryId, churchId } = payload;
    _.set(state.selectedChurchCategories, [churchId, categoryId], false);
  }
);

const selectAllCategories: Reducer<ImmutableFilterState, SelectAllCategories> =
  produce((state, { payload }) => {
    const { churchId, lowercaseSearchTerm } = payload;
    // For the first time selecting the church, initialize the churchId contents to empty object.
    if (_.isNil(_.get(state.selectedChurchCategories, churchId))) {
      _.set(state.selectedChurchCategories, churchId, {});
    }
    _.forEach(_.get(state, 'filterData.categories', []), (category) => {
      if (
        !lowercaseSearchTerm ||
        category.name.toLowerCase().includes(lowercaseSearchTerm)
      ) {
        _.set(state.selectedChurchCategories, [churchId, category.id], true);
      }
    });
  });

const unselectAllCategories: Reducer<
  ImmutableFilterState,
  SelectAllCategories
> = produce((state, { payload }) => {
  const { churchId, lowercaseSearchTerm } = payload;
  _.forEach(_.get(state, 'filterData.categories', []), (category) => {
    if (
      !lowercaseSearchTerm ||
      category.name.toLowerCase().includes(lowercaseSearchTerm)
    ) {
      _.set(state.selectedChurchCategories, [churchId, category.id], false);
    }
  });
});

const addUser: Reducer<ImmutableFilterState, AddUser> = (
  state,
  { payload }
) => {
  const { userId } = payload;
  const selectedCalendar = _.find(
    state.selectedCalendars,
    (selectedCalendar) => selectedCalendar.id === userId
  );
  // Check if the user calendar is among the selected ones
  if (selectedCalendar) {
    // I just need to call the reducer directly,that's why I don't care about the type string and this will be refactored to redux-tool-kit
    return toggleCalendar(state, {
      type: '',
      payload: { id: userId, filterType: FilterType.users },
    });
  } else {
    const users = _.get(state, 'filterData.users', []);
    const selectedUser = users.find((r) => r.id === userId);
    if (!selectedUser) return state;
    // I just need to call the reducer directly,that's why I don't care about the type string and this will be refactored to redux-tool-kit
    return addCalendar(state, {
      type: '',
      payload: selectedUser.merge({ filterType: FilterType.users }),
    });
  }
};

const addResource: Reducer<ImmutableFilterState, AddResource> = (
  state,
  { payload }
) => {
  const { resourceId } = payload;
  const selectedCalendar = _.find(
    state.selectedCalendars,
    (selectedCalendar) => selectedCalendar.id === resourceId
  );
  // Check if the resource calendar is among the selected ones
  if (selectedCalendar) {
    return toggleCalendar(state, {
      type: '',
      payload: { id: resourceId, filterType: FilterType.resources },
    });
  } else {
    const resources = _.get(state, 'filterData.resources', []);
    const selectedResource = resources.find((r) => r.id === resourceId);
    if (!selectedResource) return state;
    return addCalendar(state, {
      type: '',
      payload: { id: selectedResource.id, filterType: FilterType.resources },
    });
  }
};

const initState: Reducer<ImmutableFilterState, InitializeFiltersState> =
  produce((state, { payload }) => {
    _.map(payload, (value, key) => {
      state[key] = value;
    });
  });

const absenceView: Reducer<ImmutableFilterState, changeAbsenceView> = produce(
  (state, { payload }) => {
    state.absenceView = payload;
  }
);

const toggleAbsenceView: Reducer<ImmutableFilterState, changeAbsenceView> =
  produce((state) => {
    state.showAbsences = !state.showAbsences;
  });

const setUnselectedCalendars: Reducer<
  ImmutableFilterState,
  CdAction<SelectedCalendar[]>
> = produce((state, { payload }) => {
  state.unselectedCalendars = payload;
});

const toggleCalendarSubscription: Reducer<
  ImmutableFilterState,
  ToggleCalendarSubscription
> = produce((state, { payload }) => {
  state.calendarSubscriptions[payload.id] = !payload.isChecked;
});

const selectAllCategoriesOfOrganization: Reducer<
  ImmutableFilterState,
  SelectAllCategoriesOfOrganization
> = produce((state, { payload }) => {
  state.selectedChurchCategories = payload;
});

const userFiltersLoadedReducer: Reducer<ImmutableFilterState, undefined> =
  produce((state) => {
    state.isUserFiltersLoaded = true;
  });

const toggleAllMyCalendars: Reducer<
  ImmutableFilterState,
  ToggleAllMyCalendars
> = produce((state, { payload }) => {
  const { shared, me } = payload as MyCalendarSelectionState;
  state.showMyCalendar = me;
  _.forEach(shared, (s) => {
    state.calendarSubscriptions[s.calendar.id] = s.isSelected;
  });
});

const toggleUsersAndRoomsCalenders: Reducer<
  ImmutableFilterState,
  CdAction<{
    calendars?: SelectedCalendar[];
    shouldSelect: boolean;
  }>
> = produce((state, { payload }) => {
  const { calendars, shouldSelect } = payload as {
    calendars?: SelectedCalendar[];
    shouldSelect: boolean;
  };
  if (!calendars) {
    if (!shouldSelect) {
      state.selectedResources = {};
      state.selectedUsers = {};
    } else {
      _.forEach(state.selectedCalendars, (calendar) => {
        if (calendar.filterType === FilterType.resources) {
          state.selectedResources[calendar.id] = true;
        } else {
          state.selectedUsers[calendar.id] = true;
        }
      });
    }
    return;
  } else {
    calendars.forEach((calendar) => {
      if (shouldSelect) {
        if (calendar.filterType === FilterType.resources) {
          state.selectedResources[calendar.id] = true;
        }
        if (calendar.filterType === FilterType.users) {
          state.selectedUsers[calendar.id] = true;
        }
        if (
          !state.selectedCalendars.some(
            (item) =>
              item.id === calendar.id && item.filterType === calendar.filterType
          )
        ) {
          state.selectedCalendars.push({
            id: calendar.id,
            filterType: calendar.filterType,
          });
        }
      } else {
        if (calendar.filterType === FilterType.resources) {
          state.selectedResources[calendar.id] = false;
        }
        if (calendar.filterType === FilterType.users) {
          state.selectedUsers[calendar.id] = false;
        }
      }
    });
  }
});

const toggleAllParishes: Reducer<ImmutableFilterState, ToggleAllParishes> =
  produce((state, { payload }) => {
    const { isActive, churches, categories } = payload;
    if (isActive) {
      state.selectedChurchCategories = {};
    } else {
      state.selectedChurchCategories = {};
      _.forEach(churches, (church) => {
        state.selectedChurchCategories[church.id] = {};
        _.forEach(categories, (category) => {
          _.set(state.selectedChurchCategories, [church.id, category.id], true);
        });
      });
    }
    state.isInitializeFirstTime = true;
  });

const updateFiltersAfterUsersUpdated: Reducer<
  ImmutableFilterState,
  CdAction<User[]>
> = produce((state, { payload }) => {
  const blockedUsers = payload.filter((user) => user.status === 0);
  state.selectedCalendars = state.selectedCalendars.filter(
    (calendar) =>
      calendar.filterType === FilterType.resources ||
      (calendar.filterType === FilterType.users &&
        !blockedUsers.some((user) => user.id === calendar.id))
  );
  state.selectedUsers = _.pickBy(
    state.selectedUsers,
    (_value, key) => !blockedUsers.some((user) => user.id === _.parseInt(key))
  );
});

const toggleCategory: Reducer<
  ImmutableFilterState,
  CdAction<CategoryFilterPayload>
> = produce((state, { payload }) => {
  const { churchId, categoryId } = payload;
  const isSelected = _.get(
    state,
    `selectedChurchCategories[${churchId}][${categoryId}]`,
    false
  );
  _.set(
    state,
    `selectedChurchCategories[${churchId}][${categoryId}]`,
    !isSelected
  );
});

const handlers = {
  [FETCH_CALENDAR_FILTER_DATA_SUCCESS]: fetchCalendarFilterDataSuccess,
  [ADD_CALENDAR]: addCalendar,
  [REMOVE_CALENDAR]: removeCalendar,
  [TOGGLE_ME_FILTER]: toggleMeCalendar,
  [TOGGLE_CALENDAR]: toggleCalendar,
  [CLEAR_FILTERS]: clearFilters,
  [ADD_CATEGORY]: addCategory,
  [REMOVE_CATEGORY]: removeCategory,
  [SELECT_ALL_CATEGORIES]: selectAllCategories,
  [UNSELECT_ALL_CATEGORIES]: unselectAllCategories,
  [UPDATE_CATEGORY_CALENDAR_FILTERS_REQUEST]: updateCalendarFilters,
  [ADD_USER]: addUser,
  [ADD_RESOURCE]: addResource,
  [INITIALIZE_FILTERS_STATE]: initState,
  [CHANGE_ABSENCE_VIEW]: absenceView,
  [TOGGLE_ABSENCE_VIEW]: toggleAbsenceView,
  [SET_UNSELECTED_CALENDARS]: setUnselectedCalendars,
  [TOGGLE_CALENDAR_SUBSCRIPTION]: toggleCalendarSubscription,
  [SELECT_ALL_CATEGORIES_OF_ORGANIZATION]: selectAllCategoriesOfOrganization,
  [userFiltersLoaded.type]: userFiltersLoadedReducer,
  [TOGGLE_ALL_MY_CALENDARS]: toggleAllMyCalendars,
  [TOGGLE_USERS_AND_ROOMS_CALENDERS]: toggleUsersAndRoomsCalenders,
  [TOGGLE_ALL_PARISHES]: toggleAllParishes,
  [FETCH_USERS_SUCCESS]: updateFiltersAfterUsersUpdated,
  [TOGGLE_CATEGORY]: toggleCategory,
};

export default createReducer<ImmutableFilterState>(initialState, handlers);
