import React from 'react';
import { Alert, Form, Spin, Typography } from 'antd';
import { isUndefined } from 'lodash';

import { useDeleteCategories } from '../../hooks/useCategories';
import { CdVerticalSpace } from '../../../shared/components/cd-vertical-space/CdVerticalSpace';
import { CategoryType } from '../../../shared/models/category';

import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import { I18nService } from '@/react/services/I18nService';
import { CdCheckbox } from '@/react/shared/components/cd-checkbox/CdCheckbox';
import { CdSelect } from '@/react/shared/components/cd-select/CdSelect';
import { CdWarningIcon } from '@/react/shared/components/Icons';

interface DeleteCategoryModalProps {
  type: string;
  taxonomyId?: number;
}
export const DeleteCategoryModal = createCdModal<DeleteCategoryModalProps>(
  ({ setModalProps, type, taxonomyId }) => {
    const {
      form,
      initialValues,
      isLoading,
      shouldTransfer,
      options,
      taxonomyName,
      bookingPagesThatUseTaxonomy,
    } = useDeleteCategories({
      setModalProps,
      type,
      taxonomyId,
    });

    return (
      <Spin spinning={isLoading} style={{ overflowX: 'visible' }}>
        <Form layout="vertical" form={form} initialValues={initialValues}>
          <Typography.Text>
            {I18nService.getString(
              'Are you sure you want to delete "{{name}}"? This action cannot be undone.',
              {
                name: taxonomyName,
              }
            )}
          </Typography.Text>
          {options?.length > 0 && (
            <Form.Item
              name="shouldTransfer"
              valuePropName="checked"
              style={{ marginTop: '16px' }}
            >
              <CdCheckbox>{getTransferMessage(type)}</CdCheckbox>
            </Form.Item>
          )}
          {shouldTransfer && options?.length ? (
            <Form.Item name="replacementId">
              <CdSelect
                placeholder={I18nService.getString('Choose a category')}
                options={options}
              />
            </Form.Item>
          ) : (
            !isLoading &&
            !isUndefined(shouldTransfer) && (
              <CdVerticalSpace>
                <Alert
                  message={getWarningMessage(type, taxonomyName)}
                  type="warning"
                  icon={
                    <CdWarningIcon type="warning" style={{ marginRight: 8 }} />
                  }
                  showIcon
                />
                {bookingPagesThatUseTaxonomy?.length > 0 && (
                  <Alert
                    message={
                      <Typography.Text>
                        {getWarningMessage(
                          CategoryType.availability,
                          taxonomyName
                        )}
                        <ul style={{ margin: 0 }}>
                          {bookingPagesThatUseTaxonomy.map((page) => (
                            <li key={page.id}>{page.name}</li>
                          ))}
                        </ul>
                      </Typography.Text>
                    }
                    type="warning"
                    icon={
                      <CdWarningIcon
                        type="warning"
                        style={{ marginRight: 8 }}
                      />
                    }
                    showIcon
                  />
                )}
              </CdVerticalSpace>
            )
          )}
        </Form>
      </Spin>
    );
  },
  {
    width: 500,
    modalType: ModalType.MODAL,
    okButtonProps: { danger: true },
  }
);

const getTransferMessage = (type: string) => {
  switch (type) {
    case 'event':
      return I18nService.getString('Transfer all events to another category');
    case 'blog':
      return I18nService.getString('Transfer all blogs to another category');
    case 'absence':
      return I18nService.getString('Transfer all absences to another category');
  }
};
const getWarningMessage = (type: string, name: string) => {
  switch (type) {
    case 'event':
      return I18nService.getString(
        'Warning! All events belonging to {{name}} will be lost.',
        { name }
      );
    case 'blog':
      return I18nService.getString(
        'Warning! All blogs belonging to {{name}} will be lost.',
        { name }
      );
    case 'absence':
      return I18nService.getString(
        'Warning! All absences belonging to {{name}} will be lost.',
        { name }
      );
    case 'availability':
      return I18nService.getString(
        'Warning! All booking pages belonging to {{name}} will be lost.',
        { name }
      );
  }
};
