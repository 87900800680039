/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostWidgetsMigrationRequestBody = {
    organizationId: number;
    title?: string;
    type: PostWidgetsMigrationRequestBody.type;
    id?: number;
};
export namespace PostWidgetsMigrationRequestBody {
    export enum type {
        BLOG = 'blog',
        EVENT = 'event',
        SLIDESHOW = 'slideshow',
        DONATION = 'donation',
    }
}

