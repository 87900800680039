import { Form } from 'antd';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { FetchUsers } from '../redux/actions';

import { handleAntFormValidationErrors } from '@/react/services/ErrorHandlingService';
import { useOrganization } from '@/react/organization/hooks/useOrganization';
import { useMutateTimeRegistration } from '@/react/user/store/users';

export const useTimeBalance = ({
  currentUserId,
  timeRegistrationEnabled,
  initialTimeBalance,
}: {
  currentUserId: number;
  timeRegistrationEnabled: boolean;
  initialTimeBalance: number;
}) => {
  const [isValueChanged, setIsValueChanged] = useState<boolean>(false);
  const { organizationSettings, refreshOrganizationSettings } =
    useOrganization();
  const [timeBalanceForm] = Form.useForm();
  const timeBalanceFormInitialValue = {
    timeRegistrationEnabled,
    initialTimeBalance,
  };

  const dispatch = useDispatch();
  const { updateTimeBalance } = useMutateTimeRegistration();
  const onOk = useCallback(async () => {
    const values = await timeBalanceForm.validateFields().catch((err) => {
      handleAntFormValidationErrors(err);
    });
    if (!values) {
      return;
    } else {
      updateTimeBalance({
        currentUserId,
        timeRegistrationEnabled: values.timeRegistrationEnabled,
        initialTimeBalance: values.initialTimeBalance,
      });
      refreshOrganizationSettings();
      dispatch(FetchUsers());
    }
  }, [
    timeBalanceForm,
    updateTimeBalance,
    currentUserId,
    refreshOrganizationSettings,
    dispatch,
  ]);

  const formValueChanged = (_, allValues) => {
    if (
      allValues.timeRegistrationEnabled &&
      allValues.initialTimeBalance === undefined
    ) {
      setIsValueChanged(false);
    } else {
      setIsValueChanged(true);
    }
  };
  return {
    onOk,
    formValueChanged,
    organizationSettings,
    isValueChanged,
    timeBalanceForm,
    timeBalanceFormInitialValue,
  };
};
