import { Rule } from 'antd/lib/form';
import { NamePath } from 'antd/lib/form/interface';
import React, { useContext } from 'react';
import { isArray } from 'lodash';

import {
  AccessErrorMessage,
  FormItem,
  ProfileAccessContext,
} from '../people-contacts-profile-modals/EditPersonalInformationForm';

import { I18nService } from '@/react/services/I18nService';

export const labels = {
  email: I18nService.getString('Email'),
  phone: I18nService.getString('Mobile phone'),
  homePhone: I18nService.getString('Home phone'),
  workPhone: I18nService.getString('Work phone'),
  street: I18nService.getString('Street address'),
  city: I18nService.getString('City'),
  zipcode: I18nService.getString('Postal code'),
  poBoxNumber: I18nService.getString('PO. Box number'),
  poBoxPostCode: I18nService.getString('PO. Box postal code'),
  country: I18nService.getString('Country'),
  birthday: I18nService.getString('Birthday'),
  baptismDate: I18nService.getString('Baptism'),
  gender: I18nService.getString('Gender'),
  occupation: I18nService.getString('Occupation'),
  note: I18nService.getString('Internal note'),
  civilStatus: I18nService.getString('Civil status'),
  nationalIdentificationNumber: I18nService.getString(
    'National identification number'
  ),
  allergy: I18nService.getString('Allergies and intolerances'),
  dietary: I18nService.getString('Diet'),
  medicalNote: I18nService.getString('Medical note'),
  confirmationDate: I18nService.getString('Confirmation'),
  civilMarriageDate: I18nService.getString('Wedding'),
  membershipDate: I18nService.getString('Date of parish membership'),
  denomination: I18nService.getString('Denomination'),
  dateOfDeath: I18nService.getString('Date of death'),
};

export const FormItemWithAccess = (props: {
  name: string | string[];
  label?: string | React.ReactNode;
  extra?: string | React.ReactNode;
  fieldAccessName: string;
  trigger?: string;
  children?: any;
  rules?: Rule[];
  dependencies?: NamePath[];
  style?: React.CSSProperties;
  noErrorMessage?: boolean;
  noStyle?: boolean;
  help?: string;
  valuePropName?: string;
  offset?: { top: string; bottom?: string; right: string; left?: string };
}) => {
  const { profileAccess } = useContext(ProfileAccessContext);

  if (isArray(props.name) && props.fieldAccessName?.length === 0) {
    throw new Error('name is an array. Use fieldAccessName instead');
  }

  const renderChildren = () => {
    if (!isArray(props.children)) {
      return React.cloneElement(props.children);
    } else {
      // eslint-disable-next-line no-console
      console.log(
        'FormItemWithAccess supports only one child due to Form.Item only supporting one child'
      );
      return <>Error</>;
    }
  };

  return profileAccess?.[props.fieldAccessName]?.canEdit ? (
    <FormItem
      name={props.name}
      label={props.label}
      trigger={props.trigger || 'onChange'}
      rules={props.rules}
      noStyle={props.noStyle}
      help={props.help}
      valuePropName={props.valuePropName || 'value'}
      style={props.style}
      dependencies={props.dependencies}
      extra={props.extra}
    >
      {renderChildren()}
    </FormItem>
  ) : (
    <AccessErrorMessage label={labels[props.fieldAccessName]} />
  );
};
