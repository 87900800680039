/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeleteStripeSubscriptionsIdRequestBody } from '../models/DeleteStripeSubscriptionsIdRequestBody';
import type { EmptyObject } from '../models/EmptyObject';
import type { GetStripeInvoicesListCustomeridResponseBody } from '../models/GetStripeInvoicesListCustomeridResponseBody';
import type { PutStripeCustomerRequestBody } from '../models/PutStripeCustomerRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class StripeService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetStripeCustomer
     * Customer Retrieve
     * @returns EmptyObject
     * @throws ApiError
     */
    public getStripeCustomer(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stripe/customer',
        });
    }
    /**
     * PutStripeCustomer
     * Customer Retrieve
     * @returns EmptyObject
     * @throws ApiError
     */
    public putStripeCustomer({
        requestBody,
    }: {
        requestBody?: PutStripeCustomerRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/stripe/customer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetStripeInvoicesListCustomerid
     * Invoice Index
     * @returns GetStripeInvoicesListCustomeridResponseBody Success-Response
     * @throws ApiError
     */
    public getStripeInvoicesList({
        customerId,
        limit = 30,
        startingAfter,
    }: {
        customerId: string,
        limit?: number,
        startingAfter?: string,
    }): CancelablePromise<GetStripeInvoicesListCustomeridResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stripe/invoices/list/{customerId}',
            path: {
                'customerId': customerId,
            },
            query: {
                'limit': limit,
                'startingAfter': startingAfter,
            },
        });
    }
    /**
     * GetStripeMandatesBacs_debitReferenceid
     * View Mandate
     * @returns EmptyObject
     * @throws ApiError
     */
    public getStripeMandatesBacsDebit({
        referenceId,
    }: {
        referenceId: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stripe/mandates/bacs_debit/{referenceId}',
            path: {
                'referenceId': referenceId,
            },
        });
    }
    /**
     * GetStripeProducts
     * Products Index
     * @returns EmptyObject
     * @throws ApiError
     */
    public getStripeProducts(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stripe/products',
        });
    }
    /**
     * DeleteStripeSources
     * Source Delete
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteStripeSources({
        sourceId,
        type,
    }: {
        sourceId: string,
        type: 'card' | 'sepa_debit' | 'bacs_debit',
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/stripe/sources',
            query: {
                'sourceId': sourceId,
                'type': type,
            },
        });
    }
    /**
     * PostStripeSubscriptions
     * Subscription Create
     * @returns EmptyObject
     * @throws ApiError
     */
    public postStripeSubscriptions({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/stripe/subscriptions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeleteStripeSubscriptionsId
     * Subscription Cancel
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteStripeSubscriptions({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/stripe/subscriptions/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * PutStripeSubscriptionsId
     * Subscriptions Update
     * @returns EmptyObject
     * @throws ApiError
     */
    public putStripeSubscriptions({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: DeleteStripeSubscriptionsIdRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/stripe/subscriptions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * PostStripeWebhook
     * Webhook
     * @returns EmptyObject
     * @throws ApiError
     */
    public postStripeWebhook({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/stripe/webhook',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
