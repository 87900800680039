/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostPContributionsPosterRequestBody = {
    settings: {
        backgroundColor: string;
        fontColor: string;
        actionColor: string;
    };
    size: PostPContributionsPosterRequestBody.size;
    imageId: string;
};
export namespace PostPContributionsPosterRequestBody {
    export enum size {
        A4 = 'A4',
        A3 = 'A3',
        A2 = 'A2',
        A1 = 'A1',
        _30CM_40CM = '30cm 40cm',
        _50CM_70CM = '50cm 70cm',
    }
}

