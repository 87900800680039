import {
  Button,
  Drawer,
  Layout,
  Checkbox,
  Space,
  Typography,
  Alert,
} from 'antd';
import React, { useState } from 'react';
import { find } from 'lodash';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import styled from 'styled-components';
import NiceModal, { antdDrawerV5, useModal } from '@ebay/nice-modal-react';

import { OrderOfServiceTemplatesQuery } from '@/react/calendar/store/order-of-service/orderOfService';
import { I18nService } from '@/react/services/I18nService';
import { useTemplates } from '@/react/calendar/hooks/order-of-service/useTemplates';
import AuthorizationService from '@/react/services/AuthorizationService';
import {
  OrderOfServiceAdditionalEventDataOptions,
  OrderOfServiceSearchParams,
} from '@/react/calendar/types/orderOfServiceTypes';
import { HasMultipleChurches } from '@/react/user/store/user-session';
import {
  ISelectableCardItem,
  SelectableCards,
} from '@/react/shared/components/SelectableCards';
import CdrEmptyState from '@/react/shared/components/cd-result/CdrEmptyState';

const { Sider, Content } = Layout;

const StyledDrawer = styled(Drawer)`
  &&&& {
    .ant-drawer-body {
      padding: 0px;
      display: flex;

      .ant-layout {
        .ant-layout-sider {
          padding: 8px 24px;
        }
        .ant-layout-content {
          padding: 8px;
          overflow: auto;
        }
      }
    }
  }
`;

export const OrderOfServiceTemplateSelector = NiceModal.create(
  (orderOfServiceSearchParams: OrderOfServiceSearchParams) => {
    const hasMultiChurch = useRecoilValue(HasMultipleChurches);
    const templatesLoadable = useRecoilValueLoadable(
      OrderOfServiceTemplatesQuery
    );
    const [selectedTemplateId, setSelectedTemplateId] = useState<string>(null);
    const [additionalEventDataToInclude, setAdditionalEventDataToInclude] =
      useState<OrderOfServiceAdditionalEventDataOptions[]>([]);
    const { generateOrderOfServiceReport } = useTemplates();
    const modal = useModal('OrderOfServiceTemplateSelector');
    const templatesLoading = templatesLoadable.state === 'loading';
    const templates =
      templatesLoadable.state === 'hasValue' ? templatesLoadable.contents : [];
    const hasStudio = AuthorizationService.hasPackage('studio');

    const submitOrderOfService = () => {
      if (!selectedTemplateId) return;
      const selectedTemplate = find(templates, { id: selectedTemplateId });
      if (!selectedTemplate) return;
      generateOrderOfServiceReport(
        {
          templateId: selectedTemplateId,
          orderBy: selectedTemplate.orderBy,
          groupBy: selectedTemplate.groupBy,
          additionalEventDataToInclude,
          ...orderOfServiceSearchParams,
        },
        modal.hide
      );
    };

    const getItemAlertNoStudio = () => (
      <Alert
        message={I18nService.getString(
          'You must have the Studio package in your plan to use this template'
        )}
        type="warning"
      />
    );

    // The template is available if it does not require the studio package or if the user has the studio package
    const isTemplateAvailableForUser = (item) =>
      !item.requiresStudioPackage || hasStudio;

    const optionalInformation = [
      {
        name: I18nService.getString('Contributor'),
        value: OrderOfServiceAdditionalEventDataOptions.CONTRIBUTOR,
        visible: true,
      },
      {
        name: I18nService.getString('Priest (Assigned from Rota)'),
        value: OrderOfServiceAdditionalEventDataOptions.PRIESTS,
        visible: true,
      },
      {
        name: I18nService.getString('Location name'),
        value: OrderOfServiceAdditionalEventDataOptions.LOCATION_NAME,
        visible: true,
      },
      {
        name: I18nService.getString('Price'),
        value: OrderOfServiceAdditionalEventDataOptions.PRICE,
        visible: true,
      },
      {
        name: I18nService.getString('Short description'),
        value: OrderOfServiceAdditionalEventDataOptions.SUMMARY,
        visible: true,
      },
    ];

    if (hasMultiChurch) {
      optionalInformation.unshift({
        name: I18nService.getString('Parish'),
        value: OrderOfServiceAdditionalEventDataOptions.CHURCH,
        visible: true,
      });
    }

    const hasIntentionAccess = AuthorizationService.hasPackage('intentions');
    if (hasIntentionAccess) {
      optionalInformation.push({
        name: I18nService.getString('Intentionen'),
        value: OrderOfServiceAdditionalEventDataOptions.INTENTIONS,
        visible: true,
      });
    }

    return (
      <StyledDrawer
        {...antdDrawerV5(modal)}
        title={I18nService.getString('Worship overview - Choose template')}
        onClose={() => modal.hide()}
        footer={
          <Space>
            <Button onClick={() => modal.hide()}>
              {I18nService.getString('Cancel')}
            </Button>
            <Button
              onClick={submitOrderOfService}
              type="primary"
              disabled={!selectedTemplateId}
            >
              {I18nService.getString('Confirm')}
            </Button>
          </Space>
        }
        footerStyle={{ display: 'flex', justifyContent: 'end' }}
        width="75vw"
      >
        <Layout>
          <Sider theme="light">
            <Typography.Title level={4} style={{ marginTop: 0 }}>
              {I18nService.getString('Include this information')}
            </Typography.Title>
            <Checkbox.Group
              onChange={(selectedOptions) =>
                setAdditionalEventDataToInclude(
                  selectedOptions as OrderOfServiceAdditionalEventDataOptions[]
                )
              }
            >
              <Space direction="vertical">
                {optionalInformation?.map(
                  (optionalItem, index) =>
                    optionalItem.visible && (
                      <Checkbox
                        key={index}
                        value={optionalItem.value}
                        style={{ fontWeight: 'normal' }}
                      >
                        {optionalItem.name}
                      </Checkbox>
                    )
                )}
              </Space>
            </Checkbox.Group>
          </Sider>
          <Content style={{ height: '100%' }}>
            <SelectableCards
              loading={templatesLoading}
              dataSource={templates.map((template) => {
                const isSelectable = isTemplateAvailableForUser(template);
                return {
                  ...template,
                  isSelectable,
                  description: isSelectable
                    ? template.description
                    : getItemAlertNoStudio(),
                } as ISelectableCardItem;
              })}
              onSelect={setSelectedTemplateId}
              value={selectedTemplateId}
              emptyText={
                <CdrEmptyState
                  title={I18nService.getString('No templates found')}
                />
              }
            />
          </Content>
        </Layout>
      </StyledDrawer>
    );
  }
);
