import { AnyAction, Reducer } from 'redux';
import * as immutable from 'seamless-immutable';

import { CalendarSubscription, FBaseEvent } from '../../models/calendar';
import createReducer, { Immutable } from '../../../redux/utils';
import { Holiday } from '../../sagas/CalendarControlSagas';
import {
  ToggleAllMyCalendars,
  TOGGLE_ALL_MY_CALENDARS,
} from '../filters/Actions';

import {
  CHANGE_RESOURCE_TYPE,
  CHANGE_TIME_PERIOD,
  ChangeResourceTypeActionType,
  ChangeTimePeriodActionType,
  FETCH_CALENDAR_SUBSCRIPTIONS_SUCCESS,
  FetchCalendarSubscriptionSuccess,
  GO_TO_DATE,
  GoToDateActionType,
  INITIALIZE_VIEW_STATE,
  InitializeViewState,
  LOAD_NATIONAL_HOLIDAYS_SUCCESS_TYPE,
  LOAD_LITURGICAL_HOLIDAYS_SUCCESS_TYPE,
  LoadNationalHolidaysSuccess,
  LoadLiturgicalHolidaysSuccess,
  SET_CALENDAR_EVENT_COLORING_CRITERIA,
  SetCalendarEventColoringCriteria,
  TOGGLE_SHOW_NATIONAL_HOLIDAYS,
  TOGGLE_SHOW_LITURGICAL_HOLIDAYS,
  TOGGLE_SHOW_INTENTIONS,
  TOGGLE_SHOW_PREP_TIME,
  TOGGLE_SHOW_RESOURCES,
  ToggleShowNationalHolidays,
  ToggleShowLiturgicalHolidays,
  TOGGLE_RESOURCE_VIEW,
  TOGGLE_SHOW_COLLECTIONS,
  ToggleCollections,
  TOGGLE_SHOW_DECLINED_EVENTS,
} from './Actions';

export enum TimePeriod {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
}

export enum ResourceType {
  Calendar = 'Calendar',
  Resources = 'Resources',
  Users = 'Users',
}

export enum CalendarEventColoringCriteria {
  Category = 'Category',
  Resource = 'Resource',
}

export interface CalendarViewState {
  title?: string;
  absences: {
    absences: FBaseEvent[];
    type: string;
    start: Date;
    allDay: boolean;
  }[];
  nationalHolidays: Holiday[];
  liturgicalHolidays: Holiday[];
  timePeriod: TimePeriod;
  resourceType: ResourceType;
  showNationalHolidays: boolean;
  showLiturgicalHolidays: boolean;
  eventColoringCriteria: CalendarEventColoringCriteria;
  selectedDate: Date;
  showResources: boolean;
  showPrepTime: boolean;
  showIntentions: boolean;
  showResourceView: boolean;
  calendarSubscriptions: CalendarSubscription[];
  showCollections: boolean;
  showDeclinedEvents: boolean;
}

export type ImmutableCalendarViewState = Immutable<CalendarViewState>;

const initialState: ImmutableCalendarViewState = immutable.from({
  absences: [],
  nationalHolidays: [],
  liturgicalHolidays: [],
  timePeriod: TimePeriod.Month,
  resourceType: ResourceType.Calendar,
  showNationalHolidays: true,
  showLiturgicalHolidays: false,
  eventColoringCriteria: CalendarEventColoringCriteria.Category,
  selectedDate: new Date(),
  showResources: true,
  showPrepTime: true,
  showIntentions: false, // Default is false to ensure people without intentions access can't see
  showResourceView: false,
  calendarSubscriptions: [],
  showCollections: false,
  showDeclinedEvents: true, // Default is true for everyone
});

const ChangeTimePeriod: Reducer<
  ImmutableCalendarViewState,
  ChangeTimePeriodActionType
> = (state = initialState, { payload }) => {
  if (payload === TimePeriod.Week || payload === TimePeriod.Month) {
    state.merge({ showResourceView: false });
  }
  return state.merge({ timePeriod: payload });
};

const ChangeResourceType: Reducer<
  ImmutableCalendarViewState,
  ChangeResourceTypeActionType
> = (state = initialState, { payload }) =>
  state.merge({ resourceType: payload });

const fetchNationalHolidaysSuccess: Reducer<
  ImmutableCalendarViewState,
  LoadNationalHolidaysSuccess
> = (state = initialState, { payload }) =>
  state.merge({ nationalHolidays: payload });

const toggleShowNationalHolidays: Reducer<
  ImmutableCalendarViewState,
  ToggleShowNationalHolidays
> = (state = initialState) =>
  state.merge({
    showNationalHolidays: !state.showNationalHolidays,
  });
const toggleShowCollections: Reducer<
  ImmutableCalendarViewState,
  ToggleCollections
> = (state = initialState) =>
  state.merge({
    showCollections: !state.showCollections,
  });

const fetchLiturgicalHolidaysSuccess: Reducer<
  ImmutableCalendarViewState,
  LoadLiturgicalHolidaysSuccess
> = (state = initialState, { payload }) =>
  state.merge({ liturgicalHolidays: payload });

const toggleShowLiturgicalHolidays: Reducer<
  ImmutableCalendarViewState,
  ToggleShowLiturgicalHolidays
> = (state = initialState) =>
  state.merge({
    showLiturgicalHolidays: !state.showLiturgicalHolidays,
  });

const setCalendarEventColoringCriteria: Reducer<
  ImmutableCalendarViewState,
  SetCalendarEventColoringCriteria
> = (state = initialState, { payload }) =>
  state.merge({
    eventColoringCriteria: payload,
  });

const setSelectedDate: Reducer<ImmutableCalendarViewState, GoToDateActionType> =
  (state = initialState, { payload }) =>
    state.merge({
      selectedDate: payload,
    });

const initState: Reducer<ImmutableCalendarViewState, InitializeViewState> = (
  state,
  { payload }
) => state.merge(payload);

const toggleShowResources: Reducer<ImmutableCalendarViewState, AnyAction> = (
  state
) => state.merge({ showResources: !state.showResources });

const toggleShowPrepTime: Reducer<ImmutableCalendarViewState, AnyAction> = (
  state
) => state.merge({ showPrepTime: !state.showPrepTime });

const toggleShowIntentions: Reducer<ImmutableCalendarViewState, AnyAction> = (
  state
) => state.merge({ showIntentions: !state.showIntentions });

const toggleResourceView: Reducer<ImmutableCalendarViewState, AnyAction> = (
  state
) => state.merge({ showResourceView: !state.showResourceView });

const toggleShowDeclinedEvents: Reducer<ImmutableCalendarViewState, AnyAction> =
  (state) => state.merge({ showDeclinedEvents: !state.showDeclinedEvents });

const fetchCalendarSubscriptionSuccess: Reducer<
  ImmutableCalendarViewState,
  FetchCalendarSubscriptionSuccess
> = (state = initialState, { payload }) =>
  state.merge({ calendarSubscriptions: payload });

const toggleAllMyCalendars: Reducer<
  ImmutableCalendarViewState,
  ToggleAllMyCalendars
> = (state, { payload }) => {
  const { collections, national, liturgical } = payload;
  return state.merge({
    showNationalHolidays: national,
    showLiturgicalHolidays: liturgical,
    showCollections: collections,
  });
};

const handlers = {
  [CHANGE_RESOURCE_TYPE]: ChangeResourceType,
  [CHANGE_TIME_PERIOD]: ChangeTimePeriod,
  [LOAD_NATIONAL_HOLIDAYS_SUCCESS_TYPE]: fetchNationalHolidaysSuccess,
  [LOAD_LITURGICAL_HOLIDAYS_SUCCESS_TYPE]: fetchLiturgicalHolidaysSuccess,
  [TOGGLE_SHOW_NATIONAL_HOLIDAYS]: toggleShowNationalHolidays,
  [TOGGLE_SHOW_COLLECTIONS]: toggleShowCollections,
  [TOGGLE_SHOW_LITURGICAL_HOLIDAYS]: toggleShowLiturgicalHolidays,
  [SET_CALENDAR_EVENT_COLORING_CRITERIA]: setCalendarEventColoringCriteria,
  [GO_TO_DATE]: setSelectedDate,
  [INITIALIZE_VIEW_STATE]: initState,
  [TOGGLE_SHOW_RESOURCES]: toggleShowResources,
  [TOGGLE_SHOW_PREP_TIME]: toggleShowPrepTime,
  [TOGGLE_SHOW_INTENTIONS]: toggleShowIntentions,
  [TOGGLE_RESOURCE_VIEW]: toggleResourceView,
  [FETCH_CALENDAR_SUBSCRIPTIONS_SUCCESS]: fetchCalendarSubscriptionSuccess,
  [TOGGLE_ALL_MY_CALENDARS]: toggleAllMyCalendars,
  [TOGGLE_SHOW_DECLINED_EVENTS]: toggleShowDeclinedEvents,
};

export default createReducer<ImmutableCalendarViewState>(
  initialState,
  handlers
);
