/* eslint-disable react-hooks/exhaustive-deps */
import { filter, find, first, get, isEmpty, isNil, map, orderBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Select, Form, Input, Row, Col, Checkbox, Modal } from 'antd';
import { destroyModal, sagaModal } from 'redux-saga-modal';

import { Stole, StoleStatusTypes } from '../../models/stole';
import { I18nService } from '../../../services/I18nService';
import { Resource } from '../../../shared/models/resource';
import { PaymentMethodTypes } from '../../models/intention';
import { paymentMethods } from '../../shared/intentionCommon';
import { IntentionFee, IntentionFeeTypes } from '../../../settings/models/fee';
import { getStoleFees } from '../../../settings/redux/intention-fees/Selectors';
import { getFilteredFees } from '../../../settings/redux/intention-fees/data.converter';
import { fetchIntentionFees } from '../../../settings/redux/intention-fees/Actions';
import { getChurches, getResources } from '../../../shared/store/resources';
import { DetailedEvent } from '../../../calendar/models/calendar';
import MODAL_TYPES from '../../sagas/modal-types';
import ErrorHandlingService from '../../../services/ErrorHandlingService';
import { handleSuccessMessage } from '../../../shared/utils';
import { fullCalendarActions } from '../../../calendar/store/events/fullCalendarEventsSlice';
import { fetchEventStole } from '../../redux/stoles/Actions';
import StoleService from '../../services/StoleService';

// Antd
const { Option } = Select;
const { TextArea } = Input;

// Props
export interface CreateStoleModalProps {
  isOpen: boolean;
  event: DetailedEvent;
  stole?: Stole;
  closeToolTip?: (e?: any) => void;
}

const CreateStoleModal: FunctionComponent<CreateStoleModalProps> = ({
  isOpen,
  stole,
  event,
  closeToolTip,
}) => {
  const dispatch = useDispatch();

  const churches = useSelector(getChurches);
  const allStoleFees = useSelector(getStoleFees);
  const stolesFees: IntentionFee[] = getFilteredFees(allStoleFees);
  const resources = useSelector(getResources);
  const [stoleForm] = Form.useForm();

  const [shouldDisableSubmit, setShouldDisableSubmit] =
    useState<boolean>(false);
  const [stoleFeeAmount, setStoleFeeAmount] = useState<number>(null);
  const [churchResources, setChurchResources] = useState<Resource[]>([]);
  const [filteredStolesFees, setFilteredStolesFees] = useState<IntentionFee[]>(
    []
  );

  const stoleFormRules = {
    church: [
      { required: true, message: I18nService.getString('Select a church') },
    ],
    fee: [
      {
        required: true,
        message: I18nService.getString('Select a stole fee'),
      },
    ],
    stoleText: [
      { required: true, message: I18nService.getString('Enter stole text') },
    ],
    paymentMethod: [
      {
        required: true,
        message: I18nService.getString('Select a payment method'),
      },
    ],
  };

  useEffect(() => {
    // Fetch updated Stole fees on init
    dispatch(fetchIntentionFees({ type: IntentionFeeTypes.STOLARIEN }));
    if (isEdit) {
      stoleForm.setFieldsValue(stole);
      getStoleFeeAmount(stole.feeId);
      loadChurchDependentEntities(stole.churchId);
    } else if (event && isCreate) {
      // Predefine church if create
      stoleForm.setFieldsValue({
        church: { id: first(event.churches).id },
      });
    }

    updateForm(null);
  }, []);

  useEffect(() => {
    if (stolesFees) {
      const newFilteredStolesFees = filter(
        stolesFees,
        (fee) => !isEmpty(filter(fee.taxonomies, ['id', event.mainCategory]))
      );

      // Predefine stole fee if create
      if (isCreate && !isEmpty(newFilteredStolesFees)) {
        const firstFeeId = first(newFilteredStolesFees).id;
        stoleForm.setFieldsValue({
          fee: { id: firstFeeId },
        });

        getStoleFeeAmount(firstFeeId);
      }

      setFilteredStolesFees(newFilteredStolesFees);
    }
  }, [map(stolesFees, 'id').toString()]);

  const loadChurchDependentEntities = (churchId: number) => {
    // Load church's resources
    const church = find(resources, ['id', churchId]);
    const churchResources = get(church, 'resources', []);
    const orderedChurchResources = orderBy(churchResources, 'name');
    setChurchResources(orderedChurchResources);
  };

  const isStoleCompleted = () =>
    stole && stole.status === StoleStatusTypes.COMPLETED;

  const getStoleFeeAmount = (stoleFeeId: string) => {
    // Update total fee amount
    const amount = find(stolesFees, ['id', stoleFeeId])?.amount;
    setStoleFeeAmount(amount);
  };

  const handleClose = () => {
    if (closeToolTip) {
      closeToolTip();
    }

    closeModal();
    dispatch(fullCalendarActions.reloadCurrentView());
    dispatch(fetchEventStole(event.id));
  };

  const createStole = (payload) => {
    StoleService.createStole(payload, event.id)
      .then(() => {
        handleSuccessMessage(
          I18nService.getString('Successfully created stole.')
        );
        handleClose();
      })
      .catch(ErrorHandlingService.handleError);
  };

  const updateStole = (payload) => {
    StoleService.updateStole(payload, stole.id, event.id)
      .then(() => {
        handleSuccessMessage(
          I18nService.getString('Successfully updated stole.')
        );
        handleClose();
      })
      .catch(ErrorHandlingService.handleError);
  };

  const createOrUpdateStole = () => {
    const payload = stoleForm.getFieldsValue();

    // Set to null if undefined
    if (!payload.resource.id) {
      payload.resource = null;
    }

    // Set to null if empty
    if (isEmpty(payload.comment)) {
      payload.comment = null;
    }

    // Creating or updating stole
    if (stole) {
      updateStole(payload);
    } else {
      createStole(payload);
    }
  };

  const closeModal = () => dispatch(destroyModal(MODAL_TYPES.CREATE_STOLE));

  // Validate the fields and enable/disable submit button
  const validateForm = () => {
    stoleForm.validateFields().catch((err) => {
      if (isEmpty(err.errorFields)) {
        setShouldDisableSubmit(false);
      } else {
        setShouldDisableSubmit(true);
      }
    });
  };

  const updateForm = (newValues) => {
    const stoleFee = newValues?.fee;
    const stoleFeeId = stoleFee?.id;
    if (stoleFeeId) {
      getStoleFeeAmount(stoleFeeId);
    } else if (stoleFee && !stoleFeeId) {
      setStoleFeeAmount(null);
    }

    validateForm();
  };

  const isEdit = useMemo(() => !isNil(stole), [stole ? stole.id : null]);

  const isCreate = useMemo(() => isNil(stole), [stole ? stole.id : null]);

  return (
    <Modal
      open={isOpen}
      title={
        isEdit
          ? I18nService.getString('Edit Stole')
          : I18nService.getString('Create Stole')
      }
      onOk={createOrUpdateStole}
      onCancel={closeModal}
      okText={
        isEdit
          ? I18nService.getString('Update')
          : I18nService.getString('Create')
      }
      okButtonProps={{ disabled: shouldDisableSubmit }}
      destroyOnClose
      getContainer={document.getElementById('calendar-event-popover')}
      maskTransitionName="maskTransitionName"
    >
      <Form id="editStoleForm" form={stoleForm} onValuesChange={updateForm}>
        {/* Stole Number */}
        {isEdit && (
          <Form.Item
            name="referenceNumber"
            label={I18nService.getString('Number')}
          >
            <span className="ant-form-text">
              {stoleForm.getFieldValue('referenceNumber')}
            </span>
          </Form.Item>
        )}
        {/* Church */}
        <Form.Item
          name={['church', 'id']}
          label={I18nService.getString('Church')}
          rules={stoleFormRules.church}
        >
          {stole ? (
            <span className="ant-form-text">
              {find(churches, ['id', stole.churchId]).name}
            </span>
          ) : (
            <Select allowClear onChange={loadChurchDependentEntities}>
              {event &&
                event.churches.map((church) => (
                  <Option key={church.id} value={church.id}>
                    {get(church, 'name')}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>
        {/* Resources */}
        <Form.Item
          name={['resource', 'id']}
          label={I18nService.getString('Resource')}
        >
          <Select
            disabled={isEmpty(churchResources) || isStoleCompleted()}
            allowClear
          >
            {churchResources.map((resource) => (
              <Option key={resource.id} value={resource.id}>
                {get(resource, 'name')}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* Stole Fees - required */}
        <Form.Item label={I18nService.getString('Stole Fees')} required>
          <Row justify="space-between" style={{ marginBottom: 0 }}>
            <Col span={16}>
              <Form.Item
                name={['fee', 'id']}
                style={{ marginBottom: 0 }}
                rules={stoleFormRules.fee}
              >
                <Select
                  placeholder={I18nService.getString('Select a stole fee...')}
                  allowClear
                  disabled={isEmpty(filteredStolesFees) || isStoleCompleted()}
                >
                  {filteredStolesFees.map((fee) => (
                    <Option key={fee.id} value={fee.id}>
                      {get(fee, 'name')}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              {/* Fee Amount */}
              <Input
                prefix={get(window, 'cdApp.organization.currency')}
                disabled={true}
                style={{ padding: '0px 11px' }}
                value={stoleFeeAmount}
              />
            </Col>
          </Row>
        </Form.Item>
        {/* Stole text - required */}
        <Form.Item
          name="stoleText"
          label={I18nService.getString('Stole text')}
          rules={stoleFormRules.stoleText}
        >
          <TextArea rows={2} disabled={isStoleCompleted()} />
        </Form.Item>
        <Form.Item label={I18nService.getString('Payment method')} required>
          <Row
            justify="space-between"
            align="bottom"
            gutter={8}
            style={{ marginBottom: 0 }}
          >
            <Col span={16}>
              {/* Payment method */}
              <Form.Item
                rules={stoleFormRules.paymentMethod}
                name="paymentMethod"
                style={{ marginBottom: 0 }}
                initialValue={PaymentMethodTypes.PAIDBYCASH}
              >
                <Select
                  placeholder={I18nService.getString('Select a payment method')}
                  style={{ width: '100%' }}
                  disabled={isEmpty(paymentMethods) || isStoleCompleted()}
                  allowClear
                >
                  {paymentMethods().map((paymentMethod) => (
                    <Option key={paymentMethod.id} value={paymentMethod.id}>
                      {get(paymentMethod, 'name')}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              {/* Paid */}
              <Form.Item
                name="paid"
                valuePropName="checked"
                style={{ marginBottom: 0 }}
                initialValue={true}
              >
                <Checkbox disabled={isStoleCompleted()}>
                  {I18nService.getString('Paid?')}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        {/* Comment */}
        <Form.Item name="comment" label={I18nService.getString('Note')}>
          <TextArea
            rows={2}
            placeholder={I18nService.getString('Additional information...')}
            disabled={isStoleCompleted()}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default sagaModal({
  name: MODAL_TYPES.CREATE_STOLE,
})(CreateStoleModal);
