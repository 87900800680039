/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApproveLegalDocumentsForOrganizationRequestBody } from '../models/ApproveLegalDocumentsForOrganizationRequestBody';
import type { EmptyObject } from '../models/EmptyObject';
import type { GetOrganizationBillingInformationResponseBody } from '../models/GetOrganizationBillingInformationResponseBody';
import type { GetOrganizationDioceseResponseBody } from '../models/GetOrganizationDioceseResponseBody';
import type { GetOrganizationsIdPublicResponseBody } from '../models/GetOrganizationsIdPublicResponseBody';
import type { IndexOrganizationPeopleToElasticsearchRequestBody } from '../models/IndexOrganizationPeopleToElasticsearchRequestBody';
import type { PutOrganizationsCapsRequestBody } from '../models/PutOrganizationsCapsRequestBody';
import type { PutOrganizationsDioceseRequestBody } from '../models/PutOrganizationsDioceseRequestBody';
import type { SearchOrganizationsResponseBody } from '../models/SearchOrganizationsResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class OrganizationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * PostOrganizationsAccount
     * @returns EmptyObject
     * @throws ApiError
     */
    public postOrganizationsAccount({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organizations/account',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetOrganizationBillingInformation
     * Get Organization Billing Information
     * @returns GetOrganizationBillingInformationResponseBody
     * @throws ApiError
     */
    public getOrganizationsBilling({
        stripeCustomerId,
    }: {
        stripeCustomerId: string,
    }): CancelablePromise<GetOrganizationBillingInformationResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/billing/{stripeCustomerId}',
            path: {
                'stripeCustomerId': stripeCustomerId,
            },
        });
    }
    /**
     * PostOrganizationsHubspot
     * POST EMAIL
     * @returns EmptyObject
     * @throws ApiError
     */
    public postOrganizationsHubspot({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organizations/hubspot',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * SearchOrganizations
     * Search Organizations
     * @returns SearchOrganizationsResponseBody
     * @throws ApiError
     */
    public getOrganizationsSearch({
        name,
        country,
        limit = 10,
    }: {
        name?: string,
        country?: string,
        limit?: number,
    }): CancelablePromise<SearchOrganizationsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/search',
            query: {
                'name': name,
                'country': country,
                'limit': limit,
            },
        });
    }
    /**
     * GetOrganizationsIdAccount
     * @returns EmptyObject
     * @throws ApiError
     */
    public getOrganizationsAccount(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{id}/account',
        });
    }
    /**
     * PutOrganizationsIdAccount
     * @returns EmptyObject
     * @throws ApiError
     */
    public putOrganizationsAccount({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/organizations/{id}/account',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetOrganizationsIdAccountHasMvr
     * @returns EmptyObject
     * @throws ApiError
     */
    public getOrganizationsAccountHasMvr(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{id}/account/has-mvr',
        });
    }
    /**
     * GetOrganizationsIdAccountInfo
     * @returns EmptyObject
     * @throws ApiError
     */
    public getOrganizationsAccountInfo(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{id}/account/info',
        });
    }
    /**
     * GetOrganizationsIdCaps
     * Get Organization Caps
     * @returns EmptyObject
     * @throws ApiError
     */
    public getOrganizationsCaps(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{id}/caps',
        });
    }
    /**
     * PutOrganizationsIdCaps
     * Set Organization Caps
     * @returns EmptyObject
     * @throws ApiError
     */
    public putOrganizationsCaps({
        requestBody,
    }: {
        requestBody?: PutOrganizationsCapsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/organizations/{id}/caps',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetOrganizationDiocese
     * Get Organization Diocese
     * @returns GetOrganizationDioceseResponseBody
     * @throws ApiError
     */
    public getOrganizationsDiocese({
        id,
    }: {
        id: number,
    }): CancelablePromise<GetOrganizationDioceseResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{id}/diocese',
            path: {
                'id': id,
            },
        });
    }
    /**
     * SetOrganizationDiocese
     * Set Organization Diocese
     * @returns EmptyObject
     * @throws ApiError
     */
    public putOrganizationsDiocese({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PutOrganizationsDioceseRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/organizations/{id}/diocese',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetOrganizationsIdInfo
     * @returns EmptyObject
     * @throws ApiError
     */
    public getOrganizationsInfo(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{id}/info',
        });
    }
    /**
     * GetOrganizationsIdPublic
     * @returns GetOrganizationsIdPublicResponseBody
     * @throws ApiError
     */
    public getOrganizationsPublic(): CancelablePromise<GetOrganizationsIdPublicResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{id}/public',
        });
    }
    /**
     * PostOrganizationsOrganizationidAccountIdentityDocument
     * Stripe Account Upload Identity File
     * @returns EmptyObject
     * @throws ApiError
     */
    public postOrganizationsAccountIdentityDocument({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organizations/{organizationId}/account/identity/document',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetOrganizationsOrganizationidAlias
     * Check alias.
     * @returns EmptyObject
     * @throws ApiError
     */
    public getOrganizationsAlias({
        path,
        drupalAccessToken,
        organizationId,
    }: {
        path: string,
        drupalAccessToken: string,
        organizationId: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{organizationId}/alias',
            path: {
                'organizationId': organizationId,
            },
            query: {
                'path': path,
                'drupal_access_token': drupalAccessToken,
            },
        });
    }
    /**
     * IndexOrganizationPeopleToElasticsearch
     * Index Organization People to Elasticsearch
     * @returns EmptyObject
     * @throws ApiError
     */
    public postOrganizationsIndexPeople({
        organizationId,
        requestBody,
    }: {
        organizationId: number,
        requestBody?: IndexOrganizationPeopleToElasticsearchRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organizations/{organizationId}/index-people',
            path: {
                'organizationId': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetLegalDocumentsOfOrganization
     * Legal Document List
     * @returns EmptyObject
     * @throws ApiError
     */
    public getOrganizationsLegalDocument({
        organizationId,
    }: {
        organizationId: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{organizationId}/legal-document',
            path: {
                'organizationId': organizationId,
            },
        });
    }
    /**
     * ApproveLegalDocumentsForOrganization
     * Legal Document Approve
     * @returns EmptyObject
     * @throws ApiError
     */
    public postOrganizationsLegalDocument({
        organizationId,
        legalDocumentId,
        requestBody,
    }: {
        organizationId: number,
        legalDocumentId: string,
        requestBody?: ApproveLegalDocumentsForOrganizationRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organizations/{organizationId}/legal-document/{legalDocumentId}',
            path: {
                'organizationId': organizationId,
                'legalDocumentId': legalDocumentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetOrganizationsOrganizationidStripeAccount
     * Stripe Account Retrieve
     * @returns EmptyObject
     * @throws ApiError
     */
    public getOrganizationsStripeAccount(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{organizationId}/stripe/account',
        });
    }
}
