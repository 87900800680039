/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { PostSlideshowSettingRequestBody } from '../models/PostSlideshowSettingRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SlideshowService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetSlideShowSetting
     * Retrieve Setting
     * @returns EmptyObject
     * @throws ApiError
     */
    public getSlideshowSetting({
        drupalAccessToken,
    }: {
        drupalAccessToken?: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/slideshow/setting',
            query: {
                'drupal_access_token': drupalAccessToken,
            },
        });
    }
    /**
     * SlideShowDimensionSetting
     * Update Setting
     * @returns EmptyObject
     * @throws ApiError
     */
    public postSlideshowSetting({
        drupalAccessToken,
        requestBody,
    }: {
        drupalAccessToken?: string,
        requestBody?: PostSlideshowSettingRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/slideshow/setting',
            query: {
                'drupal_access_token': drupalAccessToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetSlideShow
     * Retrieve Slideshow
     * @returns EmptyObject
     * @throws ApiError
     */
    public getSlideshow({
        limit,
    }: {
        limit: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/slideshow/{limit}',
            path: {
                'limit': limit,
            },
        });
    }
}
