require('./Blogs');
require('./BookingCheck');
require('./Calendar');
require('./CalendarComment');
require('./CalendarLog');
require('./CalendarPrint');
require('./church.model');
require('./ChurchScreen');
require('./Contributions');
require('./customer.model');
require('./Dashboard');
require('./Dictionary');
require('./diocese.model');
require('./Files');
require('./Filesystem');
require('./Folders');
require('./Forms');
require('./group-message.model');
require('./stripe.model');
require('./Groups');
require('./GroupsFeed');
require('./index');
require('./Invitations');
require('./Jobs');
require('./legal-document');
require('./MyGroups');
require('./MyNotifications');
require('./Notifications');
require('./organization.model');
require('./Partnership');
require('./PeopleImportBatches');
require('./Picture');
require('./Resources');
require('./Revision');
require('./Revisions');
require('./RotaLinks');
require('./Shifts');
require('./SognDk');
require('./Tasks');
require('./Taxonomies');
require('./UserFeed');
require('./Users');
