import React from 'react';

import { FilterType } from '../store/filters';

import CalendarAutoComplete from './CalendarAutoComplete';
import FilterItem from './FilterItem';
import FilterList from './FilterList';

import { RenderCalendar } from '@/react/calendar/hooks/calendar/useSelectableCalendars';

const CalendarsSelect = ({
  calendarsToRender,
  isDuringSearch,
}: {
  calendarsToRender: RenderCalendar[];
  isDuringSearch: boolean;
}) => (
  <>
    <FilterList>
      {calendarsToRender.map((item) => (
        <FilterItem
          key={`${item.calendar.filterType}-${item.calendar.id}`}
          item={item.calendar}
          feedType={
            item.calendar.filterType === FilterType.users ? 'user' : 'resource'
          }
          addingNewCalendar={!item.isInNormalList}
        />
      ))}
    </FilterList>
    {!isDuringSearch && (
      <div style={{ margin: '8px 14px 0px' }}>
        <CalendarAutoComplete />
      </div>
    )}
  </>
);

export default CalendarsSelect;
