import { Form } from 'antd';
import { useState, useEffect, useCallback } from 'react';

import {
  fetchStripeOnboardingLink,
  saveLegalInformation,
  saveOnboardingUser,
} from '../services/stripe-onboarding.service';

import { handleAntFormValidationErrors } from '@/react/services/ErrorHandlingService';
import {
  formatCdPhoneInputValue,
  getStringCdPhoneInput,
} from '@/react/shared/components/CdPhoneInput';
import { useUser } from '@/react/user/hooks/useUser';
import cdApp from '@/react/config';

export enum StripeOnboardingModalStep {
  User = 0,
  Legal = 1,
  Signup = 2,
}

export const useStripeOnboardingModal = () => {
  const [form] = Form.useForm();
  const selectedUserId = cdApp.me.id;
  const { user } = useUser(selectedUserId);
  const [currentStep, setCurrentStep] = useState<StripeOnboardingModalStep>(
    StripeOnboardingModalStep.User
  );

  useEffect(() => {
    if (user) {
      if (user?.email) {
        form.setFieldsValue({
          onboardingUserEmail: user.email,
        });
      }
      if (user?.phones?.length > 0) {
        // Find phone with default 1
        const phone = user.phones.find((p) => p.default === 1);
        form.setFieldsValue({
          onboardingUserPhone: formatCdPhoneInputValue(
            phone.phone,
            phone.countryId
          ),
        });
      }
      if (user?.name) {
        form.setFieldsValue({
          onboardingUserName: user.name,
        });
      }
    }
  }, [form, user]);

  const save = useCallback(
    () =>
      form
        .validateFields()
        .then(async () => {
          const values = form.getFieldsValue();
          if (currentStep === StripeOnboardingModalStep.User) {
            await saveOnboardingUser({
              onboardingUserEmail: values.onboardingUserEmail,
              onboardingUserName: values.onboardingUserName,
              onboardingUserId: values.onboardingId,
              onboardingUserPhone: getStringCdPhoneInput(
                values.onboardingUserPhone
              ),
            });
            setCurrentStep(StripeOnboardingModalStep.Legal);
            return { preventClose: true };
          } else if (currentStep === StripeOnboardingModalStep.Legal) {
            await saveLegalInformation(values);
            await openStripeOnboarding();
            return null;
          }
        })
        .catch(handleAntFormValidationErrors),
    [currentStep, form]
  );

  const openStripeOnboarding = async () => {
    const link = await fetchStripeOnboardingLink();
    open(link);
  };
  return { save, form, currentStep, selectedUserId };
};
