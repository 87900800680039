/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ProductMetricsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Organizations_Metrics
     * Retrieve
     * @returns EmptyObject
     * @throws ApiError
     */
    public getProductMetricsOrganizations({
        offset,
        limit = 50,
    }: {
        offset: number,
        limit?: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/product-metrics/organizations',
            query: {
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * Organizations_Metrics
     * Retrieve
     * @returns EmptyObject
     * @throws ApiError
     */
    public getProductMetricsUsers({
        offset,
        limit = 50,
    }: {
        offset: number,
        limit?: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/product-metrics/users',
            query: {
                'limit': limit,
                'offset': offset,
            },
        });
    }
}
