import { useEffect, useState } from 'react';
import { theme } from 'antd';
import { loadConnectAndInitialize } from '@stripe/connect-js';

import { fetchStripeOnboardingLink } from '../services/stripe-onboarding.service';
import { openStripeOnboardingModal } from '../components/StripeOnboardingModal';

import cdApp from '@/react/config';
import { getStripeAccountSession } from '@/react/organization/services/organization.service';
import {
  useLegalInformation,
  useStripeAccount,
  useStripeOnboardingUser,
} from '@/react/organization/store/organization';
import { useCheckAcceptLegalTerms } from '@/react/contributions/hooks/useAcceptLegalTermsModal';

const { useToken } = theme;

export const usePaymentsSettingsPage = () => {
  const { token } = useToken();
  const {
    stripeOnboardingCompleted,
    stripeAccountIsLoading,
    stripeAccountData,
  } = useStripeAccount();
  const { stripeOnboardingUser, stripeOnboardingUserIsLoading } =
    useStripeOnboardingUser();
  const { legalInformation, legalInformationIsLoading } = useLegalInformation();
  // Check if initial country selection modal should be launched.
  useCheckAcceptLegalTerms();

  const stripeOnboardingStarted =
    stripeOnboardingUser?.onboardingUserId &&
    legalInformation?.legalBusinessName;

  // We use `useState` to ensure the Connect instance is only initialized once
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => getStripeAccountSession();

    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey: cdApp.references.stripe.contributions,
      fetchClientSecret: fetchClientSecret,
      appearance: {
        variables: {
          colorPrimary: token.colorPrimary,
          colorBackground: '#FFF',
          colorDanger: token.colorError,
          borderRadius: '4px',
        },
      },
    });
  });

  const openStripeOnboarding = async () => {
    const link = await fetchStripeOnboardingLink();
    open(link);
  };

  const getSupportArticle = () => {
    const organizationLanguage = cdApp.organization.locale.language;
    switch (organizationLanguage) {
      case 'de':
        return 'https://support.churchdesk.com/de/article/richten-sie-churchdesk-spenden-ein-5o29rz/';

      case 'da':
        return 'https://support.churchdesk.com/da/article/opsaetning-af-churchdesk-betalinger-1rc3jeo/';

      default:
        return 'https://support.churchdesk.com/en/article/set-up-churchdesk-payments-b4czox/';
    }
  };

  const legalInformationMissing =
    stripeOnboardingCompleted &&
    !legalInformationIsLoading &&
    !legalInformation?.legalBusinessName;

  useEffect(() => {
    // TODO: Remove after migration of the customers. This useEffect should be removed after 1st of May 2025
    if (legalInformationMissing) {
      // @ts-ignore
      openStripeOnboardingModal();
    }
  }, [legalInformationMissing]);

  return {
    stripeAccountData,
    stripeAccountIsLoading,
    stripeOnboardingCompleted,
    stripeOnboardingStarted,
    openStripeOnboarding,
    getSupportArticle,
    stripeConnectInstance,
    stripeOnboardingUser,
    stripeOnboardingUserIsLoading,
    legalInformation,
    legalInformationMissing,
    legalInformationIsLoading,
  };
};
