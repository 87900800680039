/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostTerminalCommandRequestBody = {
    action: PostTerminalCommandRequestBody.action;
};
export namespace PostTerminalCommandRequestBody {
    export enum action {
        _1 = '1',
        _2 = '2',
        _3 = '3',
        _4 = '4',
        _6 = '6',
        _9 = '9',
        _201 = '201',
        OPEN_WIFI_SETTINGS = 'OPEN_WIFI_SETTINGS',
        FORCE_CONFIG_DOWNLOAD = 'FORCE_CONFIG_DOWNLOAD',
        TAKE_APP_SCREENSHOT = 'TAKE_APP_SCREENSHOT',
        OPEN_APP_SETTINGS = 'OPEN_APP_SETTINGS',
        RESTART_APPLICATION = 'RESTART_APPLICATION',
        REBOOT_DEVICE = 'REBOOT_DEVICE',
        REFRESH_STATUS = 'REFRESH_STATUS',
    }
}

