import { Form } from 'antd';
import React, { useEffect } from 'react';

import { VerticalTabMenu } from '../vertical-tab-menu/VerticalTabMenu';
import { useAvailabilityFormData } from '../../hooks/useAvailabilityFormData';
import CdrPageLoader from '../../../../shared/components/CdrPageLoader';

import { OptionForm } from './OptionForm';
import { DetailsForm } from './DetailsForm';

import { I18nService } from '@/react/services/I18nService';
import {
  ModalType,
  createCdModal,
} from '@/react/shared/components/cd-modal-new/CdModal2';

type BookingPageSettingsModalProps = {
  taxonomyId?: number;
};

export const showBookingPageSettingsModal =
  createCdModal<BookingPageSettingsModalProps>(
    ({ setModalProps, taxonomyId }) => {
      const {
        initialValues,
        form,
        onSave,
        onSaveAndContinue,
        isLoadingExistingTaxonomy,
        title,
        activeItem,
        setActiveItem,
        copyBookingOption,
        deleteBookingOption,
        currentBookingOptions,
        formData,
        followUpForm,
        isEditMode,
      } = useAvailabilityFormData(taxonomyId);

      useEffect(() => {
        setModalProps({
          title,
          modalType: ModalType.MODAL,
          onOk: onSave,
          onAlternative: onSaveAndContinue,
        });
      }, [onSave, onSaveAndContinue, setModalProps, title]);

      return (
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <Form.Item name="bookingOptions" noStyle hidden />
          <VerticalTabMenu
            form={form}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            copyBookingOption={copyBookingOption}
          >
            {isLoadingExistingTaxonomy ? (
              <CdrPageLoader />
            ) : (
              <>
                <div
                  // Making sure that all form fields are always mounted
                  // because AntD validation works only on mounted fields
                  style={{
                    display: activeItem === 'details' ? 'block' : 'none',
                  }}
                >
                  <DetailsForm
                    isEditMode={isEditMode}
                    form={form}
                    formData={formData}
                    followUpForm={followUpForm}
                    initialValues={initialValues}
                  />
                </div>
                {currentBookingOptions?.map((option, index) => (
                  <div
                    key={option.id || index}
                    style={{
                      display:
                        activeItem === 'option-' + index ? 'block' : 'none',
                    }}
                  >
                    <OptionForm
                      form={form}
                      index={index}
                      onDeleteBookingOption={deleteBookingOption}
                    />
                  </div>
                ))}
              </>
            )}
          </VerticalTabMenu>
        </Form>
      );
    },
    {
      modalType: ModalType.MODAL,
      alternativeButtonText: I18nService.getString('Save and continue editing'),
      okText: I18nService.getString('Save'),
      width: 850,
    }
  );
