import { $injector } from 'ngimport';

import { AuthData } from '../shared/models/authentication';

export interface AuthenticationServiceType {
  getAccessToken: (ignoreMasquerade: boolean) => string;
  getCurrentUser: () => Promise<AuthData>;
  logout: (redirectToLogin: boolean) => void;
}
class AuthenticationService {
  public getCurrentUser = (): Promise<AuthData> => {
    const Authentication: AuthenticationServiceType = $injector.get(
      'AuthenticationService'
    );
    return Authentication.getCurrentUser();
  };
  public getAccessToken(ignoreMasquerade = false): string {
    if (process.env.STORYBOOK) {
      return 'sb-token';
    }
    const AuthenticationService: AuthenticationServiceType = $injector.get(
      'AuthenticationService'
    );
    return AuthenticationService.getAccessToken(ignoreMasquerade);
  }
  public logout = (redirectToLogin): void => {
    const Authentication: AuthenticationServiceType = $injector.get(
      'AuthenticationService'
    );
    Authentication.logout(redirectToLogin);
  };
}

export default new AuthenticationService();
