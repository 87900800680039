import React, { FunctionComponent, useState } from 'react';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';

import { I18nService } from '../../../services/I18nService';
import StolesOverview from '../../components/stoles/StolesOverview';
import StolesOverviewFilters from '../../components/stoles/StolesOverviewFilters';
import { navigate } from '../../../services/StateServiceFactory';

import { CdPage } from '@/react/shared/components/cd-page/CdPage';

const Stoles: FunctionComponent = () => {
  const title = I18nService.getString('Stoles');
  const subTitle = I18nService.getString('Overview of all stoles');

  // Define initialization hook and entity reload hooks
  const [searchFilters, setSearchFilters] = useState([]);

  const goToBilling = () => {
    navigate('app.private.intention.stoles.billing');
  };

  const getPageHeaderExtra = () => (
    <Button className="u-mr-10" onClick={() => goToBilling()}>
      <FontAwesomeIcon icon={faThumbtack} style={{ marginRight: 8 }} />
      {I18nService.getString('Stoles billing')}
    </Button>
  );

  // Return the intentions home screen
  return (
    <CdPage pageHeaderProps={{ title, subTitle, extra: getPageHeaderExtra() }}>
      <StolesOverviewFilters onChange={setSearchFilters} />
      <StolesOverview filters={searchFilters} />
    </CdPage>
  );
};

export default Stoles;
