import { Row, Col } from 'antd';
import React from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { I18nService } from '@/react/services/I18nService';
import { CountryISO2State } from '@/react/user/store/user-session';
import {
  getStudioContentByLocale,
  StudioContentKeys,
} from '@/react/calendar/types/studio';

const StudioLandingPageV2Container = styled.div`
  display: block;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;

  img {
    display: block;
  }

  .ant-row {
    max-width: 800px;
    margin: auto;
  }
`;
const InfoBlockLeft = styled(Col)`
  &&&& {
    display: block;
    max-width: 350px;
    margin: auto 16px auto auto;
  }
`;
const InfoBlockRight = styled(Col)`
  &&&& {
    display: block;
    max-width: 350px;
    margin: auto auto auto 16px;
  }
`;

export const StudioLandingPageV2 = () => {
  const countryISO2 = useRecoilValue(CountryISO2State);
  return (
    <StudioLandingPageV2Container>
      <Row style={{ marginBottom: 64 }}>
        <InfoBlockLeft span={11}>
          <h3>
            {I18nService.getString(
              'Design your own newsletters and invitations'
            )}
          </h3>
          <p>
            {I18nService.getString(
              'ChurchDesk Studio gives you freedom to design your own newsletters, invitations and flyers. It comes with great templates and our drag and drop editor is simple to use'
            )}
          </p>
        </InfoBlockLeft>
        <Col span={13}>
          <img
            style={{
              maxWidth: 400,
              maxHeight: 480,
              marginRight: 'auto',
              marginLeft: '16px',
            }}
            src={getStudioContentByLocale(
              countryISO2,
              StudioContentKeys.LANDING_PAGE_IMAGE_STUDIO_EDITOR
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={13}>
          <img
            style={{
              maxWidth: 400,
              maxHeight: 480,
              marginRight: 16,
              marginLeft: 'auto',
            }}
            src={getStudioContentByLocale(
              countryISO2,
              StudioContentKeys.LANDING_PAGE_IMAGE_NEWSLETTERS
            )}
          />
        </Col>
        <InfoBlockRight span={11}>
          <h3>
            {I18nService.getString(
              'Use one of our templates or create your own'
            )}
          </h3>
          <p>
            {I18nService.getString(
              'We have templates for everything ranging from newsletters to personal invitations to your concerts. You can also design and save your own templates.'
            )}
          </p>
        </InfoBlockRight>
      </Row>
      <Row style={{ marginBottom: 32 }}>
        <InfoBlockLeft span={11}>
          <h3>
            {I18nService.getString('Make a poster or flyer for your event')}
          </h3>
          <p>
            {I18nService.getString(
              'With Studio you will be able to create beautiful posters and flyers directly from the events in your calendar. It will never be easier to make flyers or posters. Click, edit and PDF it for easy printing.'
            )}
          </p>
        </InfoBlockLeft>
        <Col span={13}>
          <img
            style={{
              maxWidth: 400,
              maxHeight: 480,
              marginRight: 'auto',
              marginLeft: '16px',
            }}
            src={getStudioContentByLocale(
              countryISO2,
              StudioContentKeys.LANDING_PAGE_IMAGE_POSTERS
            )}
          />
        </Col>
      </Row>
    </StudioLandingPageV2Container>
  );
};
