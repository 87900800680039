import React from 'react';

import { useCategories } from '../../hooks/useCategories';

import { CreateCategoryForm } from './CreateCategoryForm';

import { createCdModal } from '@/react/shared/components/cd-modal-new/CdModal2';

interface CreateBlogCategoryModalProps {
  type: string;
  taxonomyId?: number;
  taxonomyName?: string;
}
export const CreateCategoryModal = createCdModal<CreateBlogCategoryModalProps>(
  ({ setModalProps, type, taxonomyId, taxonomyName }) => {
    const { form } = useCategories({
      setModalProps,
      type,
      taxonomyId,
      taxonomyName,
    });
    return (
      <CreateCategoryForm taxonomyId={taxonomyId} type={type} form={form} />
    );
  }
);
