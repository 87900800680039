import React, { FunctionComponent, Suspense } from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import styled from 'styled-components';

import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import { I18nService } from '@/react/services/I18nService';
import { TimeRegistrationReport } from '@/react/calendar/reports/TimeRegistrationReport';
import { SearchCalendar } from '@/react/calendar/reports/SearchCalendar';
import { navigate } from '@/react/services/StateServiceFactory';
import AuthorizationService from '@/react/services/AuthorizationService';
import { ReportsProps } from '@/react/calendar/reports/types/report.types';

const StyledTabs = styled(Tabs)`
  &&&& {
    @media print {
      .ant-tabs-nav-wrap {
        display: none;
      }
    }
  }
`;

const AllReports = ({ filters, activeTab }) => {
  const title = I18nService.getString('Reports');
  const subTitle = I18nService.getString(
    'You can check and download all reports here'
  );
  const hasUserAdminRole =
    AuthorizationService.hasPermission('canAdministerUsers');
  const canAccessTimeRegistration = AuthorizationService.hasPermission(
    'canAccessTimeRegistration'
  );
  const items: TabsProps['items'] = [
    {
      key: 'search',
      label: I18nService.getString('Search'),
      children: <SearchCalendar filters={filters} tabKey="search" />,
    },
    {
      key: 'activityList',
      label: I18nService.getString('Worship overview'),
      children: <SearchCalendar filters={filters} tabKey="activityList" />,
    },
    {
      key: 'timeRegistration',
      label: I18nService.getString('Time registration'),
      children: <TimeRegistrationReport filters={filters} />,
      disabled: !canAccessTimeRegistration && !hasUserAdminRole,
    },
    {
      key: 'absence',
      label: I18nService.getString('Absence'),
      children: <SearchCalendar filters={filters} tabKey="absence" />,
    },
  ];

  const tabClickHandler = (key: string) =>
    navigate(
      'app.private.calendar.print',
      {
        default: undefined,
        searchText: undefined,
        startDate: undefined,
        endDate: undefined,
        groupSelect: undefined,
        categorySelect: undefined,
        resourceSelect: undefined,
        visibilitySelect: undefined,
        selectedEventsWithoutResourcesInChurchIds: undefined,
        churchSelect: undefined,
        userSelect: undefined,
        // time registration params
        startWeekNumber: undefined,
        startYear: undefined,
        endWeekNumber: undefined,
        endYear: undefined,
        timeRegistrationUserSelect: undefined,
        // current tab
        current: key,
      },
      { reload: true }
    );

  return (
    <CdPage
      pageHeaderProps={{
        title,
        subTitle,
      }}
    >
      <StyledTabs
        type="card"
        tabBarStyle={{ marginBottom: 0 }}
        tabBarGutter={0}
        defaultActiveKey={activeTab}
        items={items}
        onTabClick={tabClickHandler}
      />
    </CdPage>
  );
};

export const Reports: FunctionComponent<ReportsProps> = ({ $stateParams }) => (
  <Suspense fallback={<CdrPageLoader />}>
    <AllReports filters={$stateParams} activeTab={$stateParams.current} />
  </Suspense>
);
