/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GetCalendarBookingStatusResponseBody = {
    status: GetCalendarBookingStatusResponseBody.status;
};
export namespace GetCalendarBookingStatusResponseBody {
    export enum status {
        REQUESTED = 'requested',
        ACCEPTED = 'accepted',
        REJECTED = 'rejected',
    }
}

