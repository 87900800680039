/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Select, Card, Button, Form, Input, Row, Col, Checkbox } from 'antd';

import { StoleStatusTypes } from '../../models/stole';
import StoleService from '../../services/StoleService';
import { I18nService } from '../../../services/I18nService';
import { Resource } from '../../../shared/models/resource';
import { PaymentMethodTypes } from '../../models/intention';
import { getEventStole } from '../../redux/stoles/Selectors';
import { paymentMethods } from '../../shared/intentionCommon';
import { IntentionFee, IntentionFeeTypes } from '../../../settings/models/fee';
import { getStoleFees } from '../../../settings/redux/intention-fees/Selectors';
import { fetchIntentionFees } from '../../../settings/redux/intention-fees/Actions';
import { getFilteredFees } from '../../../settings/redux/intention-fees/data.converter';
import ErrorHandlingService from '../../../services/ErrorHandlingService';
import { clearEventStole, fetchEventStole } from '../../redux/stoles/Actions';
import { getChurches, getResources } from '../../../shared/store/resources';

import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';

enum StoleFormStatus {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
}

// Antd
const { Option } = Select;
const { TextArea } = Input;

// Props
export interface CreateStoleProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  updateParentOnChange: Function;
  mainTaxonomy: number;
  eventYear: number;
  selectedChurchIds: number[];
  selectedResourceIds: number[];
  calendarId: number;
}

const CreateStole: FunctionComponent<CreateStoleProps> = ({
  updateParentOnChange,
  mainTaxonomy,
  eventYear,
  selectedChurchIds,
  selectedResourceIds,
  calendarId,
}) => {
  const dispatch = useDispatch();

  const churches = useSelector(getChurches);
  const allStoleFees = useSelector(getStoleFees);
  const stolesFees: IntentionFee[] = getFilteredFees(allStoleFees);
  const resources = useSelector(getResources);
  const eventStole = useSelector(getEventStole);
  const [stoleForm] = Form.useForm();

  const [, updateForm] = useState();
  const [formStatus, setStoleFormStatus] = useState<StoleFormStatus>(
    StoleFormStatus.INACTIVE
  );
  const [churchResources, setChurchResources] = useState<Resource[]>([]);
  const [filteredStolesFees, setFilteredStolesFees] = useState<IntentionFee[]>(
    []
  );
  const [filteredChurches, setFilteredChurches] = useState<Resource[]>([]);

  useEffect(() => {
    dispatch(fetchIntentionFees({ type: IntentionFeeTypes.STOLARIEN }));
  }, []);

  // Loading existing stole from event
  useEffect(() => {
    if (calendarId) {
      dispatch(fetchEventStole(calendarId));
    } else {
      setStoleFormStatus(StoleFormStatus.INACTIVE);
      dispatch(clearEventStole());
    }
  }, [calendarId]);

  useEffect(() => {
    if (mainTaxonomy) {
      filterStolesFees();
    }
  }, [mainTaxonomy]);

  useEffect(() => {
    if (selectedChurchIds) {
      filterChurches();
      // Initialize church to first of parent
      stoleForm.setFieldsValue({ churchId: selectedChurchIds[0] });
      loadChurchDependentEntities(selectedChurchIds[0]);
      getNextReferenceNumber();
    }
  }, [JSON.stringify(selectedChurchIds)]);

  useEffect(() => {
    if (selectedResourceIds) {
      const commonResourceIds = _.intersection(
        _.map(churchResources, 'id'),
        selectedResourceIds
      );
      if (commonResourceIds) {
        // Set resource in stole to first common resource
        stoleForm.setFieldsValue({ resourceId: commonResourceIds[0] });
      }
    }
  }, [JSON.stringify(selectedResourceIds)]);

  useEffect(() => {
    if (shouldDisableStoleButton() && !eventStole) {
      setStoleFormStatus(StoleFormStatus.INACTIVE);
    }
  }, [
    JSON.stringify(selectedChurchIds),
    JSON.stringify(_.map(filteredStolesFees, 'id')),
    eventYear,
  ]);

  useEffect(() => {
    if (eventStole) {
      stoleForm.setFieldsValue({
        nextReferenceNumber: eventStole.formattedReferenceNumber,
        resourceId: eventStole.resourceId,
        churchId: eventStole.churchId,
        feeId: eventStole.feeId,
        feeAmount: eventStole.fee.amount,
        stoleText: eventStole.stoleText,
        comment: eventStole.comment,
        paymentMethod: eventStole.paymentMethod,
        paid: eventStole.paid,
      });
      loadChurchDependentEntities(eventStole.churchId);
      setStoleFormStatus(StoleFormStatus.ACTIVE);
    } else {
      stoleForm.resetFields();
      // This is to show which fields need to be filled in on init
      stoleForm.validateFields();
    }
  }, [eventStole ? eventStole.id : null]);

  useEffect(() => {
    sendFormToParent();
  }, [formStatus]);

  const filterStolesFees = () => {
    const newFilteredStolesFees = _.filter(
      stolesFees,
      (fee) => !_.isEmpty(_.filter(fee.taxonomies, ['id', mainTaxonomy]))
    );

    setFilteredStolesFees(newFilteredStolesFees);
  };

  const filterChurches = () => {
    const newFilteredChurches = _.filter(
      churches && churches.asMutable({ deep: true }),
      (church) => _.includes(selectedChurchIds, church.id)
    );

    setFilteredChurches(newFilteredChurches);
  };

  const shouldDisableStoleButton = () =>
    // Disable add button if there aren't stole fees for the main category, there aren't churches selected,
    // there isn't an event year selected or they are editing an existing stole
    _.isEmpty(filteredStolesFees) ||
    _.isEmpty(selectedChurchIds) ||
    !eventYear ||
    isStoleCompleted();
  const shouldHideStoleForm = () =>
    // Hide the form if there aren't stole fees for the main category, there aren't churches selected,
    // there isn't an event year selected or they the stole status is inactive, meaning they have not
    // clicked the "Add stole" button in the card header
    _.isEmpty(filteredStolesFees) ||
    _.isEmpty(selectedChurchIds) ||
    !eventYear ||
    formStatus === StoleFormStatus.INACTIVE;
  const isStoleCompleted = () =>
    eventStole && eventStole.status === StoleStatusTypes.COMPLETED;

  // stole form functions
  const handleChurchUpdate = (churchId: number) => {
    // Clear stoleForm values that depend on the value of the previously selected church ID
    stoleForm.setFieldsValue({
      resourceId: null,
    });

    if (churchId) {
      // Re-load resources
      loadChurchDependentEntities(churchId);
      // Reload the reference number
      getNextReferenceNumber();
    }
  };

  const handleStolesFeeChange = (stoleFeeId: string) => {
    // Update total fee amount
    stoleForm.setFieldsValue({
      feeAmount: _.find(stolesFees, ['id', stoleFeeId]).amount,
    });
  };

  const getNextReferenceNumber = () => {
    const churchId: number = stoleForm.getFieldValue('churchId');
    const stoleYear = eventYear;

    if (eventStole) return;
    if (!_.isFinite(churchId)) return;

    StoleService.getStolesNextReferenceNumber(churchId, stoleYear)
      .then((value) => {
        stoleForm.setFieldsValue({ nextReferenceNumber: value });
      })
      .catch(ErrorHandlingService.handleError);
  };

  const loadChurchDependentEntities = (churchId: number) => {
    // Load church's resources
    const church = _.find(resources, ['id', churchId]);
    const churchResources = _.get(church, 'resources', []);
    const orderedChurchResources = _.orderBy(churchResources, 'name');
    setChurchResources(orderedChurchResources);
  };

  const sendFormToParent = () => {
    if (!formStatus || formStatus === StoleFormStatus.INACTIVE) {
      // Hide form if inactive or undefined
      updateParentOnChange(null);
    } else {
      // Show form if editing or adding
      updateParentOnChange(stoleForm);
    }
    // Force refresh to reflect validation changes
    updateForm(undefined);
  };

  const getExtraButton = () => {
    const tooltipText = I18nService.getString(
      'You must have a main category with stole fees, an event year and a church selected to create a stole'
    );

    let buttonText, danger;
    switch (formStatus) {
      case StoleFormStatus.ACTIVE:
        buttonText = I18nService.getString('Remove');
        danger = true;
        break;
      case StoleFormStatus.INACTIVE:
        buttonText = I18nService.getString('Add');
        danger = false;
    }

    return (
      <CdTooltip title={tooltipText}>
        <Button
          style={{ float: 'right' }}
          onClick={() =>
            formStatus === StoleFormStatus.INACTIVE
              ? setStoleFormStatus(StoleFormStatus.ACTIVE)
              : setStoleFormStatus(StoleFormStatus.INACTIVE)
          }
          disabled={shouldDisableStoleButton()}
          danger={danger}
        >
          {buttonText}
        </Button>
      </CdTooltip>
    );
  };

  return (
    <Card
      bodyStyle={{ display: shouldHideStoleForm() ? 'none' : 'block' }}
      title={I18nService.getString('Create Stole')}
      extra={getExtraButton()}
    >
      <Form
        id="createStoleForm"
        form={stoleForm}
        onValuesChange={sendFormToParent}
      >
        {/* Stole Number - required */}
        <Form.Item
          name="nextReferenceNumber"
          label={I18nService.getString('Number')}
        >
          <Input style={{ width: '50%' }} disabled></Input>
        </Form.Item>
        {/* Church - required */}
        <Form.Item
          name="churchId"
          label={I18nService.getString('Church')}
          rules={[
            {
              required: true,
              message: I18nService.getString('Please select a church...'),
            },
          ]}
        >
          {_.isNil(eventStole) ? (
            <Select
              onChange={handleChurchUpdate}
              disabled={!_.isNil(eventStole) || isStoleCompleted()}
              allowClear
            >
              {filteredChurches &&
                filteredChurches.map((church) => (
                  <Option key={church.id} value={church.id}>
                    {_.get(church, 'name')}
                  </Option>
                ))}
            </Select>
          ) : (
            <span className="ant-form-text">
              {_.find(churches, ['id', eventStole.churchId]).name}
            </span>
          )}
        </Form.Item>
        {/* Resources */}
        <Form.Item name="resourceId" label={I18nService.getString('Resource')}>
          <Select
            disabled={_.isEmpty(churchResources) || isStoleCompleted()}
            allowClear
          >
            {churchResources.map((resource) => (
              <Option key={resource.id} value={resource.id}>
                {_.get(resource, 'name')}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* Stole Fees - required */}
        <Form.Item label={I18nService.getString('Stole Fees')} required>
          <Row justify="space-between" style={{ marginBottom: 0 }}>
            <Col span={16}>
              <Form.Item
                name="feeId"
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    required: true,
                    message: I18nService.getString(
                      'Please select a stole fee...'
                    ),
                  },
                ]}
              >
                <Select
                  placeholder={I18nService.getString('Select a stole fee...')}
                  allowClear
                  onChange={handleStolesFeeChange}
                  disabled={_.isEmpty(filteredStolesFees) || isStoleCompleted()}
                >
                  {filteredStolesFees.map((fee) => (
                    <Option key={fee.id} value={fee.id}>
                      {_.get(fee, 'name')}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              {/* Fee Amount */}
              <Form.Item name="feeAmount" style={{ marginBottom: 0 }}>
                <Input
                  prefix={_.get(window, 'cdApp.organization.currency')}
                  disabled={true}
                ></Input>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        {/* Stole text - required */}
        <Form.Item
          name="stoleText"
          label={I18nService.getString('Stole text')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea rows={2} disabled={isStoleCompleted()} />
        </Form.Item>
        {/* Payment method */}
        <Form.Item label={I18nService.getString('Payment method')} required>
          <Row
            justify="space-between"
            align="bottom"
            gutter={8}
            style={{ marginBottom: 0 }}
          >
            <Col span={16}>
              <Form.Item
                name="paymentMethod"
                style={{ marginBottom: 0 }}
                initialValue={PaymentMethodTypes.PAIDBYCASH}
                rules={[
                  {
                    required: true,
                    message: I18nService.getString(
                      'Please select a payment method...'
                    ),
                  },
                ]}
              >
                <Select
                  placeholder={I18nService.getString('Select a payment method')}
                  style={{ width: '100%' }}
                  disabled={_.isEmpty(paymentMethods) || isStoleCompleted()}
                  allowClear
                >
                  {paymentMethods().map((paymentMethod) => (
                    <Option key={paymentMethod.id} value={paymentMethod.id}>
                      {_.get(paymentMethod, 'name')}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                name="paid"
                valuePropName="checked"
                style={{ marginBottom: 0 }}
                initialValue={true}
              >
                <Checkbox disabled={isStoleCompleted()}>
                  {I18nService.getString('Paid?')}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        {/* Comment */}
        <Form.Item name="comment" label={I18nService.getString('Note')}>
          <TextArea
            rows={2}
            placeholder={I18nService.getString('Additional information...')}
            disabled={isStoleCompleted()}
          />
        </Form.Item>
      </Form>
    </Card>
  );
};

export default CreateStole;
