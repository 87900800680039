import { Col, Divider, Form, Menu, MenuProps, Row, Space } from 'antd';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FormInstance } from 'antd/lib';

import { I18nService } from '../../../../services/I18nService';
import { CdRoundIconButton } from '../../../../shared/components/cd-buttons/CdRoundIconButton';
import {
  CdCopyIcon,
  CdExclamationTriangleIcon,
} from '../../../../shared/components/Icons';
import { useFlag } from '../../../../shared/hooks/useFlag';
import { CdTooltip } from '../../../../shared/components/cd-tooltip/CdTooltip';

import { FormWatchedText } from './FormWatchedText';
import { IfFormHasError } from './IfFormHasError';

export type MenuItem = Required<MenuProps>['items'][number];

const StyledDivider = styled(Divider)`
    margin: 0px;
`;

const PaddedMenu = styled(Menu)`
  padding: 16px 16px 16px 0;
  max-height: 75vh;
  overflow-y: auto;
  height: 100%;

  .ant-menu-item {
    min-height: 40px;
    display: inline-block;
    height: unset !important;
    white-space: normal;
    line-height: 1.5;
    padding: 4px 24px;
  }
`;

const Content = styled.div`
    padding: 16px;
    max-height: 75vh;
    overflow-y: auto;
`;

type VerticalTabMenuProps = {
  form: FormInstance;
  children: React.ReactNode;
  activeItem?: string;
  setActiveItem?: (key: string) => void;
  copyBookingOption?: (index: number) => void;
};

export const VerticalTabMenu = ({
  form,
  activeItem,
  setActiveItem,
  children,

  copyBookingOption,
}: VerticalTabMenuProps) => {
  const bookingOptions = Form.useWatch('bookingOptions', form);
  const bookingEnquiriesV2 = useFlag('booking_enquiries_v2');

  const menuItems: MenuItem[] = useMemo(
    () => [
      {
        label: I18nService.getString('Booking page details'),
        key: 'details',
      },
      {
        type: 'group',
        label: I18nService.getString('Options', null, 'Booking page options'),
        children: [
          ...(bookingOptions ?? []).map((option, index) => ({
            key: 'option-' + index,
            label: (
              <Row justify="space-between" wrap={false}>
                <Col flex={1} style={{ alignContent: 'center' }}>
                  <Space align="start">
                    <FormWatchedText
                      name={['bookingOptions', index, 'name']}
                      form={form}
                      defaultValue={
                        option.name || I18nService.getString('Unnamed option')
                      }
                    />
                  </Space>
                </Col>
                {bookingEnquiriesV2 && (
                  <IfFormHasError form={form} name={['bookingOptions', index]}>
                    <div
                      style={{
                        textAlign: 'center',
                        alignContent: 'center',
                        width: 32,
                        height: 32,
                      }}
                    >
                      <CdTooltip
                        title={I18nService.getString(
                          'Not all fields on the booking option are filled in properly'
                        )}
                      >
                        <CdExclamationTriangleIcon type="danger" />
                      </CdTooltip>
                    </div>
                  </IfFormHasError>
                )}
                {bookingEnquiriesV2 && (
                  <Col span={4}>
                    <CdRoundIconButton
                      icon={<CdCopyIcon type="secondary" />}
                      onClick={(event) => {
                        // Prevent from the onClick to propagate to the page to be copied to it switches to that instead of the new
                        event.stopPropagation();
                        copyBookingOption(index);
                      }}
                      tooltip={I18nService.getString(
                        'Copy option',
                        null,
                        'Copy booking option'
                      )}
                    />
                  </Col>
                )}
              </Row>
            ),
          })),
          {
            key: 'add-option',
            label: '+ ' + I18nService.getString('Add option'),
          },
        ],
      },
    ],
    [bookingEnquiriesV2, bookingOptions, copyBookingOption, form]
  );

  return (
    <>
      <StyledDivider />
      <Row>
        <Col span={8}>
          <PaddedMenu
            selectedKeys={activeItem ? [activeItem] : []}
            onClick={(e) => setActiveItem(e.key)}
            mode="inline"
            items={menuItems}
          />
        </Col>
        <Col span={16}>
          <Content>{children}</Content>
        </Col>
      </Row>
      <StyledDivider />
    </>
  );
};
