/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompletePaymentResponseBody } from '../models/CompletePaymentResponseBody';
import type { EmptyObject } from '../models/EmptyObject';
import type { GetSummaryResponseBody } from '../models/GetSummaryResponseBody';
import type { Initiate_paymentResponseBody } from '../models/Initiate_paymentResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PaymentService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * CreatePayment
     * Create Payment
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPayments({
        token,
        requestBody,
    }: {
        token: any,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payments',
            query: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetPaymentConfiguration
     * Get Payment Configuration
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPaymentsConfig(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payments/config',
        });
    }
    /**
     * Initiate_payment
     * Initiate payment
     * @returns Initiate_paymentResponseBody
     * @throws ApiError
     */
    public postPaymentsInitiatePayment({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<Initiate_paymentResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payments/initiate-payment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetAllPaymentsInForm
     * Get All Payments In Form
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPaymentsSubmissionsData({
        formId,
        contributionId,
    }: {
        /**
         * The id of the form that payment is configured
         */
        formId: any,
        /**
         * The id of the donation project added to a form
         */
        contributionId: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payments/submissions/data',
            query: {
                'formId': formId,
                'contributionId': contributionId,
            },
        });
    }
    /**
     * GetSummary
     * Get Payment Summary
     * @returns GetSummaryResponseBody
     * @throws ApiError
     */
    public getPaymentsSummary(): CancelablePromise<GetSummaryResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payments/summary',
        });
    }
    /**
     * CreateTicketPayment
     * Create Ticket Payments
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPaymentsTickets({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payments/tickets',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * CompletePayment
     * @returns EmptyObject
     * @throws ApiError
     */
    public postPaymentsCompletePayment({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payments/{id}/complete-payment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetPaymentsInFormSubmission
     * Get Form Submissions Payment
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPaymentsData(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payments/{submissionId}/data',
        });
    }
    /**
     * CompletePayment
     * @returns CompletePaymentResponseBody
     * @throws ApiError
     */
    public postV2PaymentsCompletePayment({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<CompletePaymentResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/payments/complete-payment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
