import { put, select, all, call } from 'redux-saga/effects';
import type { SagaIterator } from 'redux-saga';
import { destroyModal } from 'redux-saga-modal';
import { each, map, isEmpty, reduce } from 'lodash';

import { CdAction } from '../../redux/utils';
import UserRolesService from '../services/user-roles.service';
import MODAL_TYPES from '../redux/modal-types';
import AuthenticationService from '../../services/AuthenticationService';
import {
  SaveUserRolesPayload,
  SaveUserRolesSuccess,
  FetchUsers,
  SaveUserRolesSuccessPayload,
  ResetUserPrivileges,
  FetchUserRolesSuccess,
  FetchUserRoles,
} from '../redux/actions';
import { handleSuccessMessage } from '../../shared/utils';
import { I18nService } from '../../services/I18nService';
import { getCurrentUserId } from '../../config/store/Selector';
import { RoleInterface } from '../../organization/types/organization-role.types';

export function* getUserRoles(
  action: CdAction<{ userId: number }>
): SagaIterator {
  const userId = action.payload.userId;
  // @ts-ignore:
  const [oldRoles, organizationRoles, churchRoles] = yield all([
    call(UserRolesService.getOldRoles, action.payload),
    call(UserRolesService.getUserOrganizationRoles, userId),
    call(UserRolesService.getUserChurchRoles, userId),
  ]);
  yield put(
    FetchUserRolesSuccess({ churchRoles, oldRoles, organizationRoles })
  );
}

export function* saveUserRoles(
  action: CdAction<SaveUserRolesPayload>
): SagaIterator {
  const userId = action.payload.userId;
  const oldRoles = action.payload.data.oldRoles;
  const churchRoles = action.payload.data.churchRoles;
  const organizationRoles = action.payload.data.organizationRoles;
  const organizationRolesPayload = reduce(
    organizationRoles,
    (accumulator: Partial<RoleInterface>[], roleEnabled, roleId) => {
      if (roleEnabled) accumulator.push({ id: roleId });
      return accumulator;
    },
    []
  );
  /*
      churchRoles are of the following format:
      {
        roleId: [churchIdA, churchIdB]
        roleId2: [churchIdA, churchIdB]
      }
      Convert to
      [
        {
          churchId: number;
          roles: {
            id: string;
          }[];
        }
      ]
    */
  const churchPayload = {};
  each(churchRoles, (churchIds, roleId) => {
    each(churchIds, (churchId) => {
      if (!churchPayload[churchId]) {
        churchPayload[churchId] = {
          churchId,
          roles: [],
        };
      }
      churchPayload[churchId].roles.push({ id: roleId });
    });
  });
  const churchPayloadAsArray = map(churchPayload, (p) => p);
  const saveOperations: any = [];
  // Only save the old roles, if user is not assigned to the new roles.
  if (isEmpty(churchPayloadAsArray) && !isEmpty(oldRoles)) {
    saveOperations.push(
      call(UserRolesService.saveUserOldRoles, userId, oldRoles)
    );
  }

  saveOperations.push(
    call(UserRolesService.saveUserChurchRoles, userId, churchPayloadAsArray)
  );
  saveOperations.push(
    call(
      UserRolesService.saveUserOrganizationRoles,
      userId,
      organizationRolesPayload
    )
  );

  yield all(saveOperations);
  yield put(
    SaveUserRolesSuccess({
      newUser: action.payload.newUser,
      name: action.payload.name,
      userId,
    })
  );
  // Close user role modal
  yield put(destroyModal(MODAL_TYPES.USER_ROLES));
  if (action.payload.mode === 'inline') {
    yield put(FetchUserRoles({ userId }));
  } else {
    yield put(FetchUsers());
  }
}

export function* showUserRoleSavedSuccess(
  action: CdAction<SaveUserRolesSuccessPayload>
) {
  let message: string;
  const name = action.payload.name;
  const userIdBeingUpdated = action.payload.userId;
  if (action.payload.newUser) {
    message = I18nService.getString('{{ name }} has been invited', { name });
  } else {
    if (name.length < 1) {
      message = I18nService.getString('User permissions saved.');
    } else {
      message = I18nService.getString(
        'Permissions for {{ name }} has been saved.',
        { name }
      );
    }
  }
  yield call(handleSuccessMessage, message);
  const currentUserId = yield select(getCurrentUserId);
  if (currentUserId === userIdBeingUpdated) {
    yield all([
      call(AuthenticationService.getCurrentUser),
      put(ResetUserPrivileges()),
    ]);
  }
}
