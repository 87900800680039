import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Button, Col, Divider, Row, Space, Spin, Typography } from 'antd';
import { css } from 'styled-components';
import moment from 'moment';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { find, isEqual, isNull } from 'lodash';

import { FieldSection } from '../FieldSection';
import { DateInputValue } from '../date-input/DateInput';
import { EventAccessContext, EventLoadingState } from '../../hooks/use-event';
import { useRotaAndIntentionState } from '../../hooks/use-rota-and-intention-state';

import { openCreateIntentionModal } from './CreateIntentionModal';
import { RotaDutiesEditDropDown } from './RotaDutiesEditDropDown';
import { RotaDetails, openAddUserToRotaModal } from './AddUserToRotaModal';
import { openAssignIntentionModal } from './AssignIntentionModal';
import { openAddRotaDutyModal } from './AddRotaDutyModal';

import AuthorizationService from '@/react/services/AuthorizationService';
import { OrganizationUsersIndexed } from '@/react/organization/store/organization';
import { IntentionPriorityTypes } from '@/react/intention/models/intention';
import {
  RotaTableInitialRRuleValue,
  RotaTableState,
} from '@/react/calendar/store/events/eventRotaDuties';
import { Rota } from '@/react/calendar/models/calendar';
import CdTable, { ColumnType } from '@/react/shared/components/cd-table';
import {
  CdEditIcon,
  CdPlus,
  CdTableIcon,
  CdAngleDown,
} from '@/react/shared/components/Icons';
import { I18nService } from '@/react/services/I18nService';
import { orderUsers } from '@/react/organization/services/organization.service';
import { CdUserAvatar } from '@/react/user/components/cd-user-avatar/CdUserAvatar';

const CellContainer = styled.div`
  height: 128px;
  width: 200px;

  &:hover .editBox {
    opacity: 0.8;
  }
`;
const EmptyCellContainer = styled.div`
  height: 120px;
  width: 200px;
  position: relative;
`;
const CellHoverIntractionContainer = styled.div<{ $disabled: boolean }>`
  height: calc(100% - 16px);
  width: calc(100% - 16px);
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props) =>
    props.$disabled ? '1px solid #d7d7dab5' : `1px solid #008db6`};
  color: #008db6;
  position: absolute;
  background: white;
  opacity: 0;
  transition: 0.5s all ease-in-out;
`;

const TopBoxForCommingRepeatedEvents = styled.div<{ $numberOfColumns: number }>`
  height: 120px;
  width: ${(props) => {
    const value =
      props.$numberOfColumns * 200 + (props.$numberOfColumns - 1) * 16;
    return `${value}px`;
  }};
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  text-align: center;
  align-items: center;
  border: 1px solid #d7d7dab5;
  color: #252626;
  position: absolute;
  background: #edf1f29f;
  opacity: 0.8;
  z-index: 99;
  /* transition: 0.5s all ease-in-out; */
`;
const rowStyles = css`
  td {
    vertical-align: top;
    &:hover {
      background: none !important;
    }
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
  }
`;

const IntentionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 128px;
  min-width: 200px;
  max-width: 450px;
  &:hover .intentionEditBox {
    opacity: 0.8;
  }
`;
const ShowMoreButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
export interface EventsRotaTableValue {
  rotas: Rota[];
  shiftsAndIntentions?: Array<{
    calendarId?: number;
    startDate?: string | Date;
    endDate?: string | Date;
    rrule?: string;
    intentions?: Array<{
      id: string;
      reference: string;
      text: string;
      priority: IntentionPriorityTypes | string;
      intentionType: string;
      founder: string;
      access: { canUnassign: boolean };
      assignedBy: string;
      isRemoved?: boolean;
    }>;
    shifts?: Shift[];
  }>;
}

interface Shift {
  note?: string;
  taskId: number;
  users?: Array<{ id: number }>;
  access: { canEdit: boolean; canAssignMySelf: boolean };
}

export const EventsRotaTable = (props: {
  value?: EventsRotaTableValue;
  onChange?: (value: EventsRotaTableValue) => void;
  calendarId: number;
  eventTitle: string;
  eventChurchIds: number[];
  eventDate: DateInputValue;
  categoryIds: number[];
  onSave?: ({ notify }: { notify: boolean }) => Promise<void>;
  saveProcessing: EventLoadingState;
  isViewingPreviousEvent: boolean;
}) => {
  const {
    eventTitle,
    eventChurchIds,
    eventDate,
    onChange,
    value,
    categoryIds,
    onSave,
    saveProcessing,
    isViewingPreviousEvent,
  } = props;
  const rotaTableState = useRecoilValue(RotaTableState);
  const { setInitialRotaTableState, updateRotaTableStateByEventDateChange } =
    useRotaAndIntentionState();
  const [initialRRuleValue, setInitialRRuleValue] = useRecoilState(
    RotaTableInitialRRuleValue
  );
  const { eventAccess } = useContext(EventAccessContext);
  const hasIntentionPackage = AuthorizationService.hasPackage('intentions');
  const [page, setPage] = useState(1);
  const isEditingDisabled = props?.calendarId
    ? !eventAccess?.canEdit
    : !eventAccess?.canCreate;

  useEffect(() => {
    // Resync local rota table state with the form state if they don't match.
    // rotaTableState is the primary source of truth.
    if (!isEqual(rotaTableState, value) && !isNull(rotaTableState)) {
      onChange(rotaTableState);
    }
  }, [rotaTableState, onChange, value]);

  useEffect(() => {
    // Set the inital value
    if (isNull(rotaTableState)) {
      setInitialRotaTableState(value);
      setInitialRRuleValue(value?.shiftsAndIntentions?.[0]?.rrule);
    }
  }, [rotaTableState, setInitialRRuleValue, setInitialRotaTableState, value]);

  const tableData = useCallback(() => {
    const data = value?.shiftsAndIntentions?.slice(0, page * 5);
    return data;
  }, [page, value?.shiftsAndIntentions]);

  const data = tableData();

  const columns = useMemo<
    ColumnType<EventsRotaTableValue['shiftsAndIntentions'][number]>[]
  >(
    () =>
      RotasTableColumn({
        rotaDuties: []
          .concat(value?.rotas)
          .sort((a, b) => a.title.localeCompare(b.title)),
        eventDate,
        eventTitle,
        eventChurchIds,
        hasIntentionPackage,
        initialRRuleValue,
        categoryIds: categoryIds,
        onSave,
        saveProcessing,
        access: eventAccess,
        isViewingPreviousEvent,
      }),
    [
      value?.rotas,
      eventDate,
      eventTitle,
      eventChurchIds,
      hasIntentionPackage,
      initialRRuleValue,
      categoryIds,
      onSave,
      saveProcessing,
      eventAccess,
      isViewingPreviousEvent,
    ]
  );

  // Update Rota Table State when event date changes.
  useEffect(() => {
    updateRotaTableStateByEventDateChange(props.calendarId, eventDate);
  }, [eventDate, props.calendarId, updateRotaTableStateByEventDateChange]);

  return (
    <FieldSection>
      <Space direction="vertical">
        <Space direction="vertical">
          <Typography.Text strong>
            {I18nService.getString('Rota duties')}
          </Typography.Text>
          <Button
            type="primary"
            onClick={() =>
              openAddRotaDutyModal({
                isSeriesEvent: eventDate.rrule ? true : false,
              })
            }
            disabled={
              isEditingDisabled ||
              !eventAccess?.fields.rotas.canEdit ||
              !eventAccess?.fields?.users.canEdit ||
              isViewingPreviousEvent
            }
          >
            {I18nService.getString('+ Add rota duty')}
          </Button>
          <Typography.Text type="secondary">
            {I18nService.getString(
              'Everyone you add to the rota will be notified once you click “Save & notify”. They will also receive a reminder per email 3 days before the event.'
            )}
          </Typography.Text>
        </Space>
        <Space direction="vertical">
          {(hasIntentionPackage || value.rotas?.length > 0) && value && (
            <Row gutter={[16, 16]}>
              <Col span={24} style={{ paddingTop: '16px' }}>
                <CdTable<any>
                  rowStyles={rowStyles}
                  noStyle
                  headingText=""
                  emptyStateProps={{
                    title: I18nService.getString('No rotas'),
                    EmptyStateIcon: <CdTableIcon />,
                  }}
                  columns={columns}
                  rowKey="calendarId"
                  data={{
                    items:
                      eventDate.rrule !== initialRRuleValue &&
                      !isViewingPreviousEvent
                        ? [data?.[0], { calendarId: 'rowKeyNeededForCdTable' }]
                        : data,
                    total:
                      eventDate.rrule !== initialRRuleValue ? 2 : data?.length,
                  }}
                  onTableChange={() => {}}
                  isLoading={false}
                />
              </Col>
            </Row>
          )}
          {(hasIntentionPackage || value.rotas?.length > 0) &&
            value?.shiftsAndIntentions?.length > 5 * page &&
            page < 4 && (
              <ShowMoreButtonContainer>
                <Button onClick={() => setPage(page + 1)}>
                  {I18nService.getString('Show more')}
                </Button>
              </ShowMoreButtonContainer>
            )}
        </Space>
      </Space>
    </FieldSection>
  );
};

const RotasTableColumn = ({
  rotaDuties,
  eventTitle,
  eventDate,
  eventChurchIds,
  hasIntentionPackage,
  initialRRuleValue,
  categoryIds,
  onSave,
  saveProcessing,
  access,
  isViewingPreviousEvent,
}: {
  rotaDuties: Rota[];
  eventTitle: string;
  eventDate: DateInputValue;
  eventChurchIds: number[];
  hasIntentionPackage: boolean;
  initialRRuleValue: string | undefined;
  categoryIds: number[];
  onSave?: ({
    notify,
    saveAndContinue,
  }: {
    notify: boolean;
    saveAndContinue: boolean;
  }) => Promise<void>;
  saveProcessing: EventLoadingState;
  access: any;
  isViewingPreviousEvent: boolean;
}): ColumnType<EventsRotaTableValue['shiftsAndIntentions'][number]>[] => {
  const { canEdit, canCreate } = access;
  const dateAndIntentionsColumn: ColumnType<
    EventsRotaTableValue['shiftsAndIntentions'][number]
  >[] = [
    {
      title: I18nService.getString('Event date'),
      dataIndex: 'startDate',
      key: 'startDate',
      width: 200,
      render: (_, record, index) => {
        if (eventDate.rrule !== initialRRuleValue && index > 0) {
          return (
            <>
              <TopBoxForCommingRepeatedEvents
                $numberOfColumns={
                  hasIntentionPackage
                    ? rotaDuties.length + 2
                    : rotaDuties.length + 1
                }
              >
                {I18nService.getString(
                  'Save the event to see all events in the series.'
                )}
                <Button
                  type="primary"
                  onClick={async () =>
                    saveProcessing === EventLoadingState.NOT_LOADING &&
                    (await onSave({ notify: false, saveAndContinue: true }))
                  }
                  loading={saveProcessing === EventLoadingState.SAVING}
                  disabled={!(canEdit || canCreate) || isViewingPreviousEvent}
                >
                  {I18nService.getString('Save & continue')}
                </Button>
              </TopBoxForCommingRepeatedEvents>
              <EmptyCellContainer />
            </>
          );
        } else {
          return (
            <span>
              {eventDate.allDay
                ? moment(record?.startDate).format('ll')
                : moment(record?.startDate).format('lll')}
            </span>
          );
        }
      },
    },
  ];
  const intentionsColumn: ColumnType<
    EventsRotaTableValue['shiftsAndIntentions'][number]
  >[] = [
    {
      title: I18nService.getString('Intentions'),
      dataIndex: 'intentions',
      key: 'intentions',
      width: 200,

      render: (_, record, index) => {
        const isEditingDisabled = record?.calendarId
          ? !access?.canEdit
          : !access?.canCreate;
        if (eventDate.rrule !== initialRRuleValue && index > 0) {
          return <EmptyCellContainer />;
        } else {
          return (
            <IntentionsContainer>
              <CellHoverIntractionContainer
                className="intentionEditBox"
                $disabled={
                  isEditingDisabled || !access?.fields.intentions.canEdit
                }
              >
                <Space direction="vertical">
                  <Button
                    icon={<CdPlus />}
                    onClick={() =>
                      openAssignIntentionModal({
                        eventId: record.calendarId,
                        eventStartDate: moment(record.startDate),
                        eventTitle,
                        churchIds: eventChurchIds,
                        categoryIds,
                      })
                    }
                    disabled={
                      isEditingDisabled ||
                      !access?.fields.intentions.canEdit ||
                      isViewingPreviousEvent
                    }
                  >
                    {I18nService.getString('Assign')}
                  </Button>
                  <Button
                    icon={<CdPlus />}
                    disabled={
                      isEditingDisabled ||
                      !access?.fields.intentions.canEdit ||
                      isViewingPreviousEvent
                    }
                    onClick={() =>
                      openCreateIntentionModal({
                        churchIds: eventChurchIds,
                        eventId: record.calendarId,
                      })
                    }
                  >
                    {I18nService.getString('Create')}
                  </Button>
                </Space>
              </CellHoverIntractionContainer>
              {record?.intentions?.map(
                (intention) =>
                  !intention.isRemoved && (
                    <Typography.Text ellipsis key={intention.id}>
                      <Space>
                        {intention.reference}
                        {intention.priority ===
                          IntentionPriorityTypes.PERSOLVIERT ||
                        intention.priority === 'Erstintention'
                          ? I18nService.getString('Primary')
                          : I18nService.getString('Forwarded')}
                        {intention.text}
                      </Space>
                    </Typography.Text>
                  )
              )}
            </IntentionsContainer>
          );
        }
      },
    },
  ];

  const RotasColumn: ColumnType<
    EventsRotaTableValue['shiftsAndIntentions'][number]
  >[] = rotaDuties?.map((rota) => ({
    title: rota.required
      ? `${rota.title} (${I18nService.getString(
          '{{numberOfRequired}} required',
          { numberOfRequired: rota.required }
        )})`
      : rota.title,
    dataIndex: `${rota.taskId}`,
    key: `${rota.taskId}`,
    width: 200,

    render: (_, data, index) => {
      const calendarId = data.calendarId;
      const taskId = rota.taskId;
      const required = rota.required;
      // record means the shift record for the current rota duty
      const record = find(data.shifts, { taskId });
      const otherShifts = data.shifts
        ?.filter((shift) => shift.taskId !== taskId)
        .map((shift) => ({
          ...shift,
          name: rotaDuties.find((rota) => rota.taskId === shift.taskId)?.title,
        }));

      const countUsersAssigned = record?.users?.length || 0;
      const missingUsersNumber = required ? required - countUsersAssigned : 0;
      const extraAssignedUsers = required ? countUsersAssigned - required : 0;
      let limitedUserNumberToShow = 5;
      if (missingUsersNumber > 0 || extraAssignedUsers > 0) {
        limitedUserNumberToShow = limitedUserNumberToShow - 1;
      }
      if (record?.note) {
        limitedUserNumberToShow = limitedUserNumberToShow - 2;
      }

      const isEditingDisabled = calendarId
        ? !access?.canEdit
        : !access?.canCreate;

      if (eventDate.rrule !== initialRRuleValue && index > 0) {
        return <EmptyCellContainer />;
      } else {
        if (record) {
          return (
            <CellContainer>
              <CellHoverIntractionContainer
                className="editBox"
                $disabled={
                  isEditingDisabled ||
                  !access?.fields.rotas.canEdit ||
                  !access?.fields.users.canEdit
                }
              >
                <Button
                  onClick={() =>
                    openAddUserToRotaModal({
                      rotaDetails: {
                        calendarId,
                        taskId,
                        date: {
                          startDate: moment(data.startDate),
                          endDate: moment(data.endDate),
                          rrule: data.rrule,
                        },
                        note: record?.note,
                        users: record?.users,
                        rotaDutyName: rota.title,
                        required,
                      } as RotaDetails,
                      canEditUsers:
                        !isEditingDisabled && access?.fields.users.canEdit,
                      initialRrule: initialRRuleValue,
                      disableOkButton:
                        isEditingDisabled ||
                        !access?.fields.rotas.canEdit ||
                        !access?.fields.users.canEdit ||
                        isViewingPreviousEvent,
                      defaultParishes: eventChurchIds,
                      otherShifts: otherShifts,
                    })
                  }
                  disabled={
                    (isEditingDisabled ||
                      !access?.fields.rotas.canEdit ||
                      !access?.fields.users.canEdit ||
                      isViewingPreviousEvent) &&
                    countUsersAssigned === 0
                  }
                >
                  {countUsersAssigned > 0 ? (
                    <Space>
                      <CdEditIcon />
                      {isEditingDisabled ||
                      !access?.fields.rotas.canEdit ||
                      !access?.fields.users.canEdit ||
                      isViewingPreviousEvent
                        ? I18nService.getString('View')
                        : I18nService.getString('Edit')}
                    </Space>
                  ) : (
                    <Space>
                      <CdPlus />
                      {I18nService.getString('Assign')}
                    </Space>
                  )}
                </Button>
              </CellHoverIntractionContainer>

              <div style={{ height: '100%' }}>
                {rota.required > countUsersAssigned && (
                  <span style={{ color: 'red' }}>
                    {I18nService.getString('{{count}}x missing', {
                      count: missingUsersNumber,
                    })}
                  </span>
                )}
                {rota.required && rota.required < countUsersAssigned && (
                  <span style={{ color: 'red' }}>
                    {I18nService.getString('{{count}}x overassigned', {
                      count: extraAssignedUsers,
                    })}
                  </span>
                )}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Suspense fallback={<Spin />}>
                    <BookedUserNames
                      userIds={record?.users
                        ?.slice(0, limitedUserNumberToShow)
                        .map((user) => user.id)}
                    />
                  </Suspense>
                  {countUsersAssigned > limitedUserNumberToShow &&
                    I18nService.getString('+{{count}} more users.', {
                      count: countUsersAssigned - limitedUserNumberToShow,
                    })}
                  {record?.note && (
                    <>
                      <Divider
                        style={{ marginTop: '8px', marginBottom: '8px' }}
                      />
                      <Typography.Paragraph ellipsis={{ rows: 1 }}>
                        {record?.note}
                      </Typography.Paragraph>
                    </>
                  )}
                </div>
              </div>
            </CellContainer>
          );
        } else {
          return (
            <div
              style={{
                height: '128px',
                width: '200px',
                backgroundColor: 'white',
              }}
            ></div>
          );
        }
      }
    },
    filterDropdown: () => (
      <RotaDutiesEditDropDown
        rota={rota}
        isSeriesEvent={eventDate.rrule ? true : false}
        disabled={
          !access?.fields.rotas.canEdit ||
          !access?.fields.users.canEdit ||
          isViewingPreviousEvent
        }
      />
    ),
    filterIcon: () => <CdAngleDown />,
  }));

  return hasIntentionPackage
    ? dateAndIntentionsColumn.concat(intentionsColumn).concat(RotasColumn)
    : dateAndIntentionsColumn.concat(RotasColumn);
};

const BookedUserNames = (props: {
  userIds: number[];
}) => {
  const usersIndexedLoadable = useRecoilValueLoadable(OrganizationUsersIndexed);
  const usersIndexed =
    usersIndexedLoadable.state === 'hasValue'
      ? usersIndexedLoadable.contents
      : [];
  const userAssignedList = orderUsers(
    props.userIds?.reduce((accumulator, userId) => {
      usersIndexed[userId] && accumulator.push(usersIndexed[userId]);
      return accumulator;
    }, [])
  );

  return (
    <>
      {userAssignedList.map((user) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} key={user.id}>
          <CdUserAvatar
            name={user.name}
            key={user.id}
            blocked={user.status === 'blocked'}
            noIcon={true}
          />
        </div>
      ))}
    </>
  );
};
