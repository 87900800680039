/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { GetAllTaxonomiesResponseBody } from '../models/GetAllTaxonomiesResponseBody';
import type { PostTaxonomiesAliasCheckResponseBody } from '../models/PostTaxonomiesAliasCheckResponseBody';
import type { PostTaxonomyAliasCheckRequestBody } from '../models/PostTaxonomyAliasCheckRequestBody';
import type { PutTaxonomyAttendanceRequestBody } from '../models/PutTaxonomyAttendanceRequestBody';
import type { TaxonomyInputDTO } from '../models/TaxonomyInputDTO';
import type { TaxonomyInputDTO2 } from '../models/TaxonomyInputDTO2';
import type { TaxonomyOutputDTO } from '../models/TaxonomyOutputDTO';
import type { TaxonomyOutputDTO2 } from '../models/TaxonomyOutputDTO2';
import type { TaxonomyOutputDTO22 } from '../models/TaxonomyOutputDTO22';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TaxonomiesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create a new taxonomy
     * @returns TaxonomyOutputDTO
     * @throws ApiError
     */
    public postTaxonomies({
        requestBody,
    }: {
        requestBody?: TaxonomyInputDTO,
    }): CancelablePromise<TaxonomyOutputDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/taxonomies',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all taxonomies for an organization
     * @returns GetAllTaxonomiesResponseBody
     * @throws ApiError
     */
    public getTaxonomies({
        type,
        usage,
    }: {
        type?: ('absence' | 'event' | 'blog' | 'availability' | Array<'absence' | 'event' | 'blog' | 'availability'>),
        usage?: 'calendar',
    }): CancelablePromise<GetAllTaxonomiesResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/taxonomies',
            query: {
                'type': type,
                'usage': usage,
            },
        });
    }
    /**
     * Check if alias is unique
     * @returns PostTaxonomiesAliasCheckResponseBody
     * @throws ApiError
     */
    public postTaxonomiesAliasCheck({
        requestBody,
    }: {
        requestBody?: PostTaxonomyAliasCheckRequestBody,
    }): CancelablePromise<PostTaxonomiesAliasCheckResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/taxonomies/alias/check',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Track attendance on the given taxonomies
     * @returns EmptyObject
     * @throws ApiError
     */
    public putTaxonomiesAttendance({
        requestBody,
    }: {
        requestBody?: PutTaxonomyAttendanceRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/taxonomies/attendance',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all taxonomies for an organization
     * @throws ApiError
     */
    public getTaxonomiesPortalTaxonomies({
        type,
        portalId,
    }: {
        type: ('event' | 'blog' | Array<'event' | 'blog'>),
        portalId: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/taxonomies/portal-taxonomies',
            query: {
                'type': type,
                'portalId': portalId,
            },
        });
    }
    /**
     * Delete a taxonomy
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteTaxonomies({
        id,
        replacementId,
    }: {
        id: number,
        replacementId?: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/taxonomies/{id}',
            path: {
                'id': id,
            },
            query: {
                'replacementId': replacementId,
            },
        });
    }
    /**
     * Retrieve a single taxonomy
     * @returns TaxonomyOutputDTO2
     * @throws ApiError
     */
    public getTaxonomies1({
        id,
    }: {
        id: number,
    }): CancelablePromise<TaxonomyOutputDTO2> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/taxonomies/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Update a taxonomy
     * @returns TaxonomyOutputDTO22
     * @throws ApiError
     */
    public putTaxonomies({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: TaxonomyInputDTO2,
    }): CancelablePromise<TaxonomyOutputDTO22> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/taxonomies/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
