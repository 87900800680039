import React from 'react';
import { Form } from 'antd';

import { useGetPartnersInfo } from '../../hooks/useCategories';

import { CdSelect } from '@/react/shared/components/cd-select/CdSelect';
import { I18nService } from '@/react/services/I18nService';
import { CdVerticalSpace } from '@/react/shared/components/cd-vertical-space/CdVerticalSpace';

export const MappingPartnerCategories = () => {
  const { partners } = useGetPartnersInfo();
  return (
    <CdVerticalSpace>
      {partners?.map((partner) => (
        <Form.Item
          key={partner.id}
          name={['partners', `${partner.id}`]}
          label={partner.name}
        >
          <CdSelect
            placeholder={I18nService.getString('Choose a mapping category')}
            options={partner.categories.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
          />
        </Form.Item>
      ))}
    </CdVerticalSpace>
  );
};
