import React, { Suspense, useState } from 'react';
import { ColumnType } from 'antd/es/table';
import { Button, Col, Form, Row, Spin, Table } from 'antd';

import CdSimpleTable from '@/react/shared/components/cd-simple-table/CdSimpleTable';
import { RevisionList } from '@/react/calendar/event-details/types/revisions.type';
import { CdVersionHistory } from '@/react/shared/components/Icons';
import { I18nService } from '@/react/services/I18nService';
import { DisplayDateSimple } from '@/react/shared/components/FormattedDate';
import { CdUserAvatarWithState } from '@/react/user/components/cd-user-avatar/CdUserAvatar';
import { EventHistory } from '@/react/calendar/store/events/event';
import { MINIMUM_COLUMN_WIDTH } from '@/react/calendar/event-details/constants';
import { FieldSection } from '@/react/calendar/event-details/components/FieldSection';
export interface VersionHistoryProps {
  eventId: number;
  setEventFormWithDataBasedOnVersionHistory: (args: {
    revisionId: number;
  }) => void;
}
const versionHistoryTableColumn: (
  setLoading?: (loading: boolean) => void,
  setEventFormWithDataBasedOnVersionHistory?: (args: {
    revisionId: number;
  }) => void
) => ColumnType<RevisionList>[] = (
  setLoading,
  setEventFormWithDataBasedOnVersionHistory
) => [
  {
    title: I18nService.getString('Date'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 'auto',
    render: (date) => (
      <DisplayDateSimple
        date={date}
        allDay={false}
        showRelativeTime={false}
        dateFormat="ll"
      />
    ),
  },
  {
    title: I18nService.getString('Author'),
    dataIndex: 'revisionAuthorId',
    key: 'revisionAuthorId',
    width: 'auto',
    render: (revisionAuthorId) => (
      <CdUserAvatarWithState userId={revisionAuthorId} size="small" />
    ),
  },
  {
    title: '',
    dataIndex: 'button',
    key: 'action',
    width: 'auto',
    render: (_, record) =>
      record.isCurrentVersion ? (
        I18nService.getString('current version')
      ) : (
        <Button
          size="small"
          onClick={async () => {
            setLoading(true);
            await setEventFormWithDataBasedOnVersionHistory({
              revisionId: record.id,
            });
            setLoading(false);
          }}
        >
          {I18nService.getString('View')}
        </Button>
      ),
  },
];

/**
 * An antd table showing version history of an event. The table shows the event name, author and when the change happened.
 */
const VersionHistoryTable = (props: {
  eventId: number;
  setEventFormWithDataBasedOnVersionHistory: (args: {
    revisionId: number;
  }) => void;
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <Spin spinning={loading}>
      <CdSimpleTable<RevisionList>
        noStyle
        recoilSource={EventHistory({ eventId: props.eventId })}
        emptyStateProps={{
          title: I18nService.getString('There are no versions recorded.'),
          EmptyStateIcon: <CdVersionHistory />,
        }}
        columns={versionHistoryTableColumn(
          setLoading,
          props.setEventFormWithDataBasedOnVersionHistory
        )}
      />
    </Spin>
  );
};

export const VersionHistory = (props: VersionHistoryProps) => (
  <Row gutter={16}>
    <Col flex={`${MINIMUM_COLUMN_WIDTH + 16}px`}>
      <FieldSection>
        <Form.Item label={I18nService.getString('Version history')}>
          <Suspense
            fallback={
              <Table columns={versionHistoryTableColumn()} loading={true} />
            }
          >
            <VersionHistoryTable
              eventId={props.eventId}
              setEventFormWithDataBasedOnVersionHistory={
                props.setEventFormWithDataBasedOnVersionHistory
              }
            />
          </Suspense>
        </Form.Item>
      </FieldSection>
    </Col>
  </Row>
);
