import React, { useState } from 'react';
import { Badge, Space } from 'antd';

import { CdSelect } from '../../shared/components/cd-select/CdSelect';
import { languageMapping } from '../helpers/language';
import CdCountryFlag from '../../shared/components/cd-country-flag/CdCountryFlag';
import { sortString } from '../../shared/utils/sorter';
import cdApp from '../../config';
import { CdMasqueradeTag } from '../../shared/components/cd-buttons/CdMasqueradeTag';
import { I18nService } from '../../services/I18nService';

export const MasqueradeLanguageSelector = () => {
  const [selectedLanguage, _setSelectedLanguage] = useState<string>(
    cdApp.organization.locale.language
  );

  const setSelectedLanguage = (value) => {
    let language = cdApp.organization.locale.language;
    if (value) {
      language = value;
    }

    I18nService.setCurrentLanguage(language);
    _setSelectedLanguage(language);
  };

  return (
    <Space>
      <CdMasqueradeTag />
      <Badge
        title="You are masquerading as another language"
        count={
          cdApp.organization.locale.language !== selectedLanguage ? '⚠️' : 0
        }
        offset={[0, 30]}
      >
        <CdSelect
          placeholder="Select language"
          showSearch={false}
          filterSort={null}
          value={selectedLanguage}
          onChange={(value) => setSelectedLanguage(value)}
          options={Object.entries(languageMapping)
            .sort((a, b) => sortString(a[1].name, b[1].name))
            .map((language) => ({
              value: language[0],
              label: (
                <Space>
                  <CdCountryFlag countryIso2={language[1].countryIso2} />{' '}
                  {language[1].name}
                </Space>
              ),
            }))}
        />
      </Badge>
    </Space>
  );
};
