import { useState, useEffect } from 'react';

import { mainApi } from '../../../api';
import { handleError } from '../../../services/ErrorHandlingService';
import { I18nService } from '../../../services/I18nService';
import NotificationService from '../../../services/NotificationService';
import {
  ModalType,
  SetModalPropsType,
} from '../../../shared/components/cd-modal-new/CdModal2';
import cdApp from '../../../config';

import { useSingleEnquiry } from './use-enquiries';
import { useEnquiriesPage } from './use-enquiries-page';

export const useApproveDeclineEnquiryModal = (props: {
  setModalProps: SetModalPropsType;
  id: string;
  respond: 'accepted' | 'rejected';
}) => {
  const { setModalProps, respond, id } = props;
  const { refreshEnquiries } = useEnquiriesPage();
  const enquiryQuery = useSingleEnquiry(id);
  const enquiry = enquiryQuery.data;
  const [message, setMessage] = useState(
    props.respond === 'accepted'
      ? enquiry.taxonomy.config.emailTemplates?.confirmation
      : enquiry.taxonomy.config.emailTemplates?.rejection
  );

  const bookingLink = `${cdApp.references.bookingPage}${cdApp.organization.publicId}/${enquiry.taxonomy.alias}`;

  useEffect(() => {
    setModalProps({
      modalType: ModalType.MODAL,
      onOk: async () => {
        const response = await mainApi.put(
          `/calendar/booking/requests/${id}/respond`,
          {
            response: respond,
            comment: message,
          }
        );
        if (response.ok) {
          NotificationService.notifySuccess(
            respond === 'accepted'
              ? I18nService.getString('Enquiry approved')
              : I18nService.getString('Enquiry declined')
          );
          refreshEnquiries();
        } else {
          handleError(response);
        }
      },
    });
  }, [id, message, refreshEnquiries, respond, setModalProps]);

  return {
    isLoading: enquiryQuery.isLoading,
    bookingLink,
    enquiry,
    message,
    setMessage,
  };
};
