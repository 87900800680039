import React, { useCallback } from 'react';

import {
  CdLocationSelect,
  retrieveSelectedChurchesAndResource,
} from '../../shared/components/cd-location-select/CdLocationSelect';

interface ResourceLocationProps {
  value?: number[];
  onChange?: any;
}

export const WidgetResourceLocation = (props: ResourceLocationProps) => {
  const onChange = useCallback(
    (newValue) => {
      const resourceIds = retrieveSelectedChurchesAndResource(
        newValue
      ).resources.map((id) => parseInt(id, 10));
      props.onChange(resourceIds);
    },
    [props]
  );
  if (!props.onChange) return null;
  return (
    <CdLocationSelect
      value={props.value?.map((id) => `resource-${id}`)}
      hideNoParishBookedOption
      onChange={onChange}
      showSearch={true}
    />
  );
};
