import React from 'react';

import { I18nService } from '../../../services/I18nService';
import {
  CdSelect,
  CdSelectProps,
} from '../../../shared/components/cd-select/CdSelect';
import { useTaxonomies } from '../../hooks/use-taxonomies';
import { CategoryType } from '../../../shared/models/category';
import { sortString } from '../../../shared/utils/sorter';

interface BookingOptionsSelectProps
  extends Pick<
    CdSelectProps,
    'value' | 'onChange' | 'style' | 'disabled' | 'selectType'
  > {
  taxonomyIds: number[];
  placeholder?: string;
}

export const BookingOptionsSelect = ({
  taxonomyIds,
  ...props
}: BookingOptionsSelectProps) => {
  const { taxonomies } = useTaxonomies(CategoryType.availability);

  const bookingOptions =
    taxonomies
      ?.filter(
        (taxonomy) => !taxonomyIds?.length || taxonomyIds?.includes(taxonomy.id)
      )
      .map((taxonomy) => taxonomy.bookingOptions)
      .flat() || [];

  return (
    <CdSelect
      title={(count) =>
        count === 0
          ? I18nService.getString('All booking options')
          : I18nService.getPlural(
              count,
              '1 booking option',
              '{{ $count }} booking options',
              { $count: count }
            )
      }
      options={bookingOptions
        .map((option) => ({
          value: option.id,
          label: option.name,
        }))
        .sort((a, b) => sortString(a.label, b.label))}
      mode="multiple"
      maxTagCount="responsive"
      placeholder={I18nService.getString(
        'Choose an option',
        null,
        'Booking option'
      )}
      emptyText={I18nService.getString(
        'Select a category to view available booking options'
      )}
      disabled={bookingOptions.length === 0}
      // filterSort disabled to have same order as in booking page
      filterSort={null}
      {...props}
    />
  );
};
