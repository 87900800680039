import React, { Suspense, useCallback, useState } from 'react';
import styled from 'styled-components';
import { Button, Drawer, Form, Layout } from 'antd';
import { compose } from 'redux';
import { uniqBy, get } from 'lodash';
import moment from 'moment';

import ContentItem from './ContentItem';
import { ContentBlockProps, ContentTypeProps, SORT } from './types';

import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import { I18nService } from '@/react/services/I18nService';
import { CdPlus } from '@/react/shared/components/Icons';
import useOrganizations from '@/react/organization/hooks/useOrganizations';
import { useContentBlock } from '@/react/people/hooks/useContentBlock';

const { Content } = Layout;

const ContentBlockDrawer = styled(Drawer)`
  &&&& {
    .ant-drawer-body {
      padding: 0px;
      display: flex;
    }
    .ant-layout {
      .ant-layout-sider {
        padding: 8px 24px;
      }
      .ant-layout-content {
        padding: 8px;
        overflow: auto;
      }
    }
  }
`;

const RemoveContentBtn = styled(Button)`
  {
    display: none;
    position: absolute;
    z-index: 1;
    place-self: center center;
  }
`;

const AddContentBtn = styled(Button)`
  &&&& {
    text-transform: capitalize;
  }
`;

const ContentList = styled.ul`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const ContentListItem = styled.li`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 0 40px;
  margin: 0 0 24px 0;

  &:hover {
    ${RemoveContentBtn} {
      display: block;
      opacity: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    > * {
      opacity: 0.3;
    }
  }

  &:last-child {
    height: fit-content;
    margin: 0;
    justify-content: center;
  }
`;

export const getSelectStringForContent = (contentType) => {
  switch (contentType) {
    case 'blogs':
      return I18nService.getString('Select blogs');
    case 'events':
      return I18nService.getString('Select events');
    case 'donations':
      return I18nService.getString('Select donations');
    case 'forms':
      return I18nService.getString('Select forms');
  }
};
const getAddStringForContent = (contentType) => {
  switch (contentType) {
    case 'blogs':
      return I18nService.getString('Add blogs');
    case 'events':
      return I18nService.getString('Add events');
    case 'donations':
      return I18nService.getString('Add donations');
    case 'forms':
      return I18nService.getString('Add forms');
  }
};

export default function ContentBlock<T extends ContentTypeProps>({
  value,
  onChange,
  DrawerContent,
  contentType,
  imagePath = 'image',
  formFromProp,
  signUpText,
  showLocation,
  showShortDescription,
  sort,
  id,
}: ContentBlockProps<T>) {
  const [form] = Form.useForm();
  const [isDrawerActive, setDrawerActive] = useState<boolean>(false);
  const { resetSearch } = useOrganizations();
  const { resetAllSearchFormInDrawer } = useContentBlock();
  const toggleDrawer = useCallback(() => {
    if (!isDrawerActive) {
      resetSearch();
      resetAllSearchFormInDrawer();
    }
    setDrawerActive((state) => !state);
  }, [resetSearch, resetAllSearchFormInDrawer, isDrawerActive]);
  const RemoveItem = useCallback(
    (item) => onChange(value.filter((i) => i !== item)),
    [value, onChange]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFinish = useCallback(
    compose(toggleDrawer, () =>
      onChange(
        uniqBy(value.concat(form.getFieldValue(id) || []), 'id').filter(
          (e) => !!e
        )
      )
    ) as (form: any) => void,
    [toggleDrawer, value, onChange, contentType, form]
  );

  if (!value || !onChange || !DrawerContent) {
    return null;
  }

  return (
    <ContentList>
      <ContentBlockDrawer
        open={isDrawerActive}
        title={getSelectStringForContent(contentType)}
        onClose={toggleDrawer}
        destroyOnClose
        placement="right"
        footerStyle={{ textAlign: 'right' }}
        footer={
          <Button type="primary" onClick={onFinish}>
            {I18nService.getString('Save')}
          </Button>
        }
        width="75vw"
      >
        <Suspense fallback={<CdrPageLoader />}>
          <Layout style={{ padding: '8px' }}>
            <Content style={{ height: '100%' }}>
              <DrawerContent form={form} />
            </Content>
          </Layout>
        </Suspense>
      </ContentBlockDrawer>
      {value
        .filter((i) => !!i)
        .sort((a, b) => {
          if (sort.type === SORT.DATE) {
            if (moment(a[sort.key]).isBefore(b[sort.key])) {
              return sort?.sortDirection === 'DESC' ? -1 : 1;
            } else {
              return sort?.sortDirection === 'DESC' ? 1 : -1;
            }
          }
        })
        .map((item: T) => (
          <ContentListItem key={item.id}>
            <RemoveContentBtn danger onClick={() => RemoveItem(item)}>
              {I18nService.getString('Remove')}
            </RemoveContentBtn>
            <Suspense fallback={<ContentItem.Skeleton />}>
              {item[formFromProp] ? (
                <ContentItem<T>
                  {...item}
                  contentType={contentType}
                  showLocation={showLocation}
                  showShortDescription={showShortDescription}
                  image={get(item, imagePath)}
                  signUpText={signUpText}
                />
              ) : (
                <ContentItem<T>
                  {...item}
                  contentType={contentType}
                  showLocation={showLocation}
                  showShortDescription={showShortDescription}
                  image={get(item, imagePath)}
                />
              )}
            </Suspense>
          </ContentListItem>
        ))}
      <ContentListItem>
        <AddContentBtn
          type="dashed"
          block
          onClick={toggleDrawer}
          icon={<CdPlus />}
        >
          {getAddStringForContent(contentType)}
        </AddContentBtn>
      </ContentListItem>
    </ContentList>
  );
}
