/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { PostPeopleImportProcessfiledataRequestBody } from '../models/PostPeopleImportProcessfiledataRequestBody';
import type { PostPeopleImportProcessFileDataResponseBody } from '../models/PostPeopleImportProcessFileDataResponseBody';
import type { PostPeopleImportProcessfileheadersRequestBody } from '../models/PostPeopleImportProcessfileheadersRequestBody';
import type { PostPeopleImportProcessFileHeadersResponseBody } from '../models/PostPeopleImportProcessFileHeadersResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PeopleImportService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * List_imports
     * List imports
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeopleImport({
        accessToken,
    }: {
        accessToken?: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/import',
            query: {
                'access_token': accessToken,
            },
        });
    }
    /**
     * PostPeopleImportAvailableProperties
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeopleImportAvailableProperties({
        accessToken,
    }: {
        accessToken?: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/import/available-properties',
            query: {
                'access_token': accessToken,
            },
        });
    }
    /**
     * PostPeopleImportProcessFileData
     * @returns PostPeopleImportProcessFileDataResponseBody Success-Response: The ID of the batch created for the people import process
     * @throws ApiError
     */
    public postPeopleImportProcessFileData({
        accessToken,
        requestBody,
    }: {
        accessToken?: string,
        requestBody?: PostPeopleImportProcessfiledataRequestBody,
    }): CancelablePromise<PostPeopleImportProcessFileDataResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/import/process-file-data',
            query: {
                'access_token': accessToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * PostPeopleImportProcessFileHeaders
     * @returns PostPeopleImportProcessFileHeadersResponseBody Success-Response: The pre-mapping of the fields from the specified file and the auto-detected ChurchDesk properties
     * @throws ApiError
     */
    public postPeopleImportProcessFileHeaders({
        accessToken,
        requestBody,
    }: {
        accessToken?: string,
        requestBody?: PostPeopleImportProcessfileheadersRequestBody,
    }): CancelablePromise<PostPeopleImportProcessFileHeadersResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/import/process-file-headers',
            query: {
                'access_token': accessToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetImportSkippedPeopleDownloadToken
     * Get Import Skipped People Download Token
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeopleImportSkippedPeopleDownloadToken({
        accessToken,
    }: {
        accessToken?: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/import/skipped-people/download-token',
            query: {
                'access_token': accessToken,
            },
        });
    }
    /**
     * GetImportSkippedPeopleDownload
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeopleImportSkippedPeopleDownload({
        downloadToken,
    }: {
        downloadToken: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/import/skipped-people/download/{batchId}',
            query: {
                'downloadToken': downloadToken,
            },
        });
    }
    /**
     * GetPeopleImportBatch
     * @returns EmptyObject
     * @throws ApiError
     */
    public getPeopleImport1(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/import/{batchId}',
        });
    }
}
