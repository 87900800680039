/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { PostRolesRequestBody } from '../models/PostRolesRequestBody';
import type { PutRolesRequestBody } from '../models/PutRolesRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class RolesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create_Organization/Church_Roles
     * @returns EmptyObject
     * @throws ApiError
     */
    public postRoles({
        roleContext,
        requestBody,
    }: {
        roleContext?: 'church' | 'organization',
        requestBody?: PostRolesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/roles/{roleContext}',
            path: {
                'roleContext': roleContext,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Index_Organization/Church_Roles
     * @returns EmptyObject
     * @throws ApiError
     */
    public getRoles({
        version = 1,
        roleContext,
    }: {
        version?: number,
        roleContext?: 'church' | 'organization',
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/roles/{roleContext}',
            path: {
                'roleContext': roleContext,
            },
            query: {
                'version': version,
            },
        });
    }
    /**
     * Delete_Organization/Church_Roles
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteRoles({
        id,
        roleContext,
    }: {
        id: string,
        roleContext?: 'church' | 'organization',
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/roles/{roleContext}/{id}',
            path: {
                'id': id,
                'roleContext': roleContext,
            },
        });
    }
    /**
     * Update_Organization/Church_Roles._Example:_/roles/organization/:roleId_or_/roles/church/:roleId
     * @returns EmptyObject
     * @throws ApiError
     */
    public putRoles({
        id,
        roleContext,
        requestBody,
    }: {
        id: string,
        roleContext?: 'church' | 'organization',
        requestBody?: PutRolesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/roles/{roleContext}/{id}',
            path: {
                'id': id,
                'roleContext': roleContext,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Gets_the_role_and_its_permissions._Example:_/roles/organization/:roleId_or_/roles/church/:roleId
     * @returns EmptyObject
     * @throws ApiError
     */
    public getRoles1({
        roleContext,
        roleId,
    }: {
        roleContext: 'church' | 'organization',
        roleId: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/roles/{roleContext}/{roleId}',
            path: {
                'roleContext': roleContext,
                'roleId': roleId,
            },
        });
    }
    /**
     * CopyRole
     * @returns EmptyObject
     * @throws ApiError
     */
    public postRolesCopy({
        roleId,
        requestBody,
    }: {
        roleId: string,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/roles/{roleId}/copy',
            path: {
                'roleId': roleId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
