import { Collapse, CollapseProps } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useCalendarSearchContext } from '@/react/calendar/components/sidebar-search/CalendarSearchContext';

interface StyledCollapseProps extends CollapseProps {
  $transparentBackground?: boolean;
}

const CdCalendarFilterCollapseStyled = styled(Collapse)<StyledCollapseProps>`
&&&& {
    background: #fff;
    border-radius: 0px;
    border: 0px;
    border-top: 1px solid #ddd;

    .ant-collapse-item {
        border-bottom: 0px;
    }

    .ant-collapse-header {
        align-items: center;
        padding: 0px;
        background: ${({ $transparentBackground }) =>
          $transparentBackground ? 'none' : '#F5F7FA'};
    }

    .ant-collapse-expand-icon {
        padding-right: 8px;
        padding-left: 8px;
    }
    .ant-collapse-content-box {
        padding: 0px;
    }
    
    .ant-collapse-content {
        border-top: 0px;
        padding-bottom: 16px;
    }
}
`;

export const CdCalendarFilterCollapse = ({
  defaultActiveKey,
  ...otherProps
}: CollapseProps & { $transparentBackground?: boolean }) => {
  const preSearchActiveKey = useRef<CollapseProps['activeKey'] | null>(null);
  const [activeKey, setActiveKey] = useState<CollapseProps['activeKey']>(
    defaultActiveKey ?? []
  );
  const { isDuringSearch } = useCalendarSearchContext();

  useEffect(() => {
    if (isDuringSearch) {
      preSearchActiveKey.current = activeKey;
      setActiveKey(otherProps.items.map((i) => i.key));
    } else {
      if (preSearchActiveKey.current) {
        setActiveKey(preSearchActiveKey.current);
        preSearchActiveKey.current = null;
      }
    }
    // we intentionally don't want to refresh on items change
    // eslint-disable-next-line
  }, [isDuringSearch]);

  return (
    <CdCalendarFilterCollapseStyled
      {...otherProps}
      activeKey={activeKey}
      onChange={(val) => setActiveKey(val)}
      expandIconPosition="end"
    />
  );
};
