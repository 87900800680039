/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { GetWatermelondbPusherConfigResponseBody } from '../models/GetWatermelondbPusherConfigResponseBody';
import type { PostPusherAuthenticateRequestBody } from '../models/PostPusherAuthenticateRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class WatermelondbService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Authenticate to pusher
     * API to authenticate the user to the pusher service
     * @throws ApiError
     */
    public postWatermelondbPusherAuthenticate({
        requestBody,
    }: {
        requestBody?: PostPusherAuthenticateRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/watermelondb/pusher/authenticate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get pusher config
     * API to get the pusher configuration
     * @returns GetWatermelondbPusherConfigResponseBody
     * @throws ApiError
     */
    public getWatermelondbPusherConfig(): CancelablePromise<GetWatermelondbPusherConfigResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/watermelondb/pusher/config',
        });
    }
    /**
     * Calendar sync
     * API to sync data to watermelondb on the client for offline support
     * @returns EmptyObject
     * @throws ApiError
     */
    public getWatermelondbSync({
        lastPulledAt,
    }: {
        lastPulledAt?: number | null,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/watermelondb/sync',
            query: {
                'lastPulledAt': lastPulledAt,
            },
        });
    }
}
