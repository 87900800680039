import React, { useEffect } from 'react';
import { Form, Input, Typography, Divider } from 'antd';

import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import { I18nService } from '@/react/services/I18nService';
import { useCreateTag } from '@/react/people/tag-list/hooks/useCreateTag';
import { TagInterface } from '@/react/people/types/tag.type';

export const openCreateTagModal = createCdModal<{
  data?: TagInterface;
  isEdit: boolean;
}>(({ data, isEdit, setModalProps }) => {
  const formInitialValue = {
    names: data?.name || '',
  };
  const { onCreateOrEditTag, createTagForm } = useCreateTag({
    data,
    isEdit: isEdit,
  });

  useEffect(() => {
    setModalProps({
      modalType: ModalType.MODAL,
      title: isEdit
        ? I18nService.getString('Edit tag')
        : I18nService.getString('Create tag'),
      okText: isEdit
        ? I18nService.getString('Save')
        : I18nService.getString('Create'),
      onOk: onCreateOrEditTag,
    });
  }, [isEdit, onCreateOrEditTag, setModalProps]);
  return (
    <Form
      layout="horizontal"
      form={createTagForm}
      initialValues={formInitialValue}
    >
      {!isEdit && (
        <Typography.Text>
          {I18nService.getString(
            'To create more than one tag, separate the names by commas.'
          )}
        </Typography.Text>
      )}
      <Divider />
      <Form.Item
        name="names"
        label={I18nService.getString('Tag name')}
        rules={[{ required: true, message: 'Please input tag name!' }]}
      >
        <Input
          placeholder={I18nService.getString('Create one or more tags...')}
        />
      </Form.Item>
    </Form>
  );
});
