import { Col, Form, Input, Row, Space, Steps } from 'antd';
import React, { useEffect } from 'react';
import { FormLayout } from 'antd/es/form/Form';

import {
  StripeOnboardingModalStep,
  useStripeOnboardingModal,
} from '../hooks/useStripOnboardingModal';

import {
  CdPhoneInput,
  getInitialValueCdPhoneInput,
} from '@/react/shared/components/CdPhoneInput';
import { CdUserSelect } from '@/react/groups/components/CdUserSelect';
import {
  emailFormat,
  phoneNumber,
  required,
} from '@/react/shared/antd/validations/common';
import {
  ModalType,
  createCdModal,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import { CdOpenInNewWindow } from '@/react/shared/components/Icons';
import { I18nService } from '@/react/services/I18nService';
import { CdCheckbox } from '@/react/shared/components/cd-checkbox/CdCheckbox';
import { getString } from '@/react/services/GetStringX';

export const openStripeOnboardingModal = createCdModal<object, void>(
  ({ setModalProps }) => {
    const { save, currentStep, form, selectedUserId } =
      useStripeOnboardingModal();

    useEffect(() => {
      setModalProps({
        modalType: ModalType.MODAL,
        title: I18nService.getString('ChurchDesk payments'),
        okText:
          currentStep === StripeOnboardingModalStep.User
            ? I18nService.getString('Next')
            : I18nService.getString('Continue to Stripe'),
        onOk: save,
        width: 800,
        okButtonProps: {
          icon:
            currentStep === StripeOnboardingModalStep.User ? undefined : (
              <CdOpenInNewWindow />
            ),
        },
      });
    }, [currentStep, form, setModalProps, save]);

    const steps = [
      {
        title: I18nService.getString('Choose Stripe admin user'),
      },
      {
        title: I18nService.getString('Legal information'),
      },
      {
        title: I18nService.getString('Signup Stripe'),
      },
    ];

    const formDefaults = {
      form,
      layout: 'vertical' as FormLayout,
      style: { maxWidth: '500px', margin: 'auto' },
    };

    return (
      <Space
        direction="vertical"
        size="large"
        style={{ marginTop: '16px', width: '100%' }}
      >
        <Steps current={currentStep} items={steps} />

        <Form
          {...formDefaults}
          initialValues={{
            onboardingId: selectedUserId,
            onboardingUserPhone: getInitialValueCdPhoneInput(),
          }}
        >
          {currentStep === StripeOnboardingModalStep.User && (
            <>
              <Form.Item
                label={I18nService.getString(
                  'Register your user as the person responsible for managing the Stripe account and compliance'
                )}
              >
                {I18nService.getString(
                  'You are only allowed one user account with Stripe. The email and phone number you choose will be the only one that is able to view/edit compliance information and control bank information where you receive payouts.'
                )}
              </Form.Item>
              <Form.Item
                name="onboardingId"
                label={I18nService.getString('Legal user')}
                rules={[required()]}
                extra={I18nService.getString(
                  'Only the logged in user can be specifed as the person reponsible.'
                )}
              >
                <CdUserSelect size="large" disabled />
              </Form.Item>
              <Form.Item
                name="onboardingUserName"
                label={I18nService.getString('Full legal name')}
                rules={[required()]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="onboardingUserEmail"
                label={I18nService.getString('E-mail')}
                rules={[required(), emailFormat]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="onboardingUserPhone"
                label={I18nService.getString('Phone')}
                rules={[
                  required(
                    I18nService.getString('Please input a phone number')
                  ),
                  phoneNumber,
                ]}
              >
                <CdPhoneInput />
              </Form.Item>
              <Form.Item
                name="userConfirmManagesAccount"
                rules={[required(I18nService.getString('Required'))]}
                valuePropName="checked"
              >
                <CdCheckbox required>
                  {I18nService.getString(
                    'I confirm that I will create and manage the Stripe account.'
                  )}
                </CdCheckbox>
              </Form.Item>
              <Form.Item
                name="userConfirmClause"
                rules={[required(I18nService.getString('Required'))]}
                valuePropName="checked"
              >
                <CdCheckbox required $align="start">
                  {getString(`By using our payment and donation collection service, you agree that you will only collect funds for projects that fall into one or more of the following categories:
                  <ul>
                    <li>Community and Social Initiatives</li>
                    <li>Educational Programs</li>
                    <li>Religious Projects with Community Impact</li>
                    <li>Health and Wellness Initiatives</li>
                    <li>Environmental Conservation Projects</li>
                    <li>Arts and Cultural Programs</li>
                    <li>Humanitarian Aid and Relief Efforts</li>
                    <li>Animal Welfare Projects</li> 
                  </ul>
                  You promise not to use our service to collect payments or donations for any projects outside these categories. If you use our service for any other purpose, you understand that your account may be suspended or terminated.`)}
                </CdCheckbox>
              </Form.Item>
            </>
          )}
          {currentStep === StripeOnboardingModalStep.Legal && (
            <>
              <Form.Item
                name="legalBusinessName"
                label={I18nService.getString('Legal Business Name')}
                extra={I18nService.getString(
                  'Full legal name of the organisation'
                )}
                rules={[required()]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="line1"
                label={I18nService.getString('Street address')}
                rules={[required()]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="line2"
                label={I18nService.getString('Address Line 2')}
              >
                <Input />
              </Form.Item>

              <Row gutter={16}>
                <Col flex="150px">
                  <Form.Item
                    label={I18nService.getString('Postal Code')}
                    rules={[required()]}
                    name="postCode"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="auto">
                  <Form.Item
                    label={I18nService.getString('City')}
                    name="city"
                    rules={[required()]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Space>
    );
  }
);
