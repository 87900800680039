/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FileSystemService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetFileSystem
     * Index
     * @returns EmptyObject
     * @throws ApiError
     */
    public getFilesystem({
        start,
        length = 50,
        type,
        groupId,
        folderId,
        search,
        visibility,
        orderBy = 'title',
        orderDirection = 'ASC',
        fromGallery,
        imagesOnly,
    }: {
        start?: number,
        length?: number,
        type?: 'file' | 'folder' | '' | null,
        groupId?: number,
        folderId?: (number | 'root' | '' | null),
        search?: string | null,
        visibility?: 'web' | 'group' | '' | null,
        orderBy?: 'title' | 'createdAt',
        orderDirection?: 'ASC' | 'DESC',
        fromGallery?: boolean,
        imagesOnly?: boolean,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/filesystem',
            query: {
                'start': start,
                'length': length,
                'type': type,
                'groupId': groupId,
                'folderId': folderId,
                'search': search,
                'visibility': visibility,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
                'fromGallery': fromGallery,
                'imagesOnly': imagesOnly,
            },
        });
    }
    /**
     * GetFileSystemBreadCrumb
     * Breadcrumb
     * @returns EmptyObject
     * @throws ApiError
     */
    public getFilesystemBreadcrumb({
        folderId,
    }: {
        /**
         * Folder id to start getting the breadcrumb
         */
        folderId: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/filesystem/breadcrumb',
            query: {
                'folderId': folderId,
            },
        });
    }
    /**
     * MoveFilesFolders
     * Move
     * @returns EmptyObject
     * @throws ApiError
     */
    public postFilesystemMove({
        moveToId,
        requestBody,
    }: {
        moveToId?: number,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/filesystem/move',
            query: {
                'moveToId': moveToId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetUserPermissions
     * Permissions
     * @returns EmptyObject
     * @throws ApiError
     */
    public getFilesystemPermissions(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/filesystem/permissions',
        });
    }
    /**
     * GetFileSystem
     * Index V2
     * @returns EmptyObject
     * @throws ApiError
     */
    public getFilesystemV2({
        page,
        limit,
        type,
        groupId,
        path = '/',
        folderId,
        search,
        visibility,
        orderBy = 'name',
        orderDirection = 'ASC',
        fromGallery,
        imagesOnly,
    }: {
        page: number,
        limit: number,
        type?: 'file' | 'folder' | '' | null,
        groupId?: number,
        path?: string,
        folderId?: (number | 'root' | '' | null),
        search?: string | null,
        visibility?: 'web' | 'group' | '' | null,
        orderBy?: 'name' | 'type' | 'author' | 'visibility' | 'size' | 'updatedAt' | 'createdAt',
        orderDirection?: 'ASC' | 'DESC',
        fromGallery?: boolean,
        imagesOnly?: boolean,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/filesystem/v2',
            query: {
                'page': page,
                'limit': limit,
                'type': type,
                'groupId': groupId,
                'path': path,
                'folderId': folderId,
                'search': search,
                'visibility': visibility,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
                'fromGallery': fromGallery,
                'imagesOnly': imagesOnly,
            },
        });
    }
}
