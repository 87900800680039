import { Form } from 'antd';
import { useCallback, useState } from 'react';
import moment from 'moment';

import cdApp from '@/react/config';
import {
  useTimeRegistrationReportExport,
  useTimeRegistrationReportUsers,
} from '@/react/calendar/reports/store/reportHooks';
import { showTimeRegistrationReportConfirmModal } from '@/react/calendar/reports/TimeRegistrationReportConfirmModal';
import { navigate } from '@/react/services/StateServiceFactory';

export const useTimeRegistrationReport = ({ filters }) => {
  const [timeRegistrationReportForm] = Form.useForm();
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const { timeRegistrationReportUsers } = useTimeRegistrationReportUsers();
  const initialValues = {
    dateRange: [moment().subtract(4, 'weeks'), moment()],
    user: filters.timeRegistrationUserSelect,
  };

  const { exportTimeRegistrationReport } = useTimeRegistrationReportExport();

  const onExportTimeRegistrationReport = useCallback(async () => {
    await timeRegistrationReportForm.validateFields().then((formValues) => {
      const { dateRange, user } = formValues;

      const startWeekNumber = moment(dateRange[0]).isoWeek();
      const startYear = moment(dateRange[0]).isoWeekYear();
      const endWeekNumber = moment(dateRange[1]).isoWeek();
      const endYear = moment(dateRange[1]).isoWeekYear();
      const userWithTimeRegistrationId = user || cdApp.me.id;

      navigate('app.private.calendar.print', {
        startWeekNumber,
        startYear,
        endWeekNumber,
        endYear,
        timeRegistrationUserSelect: userWithTimeRegistrationId || cdApp.me.id,
      });

      exportTimeRegistrationReport({
        startWeekNumber: startWeekNumber,
        startYear: startYear,
        endWeekNumber: endWeekNumber,
        endYear: endYear,
        userWithTimeRegistrationId: userWithTimeRegistrationId || cdApp.me.id,
      });

      showTimeRegistrationReportConfirmModal();
    });
  }, [exportTimeRegistrationReport, timeRegistrationReportForm]);

  return {
    timeRegistrationReportForm,
    initialValues,
    onExportTimeRegistrationReport,
    timeRegistrationReportUsers,
    hasChanges,
    setHasChanges,
  };
};
