/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PutFilesRequestBody = {
    title?: string;
    visibility?: PutFilesRequestBody.visibility;
    tags?: Array<string>;
    credits?: string | null;
};
export namespace PutFilesRequestBody {
    export enum visibility {
        WEB = 'web',
        GROUP = 'group',
    }
}

