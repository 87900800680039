import { Checkbox, Divider, Form, Input } from 'antd';
import React, { useEffect } from 'react';

import { useSettingsForm } from '@/react/calendar/rota-scheme/hooks/useSettingsForm';
import {
  RotaSchemeSettingsFormData,
  RotaSchemeSettingsModalProps,
} from '@/react/calendar/rota-scheme/types/rotaScheme.types';
import { I18nService } from '@/react/services/I18nService';
import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';

export const openEditSettingsModal =
  createCdModal<RotaSchemeSettingsModalProps>(
    ({ setModalProps, rotaScheme }) => {
      const [form] = Form.useForm<RotaSchemeSettingsFormData>();
      const { initialValues, onSave } = useSettingsForm(rotaScheme, form);

      useEffect(() => {
        setModalProps({
          modalType: ModalType.POPUP,
          title: I18nService.getString('Scheme settings'),
          width: 500,
          onOk: async () => {
            await onSave();
          },
        });
      }, [onSave, setModalProps]);

      return (
        <Form form={form} initialValues={initialValues} layout="vertical">
          <Form.Item
            name="name"
            label={I18nService.getString('Name')}
            rules={[{ max: 255 }]}
          >
            <Input />
          </Form.Item>
          <Divider />
          <Form.Item
            name="settings"
            label={I18nService.getString('Public settings')}
          >
            <Checkbox.Group style={{ flexDirection: 'column' }}>
              <Checkbox value="hideNotes">
                {I18nService.getString('Hide notes')}
              </Checkbox>

              <Checkbox value="useInitials">
                {I18nService.getString(
                  'Only show user initials, hide full names'
                )}
              </Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </Form>
      );
    }
  );
