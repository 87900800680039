import { Space, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React from 'react';
import styled from 'styled-components';

import { I18nService } from '../../services/I18nService';
import { CdrFile } from '../models/files';

import { launchCdrUploadFilesModal } from './CdrUploadFilesModal';
import { CdAttachmentIcon, CdDeleteIcon, CdUploadIcon } from './Icons';
import { CdTooltip } from './cd-tooltip/CdTooltip';

export interface CdrUploadFilesProps {
  initialFiles: CdrFile[];
  onUpdateFiles: (fileList) => void;
}

const Uploader = styled(Upload.Dragger)`
  &&&& {
    .ant-upload-text {
      font-size: 14px !important;
    }
  }
`;

export const CdrUploadFiles = (props: {
  initialFiles?: CdrFile[];
  onUpdateFiles?: (fileList) => void;
  hideUploadIcon?: boolean;
  modalGetContainerClassName?: string;
  canEdit: boolean;
}) => {
  // State
  const addNewFilesToList = (files: CdrFile[]) => {
    const uploadedList = (props.initialFiles || []).concat(files);
    props.onUpdateFiles(uploadedList);
  };

  const fileList =
    props.initialFiles?.map(
      (file) =>
        ({
          uid: file.id.toString(),
          name: file.title,
          url: file.permission?.canView && file.downloadUrl,
        }) as UploadFile
    ) || [];

  return (
    <div>
      <Uploader
        name="file"
        multiple
        disabled={!props.canEdit}
        fileList={fileList as UploadFile[]}
        itemRender={(node, file, fileList, { remove, download }) => (
          <CdTooltip
            title={
              !file.url &&
              I18nService.getString(
                'You cannot download this file as you are not a member of its group'
              )
            }
          >
            <div className="ant-upload-list-item">
              <CdAttachmentIcon />
              {file.url ? (
                <a className="ant-upload-list-item-name" onClick={download}>
                  {file.name}
                </a>
              ) : (
                <span className="ant-upload-list-item-name">{file.name}</span>
              )}
              {props.canEdit && (
                <a className="ant-upload-list-item-actions" onClick={remove}>
                  <CdDeleteIcon />
                </a>
              )}
            </div>
          </CdTooltip>
        )}
        showUploadList={{
          showPreviewIcon: true,
          showDownloadIcon: false,
        }}
        beforeUpload={() => false}
        onChange={({ file, fileList }) => {
          if (file.status === 'removed') {
            const changedFiles = fileList.map(
              (file: UploadFile) =>
                ({
                  id: Number(file.uid),
                  title: file.name,
                  downloadUrl: file.url,
                }) as CdrFile
            );
            props.onUpdateFiles(changedFiles);
          } else {
            const newFilesToUpload = fileList?.filter(
              (file) => file.lastModified
            );
            launchCdrUploadFilesModal({
              filesToUpload: newFilesToUpload,
              modalGetContainer: props.modalGetContainerClassName
                ? (document
                    .getElementsByClassName(props.modalGetContainerClassName)
                    .item(0) as HTMLElement)
                : undefined,
            }).then(({ files }) => {
              addNewFilesToList(
                files.map((file) => {
                  file.permission = {
                    canView: true,
                    canEdit: true,
                    canDelete: true,
                  };
                  return file;
                })
              );
            });
          }
        }}
      >
        <Space direction="vertical" size="small">
          {!props.hideUploadIcon && (
            <span className="ant-upload-drag-icon">
              <CdUploadIcon style={{ fontSize: 28 }} />
            </span>
          )}
          <span className="ant-upload-text">
            {I18nService.getString(
              'Click or drag files to this area to upload'
            )}
          </span>
        </Space>
      </Uploader>
    </div>
  );
};
