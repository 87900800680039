/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetTasksResponseBody } from '../models/GetTasksResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TasksService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Retrieve a list of tasks for an organization.
     * @returns GetTasksResponseBody
     * @throws ApiError
     */
    public getTasks(): CancelablePromise<GetTasksResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tasks',
        });
    }
    /**
     * Retrieves a list of users that can be assigned for a given task.
     * @throws ApiError
     */
    public getTasksAssignableUsers({
        taskId,
        parishIds,
    }: {
        taskId: number,
        parishIds?: Array<number>,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tasks/{taskId}/assignable-users',
            path: {
                'taskId': taskId,
            },
            query: {
                'parishIds': parishIds,
            },
        });
    }
}
