import { Form, Select, Space, Spin } from 'antd';
import React, { useState } from 'react';
import { useRecoilValueLoadable } from 'recoil';

import { PeopleListByTypeAndSearchQuery } from '../store/newsletterListState';

import { I18nService } from '@/react/services/I18nService';
import {
  CdNewsletterListIcon,
  CdParticipantList,
} from '@/react/shared/components/Icons';

const ImportNewsletterAndParticipantDropDown = ({
  handleSelectedList,
}: {
  handleSelectedList: (values: number[]) => void;
}) => {
  const [selectedList, setSelectedList] = useState<number[]>();
  const [form] = Form.useForm();
  const newsletterlistsDataLoadable = useRecoilValueLoadable(
    PeopleListByTypeAndSearchQuery({ type: 'newsletter', searchParameter: '' })
  );
  const participantlistsDataLoadable = useRecoilValueLoadable(
    PeopleListByTypeAndSearchQuery({ type: 'participant', searchParameter: '' })
  );
  const loading =
    newsletterlistsDataLoadable.state === 'loading' ||
    participantlistsDataLoadable.state === 'loading';
  const newsletterLists = newsletterlistsDataLoadable.contents.items;
  const participantLists = participantlistsDataLoadable.contents.items;
  const options = [
    {
      key: 'participantlists',
      label: I18nService.getString('Participant Lists').toLocaleUpperCase(),
      options:
        participantLists?.map((item) => ({
          label: (
            <Space>
              <CdParticipantList />
              {item.name}
            </Space>
          ),
          value: item.id,
        })) || [],
    },
    {
      key: 'newsletterlists',
      label: I18nService.getString('Newsletter Lists').toLocaleUpperCase(),
      options:
        newsletterLists?.map((item) => ({
          label: (
            <Space>
              <CdNewsletterListIcon />
              {item.name}
            </Space>
          ),
          value: item.id,
        })) || [],
    },
  ];
  return (
    <Spin spinning={loading}>
      <Form
        layout="horizontal"
        form={form}
        onFieldsChange={() => {
          handleSelectedList(form.getFieldsValue());
        }}
      >
        <Form.Item name="selectedLists">
          <Select
            placeholder={I18nService.getString('Choose one or more lists...')}
            mode="multiple"
            onChange={(value) => setSelectedList(value)}
            value={selectedList}
            options={options}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default ImportNewsletterAndParticipantDropDown;
