/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { PostLegaldocumentRequestBody } from '../models/PostLegaldocumentRequestBody';
import type { PutLegaldocumentRequestBody } from '../models/PutLegaldocumentRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LegalDocumentService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * CreateLegalDocuments
     * Create
     * @returns EmptyObject
     * @throws ApiError
     */
    public postLegalDocument({
        name,
        show,
        countryIso2,
        requestBody,
    }: {
        name: string,
        show: boolean,
        countryIso2?: string | null,
        requestBody?: PostLegaldocumentRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/legal-document',
            query: {
                'name': name,
                'countryIso2': countryIso2,
                'show': show,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetLegalDocuments
     * Index
     * @returns EmptyObject
     * @throws ApiError
     */
    public getLegalDocument(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/legal-document',
        });
    }
    /**
     * UpdateLegalDocuments
     * Update
     * @returns EmptyObject
     * @throws ApiError
     */
    public putLegalDocument({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PutLegaldocumentRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/legal-document/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetLegalDocument
     * Retrieve
     * @returns EmptyObject
     * @throws ApiError
     */
    public getLegalDocument1({
        id,
        accessToken,
        action,
    }: {
        id: string,
        accessToken?: string,
        action?: 'view' | 'download',
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/legal-document/{id}/{action}',
            path: {
                'id': id,
                'action': action,
            },
            query: {
                'access_token': accessToken,
            },
        });
    }
}
