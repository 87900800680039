'use strict';

import * as _ from 'lodash';

import PeopleService from '../../../../../react/people/services/people.service';
import { getChurches } from '../../../../../react/shared/store/resources/index';
import ErrorHandlingService from '../../../../../react/services/ErrorHandlingService';

class CreatePeopleComponent {
  constructor(
    $scope,
    People,
    Authorization,
    gettextCatalog,
    toastr,
    $uibModal,
    $ngRedux
  ) {
    this.Authorization = Authorization;
    this.People = People;
    this.gettextCatalog = gettextCatalog;
    this.toastr = toastr;
    this.$uibModal = $uibModal;
    this.$ngRedux = $ngRedux;

    const unsubscribe = $ngRedux.connect(
      this.mapStateToScope,
      this.mapDispatchToScope
    )(this);
    $scope.$on('$destroy', unsubscribe);
  }

  $onInit() {
    /**
     * Initialize the new person object
     */
    this.person = new this.People();
    this.registerValidationHook = this.registerValidationHook.bind(this);
    this.registerFetchValuesHook = this.registerFetchValuesHook.bind(this);
  }

  /**
   * Lets a child component register its validation function.
   *
   * @param {Promise<boolean>} childValidationFunction
   */
  registerValidationHook(childValidationFunction) {
    this.validateHook = childValidationFunction;
  }
  /**
   * Lets a child component register its fetch form values function.
   *
   * @param {*} childGetValuesFunction
   */
  registerFetchValuesHook(childGetValuesFunction) {
    this.fetchValues = childGetValuesFunction;
  }

  /**
   * Save the new person and close the modal
   */
  save(createAnother) {
    this.validateHook()
      .then((isValid) => {
        if (isValid) {
          const formData = this.fetchValues();
          if (
            !_.some(
              _.pick(formData, [
                'firstName',
                'lastName',
                'email',
                'prefix',
                'phone',
              ]),

              (v) => !_.isEmpty(v)
            )
          ) {
            this.toastr.error(
              this.gettextCatalog.getString(
                'First name, last name, email or phone is required to create a person.'
              )
            );

            return;
          }

          try {
            const processedRelations =
              PeopleService.processConflictingPeopleRelations(
                formData.relations
              );

            if (!_.isEmpty(processedRelations)) {
              formData.relations = processedRelations;
            }

            this.savePerson(formData, createAnother);
          } catch (err) {
            return ErrorHandlingService.handleError(err.message);
          }
        }
      })
      .catch((error) => {
        const response = error;
        const formData = this.fetchValues();
        // If the person being created conflicts with an existing person and with only one person
        // then update the existing person, only extends fields that are empty and adds the person to
        // the new churches that the person is not a member.
        if (response && response.status === 409) {
          const data =
            response.data && (response.data.error || response.data.errors);
          if (data && data.conflictsWithMultiple) return;
          const churchesIdsToAdd = _.difference(
            data.newChurches,
            data.existingChurches
          );

          if (_.isEmpty(churchesIdsToAdd)) return;
          const churchesToAdd = _.filter(this.churches, (church) =>
            _.includes(churchesIdsToAdd, church.id)
          );

          const existingChurches = _.filter(this.churches, (church) =>
            _.includes(data.existingChurches, church.id)
          );

          this.$uibModal
            .open({
              component: 'cdConfirmationModal',
              resolve: {
                name: () => formData.firstName,
                existingChurches: () =>
                  _.map(existingChurches, 'name').join(', '),
                newChurches: () => _.map(churchesToAdd, 'name').join(', '),
              },
            })
            .result.then(() =>
              // Update existing person that conflicts with the person that is being created.
              // add to the existing person the new churches.
              this.People.addToChurches(
                { id: data.personId },
                _.extend({}, formData, { churches: churchesIdsToAdd })
              ).$promise.then((updatedPerson) => {
                this.close({
                  $value: { newPerson: updatedPerson, createAnother },
                });
              })
            );
        } else if (
          error &&
          error.errorFields &&
          error.errorFields &&
          error.errorFields.length > 0
        ) {
          // If there are any form field errors, do nothing since the errors should be displayed inline next to each field
        } else {
          return ErrorHandlingService.handleError(error);
        }
      });
  }

  savePerson(formData, createAnother) {
    // Extend the original person with the form payload, excluding fields that could have been removed to avoid conflicts
    this.person = _.extend(_.omit(this.person, ['email', 'phone']), formData);
    return this.person.$save(
      [{ condition: !this.showSalutationField, field: 'salutation' }],
      (newPerson) => {
        this.close({ $value: { newPerson, createAnother } });
      }
    );
  }

  // AngularJS <-> Redux mapping functions

  mapStateToScope = (state) => ({
    churches: getChurches(state),
  });

  mapDispatchToScope = () => ({});
}

CreatePeopleComponent.$inject = [
  '$scope',
  'People',
  'Authorization',
  'gettextCatalog',
  'toastr',
  '$uibModal',
  '$ngRedux',
];

angular.module('cdApp.people').component('cdCreatePeopleModal', {
  templateUrl:
    '@/app/people/shared/components/create-people/create-people.component.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },

  controller: CreatePeopleComponent,
});
