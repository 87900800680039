import React from 'react';
import { Modal, Form, Space, FormProps, Button, Result } from 'antd';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import { Moment } from 'moment';

import { PeopleMessage } from '../../../types/message';
import { CdVerticalSpace } from '../../../../shared/components/cd-vertical-space/CdVerticalSpace';

import {
  CdSchedule,
  CdSendMessageWithCircle,
  CdShare,
} from '@/react/shared/components/Icons';
import { I18nService } from '@/react/services/I18nService';
import { getStateUrl } from '@/react/services/StateServiceFactory';
import { CdInputWithCopy } from '@/react/shared/components/cd-input-with-copy/CdInputWithCopy';

type ShareNewsletterModalProps = {
  message: PeopleMessage;
  when?: Date | Moment;
  sent?: boolean;
};
export const ShareNewsletterModal = NiceModal.create<ShareNewsletterModalProps>(
  ({ message, when, sent }) => {
    const modal = useModal('ShareNewsletterModal');
    const formItemLayout: FormProps = {
      layout: 'vertical',
    };

    const url = getStateUrl(
      'app.public.people-newsletter',
      { uuid: message.uuid },
      { absolute: true }
    );

    return (
      <>
        <Modal
          {...antdModalV5(modal)}
          title={
            <Space>
              <CdShare />
              {I18nService.getString('Share your message')}
            </Space>
          }
          maskTransitionName="maskTransitionName"
          destroyOnClose
          footer={
            <Button onClick={() => modal.hide()}>
              {I18nService.getString('Close')}
            </Button>
          }
        >
          <CdVerticalSpace>
            {(sent || when) && (
              <Result
                status="success"
                icon={sent ? <CdSendMessageWithCircle /> : <CdSchedule />}
                title={
                  sent
                    ? I18nService.getString('The message is being sent')
                    : I18nService.getString(
                        'The message is scheduled for sending at {{date}}',
                        {
                          date: I18nService.formatDate(when as Date, 'LLL'),
                        }
                      )
                }
              />
            )}
            {I18nService.getString(
              'Copy and paste this link on Facebook, in an email, text or on your website.'
            )}
            <Form
              name="people_message_confirm_modal"
              initialValues={{ url }}
              {...formItemLayout}
            >
              <Form.Item
                name="url"
                label={I18nService.getString('Copy Url')}
                extra={I18nService.getString(
                  'Your message is publicly available on this unique link.'
                )}
              >
                <CdInputWithCopy disabled />
              </Form.Item>
            </Form>
          </CdVerticalSpace>
        </Modal>
      </>
    );
  }
);
