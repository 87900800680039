import { Col, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { calendarSettingsSelectors } from '../store/calendar-settings/calendarSettingsSlice';

import AbsenceFilters from './AbsenceFilters';
import CreateCalendarSubscriptionModal from './CreateCalendarSubscriptionModal';
import MyCalenders from './MyCalenders';
import { ParishCalendars } from './ParishCalendars';
import SelectDatePicker from './SelectDatePicker';
import SidebarStyles from './SideBarStyle';
import UsersAndRoomsCalendars from './UsersAndRoomsCalendars';
import { AvailabilityCalendarFilters } from './filters/AvailabilityCalendarFilters';
import { CalendarSearchProvider } from './sidebar-search/CalendarSearchContext';

import { CalendarSearch } from '@/react/calendar/components/sidebar-search/CalendarSearch';

export const SidebarContent: FunctionComponent = () => {
  const isBookingSystemEnabled = useSelector(
    calendarSettingsSelectors.getIsBookingSystemEnabled
  );

  return (
    <CalendarSearchProvider>
      <SidebarStyles>
        <Row style={{ flex: '1 1 0', minHeight: 0, overflow: 'auto' }}>
          <Col style={{ width: '100%' }}>
            <SelectDatePicker />
            <MyCalenders />
            <UsersAndRoomsCalendars />
            <AbsenceFilters />
            {isBookingSystemEnabled && <AvailabilityCalendarFilters />}
            <ParishCalendars />
          </Col>
        </Row>
        <Row style={{ flex: '0 0 0' }}>
          <CalendarSearch />
        </Row>
        <CreateCalendarSubscriptionModal />
      </SidebarStyles>
    </CalendarSearchProvider>
  );
};
