/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetDiocesesResponseBody } from '../models/GetDiocesesResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DiocesesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetDioceses
     * Get Dioceses
     * @returns GetDiocesesResponseBody
     * @throws ApiError
     */
    public getDioceses({
        country,
    }: {
        country: string,
    }): CancelablePromise<GetDiocesesResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/dioceses',
            query: {
                'country': country,
            },
        });
    }
}
