/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostPeoplePeopleSearchRequestBody = {
    limit?: number;
    orderBy?: string;
    orderDirection?: string;
    filter?: {
        churchIds?: Array<number>;
        comparison?: PostPeoplePeopleSearchRequestBody.comparison;
        filters?: Array<any>;
    };
    reverseName?: boolean;
    searchAfter?: Array<any> | null;
    searchType?: PostPeoplePeopleSearchRequestBody.searchType;
};
export namespace PostPeoplePeopleSearchRequestBody {
    export enum comparison {
        AND = 'AND',
        OR = 'OR',
    }
    export enum searchType {
        FILTER = 'filter',
        NEWSLETTER = 'newsletter',
        CHURCH = 'church',
        PARTICIPANT = 'participant',
    }
}

