import React from 'react';
import { Form, Row, Col, Typography, Skeleton } from 'antd';

import { I18nService } from '../../services/I18nService';

const SkeletonInput = (props) => (
  <Skeleton.Input {...props} active style={{ width: '100%' }} block />
);

export const CreateWidgetSuspended = () => (
  <Form layout="vertical">
    <Row gutter={[16, 16]} style={{ marginBottom: '24px', marginTop: '24px' }}>
      <Col span={24}>
        <Typography.Title level={3}>
          {I18nService.getString('Select widget type')}
        </Typography.Title>
        <Form.Item name="type">
          <SkeletonInput />
        </Form.Item>
        <Form.Item name="type">
          <SkeletonInput />
        </Form.Item>
        <Form.Item name="type">
          <SkeletonInput />
        </Form.Item>
        <Form.Item name="type">
          <SkeletonInput />
        </Form.Item>
        <Form.Item name="type">
          <SkeletonInput />
        </Form.Item>
        <Form.Item label={I18nService.getString('Name the widget')}>
          <SkeletonInput />
        </Form.Item>
      </Col>
    </Row>
  </Form>
);
