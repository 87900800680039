/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetTerminalAllResponseBody } from '../models/GetTerminalAllResponseBody';
import type { GetTerminalErrorsResponseBody } from '../models/GetTerminalErrorsResponseBody';
import type { GetTerminalHelloResponseBody } from '../models/GetTerminalHelloResponseBody';
import type { GetTerminalReadersetupResponseBody } from '../models/GetTerminalReadersetupResponseBody';
import type { GetTerminalResponseBody } from '../models/GetTerminalResponseBody';
import type { GetTerminalResponseBody2 } from '../models/GetTerminalResponseBody2';
import type { PostTerminalCommandRequestBody } from '../models/PostTerminalCommandRequestBody';
import type { PostTerminalHelloResponseBody } from '../models/PostTerminalHelloResponseBody';
import type { PostTerminalSyncResponseBody } from '../models/PostTerminalSyncResponseBody';
import type { TerminalAssociation } from '../models/TerminalAssociation';
import type { TerminalHelloRequestBody } from '../models/TerminalHelloRequestBody';
import type { TerminalPairing } from '../models/TerminalPairing';
import type { TerminalReceiptRequestBody } from '../models/TerminalReceiptRequestBody';
import type { TerminalSyncRequestBody } from '../models/TerminalSyncRequestBody';
import type { TerminalUpdate } from '../models/TerminalUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TerminalService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all terminals for the given organization
     * @returns GetTerminalResponseBody
     * @throws ApiError
     */
    public getTerminal({
        search = '',
        cursor = '',
        limit = 10,
    }: {
        search?: string | null,
        cursor?: string,
        limit?: number,
    }): CancelablePromise<GetTerminalResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/terminal',
            query: {
                'search': search,
                'cursor': cursor,
                'limit': limit,
            },
        });
    }
    /**
     * Get all terminals regardless organization
     * @returns GetTerminalAllResponseBody
     * @throws ApiError
     */
    public getTerminalAll({
        search = '',
        limit = 10,
        pageNumber = 1,
        status = '',
        pairing = '',
    }: {
        search?: string | null,
        limit?: number,
        pageNumber?: number,
        status?: string | null,
        pairing?: string | null,
    }): CancelablePromise<GetTerminalAllResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/terminal/all',
            query: {
                'search': search,
                'limit': limit,
                'pageNumber': pageNumber,
                'status': status,
                'pairing': pairing,
            },
        });
    }
    /**
     * Onboarding (terminal registration), part 1 (called by the tablet): the terminal is given a new unique id
     * @returns PostTerminalHelloResponseBody
     * @throws ApiError
     */
    public postTerminalHello({
        requestBody,
    }: {
        requestBody?: TerminalHelloRequestBody,
    }): CancelablePromise<PostTerminalHelloResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/terminal/hello',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Request an email receipt for a terminal payment
     * @throws ApiError
     */
    public postTerminalReceiptRequest({
        requestBody,
    }: {
        requestBody?: TerminalReceiptRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/terminal/receipt-request',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get overall terminal status, offline, missing projects, stripe not setup
     * @throws ApiError
     */
    public getTerminalStatus(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/terminal/status',
        });
    }
    /**
     * Get the details of a single terminal
     * @returns GetTerminalResponseBody2
     * @throws ApiError
     */
    public getTerminal1({
        terminalId,
    }: {
        terminalId: string,
    }): CancelablePromise<GetTerminalResponseBody2> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/terminal/{terminalId}',
            path: {
                'terminalId': terminalId,
            },
        });
    }
    /**
     * Update the details of a single terminal; terminalId *must* refer to an existing terminal
     * @throws ApiError
     */
    public putTerminal({
        terminalId,
        requestBody,
    }: {
        terminalId: string,
        requestBody?: TerminalUpdate,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/terminal/{terminalId}',
            path: {
                'terminalId': terminalId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete a terminal; terminalId *must* refer to an existing terminal
     * @throws ApiError
     */
    public deleteTerminal({
        terminalId,
    }: {
        terminalId: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/terminal/{terminalId}',
            path: {
                'terminalId': terminalId,
            },
        });
    }
    /**
     * Send a command to the terminal (reboot or restart application)
     * @throws ApiError
     */
    public postTerminalCommand({
        terminalId,
        requestBody,
    }: {
        terminalId: string,
        requestBody?: PostTerminalCommandRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/terminal/{terminalId}/command',
            path: {
                'terminalId': terminalId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get the errors of a single terminal, paginated
     * @returns GetTerminalErrorsResponseBody
     * @throws ApiError
     */
    public getTerminalErrors({
        terminalId,
        orderDirection = 'DESC',
        limit = 10,
        pageNumber = 1,
    }: {
        terminalId: string,
        orderDirection?: 'ASC' | 'DESC',
        limit?: number,
        pageNumber?: number,
    }): CancelablePromise<GetTerminalErrorsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/terminal/{terminalId}/errors',
            path: {
                'terminalId': terminalId,
            },
            query: {
                'orderDirection': orderDirection,
                'limit': limit,
                'pageNumber': pageNumber,
            },
        });
    }
    /**
     * Onboarding (terminal registration), part 2 (called by the app): the terminal is associated with an organization
     * @throws ApiError
     */
    public putTerminalHello({
        terminalId,
        requestBody,
    }: {
        terminalId: string,
        requestBody?: TerminalAssociation,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/terminal/{terminalId}/hello',
            path: {
                'terminalId': terminalId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Onboarding (terminal registration), part 3 (called by the tablet): the terminal polls for its status until registered
     * @returns GetTerminalHelloResponseBody
     * @throws ApiError
     */
    public getTerminalHello({
        terminalId,
    }: {
        terminalId: string,
    }): CancelablePromise<GetTerminalHelloResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/terminal/{terminalId}/hello',
            path: {
                'terminalId': terminalId,
            },
        });
    }
    /**
     * Terminal registration (done from alter): associates the terminal with an organization
     * @throws ApiError
     */
    public putTerminalPair({
        terminalId,
        requestBody,
    }: {
        terminalId: string,
        requestBody?: TerminalPairing,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/terminal/{terminalId}/pair',
            path: {
                'terminalId': terminalId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * The terminal asks for Stripe payment data (location and token)
     * @returns GetTerminalReadersetupResponseBody
     * @throws ApiError
     */
    public getTerminalReaderSetup({
        terminalId,
    }: {
        terminalId: string,
    }): CancelablePromise<GetTerminalReadersetupResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/terminal/{terminalId}/reader-setup',
            path: {
                'terminalId': terminalId,
            },
        });
    }
    /**
     * Terminals will periodically call this endpoint to get their new state; the ping rate is set by BE through the nextPingInMs field
     * @returns PostTerminalSyncResponseBody
     * @throws ApiError
     */
    public postTerminalSync({
        terminalId,
        requestBody,
    }: {
        terminalId: string,
        requestBody?: TerminalSyncRequestBody,
    }): CancelablePromise<PostTerminalSyncResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/terminal/{terminalId}/sync',
            path: {
                'terminalId': terminalId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Terminal registration (done from alter): unlink an organization from the terminal
     * @throws ApiError
     */
    public putTerminalUnpair({
        terminalId,
    }: {
        terminalId: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/terminal/{terminalId}/unpair',
            path: {
                'terminalId': terminalId,
            },
        });
    }
}
