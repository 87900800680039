/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostUsersIntercomRequestBody = {
    appVersion: string;
    platform: PostUsersIntercomRequestBody.platform;
    device: string;
    lastReleaseAt: string;
    osVersion?: string;
};
export namespace PostUsersIntercomRequestBody {
    export enum platform {
        IOS = 'ios',
        ANDROID = 'android',
    }
}

