/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostIntentionReporttemplatesRequestBody = {
    name: string;
    description?: string | null;
    type: PostIntentionReporttemplatesRequestBody.type;
    fromGallery?: boolean;
};
export namespace PostIntentionReporttemplatesRequestBody {
    export enum type {
        GOTTESDIENSTORDNUNG = 'gottesdienstordnung',
    }
}

