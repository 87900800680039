import React from 'react';
import styled from 'styled-components';
import { Space } from 'antd';

import { showCreateNewsletterListModal } from './CreateNewsletterList';
import { showCreateParticipantList } from './CreateParticipantList';

import {
  CdNewsletterListIcon,
  CdParticipantList,
} from '@/react/shared/components/Icons';
import { I18nService } from '@/react/services/I18nService';

const DropdownLink = styled.a`
  &:hover {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
  }
`;

export const LaunchCreateNewsletterAndParticipantListModal = () => (
  <>
    <li>
      <DropdownLink onClick={() => showCreateNewsletterListModal()}>
        <Space>
          <CdNewsletterListIcon fixedWidth />
          {I18nService.getString('Create newsletter list')}
        </Space>
      </DropdownLink>
    </li>
    <li>
      <DropdownLink onClick={() => showCreateParticipantList()}>
        <Space>
          <CdParticipantList fixedWidth />
          {I18nService.getString('Create participant list')}
        </Space>
      </DropdownLink>
    </li>
  </>
);
