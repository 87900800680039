import { react2angular } from 'react18-react2angular';

import { ON_ROTAS_ASSIGNEES_CHANGED } from '../shared/components/shifts-list/shifts-list.component';

import { showModal } from '@/react/angular/ReactModalBridge';
import { getEventVisibility } from '@/react/calendar/event-details/services/getEventVisibility';
import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import {
  TimeRegistrationIcon,
  TimeRegistrationLandingPageWrapper,
} from '@/react/user/components/TimeRegistrationLandingPage';
import { MasqueradeLanguageSelector } from '@/react/dashboard/components/MasqueradeLanguageSelector';

(function () {
  'use strict';

  function DashboardController(
    moment,
    $scope,
    $timeout,
    $uibModal,
    $state,
    $stateParams,
    localStorageService,
    gettextCatalog,
    toastr,
    Invitations,
    MyNotifications,
    PeopleMessages,
    Contributions,
    Authorization,
    contributionService,
    Calendar,
    BeaconChat,
    FeatureToggleService
  ) {
    $scope.search = {};
    $scope.limitNum = 6;
    $scope.detailLimitNum = 35;
    $scope.state =
      $stateParams.state === 'invitations' ? 'invitations' : 'upcoming';
    $scope.eventUpcoming = [];
    $scope.eventInvitations = [];
    $scope.notifications = [];
    $scope.notificationsLoaded = false;
    $scope.activities = [];
    $scope.peopleMessages = {};
    $scope.people = [];
    $scope.peopleLoaded = false;
    $scope.selectedRotasTab = 'mine';

    /**
     * Handling GDPR announcement dismissal
     */
    localStorageService.remove('isGdprAnnouncementDismissed');
    localStorageService.remove('isGdprAnnouncement2Dismissed');

    /**
     * A widget should be shown if the organization doesn't have its package enabled (A placeholder will be shown),
     * or if the organization has its package enabled and the user has access to its module.
     */
    $scope.canShowWidget = (packageName, permission) =>
      !Authorization.hasPackage(packageName) ||
      (Authorization.hasPackage(packageName) &&
        Authorization.hasPermission(permission));

    /**
     * Add user
     */
    $scope.addUser = () => {
      showModal('createUserModal', {
        user: {},
      });
    };

    /**
     * Fetch contributions
     */
    if (Authorization.hasPermission('canAccessContributions')) {
      $scope.payments = Contributions.payments();
    }

    /**
     * Fetch people messages
     */
    if (Authorization.hasPermission('canAccessPeople')) {
      const limit = $scope.limitNum;
      const offset = 0;

      $scope.peopleMessages = {
        sent: PeopleMessages.getAll({ limit, offset, status: 'sent' }),
        scheduled: PeopleMessages.getAll({
          limit,
          offset,
          status: 'scheduled',
        }),
      };
    }

    $scope.onAssigneesChanged = () => {
      $scope.$broadcast(ON_ROTAS_ASSIGNEES_CHANGED);
    };

    $scope.launchEventModal = () => {
      if ($scope.newEventFormAsDefault) {
        const churches =
          cdApp.organization.churches.length > 1
            ? []
            : cdApp.organization.churches;
        showModal('EventDrawer', {
          newEvent: { visibility: getEventVisibility(), churches },
        });
      } else {
        $scope.openCreateContentWindow('event');
      }
    };

    /**
     * Fetch upcoming events and invitations.
     */
    function fetchUpcomingAndInvitations() {
      $scope.eventUpcoming.loaded = false;
      $scope.eventInvitations.loaded = false;

      Invitations.query(function (response) {
        $scope.eventInvitations.loaded = true;

        // Filter upcoming.
        $scope.eventUpcoming.upcoming = _.filter(response, function (item) {
          return (
            item.type === 'event' &&
            item.attending !== 'no' &&
            item.attending !== 'no-answer'
          );
        });

        // Filter invitations.
        $scope.eventInvitations.invitations = _.filter(
          response,
          function (item) {
            return (
              item.type === 'event' &&
              item.attending !== 'yes' &&
              item.attending !== 'maybe'
            );
          }
        );

        $scope.eventUpcomingEmpty = _.isEmpty($scope.eventUpcoming.upcoming);
        $scope.eventInvitationsEmpty = _.isEmpty(
          $scope.eventInvitations.invitations
        );

        // Return the total number of invitations without the declined ones.
        $scope.numInvitations = _.filter(
          $scope.eventInvitations.invitations,
          function (inv) {
            return inv.attending !== 'no';
          }
        );
      });
    }

    /**
     * Fetch notifications.
     */
    MyNotifications.query(function (response) {
      $scope.notificationsLoaded = true;
      $scope.notifications = response;
      $scope.notificationsEmpty = _.isEmpty($scope.notifications);
    });

    /**
     * Filter out contributions that didn't go through.
     */
    $scope.filterPayments = () => (payment) =>
      payment.chargeStatus === 'pending' || payment.chargeStatus === 'paid';

    /**
     * Show all declined invitations.
     */
    $scope.showDeclined = function () {
      $uibModal.open({
        templateUrl: '@/app/dashboard/modals/declined-invitations.html',
        scope: $scope,
        windowClass: 'dashboard-declined-invitations modal-scrollable',
        controller: [
          '$scope',
          '$uibModalInstance',
          function ($scope, $uibModalInstance) {
            'ngInject';

            $scope.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };
          },
        ],
      });
    };

    $scope.getMessageLink = function (message, display = 'view', filter) {
      switch (message.type) {
        case 'email':
        case 'sms':
          return $state.href(`app.private.people.messages.${display}`, {
            id: message.id,
            filter,
          });

        default:
          return $state.href(
            `app.private.people.messages.editorV2.${display}`,
            {
              type: message.type,
              messageId: message.id,
              display,
              filter,
            }
          );
      }
    };

    /**
     * Update response to event invitation.
     */
    $scope.updateInvitationResponse = function (response, event) {
      Calendar.updateResponse(
        {
          eventId: event.id,
          response,
        },

        {}
      ).$promise.then(() => {
        toastr.success(
          gettextCatalog.getString(
            'Your attendance status for "{{event}}" has been updated.',
            {
              event: event.title,
            }
          )
        );

        fetchUpcomingAndInvitations();
      });
    };

    /**
     * Check if a given date is in the past.
     */
    $scope.diffDate = function (d) {
      return !moment(d).isBefore();
    };

    /**
     * Open the Messenger.
     */
    $scope.openBeaconChat = function () {
      BeaconChat.showNewMessage();
    };

    fetchUpcomingAndInvitations();

    /**
     * Get the name or email of the contributor
     */
    $scope.getContributorData = contributionService.getContributorData;

    $scope.newEventFormAsDefault = false;

    Promise.all([
      FeatureToggleService.hasFeature('new_event_form_as_default'),
      FeatureToggleService.hasFeature('disable_old_event_form'),
    ]).then(([featureNewEventFormAsDefault, featureHideOldEventForm]) => {
      $scope.newEventFormAsDefault =
        featureNewEventFormAsDefault || featureHideOldEventForm;
    });
  }

  DashboardController.$inject = [
    'moment',
    '$scope',
    '$timeout',
    '$uibModal',
    '$state',
    '$stateParams',
    'localStorageService',
    'gettextCatalog',
    'toastr',
    'Invitations',
    'MyNotifications',
    'PeopleMessages',
    'Contributions',
    'Authorization',
    'contributionService',
    'Calendar',
    'BeaconChat',
    'FeatureToggleService',
  ];

  angular
    .module('cdApp.dashboard')
    .component(
      'timeRegistrationsWidget',
      react2angular(sharedContext.use(TimeRegistrationLandingPageWrapper))
    );
  angular
    .module('cdApp.dashboard')
    .component(
      'timeRegistrationIcon',
      react2angular(sharedContext.use(TimeRegistrationIcon))
    );
  angular
    .module('cdApp.dashboard')
    .component(
      'masqueradeLanguageSelector',
      react2angular(sharedContext.use(MasqueradeLanguageSelector))
    );
  angular
    .module('cdApp.dashboard')
    .controller('DashboardController', DashboardController);
})();
