/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { PostV2PeopleMessagetemplatesRequestBody } from '../models/PostV2PeopleMessagetemplatesRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PeopleMessageTemplateService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * createPeopleMessageTemplate
     * @returns EmptyObject
     * @throws ApiError
     */
    public postV2PeopleMessageTemplates({
        requestBody,
    }: {
        requestBody?: PostV2PeopleMessagetemplatesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/people/message-templates',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * getAllPeopleMessageTemplates
     * @returns EmptyObject
     * @throws ApiError
     */
    public getV2PeopleMessageTemplates({
        type = 'advanced',
    }: {
        type?: 'email' | 'sms' | 'basic' | 'simple' | 'advanced' | 'calendar-poster' | 'event-invitation',
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/people/message-templates',
            query: {
                'type': type,
            },
        });
    }
    /**
     * deletePeopleMessageTemplates
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteV2PeopleMessageTemplates({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/people/message-templates/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * getPeopleMessageTemplate
     * @returns EmptyObject
     * @throws ApiError
     */
    public getV2PeopleMessageTemplates1({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/people/message-templates/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * updatePeopleMessageTemplate
     * @returns EmptyObject
     * @throws ApiError
     */
    public putV2PeopleMessageTemplates({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PostV2PeopleMessagetemplatesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/people/message-templates/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
