import React from 'react';
import { Space, Typography } from 'antd';

import {
  getSafeguardingEmploymentText,
  getSafeguardingLevelText,
  useTableTranslations,
} from '../common/Helpers';

import { I18nService } from '@/react/services/I18nService';
import { ColumnType } from '@/react/shared/components/cd-table';
import { UserUploadData } from '@/react/safeguarding/types/SafeguardingTypes';

export const ProgressModalTableColumn = () => {
  const translations = useTableTranslations();
  return [
    {
      title: translations.firstName,
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: translations.lastName,
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: translations.email,
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: translations.street,
      dataIndex: 'street',
      key: 'street',
    },
    {
      title: translations.city,
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: translations.postCode,
      dataIndex: 'postCode',
      key: 'postCode',
    },
    {
      title: translations.country,
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: translations.safeguardRequirement,
      dataIndex: 'safeguardRequirement',
      key: 'safeguardRequirement',
      render: (requirement) => getSafeguardingLevelText(requirement),
    },
    {
      title: translations.type,
      dataIndex: 'type',
      key: 'type',
      render: (type) => getSafeguardingEmploymentText(type),
    },
    {
      title: translations.workAndResponsibilities,
      dataIndex: 'workAndResponsibilities',
      key: 'workAndResponsibilities',
    },
    {
      title: I18nService.getString('Summary'),
      key: 'summary',
      width: 400,
      render: (data) => (
        <Space direction="vertical">
          {data.errors.length > 0 && (
            <Space direction="vertical">
              {data.errors.map((error: string[], index: any) => (
                <Typography.Text key={index + 'error'} type="danger">
                  {error}.
                </Typography.Text>
              ))}
            </Space>
          )}
          {data.addressUpdateStatus && (
            <Typography.Text type="success">
              {data.addressUpdateStatus}
            </Typography.Text>
          )}
        </Space>
      ),
    },
  ] as ColumnType<UserUploadData>[];
};
