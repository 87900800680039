/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChurchscreenContactIndexResponseBody } from '../models/ChurchscreenContactIndexResponseBody';
import type { ChurchscreenNewslettersResponseBody } from '../models/ChurchscreenNewslettersResponseBody';
import type { EmptyObject } from '../models/EmptyObject';
import type { PostChurchscreenRequestBody } from '../models/PostChurchscreenRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ChurchscreenService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * ChurchscreenUpdateSettings
     * Update
     * @returns EmptyObject
     * @throws ApiError
     */
    public postChurchscreen({
        requestBody,
    }: {
        requestBody?: PostChurchscreenRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/churchscreen',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GatherAllInformationAboutTheChurchScreen
     * Index
     * @returns EmptyObject
     * @throws ApiError
     */
    public getChurchscreen(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/churchscreen',
        });
    }
    /**
     * ChurchscreenContactIndex
     * Contact
     * @returns ChurchscreenContactIndexResponseBody
     * @throws ApiError
     */
    public getChurchscreenContact(): CancelablePromise<ChurchscreenContactIndexResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/churchscreen/contact',
        });
    }
    /**
     * SearchForTheBlogsForChurchscreen
     * Blogs Search
     * @returns EmptyObject
     * @throws ApiError
     */
    public getChurchscreenSearchBlogs({
        search,
    }: {
        /**
         * The search string, based on which we will look for the blogs.
         */
        search: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/churchscreen/search/blogs',
            query: {
                'search': search,
            },
        });
    }
    /**
     * SearchForOutdoorEvents
     * Events Search
     * @returns EmptyObject
     * @throws ApiError
     */
    public getChurchscreenSearchEvents({
        search,
    }: {
        /**
         * The search string, based on which we will look for the events.
         */
        search: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/churchscreen/search/events',
            query: {
                'search': search,
            },
        });
    }
    /**
     * ChurchscreenNewsletters
     * Tags, Index
     * @returns ChurchscreenNewslettersResponseBody
     * @throws ApiError
     */
    public getNewsletters({
        partnerToken,
    }: {
        /**
         * Partner API identifier.
         */
        partnerToken: any,
    }): CancelablePromise<ChurchscreenNewslettersResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/newsletters',
            query: {
                'partnerToken': partnerToken,
            },
        });
    }
}
