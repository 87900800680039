import { intersection } from 'lodash';
import { useCallback, useEffect } from 'react';

import { mainApi } from '../../api';
import { useTaxonomies } from '../../calendar/hooks/use-taxonomies';
import { useGroups } from '../../organization/store/organization';
import { I18nService } from '../../services/I18nService';
import NotificationService from '../../services/NotificationService';
import StateServiceFactory from '../../services/StateServiceFactory';
import {
  ModalType,
  SetModalPropsType,
} from '../../shared/components/cd-modal-new/CdModal2';
import { useFlag } from '../../shared/hooks/useFlag';
import { CategoryType } from '../../shared/models/category';
import { GroupMember } from '../groups.types';

export const useRemoveMemberModal = (
  setModalProps: SetModalPropsType,
  user: GroupMember,
  groupId
) => {
  const stateService = StateServiceFactory();
  const bookingEnquiriesV2Enabled = useFlag('booking_enquiries_v2');

  const onOk = useCallback(async () => {
    const response = await mainApi.put(
      `/groups/${groupId}/users/${user.id}/membership`,
      {
        status: 'remove',
      }
    );
    if (response.ok) {
      NotificationService.notifySuccess(
        user.name
          ? I18nService.getString('{{name}} has been removed from the group.', {
              name: user.name,
            })
          : I18nService.getString('You have been removed from the group.')
      );
      stateService.reload();
    } else {
      NotificationService.notifyError(
        user.name
          ? I18nService.getString(
              '{{name}} could not be removed from the group.',
              {
                name: user.name,
              }
            )
          : I18nService.getString('We could not remove you from the group.')
      );
    }
  }, [user, groupId, stateService]);

  useEffect(() => {
    setModalProps({
      modalType: ModalType.POPUP,
      onOk,
    });
  }, [setModalProps, onOk]);

  const { taxonomies } = useTaxonomies(CategoryType.availability);
  const { myGroupsIds } = useGroups({ userId: user.id });
  const bookingPagesThatWillLoseAvailability =
    taxonomies?.filter((taxonomy) => {
      const groupIsOnTaxonomy = taxonomy.groups
        ?.map((group) => group.id)
        .includes(Number(groupId));

      const otherGroupsUserIsIn = myGroupsIds.filter(
        (id) => id !== Number(groupId)
      );
      const userIsStillInOtherGroup =
        intersection(
          otherGroupsUserIsIn,
          taxonomy.groups?.map((group) => group.id)
        ).length > 0;

      return groupIsOnTaxonomy && !userIsStillInOtherGroup;
    }) || [];

  return {
    bookingPagesThatWillLoseAvailability,
    bookingEnquiriesV2Enabled,
  };
};
