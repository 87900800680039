import { mainApi } from '@/react/api';
import cdApp from '@/react/config';

export const saveOnboardingUser = async (values: {
  onboardingUserEmail: string;
  onboardingUserName: string;
  onboardingUserId: number;
  onboardingUserPhone: string;
}) =>
  mainApi
    .put(`p/organizations/${cdApp.organization.id}/onboardingUser`, values)
    .then((response) => {
      if (response.ok) {
        return;
      } else {
        throw response;
      }
    });

export const getOnboardingUser = async () =>
  mainApi
    .get<{
      onboardingUserEmail: string;
      onboardingUserName: string;
      onboardingUserId: number;
      onboardingUserPhone: string;
    }>(`p/organizations/${cdApp.organization.id}/onboardingUser`)
    .then((response) => {
      if (response.ok) {
        return response.data;
      } else {
        throw response;
      }
    });

export const fetchStripeOnboardingLink = () =>
  mainApi
    .get<{ url: string }>('p/organizations/verification/links')
    .then((response) => {
      if (response.ok) {
        return response.data.url;
      } else {
        throw response;
      }
    });

export const saveLegalInformation = (values: {
  legalBusinessName: string;
  city: string;
  postCode: string;
  line1: string;
  line2: string;
}) =>
  mainApi
    .put(`p/organizations/${cdApp.organization.id}/churchLegalAddress`, values)
    .then((response) => {
      if (response.ok) {
        return;
      } else {
        throw response;
      }
    });

export const getLegalInformation = () =>
  mainApi
    .get<{
      legalBusinessName: string;
      city: string;
      postCode: string;
      line1: string;
      line2: string;
    }>(`p/organizations/${cdApp.organization.id}/churchLegalAddress`)
    .then((response) => {
      if (response.ok) {
        return response.data;
      } else {
        throw response;
      }
    });
