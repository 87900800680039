/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCalendarPermissionsResponseBody } from '../models/CreateCalendarPermissionsResponseBody';
import type { CreateCalendarPosterRequestBody } from '../models/CreateCalendarPosterRequestBody';
import type { DeleteAbsenceOrEventResponseBody } from '../models/DeleteAbsenceOrEventResponseBody';
import type { EmptyObject } from '../models/EmptyObject';
import type { GetCalendarAgendaResponseBody } from '../models/GetCalendarAgendaResponseBody';
import type { GetCalendarChangesResponseBody } from '../models/GetCalendarChangesResponseBody';
import type { GetCalendarExportDownloadtokenResponseBody } from '../models/GetCalendarExportDownloadtokenResponseBody';
import type { GetCalendarFormsSearchResponseBody } from '../models/GetCalendarFormsSearchResponseBody';
import type { GetCalendarInvitationsResponseBody } from '../models/GetCalendarInvitationsResponseBody';
import type { GetUsersResponseBody2 } from '../models/GetUsersResponseBody2';
import type { PostCalendarCalendaridCommentsRequestBody } from '../models/PostCalendarCalendaridCommentsRequestBody';
import type { PostCalendarCheckRequestBody } from '../models/PostCalendarCheckRequestBody';
import type { PostCalendarEventinvitationRequestBody } from '../models/PostCalendarEventinvitationRequestBody';
import type { PostCalendarFormsRequestBody } from '../models/PostCalendarFormsRequestBody';
import type { PostCalendarIcalsubscriptionRequestBody } from '../models/PostCalendarIcalsubscriptionRequestBody';
import type { PostCalendarInvitationsCalendaridAttendingAttendingtextRequestBody } from '../models/PostCalendarInvitationsCalendaridAttendingAttendingtextRequestBody';
import type { PostCalendarMultipleabsencesRequestBody } from '../models/PostCalendarMultipleabsencesRequestBody';
import type { PostCalendarPermissionsRequestBody } from '../models/PostCalendarPermissionsRequestBody';
import type { PostCalendarRequestBody } from '../models/PostCalendarRequestBody';
import type { PostCalendarV2AgendaviewRequestBody } from '../models/PostCalendarV2AgendaviewRequestBody';
import type { PutCalendarCalendaridCommentsIdRequestBody } from '../models/PutCalendarCalendaridCommentsIdRequestBody';
import type { PutCalendarIcalsubscriptionRequestBody } from '../models/PutCalendarIcalsubscriptionRequestBody';
import type { PutCalendarMobileFiltersRequestBody } from '../models/PutCalendarMobileFiltersRequestBody';
import type { PutCalendarRequestBody } from '../models/PutCalendarRequestBody';
import type { RetrieveEventOrAbsenceResponseBody } from '../models/RetrieveEventOrAbsenceResponseBody';
import type { Update_Calendar_mobile_filterResponseBody } from '../models/Update_Calendar_mobile_filterResponseBody';
import type { UpdateCalendarFilterRequestBody } from '../models/UpdateCalendarFilterRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CalendarService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * CreateContentForCalendar
     * Create
     * @returns EmptyObject
     * @throws ApiError
     */
    public postCalendar({
        requestBody,
    }: {
        requestBody?: PostCalendarRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/calendar',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetCalendarIndex
     * Index
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendar({
        start,
        end,
        type,
        groupId,
        showBusyEvents,
    }: {
        /**
         * The start date from which events needs to be fetched.
         */
        start: any,
        /**
         * The end date when the list of the events needs to end.
         */
        end: any,
        /**
         * The type of content, by default will fetch all data.
         */
        type?: any,
        /**
         * The id of the group. Notice that it is mandatory for the group calendar.
         */
        groupId?: any,
        /**
         * Flag that indicates if the events that cannot show their title
         * (instead of the title, they show a default string 'busy') due to access limitations, must be excluded from
         * the results or not. NOTE that if the parameter is not present, the IMPLICIT "default" value is "false". This is,
         * if the parameter is not present in the params object, then it will be assumed that the parameter is present
         * and its value is "false".
         */
        showBusyEvents?: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar',
            query: {
                'start': start,
                'end': end,
                'type': type,
                'groupId': groupId,
                'showBusyEvents': showBusyEvents,
            },
        });
    }
    /**
     * GetCalendarAgenda
     * Retrieve Agenda
     * @returns GetCalendarAgendaResponseBody
     * @throws ApiError
     */
    public getCalendarAgenda({
        month,
        locale,
        timeZone,
        filter = 'all-events',
    }: {
        month: string,
        locale: string,
        timeZone: string,
        filter?: 'all-events' | 'my-events',
    }): CancelablePromise<GetCalendarAgendaResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/agenda',
            query: {
                'month': month,
                'filter': filter,
                'locale': locale,
                'timeZone': timeZone,
            },
        });
    }
    /**
     * GetCalendarAgendaview
     * Retrieve Agenda
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarAgendaview({
        month,
        locale,
        timeZone,
    }: {
        month: string,
        locale: string,
        timeZone: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/agendaview',
            query: {
                'month': month,
                'locale': locale,
                'timeZone': timeZone,
            },
        });
    }
    /**
     * GetCalendarChanges
     * Changes Index
     * @returns GetCalendarChangesResponseBody
     * @throws ApiError
     */
    public getCalendarChanges({
        limit,
        offset,
    }: {
        limit?: number,
        offset?: number,
    }): CancelablePromise<GetCalendarChangesResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/changes',
            query: {
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * CalendarCheckDoubleBooking
     * Double booking check
     * @returns EmptyObject
     * @throws ApiError
     */
    public postCalendarCheck({
        requestBody,
    }: {
        requestBody?: PostCalendarCheckRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/calendar/check',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetCalendarFilters
     * Filters Retrieve
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarData(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/data',
        });
    }
    /**
     * UpdateCalendarFilter
     * Filters Update
     * @returns EmptyObject
     * @throws ApiError
     */
    public putCalendarData({
        requestBody,
    }: {
        requestBody?: UpdateCalendarFilterRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/calendar/data',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetCalendarDayview
     * Retrieve events for the day-view
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarDayview({
        days,
        locale,
        timeZone,
    }: {
        days: Array<string>,
        locale: string,
        timeZone: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/dayview',
            query: {
                'days': days,
                'locale': locale,
                'timeZone': timeZone,
            },
        });
    }
    /**
     * GetCalendarExport
     * Export Calendar
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarExport({
        startDate,
        endDate,
        categories,
        groups,
        resources,
        users,
        search,
        columns,
        output = 'xlsx',
        eventsWithoutResourcesInChurchIds,
    }: {
        startDate: string,
        endDate: string,
        categories?: Array<number> | null,
        groups?: Array<number> | null,
        resources?: Array<number> | null,
        users?: Array<number> | null,
        search?: string | null,
        columns?: Array<string> | null,
        output?: 'isiweb' | 'json' | 'ods' | 'xlsx',
        eventsWithoutResourcesInChurchIds?: Array<number>,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/export',
            query: {
                'startDate': startDate,
                'endDate': endDate,
                'categories': categories,
                'groups': groups,
                'resources': resources,
                'users': users,
                'search': search,
                'columns': columns,
                'output': output,
                'eventsWithoutResourcesInChurchIds': eventsWithoutResourcesInChurchIds,
            },
        });
    }
    /**
     * Endpoint to generate and download an excel file with calendar data.
     * @throws ApiError
     */
    public getCalendarExportDownload({
        startDate,
        endDate,
        downloadToken,
        categories,
        groups,
        resources,
        users,
        search,
        columns,
        output = 'xlsx',
        eventsWithoutResourcesInChurchIds,
        visibility,
        type = 'event',
    }: {
        startDate: string,
        endDate: string,
        downloadToken: string,
        categories?: Array<number>,
        groups?: Array<number>,
        resources?: Array<number>,
        users?: Array<number>,
        search?: string,
        columns?: Array<string>,
        output?: 'isiweb' | 'ods' | 'xlsx',
        eventsWithoutResourcesInChurchIds?: Array<number>,
        visibility?: 'public' | 'all-users' | 'groups',
        type?: 'event' | 'absence',
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/export/download',
            query: {
                'startDate': startDate,
                'endDate': endDate,
                'categories': categories,
                'groups': groups,
                'resources': resources,
                'users': users,
                'search': search,
                'columns': columns,
                'output': output,
                'eventsWithoutResourcesInChurchIds': eventsWithoutResourcesInChurchIds,
                'visibility': visibility,
                'type': type,
                'downloadToken': downloadToken,
            },
        });
    }
    /**
     * Get report export download token
     * @returns GetCalendarExportDownloadtokenResponseBody
     * @throws ApiError
     */
    public getCalendarExportDownloadToken(): CancelablePromise<GetCalendarExportDownloadtokenResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/export/download-token',
        });
    }
    /**
     * GetCalendarExternalEventId
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarExternalEvent({
        id,
    }: {
        id: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/external-event/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Retrieve_Calendar_filter
     * Filters2 Retrieve
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarFilters({
        accessToken,
        version,
    }: {
        accessToken?: string,
        version?: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/filters',
            query: {
                'access_token': accessToken,
                'version': version,
            },
        });
    }
    /**
     * Update_Calendar_filter
     * Filters2 Update
     * @returns EmptyObject
     * @throws ApiError
     */
    public putCalendarFilters({
        accessToken,
        requestBody,
    }: {
        accessToken?: string,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/calendar/filters',
            query: {
                'access_token': accessToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * PostCalendarForms
     * @returns EmptyObject
     * @throws ApiError
     */
    public postCalendarForms({
        requestBody,
    }: {
        requestBody?: PostCalendarFormsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/calendar/forms',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetCalendarFormsSearch
     * @returns GetCalendarFormsSearchResponseBody
     * @throws ApiError
     */
    public getCalendarFormsSearch({
        title,
        sortBy,
        sortDirection,
    }: {
        title?: string | null,
        sortBy?: string,
        sortDirection?: string,
    }): CancelablePromise<GetCalendarFormsSearchResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/forms/search',
            query: {
                'title': title,
                'sortBy': sortBy,
                'sortDirection': sortDirection,
            },
        });
    }
    /**
     * DeleteCalendarFormsCalendarid
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteCalendarForms({
        calendarId,
    }: {
        calendarId: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/calendar/forms/{calendarId}',
            path: {
                'calendarId': calendarId,
            },
        });
    }
    /**
     * GetCalendar
     * Index FullCalendar (DEPRECATED)
     * @returns EmptyObject
     * @throws ApiError
     */
    public postCalendarFullcalendar({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/calendar/fullcalendar',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetHolidays
     * Holidays
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarHolydays({
        country,
        type,
        year,
    }: {
        country: string,
        type?: 'liturgical' | 'national',
        year?: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/holydays/{country}/{year}',
            path: {
                'country': country,
                'year': year,
            },
            query: {
                'type': type,
            },
        });
    }
    /**
     * CreateICalSubscription
     * @returns EmptyObject
     * @throws ApiError
     */
    public postCalendarIcalSubscription({
        requestBody,
    }: {
        requestBody?: PostCalendarIcalsubscriptionRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/calendar/ical-subscription',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetICalSubscriptionsPerUser
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarIcalSubscription(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/ical-subscription',
        });
    }
    /**
     * DeleteICalSubscription
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteCalendarIcalSubscription({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/calendar/ical-subscription/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * GetICalSubscription
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarIcalSubscription1({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/ical-subscription/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * UpdateICalSubscription
     * @returns EmptyObject
     * @throws ApiError
     */
    public putCalendarIcalSubscription({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PutCalendarIcalsubscriptionRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/calendar/ical-subscription/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * ForceSyncICalSubscription
     * @returns EmptyObject
     * @throws ApiError
     */
    public postCalendarIcalSubscriptionForceSync({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/calendar/ical-subscription/{id}/force-sync',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * PostCalendarImportFile
     * Export Calendar
     * @returns EmptyObject
     * @throws ApiError
     */
    public postCalendarImportFile({
        sourceName,
        isPreview,
        churchIds,
        authorId,
        defaultEventCategoryName,
        defaultAbsenceCategoryName,
        eventType,
        groupId,
        bookingUserId,
        startDateFilter,
        doNotCreateResources = false,
        requestBody,
    }: {
        sourceName: 'kaboo' | 'google' | 'outlook' | 'easybooking' | 'easybooking-json',
        isPreview: boolean,
        churchIds: string,
        authorId?: number,
        defaultEventCategoryName?: string,
        defaultAbsenceCategoryName?: string,
        eventType?: 'absence' | 'availability' | 'event' | 'feed' | 'external' | 'eventSeriesSource' | '' | null,
        groupId?: number,
        bookingUserId?: number,
        startDateFilter?: string,
        doNotCreateResources?: boolean,
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/calendar/import/file',
            query: {
                'sourceName': sourceName,
                'isPreview': isPreview,
                'authorId': authorId,
                'defaultEventCategoryName': defaultEventCategoryName,
                'defaultAbsenceCategoryName': defaultAbsenceCategoryName,
                'eventType': eventType,
                'groupId': groupId,
                'bookingUserId': bookingUserId,
                'churchIds': churchIds,
                'startDateFilter': startDateFilter,
                'doNotCreateResources': doNotCreateResources,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetCalendarInvitations
     * Invitations Index
     * @returns GetCalendarInvitationsResponseBody
     * @throws ApiError
     */
    public getCalendarInvitations({
        limit = 30,
        status,
    }: {
        limit?: number,
        status?: 'no-answer' | 'yes' | 'no' | 'maybe',
    }): CancelablePromise<GetCalendarInvitationsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/invitations',
            query: {
                'limit': limit,
                'status': status,
            },
        });
    }
    /**
     * GetCalendarInvitationsCalendaridAttendingAttendingtext
     * Save anon. attending
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarInvitationsAttending({
        attendingToken,
        calendarId,
        attendingText,
        status = 'pending',
    }: {
        attendingToken: string,
        calendarId: number,
        attendingText: 'no-answer' | 'yes' | 'no' | 'maybe',
        status?: 'confirmed' | 'pending',
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/invitations/{calendarId}/attending/{attendingText}',
            path: {
                'calendarId': calendarId,
                'attendingText': attendingText,
            },
            query: {
                'attendingToken': attendingToken,
                'status': status,
            },
        });
    }
    /**
     * PostCalendarInvitationsCalendaridAttendingAttendingtext
     * Save auth. attending
     * @returns EmptyObject
     * @throws ApiError
     */
    public postCalendarInvitationsAttending({
        calendarId,
        attendingText,
        requestBody,
    }: {
        calendarId: number,
        attendingText: 'no-answer' | 'yes' | 'no' | 'maybe',
        requestBody?: PostCalendarInvitationsCalendaridAttendingAttendingtextRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/calendar/invitations/{calendarId}/attending/{attendingText}',
            path: {
                'calendarId': calendarId,
                'attendingText': attendingText,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get_latest_used_location_names_for_location_name_suggestions,_max_10
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarLocationNameSuggestions(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/location-name/suggestions',
        });
    }
    /**
     * Get_Calendar_mobile_filter
     * Get Calendar Filter Mobile
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarMobileFilters({
        useOrderedFilters = false,
    }: {
        useOrderedFilters?: boolean,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/mobile/filters',
            query: {
                'useOrderedFilters': useOrderedFilters,
            },
        });
    }
    /**
     * Update_Calendar_mobile_filter
     * Update Calendar Filter Mobile
     * @returns Update_Calendar_mobile_filterResponseBody
     * @throws ApiError
     */
    public putCalendarMobileFilters({
        requestBody,
    }: {
        requestBody?: PutCalendarMobileFiltersRequestBody,
    }): CancelablePromise<Update_Calendar_mobile_filterResponseBody> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/calendar/mobile/filters',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Multiple_absences
     * Multiple absences
     * @returns EmptyObject
     * @throws ApiError
     */
    public postCalendarMultipleAbsences({
        accessToken,
        requestBody,
    }: {
        accessToken?: string,
        requestBody?: PostCalendarMultipleabsencesRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/calendar/multiple-absences',
            query: {
                'access_token': accessToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * CreateCalendarPermissions
     * Create Calendar Permissions
     * @returns CreateCalendarPermissionsResponseBody
     * @throws ApiError
     */
    public postCalendarPermissions({
        requestBody,
    }: {
        requestBody?: PostCalendarPermissionsRequestBody,
    }): CancelablePromise<CreateCalendarPermissionsResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/calendar/permissions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Generate calendar data for print
     * @throws ApiError
     */
    public getCalendarPrint({
        startDate,
        endDate,
        categories,
        groups,
        resources,
        users,
        search,
        eventsWithoutResourcesInChurchIds,
        shouldRemoveAbsences = false,
        visibility,
        type = 'event',
    }: {
        startDate: string,
        endDate: string,
        categories?: (((number | '') | Array<number>) | Record<string, number>),
        groups?: (((number | '') | Array<number>) | Record<string, number>),
        resources?: (((number | '') | Array<number>) | Record<string, number>),
        users?: (((number | '') | Array<number>) | Record<string, number>),
        search?: string | null,
        eventsWithoutResourcesInChurchIds?: (((number | '') | Array<number>) | Record<string, number>),
        shouldRemoveAbsences?: boolean,
        visibility?: 'public' | 'all-users' | 'groups',
        type?: 'event' | 'absence',
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/print',
            query: {
                'startDate': startDate,
                'endDate': endDate,
                'categories': categories,
                'groups': groups,
                'resources': resources,
                'users': users,
                'search': search,
                'eventsWithoutResourcesInChurchIds': eventsWithoutResourcesInChurchIds,
                'shouldRemoveAbsences': shouldRemoveAbsences,
                'visibility': visibility,
                'type': type,
            },
        });
    }
    /**
     * GetCalendarStudioItems
     * GET STUDIO DOCUMENTS
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarStudio({
        limit,
        searchBefore,
        searchAfter,
        calendarId,
        version = 1,
    }: {
        limit?: number,
        searchBefore?: string,
        searchAfter?: string,
        calendarId?: number,
        version?: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/studio',
            query: {
                'limit': limit,
                'searchBefore': searchBefore,
                'searchAfter': searchAfter,
                'calendarId': calendarId,
                'version': version,
            },
        });
    }
    /**
     * GetCalendarToday
     * Retrieve calendars for the current day
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarToday({
        locale,
        timeZone,
    }: {
        locale: string,
        timeZone: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/today',
            query: {
                'locale': locale,
                'timeZone': timeZone,
            },
        });
    }
    /**
     * PostCalendarV2Agendaview
     * Retrieve Agenda
     * @returns EmptyObject
     * @throws ApiError
     */
    public postCalendarV2Agendaview({
        requestBody,
    }: {
        requestBody?: PostCalendarV2AgendaviewRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/calendar/v2/agendaview',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetCalendar
     * Index FullCalendar
     * @returns EmptyObject
     * @throws ApiError
     */
    public postCalendarV2Fullcalendar({
        requestBody,
    }: {
        requestBody?: EmptyObject,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/calendar/v2/fullcalendar',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetCalendarV2Invitations
     * Get User Invitations
     * @returns GetCalendarInvitationsResponseBody
     * @throws ApiError
     */
    public getCalendarV2Invitations(): CancelablePromise<GetCalendarInvitationsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/v2/invitations',
        });
    }
    /**
     * PostCalendarCalendaridComments
     * Comment Create
     * @returns EmptyObject
     * @throws ApiError
     */
    public postCalendarComments({
        requestBody,
    }: {
        requestBody?: PostCalendarCalendaridCommentsRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/calendar/{calendarId}/comments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeleteCalendarComments
     * Comment Delete
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteCalendarComments(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/calendar/{calendarId}/comments/{id}',
        });
    }
    /**
     * PutCalendarCalendaridCommentsId
     * Comment Update
     * @returns EmptyObject
     * @throws ApiError
     */
    public putCalendarComments({
        requestBody,
    }: {
        requestBody?: PutCalendarCalendaridCommentsIdRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/calendar/{calendarId}/comments/{id}',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * CreateCalendarPoster
     * CREATE EVENT INVITATION
     * @returns EmptyObject
     * @throws ApiError
     */
    public postCalendarEventInvitation({
        templateId,
        calendarId,
        requestBody,
    }: {
        templateId: string,
        calendarId: number,
        requestBody?: PostCalendarEventinvitationRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/calendar/{calendarId}/event-invitation',
            path: {
                'calendarId': calendarId,
            },
            query: {
                'templateId': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * CreateCalendarPoster
     * CREATE POSTER
     * @returns EmptyObject
     * @throws ApiError
     */
    public postCalendarPoster({
        templateId,
        calendarId,
        requestBody,
    }: {
        templateId: string,
        calendarId: number,
        requestBody?: CreateCalendarPosterRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/calendar/{calendarId}/poster',
            path: {
                'calendarId': calendarId,
            },
            query: {
                'templateId': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetSinglePoster
     * GET POSTER EXPORT
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarPosterExport({
        exportToken,
        calendarId,
        format,
        pdfSize,
    }: {
        exportToken: string,
        calendarId: number,
        format?: 'pdf' | 'jpg' | 'png',
        pdfSize?: 'a3' | 'a4',
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/{calendarId}/poster/export',
            path: {
                'calendarId': calendarId,
            },
            query: {
                'exportToken': exportToken,
                'format': format,
                'pdfSize': pdfSize,
            },
        });
    }
    /**
     * DeleteSinglePoster
     * DELETE POSTER
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteCalendarPoster({
        calendarId,
        posterId,
    }: {
        calendarId: number,
        posterId: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/calendar/{calendarId}/poster/{posterId}',
            path: {
                'calendarId': calendarId,
                'posterId': posterId,
            },
        });
    }
    /**
     * GetSingleCalendarPoster
     * GET POSTER
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarPoster({
        calendarId,
        posterId,
    }: {
        calendarId: number,
        posterId: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/{calendarId}/poster/{posterId}',
            path: {
                'calendarId': calendarId,
                'posterId': posterId,
            },
        });
    }
    /**
     * UpdatePoster
     * UPDATE POSTER
     * @returns EmptyObject
     * @throws ApiError
     */
    public putCalendarPoster({
        posterId,
        calendarId,
        requestBody,
    }: {
        posterId: number,
        calendarId: number,
        requestBody?: GetUsersResponseBody2,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/calendar/{calendarId}/poster/{posterId}',
            path: {
                'posterId': posterId,
                'calendarId': calendarId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * DeleteAbsenceOrEvent
     * Delete
     * @returns DeleteAbsenceOrEventResponseBody
     * @throws ApiError
     */
    public deleteCalendar({
        id,
        accessToken,
        sendNotifications,
        deleteMethod = 'single',
    }: {
        id: number,
        accessToken?: string,
        sendNotifications?: string,
        deleteMethod?: 'all' | 'following' | 'single',
    }): CancelablePromise<DeleteAbsenceOrEventResponseBody> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/calendar/{id}',
            path: {
                'id': id,
            },
            query: {
                'access_token': accessToken,
                'sendNotifications': sendNotifications,
                'deleteMethod': deleteMethod,
            },
        });
    }
    /**
     * RetrieveEventOrAbsence
     * Retrieve
     * @returns RetrieveEventOrAbsenceResponseBody
     * @throws ApiError
     */
    public getCalendar1({
        id,
        accessToken,
        imageFormat = 'span5_16-9',
        parseHtmlDescToText = false,
        clientVersion,
    }: {
        id: number,
        accessToken?: string,
        imageFormat?: string,
        parseHtmlDescToText?: boolean,
        clientVersion?: number,
    }): CancelablePromise<RetrieveEventOrAbsenceResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/{id}',
            path: {
                'id': id,
            },
            query: {
                'access_token': accessToken,
                'imageFormat': imageFormat,
                'parseHtmlDescToText': parseHtmlDescToText,
                'clientVersion': clientVersion,
            },
        });
    }
    /**
     * UpdateRecordInCalendar
     * Update
     * @returns EmptyObject
     * @throws ApiError
     */
    public putCalendar({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PutCalendarRequestBody,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/calendar/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetCalendarIdRegistrations
     * Retrieve event registrations
     * @returns EmptyObject
     * @throws ApiError
     */
    public getCalendarRegistrations({
        id,
        version = 1,
    }: {
        id: number,
        version?: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/{id}/registrations',
            path: {
                'id': id,
            },
            query: {
                'version': version,
            },
        });
    }
}
