/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Table, Button, Form, Card } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/pro-regular-svg-icons';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { I18nService } from '../../../services/I18nService';
import {
  fetchAllCollections,
  setEditId,
  removeCollection,
} from '../../redux/collection/Actions';
import {
  collectionItemsSelector,
  isEditingSelector,
} from '../../redux/collection/Selectors';
import { Collection } from '../../types/Collection';
import { DisplayDateSimple } from '../../../shared/components/FormattedDate';

import CollectionForm from './CollectionForm';

import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import { CdPage } from '@/react/shared/components/cd-page/CdPage';

export function CollectionYearList() {
  const dispatch = useDispatch();
  const collections = useSelector(collectionItemsSelector);
  const isEditing = useSelector(isEditingSelector);
  const [form] = Form.useForm();

  const closeDrawer = React.useCallback(() => {
    form.resetFields();
    dispatch(setEditId(false));
  }, []);
  const editCollete = React.useCallback((id) => dispatch(setEditId(id)), []);
  const deleteCollection = React.useCallback(
    (id) =>
      showConfirmModal({
        title: I18nService.getString('Delete collection'),
        message: I18nService.getString('Are you sure you want to delete?'),
        okButtonProps: { danger: true },
        okText: I18nService.getString('Delete'),
        onOk: () => {
          dispatch(removeCollection(id));
        },
      }),
    []
  );

  React.useEffect(() => {
    dispatch(
      fetchAllCollections({
        startDate: moment().startOf('year').format('YYYY-MM-DD'),
        endDate: moment().endOf('year').format('YYYY-MM-DD'),
      })
    );
  }, []);

  return (
    <>
      <Drawer
        closable
        placement="right"
        open={isEditing}
        onClose={closeDrawer}
        destroyOnClose
        width="40%"
        footer={
          <Button onClick={form.submit} type="primary" htmlType="submit">
            {I18nService.getString('Save')}
          </Button>
        }
      >
        <CollectionForm form={form} />
      </Drawer>
      <CdPage
        pageHeaderProps={{
          title: I18nService.getString('Collection'),
          subTitle: I18nService.getString('View and edit your collection plan'),
          extra: (
            <Button onClick={() => editCollete('new')}>
              {I18nService.getString('Create new collection')}
            </Button>
          ),
        }}
      >
        <Card>
          <Table
            pagination={{ hideOnSinglePage: true }}
            bordered
            columns={[
              {
                title: I18nService.getString('Number'),
                width: '10%',
                dataIndex: 'number',
                sorter: (first, second) =>
                  first.number > second.number ? 1 : -1,
              },
              {
                title: I18nService.getString('Date'),
                width: '20%',
                dataIndex: 'date',
                defaultSortOrder: 'ascend',
                sorter: (first, second) =>
                  moment(first.date).unix() - moment(second.date).unix(),
                render: function SimpleDate(date) {
                  return <DisplayDateSimple date={date} allDay={true} />;
                },
              },
              {
                title: I18nService.getString('Purpose'),
                width: '40%',
                dataIndex: 'purpose',
                sorter: (first, second) =>
                  first.purpose.localeCompare(second.purpose),
              },
              {
                title: I18nService.getString('Name of day'),
                width: '30%',
                dataIndex: 'nameOfDay',
                sorter: (first, second) =>
                  first.nameOfDay.localeCompare(second.nameOfDay),
              },
              {
                key: 'id',
                render: function EditCollection({ id }) {
                  return (
                    <Button type="primary" onClick={() => editCollete(id)}>
                      <FontAwesomeIcon icon={faPencilAlt} className="anticon" />
                      <span>{I18nService.getString('Edit')}</span>
                    </Button>
                  );
                },
              },
              {
                key: 'id',
                render: function DeleteCollection({ id }) {
                  return (
                    <Button onClick={() => deleteCollection(id)}>
                      <FontAwesomeIcon icon={faTrashAlt} className="anticon" />
                      <span>{I18nService.getString('Delete')}</span>
                    </Button>
                  );
                },
              },
            ]}
            dataSource={Array.from(collections) as Collection[]}
            rowKey="id"
          />
        </Card>
      </CdPage>
    </>
  );
}
