import _ from 'lodash';
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../redux';
import { I18nService } from '../../services/I18nService';
import {
  ToggleShowLiturgicalHolidays,
  ToggleShowNationalHolidays,
} from '../store/main-view/Actions';

import { SearchableFilterItem } from '@/react/calendar/components/SearchableFilterItem';
import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';

/**
 * TODO: This style is repeated in `src/React/Calendar/components/CalendarItem.tsx`!
 * Style definitions should NOT be mixed with structural components!!!
 */

export const getClassName = (isSelected: boolean, color: number): string =>
  `bullet color-${color || 0} ${isSelected ? 'bullet-full' : 'bullet-empty'}`;

const HolidaysFilter: FunctionComponent<{ isDisabled: boolean }> = ({
  isDisabled,
}) => {
  const dispatch = useDispatch();
  const onToggleNationalHolidays = useCallback(
    () => dispatch(ToggleShowNationalHolidays()),
    [dispatch]
  );
  const onToggleLiturgicalHolidays = useCallback(
    () => dispatch(ToggleShowLiturgicalHolidays()),
    [dispatch]
  );
  const liturgicalHolidays = useSelector(
    (state: AppState) => state.calendar.view.liturgicalHolidays
  );
  const nationalHolidays = useSelector(
    (state: AppState) => state.calendar.view.nationalHolidays
  );
  const areNationalHolidaysSelected = useSelector(
    (state: AppState) => state.calendar.view.showNationalHolidays
  );
  const areLiturgicalHolidaysSelected = useSelector(
    (state: AppState) => state.calendar.view.showLiturgicalHolidays
  );

  const content = (
    <div>
      {/* Liturgical Calendar */}
      {!_.isEmpty(liturgicalHolidays) ? (
        <SearchableFilterItem
          isDisabled={isDisabled}
          onLabelClick={() => {
            if (isDisabled) return;
            onToggleLiturgicalHolidays();
          }}
          color="liturgicalCalendar"
          isSelected={areLiturgicalHolidaysSelected}
          name={I18nService.getString('Liturgical calendar')}
        />
      ) : null}
      {/* National Holidays */}
      {!_.isEmpty(nationalHolidays) ? (
        <SearchableFilterItem
          isDisabled={isDisabled}
          onLabelClick={() => {
            if (isDisabled) return;
            onToggleNationalHolidays();
          }}
          color="nationalHolidays"
          isSelected={areNationalHolidaysSelected}
          name={I18nService.getString('National holidays')}
        />
      ) : null}
    </div>
  );
  if (isDisabled) {
    return (
      <CdTooltip
        title={I18nService.getString(
          'This calendar cannot be enabled for this view'
        )}
      >
        {content}
      </CdTooltip>
    );
  }
  return content;
};

export default HolidaysFilter;
