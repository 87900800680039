import { Button, Image, Space } from 'antd';
import React from 'react';

import cdApp from '@/react/config';
import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';

export const CdSSOButtons = () => (
  <Space>
    <SSOButton
      provider="mainz"
      tooltip="Katholisch Bistum Mainz"
      logo="https://assets.churchdesk.com/sso/Logo-Bistum-Mainz-crop.png?class=small"
      flow="implicit"
    />
    <SSOButton
      provider="ekkw"
      tooltip="Evangelische Kirche von Kurhessen-Waldeck"
      logo="https://assets.churchdesk.com/sso/Logo-EKKW.png?class=small"
      flow="implicit"
    />
    <SSOButton
      provider="ekir"
      tooltip="Evangelischen Kirche im Rheinland"
      logo="https://assets.churchdesk.com/sso/Logo-EKIR.webp?class=small"
      flow="code"
    />
    <SSOButton
      provider="fulda"
      tooltip="Katholisch Bistum Fulda"
      logo="https://assets.churchdesk.com/sso/Logo-Bistum-Fulda.png?class=small"
      flow="code"
    />
  </Space>
);

const SSOButton = (props: {
  provider: string;
  logo: string;
  tooltip: string;
  flow?: string;
}) => (
  <Button
    shape="circle"
    size="large"
    style={{
      padding: 0,
      width: '60px',
      height: '60px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
    }}
    href={`${cdApp.config.api.main}/login/sso?provider=${props.provider}&env=prod&flow=${props.flow}`}
  >
    <CdTooltip title={props.tooltip}>
      <Image preview={false} width={37} src={props.logo} />
    </CdTooltip>
  </Button>
);
