/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostIntentionFeesRequestBody = {
    type: PostIntentionFeesRequestBody.type;
    name: string;
    description?: string | null;
    active: boolean;
    isDefault?: boolean;
    feeAllocations: Array<{
        recipientId?: string;
        amount: number;
        shouldForwardShare?: boolean;
    }>;
    taxonomies?: Array<{
        id: number;
    }>;
};
export namespace PostIntentionFeesRequestBody {
    export enum type {
        INTENTIONEN = 'intentionen',
        STOLARIEN = 'stolarien',
    }
}

