import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import { createAction } from '@reduxjs/toolkit';

import { CalendarFilterData } from '../../models/calendarData';
import { Church } from '../../models/calendar';

import {
  AbsenceView,
  ChurchCategories,
  FilterState,
  SelectedCalendar,
} from '.';

import { ActionCreator, CdAction } from '@/react/redux/utils';
import { Category } from '@/react/shared/models/category';
import { MyCalendarSelectionState } from '@/react/calendar/store/filters/types';

export const FETCH_CALENDAR_FILTER_DATA = 'calendar/fetchCalendarFilterData';

export type FetchCalendarFilterDataSuccess = CdAction<CalendarFilterData>;
export const FETCH_CALENDAR_FILTER_DATA_SUCCESS =
  'calendar/fetchCalendarFilterDataSuccess';
export const FetchCalendarFilterDataAction: ActionCreator<CalendarFilterData> =
  (calendarFilterData) => ({
    type: FETCH_CALENDAR_FILTER_DATA_SUCCESS,
    payload: calendarFilterData,
  });

export type ToggleMeFilter = CdAction<undefined>;
export const TOGGLE_ME_FILTER = 'calendar/toggleMeFilter';
export const ToggleMeFilter: ActionCreator<undefined> = () => ({
  type: TOGGLE_ME_FILTER,
});

export type AddCalendar = CdAction<SelectedCalendar>;
export const ADD_CALENDAR = 'calendar/addCalendar';
export const AddCalendar: ActionCreator<SelectedCalendar> = (
  selectedCalendar
) => ({
  type: ADD_CALENDAR,
  payload: selectedCalendar,
});

export type RemoveCalendar = CdAction<SelectedCalendar>;
export const REMOVE_CALENDAR = 'calendar/removeCalendar';
export const RemoveCalendar: ActionCreator<SelectedCalendar> = (
  selectedCalendar
) => ({
  type: REMOVE_CALENDAR,
  payload: selectedCalendar,
});

export type ToggleCalendar = CdAction<SelectedCalendar>;
export const TOGGLE_CALENDAR = 'calendar/ToggleCalendar';
export const ToggleCalendar: ActionCreator<SelectedCalendar> = (calendar) => ({
  type: TOGGLE_CALENDAR,
  payload: calendar,
});

export type ClearFilters = CdAction<undefined>;
export const CLEAR_FILTERS = 'calendar/ClearFilters';
export const ClearFilters: ActionCreator<undefined> = () => ({
  type: CLEAR_FILTERS,
});

export interface CategoryFilterPayload {
  churchId?: number;
  categoryId: number;
}
export type AddCategory = CdAction<CategoryFilterPayload>;
export const ADD_CATEGORY = 'calendar/AddCategory';
export const AddCategory: ActionCreator<CategoryFilterPayload> = (payload) => ({
  type: ADD_CATEGORY,
  payload,
});

export type RemoveCategory = CdAction<CategoryFilterPayload>;
export const REMOVE_CATEGORY = 'calendar/RemoveCategory';
export const RemoveCategory: ActionCreator<CategoryFilterPayload> = (
  payload
) => ({
  type: REMOVE_CATEGORY,
  payload,
});

export type ToggleCategory = CdAction<CategoryFilterPayload>;
export const TOGGLE_CATEGORY = 'calendar/ToggleCategory';
export const ToggleCategory: ActionCreator<CategoryFilterPayload> = (
  payload
) => ({
  type: TOGGLE_CATEGORY,
  payload,
});

export interface UpdateCategoryCalendarFiltersRequestPayload {
  churchIds: number[];
  category: Category;
}

export type UpdateCategoryCalendarFiltersRequest =
  CdAction<UpdateCategoryCalendarFiltersRequestPayload>;
export const UPDATE_CATEGORY_CALENDAR_FILTERS_REQUEST =
  'calendar/UpdateCategoryCalendarFiltersRequest';
export const UpdateCategoryCalendarFiltersRequest: ActionCreator<
  UpdateCategoryCalendarFiltersRequestPayload
> = (payload) => ({
  type: UPDATE_CATEGORY_CALENDAR_FILTERS_REQUEST,
  payload,
});

export interface UserFilterPayload {
  userId: number;
}
export type AddUser = CdAction<UserFilterPayload>;
export const ADD_USER = 'calendar/AddUser';
export const AddUser: ActionCreator<UserFilterPayload> = (payload) => ({
  type: ADD_USER,
  payload,
});

export interface ResourceFilterPayload {
  resourceId: number;
}
export type AddResource = CdAction<ResourceFilterPayload>;
export const ADD_RESOURCE = 'calendar/AddResource';
export const AddResource: ActionCreator<ResourceFilterPayload> = (payload) => ({
  type: ADD_RESOURCE,
  payload,
});

interface SelectAllCategoriesPayload {
  churchId?: number;
  lowercaseSearchTerm?: string;
}
export type SelectAllCategories = CdAction<SelectAllCategoriesPayload>;
export const SELECT_ALL_CATEGORIES = 'calendar/SelectAllCategories';
export const SelectAllCategories: ActionCreator<SelectAllCategoriesPayload> = (
  payload
) => ({
  type: SELECT_ALL_CATEGORIES,
  payload,
});

export type UnselectAllCategories = CdAction<SelectAllCategoriesPayload>;
export const UNSELECT_ALL_CATEGORIES = 'calendar/UnselectAllCategories';
export const UnselectAllCategories: ActionCreator<SelectAllCategoriesPayload> =
  (payload) => ({
    type: UNSELECT_ALL_CATEGORIES,
    payload,
  });

export type ToggleSelectAllCategories = CdAction<SelectAllCategoriesPayload>;
export const TOGGLE_SELECT_ALL_CATEGORIES =
  'calendar/ToggleSelectAllCategories';
export const ToggleSelectAllCategories: ActionCreator<
  SelectAllCategoriesPayload
> = (payload) => ({
  type: TOGGLE_SELECT_ALL_CATEGORIES,
  payload,
});

export type AdjustCategoryFilters = CdAction<number[]>;
export const ADJUST_CATEGORY_FILTERS = 'calendar/AdjustCategoryFilters';
export const AdjustCategoryFilters: ActionCreator<number[]> = (payload) => ({
  type: ADJUST_CATEGORY_FILTERS,
  payload,
});

export type InitializeFiltersState = CdAction<Partial<FilterState>>;
export const INITIALIZE_FILTERS_STATE = 'calendar/InitializeFiltersState';
export const InitializeFiltersState: ActionCreator<Partial<FilterState>> = (
  criteria: Partial<FilterState>
) => ({
  type: INITIALIZE_FILTERS_STATE,
  payload: criteria,
});

export type changeAbsenceView = CdAction<AbsenceView>;
export const CHANGE_ABSENCE_VIEW = 'calendar/changeAbsenceView';
export const changeAbsenceView: ActionCreator<AbsenceView> = (payload) => ({
  type: CHANGE_ABSENCE_VIEW,
  payload: payload,
});

export const useChangeAbsenceView = (): ((
  absenceView: AbsenceView
) => void) => {
  const dispatch = useDispatch();
  return useMemo(
    () => (absenceView: AbsenceView) => {
      dispatch(changeAbsenceView(absenceView));
    },
    [dispatch]
  );
};

export type toggleAbsenceView = CdAction<undefined>;
export const TOGGLE_ABSENCE_VIEW = 'calendar/toggleAbsenceView';
export const toggleAbsenceView: ActionCreator<undefined> = () => ({
  type: TOGGLE_ABSENCE_VIEW,
});

export const useToggleAbsenceView = (): (() => void) => {
  const dispatch = useDispatch();
  return () => dispatch(toggleAbsenceView());
};
export type ToggleCalendarSubscription = CdAction<{
  id: string;
  isChecked: boolean;
}>;
export const TOGGLE_CALENDAR_SUBSCRIPTION =
  'calendar/toggleCalendarSubscription';
export const ToggleCalendarSubscriptionAction: ActionCreator<{
  id: string;
  isChecked: boolean;
}> = (payload) => ({
  type: TOGGLE_CALENDAR_SUBSCRIPTION,
  payload,
});

export type SelectAllCategoriesOfOrganization = CdAction<ChurchCategories>;
export const SELECT_ALL_CATEGORIES_OF_ORGANIZATION =
  'calendar/selectAllCategoriesOfOrganization';
export const selectAllCategoriesOfOrganization: ActionCreator<
  ChurchCategories
> = (payload) => ({
  type: SELECT_ALL_CATEGORIES_OF_ORGANIZATION,
  payload,
});

export type ToggleAllMyCalendars = CdAction<MyCalendarSelectionState>;
export const TOGGLE_ALL_MY_CALENDARS = 'calendar/toggleAllMyCalendars';
export const toggleAllMyCalendars: ActionCreator<MyCalendarSelectionState> = (
  payload
) => ({
  type: TOGGLE_ALL_MY_CALENDARS,
  payload,
});

export type ToggleUsersAndRoomsCalenders = CdAction<{
  calendars?: SelectedCalendar[];
  shouldSelect: boolean;
}>;
export const TOGGLE_USERS_AND_ROOMS_CALENDERS =
  'calendar/toggleUsersAndRoomsCalenders';
export const toggleUsersAndRoomsCalenders: ActionCreator<{
  calendars?: SelectedCalendar[];
  shouldSelect: boolean;
}> = (payload) => ({
  type: TOGGLE_USERS_AND_ROOMS_CALENDERS,
  payload,
});

export interface ToggleAllParishesPayload {
  isActive: boolean;
  churches: Church[];
  categories: Category[];
}

export type ToggleAllParishes = CdAction<ToggleAllParishesPayload>;
export const TOGGLE_ALL_PARISHES = 'calendar/ToggleAllParishes';
export const toggleAllParishes: ActionCreator<ToggleAllParishesPayload> = (
  payload
) => ({
  type: TOGGLE_ALL_PARISHES,
  payload,
});

export const userFiltersLoaded = createAction('calendar/userFiltersLoaded');

export const setIsInitializeFirstTime = createAction(
  'calendar/setIsInitializeFirstTime'
);
