import React from 'react';
import { Form, Spin, Typography } from 'antd';
import { truncate } from 'lodash';

import { I18nService } from '../../../services/I18nService';
import { useAnniversarySettings } from '../hooks/useAnniversary';

import { AnniversaryNotifyUsers } from './AnniversaryNotifyUsers';
import { SpecialDays } from './SpecialDays';

import { CdVerticalSpace } from '@/react/shared/components/cd-vertical-space/CdVerticalSpace';
import { CdCheckbox } from '@/react/shared/components/cd-checkbox/CdCheckbox';

const { Title, Text } = Typography;

export const AnniversarySettings = () => {
  const { anniversaryTypes, loading } = useAnniversarySettings();
  return (
    <Spin spinning={loading}>
      <CdVerticalSpace>
        <Typography>
          <Title level={4}>
            {I18nService.getString(
              'Specify anniversaries to include in the anniversary app'
            )}
          </Title>
          <Text>
            {I18nService.getString(
              'Please select which anniversaries or other important events to work with in the anniversary app. You can select from all date based people contact fields. The anniversary types you select here will be available in the anniversary app and you will receive a notification when a special anniversary is coming up 14 days prior.'
            )}
          </Text>
        </Typography>

        {anniversaryTypes?.map((type) => (
          <div
            key={type.value}
            style={{
              display: 'grid',
              gridTemplateColumns: '20% 80%',
              padding: '2px',
            }}
          >
            <Form.Item
              name={['anniversariesTypeSetting', type.value, 'checked']}
              style={{ margin: '0px' }}
              valuePropName="checked"
            >
              <CdCheckbox>{truncate(type.label, { length: 30 })}</CdCheckbox>
            </Form.Item>
            <Form.Item
              name={['anniversariesTypeSetting', type.value, 'selected']}
              style={{ margin: '0px' }}
            >
              <SpecialDays />
            </Form.Item>
          </div>
        ))}

        <Typography>
          <Title level={4}>
            {I18nService.getString(
              'Select which users should receive a notification'
            )}
          </Title>
          <Text>
            {I18nService.getString(
              'You can select one or more users who 14 days prior will be informed about special upcoming anniversaries'
            )}
          </Text>
        </Typography>
        <Form.Item name="usersToNotify">
          <AnniversaryNotifyUsers />
        </Form.Item>
      </CdVerticalSpace>
    </Spin>
  );
};
