import _ from 'lodash';
import React from 'react';
import { Space, Typography } from 'antd';

import { I18nService } from '../../services/I18nService';
import {
  UserUploadData,
  fileUploadTypes,
  ISafeguardingLevelCheckType,
  SafeguardingUserProfile,
} from '../types/SafeguardingTypes';

import { getCountryIso2 } from '@/react/services/locale/country.service';
import { Resource } from '@/react/shared/models/resource';

export const getEmptyStateText = (fileUploadType) => {
  switch (fileUploadType) {
    case fileUploadTypes.CERTIFICATE_OF_CONDUCT:
      return I18nService.getString('No certificate of conduct');
    case fileUploadTypes.DOCUMENT:
      return I18nService.getString('No documents');
    case fileUploadTypes.COURSE:
      return I18nService.getString('No courses');
  }
};

export const getButtonText = (fileUploadType) => {
  switch (fileUploadType) {
    case fileUploadTypes.CERTIFICATE_OF_CONDUCT:
      return I18nService.getString('Register certificate of conduct');
    case fileUploadTypes.DOCUMENT:
      return I18nService.getString('Add documents');
    case fileUploadTypes.COURSE:
      return I18nService.getString('Add courses');
  }
};

export const getFileUploadModalTitle = (fileUploadType) => {
  switch (fileUploadType) {
    case fileUploadTypes.CERTIFICATE_OF_CONDUCT:
      return I18nService.getString('Register certificate of conduct');
    case fileUploadTypes.COURSE:
      return I18nService.getString('Add courses');
    case fileUploadTypes.DOCUMENT:
      return I18nService.getString('Add documents');
    default:
      break;
  }
};

// User can input multiple parish ids or parish name separated by comma 1177, 2345, Parish1
// This function will return an array of parish ids
export const getFormattedParishId = (
  parishIds: string,
  resources: Resource[]
) => {
  if (parishIds === undefined) {
    return [];
  }
  const parts = parishIds.split(',');

  return parts
    .map((part) => {
      const parish = resources?.find(
        (parish) =>
          parish.type === 'church' &&
          (parish.id === Number(part) ||
            parish.name.toLowerCase() === part.toString().toLowerCase().trim())
      );
      return parish.id;
    })
    .filter(Boolean);
};

export const percentage = (partial: number, total: number): number => {
  const value = Math.round((100 * partial) / total);
  return total === 0 ? 100 : value;
};

export const checkAddressUpdateStatus = ({
  userInput,
  resAddress,
}: { userInput: UserUploadData; resAddress: any }) => {
  if (!resAddress) {
    return;
  }
  if (
    userInput.street === resAddress?.street &&
    userInput.city === resAddress?.city &&
    userInput.postCode === resAddress?.postCode &&
    getCountryIso2(userInput?.country) === resAddress?.countryIso2
  ) {
    return I18nService.getString('User imported.');
  } else {
    return I18nService.getString(
      'User imported. Address ignored. User already has an address.'
    );
  }
};

export const sleep = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

// Translations are called in hook, because directly in TS file can cause translations to be pulled before they are loaded
export const useTableTranslations = () => ({
  firstName: I18nService.getString(
    'First name',
    null,
    'This text is used in the user importer'
  ),
  lastName: I18nService.getString(
    'Last name',
    null,
    'Safeguarding user importer'
  ),
  email: I18nService.getString('Email', null, 'Safeguarding user importer'),
  status: I18nService.getString('Status', null, 'Safeguarding user importer'),
  street: I18nService.getString('Street', null, 'Safeguarding user importer'),
  city: I18nService.getString('City', null, 'Safeguarding user importer'),
  postCode: I18nService.getString(
    'Postal code',
    null,
    'Safeguarding user importer'
  ),
  country: I18nService.getString('Country', null, 'Safeguarding user importer'),
  safeguardRequirement: I18nService.getString(
    'Safeguarding requirement',
    null,
    'Safeguarding user importer'
  ),
  type: I18nService.getString(
    'Type of employment',
    null,
    'Safeguarding user importer'
  ),
  workAndResponsibilities: I18nService.getString(
    'Work and responsibility',
    null,
    'Safeguarding user importer'
  ),
  parishIds: I18nService.getString(
    'Parishes',
    null,
    'Safeguarding user importer'
  ),

  // translations for the options
  select: I18nService.getString('Select', null, 'Safeguarding user importer'),
  notRequired: I18nService.getString(
    'Not required',
    null,
    'Safeguarding user importer'
  ),
  minimum: I18nService.getString('Minimum', null, 'Safeguarding user importer'),
  basic: I18nService.getString('Basic', null, 'Safeguarding user importer'),
  basicPlus: I18nService.getString(
    'Basic plus',
    null,
    'Safeguarding user importer'
  ),
  extended: I18nService.getString(
    'Extended',
    null,
    'Safeguarding user importer'
  ),
  extendedPlus: I18nService.getString(
    'Extended plus',
    null,
    'Safeguarding user importer'
  ),
  verifiedByThirdParty: I18nService.getString(
    'Verified by third party',
    null,
    'Safeguarding user importer'
  ),
  employee: I18nService.getString(
    'Employee',
    null,
    'Safeguarding user importer'
  ),
  volunteer: I18nService.getString(
    'Volunteer',
    null,
    'Safeguarding user importer'
  ),
});

export const getAlternateMatches = (field: string) => [
  field.toUpperCase(),
  field.toLowerCase(),
  _.upperFirst(field),
];

export const safeguardingFormSelect = (
  itemData: ISafeguardingLevelCheckType
) => ({
  value: itemData.value,
  label: itemData.description ? (
    <Space direction="vertical" size={0}>
      <Typography.Text>{itemData.heading}</Typography.Text>
      <Typography.Text
        type="secondary"
        style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}
      >
        {itemData.description}
      </Typography.Text>
    </Space>
  ) : (
    itemData.heading
  ),
});

export const getSafeguardingLevelText = (value: string) => {
  switch (value) {
    case 'not required':
      return I18nService.getString('Not required');
    case 'verified by third party':
      return I18nService.getString('Verified by third party');
    case 'minimum':
      return I18nService.getString('Minimum');
    case 'basic':
      return I18nService.getString('Basic');
    case 'basic plus':
      return I18nService.getString('Basic plus');
    case 'extended':
      return I18nService.getString('Extended');
    case 'extended plus':
      return I18nService.getString('Extended plus');
  }
};

export const getSafeguardingEmploymentText = (value: string) => {
  switch (value) {
    case 'volunteer':
      return I18nService.getString(
        'Volunteer',
        null,
        'Safeguarding user importer'
      );
    case 'employee':
    default:
      return I18nService.getString(
        'Employee',
        null,
        'Safeguarding user importer'
      );
  }
};

const validateSafeguardRequirement = (row: SafeguardingUserProfile) => {
  if (row.type || row.workAndResponsibilities || row.parishIds) {
    if (!row.safeguardRequirement) {
      throw new Error(
        I18nService.getString('Safeguarding requirement is required')
      );
    }
  }
};

const validateSafeguardingType = (row: SafeguardingUserProfile) => {
  if (
    row.safeguardRequirement ||
    row.workAndResponsibilities ||
    row.parishIds
  ) {
    if (!row.type) {
      throw new Error(I18nService.getString('Type is required'));
    }
  }
};

const validatingWorkAndResponsibilities = (row: SafeguardingUserProfile) => {
  if (row.safeguardRequirement || row.type || row.parishIds) {
    if (!row.workAndResponsibilities) {
      throw new Error(
        I18nService.getString('Work and responsibilities is required')
      );
    }
  }
};

const validateCountry = (row: SafeguardingUserProfile) => {
  if (row?.country) {
    const countryIso2 = getCountryIso2(row.country);
    if (!countryIso2) {
      throw new Error(I18nService.getString('Country is not valid'));
    }
  }
};

export const getValidationErrors = (row: SafeguardingUserProfile) => {
  const errors = [];
  try {
    validateSafeguardRequirement(row);
  } catch (error) {
    errors.push(error.message);
  }
  try {
    validateSafeguardingType(row);
  } catch (error) {
    errors.push(error.message);
  }
  try {
    validatingWorkAndResponsibilities(row);
  } catch (error) {
    errors.push(error.message);
  }
  try {
    validateCountry(row);
  } catch (error) {
    errors.push(error.message);
  }
  return errors;
};

export const validateRows = (
  row: SafeguardingUserProfile,
  receivedError: any
) => {
  try {
    validateSafeguardRequirement(row);
  } catch (error: unknown) {
    receivedError('safeguardRequirement', error);
  }
  try {
    validateSafeguardingType(row);
  } catch (error: unknown) {
    receivedError('type', error);
  }
  try {
    validatingWorkAndResponsibilities(row);
  } catch (error: unknown) {
    receivedError('workAndResponsibilities', error);
  }
  try {
    validateCountry(row);
  } catch (error: unknown) {
    receivedError('country', error);
  }
};
