/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetCalendarBookingAvailableslotsResponseBody } from '../models/GetCalendarBookingAvailableslotsResponseBody';
import type { GetCalendarBookingBookingpageResponseBody } from '../models/GetCalendarBookingBookingpageResponseBody';
import type { GetCalendarBookingRequestsResponseBody } from '../models/GetCalendarBookingRequestsResponseBody';
import type { GetCalendarBookingRequestsResponseBody2 } from '../models/GetCalendarBookingRequestsResponseBody2';
import type { GetCalendarBookingStatusResponseBody } from '../models/GetCalendarBookingStatusResponseBody';
import type { PostBookingRequestRequestBody } from '../models/PostBookingRequestRequestBody';
import type { PostBookingRespondRequestBody } from '../models/PostBookingRespondRequestBody';
import type { PutBookingRequestsRespondRequestBody } from '../models/PutBookingRequestsRespondRequestBody';
import type { PutCalendarBookingRequestsFollowupRequestBody } from '../models/PutCalendarBookingRequestsFollowupRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class BookingsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get booking requests
     * Get booking requests in the given organization
     * @returns GetCalendarBookingRequestsResponseBody
     * @throws ApiError
     */
    public getCalendarBookingRequests({
        statuses,
        limit = 25,
        page = 1,
        userIds,
        bookingOptionIds,
        availabilityCategoryIds,
    }: {
        statuses?: Array<'requested' | 'accepted' | 'rejected'>,
        limit?: number,
        page?: number,
        userIds?: (((number | '') | Array<number>) | Record<string, number>),
        bookingOptionIds?: (((string | '') | Array<string>) | Record<string, string>),
        availabilityCategoryIds?: (((number | '') | Array<number>) | Record<string, number>),
    }): CancelablePromise<GetCalendarBookingRequestsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/booking/requests',
            query: {
                'statuses': statuses,
                'limit': limit,
                'page': page,
                'userIds': userIds,
                'bookingOptionIds': bookingOptionIds,
                'availabilityCategoryIds': availabilityCategoryIds,
            },
        });
    }
    /**
     * Get a booking request
     * Get a booking request by id
     * @returns GetCalendarBookingRequestsResponseBody2
     * @throws ApiError
     */
    public getCalendarBookingRequests1({
        id,
    }: {
        id: string,
    }): CancelablePromise<GetCalendarBookingRequestsResponseBody2> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/booking/requests/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Give follow-up to booking request
     * If the booking page allows for a follow-up, this endpoint is to give the follow-up form submission
     * @throws ApiError
     */
    public putCalendarBookingRequestsFollowUp({
        organizationPublicId,
        id,
        requestBody,
    }: {
        organizationPublicId: string,
        id: string,
        requestBody?: PutCalendarBookingRequestsFollowupRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/calendar/booking/requests/{id}/follow-up',
            path: {
                'id': id,
            },
            query: {
                'organizationPublicId': organizationPublicId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Respond to a booking request
     * Respond to a booking request from with in the application
     * @throws ApiError
     */
    public putCalendarBookingRequestsRespond({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PutBookingRequestsRespondRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/calendar/booking/requests/{id}/respond',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Respond to a booking request
     * Respond to a booking request with either accept or reject.
     * @throws ApiError
     */
    public postCalendarBookingRespond({
        token,
        requestBody,
    }: {
        token: string,
        requestBody?: PostBookingRespondRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/calendar/booking/respond',
            query: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Public status of a booking request
     * To know what the current status of a booking, so the user can know if they have responded or not.
     * @returns GetCalendarBookingStatusResponseBody
     * @throws ApiError
     */
    public getCalendarBookingStatus({
        token,
    }: {
        token: string,
    }): CancelablePromise<GetCalendarBookingStatusResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/booking/status',
            query: {
                'token': token,
            },
        });
    }
    /**
     * Get a booking page data
     * Gets data that is needed in the booking page, such as booking options and organization information.
     * @returns GetCalendarBookingBookingpageResponseBody
     * @throws ApiError
     */
    public getCalendarBookingBookingPage({
        organizationPublicId,
        alias,
    }: {
        organizationPublicId: string,
        alias: string,
    }): CancelablePromise<GetCalendarBookingBookingpageResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/booking/{alias}/booking-page',
            path: {
                'alias': alias,
            },
            query: {
                'organizationPublicId': organizationPublicId,
            },
        });
    }
    /**
     * Get available slots for a booking option
     * Gets available slots for the given booking option, this depends on the resource availability and availabilities created for the availability category that the booking option belongs to.
     * @returns GetCalendarBookingAvailableslotsResponseBody
     * @throws ApiError
     */
    public getCalendarBookingAvailableSlots({
        intervalStart,
        intervalEnd,
        organizationPublicId,
        bookingOptionId,
    }: {
        intervalStart: string,
        intervalEnd: string,
        organizationPublicId: string,
        bookingOptionId: string,
    }): CancelablePromise<GetCalendarBookingAvailableslotsResponseBody> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/booking/{bookingOptionId}/available-slots',
            path: {
                'bookingOptionId': bookingOptionId,
            },
            query: {
                'intervalStart': intervalStart,
                'intervalEnd': intervalEnd,
                'organizationPublicId': organizationPublicId,
            },
        });
    }
    /**
     * Request a booking
     * Request booking for the selected slot
     * @throws ApiError
     */
    public postCalendarBookingRequest({
        organizationPublicId,
        bookingOptionId,
        requestBody,
    }: {
        organizationPublicId: string,
        bookingOptionId: string,
        requestBody?: PostBookingRequestRequestBody,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/calendar/booking/{bookingOptionId}/request',
            path: {
                'bookingOptionId': bookingOptionId,
            },
            query: {
                'organizationPublicId': organizationPublicId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
