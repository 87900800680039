/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SafeguardDoc = ({
    reviewedBy?: number | null;
    reviewDate: string;
    thirdPartyReviewName?: string | null;
    refreshRequired?: boolean;
    refreshDate?: string | null;
    type: SafeguardDoc.type;
    documentWhereabouts: SafeguardDoc.documentWhereabouts;
    comments?: string | null;
} | {
    reviewedBy?: number | null;
    reviewDate: string;
    thirdPartyReviewName?: string | null;
    refreshRequired?: boolean;
    refreshDate?: string | null;
    type: SafeguardDoc.type;
    courseTitle: string;
    courseType: SafeguardDoc.courseType;
    courseDate: string;
    courseDuration?: number | null;
    fileIds: Array<string>;
} | {
    reviewedBy?: number | null;
    reviewDate: string;
    thirdPartyReviewName?: string | null;
    refreshRequired?: boolean;
    refreshDate?: string | null;
    type: SafeguardDoc.type;
    documentType: SafeguardDoc.documentType;
    fileId: string;
});
export namespace SafeguardDoc {
    export enum type {
        CERTIFICATE_OF_CONDUCT = 'certificateOfConduct',
    }
    export enum documentWhereabouts {
        RETURNED = 'returned',
        DESTROYED = 'destroyed',
    }
    export enum courseType {
        BASIC = 'basic',
        EXTENDED = 'extended',
    }
    export enum documentType {
        CODE_OF_CONDUCT = 'codeOfConduct',
        SELF_DISCLOSURE = 'selfDisclosure',
        COMBINED_DECLARATION = 'combinedDeclaration',
    }
}

