import React from 'react';
import { Calendar, Col, Row, Space, Typography } from 'antd';
import 'react-day-picker/lib/style.css';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';

import { UserTimeRegistrationCalendar } from './types/timeRegistration.types';

import {
  CdTimeRegistrationNextMonth,
  CdTimeRegistrationPreviousMonth,
} from '@/react/shared/components/Icons';
import { I18nService } from '@/react/services/I18nService';

export const cDBlue = '#12A3C0';
const calendarRegistered = '#C4E8DE';
const calendarBooking = '#EFE8C2';

const StyledCalendar = styled(Calendar)`

    th {
        text-align: center;
    }

    .cell-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .cell-inner {
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        outline-color: ${cDBlue};
    }

    .cell-inner:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }

    .today, .today:hover {
        background-color: ${cDBlue};
        color: white;
    }

    .booked, .booked:hover {
        background-color: ${calendarBooking};
        outline-color: ${calendarBooking};
    }

    .hours, .hours:hover {
        background-color: ${calendarRegistered};
        outline-color: ${calendarRegistered};
    }

    .selected, .selected:hover {
        outline-style: solid;
        outline-width: 2px;
        outline-offset: 2px;
    }

     .ant-picker-date-panel .ant-picker-cell:not(.ant-picker-cell-in-view) {
      pointer-events: none;
    }
`;

export const TimeRegistrationCalendar = ({
  selectedDate,
  setSelectedDate,
  userTimeRegistrationCalendar,
}: {
  selectedDate: Dayjs;
  setSelectedDate: (date: Dayjs) => void;
  userTimeRegistrationCalendar: UserTimeRegistrationCalendar[];
}) => {
  const goToPreviousMonthCalendar = (value, onChange) => {
    const month = dayjs(value).subtract(1, 'month').month();
    let date = value.clone().month(month);

    if (month === 11) {
      const year = dayjs(value).subtract(1, 'year').year();
      date = date.clone().year(year);
    }

    onChange(date);
  };

  const goToNextMonthCalendar = (value, onChange) => {
    const month = dayjs(value).add(1, 'month').month();
    let date = value.clone().month(month);

    if (month === 0) {
      const year = dayjs(value).add(1, 'year').year();
      date = date.clone().year(year);
    }

    onChange(date);
  };

  const isNextMonthDisabled = React.useMemo(() => {
    const today = dayjs();
    const nextMonth = dayjs(selectedDate).clone().add(1, 'month');

    return nextMonth.isAfter(today, 'month');
  }, [selectedDate]);

  const getDayStyleClass = React.useCallback(
    (date: Dayjs) => {
      const data = userTimeRegistrationCalendar?.find((item) =>
        dayjs(item.date).isSame(date, 'day')
      );

      const today = dayjs().toDate();
      const isToday = dayjs(date).isSame(today, 'day');
      const selected = dayjs(date).isSame(selectedDate, 'day');

      let className = '';

      if (
        isToday &&
        ((selectedDate && selectedDate.month() === dayjs().month()) ||
          !selectedDate)
      ) {
        className = 'today';
      } else if (
        data &&
        data.booking &&
        (data.hours === undefined || data.hours === 0)
      ) {
        className = 'booked';
      } else if (data && !!data.hours) {
        className = 'hours';
      } else {
        className = '';
      }

      if (selected) {
        className += ' selected';
      }

      return className;
    },
    [userTimeRegistrationCalendar, selectedDate]
  );

  // Stand in until we actually save the date where the user activated time registration
  const dateOfInitialTimeBalance = dayjs('2024-06-27 00:00');

  const dateCellRender = (date: Dayjs) => {
    const className = getDayStyleClass(date);

    return (
      <div className="cell-wrapper">
        <div className={`cell-inner ${className}`}>{date.format('DD')}</div>
      </div>
    );
  };

  return (
    <>
      <Col span={24}>
        <Row style={{ width: '100%' }} justify="space-between" align="bottom">
          <Col>
            <Typography.Text strong>
              {I18nService.getString('Select date to edit')}
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Link
              onClick={() => setSelectedDate(moment() as unknown as Dayjs)}
            >
              {I18nService.getString('Go to today')}
            </Typography.Link>
          </Col>
        </Row>
      </Col>
      <Col
        span={24}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Space
          style={{
            width: '100%',
            border: '1px solid #d9d9d9',
            borderRadius: 4,
          }}
        >
          <StyledCalendar
            headerRender={({ value, onChange }) => (
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignContent: 'center',
                  padding: 8,
                }}
              >
                <Typography.Link
                  onClick={() => goToPreviousMonthCalendar(value, onChange)}
                >
                  <CdTimeRegistrationPreviousMonth size="lg" />
                </Typography.Link>
                {moment(value.toDate()).format('MMMM')}{' '}
                {dayjs(value).format('YYYY')}
                <Typography.Link
                  disabled={isNextMonthDisabled}
                  onClick={() => goToNextMonthCalendar(value, onChange)}
                >
                  <CdTimeRegistrationNextMonth size="lg" />
                </Typography.Link>
              </Row>
            )}
            fullCellRender={dateCellRender}
            value={selectedDate}
            onSelect={setSelectedDate}
            fullscreen={false}
            disabledDate={(date) =>
              dayjs(date).isBefore(dateOfInitialTimeBalance) ||
              dayjs(date).isAfter(dayjs(), 'day')
            }
          />
        </Space>
      </Col>
    </>
  );
};
