/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostPPaymentsRequestBody = {
    contributionId: string;
    paymentType: PostPPaymentsRequestBody.paymentType;
    email: string;
    amount: number;
    payerEmail: string;
    payerFirstName: string;
    payerLastName?: string;
};
export namespace PostPPaymentsRequestBody {
    export enum paymentType {
        CARD = 'CARD',
        SEPA = 'SEPA',
    }
}

