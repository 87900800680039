import { Modal, Spin, Typography, Form, Select, Row, Col } from 'antd';
import React, { FunctionComponent, Suspense, useEffect, useState } from 'react';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import styled from 'styled-components';
import { useRecoilValueLoadable } from 'recoil';

import { I18nService } from '../../services/I18nService';
import { PosterTokenQuery } from '../store/contributions';
import usePoster from '../hooks/usePoster';

import UploadFile, {
  ImageHelperText,
} from '@/react/people/components/UploadFile';
import cdApp from '@/react/config';

const { Paragraph } = Typography;

const Container = styled.div`
  margin: 24px;
`;

const LogoPreview = styled.img`
  padding: 0 16px 16px 16px;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;

const CreatePosterWidgetModal = NiceModal.create(
  ({ contributionId }: { contributionId: string }) => (
    <CreatePosterWidget contributionId={contributionId} />
  )
);

interface CreatePosterWidgetProps {
  contributionId: string;
}

export const CreatePosterWidget: FunctionComponent<CreatePosterWidgetProps> = ({
  contributionId,
}) => {
  const { uploadLogo, createPoster } = usePoster();
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState('');
  const [imageId, setImageId] = useState('');
  const [iframeUrl, setIframeUrl] = useState('');
  const modal = useModal('CreatePosterWidget');
  const organizationId = cdApp.organization.id;
  const close = modal.remove;
  let accessToken;

  const accessTokenLoadable = useRecoilValueLoadable(
    PosterTokenQuery({ contributionId })
  );
  if (accessTokenLoadable.state === 'hasValue') {
    accessToken = accessTokenLoadable.contents;
  } else {
    accessToken = null;
  }

  useEffect(() => {
    setIframeUrl(
      `${cdApp.config.api.main}/p/contributions/${contributionId}/poster/preview?backgroundColor=white&fontColor=black&actionColor=%2327AE60&token=${accessToken}#toolbar=0`
    );
  }, [contributionId, accessToken]);

  useEffect(() => {
    if (imageId) {
      setIframeUrl(
        `${cdApp.config.api.main}/p/contributions/${contributionId}/poster/preview?backgroundColor=white&fontColor=black&actionColor=%2327AE60&token=${accessToken}&imageId=${imageId}#toolbar=0`
      );
    }
  }, [imageId, contributionId, accessToken]);

  return (
    <Modal
      {...{
        ...antdModalV5(modal),
        width: 896,
        title: I18nService.getString(`Create Scan and Donate Poster`),
        bodyStyle: { padding: 0 },
        onCancel: close,
        okText: I18nService.getString('Create'),
        cancelText: I18nService.getString('Close'),
        onOk: async () => {
          await form.validateFields();
          const posterUrl = await createPoster(
            contributionId,
            organizationId,
            form.getFieldValue('size'),
            imageId
          );
          open(posterUrl, '_blank');
          await modal.resolve();
          close();
        },
      }}
    >
      <Container>
        <Row style={{ height: '100%' }} gutter={[24, 24]}>
          <Col style={{ paddingRight: '24px' }} span={12}>
            <Paragraph style={{ fontWeight: 'bold' }}>
              {I18nService.getString(
                'Choose the size of the scan and donate poster'
              )}
            </Paragraph>
            <Form layout="vertical" form={form}>
              <Form.Item initialValue="A4" name="size" noStyle>
                <Select
                  options={[
                    { value: 'A4', label: 'A4 format' },
                    { value: 'A3', label: 'A3 format' },
                    { value: 'A2', label: 'A2 format' },
                    { value: 'A1', label: 'A1 format' },
                    { value: '30cm 40cm', label: '30x40 cm' },
                    { value: '50cm 70cm', label: '50x70 cm' },
                  ]}
                  placeholder={I18nService.getString('A4')}
                  style={{ width: '120px', marginBottom: '25px' }}
                ></Select>
              </Form.Item>
              {/* Checkbox group for selecting colors for the poster will be added later */}
              {/* <Paragraph style={{ fontWeight: 'bold' }}>
                {I18nService.getString(
                  'Set the color scheme for the poster'
                )}
              </Paragraph>
              <Form.Item style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Checkbox
                    style={{
                      fontWeight: 'normal',
                      margin: '0px 0px 10px 0px',
                    }}
                  >
                    {I18nService.getString('Choose background color')}
                  </Checkbox>
                  <Checkbox
                    style={{
                      fontWeight: 'normal',
                      margin: '0px 0px 10px 0px',
                    }}
                  >
                    {I18nService.getString('Choose font color')}
                  </Checkbox>
                  <Checkbox
                    style={{
                      fontWeight: 'normal',
                      margin: '0px 0px 10px 0px',
                    }}
                  >
                    {I18nService.getString(
                      'Choose color on the call to action'
                    )}
                  </Checkbox>
                </div>
              </Form.Item> */}
              <Paragraph style={{ fontWeight: 'bold', marginTop: '25px' }}>
                {I18nService.getString('Choose logo')}
              </Paragraph>
              <Paragraph style={{ fontWeight: 'normal' }}>
                {I18nService.getString(
                  'Please choose an appropriate logo for your donation project'
                )}
              </Paragraph>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: I18nService.getString(
                      'Please add a logo to the poster'
                    ),
                  },
                ]}
                name="imageId"
              >
                <UploadFile
                  uploadFile={async (arg) => {
                    const result = await uploadLogo(arg);
                    setImageUrl(result.url);
                    setImageId(result.uuid);
                  }}
                  maxFileSize={10}
                  allowedFileExtensions={['PNG', 'JPEG', 'JPG']}
                  deleteFile={null}
                  multiple={false}
                >
                  <LogoPreview
                    src={imageUrl ? imageUrl : '/img/placeholder.jpeg'}
                    alt="logo"
                  />
                  <ImageHelperText>
                    {I18nService.getString('Click or drop an image to upload')}
                  </ImageHelperText>
                  <p>{I18nService.getString('JPEG or PNG')}</p>
                </UploadFile>
              </Form.Item>
            </Form>
          </Col>
          <Col style={{ paddingLeft: '40px' }} span={12}>
            {accessToken && (
              <iframe
                src={iframeUrl}
                style={{
                  width: '342px',
                  height: '500px',
                  border: '0px',
                  paddingRight: '18px',
                  paddingBottom: '20px',
                  paddingTop: '20px',
                  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                }}
              ></iframe>
            )}
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

const CreatePosterModalParent = (props) => (
  <Suspense fallback={<Spin />}>
    <CreatePosterWidgetModal {...props} />
  </Suspense>
);
export default CreatePosterModalParent;
