import React, { Suspense } from 'react';
import { Button } from 'antd';

import { I18nService } from '../../services/I18nService';
import { CdPage } from '../../shared/components/cd-page/CdPage';
import { Anniversary } from '../anniversary/components/Anniversary';
import useAnniversary from '../anniversary/hooks/useAnniversary';

import { getStateUrl } from '@/react/services/StateServiceFactory';
import { ProductLandingPage } from '@/react/product-landingpage/ProductLandingPage';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';

export const AnniversaryPageWraper = () => (
  <Suspense fallback={<CdrPageLoader marginLess />}>
    <AnniversaryPage />
  </Suspense>
);

function AnniversaryPage() {
  const { accessDenied } = useAnniversary();
  if (accessDenied) {
    return <ProductLandingPage packageName="anniversary" />;
  }
  return (
    <CdPage
      pageHeaderProps={{
        extra: [
          <Button
            key="anniversary.settings"
            href={getStateUrl(`app.private.settings.people`)}
          >
            {I18nService.getString('Settings')}
          </Button>,
        ],
        title: I18nService.getString('Anniversaries'),
        subTitle: I18nService.getString(
          'View all upcoming anniversaries for next 14 days'
        ),
        backIcon: false,
      }}
    >
      <Anniversary />
    </CdPage>
  );
}
