import React from 'react';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import { compose } from 'redux';
import { Form, Input, Modal } from 'antd';

import useCdFormLabel from '../hooks/useCdFormLabel';

import { I18nService } from '@/react/services/I18nService';

const EditLabel = NiceModal.create<{ label: { id: number; label: string } }>(
  ({ label }) => {
    const { update } = useCdFormLabel();
    const modal = useModal('EditLabel');
    const [form] = Form.useForm();

    return (
      <Modal
        {...antdModalV5(modal)}
        title={I18nService.getString('Edit label')}
        onOk={compose(modal.hide, form.submit)}
      >
        <Form
          form={form}
          initialValues={label}
          onFinish={compose(modal.hide, update)}
        >
          <Form.Item name="id" hidden>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="label" label={I18nService.getString('Label')}>
            <Input type="text" />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);

export default EditLabel;
