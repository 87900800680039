import React, { useState } from 'react';
import { Button, Card, Layout, Typography } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';

import { I18nService } from '../../services/I18nService';
import cdApp from '../../config';
import { CdCopyIcon } from '../../shared/components/Icons';
import {
  createCdModal,
  ModalType,
} from '../../shared/components/cd-modal-new/CdModal2';
import { CdVerticalSpace } from '../../shared/components/cd-vertical-space/CdVerticalSpace';

import { WidgetInterface } from './types/widget.type';

export const showWidgetPreviewModal = ({
  widgetData,
}: {
  widgetData: WidgetInterface;
}) => {
  const { id, type, organizationId } = widgetData;
  const baseUrl = cdApp.references.websiteWidgetsUrl;
  const locale = cdApp.organization.locale.language;
  const iframeUrl = `${baseUrl}/${locale}/w/${organizationId}/${type}/${id}/1/-/-/-/-/-/-/or/list?frameId=${id}`;
  const altarId = cdApp.organization.id;
  // data-churchdesk-user-embed=true ensures that portal widget knows not to override the organzation id on a Mono website.
  const codeTemplate = `<div data-churchdesk-widget-id="${id}" data-churchdesk-organization-id="${altarId}" data-churchdesk-user-embed=true data-churchdesk-widget-type="${type}"></div><script src="${baseUrl}/widget-loader.js"></script>`;

  return showPreviewModal({ frameId: id, iframeUrl, codeTemplate });
};

export const showPreviewModal = createCdModal<{
  frameId?: string | number;
  iframeUrl?: string;
  codeTemplate: string;
}>(
  ({ frameId, iframeUrl, codeTemplate }) => {
    const [height, setHeight] = useState('250px');
    const [buttonText, setButtonText] = useState(
      I18nService.getString('Copy code')
    );

    window.addEventListener('message', (e) => {
      const data = e && e.data;
      const action = data && data.action;
      const frameIdFromMessage = data && data.frameId;
      if (
        action === 'churchdeskAdjustHeight' &&
        frameId?.toString() === frameIdFromMessage.toString()
      ) {
        setHeight(`${Math.max(data.height, 100)}px`);
      }
    });

    return (
      <CdVerticalSpace>
        {iframeUrl && (
          <Layout style={{ padding: '24px' }}>
            <Card bodyStyle={{ padding: '16px' }}>
              <iframe
                src={iframeUrl}
                style={{
                  width: '100%',
                  height: `${height}`,
                  border: '0px',
                }}
              ></iframe>
            </Card>
          </Layout>
        )}
        <Typography.Title level={5}>
          {I18nService.getString('Copy code')}
        </Typography.Title>
        <Typography.Text style={{ marginBottom: '32px !important' }}>
          {I18nService.getString(
            'Insert the widget on your website by copying this code and pasting it into the HTML of your site.'
          )}
        </Typography.Text>
        <Typography.Paragraph code>{codeTemplate}</Typography.Paragraph>
        <CopyToClipboard
          text={codeTemplate}
          onCopy={() => {
            setButtonText(I18nService.getString('Copied'));
          }}
        >
          <Button>
            <CdCopyIcon />
            {buttonText}
          </Button>
        </CopyToClipboard>
      </CdVerticalSpace>
    );
  },
  {
    title: I18nService.getString('Widget preview'),
    modalType: ModalType.POPUP,
    hideOk: true,
    width: 1000,
  }
);
