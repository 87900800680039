/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TaxonomyInputDTO2 = {
    id?: number;
    name: string;
    color?: number;
    description?: string | null;
    type: TaxonomyInputDTO2.type;
    registerAttendance?: boolean;
    declinePrimaryIntentions?: boolean | null;
    alias?: string | null;
    groups?: Array<{
        id: number;
    }>;
    bookingOptions?: Array<{
        id?: string;
        name?: string;
        description?: string;
        taxonomyId?: number;
        mainResourceId?: number;
        additionalResourceIds?: Array<number>;
        userIds?: Array<number>;
        config?: {
            /**
             * Value in minutes
             */
            duration?: number;
            /**
             * Value in minutes
             */
            minimumNoticePeriod?: number;
            /**
             * Value in minutes
             */
            startTimeIncrement?: number;
            /**
             * Value in minutes
             */
            bufferBeforeEvent?: number;
            /**
             * Value in minutes
             */
            bufferAfterEvent?: number;
            bookingHours?: Record<string, Array<{
                start: string;
                end: string;
            }>>;
            enquiryLimit?: {
                value: number;
                unit: 'day' | 'week';
            };
            showUsers?: boolean;
            bookingMode?: 'usersAndResources' | 'onlyResources' | 'onlyUsers' | null;
        };
    }>;
    config?: {
        type: TaxonomyInputDTO2.type;
        publicName: string;
        form?: {
            id: string;
            alias: string;
        };
        followUpForm?: {
            id: string;
            alias: string;
        };
        automaticFollowUpForm?: boolean;
        emailTemplates?: {
            confirmation?: string;
            rejection?: string;
        };
        event?: {
            title: string;
            churchIds: Array<number>;
            groupIds?: Array<number>;
            categoryIds: Array<number>;
            visibility: TaxonomyInputDTO2.visibility;
        };
    };
};
export namespace TaxonomyInputDTO2 {
    export enum type {
        ABSENCE = 'absence',
        EVENT = 'event',
        BLOG = 'blog',
        AVAILABILITY = 'availability',
    }
    export enum visibility {
        PRIVATE = 'private',
        INTERNAL = 'internal',
        PUBLIC = 'public',
    }
}

