/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyObject } from '../models/EmptyObject';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ICalService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * iCalAllEntities
     * Index
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIcal({
        token,
    }: {
        /**
         * - The identification token of the user.
         */
        token?: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ical',
            query: {
                'token': token,
            },
        });
    }
    /**
     * iCalEntity
     * Retrieve Entity
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIcalEntity({
        token,
    }: {
        /**
         * - The access token for the iCal.
         */
        token: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ical/entity/{id}',
            query: {
                'token': token,
            },
        });
    }
    /**
     * iCalGroup
     * Retrieve Group
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIcalGroup({
        token,
    }: {
        /**
         * - The access token for the iCal.
         */
        token: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ical/group/{id}',
            query: {
                'token': token,
            },
        });
    }
    /**
     * Get_lists_of_batches_for_an_organization
     * GET LIST OF BATCHES
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIcalImportListBatches({
        limit,
        pageNumber,
    }: {
        limit?: number,
        pageNumber?: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ical/import/list-batches',
            query: {
                'limit': limit,
                'pageNumber': pageNumber,
            },
        });
    }
    /**
     * DeleteBatchAction
     * @returns EmptyObject
     * @throws ApiError
     */
    public deleteIcalImportBatch({
        id,
    }: {
        id: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/ical/import/{id}/batch',
            path: {
                'id': id,
            },
        });
    }
    /**
     * parishICalFeed
     * Parish ical feed
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIcalParish({
        parishId,
        token,
    }: {
        parishId: number,
        token?: string,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ical/parish/{parishId}',
            path: {
                'parishId': parishId,
            },
            query: {
                'token': token,
            },
        });
    }
    /**
     * iCalResource
     * Retrieve Resource
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIcalResource({
        token,
    }: {
        /**
         * - The access token for the iCal.
         */
        token: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ical/resource/{id}',
            query: {
                'token': token,
            },
        });
    }
    /**
     * iCalResourcePublic
     * Retrieve Public Resource
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIcalResourcePublic({
        id,
    }: {
        id: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ical/resource/{id}/public',
            path: {
                'id': id,
            },
        });
    }
    /**
     * iCalTaxonomy
     * Retrieve Taxonomy
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIcalTaxonomy({
        token,
    }: {
        /**
         * - The access token for the iCal.
         */
        token: any,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ical/taxonomy/{id}',
            query: {
                'token': token,
            },
        });
    }
    /**
     * iCalGenerateToken
     * Token
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIcalToken(): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ical/token',
        });
    }
    /**
     * iCalUser
     * Retrieve User
     * @returns EmptyObject
     * @throws ApiError
     */
    public getIcalUser({
        id,
    }: {
        id: number,
    }): CancelablePromise<EmptyObject> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ical/user/{id}',
            path: {
                'id': id,
            },
        });
    }
}
