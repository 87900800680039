'use strict';

import { react2angular } from 'react18-react2angular';

import { organizationStatus } from '@/react/user/screens/UserList';
import { SettingsUserListPageHOC } from '@/react/user/screens/SettingsUserList';
import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';

class UsersSettingsComponent {
  constructor(
    $state,
    $uibModal,
    Users,
    userFactory,
    Authorization,
    cdApp,
    gettextCatalog,
    moment,
    toastr,
    Analytics,
    $stateParams,
    _
  ) {
    'ngInject';

    this.$state = $state;
    this.$uibModal = $uibModal;
    this.Users = Users;
    this.userFactory = userFactory;
    this.Authorization = Authorization;
    this.cdApp = cdApp;
    this.gettextCatalog = gettextCatalog;
    this.moment = moment;
    this.toastr = toastr;
    this.Analytics = Analytics;
    this._ = _;

    this.role = {};
    this.permissions = _.get(cdApp, 'organization.permissions');
    this.selectedRoles = [];
    this.selectedStatus = [1];
    this.canAdministerUsers = Authorization.hasPermission('canAdministerUsers');
    this.sorting = { field: 'name', reverse: false };
    this.$stateParams = $stateParams;
  }
  $onInit() {
    this.createGroup = this.createGroup.bind(this);
    this.userInfoInStateParams = this.userInfoInStateParams.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.createUser = this.createUser.bind(this);
    this.resendInvite = this.resendInvite.bind(this);

    // If user details are passed in the state params, opent he create user modal
    if (this.userInfoInStateParams()) {
      this.createUser();
    }
  }

  userInfoInStateParams() {
    return (
      this.$stateParams?.firstName ||
      this.$stateParams?.lastName ||
      this.$stateParams?.email
    );
  }

  createGroup(done) {
    const { $uibModal } = this;

    $uibModal
      .open({
        component: 'cdCreateGroupModal',
      })
      .result.then((newGroup) => {
        done(newGroup);
      });
  }

  createUser() {
    this.$uibModal
      .open({
        component: 'cdCreateUserModal',
        size: 'lg',
        windowClass: 'modal-ui-select',
        resolve: {
          groupIds: () => [this.groupId],
          userInfo: {
            firstName: this.$stateParams.firstName,
            lastName: this.$stateParams.lastName,
            email: this.$stateParams.email,
            title: this.$stateParams.title,
          },
        },
      })
      .result.finally(() => {
        // Clear the state params and remove any user info that was passed in, after the modal is closed
        if (this.userInfoInStateParams()) {
          this.$state.go(
            '.',
            { firstName: undefined, lastName: undefined, email: undefined },
            { notify: false }
          );
        }
      });
  }

  sendMessage(user) {
    this.$uibModal.open({
      templateUrl:
        '@/app/settings/users-settings/shared/templates/send-message.modal.html',
      controller: [
        '$http',
        '$scope',
        '$uibModalInstance',
        'toastr',
        'cdApp',
        'gettextCatalog',
        function (
          $http,
          $scope,
          $uibModalInstance,
          toastr,
          cdApp,
          gettextCatalog
        ) {
          'ngInject';

          $scope.user = user;

          $scope.send = () => {
            $http
              .post(
                `${cdApp.config.api.main}/users/${$scope.user.id}/contact`,
                {
                  subject: $scope.subject,
                  message: $scope.message,
                }
              )
              .then(() => {
                toastr.success(
                  gettextCatalog.getString('Your message has been sent.')
                );
              })
              .catch(({ data }) => {
                toastr.error(
                  _.get(data, 'message') ||
                    gettextCatalog.getString(
                      'An error occurred, please try again. If the problem persists, please contact our support.'
                    )
                );
              });

            $uibModalInstance.close(user);
          };

          $scope.cancel = () => {
            $uibModalInstance.dismiss('cancel');
          };
        },
      ],
    });
  }

  resendInvite(user) {
    this.Users.resendInvitation({ id: user.id }).$promise.then(() => {
      this.toastr.success(
        user.organizationStatus === organizationStatus.CREATED_WITHOUT_LOGIN
          ? this.gettextCatalog.getString('The invite has been sent.')
          : this.gettextCatalog.getString('The invite has been resent.')
      );
    });
  }

  setSorting(field) {
    if (this.sorting.field === field) {
      this.sorting.reverse = !this.sorting.reverse;
    } else {
      this.sorting.field = field;
      this.sorting.reverse = false;
    }
  }

  getSortingDirectionClass(field) {
    if (this.sorting.field === field) {
      return this.sorting.reverse
        ? 'fa fa-sort-down fa-fw'
        : 'fa fa-sort-up fa-fw';
    }
  }
}
UsersSettingsComponent.$inject = [
  '$state',
  '$uibModal',
  'Users',
  'userFactory',
  'Authorization',
  'cdApp',
  'gettextCatalog',
  'moment',
  'toastr',
  'Analytics',
  '$stateParams',
  '_',
];

angular
  .module('cdApp.settings')
  .component(
    'cdUsersSettingsState',
    react2angular(sharedContext.use(SettingsUserListPageHOC), [], [])
  );
