import { Checkbox, CheckboxProps } from 'antd';
import React from 'react';
import styled from 'styled-components';

const CdCheckboxStyled = styled(Checkbox)<{
  $align?: 'start' | 'center';
  required?: boolean;
}>`
.ant-checkbox {
    ${(props) =>
      props.$align === 'start' &&
      `
      margin-top: 3px;
      align-self: start;
      `}
  }
`;

export const CdCheckbox = (
  props: CheckboxProps & {
    $align?: 'start' | 'center';
    required?: boolean;
  }
) => (
  <CdCheckboxStyled style={{ fontWeight: 'normal', ...props.style }} {...props}>
    {props.required && (
      <span
        style={{
          display: 'inline-block',
          marginInlineEnd: '4px',
          color: '#f5222d',
          fontSize: '14px',
          fontFamily: 'SimSun, sans-serif',
          lineHeight: 1,
          content: '*',
        }}
      >
        *
      </span>
    )}
    {props.children}
  </CdCheckboxStyled>
);
