import React from 'react';
import { Space, Typography } from 'antd';
import { isNull } from 'lodash';

import { I18nService } from '../../../services/I18nService';
import { CdDropdown } from '../../../shared/components/cd-dropdown/CdDropdown';
import {
  CdEyeIcon,
  CdCheckIcon,
  CdBan,
  CdCircleFilled,
} from '../../../shared/components/Icons';
import { useEnquiries } from '../hooks/use-enquiries';
import { useEnquiriesPage } from '../hooks/use-enquiries-page';
import { OrderType } from '../../../shared/models/table';
import { Enquiry } from '../types/enquiry';
import { ColumnType } from '../../../shared/components/cd-table';
import { CdVerticalSpace } from '../../../shared/components/cd-vertical-space/CdVerticalSpace';
import { COLOR_INDEX } from '../../../../app/ResourceColors';
import { CdServerSideTable } from '../../../shared/components/cd-server-side-table/CdServerSideTable';
import { useFlag } from '../../../shared/hooks/useFlag';

import { EnquiryStatusIcon } from './EnquiryStatus';
import { DateIntervalRender } from './DateIntervalRender';
import { EnquiriesFilterForm } from './EnquiriesFilterForm';

export const OpenEnquiriesTab = () => {
  const { viewEnquiry, approveEnquiry, declineEnquiry, form, searchParams } =
    useEnquiriesPage();
  const bookingEnquiriesV2Enabled = useFlag('booking_enquiries_v2');

  return (
    <CdServerSideTable
      pagedQuery={useEnquiries}
      searchParams={{ statuses: ['requested'], ...searchParams }}
      noStyle
      filterForm={
        bookingEnquiriesV2Enabled && <EnquiriesFilterForm form={form} />
      }
      columns={[
        ...commonColumns(),
        {
          key: 'actions',
          width: 1,
          render: (_, record) => (
            <CdDropdown
              items={[
                {
                  key: 'view',
                  label: I18nService.getString('View'),
                  icon: <CdEyeIcon />,
                  onClick: () => viewEnquiry(record.id),
                },
                {
                  type: 'divider',
                },
                {
                  key: 'approve',
                  label: I18nService.getString('Approve', null, 'Enquiry'),
                  icon: <CdCheckIcon />,
                  onClick: () => approveEnquiry(record.id),
                },
                {
                  key: 'decline',
                  label: I18nService.getString('Decline', null, 'Enquiry'),
                  icon: <CdBan />,
                  onClick: () => declineEnquiry(record.id),
                },
              ]}
              size="small"
            >
              {I18nService.getString('Actions')}
            </CdDropdown>
          ),
        },
      ]}
      emptyStateProps={{
        title: I18nService.getString('No open enquiries'),
      }}
    />
  );
};

export const commonColumns: () => ColumnType<Enquiry>[] = () => [
  {
    title: I18nService.getString('Date'),
    dataIndex: 'startDate',
    width: 230,
    render: (_, record) => (
      <DateIntervalRender
        startDate={record.startDate}
        endDate={record.endDate}
      />
    ),
    orderType: OrderType.DATE,
    defaultSortOrder: 'descend',
  },
  {
    title: I18nService.getString('Status'),
    dataIndex: 'status',
    width: 130,
    render: (status) => <EnquiryStatusIcon status={status} />,
  },
  {
    title: I18nService.getString('Type'),
    dataIndex: 'taxonomy',
    width: 180,
    render: (taxonomy) => taxonomy.name,
  },
  {
    title: I18nService.getString('Booking option'),
    dataIndex: 'bookingOption',
    width: 220,
    render: (bookingOption, record) => (
      <CdVerticalSpace size={0}>
        <Typography.Text>{bookingOption.name}</Typography.Text>
        {record.resources?.map((resource) => (
          <Space key={resource.id}>
            {!isNull(resource.color) && (
              <CdCircleFilled
                style={{
                  color: COLOR_INDEX[resource.color],
                }}
              />
            )}
            {resource.name}
          </Space>
        ))}
      </CdVerticalSpace>
    ),
  },
  {
    title: I18nService.getString('Users'),
    dataIndex: 'users',
    render: (users) =>
      users?.map((user) => user.contact?.fullName || user.email).join(', '),
  },
];
