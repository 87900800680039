/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostPortalWidgetsRequestBody = {
    name: string;
    type: PostPortalWidgetsRequestBody.type;
    configuration: any;
    portalId: string;
};
export namespace PostPortalWidgetsRequestBody {
    export enum type {
        SEARCH = 'search',
        TEASER = 'teaser',
    }
}

