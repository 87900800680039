export interface PeopleChurch {
  id: number;
  name: string;
}

export interface PersonTag {
  id: number;
  name: string;
}

export enum GenderTypes {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}
export interface People {
  id: number;
  email: string;
  phone: string;
  name: string;
  firstName: string;
  lastName: string;
  picture: string;
  roles: number[];
  status: number;
  lastAccess: Date;
  createdAt: Date;
  groups: number[];
}

export interface Person {
  allergy: string;
  churches: PeopleChurch[];
  city?: string;
  contactId: number;
  countryIso2?: string;
  deletedAt: string;
  email: string;
  emailNotification: boolean | string;
  emailStatus: string;
  fields: PeopleFields[];
  firstName: string;
  fullName: string;
  id: number;
  lastName: string;
  organizationId: number;
  phone?: string;
  picture: { url?: string };
  postCode?: string;
  smsNotification: boolean | string;
  street?: string;
  tags: PersonTag;
  peopleNewsletterLists: PeopleNewsletterLists[];
  createdAt?: string;
  zipcode?: string;
}

export interface PeopleNewsletterLists {
  id: number;
  name: string;
  subscribed: boolean;
}
export interface ParticipantLists {
  id: number;
  name: string;
  subscribed: boolean;
}
interface PeopleFields {
  label: string;
  value: string;
  property: string;
  type: string;
  group?: string;
  sensitive: boolean;
}

export interface PeopleFromSearch {
  id: number;
  organizationId: number;
  contactId: number;
  picture: string;
  fields: PeopleFields[];
}

export interface PeopleSearch {
  total: number;
  people: PeopleFromSearch[];
  totalMatching: number;
  searchAfter: [];
}

export interface PeopleAnniversarySettings {
  anniversariesTypeSetting?: {
    [key: string]: {
      checked: boolean;
      selected?: number[];
      label: string;
    };
  };
  usersToNotify?: number[];
  accessDenied?: boolean;
}
export interface PeopleAnniversaryUsersToNotify {
  id: number;
  name: string;
  email: string;
  picture: string;
}
export interface PeopleContactProfileStaticFieldsOptions {
  salutation: {
    mr: string;
    mrs: string;
    miss: string;
    ms: string;
    dr: string;
    other: string;
  };
  formalSalutation: {
    mister: string;
    miss: string;
    doctor: string;
    other: string;
  };
  civilStatus: {
    single: string;
    inARelationship: string;
    married: string;
    registeredPartnership: string;
    divorced: string;
    widowed: string;
    registeredPartnershipDissolved: string;
  };
  allergy: {
    nuts: string;
    eggs: string;
    dairy: string;
    fish: string;
    shellfish: string;
    wheat: string;
    soy: string;
  };
  dietary: {
    vegan: string;
    vegetarian: string;
    pescetarian: string;
  };
  denomination: {
    evangelical: string;
    'french-reformed': string;
    'evangelical-lutheran': string;
    'evangelical-reformed': string;
  };
  gender: {
    male: string;
    femail: string;
    other: string;
  };
}
export interface PeopleContactProfileFields {
  [key: string]: {
    name: string;
    sensitive: boolean;
    locked: boolean;
  };
}
interface FieldPermissions {
  canView: boolean;
  canEdit: boolean;
}

export interface ProfileAccess {
  [fieldName: string]: FieldPermissions;
}
