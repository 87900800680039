import React, { useEffect, useCallback } from 'react';
import { Form, Input, Radio, Select, Space } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { compose } from 'redux';

import { FormListSearchParamsAtom, FormLabels } from '../../store/form';

import { I18nService } from '@/react/services/I18nService';
import { SelectedOrganizationChurches } from '@/react/organization/store/organization';
import { navigate } from '@/react/services/StateServiceFactory';
import { HasMultipleChurches } from '@/react/user/store/user-session';

const form2query = (_field, fields) =>
  fields.reduce((o, { name, value }) => {
    o[name] = value;
    return o;
  }, {});

const updateUrl = (filters) => navigate('app.private.forms.list', filters);

export default function FormsFilter({ initialQuery }: { initialQuery: any }) {
  const [form] = Form.useForm();
  const [searchQuery, setSearchQuery] = useRecoilState(
    FormListSearchParamsAtom
  );
  const labels = useRecoilValue(FormLabels).items;
  const churches = useRecoilValue(SelectedOrganizationChurches);
  const isMultiParish = useRecoilValue(HasMultipleChurches);

  const setQuery = useCallback(
    (newQuery) => {
      setSearchQuery((oldQuery) => ({
        ...oldQuery,
        ...newQuery,
      }));
      return newQuery;
    },
    [setSearchQuery]
  );

  const onFiltersChange = compose(updateUrl, setQuery, form2query);

  useEffect(() => {
    if (initialQuery) {
      setQuery(initialQuery);
    }
    return () => {
      form.resetFields();
    };
  }, [initialQuery, setQuery, form]);

  return (
    <Form
      form={form}
      onFieldsChange={onFiltersChange}
      initialValues={{ ...searchQuery, ...initialQuery }}
    >
      <Space>
        <Form.Item name="myForms" noStyle>
          <Radio.Group
            options={[
              {
                label: I18nService.getString(
                  'My',
                  undefined,
                  'Next to a radio button, which shows my forms'
                ),
                value: true,
              },
              {
                label: I18nService.getString(
                  'All',
                  undefined,
                  'Next to a radio button, which shows all forms'
                ),
                value: false,
              },
            ]}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        <Form.Item noStyle name="labelId">
          <Select
            placeholder={I18nService.getString('Label')}
            style={{ minWidth: '200px' }}
          >
            <Select.Option value={null}>
              {I18nService.getString('All labels')}
            </Select.Option>
            {labels.map(({ id, label }) => (
              <Select.Option key={id} value={id}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {isMultiParish && (
          <Form.Item noStyle name="churches">
            <Select
              mode="multiple"
              showSearch={false}
              maxTagCount={0}
              popupMatchSelectWidth={false}
              style={{ minWidth: '200px' }}
              placeholder={I18nService.getString('Parishes')}
              maxTagPlaceholder={({ length }) =>
                I18nService.getPlural(
                  length,
                  'Select parishes',
                  '{{length}} parishes',
                  { length }
                )
              }
            >
              {churches.map(({ name, id }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item noStyle name="title">
          <Input placeholder={I18nService.getString('Search for forms')} />
        </Form.Item>
      </Space>
    </Form>
  );
}
